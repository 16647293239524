import { Form } from "react-bootstrap";
import { Address } from "../Common/VendorContactReadOnly";

const countryList = [
  {
    text: "United States of America",
    value: "USA",
  },
  {
    text: "Canada",
    value: "CA",
  },
];

export default function AddressReadOnly(props: Address) {
  let cityStateSeperator = "";
  if (props.city && props.state) cityStateSeperator = ",";

  let countrySeperator = "";
  if (props.country) countrySeperator = ",";

  let country = countryList.find((c) => c.value === props.country);
  const countryValue = country ? country?.text : props.country;

  return (
    <Form.Label className="text-muted px-1 w-100" column>
      {props.address1}
      <br />
      {props?.address2 && (
        <>
          {props.address2} <br />
        </>
      )}
      {props?.address3 && (
        <>
          {props.address3} <br />
        </>
      )}
      <span>
        {" "}
        {props.city}
        {cityStateSeperator} {props.state} {props.zipCode}{" "}
      </span>
      <br /> {countryValue}
    </Form.Label>
  );
}
