import { Configuration } from '@azure/msal-browser';
import config from "./okta.config"

const defaultMsalConfiguration: Configuration = {
    auth: {
        clientId: "",
        authority: '',
        redirectUri: ''
    },

    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const defaultApiConfiguration = {
    basePath: process.env.REACT_APP_API_BASE_PATH,
    accessToken: '',    
    commonAPIPath: process.env.REACT_APP_COMMON_API_BASE_PATH
}
 

export interface IAppConfigurations {
    msalConfiguration: Configuration,
    apiConfiguration: any,
    defaultSignInScopes: string[]
}

export let AppConfiguration: IAppConfigurations = {
    msalConfiguration: defaultMsalConfiguration,
    apiConfiguration: defaultApiConfiguration,
    defaultSignInScopes: ["openid", "profile", "email", "User.Read", "offline_access"]
}

export let OktaConfig = config;
