import React from "react";
import { XtNavDropdownMultiProps } from "../../Props/XtNavDropdownMultiProps";
import { TreeNode } from "../../Utils/Tree";
import { XtNavDropdown } from "./XtNavDropdown";
import { XtNavDropdownItem } from "./XtNavDropdownItem";

export function XtNavDropdownMulti(props: XtNavDropdownMultiProps) {
    let dataTree = props.data;
    if (dataTree) {
        let rootNode = dataTree.Root;
        return treeRecursion(rootNode,0);
    } else {
        return (<div key="tree0"></div>);
    }
}


function treeRecursion(node: TreeNode, id) {
    let drop: string = "end"
    if (node == null) {
        return (<div key={"tree" + id}></div>);
    }
    if (node.Children && node.Children.length > 0) {
        if (node.IsRoot()) {
            drop = "down"
        }
        
        return (
            <XtNavDropdown key={"tree" + id} drop={drop} title={node.Data.title} className={node.Data.className} id={node.Data.id}>
                {
                    node.Children.map((child, i) => {
                        let index = id + i;
                        if (child.Data.type ===XtNavDropdownItem)
                         return  child.Data;
                        else 
                        return treeRecursion(child, index);
                    })
                }
            </XtNavDropdown>);
    }
    else {
        return (<XtNavDropdownItem key={"tree" + id} href={node.Data.url} target="_blank">{node.Data.title} </XtNavDropdownItem>)
    }

}