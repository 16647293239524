import { useContext, useEffect, useRef, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Accordion, Button } from "react-bootstrap";

import { APIFactoryCommon } from "../../../../Xt/ApiClients/Common";
import {
  APIFactory,
  InvoiceApi,
  PurchaseOrderApi,
  InvoiceCoding,
} from "../../../../Xt/ApiClients";
import { XtForm } from "nwcommon";
import { Loading } from "../../../../Xt/Layout/Loading";
import { XtBreadcrumbs } from "../../../../Xt/Layout/XtBreadcrumbs";
import { EditInvoiceHeader } from "./EditInvoice-Header";
import { EditInvoiceInvoiceCoding } from "./EditInvoice-InvoiceCoding";
import XtAccordianItem from "../../Nw/PurchaseRequisition/Common/XtAccordianItem";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import ErrorDialog from "../../Dialogs/Common/ErrorDialog";
import { PoCostDetails } from "../Common/PoCostDetails";
import LoadingDailog, {
  EnConfirmationMessageType,
  LoadingDailogProps,
} from "../../Dialogs/Common/LoadingDailog";
import { ErrorUtil } from "../../../../Xt/Utils/ErrorUtil";
import { InvoiceConstants } from "../Common/InvoiceConstants";
import { XtFormUtil } from "../../../../Xt/Utils/XtFormUtil";
import { NumberUtil } from "../../../../Xt/Utils/NumberUtil";
import InvoiceAuditLog from "../Details/InvoiceAuditLog";
import { InvoiceDraftDeleteButton } from "./InvoiceDraftDeleteButton";

const breadCrumbs = {
  breadCrumbs: [
    {
      href: "/invoice",
      text: "Invoice List",
      isActive: false,
    },
    {
      href: "/invoice/add",
      text: "Create Invoice",
      isActive: true,
    },
  ],
};

export function EditInvoice(props: any) {
  let factory: APIFactory;
  let factoryCommon: APIFactoryCommon;
  const context: IXtContext = useContext(XtContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [invoiceId, setInvoiceId] = useState<any>("");
  const [runningCost, setRunningCost] = useState<number>(0);
  const [po, setPO] = useState<any>(null);
  const [selectedPONumber, setSelectedPONumber] = useState(null);
  const [company, setCompany] = useState<any>(null);
  const [invoiceCoding, setInvoiceCoding] = useState<any[]>([]);
  const [reviewers, setReviewers] = useState<any>(null);
  const [redirectTo, setRedirectTo] = useState<string>("");
  const [errors, setErrors] = useState([]);
  const [invCodeRunningTotals, setInvCodeRunningTotals] = useState<any>({});
  const [submitLoading, setSubmitLoading] = useState<LoadingDailogProps>({
    show: false,
  });
  const [invCodingsOriginalAmounts, setInvCodingsOriginalAmounts] =
    useState<any>([]);
  const [loadAccountSpecificUsers, setLoadAccountSpecificUsers] =
    useState(null);
  const [selectedAccountRep, setSelectedAccountRep] = useState<any>(null);
  const saveRef = useRef(null);

  let { id } = useParams();

  useEffect(() => {
    if (id !== "") {
      setInvoiceId(id);
    }
  }, [id]);

  useEffect(() => {
    if (invoiceId !== "" && context?.loggedOnUser?.username) {
      let invApi: InvoiceApi = factory.create(InvoiceApi);
      const c: PurchaseOrderApi =
        factory.create<PurchaseOrderApi>(PurchaseOrderApi);
      setLoading(true);
      invApi
        .invoiceInvoiceGuidGet(invoiceId, context?.loggedOnUser?.username)
        .then(async (dd: any) => {
          setData(dd);
          setSelectedPONumber({ text: dd?.poNumber, value: dd?.poNumber });
          setReviewers(dd.reviewers);
          setInvCodingsOriginalAmounts(dd.invoiceCodings);
          var result;
          if (dd?.poNumber) {
            result = await c.purchaseOrderPoIDGet(
              dd.poNumber,
              context?.loggedOnUser?.username
            );
            let res: any = result;
            setPO(res);

            let loadSpecificUser = false;

            const invCodings = dd.invoiceCodings?.map((i) => {
              const poLineItem = res.poLineItemList?.find(
                (poli) => poli?.lineNumber === i?.poLineNumber
              );
              if (
                InvoiceConstants.AccountCodesForRepFilters.includes(
                  poLineItem?.accountType
                )
              )
                loadSpecificUser = true;
              return {
                ...i,
                amount: i?.amount,
                committedAmount: poLineItem?.committedAmount,
                costToDateAmount: poLineItem?.costToDateAmount,
                // runningTotal:  poLineItem?.runningTotal + i?.amount,
                runningTotal: dd?.status.includes("Originator")
                  ? poLineItem?.runningTotal
                  : poLineItem?.runningTotal + i?.amount,
                remainingAmount:
                  poLineItem?.committedAmount - poLineItem?.runningTotal,
                poLineDescription: poLineItem?.lineDescription,
                description: i?.description,
              };
            });
            setLoadAccountSpecificUsers(loadSpecificUser);
            setInvoiceCoding(invCodings);
            // setRunningCost(res?.runningTotal + dd?.vendorAmount);
            setRunningCost(
              dd?.status.includes("Originator")
                ? res?.runningTotal
                : res?.runningTotal + dd?.vendorAmount
            ); // fix
          }
        })
        .catch((err) => {
          if (err.status === 403) {
            setRedirectTo("/Error403");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [invoiceId, context?.loggedOnUser?.username]);

  const onPONumberChange = async (e) => {
    setLoading(true);
    if (!e?.value) {
      setSelectedPONumber(null);
      setPO(null);
      setCompany(null);
      setInvoiceCoding([]);
      setRunningCost(0);
      setSelectedAccountRep(null);
    } else {
      setSelectedPONumber(e?.value);
      const c: PurchaseOrderApi =
        factory.create<PurchaseOrderApi>(PurchaseOrderApi);
      var result = await c.purchaseOrderPoIDGet(
        e.value.value,
        context?.loggedOnUser?.username
      );
      let nPO: any = result;
      setPO(result);
      let loadSpecificUser = false;
      let codingList: any[] = [];
      nPO?.poLineItemList.map((v) => {
        if (v.itemID) {
          v.poLineDescription = v.lineDescription;
          v.description = undefined;
          v["poLineNumber"] = v["lineNumber"];
          if (
            InvoiceConstants.AccountCodesForRepFilters.includes(v?.accountType)
          )
            loadSpecificUser = true;
          codingList.push(v);
        }
      });
      setLoadAccountSpecificUsers(loadSpecificUser);
      setInvoiceCoding(codingList);
      setRunningCost(nPO?.runningTotal);
    }
    // await loadReviewers(invApi, prApi, e.value.value)
    setLoading(false);
  };

  const onSubmit = async (e) => {
    var postData = e.postData;
    postData.VendorAmount = postData.VendorAmount.replace(/[^\d\.\-]/g, "");
    var total = 0;

    const actionType =
      e?.nativeEvent?.submitter?.id == "submit" ? "Submit" : "Save";
    //if(actionType =="Save" && e?.postData?.PONumber==="")
    //{
    //  setErrors([`PO Number is required.`]);
    //  return;
    //}
    e.postData.VendorCode = po?.vendorID;
    postData.invoiceCodings = [];
    postData.poTotalAmount =
      data?.poTotalAmount > 0 ? data?.poTotalAmount : po?.trN_PO_TOT_AMT;
    postData.poRunningTotalAmount = runningCost;
    postData.InvoiceDate = new Date(postData.InvoiceDate);
    postData.actionType = actionType;
    const submitErrors = [];
    invoiceCoding.map((v, i) => {
      let c: InvoiceCoding = {};
      c.projectNumber = v.projectNumber;
      c.accountNumber = v.accountNumber;
      c.orgId = v.orgId;
      if (v.lineAccountList !== undefined && v.lineAccountList.length > 0) {
        c.projectNumber = v.lineAccountList[0].mM_POLNACCT_PROJ_ID;
        c.accountNumber = v.lineAccountList[0].accT_ID;
        c.orgId = v.lineAccountList[0].orG_ID;
        c.projectName = v.lineAccountList[0].proJ_NAME;
        c.accountName = v.lineAccountList[0].accT_NAME;
        c.accountGroupCode = v.lineAccountList[0].accountGroupCode;
      } else {
        c.projectName = v.projectName;
        c.accountName = v.accountName;
        c.orgId = v.orgId;
      }
      c.invoiceCodingGuid = v.invoiceCodingGuid;
      c.description = v?.description;

      c.sno = v.poLineNumber;
      c.poLineNumber = v.poLineNumber;
      if (v.amount) {
        c.amount = parseFloat(v.amount);
      } else {
        c.amount = 0;
      }

      c.total = parseFloat(v.committedAmount);

      total = NumberUtil.decimalNumSum(total, c.amount);
      postData.invoiceCodings.push(c);

      // if(actionType =="Submit" && c.amount>parseFloat(v.committedAmount))
      // submitErrors.push(`#${c.sno}: Invoice amount cannot be more than committed amount.` );
    });

    if (actionType == "Submit") {
      if (postData?.attachments.length === 0) {
        submitErrors.push("At least one attachment is required.");
      }

      if (
        Number(NumberUtil.formatToTwoDecimal(total)) !==
        Number(NumberUtil.formatToTwoDecimal(postData.VendorAmount))
      ) {
        submitErrors.push(
          "Total invoice coding amount is not equals to invoice amount."
        );
      }

      if (submitErrors.length > 0) {
        setErrors(submitErrors);
        return;
      }
    }

    setSubmitLoading({
      show: true,
      message: ["Invoice is being updated....."],
      messageType: EnConfirmationMessageType.Wait,
    });

    try {
      let invApi: InvoiceApi = factory.create(InvoiceApi);
      let result = await invApi.invoiceInvoiceGuidPut(
        id,
        context?.loggedOnUser?.username,
        postData
      );
      if (result.ok) {
        const newInvoice = await result.json();
        setDailogSuccess(newInvoice?.invoiceID, postData.actionType);
      } else {
        let msg = await result.json();
        const errors = ErrorUtil.getErorMessageFromApi(msg);
        setDailogError(errors);
      }
    } catch (ex) {
      const errors = ErrorUtil.getErorMessageFromApi(ex);
      setDailogError(errors);
    }
  };

  const setDailogSuccess = (invoiceId: string, actionType: string) => {
    const message =
      actionType === "Submit"
        ? `Invoice ${invoiceId} has been successfully submitted.`
        : actionType === "Delete"
        ? `Draft for Invoice ${invoiceId} has been successfully deleted.`
        : `Invoice ${invoiceId} has been successfully saved as draft.`;
    const title =
      actionType === "Submit"
        ? "Invoice Submission Successful"
        : actionType === "Delete"
        ? "Invoice Deleted"
        : "Invoice Saved as Draft";
    setSubmitLoading({
      show: true,
      message: [message],
      title: title,
      messageType: EnConfirmationMessageType.Success,
      onClose: () => {
        setRedirectTo("/invoice");
        setSubmitLoading({ show: false });
      },
    });
  };

  const setDailogError = (errorMessage: string[]) => {
    setSubmitLoading({
      show: true,
      title: "Error",
      messageType: EnConfirmationMessageType.Error,
      message: errorMessage,
      onClose: (e) => {
        setSubmitLoading({ show: false });
      },
    });
  };

  const onInvoiceLineItemChange = (e, row) => {
    if (e?.field === "amount") {
      let invCodingRunningTotalTemp = { ...invCodeRunningTotals };
      let index = invoiceCoding.indexOf(row);
      var runningTotal = invoiceCoding[index].runningTotal;
      const invCodingAmount = parseFloat(e.value || 0);
      let lastAmount;
      if (
        invCodingsOriginalAmounts === undefined ||
        invCodingsOriginalAmounts.length === 0
      ) {
        lastAmount = 0;
      } else {
        lastAmount = invCodingsOriginalAmounts[index].amount;
      }
      runningTotal = runningTotal + (invCodingAmount - lastAmount);
      invoiceCoding[index].amount = invCodingAmount;
      invoiceCoding[index].remainingAmount =
        invoiceCoding[index].total - runningTotal;

      invCodingRunningTotalTemp[index] = runningTotal;

      setInvCodeRunningTotals(invCodingRunningTotalTemp);

      let totalRunningAmount = 0;
      invoiceCoding.forEach((v, i) => {
        totalRunningAmount =
          (invCodingRunningTotalTemp[i] ?? invoiceCoding[i].runningTotal) +
          totalRunningAmount;
      });
      setRunningCost(totalRunningAmount);
    } else if (e?.field === "description") {
      let index = invoiceCoding.indexOf(row);
      invoiceCoding[index].description = e.value || "";
    }

    setInvoiceCoding(invoiceCoding);
  };

  const onSave = (e) => {
    let fe = saveRef.current.form.elements;
    const postData = XtFormUtil.getPostData(fe);
    onSubmit({ ...e, postData: postData });
  };

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <APIFactoryCommon
        ref={(e) => {
          factoryCommon = e;
        }}
      />

      {redirectTo !== "" && <Redirect push to={{ pathname: redirectTo }} />}

      <div className="row mb-3 align-items-md-center">
        <div className="col">
          <div className="mb-3 mb-md-0">
            <XtBreadcrumbs {...breadCrumbs} />
          </div>
        </div>

        <div className="col-auto">
          {data?.status === "Draft" && (
            <>
              <InvoiceDraftDeleteButton
                setDialogSuccess={setDailogSuccess}
                setDialogError={setDailogError}
                invoiceGuid={data?.invoiceGuid}
                invoiceID={data?.invoiceID}
                loading={loading}
                setLoading={setLoading}
              />
              <span className="px-3 py-2 fw-bold text-white d-inline-block  pr-status-draft">
                {data?.status}
              </span>
            </>
          )}
        </div>
      </div>

      <div className="pr-create-new">
        <XtForm onSubmit={onSubmit}>
          <Accordion alwaysOpen>
            <XtAccordianItem header="INVOICE DETAIL " eventKey="0">
              <EditInvoiceHeader
                onPOChange={onPONumberChange}
                purchaseOrder={po}
                company={company}
                invoice={data}
                selectedPONumber={selectedPONumber}
                loadAccountSpecificUsers={loadAccountSpecificUsers}
                selectedAccountRep={selectedAccountRep}
                setSelectedAccountRep={setSelectedAccountRep}
              />
            </XtAccordianItem>
            <XtAccordianItem
              header="AUDIT LOG"
              eventKey="1"
              IsCollapsible={true}
            >
              <InvoiceAuditLog invoiceGuid={invoiceId} />
            </XtAccordianItem>

            <XtAccordianItem header="PO Cost Details" eventKey="1">
              <PoCostDetails
                committedAmount={po?.totalAmount}
                costToDate={po?.costToDateAmount}
                runningTotalCost={runningCost}
              />
            </XtAccordianItem>

            <XtAccordianItem header="INVOICE CODING" eventKey="2">
              <EditInvoiceInvoiceCoding
                data={invoiceCoding}
                orgId={company?.orgId}
                onInvoiceLineItemChange={onInvoiceLineItemChange}
                triggerCount={1}
                invCodeRunningTotals={invCodeRunningTotals}
              />
            </XtAccordianItem>
          </Accordion>

          <DialogActionsBar layout="end">
            <Button
              type="button"
              className="me-2"
              variant="secondary"
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="button"
              id="save"
              variant="secondary"
              disabled={loading}
              ref={saveRef}
              onClick={onSave}
            >
              Save
            </Button>
            <Button
              type="submit"
              id="submit"
              variant="primary"
              disabled={loading}
            >
              Submit
            </Button>
          </DialogActionsBar>
        </XtForm>

        {loading && <Loading />}
        {errors.length > 0 && (
          <ErrorDialog errorMessage={errors} onClose={(e) => setErrors([])} />
        )}
        {submitLoading && submitLoading.show && (
          <LoadingDailog {...submitLoading} />
        )}
      </div>
    </>
  );
}
