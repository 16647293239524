import { Grid } from "@progress/kendo-react-grid";
import React from "react";
import { AdvSearchAndGridUtil } from "../../../../../Xt/Utils/AdvanceSearchAndGridUtil";
import {
  APIFactory,
  InvoiceApi,
  PurchaseRequisitionApi,
} from "../../../../../Xt/ApiClients";
import { GridColumn } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button, Col, Container } from "react-bootstrap";
import { useContext } from "react";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { Loading } from "../../../../../Xt/Layout/Loading";

export default function AttachmentList(props) {
  const context: IXtContext = useContext(XtContext);
  let factory: APIFactory;
  const [visible, setVisible] = React.useState(false);
  const [attachGuid, setAttachGuid] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const hasFile = props.files && props.files.length > 0;
  const toggleDialog = () => {
    setVisible(!visible);
  };

  const handleDelete = () => {
    setLoading(true);
    let c: InvoiceApi = factory.create(InvoiceApi);
    if (props?.invoiceGuid) {
      c.invoiceDeleteInvoiceAttachmentInvoiceGuidDelete(
        props?.invoiceGuid,
        context?.loggedOnUser?.username,
        attachGuid
      )
        .then((res) => {
          const updatedFiles = props?.files.filter(
            (file) => file.attachmentGuid !== attachGuid
          );
          props.onUpdateFiles(updatedFiles);
          setVisible(!visible);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (props?.prGuid) {
      let prApi: PurchaseRequisitionApi = factory.create(
        PurchaseRequisitionApi
      );
      prApi
        .purchaseRequisitionDeletePurchaseRequisitionAttachmentPrGuidDelete(
          props?.prGuid,
          context?.loggedOnUser?.username,
          attachGuid
        )
        .then((res) => {
          const updatedFiles = props?.files.filter(
            (file) => file.attachmentGuid !== attachGuid
          );
          props.onUpdateFiles(updatedFiles);
          setVisible(!visible);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleDeletePopup = (attachmentGuid) => {
    setAttachGuid(attachmentGuid);
    setVisible(!visible);
  };
  return (
    <>
      {loading && <Loading />}
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      {visible && (
        <Dialog title="Confirmation" onClose={toggleDialog}>
          <Container>
            <Col>Do you really want to delete this file?</Col>
          </Container>
          <DialogActionsBar>
            <Button type="button" variant="primary" onClick={handleDelete}>
              Yes
            </Button>
            <Button variant="secondary" onClick={toggleDialog}>
              No
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {hasFile && (
        <Grid data={props?.files} onSelectionChange={props?.onDeleteAttachment}>
          {AdvSearchAndGridUtil.renderGridFields(props.schema)}
          {props?.allowAttachmentDelete && (
            <GridColumn
              cell={(props) => (
                <td>
                  <span
                    onClick={() =>
                      handleDeletePopup(props.dataItem.attachmentGuid)
                    }
                    className="k-icon k-i-delete k-link"
                  ></span>
                </td>
              )}
            />
          )}
        </Grid>
      )}
    </>
  );
}
