import { XtHome } from "./XtHome"
import { XtSilentLogin } from "./XtSilentLogin"
import { XtAuthDialog } from "./XtAuthDialog"
import { XtLogout } from "./XtLogout"
import { XtAddCompany } from "./XtAddCompany"
import XtAdminSettings from "./XtAdminSettings"
import { XtAddEditCountryView } from "./Dialogs/Country/XtAddEditCountryView"
import { NwPurchaseRequisitionList } from "./Nw/PurchaseRequisition/List/NwPurchaseRequisitionList"
import { XtGroup } from "./XtGroup"
import { XtUser } from "./XtUser"
import { XtUpload } from "./XtUpload"
import { XtFile } from "./XtFile"
import XtUserPermission from "./Dialogs/Users/XtUserPermission"
import NwCreateNewPr from "./Nw/PurchaseRequisition/CreateNew/NwCreateNewPr"
import NwApprovalView from "./Nw/PurchaseRequisition/ApprovalView/NwApprovalView"
import NwUnAuthorize from "./Nw/PurchaseRequisition/UnAuthorize"
import { AddInvoice as AddInvoice1 } from "./invoice/AddEditInvoice/AddInvoice"
import { InvoiceList as Invoice1 } from "./invoice/Invoice"
import { POList as POList1 } from "./Nw/PurchaseOrder/PurchaseOrderList"
import NwPODetails from "./Nw/PurchaseOrder/PODetails"
import CostPointIntegrationLandingPage from "./Nw/CostPointIntegration/CostPointIntegrationLandingPage"
import { NwPOChangeRequest as NwPOChangeRequest1 } from "./Nw/PurchaseOrder/POChange"
import { InvoiceDetails as InvoiceDetails1 } from "./invoice/Details/InvoiceDetails"
import { EditInvoice as EditInvoice1 } from "./invoice/EditInvoice/EditInvoice"
import POChangeRequestDailog from "./Nw/PurchaseOrder/POChangeRequestDailog"
import { InvoiceCPIntegration as InvoiceCPIntegration1 } from "./invoice/CostPointIntegration/InvoiceCPIntegration"
import MyActionItem from "./Nw/MyActionItem"
import { NwPersonnelRequisitionList } from "./Nw/PersonnelRequisition/ListView"
import  NwCreateNewPrr from "./Nw/PersonnelRequisition/CreateNew/CreateNewPrr"
import NwPrrApprovalView from "./Nw/PersonnelRequisition/ApprovalView/PrrApprovalView"




export let Home = XtHome
export let SilentLogin = XtSilentLogin
export let AuthDialog = XtAuthDialog
export let Logout = XtLogout
export let AddCompany = XtAddCompany
export let AdminSettings = XtAdminSettings
export let AddEditCountryView = XtAddEditCountryView
export let PurchaseRequisitionList = NwPurchaseRequisitionList
export let Group = XtGroup
export let UserPermission = XtUserPermission
export let Upload = XtUpload
export let CreateNewPr = NwCreateNewPr
export let File = XtFile
export let ApprovalView = NwApprovalView
export let PrrApprovalView = NwPrrApprovalView
export let UnAuthorize = NwUnAuthorize
export let AddInvoice = AddInvoice1
export let Invoice = Invoice1
export let PoDetails = NwPODetails
export let CpIntegraitonLandingPage = CostPointIntegrationLandingPage
export let POList = POList1
export let PersonnelRequisitionList = NwPersonnelRequisitionList
export let CreateNewPrr = NwCreateNewPrr
export let NwPOChangeRequest = NwPOChangeRequest1;
export let PoEdit = NwCreateNewPr
export let InvoiceDetails = InvoiceDetails1;
export let EditInvoice = EditInvoice1;
export let PoChangeDailog = POChangeRequestDailog;
export let InvoiceCPIntegration = InvoiceCPIntegration1;
export let ActionItems = MyActionItem; 
