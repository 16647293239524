export default function PRNumberGridCell(props) {
  let numberToUse = "";
  let guidToUse = "";
  let urlToUse = "";

  if (props.dataItem.prrNumber) {
    numberToUse = props.dataItem.prrNumber;
    guidToUse = props.dataItem.personnelRequisitionGuid;
    urlToUse = "/prr/view/" + guidToUse;
  } else if (props.dataItem.prNumber) {
    numberToUse = props.dataItem.prNumber;
    guidToUse = props.dataItem.purchaseRequisitionGuid;
    urlToUse = "/pr/view/" + guidToUse;
  }

  return (
    <>
      <td>
        <a
          href={urlToUse}
          target="_blank"
          rel="noreferrer"
          className="text-primary"
        >
          {numberToUse}
        </a>
      </td>
    </>
  );
}
