export const myActionFilter = [
  { value: "MyActivePRR", text: "My Active PRRs" },
  { value: "MyPRR", text: "My PRRs" },
  { value: "MyDraft", text: "Drafts" },
  { value: "AllPRR", text: "All PRRs" },
];
export const employmentTypeOption = [
  { text: "Regular Full Time", value: "Regular Full Time" },
  { text: "Regular Part Time", value: "Regular Part Time" },
  { text: "Temporary Full Time", value: "Temporary Full Time" },
  { text: "Temporary Part Time", value: "Temporary Part Time" },
  { text: "Union", value: "Union" },
];
export const completedOption = [
  { text: "Yes", value: "true" },
  { text: "No", value: "false" },
];
export const isTravelRequired = [
  { text: "Yes", value: "Yes" },
  { text: "No", value: "No" },
];
export const yearsOfExp = [
  { text: "0", value: "0" },
  { text: "1", value: "1" },
  { text: "2", value: "2" },
  { text: "3", value: "3" },
  { text: "4", value: "4" },
  { text: "5", value: "5" },
  { text: "6", value: "6" },
  { text: "7", value: "7" },
  { text: "8", value: "8" },
  { text: "9", value: "9" },
  { text: "10", value: "10" },
  { text: "11", value: "11" },
  { text: "12", value: "12" },
  { text: "13", value: "13" },
  { text: "14", value: "14" },
  { text: "15", value: "15" },
  { text: "16", value: "16" },
  { text: "17", value: "17" },
  { text: "18", value: "18" },
  { text: "19", value: "19" },
  { text: "20", value: "20" },
  { text: "21", value: "21" },
  { text: "22", value: "22" },
  { text: "23", value: "23" },
  { text: "24", value: "24" },
  { text: "25", value: "25" },
  { text: "26", value: "26" },
  { text: "27", value: "27" },
  { text: "28", value: "28" },
  { text: "29", value: "29" },
  { text: "30+", value: "30" },
];
export const wageType = [
  { text: "Salary", value: "Salary" },
  { text: "Hourly", value: "Hourly" },
];
export const education = [
  { text: "High School", value: "High School" },
  { text: "College", value: "College" },
  { text: "Advanced Degree", value: "Advanced Degree" },
];
export const employeeTypes = [
  { text: "Direct", value: "Direct" },
  { text: "Indirect", value: "Indirect" },
];

export const requestReasons = [
  { text: "Addition", value: "Addition" },
  { text: "Replacement", value: "Replacement" },
];
export const vacancies = [
  { text: "1", value: "1" },
  { text: "2", value: "2" },
  { text: "3", value: "3" },
  { text: "4", value: "4" },
  { text: "5", value: "5" },
  { text: "6", value: "6" },
  { text: "7", value: "7" },
  { text: "8", value: "8" },
  { text: "9", value: "9" },
  { text: "10", value: "10" },
  { text: "11", value: "11" },
  { text: "12", value: "12" },
  { text: "13", value: "13" },
  { text: "14", value: "14" },
  { text: "15", value: "15" },
  { text: "16", value: "16" },
  { text: "17", value: "17" },
  { text: "18", value: "18" },
  { text: "19", value: "19" },
  { text: "20", value: "20" },
];
export const isHiringManager = [
  { text: "Yes", value: "Yes" },
  { text: "No", value: "No" },
];
