import React from "react";
import {
    Grid,
    GridColumn,
    GridPageChangeEvent,
    GridToolbar,
  GridProps,
} from "@progress/kendo-react-grid";
import RightAlignGridColumnHeader from "../../Nw/PurchaseRequisition/Common/GridCell/RightAlignGridColumnHeader";
import AmountGridCell, {TrucAmountGridCell, TrucAmountGridCellBg} from "../../Nw/PurchaseRequisition/Common/GridCell/AmountGridCell";
import { NumberUtil } from "../../../../Xt/Utils/NumberUtil";

export type PoCostDetailsProps = {
  committedAmount: number;
  costToDate: number;
  runningTotalCost: number;
};
    
export function PoCostDetails(props: PoCostDetailsProps) {
  const [data, setData] = React.useState<any[]>([]);

    React.useEffect(() => {
        let d = [];

        d.push({
          committedAmount: props?.committedAmount,
          costToDate: props?.costToDate,
          runningTotalCost: props?.runningTotalCost ,
      remainingAmount: NumberUtil.decimalNumDiff(
        props?.committedAmount,
        props?.runningTotalCost
      ),
        });
    
        setData(d);
    }, [props]);

  return (
    <Grid
        data={data}
        total={0}
      style={{ width: "850px" }}
        className="k-grid-pr-list"
    >
        <GridColumn title="Committed Amount" field="committedAmount" width="200px"  headerCell={RightAlignGridColumnHeader} cell={AmountGridCell}  />
        <GridColumn title="Cost to Date" field="costToDate" width="200px"  headerCell={RightAlignGridColumnHeader} cell={AmountGridCell} />
        <GridColumn title="Running Total" field="runningTotalCost" width="200px" headerCell={RightAlignGridColumnHeader} cell={TrucAmountGridCell} />
        <GridColumn title="Remaining Amount" field="remainingAmount" width="200px"  headerCell={RightAlignGridColumnHeader} cell={TrucAmountGridCellBg} />
    </Grid>)
}
