import { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";

import FormField from "../Common/FormField";
import FormLabelMutedText from "../Common/FormLabelMutedText";
import { PrApiService } from "../Common/PrApiService";
import { APIFactory, VendorApi } from "../../../../../Xt/ApiClients";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import NwVendorContactReadOnly from "../Common/VendorContactReadOnly";

export default function NwVendorInfoReadOnly(props) {
  const { pr } = props;
  let apiFactory: APIFactory;
  const [vendor, setVendor] = useState(null);
  const context: IXtContext = useContext(XtContext);

  useEffect(() => {
        if (pr?.vendorCode) {
      if (pr?.vendorCode && context?.loggedOnUser) {
        const vendorCode = pr?.vendorCode;
        let api: VendorApi = apiFactory.create(VendorApi);
        PrApiService.getVendor(
          context,
          api,
          vendorCode,
          pr?.companyCode,
          pr?.vendorAddressGuid,
          pr?.addressContactGuid
        ).then((vendorDetails) => {
             setVendor(vendorDetails);
        });
      }
    }
  }, [pr?.vendorCode, context?.loggedOnUser?.username]);

  return (
    <>
      <APIFactory
        ref={(e) => {
          apiFactory = e;
        }}
      />

      <Row>
        <FormField label="Recommended Vendor">
          <FormLabelMutedText
            value={
              vendor?.vendorName &&
              `${vendor?.vendorName} (${vendor?.vendorCode})`
            }
          />
        </FormField>
        <NwVendorContactReadOnly {...vendor} />
      </Row>
    </>
  );
}
