/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Northwind Common API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum Actions {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6
}
/**
 * 
 * @export
 * @interface AdditionalInformation
 */
export interface AdditionalInformation {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformation
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalInformation
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformation
     */
    status?: string;
    /**
     * 
     * @type {Array<Array<AdditionalInformationItem>>}
     * @memberof AdditionalInformation
     */
    additionalInformationList?: Array<Array<AdditionalInformationItem>>;
}
/**
 * 
 * @export
 * @interface AdditionalInformationItem
 */
export interface AdditionalInformationItem {
    /**
     * 
     * @type {number}
     * @memberof AdditionalInformationItem
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AdditionalInformationItem
     */
    employeeId?: number;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customEffectiveDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customChangeReason?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customContractStatusIfApplicable?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customTaxableEntityCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customTaxableEntityDescriptionTaxID?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customHomeOrganizationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customHomeOrganizationCodeDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customOvertimeState?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customTimesheetCycleCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customTimesheetCycleCodeDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customPayCycleCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customPayCycleCodeDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customEmployeeClassCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customEmployeeClassCodeDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customWorkHoursintheYear?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customLaborGroupCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customLaborGroupCodeDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customGLCCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customGLCCodeDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customComments1?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customTimecardApprover?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customWorkSchedule?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationItem
     */
    customManualCPEntry?: string;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    companyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    companyIdCP?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    bambooHRName?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    taxbleEntityIdCP?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    isDeleted?: number;
}
/**
 * 
 * @export
 * @interface CompanySingleResult
 */
export interface CompanySingleResult {
    /**
     * 
     * @type {Array<Company>}
     * @memberof CompanySingleResult
     */
    queryable?: Array<Company>;
}
/**
 * 
 * @export
 * @interface Compensation
 */
export interface Compensation {
    /**
     * 
     * @type {string}
     * @memberof Compensation
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Compensation
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Compensation
     */
    status?: string;
    /**
     * 
     * @type {Array<CompensationItem>}
     * @memberof Compensation
     */
    compensationList?: Array<CompensationItem>;
}
/**
 * 
 * @export
 * @interface CompensationAdditionalInformation
 */
export interface CompensationAdditionalInformation {
    /**
     * 
     * @type {string}
     * @memberof CompensationAdditionalInformation
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompensationAdditionalInformation
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompensationAdditionalInformation
     */
    status?: string;
    /**
     * 
     * @type {Array<Array<CompensationAdditionalInformationItem>>}
     * @memberof CompensationAdditionalInformation
     */
    compensationAdditionalInformationList?: Array<Array<CompensationAdditionalInformationItem>>;
}
/**
 * 
 * @export
 * @interface CompensationAdditionalInformationItem
 */
export interface CompensationAdditionalInformationItem {
    /**
     * 
     * @type {number}
     * @memberof CompensationAdditionalInformationItem
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompensationAdditionalInformationItem
     */
    employeeId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompensationAdditionalInformationItem
     */
    customEffectiveDate1?: string;
    /**
     * 
     * @type {string}
     * @memberof CompensationAdditionalInformationItem
     */
    customFringeRate?: string;
    /**
     * 
     * @type {string}
     * @memberof CompensationAdditionalInformationItem
     */
    customComment?: string;
}
/**
 * 
 * @export
 * @interface CompensationItem
 */
export interface CompensationItem {
    /**
     * 
     * @type {string}
     * @memberof CompensationItem
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompensationItem
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompensationItem
     */
    startDate?: string;
    /**
     * 
     * @type {Rate}
     * @memberof CompensationItem
     */
    rate?: Rate;
    /**
     * 
     * @type {string}
     * @memberof CompensationItem
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof CompensationItem
     */
    exempt?: string;
    /**
     * 
     * @type {string}
     * @memberof CompensationItem
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof CompensationItem
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof CompensationItem
     */
    paidPer?: string;
    /**
     * 
     * @type {string}
     * @memberof CompensationItem
     */
    paySchedule?: string;
    /**
     * 
     * @type {OvertimeRate}
     * @memberof CompensationItem
     */
    overtimeRate?: OvertimeRate;
}
/**
 * 
 * @export
 * @interface Directory
 */
export interface Directory {
    /**
     * 
     * @type {string}
     * @memberof Directory
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Directory
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Directory
     */
    status?: string;
    /**
     * 
     * @type {Array<Field>}
     * @memberof Directory
     */
    fields?: Array<Field>;
    /**
     * 
     * @type {Array<Employee>}
     * @memberof Directory
     */
    employees?: Array<Employee>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EdmContainerElementKind {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EdmExpressionKind {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6,
    NUMBER_7 = <any> 7,
    NUMBER_8 = <any> 8,
    NUMBER_9 = <any> 9,
    NUMBER_10 = <any> 10,
    NUMBER_11 = <any> 11,
    NUMBER_12 = <any> 12,
    NUMBER_13 = <any> 13,
    NUMBER_14 = <any> 14,
    NUMBER_15 = <any> 15,
    NUMBER_16 = <any> 16,
    NUMBER_17 = <any> 17,
    NUMBER_18 = <any> 18,
    NUMBER_19 = <any> 19,
    NUMBER_20 = <any> 20,
    NUMBER_21 = <any> 21,
    NUMBER_22 = <any> 22,
    NUMBER_23 = <any> 23,
    NUMBER_24 = <any> 24,
    NUMBER_25 = <any> 25
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EdmSchemaElementKind {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EdmTypeKind {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6,
    NUMBER_7 = <any> 7,
    NUMBER_8 = <any> 8,
    NUMBER_9 = <any> 9
}
/**
 * 
 * @export
 * @interface Employee
 */
export interface Employee {
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    ethnicity?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    workPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    maritalStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    homePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    homeEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    workEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    division?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    empStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    emergencyContactName?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    emergencyContactRelationship?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    emergencyContactHomePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    emergencyContactMobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    emergencyContactWorkPhone?: string;
}
export interface EmployeeList{
    id?: string;
    fullName2?: string;
}
/**
 * 
 * @export
 * @interface EmployeeStatus
 */
export interface EmployeeStatus {
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    employmentStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    terminationReasonId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    terminationTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    terminationRehireId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    acaStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    benetracStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    gusto?: string;
}
/**
 * 
 * @export
 * @interface EmployeeStatuses
 */
export interface EmployeeStatuses {
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatuses
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeStatuses
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatuses
     */
    status?: string;
    /**
     * 
     * @type {Array<EmployeeStatus>}
     * @memberof EmployeeStatuses
     */
    employeeStatusList?: Array<EmployeeStatus>;
}
/**
 * 
 * @export
 * @interface Field
 */
export interface Field {
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IEdmDirectValueAnnotationsManager
 */
export interface IEdmDirectValueAnnotationsManager {
}
/**
 * 
 * @export
 * @interface IEdmEntityContainer
 */
export interface IEdmEntityContainer {
    /**
     * 
     * @type {Array<IEdmEntityContainerElement>}
     * @memberof IEdmEntityContainer
     */
    elements?: Array<IEdmEntityContainerElement>;
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmEntityContainer
     */
    schemaElementKind?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainer
     */
    namespace?: string;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainer
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IEdmEntityContainerElement
 */
export interface IEdmEntityContainerElement {
    /**
     * 
     * @type {EdmContainerElementKind}
     * @memberof IEdmEntityContainerElement
     */
    containerElementKind?: EdmContainerElementKind;
    /**
     * 
     * @type {IEdmEntityContainer}
     * @memberof IEdmEntityContainerElement
     */
    container?: IEdmEntityContainer;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainerElement
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IEdmExpression
 */
export interface IEdmExpression {
    /**
     * 
     * @type {EdmExpressionKind}
     * @memberof IEdmExpression
     */
    expressionKind?: EdmExpressionKind;
}
/**
 * 
 * @export
 * @interface IEdmModel
 */
export interface IEdmModel {
    /**
     * 
     * @type {Array<IEdmSchemaElement>}
     * @memberof IEdmModel
     */
    schemaElements?: Array<IEdmSchemaElement>;
    /**
     * 
     * @type {Array<IEdmVocabularyAnnotation>}
     * @memberof IEdmModel
     */
    vocabularyAnnotations?: Array<IEdmVocabularyAnnotation>;
    /**
     * 
     * @type {Array<IEdmModel>}
     * @memberof IEdmModel
     */
    referencedModels?: Array<IEdmModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IEdmModel
     */
    declaredNamespaces?: Array<string>;
    /**
     * 
     * @type {IEdmDirectValueAnnotationsManager}
     * @memberof IEdmModel
     */
    directValueAnnotationsManager?: IEdmDirectValueAnnotationsManager;
    /**
     * 
     * @type {IEdmEntityContainer}
     * @memberof IEdmModel
     */
    entityContainer?: IEdmEntityContainer;
}
/**
 * 
 * @export
 * @interface IEdmSchemaElement
 */
export interface IEdmSchemaElement {
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmSchemaElement
     */
    schemaElementKind?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmSchemaElement
     */
    namespace?: string;
    /**
     * 
     * @type {string}
     * @memberof IEdmSchemaElement
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IEdmTerm
 */
export interface IEdmTerm {
    /**
     * 
     * @type {IEdmTypeReference}
     * @memberof IEdmTerm
     */
    type?: IEdmTypeReference;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    appliesTo?: string;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    defaultValue?: string;
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmTerm
     */
    schemaElementKind?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    namespace?: string;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IEdmType
 */
export interface IEdmType {
    /**
     * 
     * @type {EdmTypeKind}
     * @memberof IEdmType
     */
    typeKind?: EdmTypeKind;
}
/**
 * 
 * @export
 * @interface IEdmTypeReference
 */
export interface IEdmTypeReference {
    /**
     * 
     * @type {boolean}
     * @memberof IEdmTypeReference
     */
    isNullable?: boolean;
    /**
     * 
     * @type {IEdmType}
     * @memberof IEdmTypeReference
     */
    definition?: IEdmType;
}
/**
 * 
 * @export
 * @interface IEdmVocabularyAnnotatable
 */
export interface IEdmVocabularyAnnotatable {
}
/**
 * 
 * @export
 * @interface IEdmVocabularyAnnotation
 */
export interface IEdmVocabularyAnnotation {
    /**
     * 
     * @type {string}
     * @memberof IEdmVocabularyAnnotation
     */
    qualifier?: string;
    /**
     * 
     * @type {IEdmTerm}
     * @memberof IEdmVocabularyAnnotation
     */
    term?: IEdmTerm;
    /**
     * 
     * @type {IEdmVocabularyAnnotatable}
     * @memberof IEdmVocabularyAnnotation
     */
    target?: IEdmVocabularyAnnotatable;
    /**
     * 
     * @type {IEdmExpression}
     * @memberof IEdmVocabularyAnnotation
     */
    value?: IEdmExpression;
}
/**
 * 
 * @export
 * @interface InvoiceCodingPost
 */
export interface InvoiceCodingPost {
    /**
     * 
     * @type {number}
     * @memberof InvoiceCodingPost
     */
    vchRLNNO?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCodingPost
     */
    pOLNNO?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCodingPost
     */
    qty?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCodingPost
     */
    trNUNITCSTAMT?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCodingPost
     */
    vchRLNDESC?: string;
}
/**
 * 
 * @export
 * @interface InvoicePost
 */
export interface InvoicePost {
    /**
     * 
     * @type {string}
     * @memberof InvoicePost
     */
    apprvDFL?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoicePost
     */
    fYCD?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoicePost
     */
    holDVCHRFL?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoicePost
     */
    invCDT?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoicePost
     */
    invCID?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoicePost
     */
    pDNO?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoicePost
     */
    pOID?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoicePost
     */
    suBPDNO?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePost
     */
    trNINVCAMT?: number;
    /**
     * 
     * @type {Array<InvoiceCodingPost>}
     * @memberof InvoicePost
     */
    invoiceCodingList?: Array<InvoiceCodingPost>;
}
export interface InvoiceAdditionalComment {
    /**
     * 
     * @type {string}
     * @memberof InvoiceAdditionalComment
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAdditionalComment
     */
    resourceGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceAdditionalComment
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceAdditionalComment
     */
    updatedOn?: Date;
}
/**
 * 
 * @export
 * @interface JobInfo
 */
export interface JobInfo {
    /**
     * 
     * @type {string}
     * @memberof JobInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JobInfo
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobInfo
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof JobInfo
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof JobInfo
     */
    department?: string;
    /**
     * 
     * @type {string}
     * @memberof JobInfo
     */
    division?: string;
    /**
     * 
     * @type {string}
     * @memberof JobInfo
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof JobInfo
     */
    reportsTo?: string;
}
/**
 * 
 * @export
 * @interface JobInfoAdditonalInformation
 */
export interface JobInfoAdditonalInformation {
    /**
     * 
     * @type {string}
     * @memberof JobInfoAdditonalInformation
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobInfoAdditonalInformation
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobInfoAdditonalInformation
     */
    status?: string;
    /**
     * 
     * @type {Array<Array<JobInfoAdditonalInformationItem>>}
     * @memberof JobInfoAdditonalInformation
     */
    jobInfoAdditionalInformationList?: Array<Array<JobInfoAdditonalInformationItem>>;
}
/**
 * 
 * @export
 * @interface JobInfoAdditonalInformationItem
 */
export interface JobInfoAdditonalInformationItem {
    /**
     * 
     * @type {number}
     * @memberof JobInfoAdditonalInformationItem
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof JobInfoAdditonalInformationItem
     */
    employeeId?: number;
    /**
     * 
     * @type {string}
     * @memberof JobInfoAdditonalInformationItem
     */
    customEffectiveDate2?: string;
    /**
     * 
     * @type {any}
     * @memberof JobInfoAdditonalInformationItem
     */
    customClientFacingTitle?: any;
    /**
     * 
     * @type {string}
     * @memberof JobInfoAdditonalInformationItem
     */
    customProject?: string;
    /**
     * 
     * @type {string}
     * @memberof JobInfoAdditonalInformationItem
     */
    customProjectDescription?: string;
    /**
     * 
     * @type {any}
     * @memberof JobInfoAdditonalInformationItem
     */
    customComment1?: any;
    /**
     * 
     * @type {string}
     * @memberof JobInfoAdditonalInformationItem
     */
    customRegion1?: string;
    /**
     * 
     * @type {string}
     * @memberof JobInfoAdditonalInformationItem
     */
    customCoreEmployees1?: string;
    /**
     * 
     * @type {string}
     * @memberof JobInfoAdditonalInformationItem
     */
    customWorkCarePOC1?: string;
    /**
     * 
     * @type {string}
     * @memberof JobInfoAdditonalInformationItem
     */
    customProjectDepartment?: string;
}
/**
 * 
 * @export
 * @interface JobInformation
 */
export interface JobInformation {
    /**
     * 
     * @type {string}
     * @memberof JobInformation
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobInformation
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobInformation
     */
    status?: string;
    /**
     * 
     * @type {Array<JobInfo>}
     * @memberof JobInformation
     */
    jobInformationList?: Array<JobInfo>;
}
/**
 * 
 * @export
 * @interface LLNSAdditionalInformation
 */
export interface LLNSAdditionalInformation {
    /**
     * 
     * @type {string}
     * @memberof LLNSAdditionalInformation
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LLNSAdditionalInformation
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LLNSAdditionalInformation
     */
    status?: string;
    /**
     * 
     * @type {Array<Array<LLNSAdditionalInformationItem>>}
     * @memberof LLNSAdditionalInformation
     */
    llnSAdditionalInformationList?: Array<Array<LLNSAdditionalInformationItem>>;
}
/**
 * 
 * @export
 * @interface LLNSAdditionalInformationItem
 */
export interface LLNSAdditionalInformationItem {
    /**
     * 
     * @type {number}
     * @memberof LLNSAdditionalInformationItem
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LLNSAdditionalInformationItem
     */
    employeeId?: number;
    /**
     * 
     * @type {string}
     * @memberof LLNSAdditionalInformationItem
     */
    customPAD?: string;
    /**
     * 
     * @type {string}
     * @memberof LLNSAdditionalInformationItem
     */
    customLLNSWorkLocations?: string;
    /**
     * 
     * @type {string}
     * @memberof LLNSAdditionalInformationItem
     */
    customWorkDuration?: string;
    /**
     * 
     * @type {string}
     * @memberof LLNSAdditionalInformationItem
     */
    customWorkSchedule1?: string;
    /**
     * 
     * @type {string}
     * @memberof LLNSAdditionalInformationItem
     */
    customRetiree?: string;
    /**
     * 
     * @type {string}
     * @memberof LLNSAdditionalInformationItem
     */
    customDDSLP?: string;
    /**
     * 
     * @type {string}
     * @memberof LLNSAdditionalInformationItem
     */
    customHoursLimit?: string;
    /**
     * 
     * @type {string}
     * @memberof LLNSAdditionalInformationItem
     */
    customMinOpsLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof LLNSAdditionalInformationItem
     */
    customDrivingPrivilege?: string;
    /**
     * 
     * @type {any}
     * @memberof LLNSAdditionalInformationItem
     */
    customAuthorizedRequestor?: any;
    /**
     * 
     * @type {any}
     * @memberof LLNSAdditionalInformationItem
     */
    customSupervisor1?: any;
    /**
     * 
     * @type {any}
     * @memberof LLNSAdditionalInformationItem
     */
    customComments2?: any;
    /**
     * 
     * @type {string}
     * @memberof LLNSAdditionalInformationItem
     */
    customEffectiveDate3?: string;
    /**
     * 
     * @type {any}
     * @memberof LLNSAdditionalInformationItem
     */
    customRequisitionNumber?: any;
    /**
     * 
     * @type {any}
     * @memberof LLNSAdditionalInformationItem
     */
    customBadgeID?: any;
    /**
     * 
     * @type {any}
     * @memberof LLNSAdditionalInformationItem
     */
    customPayrollNumber?: any;
    /**
     * 
     * @type {string}
     * @memberof LLNSAdditionalInformationItem
     */
    customLLNSJobCode?: string;
}
/**
 * 
 * @export
 * @interface LineItemAccountPost
 */
export interface LineItemAccountPost {
    /**
     * 
     * @type {string}
     * @memberof LineItemAccountPost
     */
    proJID?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemAccountPost
     */
    accTID?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemAccountPost
     */
    orGID?: string;
}
/**
 * 
 * @export
 * @interface ODataEntitySetInfo
 */
export interface ODataEntitySetInfo {
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataEntitySetInfo
     */
    typeAnnotation?: ODataTypeAnnotation;
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface ODataFunctionImportInfo
 */
export interface ODataFunctionImportInfo {
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataFunctionImportInfo
     */
    typeAnnotation?: ODataTypeAnnotation;
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface ODataServiceDocument
 */
export interface ODataServiceDocument {
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataServiceDocument
     */
    typeAnnotation?: ODataTypeAnnotation;
    /**
     * 
     * @type {Array<ODataEntitySetInfo>}
     * @memberof ODataServiceDocument
     */
    entitySets?: Array<ODataEntitySetInfo>;
    /**
     * 
     * @type {Array<ODataSingletonInfo>}
     * @memberof ODataServiceDocument
     */
    singletons?: Array<ODataSingletonInfo>;
    /**
     * 
     * @type {Array<ODataFunctionImportInfo>}
     * @memberof ODataServiceDocument
     */
    functionImports?: Array<ODataFunctionImportInfo>;
}
/**
 * 
 * @export
 * @interface ODataSingletonInfo
 */
export interface ODataSingletonInfo {
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataSingletonInfo
     */
    typeAnnotation?: ODataTypeAnnotation;
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface ODataTypeAnnotation
 */
export interface ODataTypeAnnotation {
    /**
     * 
     * @type {string}
     * @memberof ODataTypeAnnotation
     */
    typeName?: string;
}
/**
 * 
 * @export
 * @interface OvertimeRate
 */
export interface OvertimeRate {
    /**
     * 
     * @type {string}
     * @memberof OvertimeRate
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof OvertimeRate
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface POLineAccount
 */
export interface POLineAccount {
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    cpPurchaseOrderItemGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    cpLineItemAccountGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    pOID?: string;
    /**
     * 
     * @type {number}
     * @memberof POLineAccount
     */
    pOLNNO?: number;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    accTID?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    accTNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    orGID?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    orGNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    iteMID?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    mMPOLNACCTPROJID?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    proJNAME?: string;
    /**
     * 
     * @type {Date}
     * @memberof POLineAccount
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof POLineAccount
     */
    updatedOn?: Date;
}
/**
 * 
 * @export
 * @interface POLineItem
 */
export interface POLineItem {
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    pOID?: string;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    ordereDAMT?: number;
    /**
     * 
     * @type {Date}
     * @memberof POLineItem
     */
    poqstaTPOLNCTWPERFENDDT?: Date;
    /**
     * 
     * @type {Date}
     * @memberof POLineItem
     */
    poqstaTPOLNCTWPERFSTARTDT?: Date;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    pOLNDESC?: string;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    pOLNNO?: number;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    trNVCHRDAMT?: number;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    opeNAMT?: number;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    iteMID?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    pOLNUMCD?: string;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    ordereDAMTSUBPO?: number;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    cpPurchaseOrderGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    cpPurchaseOrderItemGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof POLineItem
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof POLineItem
     */
    updatedOn?: Date;
    /**
     * 
     * @type {Array<POLineAccount>}
     * @memberof POLineItem
     */
    lineAccountList?: Array<POLineAccount>;
}
/**
 * 
 * @export
 * @interface PurchaseOrderInvoice
 */
export interface PurchaseOrderInvoice {
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoice
     */
    pOID?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderInvoice
     */
    pOTOTAMT?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoice
     */
    sPOSTATUSTYPE?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoice
     */
    sPOTYPE?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderInvoice
     */
    unreLAMT?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoice
     */
    venDID?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoice
     */
    venDNAME?: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderInvoice
     */
    perFENDDT?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderInvoice
     */
    perFSTARTDT?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderInvoice
     */
    orDDT?: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoice
     */
    buyeRID?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoice
     */
    lasTFIRSTNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoice
     */
    companyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoice
     */
    cpPurchaseOrderGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderInvoice
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderInvoice
     */
    updatedOn?: Date;
    /**
     * 
     * @type {Array<POLineItem>}
     * @memberof PurchaseOrderInvoice
     */
    poLineItemList?: Array<POLineItem>;
}
/**
 * 
 * @export
 * @interface PurchaseOrderInvoiceSingleResult
 */
export interface PurchaseOrderInvoiceSingleResult {
    /**
     * 
     * @type {Array<PurchaseOrderInvoice>}
     * @memberof PurchaseOrderInvoiceSingleResult
     */
    queryable?: Array<PurchaseOrderInvoice>;
}
/**
 * 
 * @export
 * @interface PurchaseOrderItemPost
 */
export interface PurchaseOrderItemPost {
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    iteMID?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    iteMRVSNID?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderItemPost
     */
    orDQTY?: number;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderItemPost
     */
    oriGDUEDT?: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    pommaiNPOLNAUTOVCHRFL?: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderItemPost
     */
    pommaiNPOLNDESIREDDT?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderItemPost
     */
    pommaiNPOLNDUEDT?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderItemPost
     */
    pommaiNPOLNORDDT?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderItemPost
     */
    pommaiNPOLNPERFENDDT?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderItemPost
     */
    pommaiNPOLNPERFSTARTDT?: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    pOLNDESC?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderItemPost
     */
    pOLNNO?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    pOLNTX?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderItemPost
     */
    trNGRUNCSTAMT?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderItemPost
     */
    trNNETUNCSTAMT?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderItemPost
     */
    trNPOLNEXTAMT?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    pOLNUMCD?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    cerTOFCNFRMFL?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    qCREQDFL?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    srcEINSPFL?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    tranType?: string;
    /**
     * 
     * @type {Array<LineItemAccountPost>}
     * @memberof PurchaseOrderItemPost
     */
    lineItemAccountList?: Array<LineItemAccountPost>;
}
/**
 * 
 * @export
 * @interface PurchaseOrderPost
 */
export interface PurchaseOrderPost {
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    buyeRID?: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderPost
     */
    desireDDT?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderPost
     */
    duEDT?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderPost
     */
    orDDT?: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    sPOSTATUSTYPE?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    sPOTAXABLETYPE?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    sPOTYPE?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    termSDC?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    venDID?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    addRDC?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    pOID?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    companyCode?: string;
    /**
     * 
     * @type {Array<PurchaseOrderItemPost>}
     * @memberof PurchaseOrderPost
     */
    purchaseOrderItemList?: Array<PurchaseOrderItemPost>;
}
/**
 * 
 * @export
 * @interface Rate
 */
export interface Rate {
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    view?: string;
    /**
     * 
     * @type {Actions}
     * @memberof Role
     */
    action?: Actions;
}
/**
 * 
 * @export
 * @interface UserFavoriteApplication
 */
export interface UserFavoriteApplication {
    /**
     * 
     * @type {string}
     * @memberof UserFavoriteApplication
     */
    userFavoriteApplicationGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof UserFavoriteApplication
     */
    applicationId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserFavoriteApplication
     */
    userGuid?: string;
}
/**
 * 
 * @export
 * @interface Vendor
 */
export interface Vendor {
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    vendorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    vendorName?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    taxID?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    contactPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    vendorAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    venDID?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    venDNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    venDLONGNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    sVENDPOCNTLCD?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    aP1099TAXID?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    aPACCTSDESC?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    autOVCHRFL?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    cagECD?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    casHACCTSDESC?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    dunSNO?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    eDVCHPAYVENDFL?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    termSDC?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    venD1099NAME?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    citYNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    countrYCD?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    lN1ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    lN2ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    lN3ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    maiLSTATEDC?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    postaLCD?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    companyCode?: string;
    /**
     * 
     * @type {Array<VendorAddress>}
     * @memberof Vendor
     */
    vendorAddressList?: Array<VendorAddress>;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    aPCHKVENDID?: string;
    /**
     * 
     * @type {Date}
     * @memberof Vendor
     */
    entrYDTT?: Date;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    holDPMTFL?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    paYWHENPAIDFL?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    prnT1099FL?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    pRVENDFL?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    sMSUBCTRFL?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    sAP1099TYPECD?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    useRID?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    venDAPPRVL?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    venDNAME1?: string;
}
/**
 * 
 * @export
 * @interface VendorAddress
 */
export interface VendorAddress {
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    activEFL?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    addRDC?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDCITYNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDCOUNTRYCD?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDLN1ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDLN2ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDLN3ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDMAILSTATEDC?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDPOSTALCD?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    sORDADDRCD?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    sPMTADDRCD?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    citYNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    countrYCD?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    lN1ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    lN2ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    lN3ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    maiLSTATEDC?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    postaLCD?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    emaiLID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    phonEID?: string;
    /**
     * 
     * @type {Array<VendorContact>}
     * @memberof VendorAddress
     */
    contactList?: Array<VendorContact>;
}
/**
 * 
 * @export
 * @interface VendorAddressPost
 */
export interface VendorAddressPost {
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    addRDC?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    citYNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    countrYCD?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    emaiLID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    lN1ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    lN2ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    lN3ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    maiLSTATEDC?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    otHPHONEID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    phonEID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    postaLCD?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    sORDADDRCD?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    sPMTADDRCD?: string;
    /**
     * 
     * @type {Array<VendorContactPost>}
     * @memberof VendorAddressPost
     */
    contactList?: Array<VendorContactPost>;
}
/**
 * 
 * @export
 * @interface VendorContact
 */
export interface VendorContact {
    /**
     * 
     * @type {string}
     * @memberof VendorContact
     */
    cntacTFIRSTNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContact
     */
    cntacTLASTNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContact
     */
    cntacTTITLENAME?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContact
     */
    cpmvenDVENDADDRCNTACTEMAILID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContact
     */
    cpmvenDVENDADDRCNTACTOTHPHONEID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContact
     */
    cpmvenDVENDADDRCNTACTPHONEID?: string;
    /**
     * 
     * @type {number}
     * @memberof VendorContact
     */
    cpmvenDVENDADDRCNTACTSEQNO?: number;
    /**
     * 
     * @type {string}
     * @memberof VendorContact
     */
    notes?: string;
}
/**
 * 
 * @export
 * @interface VendorContactPost
 */
export interface VendorContactPost {
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    cntacTFIRSTNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    cntacTLASTNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    cntacTTITLENAME?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    cpmvenDVENDADDRCNTACTEMAILID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    cpmvenDVENDADDRCNTACTPHONEID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    cpmvenDVENDADDRCNTACTSEQNO?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    tranType?: string;
}
/**
 * 
 * @export
 * @interface VendorPost
 */
export interface VendorPost {
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    aP1099TAXID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    aPACCTSDESC?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    autOVCHRFL?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    cagECD?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    casHACCTSDESC?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    dunSNO?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    eDVCHPAYVENDFL?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    termSDC?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    venD1099NAME?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    venDID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    venDLONGNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    venDNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    companyCode?: string;
    /**
     * 
     * @type {Array<VendorAddressPost>}
     * @memberof VendorPost
     */
    vendorAddressList?: Array<VendorAddressPost>;
}
/**
 * 
 * @export
 * @interface VendorSingleResult
 */
export interface VendorSingleResult {
    /**
     * 
     * @type {Array<Vendor>}
     * @memberof VendorSingleResult
     */
    queryable?: Array<Vendor>;
}
/**
 * ApplicationApi - fetch parameter creator
 * @export
 */
export const ApplicationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationFavoriteGet(filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Application/favorite`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationGet(filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Application`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationFavoriteGet(filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ApplicationApiFetchParamCreator(configuration).applicationFavoriteGet(filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationGet(filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ApplicationApiFetchParamCreator(configuration).applicationGet(filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationFavoriteGet(filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ApplicationApiFp(configuration).applicationFavoriteGet(filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationGet(filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ApplicationApiFp(configuration).applicationGet(filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationFavoriteGet(filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ApplicationApiFp(this.configuration).applicationFavoriteGet(filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationGet(filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ApplicationApiFp(this.configuration).applicationGet(filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

}
/**
 * AuthorizationApi - fetch parameter creator
 * @export
 */
export const AuthorizationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [loggedonuser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationInvoiceIntegrateProjectNumberGet(projectNumber: string, loggedonuser?: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectNumber' is not null or undefined
            if (projectNumber === null || projectNumber === undefined) {
                throw new RequiredError('projectNumber','Required parameter projectNumber was null or undefined when calling authorizationInvoiceIntegrateProjectNumberGet.');
            }
            const localVarPath = `/Authorization/invoice/integrate/{projectNumber}`
                .replace(`{${"projectNumber"}}`, encodeURIComponent(String(projectNumber)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [loggedonuser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationInvoiceProjectNumberGet(projectNumber: string, loggedonuser?: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectNumber' is not null or undefined
            if (projectNumber === null || projectNumber === undefined) {
                throw new RequiredError('projectNumber','Required parameter projectNumber was null or undefined when calling authorizationInvoiceProjectNumberGet.');
            }
            const localVarPath = `/Authorization/invoice/{projectNumber}`
                .replace(`{${"projectNumber"}}`, encodeURIComponent(String(projectNumber)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [loggedonuser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationProjectNumberGet(projectNumber: string, loggedonuser?: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectNumber' is not null or undefined
            if (projectNumber === null || projectNumber === undefined) {
                throw new RequiredError('projectNumber','Required parameter projectNumber was null or undefined when calling authorizationProjectNumberGet.');
            }
            const localVarPath = `/Authorization/{projectNumber}`
                .replace(`{${"projectNumber"}}`, encodeURIComponent(String(projectNumber)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {Array<Role>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationUsernamePost(username: string, body?: Array<Role>, options: any = {}): FetchArgs {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling authorizationUsernamePost.');
            }
            const localVarPath = `/Authorization/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;Role&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsPriviledgedUserPost(body?: string, options: any = {}): FetchArgs {
            const localVarPath = `/User/IsPriviledgedUser`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsPriviledgedUserProjectNumberPost(projectNumber: string, body?: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectNumber' is not null or undefined
            if (projectNumber === null || projectNumber === undefined) {
                throw new RequiredError('projectNumber','Required parameter projectNumber was null or undefined when calling userIsPriviledgedUserProjectNumberPost.');
            }
            const localVarPath = `/User/IsPriviledgedUser/{projectNumber}`
                .replace(`{${"projectNumber"}}`, encodeURIComponent(String(projectNumber)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsPriviledgedUsersPost(body?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/User/IsPriviledgedUsers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsPriviledgedUsersProjectNumberPost(projectNumber: string, body?: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'projectNumber' is not null or undefined
            if (projectNumber === null || projectNumber === undefined) {
                throw new RequiredError('projectNumber','Required parameter projectNumber was null or undefined when calling userIsPriviledgedUsersProjectNumberPost.');
            }
            const localVarPath = `/User/IsPriviledgedUsers/{projectNumber}`
                .replace(`{${"projectNumber"}}`, encodeURIComponent(String(projectNumber)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsSuperUserGet(loggedonuser?: string, options: any = {}): FetchArgs {
            const localVarPath = `/User/IsSuperUser`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorizationApi - functional programming interface
 * @export
 */
export const AuthorizationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [loggedonuser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationInvoiceIntegrateProjectNumberGet(projectNumber: string, loggedonuser?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthorizationApiFetchParamCreator(configuration).authorizationInvoiceIntegrateProjectNumberGet(projectNumber, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [loggedonuser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationInvoiceProjectNumberGet(projectNumber: string, loggedonuser?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthorizationApiFetchParamCreator(configuration).authorizationInvoiceProjectNumberGet(projectNumber, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [loggedonuser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationProjectNumberGet(projectNumber: string, loggedonuser?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthorizationApiFetchParamCreator(configuration).authorizationProjectNumberGet(projectNumber, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {Array<Role>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationUsernamePost(username: string, body?: Array<Role>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthorizationApiFetchParamCreator(configuration).authorizationUsernamePost(username, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsPriviledgedUserPost(body?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthorizationApiFetchParamCreator(configuration).userIsPriviledgedUserPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsPriviledgedUserProjectNumberPost(projectNumber: string, body?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthorizationApiFetchParamCreator(configuration).userIsPriviledgedUserProjectNumberPost(projectNumber, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsPriviledgedUsersPost(body?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthorizationApiFetchParamCreator(configuration).userIsPriviledgedUsersPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsPriviledgedUsersProjectNumberPost(projectNumber: string, body?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthorizationApiFetchParamCreator(configuration).userIsPriviledgedUsersProjectNumberPost(projectNumber, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsSuperUserGet(loggedonuser?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthorizationApiFetchParamCreator(configuration).userIsSuperUserGet(loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthorizationApi - factory interface
 * @export
 */
export const AuthorizationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [loggedonuser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationInvoiceIntegrateProjectNumberGet(projectNumber: string, loggedonuser?: string, options?: any) {
            return AuthorizationApiFp(configuration).authorizationInvoiceIntegrateProjectNumberGet(projectNumber, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [loggedonuser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationInvoiceProjectNumberGet(projectNumber: string, loggedonuser?: string, options?: any) {
            return AuthorizationApiFp(configuration).authorizationInvoiceProjectNumberGet(projectNumber, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [loggedonuser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationProjectNumberGet(projectNumber: string, loggedonuser?: string, options?: any) {
            return AuthorizationApiFp(configuration).authorizationProjectNumberGet(projectNumber, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} username 
         * @param {Array<Role>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationUsernamePost(username: string, body?: Array<Role>, options?: any) {
            return AuthorizationApiFp(configuration).authorizationUsernamePost(username, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsPriviledgedUserPost(body?: string, options?: any) {
            return AuthorizationApiFp(configuration).userIsPriviledgedUserPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsPriviledgedUserProjectNumberPost(projectNumber: string, body?: string, options?: any) {
            return AuthorizationApiFp(configuration).userIsPriviledgedUserProjectNumberPost(projectNumber, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsPriviledgedUsersPost(body?: Array<string>, options?: any) {
            return AuthorizationApiFp(configuration).userIsPriviledgedUsersPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsPriviledgedUsersProjectNumberPost(projectNumber: string, body?: Array<string>, options?: any) {
            return AuthorizationApiFp(configuration).userIsPriviledgedUsersProjectNumberPost(projectNumber, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsSuperUserGet(loggedonuser?: string, options?: any) {
            return AuthorizationApiFp(configuration).userIsSuperUserGet(loggedonuser, options)(fetch, basePath);
        },
    };
};

/**
 * AuthorizationApi - object-oriented interface
 * @export
 * @class AuthorizationApi
 * @extends {BaseAPI}
 */
export class AuthorizationApi extends BaseAPI {
    /**
     * 
     * @param {string} projectNumber 
     * @param {string} [loggedonuser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public authorizationInvoiceIntegrateProjectNumberGet(projectNumber: string, loggedonuser?: string, options?: any) {
        return AuthorizationApiFp(this.configuration).authorizationInvoiceIntegrateProjectNumberGet(projectNumber, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} projectNumber 
     * @param {string} [loggedonuser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public authorizationInvoiceProjectNumberGet(projectNumber: string, loggedonuser?: string, options?: any) {
        return AuthorizationApiFp(this.configuration).authorizationInvoiceProjectNumberGet(projectNumber, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} projectNumber 
     * @param {string} [loggedonuser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public authorizationProjectNumberGet(projectNumber: string, loggedonuser?: string, options?: any) {
        return AuthorizationApiFp(this.configuration).authorizationProjectNumberGet(projectNumber, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} username 
     * @param {Array<Role>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public authorizationUsernamePost(username: string, body?: Array<Role>, options?: any) {
        return AuthorizationApiFp(this.configuration).authorizationUsernamePost(username, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public userIsPriviledgedUserPost(body?: string, options?: any) {
        return AuthorizationApiFp(this.configuration).userIsPriviledgedUserPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} projectNumber 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public userIsPriviledgedUserProjectNumberPost(projectNumber: string, body?: string, options?: any) {
        return AuthorizationApiFp(this.configuration).userIsPriviledgedUserProjectNumberPost(projectNumber, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<string>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public userIsPriviledgedUsersPost(body?: Array<string>, options?: any) {
        return AuthorizationApiFp(this.configuration).userIsPriviledgedUsersPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} projectNumber 
     * @param {Array<string>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public userIsPriviledgedUsersProjectNumberPost(projectNumber: string, body?: Array<string>, options?: any) {
        return AuthorizationApiFp(this.configuration).userIsPriviledgedUsersProjectNumberPost(projectNumber, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [loggedonuser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public userIsSuperUserGet(loggedonuser?: string, options?: any) {
        return AuthorizationApiFp(this.configuration).userIsSuperUserGet(loggedonuser, options)(this.fetch, this.basePath);
    }

}
/**
 * BambooEmployeeApi - fetch parameter creator
 * @export
 */
export const BambooEmployeeApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeGet(options: any = {}): FetchArgs {
            const localVarPath = `/Bamboo/Employee`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        bambooEmployeeListGet(options: any = {}): FetchArgs {
            const localVarPath = `/Bamboo/Employee/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdAdditionalInformationGet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling bambooEmployeeIdAdditionalInformationGet.');
            }
            const localVarPath = `/Bamboo/Employee/{id}/AdditionalInformation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdCompensationAdditionalInformationGet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling bambooEmployeeIdCompensationAdditionalInformationGet.');
            }
            const localVarPath = `/Bamboo/Employee/{id}/CompensationAdditionalInformation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdCompensationGet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling bambooEmployeeIdCompensationGet.');
            }
            const localVarPath = `/Bamboo/Employee/{id}/Compensation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdEmploymentStatusGet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling bambooEmployeeIdEmploymentStatusGet.');
            }
            const localVarPath = `/Bamboo/Employee/{id}/EmploymentStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdGet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling bambooEmployeeIdGet.');
            }
            const localVarPath = `/Bamboo/Employee/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdJobInformationAdditionalInformationGet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling bambooEmployeeIdJobInformationAdditionalInformationGet.');
            }
            const localVarPath = `/Bamboo/Employee/{id}/JobInformationAdditionalInformation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdJobInformationGet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling bambooEmployeeIdJobInformationGet.');
            }
            const localVarPath = `/Bamboo/Employee/{id}/JobInformation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdLLNSAdditionalInformationGet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling bambooEmployeeIdLLNSAdditionalInformationGet.');
            }
            const localVarPath = `/Bamboo/Employee/{id}/LLNSAdditionalInformation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BambooEmployeeApi - functional programming interface
 * @export
 */
export const BambooEmployeeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Directory> {
            const localVarFetchArgs = BambooEmployeeApiFetchParamCreator(configuration).bambooEmployeeGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeListGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = BambooEmployeeApiFetchParamCreator(configuration).bambooEmployeeListGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdAdditionalInformationGet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdditionalInformation> {
            const localVarFetchArgs = BambooEmployeeApiFetchParamCreator(configuration).bambooEmployeeIdAdditionalInformationGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdCompensationAdditionalInformationGet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompensationAdditionalInformation> {
            const localVarFetchArgs = BambooEmployeeApiFetchParamCreator(configuration).bambooEmployeeIdCompensationAdditionalInformationGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdCompensationGet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Compensation> {
            const localVarFetchArgs = BambooEmployeeApiFetchParamCreator(configuration).bambooEmployeeIdCompensationGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdEmploymentStatusGet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmployeeStatuses> {
            const localVarFetchArgs = BambooEmployeeApiFetchParamCreator(configuration).bambooEmployeeIdEmploymentStatusGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdGet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Employee> {
            const localVarFetchArgs = BambooEmployeeApiFetchParamCreator(configuration).bambooEmployeeIdGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdJobInformationAdditionalInformationGet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobInfoAdditonalInformation> {
            const localVarFetchArgs = BambooEmployeeApiFetchParamCreator(configuration).bambooEmployeeIdJobInformationAdditionalInformationGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdJobInformationGet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobInformation> {
            const localVarFetchArgs = BambooEmployeeApiFetchParamCreator(configuration).bambooEmployeeIdJobInformationGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdLLNSAdditionalInformationGet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LLNSAdditionalInformation> {
            const localVarFetchArgs = BambooEmployeeApiFetchParamCreator(configuration).bambooEmployeeIdLLNSAdditionalInformationGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BambooEmployeeApi - factory interface
 * @export
 */
export const BambooEmployeeApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeGet(options?: any) {
            return BambooEmployeeApiFp(configuration).bambooEmployeeGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdAdditionalInformationGet(id: string, options?: any) {
            return BambooEmployeeApiFp(configuration).bambooEmployeeIdAdditionalInformationGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdCompensationAdditionalInformationGet(id: string, options?: any) {
            return BambooEmployeeApiFp(configuration).bambooEmployeeIdCompensationAdditionalInformationGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdCompensationGet(id: string, options?: any) {
            return BambooEmployeeApiFp(configuration).bambooEmployeeIdCompensationGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdEmploymentStatusGet(id: string, options?: any) {
            return BambooEmployeeApiFp(configuration).bambooEmployeeIdEmploymentStatusGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdGet(id: string, options?: any) {
            return BambooEmployeeApiFp(configuration).bambooEmployeeIdGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdJobInformationAdditionalInformationGet(id: string, options?: any) {
            return BambooEmployeeApiFp(configuration).bambooEmployeeIdJobInformationAdditionalInformationGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdJobInformationGet(id: string, options?: any) {
            return BambooEmployeeApiFp(configuration).bambooEmployeeIdJobInformationGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bambooEmployeeIdLLNSAdditionalInformationGet(id: string, options?: any) {
            return BambooEmployeeApiFp(configuration).bambooEmployeeIdLLNSAdditionalInformationGet(id, options)(fetch, basePath);
        },
    };
};

/**
 * BambooEmployeeApi - object-oriented interface
 * @export
 * @class BambooEmployeeApi
 * @extends {BaseAPI}
 */
export class BambooEmployeeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BambooEmployeeApi
     */
    public bambooEmployeeGet(options?: any) {
        return BambooEmployeeApiFp(this.configuration).bambooEmployeeGet(options)(this.fetch, this.basePath);
    }
    public bambooEmployeeListGet(options?: any) {
        return BambooEmployeeApiFp(this.configuration).bambooEmployeeListGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BambooEmployeeApi
     */
    public bambooEmployeeIdAdditionalInformationGet(id: string, options?: any) {
        return BambooEmployeeApiFp(this.configuration).bambooEmployeeIdAdditionalInformationGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BambooEmployeeApi
     */
    public bambooEmployeeIdCompensationAdditionalInformationGet(id: string, options?: any) {
        return BambooEmployeeApiFp(this.configuration).bambooEmployeeIdCompensationAdditionalInformationGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BambooEmployeeApi
     */
    public bambooEmployeeIdCompensationGet(id: string, options?: any) {
        return BambooEmployeeApiFp(this.configuration).bambooEmployeeIdCompensationGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BambooEmployeeApi
     */
    public bambooEmployeeIdEmploymentStatusGet(id: string, options?: any) {
        return BambooEmployeeApiFp(this.configuration).bambooEmployeeIdEmploymentStatusGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BambooEmployeeApi
     */
    public bambooEmployeeIdGet(id: string, options?: any) {
        return BambooEmployeeApiFp(this.configuration).bambooEmployeeIdGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BambooEmployeeApi
     */
    public bambooEmployeeIdJobInformationAdditionalInformationGet(id: string, options?: any) {
        return BambooEmployeeApiFp(this.configuration).bambooEmployeeIdJobInformationAdditionalInformationGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BambooEmployeeApi
     */
    public bambooEmployeeIdJobInformationGet(id: string, options?: any) {
        return BambooEmployeeApiFp(this.configuration).bambooEmployeeIdJobInformationGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BambooEmployeeApi
     */
    public bambooEmployeeIdLLNSAdditionalInformationGet(id: string, options?: any) {
        return BambooEmployeeApiFp(this.configuration).bambooEmployeeIdLLNSAdditionalInformationGet(id, options)(this.fetch, this.basePath);
    }

}
/**
 * CompanyApi - fetch parameter creator
 * @export
 */
export const CompanyApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyAllGet(loggedonuser?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Company/All`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCodeGet(code: string, options: any = {}): FetchArgs {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling companyCodeGet.');
            }
            const localVarPath = `/Company/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [chargeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGet(loggedonuser?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, chargeType?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Company`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (chargeType !== undefined) {
                localVarQueryParameter['chargeType'] = chargeType;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyAllGet(loggedonuser?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CompanyApiFetchParamCreator(configuration).companyAllGet(loggedonuser, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCodeGet(code: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompanySingleResult> {
            const localVarFetchArgs = CompanyApiFetchParamCreator(configuration).companyCodeGet(code, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [chargeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGet(loggedonuser?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, chargeType?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CompanyApiFetchParamCreator(configuration).companyGet(loggedonuser, filter, top, skip, take, orderBy, chargeType, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyAllGet(loggedonuser?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return CompanyApiFp(configuration).companyAllGet(loggedonuser, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCodeGet(code: string, options?: any) {
            return CompanyApiFp(configuration).companyCodeGet(code, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [chargeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGet(loggedonuser?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, chargeType?: string, options?: any) {
            return CompanyApiFp(configuration).companyGet(loggedonuser, filter, top, skip, take, orderBy, chargeType, options)(fetch, basePath);
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {string} [loggedonuser] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyAllGet(loggedonuser?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return CompanyApiFp(this.configuration).companyAllGet(loggedonuser, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyCodeGet(code: string, options?: any) {
        return CompanyApiFp(this.configuration).companyCodeGet(code, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [loggedonuser] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {string} [chargeType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyGet(loggedonuser?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, chargeType?: string, options?: any) {
        return CompanyApiFp(this.configuration).companyGet(loggedonuser, filter, top, skip, take, orderBy, chargeType, options)(this.fetch, this.basePath);
    }

}
/**
 * DelegateApi - fetch parameter creator
 * @export
 */
export const DelegateApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} bambooHRID 
         * @param {string} [applicationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designatorBambooHRIDGet(bambooHRID: string, applicationType?: string, options: any = {}): FetchArgs {
            // verify required parameter 'bambooHRID' is not null or undefined
            if (bambooHRID === null || bambooHRID === undefined) {
                throw new RequiredError('bambooHRID','Required parameter bambooHRID was null or undefined when calling designatorBambooHRIDGet.');
            }
            const localVarPath = `/Designator/{bambooHRID}`
                .replace(`{${"bambooHRID"}}`, encodeURIComponent(String(bambooHRID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (applicationType !== undefined) {
                localVarQueryParameter['applicationType'] = applicationType;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bambooHRID 
         * @param {string} [applicationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designeeBambooHRIDGet(bambooHRID: string, applicationType?: string, options: any = {}): FetchArgs {
            // verify required parameter 'bambooHRID' is not null or undefined
            if (bambooHRID === null || bambooHRID === undefined) {
                throw new RequiredError('bambooHRID','Required parameter bambooHRID was null or undefined when calling designeeBambooHRIDGet.');
            }
            const localVarPath = `/Designee/{bambooHRID}`
                .replace(`{${"bambooHRID"}}`, encodeURIComponent(String(bambooHRID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (applicationType !== undefined) {
                localVarQueryParameter['applicationType'] = applicationType;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DelegateApi - functional programming interface
 * @export
 */
export const DelegateApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} bambooHRID 
         * @param {string} [applicationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designatorBambooHRIDGet(bambooHRID: string, applicationType?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DelegateApiFetchParamCreator(configuration).designatorBambooHRIDGet(bambooHRID, applicationType, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} bambooHRID 
         * @param {string} [applicationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designeeBambooHRIDGet(bambooHRID: string, applicationType?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DelegateApiFetchParamCreator(configuration).designeeBambooHRIDGet(bambooHRID, applicationType, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DelegateApi - factory interface
 * @export
 */
export const DelegateApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} bambooHRID 
         * @param {string} [applicationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designatorBambooHRIDGet(bambooHRID: string, applicationType?: string, options?: any) {
            return DelegateApiFp(configuration).designatorBambooHRIDGet(bambooHRID, applicationType, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} bambooHRID 
         * @param {string} [applicationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designeeBambooHRIDGet(bambooHRID: string, applicationType?: string, options?: any) {
            return DelegateApiFp(configuration).designeeBambooHRIDGet(bambooHRID, applicationType, options)(fetch, basePath);
        },
    };
};

/**
 * DelegateApi - object-oriented interface
 * @export
 * @class DelegateApi
 * @extends {BaseAPI}
 */
export class DelegateApi extends BaseAPI {
    /**
     * 
     * @param {string} bambooHRID 
     * @param {string} [applicationType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DelegateApi
     */
    public designatorBambooHRIDGet(bambooHRID: string, applicationType?: string, options?: any) {
        return DelegateApiFp(this.configuration).designatorBambooHRIDGet(bambooHRID, applicationType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} bambooHRID 
     * @param {string} [applicationType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DelegateApi
     */
    public designeeBambooHRIDGet(bambooHRID: string, applicationType?: string, options?: any) {
        return DelegateApiFp(this.configuration).designeeBambooHRIDGet(bambooHRID, applicationType, options)(this.fetch, this.basePath);
    }

}
/**
 * InvoiceApi - fetch parameter creator
 * @export
 */
export const InvoiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
        * 
        * @param {string} invoiceGuid 
        * @param {string} loggedonuser 
        * @param {InvoiceAdditionalComment} [body] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        invoiceAddCommentInvoiceGuidPost(invoiceGuid: string, loggedonuser: string, body?: InvoiceAdditionalComment, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceGuid' is not null or undefined
            if (invoiceGuid === null || invoiceGuid === undefined) {
                throw new RequiredError('invoiceGuid', 'Required parameter invoiceGuid was null or undefined when calling invoiceAddCommentInvoiceGuidPost.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceAddCommentInvoiceGuidPost.');
            }
            const localVarPath = `/Invoice/AddComment/{invoiceGuid}`
                .replace(`{${"invoiceGuid"}}`, encodeURIComponent(String(invoiceGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoiceAdditionalComment" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGet(companyCode?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Invoice`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InvoicePost} [body] 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePost(body?: InvoicePost, companyCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Invoice`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoicePost" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InvoicePost} [body] 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePut(body?: InvoicePost, companyCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Invoice`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoicePost" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    return {
        /**
        * 
        * @param {string} invoiceGuid 
        * @param {string} loggedonuser 
        * @param {InvoiceAdditionalComment} [body] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        invoiceAddCommentInvoiceGuidPost(invoiceGuid: string, loggedonuser: string, body?: InvoiceAdditionalComment, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceAddCommentInvoiceGuidPost(invoiceGuid, loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },

        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGet(companyCode?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceGet(companyCode, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {InvoicePost} [body] 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePost(body?: InvoicePost, companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoicePost(body, companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {InvoicePost} [body] 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePut(body?: InvoicePost, companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoicePut(body, companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
        * 
        * @param {string} invoiceGuid 
        * @param {string} loggedonuser 
        * @param {InvoiceAdditionalComment} [body] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        invoiceAddCommentInvoiceGuidPost(invoiceGuid: string, loggedonuser: string, body?: InvoiceAdditionalComment, options?: any) {
            return InvoiceApiFp(configuration).invoiceAddCommentInvoiceGuidPost(invoiceGuid, loggedonuser, body, options)(fetch, basePath);
        },

        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGet(companyCode?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceGet(companyCode, search, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {InvoicePost} [body] 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePost(body?: InvoicePost, companyCode?: string, options?: any) {
            return InvoiceApiFp(configuration).invoicePost(body, companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {InvoicePost} [body] 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePut(body?: InvoicePost, companyCode?: string, options?: any) {
            return InvoiceApiFp(configuration).invoicePut(body, companyCode, options)(fetch, basePath);
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
   * 
   * @param {string} invoiceGuid 
   * @param {string} loggedonuser 
   * @param {InvoiceAdditionalComment} [body] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvoiceApi
   */
    public invoiceAddCommentInvoiceGuidPost(invoiceGuid: string, loggedonuser: string, body?: InvoiceAdditionalComment, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceAddCommentInvoiceGuidPost(invoiceGuid, loggedonuser, body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceGet(companyCode?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceGet(companyCode, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {InvoicePost} [body] 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoicePost(body?: InvoicePost, companyCode?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoicePost(body, companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {InvoicePost} [body] 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoicePut(body?: InvoicePost, companyCode?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoicePut(body, companyCode, options)(this.fetch, this.basePath);
    }

}
/**
 * MetadataApi - fetch parameter creator
 * @export
 */
export const MetadataApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odata(options: any = {}): FetchArgs {
            const localVarPath = `/odata`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odatametadata(options: any = {}): FetchArgs {
            const localVarPath = `/odata/$metadata`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetadataApi - functional programming interface
 * @export
 */
export const MetadataApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odata(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ODataServiceDocument> {
            const localVarFetchArgs = MetadataApiFetchParamCreator(configuration).odata(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odatametadata(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IEdmModel> {
            const localVarFetchArgs = MetadataApiFetchParamCreator(configuration).odatametadata(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MetadataApi - factory interface
 * @export
 */
export const MetadataApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odata(options?: any) {
            return MetadataApiFp(configuration).odata(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odatametadata(options?: any) {
            return MetadataApiFp(configuration).odatametadata(options)(fetch, basePath);
        },
    };
};

/**
 * MetadataApi - object-oriented interface
 * @export
 * @class MetadataApi
 * @extends {BaseAPI}
 */
export class MetadataApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public odata(options?: any) {
        return MetadataApiFp(this.configuration).odata(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public odatametadata(options?: any) {
        return MetadataApiFp(this.configuration).odatametadata(options)(this.fetch, this.basePath);
    }

}
/**
 * NotificationApi - fetch parameter creator
 * @export
 */
export const NotificationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationGet(loggedonuser?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Notification`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationGet(loggedonuser?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).notificationGet(loggedonuser, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationGet(loggedonuser?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return NotificationApiFp(configuration).notificationGet(loggedonuser, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {string} [loggedonuser] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationGet(loggedonuser?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return NotificationApiFp(this.configuration).notificationGet(loggedonuser, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

}
/**
 * ProjectApi - fetch parameter creator
 * @export
 */
export const ProjectApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectChargeCodesUserGuidGet(userGuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'userGuid' is not null or undefined
            if (userGuid === null || userGuid === undefined) {
                throw new RequiredError('userGuid','Required parameter userGuid was null or undefined when calling projectChargeCodesUserGuidGet.');
            }
            const localVarPath = `/Project/ChargeCodes/{userGuid}`
                .replace(`{${"userGuid"}}`, encodeURIComponent(String(userGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDetailProjectNumberGet(projectNumber: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectNumber' is not null or undefined
            if (projectNumber === null || projectNumber === undefined) {
                throw new RequiredError('projectNumber','Required parameter projectNumber was null or undefined when calling projectDetailProjectNumberGet.');
            }
            const localVarPath = `/Project/Detail/{projectNumber}`
                .replace(`{${"projectNumber"}}`, encodeURIComponent(String(projectNumber)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Project`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {string} [chargeType] 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectListByUserGet(loggedonuser?: string, chargeType?: string, companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Project/ListByUser`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (chargeType !== undefined) {
                localVarQueryParameter['chargeType'] = chargeType;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [chargeType] 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectListGet(chargeType?: string, companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Project/List`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (chargeType !== undefined) {
                localVarQueryParameter['chargeType'] = chargeType;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} chargeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectNumberChargeCodeChargeCodeGet(chargeCode: string, options: any = {}): FetchArgs {
            // verify required parameter 'chargeCode' is not null or undefined
            if (chargeCode === null || chargeCode === undefined) {
                throw new RequiredError('chargeCode','Required parameter chargeCode was null or undefined when calling projectProjectNumberChargeCodeChargeCodeGet.');
            }
            const localVarPath = `/Project/projectNumber/ChargeCode/{chargeCode}`
                .replace(`{${"chargeCode"}}`, encodeURIComponent(String(chargeCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectNumbersGet(options: any = {}): FetchArgs {
            const localVarPath = `/Project/ProjectNumbers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUserGuidGet(userGuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'userGuid' is not null or undefined
            if (userGuid === null || userGuid === undefined) {
                throw new RequiredError('userGuid','Required parameter userGuid was null or undefined when calling projectUserGuidGet.');
            }
            const localVarPath = `/Project/{userGuid}`
                .replace(`{${"userGuid"}}`, encodeURIComponent(String(userGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectChargeCodesUserGuidGet(userGuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProjectApiFetchParamCreator(configuration).projectChargeCodesUserGuidGet(userGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDetailProjectNumberGet(projectNumber: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProjectApiFetchParamCreator(configuration).projectDetailProjectNumberGet(projectNumber, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProjectApiFetchParamCreator(configuration).projectGet(companyCode, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {string} [chargeType] 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectListByUserGet(loggedonuser?: string, chargeType?: string, companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProjectApiFetchParamCreator(configuration).projectListByUserGet(loggedonuser, chargeType, companyCode, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [chargeType] 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectListGet(chargeType?: string, companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProjectApiFetchParamCreator(configuration).projectListGet(chargeType, companyCode, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} chargeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectNumberChargeCodeChargeCodeGet(chargeCode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProjectApiFetchParamCreator(configuration).projectProjectNumberChargeCodeChargeCodeGet(chargeCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectNumbersGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProjectApiFetchParamCreator(configuration).projectProjectNumbersGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} userGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUserGuidGet(userGuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProjectApiFetchParamCreator(configuration).projectUserGuidGet(userGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} userGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectChargeCodesUserGuidGet(userGuid: string, options?: any) {
            return ProjectApiFp(configuration).projectChargeCodesUserGuidGet(userGuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} projectNumber 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDetailProjectNumberGet(projectNumber: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ProjectApiFp(configuration).projectDetailProjectNumberGet(projectNumber, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ProjectApiFp(configuration).projectGet(companyCode, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [loggedonuser] 
         * @param {string} [chargeType] 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectListByUserGet(loggedonuser?: string, chargeType?: string, companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ProjectApiFp(configuration).projectListByUserGet(loggedonuser, chargeType, companyCode, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [chargeType] 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectListGet(chargeType?: string, companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ProjectApiFp(configuration).projectListGet(chargeType, companyCode, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} chargeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectNumberChargeCodeChargeCodeGet(chargeCode: string, options?: any) {
            return ProjectApiFp(configuration).projectProjectNumberChargeCodeChargeCodeGet(chargeCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectNumbersGet(options?: any) {
            return ProjectApiFp(configuration).projectProjectNumbersGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} userGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUserGuidGet(userGuid: string, options?: any) {
            return ProjectApiFp(configuration).projectUserGuidGet(userGuid, options)(fetch, basePath);
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @param {string} userGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectChargeCodesUserGuidGet(userGuid: string, options?: any) {
        return ProjectApiFp(this.configuration).projectChargeCodesUserGuidGet(userGuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} projectNumber 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDetailProjectNumberGet(projectNumber: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ProjectApiFp(this.configuration).projectDetailProjectNumberGet(projectNumber, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ProjectApiFp(this.configuration).projectGet(companyCode, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [loggedonuser] 
     * @param {string} [chargeType] 
     * @param {string} [companyCode] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectListByUserGet(loggedonuser?: string, chargeType?: string, companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ProjectApiFp(this.configuration).projectListByUserGet(loggedonuser, chargeType, companyCode, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [chargeType] 
     * @param {string} [companyCode] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectListGet(chargeType?: string, companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ProjectApiFp(this.configuration).projectListGet(chargeType, companyCode, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} chargeCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectProjectNumberChargeCodeChargeCodeGet(chargeCode: string, options?: any) {
        return ProjectApiFp(this.configuration).projectProjectNumberChargeCodeChargeCodeGet(chargeCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectProjectNumbersGet(options?: any) {
        return ProjectApiFp(this.configuration).projectProjectNumbersGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} userGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUserGuidGet(userGuid: string, options?: any) {
        return ProjectApiFp(this.configuration).projectUserGuidGet(userGuid, options)(this.fetch, this.basePath);
    }

}
/**
 * PurchaseOrderApi - fetch parameter creator
 * @export
 */
export const PurchaseOrderApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderGet(companyCode?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/PurchaseOrder`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} poID 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderHistoryPoIDGet(poID: string, companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'poID' is not null or undefined
            if (poID === null || poID === undefined) {
                throw new RequiredError('poID','Required parameter poID was null or undefined when calling purchaseOrderHistoryPoIDGet.');
            }
            const localVarPath = `/PurchaseOrder/History/{poID}`
                .replace(`{${"poID"}}`, encodeURIComponent(String(poID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} poID 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPoIDGet(poID: string, companyCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'poID' is not null or undefined
            if (poID === null || poID === undefined) {
                throw new RequiredError('poID','Required parameter poID was null or undefined when calling purchaseOrderPoIDGet.');
            }
            const localVarPath = `/PurchaseOrder/{poID}`
                .replace(`{${"poID"}}`, encodeURIComponent(String(poID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PurchaseOrderPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPost(body?: PurchaseOrderPost, options: any = {}): FetchArgs {
            const localVarPath = `/PurchaseOrder`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PurchaseOrderPost" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PurchaseOrderPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPut(body?: PurchaseOrderPost, options: any = {}): FetchArgs {
            const localVarPath = `/PurchaseOrder`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PurchaseOrderPost" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseOrderApi - functional programming interface
 * @export
 */
export const PurchaseOrderApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderGet(companyCode?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).purchaseOrderGet(companyCode, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} poID 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderHistoryPoIDGet(poID: string, companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).purchaseOrderHistoryPoIDGet(poID, companyCode, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} poID 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPoIDGet(poID: string, companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PurchaseOrderInvoiceSingleResult> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).purchaseOrderPoIDGet(poID, companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PurchaseOrderPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPost(body?: PurchaseOrderPost, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).purchaseOrderPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PurchaseOrderPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPut(body?: PurchaseOrderPost, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).purchaseOrderPut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PurchaseOrderApi - factory interface
 * @export
 */
export const PurchaseOrderApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderGet(companyCode?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return PurchaseOrderApiFp(configuration).purchaseOrderGet(companyCode, search, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} poID 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderHistoryPoIDGet(poID: string, companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return PurchaseOrderApiFp(configuration).purchaseOrderHistoryPoIDGet(poID, companyCode, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} poID 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPoIDGet(poID: string, companyCode?: string, options?: any) {
            return PurchaseOrderApiFp(configuration).purchaseOrderPoIDGet(poID, companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PurchaseOrderPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPost(body?: PurchaseOrderPost, options?: any) {
            return PurchaseOrderApiFp(configuration).purchaseOrderPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PurchaseOrderPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPut(body?: PurchaseOrderPost, options?: any) {
            return PurchaseOrderApiFp(configuration).purchaseOrderPut(body, options)(fetch, basePath);
        },
    };
};

/**
 * PurchaseOrderApi - object-oriented interface
 * @export
 * @class PurchaseOrderApi
 * @extends {BaseAPI}
 */
export class PurchaseOrderApi extends BaseAPI {
    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderGet(companyCode?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderGet(companyCode, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} poID 
     * @param {string} [companyCode] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderHistoryPoIDGet(poID: string, companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderHistoryPoIDGet(poID, companyCode, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} poID 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderPoIDGet(poID: string, companyCode?: string, options?: any) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderPoIDGet(poID, companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PurchaseOrderPost} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderPost(body?: PurchaseOrderPost, options?: any) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PurchaseOrderPost} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderPut(body?: PurchaseOrderPost, options?: any) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderPut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ResourceAttributeApi - fetch parameter creator
 * @export
 */
export const ResourceAttributeApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAPAccountGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/ResourceAttribute/APAccount`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [accountGrpCode] 
         * @param {string} [accountTypeCode] 
         * @param {string} [orgID] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAccountCodeByFilterGet(companyCode?: string, accountGrpCode?: string, accountTypeCode?: string, orgID?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/ResourceAttribute/AccountCodeByFilter`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (accountGrpCode !== undefined) {
                localVarQueryParameter['accountGrpCode'] = accountGrpCode;
            }

            if (accountTypeCode !== undefined) {
                localVarQueryParameter['accountTypeCode'] = accountTypeCode;
            }

            if (orgID !== undefined) {
                localVarQueryParameter['orgID'] = orgID;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [accountGrpCode] 
         * @param {string} [accountTypeCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAccountCodeGet(companyCode?: string, accountGrpCode?: string, accountTypeCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/ResourceAttribute/AccountCode`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (accountGrpCode !== undefined) {
                localVarQueryParameter['accountGrpCode'] = accountGrpCode;
            }

            if (accountTypeCode !== undefined) {
                localVarQueryParameter['accountTypeCode'] = accountTypeCode;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [accGroupCode] 
         * @param {string} [orgID] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAccountTypeByFilterGet(companyCode?: string, accGroupCode?: string, orgID?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/ResourceAttribute/AccountTypeByFilter`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (accGroupCode !== undefined) {
                localVarQueryParameter['accGroupCode'] = accGroupCode;
            }

            if (orgID !== undefined) {
                localVarQueryParameter['orgID'] = orgID;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [accGroupCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAccountTypeGet(companyCode?: string, accGroupCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/ResourceAttribute/AccountType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (accGroupCode !== undefined) {
                localVarQueryParameter['accGroupCode'] = accGroupCode;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAllAccountTypeCompanyCodeGet(companyCode: string, options: any = {}): FetchArgs {
            // verify required parameter 'companyCode' is not null or undefined
            if (companyCode === null || companyCode === undefined) {
                throw new RequiredError('companyCode','Required parameter companyCode was null or undefined when calling resourceAttributeAllAccountTypeCompanyCodeGet.');
            }
            const localVarPath = `/ResourceAttribute/AllAccountType/{companyCode}`
                .replace(`{${"companyCode"}}`, encodeURIComponent(String(companyCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeBuyerGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/ResourceAttribute/Buyer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeCashAccountGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/ResourceAttribute/CashAccount`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [chargeType] 
         * @param {string} [projectNumber] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeChargeCodeGet(companyCode?: string, chargeType?: string, projectNumber?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/ResourceAttribute/ChargeCode`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (chargeType !== undefined) {
                localVarQueryParameter['chargeType'] = chargeType;
            }

            if (projectNumber !== undefined) {
                localVarQueryParameter['projectNumber'] = projectNumber;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [resourceName] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeGet(resourceName?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/ResourceAttribute`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (resourceName !== undefined) {
                localVarQueryParameter['resourceName'] = resourceName;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributePaymentTermsGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/ResourceAttribute/PaymentTerms`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeVendorGetVendorsFromCompanyCodeCompanyCodeGet(companyCode: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'companyCode' is not null or undefined
            if (companyCode === null || companyCode === undefined) {
                throw new RequiredError('companyCode','Required parameter companyCode was null or undefined when calling resourceAttributeVendorGetVendorsFromCompanyCodeCompanyCodeGet.');
            }
            const localVarPath = `/ResourceAttribute/Vendor/GetVendorsFromCompanyCode/{companyCode}`
                .replace(`{${"companyCode"}}`, encodeURIComponent(String(companyCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },



        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [vendorID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeVendorGetDetailsGet(companyCode?: string, vendorID?: string, options: any = {}): FetchArgs {
            const localVarPath = `/ResourceAttribute/Vendor/GetDetails`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (vendorID !== undefined) {
                localVarQueryParameter['vendorID'] = vendorID;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourceAttributeApi - functional programming interface
 * @export
 */
export const ResourceAttributeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAPAccountGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributeAPAccountGet(companyCode, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [accountGrpCode] 
         * @param {string} [accountTypeCode] 
         * @param {string} [orgID] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAccountCodeByFilterGet(companyCode?: string, accountGrpCode?: string, accountTypeCode?: string, orgID?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributeAccountCodeByFilterGet(companyCode, accountGrpCode, accountTypeCode, orgID, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [accountGrpCode] 
         * @param {string} [accountTypeCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAccountCodeGet(companyCode?: string, accountGrpCode?: string, accountTypeCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributeAccountCodeGet(companyCode, accountGrpCode, accountTypeCode, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [accGroupCode] 
         * @param {string} [orgID] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAccountTypeByFilterGet(companyCode?: string, accGroupCode?: string, orgID?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributeAccountTypeByFilterGet(companyCode, accGroupCode, orgID, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [accGroupCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAccountTypeGet(companyCode?: string, accGroupCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributeAccountTypeGet(companyCode, accGroupCode, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAllAccountTypeCompanyCodeGet(companyCode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributeAllAccountTypeCompanyCodeGet(companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeBuyerGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributeBuyerGet(companyCode, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeCashAccountGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributeCashAccountGet(companyCode, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [chargeType] 
         * @param {string} [projectNumber] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeChargeCodeGet(companyCode?: string, chargeType?: string, projectNumber?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributeChargeCodeGet(companyCode, chargeType, projectNumber, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [resourceName] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeGet(resourceName?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributeGet(resourceName, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributePaymentTermsGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributePaymentTermsGet(companyCode, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
         /**
         * 
         * @param {string} companyCode 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
         resourceAttributeVendorGetVendorsFromCompanyCodeCompanyCodeGet(companyCode: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributeVendorGetVendorsFromCompanyCodeCompanyCodeGet(companyCode, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },

        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [vendorID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
         resourceAttributeVendorGetDetailsGet(companyCode?: string, vendorID?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VendorSingleResult> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributeVendorGetDetailsGet(companyCode, vendorID, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },


    }
};

/**
 * ResourceAttributeApi - factory interface
 * @export
 */
export const ResourceAttributeApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAPAccountGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributeAPAccountGet(companyCode, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [accountGrpCode] 
         * @param {string} [accountTypeCode] 
         * @param {string} [orgID] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAccountCodeByFilterGet(companyCode?: string, accountGrpCode?: string, accountTypeCode?: string, orgID?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributeAccountCodeByFilterGet(companyCode, accountGrpCode, accountTypeCode, orgID, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [accountGrpCode] 
         * @param {string} [accountTypeCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAccountCodeGet(companyCode?: string, accountGrpCode?: string, accountTypeCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributeAccountCodeGet(companyCode, accountGrpCode, accountTypeCode, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [accGroupCode] 
         * @param {string} [orgID] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAccountTypeByFilterGet(companyCode?: string, accGroupCode?: string, orgID?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributeAccountTypeByFilterGet(companyCode, accGroupCode, orgID, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [accGroupCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAccountTypeGet(companyCode?: string, accGroupCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributeAccountTypeGet(companyCode, accGroupCode, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeAllAccountTypeCompanyCodeGet(companyCode: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributeAllAccountTypeCompanyCodeGet(companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeBuyerGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributeBuyerGet(companyCode, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeCashAccountGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributeCashAccountGet(companyCode, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [chargeType] 
         * @param {string} [projectNumber] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeChargeCodeGet(companyCode?: string, chargeType?: string, projectNumber?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributeChargeCodeGet(companyCode, chargeType, projectNumber, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [resourceName] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeGet(resourceName?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributeGet(resourceName, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributePaymentTermsGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributePaymentTermsGet(companyCode, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
         /**
         * 
         * @param {string} companyCode 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
         resourceAttributeVendorGetVendorsFromCompanyCodeCompanyCodeGet(companyCode: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributeVendorGetVendorsFromCompanyCodeCompanyCodeGet(companyCode, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
               /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [vendorID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeVendorGetDetailsGet(companyCode?: string, vendorID?: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributeVendorGetDetailsGet(companyCode, vendorID, options)(fetch, basePath);
        },


    };
};

/**
 * ResourceAttributeApi - object-oriented interface
 * @export
 * @class ResourceAttributeApi
 * @extends {BaseAPI}
 */
export class ResourceAttributeApi extends BaseAPI {
    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
    public resourceAttributeAPAccountGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributeAPAccountGet(companyCode, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [accountGrpCode] 
     * @param {string} [accountTypeCode] 
     * @param {string} [orgID] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
    public resourceAttributeAccountCodeByFilterGet(companyCode?: string, accountGrpCode?: string, accountTypeCode?: string, orgID?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributeAccountCodeByFilterGet(companyCode, accountGrpCode, accountTypeCode, orgID, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [accountGrpCode] 
     * @param {string} [accountTypeCode] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
    public resourceAttributeAccountCodeGet(companyCode?: string, accountGrpCode?: string, accountTypeCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributeAccountCodeGet(companyCode, accountGrpCode, accountTypeCode, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [accGroupCode] 
     * @param {string} [orgID] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
    public resourceAttributeAccountTypeByFilterGet(companyCode?: string, accGroupCode?: string, orgID?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributeAccountTypeByFilterGet(companyCode, accGroupCode, orgID, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [accGroupCode] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
    public resourceAttributeAccountTypeGet(companyCode?: string, accGroupCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributeAccountTypeGet(companyCode, accGroupCode, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} companyCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
    public resourceAttributeAllAccountTypeCompanyCodeGet(companyCode: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributeAllAccountTypeCompanyCodeGet(companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
    public resourceAttributeBuyerGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributeBuyerGet(companyCode, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
    public resourceAttributeCashAccountGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributeCashAccountGet(companyCode, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [chargeType] 
     * @param {string} [projectNumber] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
    public resourceAttributeChargeCodeGet(companyCode?: string, chargeType?: string, projectNumber?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributeChargeCodeGet(companyCode, chargeType, projectNumber, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [resourceName] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
    public resourceAttributeGet(resourceName?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributeGet(resourceName, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
    public resourceAttributePaymentTermsGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributePaymentTermsGet(companyCode, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} companyCode 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
      public resourceAttributeVendorGetVendorsFromCompanyCodeCompanyCodeGet(companyCode: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributeVendorGetVendorsFromCompanyCodeCompanyCodeGet(companyCode, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [vendorID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
    public resourceAttributeVendorGetDetailsGet(companyCode?: string, vendorID?: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributeVendorGetDetailsGet(companyCode, vendorID, options)(this.fetch, this.basePath);
    }
    

}
/**
 * SynchronizationApi - fetch parameter creator
 * @export
 */
export const SynchronizationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationCPVendorGet(options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/CPVendor`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationEmployeeGet(options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/Employee`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationInvoiceGet(options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/Invoice`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [parameter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationParametersPost(parameter?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/Parameters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (parameter !== undefined) {
                localVarQueryParameter['parameter'] = parameter;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationProjectGet(options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/Project`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationPurchaseOrderGet(options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/PurchaseOrder`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncAccountCodeGet(companyCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/SyncAccountCode`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncAccountTypeGet(companyCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/SyncAccountType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncBambooHRIDGet(options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/SyncBambooHRID`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncCpEmployeeByCompanyGet(companyCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/SyncCpEmployeeByCompany`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncCpProjectByCompanyGet(companyCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/SyncCpProjectByCompany`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncCpPurchaseOrderCompanyGet(companyCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/SyncCpPurchaseOrderCompany`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncCpVendorByCompanyGet(companyCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/SyncCpVendorByCompany`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncInvoiceCompanyGet(companyCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/SyncInvoiceCompany`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncInvoiceIncodingGet(companyCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/SyncInvoiceIncoding`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncOrgAccountByCompanyGet(companyCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/SyncOrgAccountByCompany`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncOrgAccountGet(options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/SyncOrgAccount`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} poNumber 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncPurchaseOrderByPONumberPoNumberGet(poNumber: string, companyCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'poNumber' is not null or undefined
            if (poNumber === null || poNumber === undefined) {
                throw new RequiredError('poNumber','Required parameter poNumber was null or undefined when calling synchronizationSyncPurchaseOrderByPONumberPoNumberGet.');
            }
            const localVarPath = `/Synchronization/SyncPurchaseOrderByPONumber/{poNumber}`
                .replace(`{${"poNumber"}}`, encodeURIComponent(String(poNumber)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncVendorCompanyCodeGet(companyCode: string, options: any = {}): FetchArgs {
            // verify required parameter 'companyCode' is not null or undefined
            if (companyCode === null || companyCode === undefined) {
                throw new RequiredError('companyCode','Required parameter companyCode was null or undefined when calling synchronizationSyncVendorCompanyCodeGet.');
            }
            const localVarPath = `/Synchronization/SyncVendor/{companyCode}`
                .replace(`{${"companyCode"}}`, encodeURIComponent(String(companyCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {string} vendorCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncVendorCompanyCodeVendorCodeGet(companyCode: string, vendorCode: string, options: any = {}): FetchArgs {
            // verify required parameter 'companyCode' is not null or undefined
            if (companyCode === null || companyCode === undefined) {
                throw new RequiredError('companyCode','Required parameter companyCode was null or undefined when calling synchronizationSyncVendorCompanyCodeVendorCodeGet.');
            }
            // verify required parameter 'vendorCode' is not null or undefined
            if (vendorCode === null || vendorCode === undefined) {
                throw new RequiredError('vendorCode','Required parameter vendorCode was null or undefined when calling synchronizationSyncVendorCompanyCodeVendorCodeGet.');
            }
            const localVarPath = `/Synchronization/SyncVendor/{companyCode}/{vendorCode}`
                .replace(`{${"companyCode"}}`, encodeURIComponent(String(companyCode)))
                .replace(`{${"vendorCode"}}`, encodeURIComponent(String(vendorCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncVendorGet(options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/SyncVendor`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncVouchersCompanyCodeGet(companyCode: string, options: any = {}): FetchArgs {
            // verify required parameter 'companyCode' is not null or undefined
            if (companyCode === null || companyCode === undefined) {
                throw new RequiredError('companyCode','Required parameter companyCode was null or undefined when calling synchronizationSyncVouchersCompanyCodeGet.');
            }
            const localVarPath = `/Synchronization/SyncVouchers/{companyCode}`
                .replace(`{${"companyCode"}}`, encodeURIComponent(String(companyCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationUserGet(options: any = {}): FetchArgs {
            const localVarPath = `/Synchronization/User`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SynchronizationApi - functional programming interface
 * @export
 */
export const SynchronizationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationCPVendorGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationCPVendorGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationEmployeeGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationEmployeeGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationInvoiceGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationInvoiceGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [parameter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationParametersPost(parameter?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationParametersPost(parameter, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationProjectGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationProjectGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationPurchaseOrderGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationPurchaseOrderGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncAccountCodeGet(companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncAccountCodeGet(companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncAccountTypeGet(companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncAccountTypeGet(companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncBambooHRIDGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncBambooHRIDGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncCpEmployeeByCompanyGet(companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncCpEmployeeByCompanyGet(companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncCpProjectByCompanyGet(companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncCpProjectByCompanyGet(companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncCpPurchaseOrderCompanyGet(companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncCpPurchaseOrderCompanyGet(companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncCpVendorByCompanyGet(companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncCpVendorByCompanyGet(companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncInvoiceCompanyGet(companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncInvoiceCompanyGet(companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncInvoiceIncodingGet(companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncInvoiceIncodingGet(companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncOrgAccountByCompanyGet(companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncOrgAccountByCompanyGet(companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncOrgAccountGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncOrgAccountGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} poNumber 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncPurchaseOrderByPONumberPoNumberGet(poNumber: string, companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncPurchaseOrderByPONumberPoNumberGet(poNumber, companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncVendorCompanyCodeGet(companyCode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncVendorCompanyCodeGet(companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {string} vendorCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncVendorCompanyCodeVendorCodeGet(companyCode: string, vendorCode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncVendorCompanyCodeVendorCodeGet(companyCode, vendorCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncVendorGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncVendorGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncVouchersCompanyCodeGet(companyCode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationSyncVouchersCompanyCodeGet(companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationUserGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationApiFetchParamCreator(configuration).synchronizationUserGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SynchronizationApi - factory interface
 * @export
 */
export const SynchronizationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationCPVendorGet(options?: any) {
            return SynchronizationApiFp(configuration).synchronizationCPVendorGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationEmployeeGet(options?: any) {
            return SynchronizationApiFp(configuration).synchronizationEmployeeGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationInvoiceGet(options?: any) {
            return SynchronizationApiFp(configuration).synchronizationInvoiceGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [parameter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationParametersPost(parameter?: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationParametersPost(parameter, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationProjectGet(options?: any) {
            return SynchronizationApiFp(configuration).synchronizationProjectGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationPurchaseOrderGet(options?: any) {
            return SynchronizationApiFp(configuration).synchronizationPurchaseOrderGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncAccountCodeGet(companyCode?: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncAccountCodeGet(companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncAccountTypeGet(companyCode?: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncAccountTypeGet(companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncBambooHRIDGet(options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncBambooHRIDGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncCpEmployeeByCompanyGet(companyCode?: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncCpEmployeeByCompanyGet(companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncCpProjectByCompanyGet(companyCode?: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncCpProjectByCompanyGet(companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncCpPurchaseOrderCompanyGet(companyCode?: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncCpPurchaseOrderCompanyGet(companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncCpVendorByCompanyGet(companyCode?: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncCpVendorByCompanyGet(companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncInvoiceCompanyGet(companyCode?: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncInvoiceCompanyGet(companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncInvoiceIncodingGet(companyCode?: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncInvoiceIncodingGet(companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncOrgAccountByCompanyGet(companyCode?: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncOrgAccountByCompanyGet(companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncOrgAccountGet(options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncOrgAccountGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} poNumber 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncPurchaseOrderByPONumberPoNumberGet(poNumber: string, companyCode?: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncPurchaseOrderByPONumberPoNumberGet(poNumber, companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncVendorCompanyCodeGet(companyCode: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncVendorCompanyCodeGet(companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {string} vendorCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncVendorCompanyCodeVendorCodeGet(companyCode: string, vendorCode: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncVendorCompanyCodeVendorCodeGet(companyCode, vendorCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncVendorGet(options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncVendorGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationSyncVouchersCompanyCodeGet(companyCode: string, options?: any) {
            return SynchronizationApiFp(configuration).synchronizationSyncVouchersCompanyCodeGet(companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationUserGet(options?: any) {
            return SynchronizationApiFp(configuration).synchronizationUserGet(options)(fetch, basePath);
        },
    };
};

/**
 * SynchronizationApi - object-oriented interface
 * @export
 * @class SynchronizationApi
 * @extends {BaseAPI}
 */
export class SynchronizationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationCPVendorGet(options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationCPVendorGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationEmployeeGet(options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationEmployeeGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationInvoiceGet(options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationInvoiceGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [parameter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationParametersPost(parameter?: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationParametersPost(parameter, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationProjectGet(options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationProjectGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationPurchaseOrderGet(options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationPurchaseOrderGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncAccountCodeGet(companyCode?: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncAccountCodeGet(companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncAccountTypeGet(companyCode?: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncAccountTypeGet(companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncBambooHRIDGet(options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncBambooHRIDGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncCpEmployeeByCompanyGet(companyCode?: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncCpEmployeeByCompanyGet(companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncCpProjectByCompanyGet(companyCode?: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncCpProjectByCompanyGet(companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncCpPurchaseOrderCompanyGet(companyCode?: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncCpPurchaseOrderCompanyGet(companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncCpVendorByCompanyGet(companyCode?: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncCpVendorByCompanyGet(companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncInvoiceCompanyGet(companyCode?: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncInvoiceCompanyGet(companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncInvoiceIncodingGet(companyCode?: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncInvoiceIncodingGet(companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncOrgAccountByCompanyGet(companyCode?: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncOrgAccountByCompanyGet(companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncOrgAccountGet(options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncOrgAccountGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} poNumber 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncPurchaseOrderByPONumberPoNumberGet(poNumber: string, companyCode?: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncPurchaseOrderByPONumberPoNumberGet(poNumber, companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} companyCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncVendorCompanyCodeGet(companyCode: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncVendorCompanyCodeGet(companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} companyCode 
     * @param {string} vendorCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncVendorCompanyCodeVendorCodeGet(companyCode: string, vendorCode: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncVendorCompanyCodeVendorCodeGet(companyCode, vendorCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncVendorGet(options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncVendorGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} companyCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationSyncVouchersCompanyCodeGet(companyCode: string, options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationSyncVouchersCompanyCodeGet(companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public synchronizationUserGet(options?: any) {
        return SynchronizationApiFp(this.configuration).synchronizationUserGet(options)(this.fetch, this.basePath);
    }

}
/**
 * UserFavoriteApplicationApi - fetch parameter creator
 * @export
 */
export const UserFavoriteApplicationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteApplicationApplicationIdDelete(applicationId: number, options: any = {}): FetchArgs {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling userFavoriteApplicationApplicationIdDelete.');
            }
            const localVarPath = `/UserFavoriteApplication({applicationId})`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserFavoriteApplication} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteApplicationPost(body?: UserFavoriteApplication, options: any = {}): FetchArgs {
            const localVarPath = `/UserFavoriteApplication`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserFavoriteApplication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserFavoriteApplicationApi - functional programming interface
 * @export
 */
export const UserFavoriteApplicationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteApplicationApplicationIdDelete(applicationId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserFavoriteApplicationApiFetchParamCreator(configuration).userFavoriteApplicationApplicationIdDelete(applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UserFavoriteApplication} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteApplicationPost(body?: UserFavoriteApplication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserFavoriteApplicationApiFetchParamCreator(configuration).userFavoriteApplicationPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserFavoriteApplicationApi - factory interface
 * @export
 */
export const UserFavoriteApplicationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteApplicationApplicationIdDelete(applicationId: number, options?: any) {
            return UserFavoriteApplicationApiFp(configuration).userFavoriteApplicationApplicationIdDelete(applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UserFavoriteApplication} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoriteApplicationPost(body?: UserFavoriteApplication, options?: any) {
            return UserFavoriteApplicationApiFp(configuration).userFavoriteApplicationPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * UserFavoriteApplicationApi - object-oriented interface
 * @export
 * @class UserFavoriteApplicationApi
 * @extends {BaseAPI}
 */
export class UserFavoriteApplicationApi extends BaseAPI {
    /**
     * 
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserFavoriteApplicationApi
     */
    public userFavoriteApplicationApplicationIdDelete(applicationId: number, options?: any) {
        return UserFavoriteApplicationApiFp(this.configuration).userFavoriteApplicationApplicationIdDelete(applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UserFavoriteApplication} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserFavoriteApplicationApi
     */
    public userFavoriteApplicationPost(body?: UserFavoriteApplication, options?: any) {
        return UserFavoriteApplicationApiFp(this.configuration).userFavoriteApplicationPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * UserInterfaceMenuApi - fetch parameter creator
 * @export
 */
export const UserInterfaceMenuApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInterfaceMenuGet(filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/UserInterfaceMenu`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInterfaceMenuApi - functional programming interface
 * @export
 */
export const UserInterfaceMenuApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInterfaceMenuGet(filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserInterfaceMenuApiFetchParamCreator(configuration).userInterfaceMenuGet(filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserInterfaceMenuApi - factory interface
 * @export
 */
export const UserInterfaceMenuApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInterfaceMenuGet(filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return UserInterfaceMenuApiFp(configuration).userInterfaceMenuGet(filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
    };
};

/**
 * UserInterfaceMenuApi - object-oriented interface
 * @export
 * @class UserInterfaceMenuApi
 * @extends {BaseAPI}
 */
export class UserInterfaceMenuApi extends BaseAPI {
    /**
     * 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInterfaceMenuApi
     */
    public userInterfaceMenuGet(filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return UserInterfaceMenuApiFp(this.configuration).userInterfaceMenuGet(filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

}
/**
 * VendorApi - fetch parameter creator
 * @export
 */
export const VendorApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Vendor`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VendorPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorPost(body?: VendorPost, options: any = {}): FetchArgs {
            const localVarPath = `/Vendor`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VendorPost" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VendorPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorPut(body?: VendorPost, options: any = {}): FetchArgs {
            const localVarPath = `/Vendor`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VendorPost" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
         /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [vendorID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
         vendorVendorVendorDetailsGet(companyCode?: string, vendorID?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Vendor/Vendor/VendorDetails`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (vendorID !== undefined) {
                localVarQueryParameter['vendorID'] = vendorID;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },


    }
};

/**
 * VendorApi - functional programming interface
 * @export
 */
export const VendorApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorGet(companyCode, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {VendorPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorPost(body?: VendorPost, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {VendorPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorPut(body?: VendorPost, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorPut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
         /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [vendorID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
         vendorVendorVendorDetailsGet(companyCode?: string, vendorID?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VendorSingleResult> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorVendorVendorDetailsGet(companyCode, vendorID, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },       

    }
};

/**
 * VendorApi - factory interface
 * @export
 */
export const VendorApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return VendorApiFp(configuration).vendorGet(companyCode, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {VendorPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorPost(body?: VendorPost, options?: any) {
            return VendorApiFp(configuration).vendorPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {VendorPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorPut(body?: VendorPost, options?: any) {
            return VendorApiFp(configuration).vendorPut(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [vendorID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
          vendorVendorVendorDetailsGet(companyCode?: string, vendorID?: string, options?: any) {
            return VendorApiFp(configuration).vendorVendorVendorDetailsGet(companyCode, vendorID, options)(fetch, basePath);
        },
    };
};

/**
 * VendorApi - object-oriented interface
 * @export
 * @class VendorApi
 * @extends {BaseAPI}
 */
export class VendorApi extends BaseAPI {
    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorGet(companyCode?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return VendorApiFp(this.configuration).vendorGet(companyCode, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {VendorPost} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorPost(body?: VendorPost, options?: any) {
        return VendorApiFp(this.configuration).vendorPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {VendorPost} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorPut(body?: VendorPost, options?: any) {
        return VendorApiFp(this.configuration).vendorPut(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} [companyCode] 
     * @param {string} [vendorID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
     public vendorVendorVendorDetailsGet(companyCode?: string, vendorID?: string, options?: any) {
        return VendorApiFp(this.configuration).vendorVendorVendorDetailsGet(companyCode, vendorID, options)(this.fetch, this.basePath);
    }

}
/**
 * 
 * @export
 * @interface LaborGroup
 */
export interface LaborGroup {
    ID?: string;
    Type?: string;
    TypeDescription?: string;
}
/**
 * 
 * @export
 * @interface LaborGroupSingleResult
 */
export interface LaborGroupSingleResult {
    /**
     * 
     * @type {Array<LaborGroup>}
     * @memberof LaborGroupSingleResult
     */
    queryable?: Array<LaborGroup>;
}
export class LaborGroupApi extends BaseAPI {
    /**
     *  
     *  
     * 
        * @param {string} [companyCode]
        * @param {string} [filter]
        * @param {number} [top]
        * @param {number} [skip]
        * @param {number} [take]
        * @param {string} [orderBy]
        * @param {*} [options] Override http request option.
        */
    public laborGroupGet(companyCode: number, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return LaborGroupApiFp(this.configuration).laborGroupGet(companyCode, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     *  
     *  
     * 
        * @param {LaborGroupPost} [body]
        * @param {*} [options] Override http request option.
        */
    // public laborGroupPost(body?: LaborGroupPost, options?: any) {
    //     return LaborGroupApiFp(this.configuration).laborGroupPost(body, options)(this.fetch, this.basePath);
    // }

    /**
     *  
     *  
     * 
        * @param {LaborGroupPost} [body]
        * @param {*} [options] Override http request option.
        */
    // public laborGroupPut(body?: LaborGroupPost, options?: any) {
    //     return LaborGroupApiFp(this.configuration).laborGroupPut(body, options)(this.fetch, this.basePath);
    // }

    /**
     *  
     *  
     * 
        * @param {string} laborGroupID
        * @param {string} [companyCode]
        * @param {*} [options] Override http request option.
        */
    public laborGroupIDGet(laborGroupID: string, companyCode?: string, options?: any) {
        return LaborGroupApiFp(this.configuration).laborGroupIDGet(options)(this.fetch, this.basePath);
    }

}
export const LaborGroupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        laborGroupGet(companyCode: number, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LaborGroupApiFetchParamCreator(configuration).laborGroupGet(companyCode, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {LaborGroupPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        // laborGroupPost(body?: LaborGroupPost, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
        //     const localVarFetchArgs = LaborGroupApiFetchParamCreator(configuration).laborGroupPost(body, options);
        //     return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        //         return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
        //             if (response.status >= 200 && response.status < 300) {
        //                 return response;
        //             } else {
        //                 throw response;
        //             }
        //         });
        //     };
        // },
        /**
         * 
         * @param {LaborGroupPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        // laborGroupPut(body?: LaborGroupPost, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
        //     const localVarFetchArgs = LaborGroupApiFetchParamCreator(configuration).laborGroupPut(body, options);
        //     return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        //         return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
        //             if (response.status >= 200 && response.status < 300) {
        //                 return response;
        //             } else {
        //                 throw response;
        //             }
        //         });
        //     };
        // },
        /**
         * 
         * @param {string} laborGroupID 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        laborGroupIDGet(laborGroupID: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LaborGroupSingleResult> {
            const localVarFetchArgs = LaborGroupApiFetchParamCreator(configuration).laborGroupIDGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};
export const LaborGroupApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyCode] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        laborGroupGet(companyCode: number, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            const code = (companyCode !== undefined) ? encodeURIComponent(String(companyCode)) : "";
            const localVarPath = `/LaborGroup/List?companyCode=${code}`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        // /**
        //  * 
        //  * @param {LaborGroupPost} [body] 
        //  * @param {*} [options] Override http request option.
        //  * @throws {RequiredError}
        //  */
        // laborGroupPost(body?: LaborGroupPost, options: any = {}): FetchArgs {
        //     const localVarPath = `/LaborGroup`;
        //     const localVarUrlObj = url.parse(localVarPath, true);
        //     const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
        //     const localVarHeaderParameter = {} as any;
        //     const localVarQueryParameter = {} as any;

        //     // authentication Bearer required
        //     if (configuration && configuration.apiKey) {
        //         const localVarApiKeyValue = typeof configuration.apiKey === 'function'
		// 			? configuration.apiKey("Authorization")
		// 			: configuration.apiKey;
        //         localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
        //     }

        //     localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

        //     localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
        //     // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
        //     delete localVarUrlObj.search;
        //     localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        //     const needsSerialization = (<any>"LaborGroupPost" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        //     localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

        //     return {
        //         url: url.format(localVarUrlObj),
        //         options: localVarRequestOptions,
        //     };
        // },
        // /**
        //  * 
        //  * @param {LaborGroupPost} [body] 
        //  * @param {*} [options] Override http request option.
        //  * @throws {RequiredError}
        //  */
        // laborGroupPut(body?: LaborGroupPost, options: any = {}): FetchArgs {
        //     const localVarPath = `/LaborGroup`;
        //     const localVarUrlObj = url.parse(localVarPath, true);
        //     const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
        //     const localVarHeaderParameter = {} as any;
        //     const localVarQueryParameter = {} as any;

        //     // authentication Bearer required
        //     if (configuration && configuration.apiKey) {
        //         const localVarApiKeyValue = typeof configuration.apiKey === 'function'
		// 			? configuration.apiKey("Authorization")
		// 			: configuration.apiKey;
        //         localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
        //     }

        //     localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

        //     localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
        //     // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
        //     delete localVarUrlObj.search;
        //     localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        //     const needsSerialization = (<any>"LaborGroupPost" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        //     localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

        //     return {
        //         url: url.format(localVarUrlObj),
        //         options: localVarRequestOptions,
        //     };
        // },
        /**
         * 
         * @param {string} laborGroupID 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        laborGroupIDGet(laborGroupID: string, companyCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'laborGroupID' is not null or undefined
            if (laborGroupID === null || laborGroupID === undefined) {
                throw new RequiredError('laborGroupID','Required parameter laborGroupID was null or undefined when calling laborGroupIDGet.');
            }
            const localVarPath = `/LaborGroup/LaborGroup/{laborGroupID}`
                .replace(`{${"laborGroupID"}}`, encodeURIComponent(String(laborGroupID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};