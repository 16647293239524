import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { XtForm } from 'nwcommon';
import { XtField } from 'nwcommon';
import { Loading } from '../../../../Xt/Layout/Loading';
import { APIFactory, GroupApi, Group, GroupPermissionApi, ResourceApi } from '../../../../Xt/ApiClients';
import { IXtContext, XtContext } from '../../../../Xt/Contexts/XtContext';

export type XtGroupPermissionProps = {
    dataItem: Group;
    onClose: any;
    title: string;

}

export function XtGroupPermissionView(props: XtGroupPermissionProps) {

    let factory: APIFactory;

    const [resources, setResources] = React.useState<any[]>()
    const [applications, setApplication] = React.useState<any[]>()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [selectedItems, setSelectedItems] = React.useState<any[]>([])
    const [allResourceActions, setAllResourceActions] = React.useState<any[]>([])
    const [time, setTime] = React.useState<number>(0)

    const context: IXtContext = React.useContext(XtContext);

    React.useEffect(() => {
        if (context?.loggedOnUser?.username) {
            let s: GroupPermissionApi = factory.create(GroupPermissionApi);
            let s1: ResourceApi = factory.create(ResourceApi);
            setLoading(true);
            s.groupPermissionGet(context?.loggedOnUser?.username, props.dataItem.groupGuid).then(async (resp) => {
                if (resp.ok) {
                    let json = await resp.json();
                    let permissions = json.value;
                    let p: any[] = [];
                    permissions?.map((v, i) => {
                        p.push({
                            groupGuid: props.dataItem.groupGuid,
                            resourceActionGuid: v.resourceActionGuid,
                            resourceGuid: v.resourceGuid
                        })
                    })
                    setSelectedItems(p);

                    setLoading(true);
                    s1.resourceGet(context?.loggedOnUser?.username).then(async (resp) => {
                        if (resp.ok) {
                            let json = await resp.json();
                            let data = json.value;
                            let app: string[] = [];
                            let allActions: any[] = [];
                            data?.map((v, i) => {
                                if (app.indexOf(v.application) === -1)
                                    app.push(v.application)
                                v.resourceActions?.map((v1, i1) => {
                                    allActions.push({
                                        groupGuid: props.dataItem.groupGuid,
                                        resourceActionGuid: v1.actionGuid,
                                        resourceGuid: v.resourceGuid
                                    });
                                })
                            })
                            setResources(data);
                            setApplication(app);
                            setAllResourceActions(allActions);
                        }
                    }).finally(() => {
                        setLoading(false)
                    })
                }
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [props.dataItem, context?.loggedOnUser?.username])

    const onSubmit = async (e) => {
        let s: GroupPermissionApi = factory.create(GroupPermissionApi);
        let res = await s.groupPermissionPost(context?.loggedOnUser?.username, selectedItems);
        if (res.ok) {
            if (props.onClose) {
                props.onClose({ ...e, reloadData: true });
            }
        }
        else {
            // TODO: show error
        }
        
    }

    const onCancel = (e) => {
        if (props.onClose) {
            props.onClose(e)
        }
    }

    const onCheckboxClicked = (e) => {
        let checked = e.currentTarget?.checked;
        let itemId = e.target.getAttribute("itemid")
        if (checked) {
            let item = allResourceActions.find((v, i) => { return v.resourceActionGuid === itemId ? v : null });
            if (item) {
                selectedItems.push({
                    groupGuid: props.dataItem.groupGuid,
                    resourceActionGuid: item.resourceActionGuid,
                    resourceGuid: item.resourceGuid
                })
                setSelectedItems(selectedItems);
                setTime(new Date().getTime());
            }
        }
        else {            
            let nSelectedItems: any[] = [];
            selectedItems.map((v, i) => {
                if (v.resourceActionGuid !== itemId)
                    nSelectedItems.push(v);
            });
            setSelectedItems(nSelectedItems);
            setTime(new Date().getTime());
        }
    }

    const renderResources = () => {
        let items: any[] = [];
        applications?.map((v, i) => {
            items.push(<Row key={ v+i }>
                <Col sm={12}><h5>{v}<hr /></h5></Col>
                <Col sm={12}>
                    { renderApplicationResources(v) }
                </Col>
            </Row>)
        })
        return items;
    }

    const renderApplicationResources = (appName: string) => {
        let items: any[] = [];
        resources?.map((v, i) => {
            if (v.application === appName) {
                items.push(<Row key={ v.resourceGuid }>
                    <Col sm={12}><h6>{v.title}</h6></Col>
                    <Col sm={12}>
                        {renderApplicationResourceActions(v)}
                    </Col>
                    <Col sm={12}><h6>&nbsp;</h6></Col>
                </Row>)
            }            
        })
        return items;
    }

    const isChecked = (v) => {
        return selectedItems.find((v1, i) => { return (v1.resourceActionGuid === v.actionGuid) ? v1 : null; }) !== undefined;        
    }

    const renderApplicationResourceActions = (r: any) => {
        let items: any[] = [];
        r?.resourceActions?.map((v, i) => {
            items.push(
                <Col sm={3} key={v.actionGuid}>
                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" onClick={onCheckboxClicked} checked={isChecked(v)} label={v.actionTitle} itemID={v.actionGuid} />
                    </Form.Group>
                </Col>
            )
        })
        return (<Row>{items}</Row>);
    }

    return (
        <>
            {<Dialog title={props.title} onClose={props.onClose} {...props} width={"50%"}>
                <APIFactory ref={(e) => { factory = e; }} />
                <XtForm onSubmit={onSubmit}>
                    <Container>
                        {renderResources()}
                        {loading && <Loading />}
                    </Container>
                    <DialogActionsBar>
                        <Button type="submit" variant="primary">Submit</Button>
                        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                    </DialogActionsBar>
                </XtForm>
                
            </Dialog>}
        </>
    )
}