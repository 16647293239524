import { useContext, useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { XtNavDropdown } from "./NavBar/XtNavDropdown";
import NwAlertMenuItem from "./NwMenu/NwAlertIMenuItem";
import { APIFactoryCommon, NotificationApi } from "../ApiClients/Common/index";
import google from "../../images/google.png";
import { IXtContext, XtContext } from "../Contexts/XtContext"

export function XtAlertDropDown(props) {
    let factory: APIFactoryCommon;
    const init = true;

    const [notifications, setNotifictions] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const context: IXtContext = useContext(XtContext);
    useEffect(() => {
        if (context?.loggedOnUser) {
            let s: NotificationApi = factory.create(NotificationApi);
            s.notificationGet(context?.loggedOnUser?.username).then(async (data) => {
                var d1 = await data.json();
                setNotifictions(d1.value);
                setNotificationCount(d1['@odata.count']);
            });
        }
    }, [context?.loggedOnUser?.username]);

    return (
        <>
            <div className="nav-item">
                <a href="https://www.google.com/" target="_blank" className="nav-link">
                    <img src={google} height="30" />
                </a>
            </div>
            <div className="alert-menu">
                <APIFactoryCommon
                    ref={(e) => {
                        factory = e;
                    }}
                />
                {/* <i className="material-icons">notifications</i> */}
                <XtNavDropdown align="end" className="nav-icon nav-notification">
                    <div className="nav-notification-body">
                        {notifications.map((n, i) => (
                            <NwAlertMenuItem key={i} {...n} />
                        ))}
                    </div>
                    <NavDropdown.Item
                        className="notification-item-footer"
                        href="#action5"
                    >
                        View All
                    </NavDropdown.Item>
                </XtNavDropdown>

                <span
                    className={notificationCount === 0 ? "d-none" : "notification-count"}
                >
                    {notificationCount}
                </span>
            </div>
        </>
    );
}
