import React from 'react';
import { Configuration, ConfigurationParameters } from "./SwaggerCLICommon"
import { AppConfiguration, defaultApiConfiguration } from '../../../components/Configurations/config';
import { useOktaAuth } from '@okta/okta-react';
import { XtContext, IXtContext } from '../../../Xt/Contexts/XtContext';
declare var window: any;

export * from "./SwaggerCLICommon"

class APIFactoryCommon extends React.Component<{}, {}>{

    create<T>(type: (new (e) => T)): T {
        let d: IXtContext = this.context;
        let config = new Configuration({ apiKey: "Bearer " + d.tokens.accessToken, basePath: defaultApiConfiguration.commonAPIPath })
        return new type(config);
    }

    constructor(props) {
        super(props)
    }

    render() {
        return (<></>)
    }

    async callAPI(f, errorTitle?: string) {
        let d: IXtContext = this.context;
        try {
            return await f
        } catch (e) {
          ;
            const err =[];
            let msg = await e.json();
            if (msg.status && msg.status === 401) {
                window.location = "/logout"
                return;
            }

            if (msg.status && msg.status === 400) {
          
                if (msg.errors) {
                  Object.keys(msg.errors).forEach((key) => {
                    err.push(
                      `${key}- ${msg.errors[key as keyof typeof msg.errors].join(
                        ","
                      )}`
                    );
                  });
                }
              } else {
                err.push(msg?.message || msg?.title )
              }
            d?.onError({
                title: errorTitle || "Error", message: err
            });
        }
    }

    showErrorMessage(message: string, title?: string) {
        let d: IXtContext = this.context;
        d?.onError({
            title: title || "Error", message: [message]
        });
    }
}

APIFactoryCommon.contextType = XtContext;

export { APIFactoryCommon };