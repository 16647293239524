import { PersonnelRequisitionApi } from "../../../../../Xt/ApiClients";
import { IXtContext } from "../../../../../Xt/Contexts/XtContext";
import { PRR, ApprovalFlowLog, PrrPermission } from "../Interfaces/API";

export const PrrApiService = {
  getPrrByGuid: (
    context: IXtContext,
    api: PersonnelRequisitionApi,
    guid: string
  ): Promise<PRR> => {
    return api
      .personnelRequisitionKeyGet(context?.loggedOnUser?.username, guid)
      .then(async (prrData) => {
        let prr: any = prrData;

        if (prr.attachmentList && prr.attachmentList.length > 0) {
          let attachmentList = prr.attachmentList.map((a) => {
            return {
              ...a,
              createdOn: new Date(a.createdOn),
            };
          });
          prr.attachmentList = attachmentList;
        }
        prr.locationList =
          prr?.locationList?.length > 0
            ? prr.locationList.split(";").map((location) => {
                const [city, state] = location.split(", ");
                return { city, state };
              })
            : [];
        return prr;
      });
  },
  getApprovalFlowByGuid: (
    context: IXtContext,
    api: PersonnelRequisitionApi,
    guid: string
  ): Promise<ApprovalFlowLog[]> => {
    return api
      .personnelRequisitionApprovalFlowKeyGet(
        context?.loggedOnUser?.username,
        guid
      )
      .then(async (data) => {
        let dataFromApi = await data.json();
        return dataFromApi.value;
      });
  },

  getApprovalFlowLogByGuid: (
    context: IXtContext,
    api: PersonnelRequisitionApi,
    guid: string
  ) => {
    return api
      .personnelRequisitionApprovalFlowLogKeyGet(
        context?.loggedOnUser?.username,
        guid
      )
      .then(async (data) => {
        let dataFromApi = await data.json();
        return dataFromApi.value;
      });
  },
  //Gets the permissions for the current user to see if they are able to access a prr
  getPermissionByPrrGuid: (
    context: IXtContext,
    api: PersonnelRequisitionApi,
    guid: string
  ): Promise<PrrPermission> => {
    return api
      .personnelRequisitionRequisitionPermissionKeyGet(
        context?.loggedOnUser?.username,
        guid
      )
      .then(async (data) => {
        let dataFromApi: PrrPermission = data;
        return dataFromApi;
      });
  },
};
