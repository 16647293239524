import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { XtForm } from "nwcommon";
import { useContext, useEffect, useReducer, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  APIFactory,
  PersonnelRequisition,
  PersonnelRequisitionApi,
} from "../../../../../Xt/ApiClients";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { Loading } from "../../../../../Xt/Layout/Loading";
import { XtBreadcrumbs } from "../../../../../Xt/Layout/XtBreadcrumbs";
import LoadingDialog, {
  EnConfirmationMessageType,
  LoadingDailogProps,
} from "../../../Dialogs/Common/LoadingDailog";
import XtAccordianItem from "../../PurchaseRequisition/Common/XtAccordianItem";
import {
  CancelButtonEdit,
  DeleteButton,
} from "../Components/Buttons/CancelAndDeleteButtons";
import CloneButton from "../Components/Buttons/CloneButton";
import SaveButton from "../Components/Buttons/SaveButton";
import SubmitButton from "../Components/Buttons/SubmitButton";
import { PrrApiService } from "../GridCell/PrrApiService";
import {
  PrrRequestType,
  PrrStatus,
  RequestPageMode,
} from "../Interfaces/Enums";
import NwAdditionalInfo from "./AdditionalInfo";
import NwGeneralInfo from "./Generalnfo";
import NwPositionInfo from "./PositionDetails";
import { EnPrrAction, EnPrrTypeDetail, PrrReducer } from "./PrrReducer";
import { PRR, PrrPermission } from "../Interfaces/API";
import {
  submitNewPrr,
  submitExistingPrr,
} from "../../../../../api/react-query";

export default function NwCreateNewPrr() {
  let factory;
  const history = useHistory();
  let { prrGuid } = useParams();
  const location = useLocation();
  const context: IXtContext = useContext(XtContext);
  const redirectTo = { shouldRedirect: false, url: "" };

  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState(null);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [redirect, setRedirect] = useState(redirectTo);
  const [state, dispatch] = useReducer(PrrReducer.reducer, null);
  const [isLaborGroupLoading, setIsLaborGroupLoading] = useState(false);
  const [pageMode, setPageMode] = useState(RequestPageMode.PRR_CREATE_NEW);
  const [submitLoading, setSubmitLoading] = useState<LoadingDailogProps>({
    show: false,
  });
  const [isClone, setIsClone] = useState(false);

  const token = context?.tokens?.accessToken;
  const username = context?.loggedOnUser?.username;

  const queryClient = useQueryClient();

  const saveNewPrrMutation = useMutation(submitNewPrr, {
    onSuccess: (newPrr) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "prrList" && query.queryKey[3] === "MyDraft"
          );
        },
      });

      setDialogSuccess(newPrr.prrNumber, "Save", "");
    },
    onError: (error) => {
      console.log(error);
      setDialogError(["Internal Server Error. Please contact support."]);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const saveExistingPrrMutation = useMutation(submitExistingPrr, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "prrList" && query.queryKey[3] === "MyDraft"
          );
        },
      });

      setDialogSuccessForExistingPrr(state?.prrNumber, "Save", "");
    },
    onError: (error) => {
      console.log(error);
      setDialogError(["Internal Server Error. Please contact support."]);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const submitNewPrrMutation = useMutation(submitNewPrr, {
    onSuccess: (newPrr) => {
      const listModesToInvalidate = ["MyActivePRR", "MyPRR", "AllPRR"];

      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "prrList" &&
            listModesToInvalidate.includes(query.queryKey[3] as string)
          );
        },
      });

      setDialogSuccess(newPrr.prrNumber, "Submit", "");
    },
    onError: (error) => {
      console.log(error);
      setDialogError(["Internal Server Error. Please contact support."]);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const submitExistingPrrMutation = useMutation(submitExistingPrr, {
    onSuccess: () => {
      const listModesToInvalidate = ["MyActivePRR", "MyPRR", "AllPRR"];

      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "prrList" &&
            listModesToInvalidate.includes(query.queryKey[3] as string)
          );
        },
      });

      setDialogSuccessForExistingPrr(state?.prrNumber, "Submit", "");
    },
    onError: (error) => {
      console.log(error);
      setDialogError(["Internal Server Error. Please contact support."]);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (location.pathname.includes("prr/create"))
      setPageMode(RequestPageMode.PRR_CREATE_NEW);
    if (location.pathname.includes("prr/edit")) {
      setPageMode(RequestPageMode.PRR_EDIT);
    }
  }, [location.pathname]);

  const loadPrrEdit = async () => {
    if (!prrGuid) return;

    let prrApi: PersonnelRequisitionApi = factory.create(
      PersonnelRequisitionApi,
    );
    setLoading(true);

    PrrApiService.getPermissionByPrrGuid(context, prrApi, prrGuid)
      .then((data: PrrPermission) => {
        let permission: any = data;
        if (permission.isEditable === false) {
          setRedirect({ shouldRedirect: true, url: "/unauthorize" });
        } else {
          setPermission(data);
          setLoading(true);
          PrrApiService.getPrrByGuid(context, prrApi, prrGuid).then(
            (prrData: PRR) => {
              setIsLaborGroupLoading(!!prrData.companyCode);

              let prr: any = prrData;
              setBreadCrumbs([
                {
                  href: "/prr",
                  text: "Personnel Requisitions ",
                  isActive: false,
                },
                {
                  href: "/prr/create",
                  text: "" + prr?.prrNumber,
                  isActive: true,
                },
              ]);

              dispatch({ type: EnPrrAction.EDIT_PRR, payload: prr });
            },
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadPrrClone = () => {
    setPageMode(RequestPageMode.PRR_CREATE_NEW);
    if (!prrGuid) return;

    let prrApi: PersonnelRequisitionApi = factory.create(
      PersonnelRequisitionApi,
    );
    setLoading(true);
    PrrApiService.getPrrByGuid(context, prrApi, prrGuid).then((prr: PRR) => {
      setBreadCrumbs([
        {
          href: "/prr",
          text: "Personnel Requisitions ",
          isActive: false,
        },
        {
          href: "/prr/create",
          text: "Create New PRR",
          isActive: true,
        },
      ]);
      dispatch({ type: EnPrrAction.CLONE_PRR, payload: prr });
      setLoading(false);
      setRedirect({ shouldRedirect: false, url: "/prr/create" });
      history.push("/prr/create");
    });
  };

  const loadPrrCreateNew = () => {
    setBreadCrumbs([
      {
        href: "/prr",
        text: "Personnel Requisitions",
        isActive: false,
      },

      {
        href: "/prr/create",
        text: "Create New PRR",
        isActive: true,
      },
    ]);
    //get prr from local storage
    const prr = JSON.parse(localStorage.getItem("prr"));

    if (!prr) {
      dispatch({ type: EnPrrAction.CREATE_NEW_PRR });
    } else {
      localStorage.setItem("prr", null);
      dispatch({ type: EnPrrAction.CLONE_PRR, payload: prr });
      setIsClone(true);
    }
  };

  useEffect(() => {
    if (context?.loggedOnUser?.username) {
      switch (pageMode) {
        case RequestPageMode.PRR_EDIT:
          loadPrrEdit();
          break;

        case RequestPageMode.PRR_CREATE_NEW:
          loadPrrCreateNew();
          break;
      }
    }
  }, [context?.loggedOnUser?.username, pageMode]);

  const validateDate = (dateToValidate) => {
    if (!dateToValidate) return false;
    if (dateToValidate instanceof String && dateToValidate?.trim() === "")
      return false;
    var dateObj = new Date(dateToValidate);

    const isValidInstance = dateObj instanceof Date;

    if (!isValidInstance) {
      return false;
    }

    const isInvalid = isNaN(dateObj.getTime());
    if (isInvalid) {
      return false;
    }
    if (dateObj < new Date("01/01/2000")) {
      return false;
    }
    //If the date is in the past
    var pastDate = new Date();
    pastDate.setDate(pastDate.getDate() - 1);
    if (dateToValidate < pastDate) {
      return false;
    }
    return true;
  };

  const getValidationErrorsOnSubmit = (e) => {
    const submitErrors = [];
    const actionType =
      e?.nativeEvent?.submitter?.id === "submit" ? "Submit" : "Save";

    const regex = new RegExp("^[ -~]*$"); //regex to assert submitted field value is a valid ansi character string

    if (actionType === "Submit") {
      const dateNeeded = e?.postData?.dateNeeded;
      if (!validateDate(dateNeeded)) {
        submitErrors.push(`Date Needed should have valid date: ${dateNeeded}`);
      }
      //If min and max are not 0, check if min is less than max
      if (
        state?.wageMinimum &&
        state?.wageMaximum &&
        state?.wageMinimum > state?.wageMaximum
      ) {
        submitErrors.push("Invalid Min and/or Max wage rates.");
      }
      if (state?.jobTitle.length > 151) {
        submitErrors.push("Job Title must be less than 151 characters");
      }
      if (!regex.test(state?.jobTitle)) {
        submitErrors.push(
          "Job Title has an unexpected special character which is not allowed (Example: bullets).",
        );
      }
      if (state?.additionalAttachmentFileList.length <= 0) {
        submitErrors.push("At least one attachment must be attached.");
      }
      if (state?.additionalAttachmentFileList.length > 1) {
        submitErrors.push("Only one attachment can be attached.");
      }
      if (state?.locationList.length <= 0) {
        submitErrors.push("At least one location must be selected.");
      }
      if (state?.jobDescription.length > 4000) {
        submitErrors.push("Job Description must be less than 4000 characters");
      }
      if (!regex.test(state?.jobDescription)) {
        submitErrors.push(
          "Job Description Summary has an unexpected special character which is not allowed (Example: bullets).",
        );
      }
      if (state?.requiredSkills.length > 4000) {
        submitErrors.push("Required Skills must be less than 4000 characters");
      }
      if (!regex.test(state?.requiredSkills)) {
        submitErrors.push(
          "Required Skills has an unexpected special character which is not allowed (Example: bullets).",
        );
      }
      if (state?.requiredLicenses.length > 4000) {
        submitErrors.push(
          "Required Licenses and Certifications must be less than 4000 characters",
        );
      }
      if (!regex.test(state?.requiredLicenses)) {
        submitErrors.push(
          "Required Licenses has an unexpected special character which is not allowed (Example: bullets).",
        );
      }
      if (state?.securityAccessRequirements.length > 4000) {
        submitErrors.push(
          "Security Access Requirements must be less than 4000 characters",
        );
      }
      if (!regex.test(state?.securityAccessRequirements)) {
        submitErrors.push(
          "Security Access Requirements has an unexpected special character which is not allowed (Example: bullets).",
        );
      }
      if (state?.comments.length > 4000) {
        submitErrors.push("Comments must be less than 4000 characters");
      }
      if (!regex.test(state?.comments)) {
        submitErrors.push(
          "Comments has an unexpected special character which is not allowed (Example: bullets).",
        );
      }
    }

    return submitErrors;
  };

  const handleSubmitNewPrr = (prr: PersonnelRequisition) => {
    submitNewPrrMutation.mutate({ token, loggedOnUserName: username, prr });
  };

  const handleSubmitExistingPrr = (prr: PersonnelRequisition) => {
    prr.personnelRequisitionGuid = state?.personnelRequisitionGuid;
    prr.prrNumber = state?.prrNumber;

    submitExistingPrrMutation.mutate({
      token,
      loggedOnUserName: username,
      prrGuid: prr.personnelRequisitionGuid,
      prr,
    });
  };

  const handleSaveNewPrr = (prr: PersonnelRequisition) => {
    saveNewPrrMutation.mutate({ token, loggedOnUserName: username, prr });
  };

  const handleSaveExistingPrr = (prr: PersonnelRequisition) => {
    prr.personnelRequisitionGuid = state?.personnelRequisitionGuid;
    prr.prrNumber = state?.prrNumber;

    saveExistingPrrMutation.mutate({
      token,
      loggedOnUserName: username,
      prrGuid: prr.personnelRequisitionGuid,
      prr,
    });
  };

  const preprocessPrr = (actionType: "submit" | "save") => {
    const newPersonnel: PersonnelRequisition = {
      createdBy: context?.loggedOnUser?.userGuid,
      createdOn: state?.createdOn,
      dateNeeded: state.dateNeeded,
      employeeType: state?.employeeType === "Direct" ? "1" : "0",
      companyCode: state?.companyCode,
      employingCompany: state?.employingCompany,
      projectNumber:
        state?.projectNumber !== "" && state?.projectNumber !== null
          ? state?.projectNumber
          : state?.chargeNumber,
      projectName: state?.projectName,
      requestReason: state?.requestReason,
      replacementEmployee: state?.replacementEmployee || null,
      //Position Information
      employmentType: state?.employmentType,
      jobTitle: state?.jobTitle,
      jobDescription: state?.jobDescription,
      attachmentList: state?.attachmentFileList
        ? state?.attachmentFileList
        : state?.additionalAttachmentFileList ?? [],
      additionalAttachmentFileList: state?.additionalAttachmentFileList,
      laborGroup: state?.laborGroup,
      organization: state?.organization,
      vacancies: state?.vacancies?.toString(),
      department: state?.department ?? "",
      isHiringManager: state.isHiringManager === "Yes" ? true : false,
      hiringManager:
        state.isHiringManager === "Yes"
          ? context.loggedOnUser.bambooHRID
          : state.hiringManager,

      locationList:
        state?.locationList?.length > 0
          ? state.locationList
              .map((location) => `${location.city}, ${location.state}`)
              .join(";")
          : "",

      //Optional Information
      wageType: state?.wageType,
      wageMinimum: Number(state?.wageMinimum),
      wageMaximum: Number(state?.wageMaximum),
      yearsExperience: state?.yearsExperience,
      education: state?.education,
      travelRequired: state?.travelRequired === "Yes" ? true : false,
      travelAmount: state?.travelAmount,
      requiredSkills: state?.requiredSkills,
      requiredLicenses: state?.requiredLicenses,
      desiredSkills: state?.desiredSkills,
      securityAccessRequirements: state?.securityAccessRequirements,
      specialSkillsTraining: state?.specialSkillsTraining,
      comments: state?.comments,

      //Hidden Fields
      status:
        actionType === "submit"
          ? state?.status === PrrStatus.DRAFT
            ? PrrStatus.SUBMITTED
            : state?.status
          : PrrStatus.DRAFT,
      requisitionType: state?.requisitionType,
      isCompleted: state?.isCompleted === "Yes" ? "true" : "false",
      prrTypeDetail: state?.prrTypeDetail,
      prrNumber: "",
      chargeNumber: "",
    };

    return newPersonnel;
  };

  const startSubmitLoading = () => {
    const waitMessage =
      pageMode === RequestPageMode.PRR_EDIT
        ? "Personnel request is being updated....."
        : "Personnel request is being created.....";

    setSubmitLoading({
      show: true,
      message: [waitMessage],
      messageType: EnConfirmationMessageType.Wait,
    });
  };

  const onSubmit = async (e) => {
    startSubmitLoading();
    const submitErrors = getValidationErrorsOnSubmit(e);

    if (submitErrors.length > 0) {
      setDialogError(submitErrors);
      return;
    }

    const newPersonnel = preprocessPrr("submit");

    if (pageMode === RequestPageMode.PRR_EDIT) {
      handleSubmitExistingPrr(newPersonnel);
    } else {
      handleSubmitNewPrr(newPersonnel);
    }
  };

  const onSave = async (e) => {
    startSubmitLoading();
    const submitErrors = getValidationErrorsOnSubmit(e);

    if (submitErrors.length > 0) {
      setDialogError(submitErrors);
      return;
    }

    const newPersonnel = preprocessPrr("save");

    if (pageMode === RequestPageMode.PRR_EDIT) {
      handleSaveExistingPrr(newPersonnel);
    } else {
      handleSaveNewPrr(newPersonnel);
    }
  };

  const setDialogSuccess = (
    prrNumber: string,
    actionType: string,
    alertMessage: string,
  ) => {
    const prrTypeName =
      state?.prrType === PrrRequestType.PERSONNEL_REQUISITION
        ? "PRR"
        : "Edit PRR";
    const message =
      actionType === "Submit"
        ? `${prrNumber} has been successfully created.`
        : `${prrNumber} has been successfully saved as draft.`;
    const title =
      actionType === "Submit"
        ? prrTypeName + " Creation Successful"
        : prrTypeName + " Saved as Draft";
    setSubmitLoading({
      show: true,
      message: [message],
      title: title,
      messageType: EnConfirmationMessageType.Success,
      alertMessageOnSuccess: alertMessage,
      onClose: () => {
        setRedirect({ shouldRedirect: true, url: "/prr" });
        setSubmitLoading({ show: false });
      },
    });
  };

  const setDialogSuccessForExistingPrr = (
    prrNumber: string,
    actionType: string,
    alertMessage: string,
  ) => {
    const prrTypeName = "PRR";
    const message =
      actionType === "Submit"
        ? `${prrNumber} has been successfully updated.`
        : actionType === "Delete"
          ? null
          : `${prrNumber} has been successfully saved as draft.`;
    const title =
      actionType === "Submit"
        ? prrTypeName + " Update Successful"
        : actionType === "Delete"
          ? prrTypeName + " Delete Successful"
          : prrTypeName + " Saved as Draft";

    setSubmitLoading({
      show: true,
      message: message ? [message] : null,
      title: title,
      messageType: EnConfirmationMessageType.Success,
      alertMessageOnSuccess: alertMessage,
      onClose: () => {
        setRedirect({ shouldRedirect: true, url: "/prr" });
        setSubmitLoading({ show: false });
      },
    });
  };

  const setDialogError = (errorMessage: string[]) => {
    setSubmitLoading({
      show: true,
      title: "Error",
      messageType: EnConfirmationMessageType.Error,
      message: errorMessage,
      onClose: () => {
        setSubmitLoading({ show: false });
      },
    });
  };

  const setDialogWarningForApprovals = (errorMessage: string[]) => {
    setSubmitLoading({
      show: true,
      title: "Warning",
      messageType: EnConfirmationMessageType.Warning,
      message: errorMessage,
      onClose: () => {
        setRedirect({ shouldRedirect: true, url: "/prr?view=drafts" });
        setSubmitLoading({ show: false });
      },
    });
  };

  const activeSections = ["0", "1", "2", "3", "4"];
  const bc = {
    breadCrumbs: breadCrumbs,
  };

  const canSubmit = () => {
    if (!permission) return true;
    if (permission.isEditable) return true;
    return false;
  };

  const canSave = () => {
    if (
      (!permission || permission.isEditable) &&
      state?.status !== PrrStatus.REQUESTOR
    )
      return true;
    return false;
  };

  const canClone = () => {
    if (
      state?.prrTypeDetail === EnPrrTypeDetail.PRR_CLONE ||
      state?.prrTypeDetail === EnPrrTypeDetail.PRR_EDIT
    ) {
      return true;
    }
    return false;
  };

  const handleLaborGroupLoading = (isLoading: boolean) => {
    setIsLaborGroupLoading(isLoading);
  };

  return (
    <>
      {redirect.shouldRedirect && <Redirect push to={redirect.url} />}
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <div className="row mb-3 align-items-md-center">
        <div className="col">
          <div className="mb-3 mb-md-0">
            <XtBreadcrumbs {...bc} />
          </div>
        </div>

        <div className="col-auto">
          {state?.status === PrrStatus.DRAFT && (
            <DeleteButton
              prrGuid={prrGuid}
              setLoading={setLoading}
              loading={loading}
              pageMode={pageMode}
              setDailogSuccessForExistingReq={setDialogSuccessForExistingPrr}
              setDialogError={setDialogError}
            />
          )}
          <span className="px-3 py-2 fw-bold text-white d-inline-block pr-status-draft">
            {state?.status}
          </span>
        </div>
      </div>

      <div className="pr-create-new">
        <XtForm onSubmit={onSubmit}>
          <Accordion defaultActiveKey={activeSections} alwaysOpen>
            <XtAccordianItem header="GENERAL" eventKey="0">
              <NwGeneralInfo state={state} dispatch={dispatch} />
            </XtAccordianItem>
            <XtAccordianItem header="POSITION INFO" eventKey="4">
              <NwPositionInfo
                state={state}
                dispatch={dispatch}
                pageMode={pageMode}
                handleLaborGroupLoading={handleLaborGroupLoading}
              />
            </XtAccordianItem>
            <XtAccordianItem header="ADDITIONAL" eventKey="6">
              <NwAdditionalInfo
                state={state}
                dispatch={dispatch}
                pageMode={pageMode}
              />
            </XtAccordianItem>
          </Accordion>
          <DialogActionsBar layout="end">
            {state?.status !== PrrStatus.DRAFT && (
              <CancelButtonEdit
                prrNumber={prrGuid}
                setLoading={setLoading}
                loading={loading}
                pageMode={pageMode}
                setDailogSuccessForExistingReq={setDialogSuccessForExistingPrr}
                setDialogError={setDialogError}
              />
            )}
            {canClone() && !isClone && (
              <CloneButton loadClone={loadPrrClone} loading={loading} />
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                setRedirect({ shouldRedirect: true, url: "/prr" });
              }}
              disabled={loading}
            >
              Close
            </Button>

            {canSave() && (
              <>
                <SaveButton
                  loading={loading || isLaborGroupLoading}
                  onSave={(e) => onSave({ ...e, postData: state })}
                />
              </>
            )}

            {canSubmit() && (
              <>
                <SubmitButton
                  loading={loading || isLaborGroupLoading}
                  onSubmit={onSubmit}
                />
              </>
            )}
          </DialogActionsBar>
        </XtForm>
        {loading && <Loading />}
        {submitLoading && submitLoading.show && (
          <LoadingDialog {...submitLoading} />
        )}
      </div>
    </>
  );
}
