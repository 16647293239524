import { Button } from "react-bootstrap";
import { EnApprovalMode } from "../../Interfaces/Enums";

interface IApproveButtonProps {
  onApprovalButtonClick: (approvalMode: EnApprovalMode) => void;
}

export default function ApproveButton(props: IApproveButtonProps) {
  const { onApprovalButtonClick } = props;
  return (
    <Button
      type="button"
      variant="primary"
      id="submit"
      onClick={() => {
        onApprovalButtonClick(EnApprovalMode.Approve);
      }}
    >
      Approve
    </Button>
  );
}
