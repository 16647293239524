import { XtField } from "nwcommon";
import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import {
  APIFactory,
  ResourceAttributeApi as PrResourceAttributeApi,
} from "../../../../../Xt/ApiClients";
import { APIFactoryCommon } from "../../../../../Xt/ApiClients/Common";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { defaultApiConfiguration } from "../../../../Configurations/config";
import FormField, { RequiredFormFieldWithParam } from "../Common/FormField";
import { enum_request_type } from "../Common/NwPrConstants";
import PopOverIcon from "../Common/PopOverIcon";
import { PrApiService } from "../Common/PrApiService";
import { EnPrAction } from "../Common/PrReducer";
import AttachmentListWithDelete from "./AttachmentListWithDelete";
import { RequestPageMode } from "./NwCreateNewPr";

const competedInfo = (
  <PopOverIcon header="Competed">
    <div>
      <p>
        <b>Competed</b> - In accordance with COP-4002 “Acquisition of Goods and
        Services,” purchases that exceed $10,000 in amount, require competition.
        Competition is the act of soliciting two or more vendors/subcontractors
        for a scope of work.
      </p>
      <p>
        <b>Sole Source Justification</b> – If Competition was not solicited, the
        requestor must submit COF-4003.04, “Single or Sole-Source Justification
        Acquisitions that Exceed $10K.” Single or sole-source procurements
        cannot be justified on the basis of lack of advance planning or concerns
        related to funding availability.
      </p>
    </div>
  </PopOverIcon>
);

export default function NwAdditionalInfo(props) {
  const { state, dispatch } = props;
  let commonfactory: APIFactoryCommon;
  let prFactory: APIFactory;

  const [qualityLevels, setQualityLevels] = useState([]);
  const [leaseTypes, setLeaseTypes] = useState([]);

  const context: IXtContext = useContext(XtContext);

  const loadQualityLevels = () => {
    const api: PrResourceAttributeApi = prFactory.create(
      PrResourceAttributeApi,
    );
    PrApiService.getResourceAttributeList(
      context,
      api,
      "PR",
      "QualityLevel",
    ).then((data) => {
      //const sortedQualityLevel = data.sort(function (a, b) {
      //    return a.text.localeCompare(b.text);
      const sortedQualityLevel = data;
      //return sortedQualityLevel;
      //});

      setQualityLevels(sortedQualityLevel);
    });
  };

  const loadLeaseTypes = () => {
    const api: PrResourceAttributeApi = prFactory.create(
      PrResourceAttributeApi,
    );
    PrApiService.getResourceAttributeList(context, api, "PR", "lease").then(
      (data) => {
        setLeaseTypes(data);
      },
    );
  };

  useEffect(() => {
    if (context?.loggedOnUser?.username) {
      loadQualityLevels();
      loadLeaseTypes();
    }
  }, [context?.loggedOnUser?.username]);

  const handleDownload = (e) => {
    const fileUrl = `${defaultApiConfiguration.basePath}/files/SingleSoleSourceJustification.doc`;

    e.preventDefault();
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "SingleSoleSourceJustification.doc";
        link.click();
      });
  };

  return (
    <>
      <APIFactoryCommon
        ref={(e) => {
          commonfactory = e;
        }}
      />

      <APIFactory
        ref={(e) => {
          prFactory = e;
        }}
      />
      <Row>
        <Col md={6}>
          <Row className="mb-3">
            <Col sm={4} className="d-flex align-content-center">
              <Form.Label className="me-1">
                Quality Level
                <span className="text-danger">*</span>
              </Form.Label>
              <PopOverIcon header="Quality Level">
                <div>
                  Is this a client deliverable or have an associated
                  specification or involve data collection? <br />
                  <ul>
                    <li>No (SelectNon-Quality)</li>
                    <li>Yes (Continue to Next Question)</li>
                  </ul>
                  Is the item/service nuclear-related or associated with an
                  analytical lab?
                  <ul>
                    <li>No (QL-2)</li>
                    <li>Yes (QL-1)</li>
                  </ul>
                  QA Manager approval will be required for Quality Level 1 and
                  2.
                  <br /> <br />
                  QL-1 includes laboratory services and items/services for
                  nuclear systems.
                  <br /> <br />
                  QL-2 includes items or services that have an associated
                  specification/design document, instruments used for data
                  collection.
                  <br /> <br />
                  Non-Quality includes consumable items, bonds, rental of
                  equipment (not used for data collection), temporary fencing,
                  site maintenance services, office supplies
                </div>
              </PopOverIcon>
            </Col>
            <Col sm={8}>
              <XtField
                type="dropdown"
                name="qualityLevel"
                items={qualityLevels}
                value={
                  qualityLevels.find((q) => q.value === state?.qualityLevel) ||
                  null
                }
                onChange={(e) => {
                  dispatch({
                    type: EnPrAction.QUALITY_LEVEL_CHANGE,
                    payload: e.value.value,
                  });
                }}
                textField="text"
                required={true}
                errormessage="Quality level is required"
                disabled={state?.chargeType === "Indirect"}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Label column sm={4}>
              Lease<span className="text-danger">*</span>
            </Form.Label>
            <Col xs={8} className="align-self-center ">
              <XtField
                type="radiogroup"
                name="isLeaseChecked"
                layout="horizontal"
                items={[
                  { value: "Yes", text: "Yes" },
                  { value: "No", text: "No" },
                ]}
                value={state?.isLeaseChecked}
                onChange={(e) =>
                  dispatch({
                    type: EnPrAction.IS_LEASE_CHECKED,
                    payload: e.value,
                  })
                }
                required={true}
                errormessage="Lease is required"
              />

              {state?.isLeaseChecked === "Yes" && (
                <Row className="mt-3">
                  <Col xs={12}>
                    <XtField
                      type="dropdown"
                      name="leaseType"
                      items={leaseTypes}
                      value={leaseTypes.find(
                        (l) => l.value === state?.leaseType,
                      )}
                      textField="text"
                      required={true}
                      onChange={(e) =>
                        dispatch({
                          type: EnPrAction.LEASE_CHANGE,
                          payload: e.value.value,
                        })
                      }
                      errormessage="Lease type is required"
                    />
                  </Col>{" "}
                </Row>
              )}
            </Col>
          </Row>
          <Row className="mb-3 double-width">
            <FormField label="Comments">
              <XtField
                name="comments"
                type="textarea"
                defaultValue={state?.comments}
                key={`comments-${state?.prNumber}`}
              />
            </FormField>
          </Row>
          <Row className="mb-3">
            <Form.Label column sm={4}>
              Attachments
            </Form.Label>
            <Col sm={8}>
              <XtField
                type="file"
                showtables={"true"}
                showdescription={false}
                multiple={true}
                name="additionalAttachmentFileList"
              />

              {props?.pageMode !== RequestPageMode.PO_CHANGE_REQUEST && (
                <AttachmentListWithDelete
                  files={state?.attachmentList?.filter(
                    (a) => a.attachmentType === "AdditionalAttachment",
                  )}
                  state={state}
                  dispatch={dispatch}
                />
              )}
            </Col>
          </Row>
        </Col>

        <Col md={6}>
          {state?.prType !== enum_request_type.PO_CHANGE_REQUEST && (
            <>
              {state?.totalAmount > 10000 && (
                <Row>
                  <RequiredFormFieldWithParam
                    label="Competed"
                    info={competedInfo}
                    Form_lg={12}
                    Label_sm={4}
                    Control_sm={8}
                  >
                    <XtField
                      type="radiogroup"
                      name="isCompeted"
                      layout="horizontal"
                      items={[
                        { value: "Yes", text: "Yes" },
                        { value: "No", text: "No" },
                      ]}
                      value={state?.isCompeted}
                      onChange={(e) =>
                        dispatch({
                          type: EnPrAction.COMPETED_CHANGE,
                          payload: e.value,
                        })
                      }
                      required={true}
                      errormessage="Competed is required"
                    />
                  </RequiredFormFieldWithParam>
                </Row>
              )}

              {state?.totalAmount > 10000 && state?.isCompeted === "Yes" && (
                <Row>
                  <Form.Label column sm={4}>
                    Upload Quotes<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm={8}>
                    <XtField
                      type="file"
                      showtables={"true"}
                      multiple={true}
                      name="quotesFileList"
                      showdescription={false}
                      required
                    />
                    {props?.pageMode !== RequestPageMode.PO_CHANGE_REQUEST && (
                      <AttachmentListWithDelete
                        files={state?.attachmentList?.filter(
                          (a) => a.attachmentType === "Quotes",
                        )}
                        state={state}
                        dispatch={dispatch}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {state?.totalAmount > 10000 &&
                state?.isCompeted === "No" &&
                props?.pageMode !== RequestPageMode.PO_CHANGE_REQUEST && (
                  <Row className="mb-3">
                    <Col sm={4}>
                      <a
                        href=""
                        onClick={handleDownload}
                        className="text-primary"
                      >
                        Upload Sole Source Justification Form
                      </a>
                    </Col>
                    <Col sm={8}>
                      <XtField
                        type="file"
                        showtables={"true"}
                        multiple={true}
                        name="ssjFormFileList"
                        showdescription={false}
                      />

                      {props?.pageMode !==
                        RequestPageMode.PO_CHANGE_REQUEST && (
                        <AttachmentListWithDelete
                          files={state?.attachmentList?.filter(
                            (a) => a.attachmentType === "SSJForm",
                          )}
                          state={state}
                          dispatch={dispatch}
                        />
                      )}
                    </Col>
                  </Row>
                )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
