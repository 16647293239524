import { useContext, useEffect, useState } from "react";
import { APIFactory, InvoiceApi } from "../../../../Xt/ApiClients";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import { DateUtil } from "../../../../Xt/Utils/DateUtil";

interface InvoiceAuditLogProps {
  invoiceGuid: string;
  auditLogComment?: any[];
}

export default function AuditLog(props: InvoiceAuditLogProps) {
  const context: IXtContext = useContext(XtContext);
  let factory: APIFactory;
  const init = true;
  const [logList, setWorkflowLogData] = useState([]);

  const loadLogData = () => {
    if (props?.invoiceGuid) {
      let api: InvoiceApi = factory.create(InvoiceApi);
      var id = props.invoiceGuid;
      api
        .invoiceReviewerHistoryInvoiceGuidGet(
          context?.loggedOnUser?.username,
          id
        )
        .then(async (data) => {
          setWorkflowLogData(data);
        });
    }
  };
  useEffect(() => {
    if (props?.invoiceGuid && context?.loggedOnUser?.username) {
      loadLogData();
    }
  }, [props?.invoiceGuid, context?.loggedOnUser?.username]);

  useEffect(() => {
    props?.auditLogComment &&
      setWorkflowLogData((prev) => [...props?.auditLogComment, ...prev]);
  }, [props?.auditLogComment]);

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      {logList.map((val, i) => (
        <div className="mb-4" key={`key-${i}`}>
          <div className="small d-flex justify-content-between text-muted mb-1">
            <div className="">
              <span>
                {" "}
                {val?.displayName === val.completedBy
                  ? val.displayName
                  : val.completedBy}
              </span>
              <span className="badge bg-primary  ms-2">{val.role}</span>
            </div>
            <div className="d-flex align-items-center">
              <i className="material-icons small me-1">schedule</i>
              {val.isAdditionalComment && "Commented on"}{" "}
              {!val.isAdditionalComment && (
                <>
                  {val.stage === 1 && "Submitted on"}{" "}
                  {val.stage !== 1 &&
                    val.isRejected === false &&
                    val.isRevised === false &&
                    "Approved on"}{" "}
                  {val.stage !== 1 &&
                    val.isRejected === true &&
                    val.isRevised === false &&
                    "Rejected on"}{" "}
                  {val.stage !== 1 && val.isRevised === true && "Revised on"}{" "}
                </>
              )}
              {DateUtil.convertUTCDateToLocalDate(val.timeStamp)}
            </div>
            {/* <div className="d-flex align-items-center">
                  <i className="material-icons small me-1">schedule</i>
                  {val.stage === '1' && "Submitted on"} 
                  {val.stage !== '1' && val.isRejected === false && val.isRevised === false && "Approved on"} {' '} 
                  {val.isRejected === true && "Rejected on"} 
                  {val.isRevised === true && "Revised on"} {' '}  
                  {DateUtil.convertUTCDateToLocalDate(val.timeStamp)} {val.displayName === val.completedBy ? "" : (" by " + val.completedBy)}
              </div> */}
          </div>
          {val?.comment && val?.comment?.length > 0 && (
            <small className="d-block fw-bold mb-0 text-primary">
              {val?.comment}
            </small>
          )}
          {val?.comment && val?.comment?.length === 0 && (
            <div className="text-muted text-uppercase small font-weight-bold ml-3 d-block">
              {" "}
              No Comments
            </div>
          )}
        </div>
      ))}
    </>
  );
}
