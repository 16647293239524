import React, { useState } from "react";
import { Button, Container, NavLink } from "react-bootstrap";
import { enum_pr_status } from "../NwPrConstants";
import { Redirect } from "react-router-dom";

export default function PoStatusGridCell(props) {
    const status: string = props.dataItem["s_PO_STATUS_TYPE"];
    let value = status;
    if (value === "C")
        value = "Closed"
    else if (value === "S")
        value = "System Closed"
    else if (value === "V")
        value = "Void"
    else if (value === "O")
        value = "Open"
    return (<td>{value}</td>);

}
