import { GridColumn } from "@progress/kendo-react-grid";
import { useState } from "react";
import { XtAdvancedSearchFieldCondition } from "nwcommon";
import { XtPaging } from "../ApiClients/ApiHelper";
import { XtGridRowSelection } from "nwcommon";
import { XtExportType } from "nwcommon";
import { XtGridState } from "./XtGridState";

export const AdvSearchAndGridUtil = {
  getExportType: (e: any) => {
    return {
      type: e.type,
      fields: e.dataItems,
      filename: "test." + (e.type === "pdf" ? "pdf" : "xlsx"),
    };
  },

  mapSchema: (
    entity: string,
    columns: any[],
    searchFields: XtAdvancedSearchFieldCondition[],
    searchParams: any,
    Search: any
  ) => {
    columns.map((column, i) => {
      let advSearchSelectedValue = null;
      searchParams.advancedSearchCondition?.map((sp, i) => {
        if (column.field === sp.field.field) {
          advSearchSelectedValue = sp;
        }
      });
      if (column.type === "text" && column.searchable === true) {
        searchFields.push(
          Search.getTextboxType(
            entity,
            column.field,
            column.label,
            advSearchSelectedValue?.value,
            advSearchSelectedValue?.operator.value,
            advSearchSelectedValue?.value2
          )
        );
      } else if (column.type === "number" && column.searchable === true) {
        searchFields.push(
          Search.getNumericType(
            entity,
            column.field,
            column.label,
            advSearchSelectedValue?.value,
            advSearchSelectedValue?.operator.value,
            advSearchSelectedValue?.value2
          )
        );
      } else if (column.type === "dropdown" && column.searchable === true) {
        searchFields.push(
          Search.getDropdownType(
            entity,
            column.field,
            column.label,
            column.options,
            advSearchSelectedValue?.value,
            advSearchSelectedValue?.operator.value
          )
        );
      } else if (column.type === "date" && column.searchable === true) {
        searchFields.push(
          Search.getDatePickerType(
            entity,
            column.field,
            column.label,
            advSearchSelectedValue?.value,
            advSearchSelectedValue?.operator.value,
            advSearchSelectedValue?.value2
          )
        );
      } else if (column.type === "boolean" && column.searchable === true)
        searchFields.push(
          Search.getBooleanType(
            entity,
            column.field,
            column.label,
            [
              { text: "Active", value: "true" },
              { text: "Inactive", value: "false" },
            ],
            advSearchSelectedValue?.value,
            advSearchSelectedValue?.operator.value
          )
        );
    });
  },
  renderGridFields: (columns: any[]) => {
    let items: any[] = [];
    columns.map((column, i) => {
      if (column.visible)
        items.push(
          <GridColumn
            sortable={true}
            key={"row" + i}
            field={column.field}
            title={column.label}
            {...column}
          />
        );
    });
    return items;
  },

  onSortChange: (e: any, { paging, setPaging }) => {
    if (e.sort.length > 0) setPaging({ ...paging, sort: e.sort[0] });
    else {
      setPaging({
        ...paging,
        sort: {
          ...paging.sort,
          dir: paging.sort.dir === "asc" ? "desc" : "asc",
        },
      });
    }
  },

  onExportAfterRender: (e: any, { isExported, setIsExported }) => {
    if (e && !isExported) {
      e.save();
      setIsExported(true);
    }
  },
  useXtGridSate: (pageSize: number, orderByField: string) => {
    const [exportType, setExportType] = useState<any>({
      type: "none",
      fields: [],
      filename: "",
    });
    const [data, setData] = useState<any[]>([]);
    const [reportData, setReportData] = useState<any[]>([]);
    const [dataCount, setDataCount] = useState<number>(0);
    const [dialogTitle, setDialogTitle] = useState<string>("");
    const [isExported, setIsExported] = useState<boolean>(false);
    const [paging, setPaging] = useState<XtPaging>({
      skip: 0,
      top: pageSize,
      sort: { field: orderByField, dir: "desc" },
    });
    const [defaultPage, setDeafultPaging] = useState<XtPaging>({
      skip: 0,
      top: pageSize,
      sort: { field: orderByField, dir: "desc" },
    });
    const [searchParams, setSearchParam] = useState<any>({});
    const [gridSelection, setGridSelection] = useState<XtGridRowSelection>({
      headerSelected: false,
      differedData: [],
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [time, setTime] = useState<number>(0);

    const xtGridState: XtGridState = {
      exportType,
      setExportType,
      data,
      setData,
      reportData,
      setReportData,
      dataCount,
      setDataCount,
      dialogTitle,
      setDialogTitle,
      isExported,
      setIsExported,
      paging,
      defaultPage,
      setPaging,
      searchParams,
      setSearchParam,
      gridSelection,
      setGridSelection,
      loading,
      setLoading,
      showDialog,
      setShowDialog,
      time,
      setTime,
    };

    return xtGridState;
  },
};
