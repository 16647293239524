import { Link } from "react-router-dom";
export default function InvoiceLinkGridCell(props) {
  let vendorInvoiceNumber: string = props.dataItem["vendorInvoiceNo"];
  let invoiceGuid: string = props.dataItem["invoiceGuid"];

  return (
    <>
      <td>
        <Link to={"/invoice/details/" + invoiceGuid} className="text-primary">
          {vendorInvoiceNumber}
        </Link>
      </td>
    </>
  );
}

export function InvoiceIdLinkGridCell(props) {
  let invoiceID: string = props.dataItem["invoiceID"];
  let invoiceGuid: string = props.dataItem["invoiceGuid"];

  return (
    <>
      <td>
        <a
          href={"/invoice/details/" + invoiceGuid}
          target="_blank"
          rel="noreferrer"
          className="text-primary"
        >
          {invoiceID}
        </a>
      </td>
    </>
  );
}
