import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Notification,
  NotificationGroup
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

export type NwNotificationType = "success" | "warning" | "error" | "info";

export interface NwNotificationProps {
  showNotification: boolean;
  type: NwNotificationType;
  onClose: any;
  message: string;
  timeout?: number;
}

export function NwNotification(props: NwNotificationProps) {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {

    setShow(props.showNotification);
    if (props.showNotification) {
      setTimeout(() => onClose(), props?.timeout ?? 2000);
    }
  }, [props.showNotification]);
  const onClose = () => {
    setShow(false);
    if(props?.onClose)
        props?.onClose();
  }

  return (
    <React.Fragment>
      <NotificationGroup
      >
        <Fade>
          {show && (
            <Notification
              type={{ style: props?.type, icon: true }}
              closable={true}
              onClose={onClose}
            >
              <span>{props?.message}</span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </React.Fragment>
  );
}
