import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { defaultXtContext, XtContext, IXtContext } from '../../Xt/Contexts/XtContext';
import { XtContextProvider } from '../../Xt/Contexts/XtContextProvider';
import { defaultApiConfiguration, OktaConfig } from "../Configurations/config"

export function XtSilentLogin(props){
	const context: IXtContext = React.useContext(XtContext);
	const { authState, oktaAuth } = useOktaAuth();
	  React.useEffect(() => {
	  	if(authState && authState.isAuthenticated === true){
				var co = context;
	  	}
	    oktaAuth.signInWithRedirect()
	  }, [authState, oktaAuth]); // Update if authState changes		
	return (
		<XtContextProvider xtContext={defaultXtContext} config={{ apiBasePath: defaultApiConfiguration.basePath, oktaBasePath: OktaConfig.app.basename, commonBasePath: defaultApiConfiguration.commonAPIPath }}>
			</XtContextProvider>
		)
}