import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Loading } from "../../../../Xt/Layout/Loading";
export interface ErrorDialogProps {
  errorMessage?: string[];
  onClose?: any;
  width?:string;
  title?:string;
}

export default function ErrorDialog(props: ErrorDialogProps) {
  return (
    <Dialog onClose={props.onClose}  title={ props?.title??"Error"} width={props?.width}>
      <>
        <div className="alert alert-danger">
          <ul style={{ listStyleType: "none", paddingLeft:"0"} }>
            {props?.errorMessage.map((e) => (
              <li> {e} </li>
            ))}
          </ul>
        </div>
        <DialogActionsBar layout="end">
          <Button type="button" variant="primary" onClick={props.onClose}>
            Ok
          </Button>
        </DialogActionsBar>
      </>
    </Dialog>
  );
}
