import React, { useContext, useEffect, useState } from "react";
import { Redirect, useParams, useLocation } from "react-router-dom";
import {
  APIFactory,
  InvoiceApi,
  PurchaseOrderApi,
  PurchaseRequisitionApi,
} from "../../../../Xt/ApiClients";
import { XtBreadcrumbs } from "../../../../Xt/Layout/XtBreadcrumbs";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import FormField, {
  FormField3Column,
  FormFieldWithParam,
} from "../PurchaseRequisition/Common/FormField";
import FormLabelMutedText, {
  FormLabelMutedAmount,
} from "../PurchaseRequisition/Common/FormLabelMutedText";
import { NumberUtil } from "../../../../Xt/Utils/NumberUtil";
import XtAccordianItem from "../PurchaseRequisition/Common/XtAccordianItem";
import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { AdvSearchAndGridUtil } from "../../../../Xt/Utils/AdvanceSearchAndGridUtil";
import NwPrStatusGridCell from "../PurchaseRequisition/Common/GridCell/NwPrStatusGridCell";

import { DateUtil } from "../../../../Xt/Utils/DateUtil";
import RightAlignGridColumnHeader from "../PurchaseRequisition/Common/GridCell/RightAlignGridColumnHeader";
import RightAlignGridCell from "../PurchaseRequisition/Common/GridCell/NumberGridCell";
import AmountGridCell, {
  NumberGridCellWithComma,
} from "../PurchaseRequisition/Common/GridCell/AmountGridCell";
import PrNumberGridCell from "../PurchaseRequisition/Common/GridCell/PrNumberGridCell";
import DateGridCell from "../PurchaseRequisition/Common/GridCell/DateGridCell";
import InvoiceStatusGridCell from "../../invoice/Invoice";
import InvoiceLinkGridCell, {
  InvoiceIdLinkGridCell,
} from "../PurchaseRequisition/Common/GridCell/InvoiceDetailLinkGridCell";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import { Loading } from "../../../../Xt/Layout/Loading";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { XtPaging } from "../../../../Xt/ApiClients/ApiHelper";

const gridSchemas = {
  purchaseOrderItems: [
    {
      field: "lineItemNumber",
      label: "#",
      visible: true,
      searchable: false,
      type: "number",
      width: 100,
    },
    {
      field: "chargeCode",
      label: "Charge Code",
      visible: true,
      searchable: false,
      type: "text",
      width: 100,
    },
    {
      field: "type",
      label: "Type",
      visible: true,
      searchable: false,
      type: "text",
      width: 150,
    },

    {
      field: "accountNumber",
      label: "Account Number",
      visible: true,
      searchable: false,
      type: "text",
      width: "150px",
    },
    {
      field: "description",
      label: "Description",
      visible: true,
      searchable: false,
      type: "text",
      minWidth: 150,
    },
    {
      field: "quantity",
      label: "Quantity",
      visible: true,
      searchable: false,
      type: "number",
      width: 80,
      headerCell: RightAlignGridColumnHeader,
      cell: RightAlignGridCell,
    },
    {
      field: "unit",
      label: "Unit",
      visible: true,
      searchable: false,
      type: "number",
      width: 60,
    },
    {
      field: "unitPrice",
      label: "Unit Price",
      visible: true,
      searchable: false,
      type: "number",
      width: 100,
      format: "{0:c2}",
      headerCell: RightAlignGridColumnHeader,
      cell: AmountGridCell,
    },

    {
      field: "startDate",
      label: "Start Date",
      format: "{0:MM/dd/yyyy}",
      editor: "date",
      visible: true,
      searchable: false,
      type: "date",
      width: 100,
    },
    {
      field: "endDate",
      label: "End Date",
      format: "{0:MM/dd/yyyy}",
      visible: true,
      editor: "date",
      searchable: false,
      type: "date",
      width: 100,
    },
    {
      field: "total",
      label: "Total",
      visible: true,
      searchable: false,
      type: "number",
      width: 150,
      format: "{0:c2}",
      headerCell: RightAlignGridColumnHeader,
      cell: AmountGridCell,
    },
    {
      field: "costToDate",
      label: "Cost to Date",
      visible: true,
      searchable: false,
      type: "number",
      width: 150,
      format: "{0:c2}",
      headerCell: RightAlignGridColumnHeader,
      cell: AmountGridCell,
    },
    ,
    {
      field: "remainingAmount",
      label: "Remaining Amount",
      visible: true,
      searchable: false,
      type: "number",
      width: 150,
      format: "{0:c2}",
      headerCell: RightAlignGridColumnHeader,
      cell: AmountGridCell,
    },
  ],
  prHistoryDetails: [
    {
      field: "prNumber",
      label: "Purchase Req.",
      visible: true,
      searchable: false,
      type: "text",
      width: 250,
      cell: PrNumberGridCell,
    },
    {
      field: "createdOn",
      label: "Created Date",
      format: "{0:MM/dd/yyyy}",
      visible: true,
      searchable: false,
      type: "date",
      width: 250,
    },

    {
      field: "prType",
      label: "PR Type",
      visible: true,
      searchable: false,
      type: "text",
      width: 330,
    },
    {
      field: "totalAmount",
      label: "Total Amount",
      visible: true,
      searchable: false,
      type: "number",
      width: 260,
      format: "{0:c2}",
      headerCell: RightAlignGridColumnHeader,
      cell: AmountGridCell,
    },
    {
      field: "status",
      label: "Status",
      visible: true,
      searchable: true,
      type: "text",
      cell: NwPrStatusGridCell,
      width: 250,
    },
  ],
  invoiceHistoryDetails: [
    {
      field: "invoiceID",
      label: "Invoice ID",
      visible: true,
      searchable: false,
      type: "text",
      width: 250,
      cell: InvoiceIdLinkGridCell,
    },

    {
      field: "vendorInvoiceNo",
      label: "Vendor Invoice #",
      visible: true,
      searchable: false,
      type: "text",
      width: 250,
    },
    {
      field: "invoiceDate",
      label: "Invoice Date",
      format: "{0:MM/dd/yyyy}",
      visible: true,
      editor: "date",
      searchable: false,
      type: "date",
      width: 250,
      cell: DateGridCell,
    },
    {
      field: "accountNumber",
      label: "Account Number",
      visible: true,
      searchable: false,
      type: "text",
      width: 330,
    },
    {
      field: "totalAmount",
      label: "Total Amount",
      visible: true,
      searchable: false,
      type: "number",
      width: 260,
      format: "{0:c2}",
      headerCell: RightAlignGridColumnHeader,
      cell: AmountGridCell,
    },
    {
      field: "status",
      label: "Status",
      visible: true,
      searchable: true,
      type: "text",
      cell: InvoiceStatusGridCell,
      width: 250,
    },
  ],
};

const getDate = (date) => {
  return date && new Date(date) > new Date("1900/01/01")
    ? new Date(date)
    : null;
};
const getAccountNumber = (poItem) => {
  return poItem?.lineItemAccountList && poItem?.lineItemAccountList.length > 0
    ? poItem?.lineItemAccountList[0]?.pO_POLNACCT_ACCT_ID
    : "";
};
const mapPoDetailsFromApiToPoDetailsForUi = (poDetailsFromApi) => {
  let poStatus = poDetailsFromApi?.poStatusType;
  if (poStatus === "C") poStatus = "Closed";
  else if (poStatus === "S") poStatus = "System Closed";
  else if (poStatus === "V") poStatus = "Void";
  else if (poStatus === "O") poStatus = "Open";

  const poOrderLineItems = poDetailsFromApi.poLineItemList.map((p) => {
    return {
      lineItemNumber: p.lineNumber,
      chargeCode: p.chargeCode,
      type: p.accountType,
      accountNumber: p.accountID,
      description: p.lineDescription,
      quantity: p.quantity,
      unit: p.unit,
      unitPrice: p.unitPrice,
      startDate: p.startDate ? getDate(p.startDate) : "",
      endDate: p.endDate ? getDate(p.endDate) : "",
      total: p.orderedAmount,
      costToDate: p.committedAmount,
      remainingAmount: p.remainingAmount,
      accountName: p.accountName,
      typeName: p.typeName,
      chargeCodeName: p.chargeCodeName,
      unitName: p.unitName,
    };
  });

  const endDateRecord = poOrderLineItems
    ?.filter((c) => c?.endDate)
    ?.reduce(function (prev, current) {
      return prev?.endDate > current?.endDate ? prev : current;
    }, undefined);

  const startDateRecord = poOrderLineItems
    ?.filter((c) => c?.startDate)
    ?.reduce(function (prev, current) {
      return prev?.startDate < current?.startDate ? prev : current;
    }, undefined);

  return {
    poDetailsNumber: poDetailsFromApi.poNumber,
    projectNumber: poDetailsFromApi.projectNumber,
    vendorName: poDetailsFromApi.vendorName,
    totalAmount: poDetailsFromApi.totalAmount,
    costToDateAmount: poDetailsFromApi.costToDateAmount,
    remainingAmount: poDetailsFromApi.remainingAmount,
    runningTotal: poDetailsFromApi.runningTotal,
    buyerID: poDetailsFromApi.buyerID,
    buyerName: poDetailsFromApi.BuyerName,
    orderDate: poDetailsFromApi.orderDate,
    startDate: DateUtil.formatmmDdYYY(startDateRecord?.startDate),
    endDate: DateUtil.formatmmDdYYY(endDateRecord?.endDate),
    projectManager: "",
    subcontractAdmin: "",
    procurementRep: "",
    purchaseOrderLineItems: poOrderLineItems,
    companyName: poDetailsFromApi.companyName,
    status: poStatus,
    vendorID: poDetailsFromApi.vendorID,
  };
};
const initialSort: Array<SortDescriptor> = [
  { field: "invoiceDate", dir: "desc" },
];

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 15 };

export default function NwPODetails() {
  // const prGuid= "8801ec6c-cc5a-409f-8389-a7efff5d8bbe";
  let factory: APIFactory;
  const context: IXtContext = useContext(XtContext);
  // const pageSize = 15;
  const orderByField = "invoiceDate";

  const activeSections = ["0", "1", "2"];
  const redirectTo = { shouldRedirect: false, url: "" };
  const [redirect, setRedirect] = useState(redirectTo);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [poDetails, setPoDetails] = useState(null);
  const [sort, setSort] = useState(initialSort);
  const [prDetails, setPrDetails] = useState(null);
  const [prHistory, setPrHistory] = useState([]);
  const [showCreatePoChangeRequest, setShowCreatePoChangeRequest] =
    useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [loading, setLoading] = useState(0);
  const [allowToAddInvoice, setAllowToAddInvoice] =
    React.useState<string>(null);

  let { poNumber } = useParams();
  const onPageChange = (event: GridPageChangeEvent) => {
    setPage({
      ...event.page,
    });
  };

  const onGridRowClick = (e) => {
    let url = "/pr/view/" + e.dataItem.purchaseRequisitionGuid;

    window.open(url, "_blank");
  };

  const onInvoiceGridRowClick = (e) => {
    let url =
      e.dataItem.isEditable && e.dataItem?.status?.toLowerCase() === "draft"
        ? "/invoice/edit/" + e.dataItem.invoiceGuid
        : "/invoice/details/" + e.dataItem.invoiceGuid;
    window.open(url, "_blank");
  };

  const breadCrumbs = {
    breadCrumbs: [
      {
        href: "/po",
        text: "Purchase Orders ",
        isActive: false,
      },
      {
        href: "/po/details",
        text: "" + poNumber,
        isActive: true,
      },
    ],
  };

  const loadPoHistory = () => {
    let prApi: PurchaseRequisitionApi = factory.create(PurchaseRequisitionApi);
    setLoading((loading) => loading + 1);
    prApi
      .purchaseRequisitionPOHistoryPoNumberGet(
        poNumber,
        context?.loggedOnUser?.username
      )
      .then(async (data) => {
        var dataFromApi = await data.json();
        const historyList = dataFromApi.value.map((h) => {
          return { ...h, createdOn: new Date(h.createdOn) };
        });

        const statusOtherThanPoCreatedAndPoUpdated = historyList?.find(
          (h) =>
            h?.status?.toLowerCase() !== "po created" &&
            h?.status?.toLowerCase() !== "po updated" &&
            h?.status?.toLowerCase() !== "rejected"
        );
        if (statusOtherThanPoCreatedAndPoUpdated)
          setShowCreatePoChangeRequest(false);
        else setShowCreatePoChangeRequest(true);

        setPrHistory(historyList);
      })
      .finally(() => setLoading((loading) => loading - 1));
  };

  const loadInvoiceHistory = () => {
    let filterValue = `poNumber eq '${poNumber}'`;
    let invoiceApi: InvoiceApi = factory.create(InvoiceApi);
    setLoading((loading) => loading + 1);

    invoiceApi
      .invoiceGet(
        context?.loggedOnUser?.username,
        "",
        "all",
        filterValue,
        100,
        0,
        100
      )

      .then(async (data) => {
        var dataFromApi = await data.json();

        const invoices = dataFromApi.value.map((inv) => {
          var listOfAccountNames = "";
          if (inv.invoiceCodings && inv.invoiceCodings.length > 0) {
            var accountNumbers = inv.invoiceCodings.map(
              (x) => x.accountNumber + "-" + x.accountName
            );
            listOfAccountNames = accountNumbers.join(",");
          }
          let p = {
            vendorInvoiceNo: inv.vendorInvoiceNo,
            invoiceDate: inv.invoiceDate,
            accountNumber: listOfAccountNames,
            totalAmount: inv.vendorAmount,
            status: inv.status,
            invoiceGuid: inv.invoiceGuid,
            invoiceID: inv.invoiceID,
            isEditable: inv.isEditable,
          };
          return p;
        });

        setInvoiceList(invoices);
      })
      .finally(() => setLoading((loading) => loading - 1));
  };

  const loadPrDetails = () => {
    let prApi: PurchaseRequisitionApi = factory.create(PurchaseRequisitionApi);
    setLoading((loading) => loading + 1);

    prApi
      .purchaseRequisitionDetailPoNumberGet(
        poNumber,
        context?.loggedOnUser?.username
      )
      .then(async (data) => {
        var dataFromApi: any = data;
        setPrDetails(dataFromApi);
        //setPoNumber(dataFromApi?.poNumber);
      })
      .finally(() => setLoading((loading) => loading - 1));
  };
  const loadInvoiceAuth = () => {
    if (context?.loggedOnUser?.username) {
      let s: InvoiceApi = factory.create(InvoiceApi);
      s.invoiceAddInvoiceAuthGet(context?.loggedOnUser?.username).then(
        async (value: Response) => {
          var d2 = await value.json();
          setAllowToAddInvoice(d2.result);
        }
      );
    }
  };

  useEffect(() => {
    if (context?.loggedOnUser?.username) {
      loadPoDetails();
      loadPoHistory();
      loadPrDetails();
      loadInvoiceHistory();
      loadInvoiceAuth();
    }
  }, [context?.loggedOnUser?.username]);

  const loadPoDetails = () => {
    let poApi: PurchaseOrderApi = factory.create(PurchaseOrderApi);
    setLoading((loading) => loading + 1);
    poApi
      .purchaseOrderPoIDGet(poNumber, context?.loggedOnUser?.username)
      .then((poDetails) => {
        let poFromApi: any = poDetails;
        const poDetailsForUi = mapPoDetailsFromApiToPoDetailsForUi(poFromApi);

        setPoDetails(poDetailsForUi);
      })
      .finally(() => setLoading((loading) => loading - 1));
  };

  const getRequisitionType = () => {
    let requisitionType = "";
    if (prDetails) requisitionType = prDetails?.requisitionType;
    else
      requisitionType = poDetails?.vendorID.endsWith("4")
        ? "Material"
        : "Service";

    return requisitionType == "Material" ? "Material/ODCs" : "Service";
  };

  const getSubcontractAdmin = () => {
    if (poDetails) return prDetails?.subContractAdmin;

    return "";
  };

  const getProcurementRepresentative = () => {
    if (poDetails) return prDetails?.procurementRepresentative;

    return "";
  };

  const getChargeType = () => {
    if (prDetails) return prDetails?.chargeCdoe;

    let chargeType = "";
    var filterDirectChargeTypes = poDetails?.poLineItemList.filter(
      (x) => x.accountGroupCode?.toLowerCase() == "dir"
    );
    if (filterDirectChargeTypes && filterDirectChargeTypes.length > 0) {
      chargeType = "Direct";
    } else {
      chargeType = "Indirect";
    }
    return chargeType;
  };

  const statusClassName = `badge  po-status-${poDetails?.status
    ?.replace(" ", "-")
    ?.toLowerCase()}`;

  return (
    <>
      {redirect.shouldRedirect && <Redirect push to={redirect.url} />}

      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />

      <div className="row mb-3 align-items-md-center">
        <div className="col">
          <div className="mb-3 mb-md-0">
            <XtBreadcrumbs {...breadCrumbs} />
          </div>
        </div>
        <div className="col-auto">
          <span className={statusClassName} role={"gridcell"}>
            {poDetails?.status}
          </span>
        </div>
      </div>

      <div className="row mb-3 align-items-md-center">
        <div className="col">
          <h4 className="mb-3 mb-md-0">
            Purchase Order Details for {poNumber}
          </h4>
        </div>
        {allowToAddInvoice && (
          <div className="col-sm-auto pe-md-0 mb-2">
            <Button
              className="me-2 btn-sm-full"
              onClick={(e) =>
                setRedirect({
                  shouldRedirect: true,
                  url: `/invoice/add/${poNumber}`,
                })
              }
            >
              {" "}
              Add New Invoice{" "}
            </Button>
          </div>
        )}

        {showCreatePoChangeRequest && (
          <div className="col-sm-auto mb-2">
            <Button
              className="me-2 btn-sm-full"
              onClick={(e) =>
                setRedirect({
                  shouldRedirect: true,
                  url: "/po/change/" + poNumber,
                })
              }
            >
              {" "}
              Create PO Change Request{" "}
            </Button>
          </div>
        )}
      </div>
      <Row>
        <Col sm={6}>
          <FormField3Column label="Project No:">
            <FormLabelMutedText value={poDetails?.projectNumber} />
          </FormField3Column>
          <FormField3Column label="Vendor Name:">
            <FormLabelMutedText
              value={
                poDetails?.vendorName &&
                `${poDetails?.vendorName} (${poDetails?.vendorID})`
              }
            />
          </FormField3Column>
          <FormField3Column label="PO Committed Amount:">
            <FormLabelMutedText
              value={NumberUtil.formatToTwoDecimalWithComma(
                poDetails?.totalAmount
              )}
            />
          </FormField3Column>

          <FormField3Column label="Cost to Date:">
            <FormLabelMutedText
              value={NumberUtil.formatToTwoDecimalWithComma(
                poDetails?.costToDateAmount
              )}
            />
          </FormField3Column>
          <FormField3Column label="Running Total:">
            <FormLabelMutedText
              value={NumberUtil.formatToTwoDecimalWithComma(
                poDetails?.runningTotal
              )}
            />
          </FormField3Column>
          <FormField3Column label="Remaining Amount:">
            <FormLabelMutedText
              value={NumberUtil.formatToTwoDecimalWithComma(
                poDetails?.remainingAmount
              )}
            />
          </FormField3Column>
        </Col>
        <Col sm={6}>
          <Row>
            <FormFieldWithParam
              Form_md={12}
              Form_xl={6}
              Label_sm={4}
              Control_sm={8}
              Label_md={6}
              Control_md={6}
              Label_xl={4}
              Control_xl={8}
              label="Requisition Type:"
            >
              <FormLabelMutedText value={getRequisitionType()} />
            </FormFieldWithParam>
            <FormFieldWithParam
              Form_md={12}
              Form_xl={6}
              Label_sm={4}
              Control_sm={8}
              Label_md={6}
              Control_md={6}
              Label_xl={4}
              Control_xl={8}
              label="Order Date:"
            >
              <FormLabelMutedText
                value={DateUtil.formatmmDdYYY(poDetails?.orderDate)}
              />
            </FormFieldWithParam>
          </Row>
          {getRequisitionType() === "Service" && (
            <Row>
              <FormFieldWithParam
                Form_md={12}
                Form_xl={6}
                Label_sm={4}
                Control_sm={8}
                Label_md={6}
                Control_md={6}
                Label_xl={4}
                Control_xl={8}
                label="Start Date:"
              >
                <FormLabelMutedText value={poDetails?.startDate} />
              </FormFieldWithParam>

              <FormFieldWithParam
                Form_md={12}
                Form_xl={6}
                Label_sm={4}
                Control_sm={8}
                Label_md={6}
                Control_md={6}
                Label_xl={4}
                Control_xl={8}
                label="End Date:"
              >
                <FormLabelMutedText value={poDetails?.endDate} />
              </FormFieldWithParam>
            </Row>
          )}
          <FormFieldWithParam
            Form_md={12}
            Form_xl={6}
            Label_sm={4}
            Control_sm={8}
            Label_md={6}
            Control_md={6}
            Label_xl={4}
            Control_xl={8}
            label="NW Company:"
          >
            <FormLabelMutedText value={poDetails?.companyName} />
          </FormFieldWithParam>
          <FormFieldWithParam
            Form_md={12}
            Form_xl={6}
            Label_sm={4}
            Control_sm={8}
            Label_md={6}
            Control_md={6}
            Label_xl={4}
            Control_xl={8}
            label="Project Manager:"
          >
            <FormLabelMutedText value={prDetails?.projectManager} />
          </FormFieldWithParam>
          {getRequisitionType() !== "Material/ODCs" && (
            <FormFieldWithParam
              Form_md={12}
              Form_xl={6}
              Label_sm={4}
              Control_sm={8}
              Label_md={6}
              Control_md={6}
              Label_xl={4}
              Control_xl={8}
              label="Subcontract Admin:"
            >
              <FormLabelMutedText value={getSubcontractAdmin()} />
            </FormFieldWithParam>
          )}
          {getRequisitionType() === "Material/ODCs" && (
            <FormFieldWithParam
              Form_md={12}
              Form_xl={6}
              Label_sm={4}
              Control_sm={8}
              Label_md={6}
              Control_md={6}
              Label_xl={4}
              Control_xl={8}
              label="Procurement Rep:"
            >
              <FormLabelMutedText value={getProcurementRepresentative()} />
            </FormFieldWithParam>
          )}
        </Col>
      </Row>

      <Accordion defaultActiveKey={activeSections} alwaysOpen>
        {prDetails === null && (
          <div className="alert alert-warning" role="alert">
            Warning: This purchase order does not have PR record created in this
            system. Quantity, unit, unit price data shown is from Costpoint and
            might not reflect actual quantities and unit prices for PO line
            items.
          </div>
        )}
        <XtAccordianItem header="Purchase Order Line Item Details" eventKey="0">
          {/*<Grid data={poDetails?.purchaseOrderLineItems}>*/}
          {/*    {AdvSearchAndGridUtil.renderGridFields(gridSchemas.purchaseOrderItems)}*/}
          {/*</Grid>*/}
          <Grid
            data={poDetails?.purchaseOrderLineItems.sort(
              (l1, l2) => l1.lineItemNumber - l2.lineItemNumber
            )}
            total={0}
            className="k-grid-pr-list"
          >
            <GridColumn title="#" field="lineItemNumber" width="100" />
            <GridColumn
              title="Charge Code"
              field="chargeCode"
              cell={(v) => {
                var value =
                  v.dataItem[v.field] + " - " + v.dataItem?.chargeCodeName;
                return <td>{value}</td>;
              }}
            />
            <GridColumn
              title="Type"
              field="type"
              cell={(v) => {
                var value = v.dataItem[v.field] + " - " + v.dataItem?.typeName;
                return <td>{value}</td>;
              }}
            />
            <GridColumn
              title="Account Number"
              field="accountNumber"
              cell={(v) => {
                var value =
                  v.dataItem[v.field] + " - " + v.dataItem?.accountName;
                return <td>{value}</td>;
              }}
            />
            <GridColumn
              className="po-description-column"
              title="Description"
              field="description"
              width="150"
            />
            <GridColumn
              title="Quantity"
              field="quantity"
              width="150"
              cell={NumberGridCellWithComma}
            />
            <GridColumn
              title="Unit"
              field="unit"
              width="150"
              cell={(v) => {
                var value = v.dataItem[v.field] + " - " + v.dataItem?.unitName;
                return <td>{value}</td>;
              }}
            />
            <GridColumn
              title="Unit Price"
              field="unitPrice"
              cell={AmountGridCell}
              headerCell={RightAlignGridColumnHeader}
            />
            {getRequisitionType() === "Service" && (
              <GridColumn
                title="Start Date"
                field="startDate"
                cell={(v) => {
                  if (v === undefined) return <td></td>;
                  var value = DateUtil.formatmmDdYYY(v.dataItem[v.field]);
                  return <td>{value}</td>;
                }}
              />
            )}
            {getRequisitionType() === "Service" && (
              <GridColumn
                title="End Date"
                field="endDate"
                cell={(v) => {
                  if (v === undefined) return <td></td>;
                  var value = DateUtil.formatmmDdYYY(v.dataItem[v.field]);
                  return <td>{value}</td>;
                }}
              />
            )}
            <GridColumn
              title="Total"
              field="total"
              cell={AmountGridCell}
              headerCell={RightAlignGridColumnHeader}
            />
          </Grid>
        </XtAccordianItem>
        {prHistory === null && (
          <div className="alert alert-warning" role="alert">
            Warning: This purchase order does not have any historical
            information because PR was not created in this system for this PO.
          </div>
        )}
        <XtAccordianItem
          header="Purchase Requisition History Details"
          eventKey="1"
        >
          <Grid onRowClick={onGridRowClick} data={prHistory}>
            {AdvSearchAndGridUtil.renderGridFields(
              gridSchemas.prHistoryDetails
            )}
          </Grid>
        </XtAccordianItem>

        <XtAccordianItem header="Invoice History Details" eventKey="2">
          <Grid
            // id="invoiceHistoryGrid"
            onRowClick={onInvoiceGridRowClick}
            data={orderBy(
              invoiceList.slice(page.skip, page.take + page.skip),
              sort
            )}
            skip={page.skip}
            take={page.take}
            pageSize={15}
            pageable={true}
            onPageChange={onPageChange}
            total={invoiceList.length}
          >
            {AdvSearchAndGridUtil.renderGridFields(
              gridSchemas.invoiceHistoryDetails
            )}
          </Grid>
        </XtAccordianItem>
      </Accordion>

      {loading > 0 && <Loading />}
    </>
  );
}
