import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { XtField, XtGrid } from "nwcommon";

import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DateUtil } from "../../../../../Xt/Utils/DateUtil";
import {
  APIFactory,
  PurchaseRequisition,
  PurchaseRequisitionApi,
} from "../../../../../Xt/ApiClients";
import { PrApiService } from "../Common/PrApiService";
import PrCompletedGridCell from "../Common/GridCell/PrCompletedGridCell";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";

interface AuditLogProps {
  prGuid: string;
  auditLogComment?: any[];
}

export default function AuditLog(props: AuditLogProps) {
  let factory: APIFactory;
  const init = true;

  const context: IXtContext = useContext(XtContext);

  const [logList, setWorkflowLogData] = useState([]);

  const loadLogData = () => {
    if (props?.prGuid && context?.loggedOnUser?.username) {
      let api: PurchaseRequisitionApi = factory.create(PurchaseRequisitionApi);
      var id = props.prGuid;
      PrApiService.getApprovalFlowLogByGuid(context, api, props.prGuid).then(
        (workflowData) => {
          setWorkflowLogData(workflowData);
        }
      );
    }
  };
  useEffect(() => {
    if (props?.prGuid && context?.loggedOnUser?.username) {
      loadLogData();
    }
  }, [props?.prGuid, context?.loggedOnUser?.username]);

  useEffect(() => {
    props?.auditLogComment &&
      setWorkflowLogData((prev) => [...props?.auditLogComment, ...prev]);
  }, [props?.auditLogComment]);

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      {logList.map((val, index) => (
        <div key={index} className="mb-4">
          <div className="small d-flex justify-content-between text-muted mb-1">
            <div className="">
              <span> {val.displayName}</span>
              <span className="badge bg-primary  ms-2">{val.role}</span>
            </div>
            <div className="d-flex align-items-center">
              <i className="material-icons small me-1">schedule</i>
              {val.isAdditionalComment && "Commented on"}
              {!val.isAdditionalComment && (
                <>
                  {val.stage === "1" && "Submitted on"}
                  {val.stage !== "1" &&
                    val.isRejected === false &&
                    val.isRevised === false &&
                    "Approved on"}
                  {val.isRejected === true && "Rejected on"}
                  {val.isRevised === true && "Revised on"}
                </>
              )}{" "}
              {DateUtil.convertUTCDateToLocalDate(val.timeStamp)}{" "}
              {val.displayName === val.completedBy
                ? ""
                : " by " + val.completedBy}
            </div>
          </div>
          {val.comment && val.comment.length > 0 && (
            <small className="d-block fw-bold mb-0 text-primary">
              {val.comment}
            </small>
          )}
          {val.comment && val.comment.length === 0 && (
            <div className="text-muted text-uppercase small font-weight-bold ml-3 d-block">
              {" "}
              No Comments
            </div>
          )}
        </div>
      ))}
    </>
  );
}
