import { UserApi } from "../../../../Xt/ApiClients";

export const InvoiceApiService = {
  getReps: (
    api: UserApi,
    loggedOnUser: string,
    loadAccountSpecificUsers: boolean
  ) => {
    return api.userAccountingUsersGet(loggedOnUser).then(async (userData) => {

      ;
      if (!userData) return [];
      const reps = [];
      let actualUserData = await userData.json();
      if (actualUserData?.DefaultGroupUsers) {
        actualUserData.DefaultGroupUsers.map((x) => {
          reps.push({
            text: x.Displayname,
            value: x.UserGuid,
          });
        });
      }

      if (!loadAccountSpecificUsers) return reps;
      if (actualUserData.AccountCodeSpecificUsers) {
        actualUserData.AccountCodeSpecificUsers.map((x) => {
          reps.push({
            text: x.Displayname,
            value: x.UserGuid,
          });
        });
      }

      return reps;
    });
  },
};
