import { CancelButtonView } from "./CancelAndDeleteButtons";
import ReviseButton from "./ReviseButton";
import ApproveButton from "./ApproveButton";
import { EnApprovalMode } from "../../Interfaces/Enums";
import RejectButton from "./RejectButton";

interface IActionButtonsProps {
  onApprovalButtonClick: (approvalMode: EnApprovalMode) => void;
}

export default function ActionButtons(props: IActionButtonsProps): JSX.Element {
  const { onApprovalButtonClick } = props;

  return (
    <div className="d-flex gap-3">
      <CancelButtonView onApprovalButtonClick={onApprovalButtonClick} />
      <RejectButton onApprovalButtonClick={onApprovalButtonClick} />
      <ReviseButton onApprovalButtonClick={onApprovalButtonClick} />
      <ApproveButton onApprovalButtonClick={onApprovalButtonClick} />
    </div>
  );
}
