import { XtField } from "nwcommon";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import FormField, { TwoFieldFormField } from "../Components/FormField";
import PopOverIcon from "../Components/PopOverIcon";
import { EnPrrAction, State } from "./PrrReducer";
import {
  education,
  isTravelRequired,
  wageType,
  yearsOfExp,
} from "../Interfaces/Constants";

export default function NwAdditionalInfo(props) {
  const state: State = props.state;
  const dispatch: any = props.dispatch;
  const [minRate, setMinRate] = useState(undefined);
  const [maxRate, setMaxRate] = useState(undefined);
  const liscenseInfo = (
    <PopOverIcon header="Info">
      <div>
        <p>CDL, HAZWOPER, Certified Safety Professional, PE, FE, etc.</p>
      </div>
    </PopOverIcon>
  );
  const securityInfo = (
    <PopOverIcon header="Info">
      <div>
        <p>Q Level Security Clearance, Badge eligibility, Base Access, etc</p>
      </div>
    </PopOverIcon>
  );
  const commentInfo = (
    <PopOverIcon header="Info">
      <div>
        <p>
          Please add anything else that may not be captured in the Job
          Description (example: required equipment, additional health testing,
          helicopter flight capabilities, etc.)
        </p>
      </div>
    </PopOverIcon>
  );

  useEffect(() => {
    if (state?.wageMinimum) {
      setMinRate(formatWageRate(state.wageMinimum));
    }
    if (state?.wageMaximum) {
      setMaxRate(formatWageRate(state?.wageMaximum));
    }
  }, [state?.wageMinimum, state?.wageMaximum]);

  const formatWageRate = (
    wageRate: string | number | undefined
  ): string | undefined => {
    let numberValue: number | undefined = undefined;

    if (typeof wageRate === "string") {
      // Remove non-numeric characters from the input
      const numericValue = wageRate.replace(/[^0-9.]/g, "");

      numberValue = Number(numericValue);
    } else {
      numberValue = wageRate;
    }

    if (numberValue) {
      const hasDecimals = Number.isInteger(numberValue) ? false : true;

      const formattedValue = numberValue.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: hasDecimals ? 2 : 0,
      });

      return formattedValue;
    }

    return undefined;
  };
  const unformatWageRate = (formattedValue) => {
    if (formattedValue) {
      // Remove non-numeric characters from the input
      const numericValue = formattedValue.replace(/[^0-9.]/g, "");

      const numberValue = Number(numericValue);

      return numberValue;
    }
    return undefined;
  };

  return (
    <>
      <Row className="mb-3">
        <Col sm={6}>
          <Row className="mb-3 ">
            <FormField label="Wage Type">
              <XtField
                type="radiogroup"
                name="wageType"
                layout="horizontal"
                items={wageType}
                value={state?.wageType || undefined}
                textField="text"
                required={false}
                onChange={(e) => {
                  dispatch({
                    type: EnPrrAction.WAGE_TYPE_CHANGE,
                    payload: e?.value ? e.value : undefined,
                  });
                }}
              />
            </FormField>
            <TwoFieldFormField label="Proposed Wage Rate" isRequired={false}>
              <XtField
                type="text"
                name="wageMinimum"
                placeholder="Min"
                value={formatWageRate(minRate) || ""}
                onChange={(e) => {
                  setMinRate(e.target.value);
                  dispatch({
                    type: EnPrrAction.MIN_WAGE_RATE_CHANGE,
                    payload: e?.target?.value
                      ? unformatWageRate(e.target.value)
                      : undefined,
                  });
                }}
                textField="text"
                required={false}
                errormessage="Min Wage Rate must be above 10"
              />

              <XtField
                type="text"
                name="wageMaximum"
                placeholder="Max"
                value={formatWageRate(maxRate) || ""}
                onChange={(e) => {
                  setMaxRate(e.target.value);
                  dispatch({
                    type: EnPrrAction.MAX_WAGE_RATE_CHANGE,
                    payload: e?.target?.value
                      ? unformatWageRate(e.target.value)
                      : undefined,
                  });
                }}
                textField="text"
                required={false}
              />
            </TwoFieldFormField>
          </Row>
          <Row className="mb-3 years-exp">
            <FormField label="Min. Years of Experience">
              <XtField
                type="dropdown"
                name="yearsExperience"
                value={state?.yearsExperience || ""}
                textField="text"
                items={yearsOfExp}
                onChange={(e) => {
                  dispatch({
                    type: EnPrrAction.YEARS_OF_EXPERIENCE_CHANGE,
                    payload: e?.value?.value ? e.value.value : undefined,
                  });
                }}
                required={false}
              />
            </FormField>
          </Row>
        </Col>
        <Col sm={6}>
          <Row className="mb-3">
            <FormField label="Education">
              <XtField
                type="dropdown"
                name="education"
                items={education}
                textField="text"
                value={state?.education || null}
                onChange={(e) =>
                  dispatch({
                    type: EnPrrAction.EDUCATION_CHANGE,
                    payload: e?.value ? e?.value?.value : undefined,
                  })
                }
              />
            </FormField>
          </Row>

          <Row className="mb-3">
            <FormField label="Is travel required?">
              <XtField
                type="radiogroup"
                name="travelRequired"
                layout="horizontal"
                items={isTravelRequired}
                textField="text"
                value={
                  isTravelRequired.find((i) => i.text === state?.travelRequired)
                    ?.text || undefined
                }
                onChange={(e) => {
                  dispatch({
                    type: EnPrrAction.IS_TRAVEL_REQUIRED_CHANGE,
                    payload: e?.value ? e.value : undefined,
                  });
                }}
                required={false}
              />
            </FormField>
          </Row>

          {state?.travelRequired === "Yes" ? (
            <Row className="mb-3">
              <FormField label="Travel Percentage">
                <XtField
                  type="dropdown"
                  name="travelAmount"
                  value={state?.travelAmount || null}
                  items={[
                    { text: "0-25%", value: "0-25%" },
                    { text: "25-50%", value: "25-50%" },
                    { text: "50-75%", value: "50-75%" },
                    { text: "75-100%", value: "75-100%" },
                    { text: "100%", value: "100%" },
                  ]}
                  onChange={(e) => {
                    dispatch({
                      type: EnPrrAction.TRAVEL_PERCENTAGE_CHANGE,
                      payload: e?.value ? e.value.value : undefined,
                    });
                  }}
                  textField="text"
                  required={false}
                />
              </FormField>
            </Row>
          ) : null}
        </Col>
      </Row>
      <Col>
        <Row className="mb-3 ">
          <FormField
            label="Required Licenses and Certifications"
            info={liscenseInfo}
          >
            <XtField
              name="requiredLicenses"
              type="textarea"
              defaultValue={state?.requiredLicenses || ""}
              onChange={(e) => {
                dispatch({
                  type: EnPrrAction.REQUIRED_LISCENSES_CHANGE,
                  payload: e.target.value,
                });
              }}
            />
          </FormField>
        </Row>
        {/* <Row className="mb-3">
          <FormField label="Preferred Skills">
            <XtField
              name="desiredSkills"
              type="textarea"
              defaultValue={state?.desiredSkills || ""}
              onChange={(e) => {
                dispatch({
                  type: EnPrrAction.DESIRED_SKILLS_CHANGE,
                  payload: e.target.value,
                });
              }}
            />
          </FormField>
        </Row> */}

        <Row className="mb-3">
          <FormField label="Security Access Requirements" info={securityInfo}>
            <XtField
              name="securityAccessRequirements"
              type="textarea"
              value={state?.securityAccessRequirements || ""}
              onChange={(e) => {
                dispatch({
                  type: EnPrrAction.SECURITY_ACCESS_CHANGE,
                  payload: e.target.value,
                });
              }}
            />
          </FormField>
        </Row>
        <Row className="mb-3">
          <FormField label="Comments" info={commentInfo}>
            <XtField
              name="comments"
              type="textarea"
              value={state?.comments || ""}
              onChange={(e) => {
                dispatch({
                  type: EnPrrAction.COMMENTS_CHANGE,
                  payload: e.target.value,
                });
              }}
            />
          </FormField>
        </Row>
      </Col>
    </>
  );
}
