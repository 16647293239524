import { useState, useRef, useEffect } from "react";

import { Form } from "react-bootstrap";

import FileUploadGrid from "./FileUploadGrid";
import { EnPrrAction } from "../Pages/Nw/PersonnelRequisition/CreateNew/PrrReducer";

export interface IFile {
  name: string;
  type: string;
  size: string;
  description: string;
  data: string;
}

// This is called a Discriminated Union
type FileUploadProps =
  | {
      type: "purchaseOrder";
      purchaseOrder: Object | null;
      name: string;
      state?: never;
    }
  | {
      type: "prr";
      errorMessage: string;
      purchaseOrder?: never;
      name: string;
      state: any;
      dispatch: (any) => void;
    }
  | {
      type: "purchaseRequisition";
      purchaseOrder?: never;
      purchaseRequisition: Object | null;
      name: string;
      state?: never;
    };

const validPrrFileTypes = [
  ".docx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export default function FileUpload(props: FileUploadProps) {
  const isRequired = props.type === "prr";

  const [files, setFiles] = useState<IFile[] | any[]>([]);
  const [isInvalid, setIsInvalid] = useState(false);

  const fileRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (props.type === "purchaseOrder" && !props.purchaseOrder) {
      setFiles([]);

      if (fileRef && fileRef.current) fileRef.current.value = "";
    } else if (props.type === "prr") {
      setFiles(props.state?.additionalAttachmentFileList ?? []);

      if (fileRef && fileRef.current) fileRef.current.value = "";
    }
  }, [
    props.type,
    props.state?.additionalAttachmentFileList,
    props.purchaseOrder,
  ]);

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsInvalid(false);
    const xfs = [...files];

    for (let i = 0; i < e.target.files.length; i++) {
      const ff = e.target.files[i];

      if (isRequired && !validPrrFileTypes.includes(ff.type)) {
        alert("Invalid file type. Please upload a Word document (.docx)");
        return;
      }

      xfs.push({
        name: ff.name,
        type: ff.type,
        size: ff.size + " bytes",
        description: "",
        data: await toBase64(ff),
      });
    }

    e.target.value = "";
    setFiles(xfs);

    if (props.type === "prr") {
      props.dispatch({
        type: EnPrrAction.ATTACHMENT_ADD_FILE,
        payload: xfs,
      });
    }
  };

  const toBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result?.toString() || "");
      reader.onerror = (error) => reject(error);
    });
  };

  const removeFileHandler = (fileIndex: number) => {
    const newFiles = [...files];
    newFiles.splice(fileIndex, 1);

    setFiles(newFiles);
    if (props.type === "prr") {
      props.dispatch({
        type: EnPrrAction.ATTACHMENT_ADD_FILE,
        payload: newFiles,
      });
    }
  };

  const onInvalid = (e) => {
    if (!isRequired) return;

    e.preventDefault();
    if (files.length === 0) {
      setIsInvalid(true);
    }
  };

  return (
    <>
      <Form.Control
        title="Choose File"
        type="file"
        ref={fileRef}
        onChange={onFileChange}
        className="xtupload-button mb-3 mt-3"
        required={isRequired && files.length === 0}
        onInvalid={onInvalid}
        accept={isRequired ? validPrrFileTypes.join(",") : "*"}
        disabled={isRequired && files.length > 0}
      />

      {isRequired && props.errorMessage && isInvalid && (
        <div className="invalid-field">{props.errorMessage}</div>
      )}

      <FileUploadGrid files={files} onRemoveFile={removeFileHandler} />
      {props.name && (
        <input
          type="hidden"
          name={"uploader_" + props.name}
          value={JSON.stringify(files) || ""}
        />
      )}
    </>
  );
}
