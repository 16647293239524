import React, { HtmlHTMLAttributes } from "react";
import { Col } from "react-bootstrap";

type XtElementType = "file" | "folder";

export type XtFileType = {
  name: string;
  type: string;
  parent: string;
};

export type XtDoubleClick = {
  type: XtElementType;
  dataItem: XtFileType;
};

export type XtFileProps = React.PropsWithChildren<{
  file: XtFileType;
  onContextMenu?: any;
  onDoubleClick?: any;
  onClick?: any;
  selected: boolean;
}>;

export function XtFile(props: XtFileProps) {
  const onFileClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.onClick) {
      props.onClick({ ...e, dataItem: props.file });
    }
  };

  const onFileDoubleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.onDoubleClick) {
      props.onDoubleClick({ ...e, dataItem: props.file });
    }
  };

  const onContextMenu = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.onContextMenu) {
      props.onContextMenu({ ...e, dataItem: props.file });
    }
  };

  const renderFile = () => {
    return (
      <div
        className={props.selected ? "file-folder file-selected" : "file-folder"}
        onContextMenu={onContextMenu}
        onClick={onFileClick}
        onDoubleClick={onFileDoubleClick}
      >
        <a className="d-flex align-items-center" href="#">
          <XtFileIcon {...props} />
          {props.file.name}
        </a>
      </div>
    );
  };

  const renderFolder = () => {
    return (
      <div
        className={props.selected ? "file-folder file-selected" : "file-folder"}
        onContextMenu={onContextMenu}
        onClick={onFileClick}
        onDoubleClick={onFileDoubleClick}
      >
        <a className="d-flex align-items-center" href="#">
          <XtFileIcon {...props} />
          {props.file.name}
        </a>
      </div>
    );
  };

  return (
    <>
      {props.file.type !== "" && renderFile()}
      {props.file.type === "" && renderFolder()}
    </>
  );
}

export function XtFileIcon(props: XtFileProps) {
  const getIcon = () => {
    if (props.file.type === "") {
      return "k-i-folder";
    } else {
      let ext = props?.file?.name?.split(".").pop()?.toLocaleLowerCase();
      switch (ext) {
        case "txt":
          return "k-i-txt";
          break;
        case "pdf":
          return "k-i-pdf";
          break;
        case "doc":
        case "docx":
          return "k-i-doc";
          break;
        case "xls":
        case "xlsx":
          return "k-i-xls";
          break;
        case "zip":
          return "k-i-zip";
          break;
        case "csv":
          return "k-i-csv";
          break;
        default:
          return "k-i-txt";
      }
    }
  };

  return <span className={"k-icon " + getIcon()}></span>;
}
