const isInvalidKey = (s: string) => {
    if (s === "" || s === "item" || s === "namedItem" || s === "undefined" || s === "files" || s === "dummyFile")
        return true;
}
export const XtFormUtil = {
 
    getPostData : (fe) => 
    {

        var vals: any = {};
        var values = [];
        var pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        
        for (let  v in fe ){
            
            let key = fe[v].name;
            let val = fe[v].value
            let f: any = v;
            if (!isInvalidKey(fe[v].name) && !isNaN(f) && !pattern.test(key)) {
                if (key.indexOf("InvoiceDate") !== -1) {
                    
                }
                if (key.toString().indexOf("uploader_") >= 0) {
                    values.push(`"${key.toString().replace("uploader_", "")}":${val.toString() === "" ? "[]" : val.toString()}`)
                }
                else if (typeof (val) === "string") {
                    if (val.indexOf("\n") >= 0) {
                        values.push(`"${key.toString()}": ""`)
                        eval(`vals.${key.toString()}=\`${val}\``)
                    }
                    else {
                        values.push(`"${key.toString()}": "${val.toString().replace("\t", "").replace("\\", "")}"`)
                    }
                }
                else {
                    values.push(`"${key.toString()}":${val.toString().replace("\t", "").replace("\\", "")}`)
                }
            }
        }

        let str = `{${values.join(`,`)}}`;
        let json = JSON.parse((str))
        for (let i in vals) {
            json[i] = vals[i]
        }
        return json; 

    }

}