import autosize from "autosize";
import { XtField } from "nwcommon";
import { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import { useQuery } from "@tanstack/react-query";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import {
  getBambooEmployeeList,
  getLaborGroupsByCompanyCode,
} from "../../../../../api/react-query";
import { MINUTE } from "../../../../../utils/constants";
import FileUpload from "../../../../ui/FileUpload";
import FormLabelMutedText from "../../PurchaseRequisition/Common/FormLabelMutedText";
import FormField, { RequiredFormField } from "../Components/FormField";
import PopOverIcon from "../Components/PopOverIcon";
import {
  employmentTypeOption,
  isHiringManager,
  vacancies,
} from "../Interfaces/Constants";
import LocationForm from "./LocationForm";
import { EnPrrAction, State } from "./PrrReducer";

export default function NwPositionInfo(props) {
  const state: State = props.state;
  const dispatch: any = props.dispatch;
  const context: IXtContext = useContext(XtContext);

  //const [departments, setDepartments] = useState([]);
  //const [loadingDepartments, setLoadingDepartments] = useState(false);

  const token = context?.tokens?.accessToken;
  const username = context?.loggedOnUser?.username;

  const {
    data: employeeList,
    isLoading: isEmployeeListLoading,
    isFetching: isEmployeeListFetching,
  } = useQuery(["bambooEmployeeList", token, username], getBambooEmployeeList, {
    enabled: !!token && !!username,
    placeholderData: [],
    staleTime: Infinity,
    cacheTime: 20 * MINUTE,
  });

  const {
    data: laborGroups,
    isLoading: isLaborGroupsLoading,
    isFetching: isLaborGroupsFetching,
  } = useQuery(
    ["laborGroups", token, username, state?.companyCode],
    getLaborGroupsByCompanyCode,
    {
      enabled: !!token && !!username && !!state?.companyCode,
      placeholderData: [],
      staleTime: Infinity,
      cacheTime: 20 * MINUTE,
    },
  );

  props.handleLaborGroupLoading(isLaborGroupsLoading || isLaborGroupsFetching);

  useEffect(() => {
    if (state?.hiringManager === context?.loggedOnUser?.bambooHRID) {
      dispatch({
        type: EnPrrAction.IS_HIRING_MANAGER_CHANGE,
        payload: { isHiringManager: "Yes" },
      });
    } else {
      dispatch({
        type: EnPrrAction.IS_HIRING_MANAGER_CHANGE,
        payload: { isHiringManager: "No" },
      });
    }
  }, [context?.loggedOnUser?.bambooHRID, state?.hiringManager, dispatch]);

  // Commented out per mvp model
  // Will be added back in future release
  // Reference: https://dev.azure.com/NorthWindGroup/NW-ESS/_workitems/edit/495/
  // useEffect(() => {
  //   if (!context?.loggedOnUser?.username || !factory) return;

  //   setLoadingDepartments(true);
  //   let departmentAPI: DepartmentApi = factory.create(DepartmentApi);
  //   departmentAPI
  //     .departmentsGet(context?.loggedOnUser?.username)
  //     .then(async (departmentData) => {
  //       var departmentDataFromApi = await departmentData.json();
  //       let departmentList = departmentDataFromApi.value.map((d) => {
  //         return {
  //           text: d.name,
  //           value: d.name,
  //         };
  //       });
  //       console.log(departmentList);
  //       setLoadingDepartments(false);
  //       setDepartments(departmentList);
  //     });
  // }, [context?.loggedOnUser?.username, dispatch, factory]);

  const jobDescriptionInfo = (
    <PopOverIcon header="Info">
      <div>
        <p>
          Example Job Description Summary: A Laborer for the Moab Uranium Mill
          Tailings Remedial Action (UMTRA) Project performs construction,
          maintenance, and janitorial tasks at the Moab and Crescent Junction
          sites.
        </p>
      </div>
    </PopOverIcon>
  );

  const attachmentInfo = (
    <PopOverIcon header="Info">
      <div>
        <p>
          Enter the full Job Description including, Roles and Responsibilities,
          and Qualifications. If you do not know this information, contact your
          HR Regional Manager. (Word documents only)
        </p>
      </div>
    </PopOverIcon>
  );

  return (
    <>
      <Row>
        <Col>
          <RequiredFormField label="Employment Type">
            <XtField
              type="dropdown"
              name="employmentType"
              items={employmentTypeOption}
              textField="text"
              value={state?.employmentType || null}
              onChange={(e) =>
                dispatch({
                  type: EnPrrAction.EMPLOYMENT_TYPE_CHANGE,
                  payload: e?.value ? e?.value?.value : null,
                })
              }
              required={true}
              errormessage="Employment Type is required"
            />
          </RequiredFormField>

          <RequiredFormField label="Job Title">
            <XtField
              type="text"
              name="jobTitle"
              textField="text"
              value={state?.jobTitle || ""}
              onChange={(e) => {
                dispatch({
                  type: EnPrrAction.JOB_TITLE_CHANGE,
                  payload: e.target.value,
                });
              }}
              required={true}
              errormessage="Job title is required"
            />
          </RequiredFormField>

          <RequiredFormField
            label="Job Description Summary"
            info={jobDescriptionInfo}
          >
            <XtField
              type="textarea"
              name="jobDescription"
              textField="text"
              value={state?.jobDescription || ""}
              onChange={(e) => {
                dispatch({
                  type: EnPrrAction.JOB_DESCRIPTION_CHANGE,
                  payload: e.target.value,
                });
                autosize(document.querySelectorAll("textarea"));
              }}
              required={true}
              errormessage="Job description is required"
            />
          </RequiredFormField>

          <Row className="py-2">
            <RequiredFormField
              column
              sm={4}
              label="Attach Complete Job Description"
              info={attachmentInfo}
            >
              <Col sm={8} className="px-2">
                <FileUpload
                  name="attachmentList"
                  type="prr"
                  errorMessage="Job Description Attachment is required"
                  state={state}
                  dispatch={dispatch}
                />
              </Col>
              <a
                href="https://northwindgrp.sharepoint.com/:w:/r/sites/DocumentLibrary/Human%20Resources/HRP-5001%20Employment%20Process/HRF-5001.20%20Position%20Decription%20Template/HRF-5001.20%20Rev%202%2c%20Position%20Description%20Template%2c%205-13-24.docx?d=wf91e707105aa4d529ab61e0fd5a9b9f8&csf=1&web=1&e=FF67Oj"
                className="px-2"
                target="_blank"
                rel="noreferrer"
              >
                Click here for Position Description Template
              </a>
            </RequiredFormField>
          </Row>
          <Row>
            <LocationForm state={state} dispatch={dispatch} />
          </Row>
        </Col>

        <Col>
          <RequiredFormField label="Labor Group">
            <XtField
              type="dropdown"
              name="laborGroup"
              items={laborGroups || []}
              textField="text"
              value={
                laborGroups.find((l) => l.value === state?.laborGroup) || null
              }
              onChange={(e) =>
                dispatch({
                  type: EnPrrAction.LABOR_GROUP_CHANGE,
                  payload: e?.value ? e?.value?.value : null,
                })
              }
              required={true}
              errormessage="Labor Group is required"
              loading={isLaborGroupsLoading || isLaborGroupsFetching}
            />
          </RequiredFormField>

          <RequiredFormField label="# of Vacancies">
            <XtField
              autoComplete="off"
              type="dropdown"
              name="vacancies"
              textField="text"
              items={vacancies}
              layout="horizontal"
              value={
                vacancies.find((v) => v.value === state?.vacancies)?.text ||
                null
              }
              onChange={(e) => {
                dispatch({
                  type: EnPrrAction.NUMBER_OF_VACANCIES_CHANGE,
                  payload: e?.value ? e.value.value : null,
                });
              }}
              required={true}
              errormessage="# of Vacancies is required"
            />
          </RequiredFormField>

          <RequiredFormField label="Are you the Hiring Manager?">
            <XtField
              type="radiogroup"
              name="isHiringManager"
              layout="horizontal"
              items={isHiringManager}
              value={
                state?.isHiringManager !== null
                  ? isHiringManager.find(
                      (i) => i.text === state?.isHiringManager,
                    )?.text || null
                  : null
              }
              onChange={(e) => {
                dispatch({
                  type: EnPrrAction.IS_HIRING_MANAGER_CHANGE,
                  payload: {
                    isHiringManager: e.value,
                    hiringManager:
                      e.value === "Yes"
                        ? context?.loggedOnUser?.bambooHRID || 1234
                        : undefined,
                  },
                });
              }}
              required={true}
              errormessage="Are you the hiring manager is required"
            />
          </RequiredFormField>

          {state?.isHiringManager === "No" ? (
            <RequiredFormField label="Hiring Manager">
              <XtField
                type="dropdown"
                name="hiringManager"
                items={employeeList}
                textField="text"
                value={
                  employeeList.find((e) => e.value === state?.hiringManager) ||
                  null
                }
                onChange={(e) => {
                  dispatch({
                    type: EnPrrAction.HIRING_MANAGER_CHANGE,
                    payload: e?.value ? e?.value?.value : null,
                  });
                }}
                required={true}
                errormessage="Hiring manager is required"
                loading={isEmployeeListLoading || isEmployeeListFetching}
              />
            </RequiredFormField>
          ) : (
            <>
              <FormField label="Hiring Manager">
                <FormLabelMutedText
                  value={context?.loggedOnUser?.displayname}
                />
              </FormField>
            </>
          )}
          {/* Commented out per mvp model
              Will be added back in future release 
              Reference: https://dev.azure.com/NorthWindGroup/NW-ESS/_workitems/edit/495/
          <RequiredFormField label="Department">
            <XtField
              type="dropdown"
              name="department"
              layout="horizontal"
              items={departments}
              textField="text"
              loading={loadingDepartments}
              value={
                state?.department !== null
                  ? departments.find((i) => i.text === state?.department)
                      ?.text || null
                  : null
              }
              onChange={(e) => {
                dispatch({
                  type: EnPrrAction.DEPARTMENT_CHANGE,
                  payload: e?.value ? e.value.value : null,
                });
              }}
              required={true}
              errormessage="Department is required"
            />
          </RequiredFormField> */}
        </Col>
      </Row>
    </>
  );
}
