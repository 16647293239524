import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import * as Search from "nwcommon";
import {
  XtAdvancedSearchFieldCondition,
  XtAdvancedSearchBox,
  XtExport,
  XtExportType,
  XtExportToolbar,
  XtAdvancedSearchChild,
} from "nwcommon";
import { XtContext, IXtContext } from "../../../../Xt/Contexts/XtContext";
import { GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { XtGrid, XtGridRowSelection } from "nwcommon";
import { Loading } from "../../../../Xt/Layout/Loading";
import { XtPaging } from "../../../../Xt/ApiClients/ApiHelper";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import {
  APIFactory,
  PurchaseOrderApi,
  VendorApi,
} from "../../../../Xt/ApiClients";
import { APIFactoryCommon, CompanyApi } from "../../../../Xt/ApiClients/Common";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import PoLinkGridCell from "../PurchaseRequisition/Common/GridCell/PoLinkGridCell";
import PoStatusGridCell from "../PurchaseRequisition/Common/GridCell/PoStatusGridCell";
import AmountGridCell from "../PurchaseRequisition/Common/GridCell/AmountGridCell";
import RightAlignGridColumnHeader from "../PurchaseRequisition/Common/GridCell/RightAlignGridColumnHeader";
import DateGridCell from "../PurchaseRequisition/Common/GridCell/DateGridCell";
import { PaginationContext } from "../../../../Xt/Contexts/PaginationContext";

type PurchaseOrderInvoice = any;

interface XtExportFileType {
  type: XtExportType;
  fields: any[];
  filename: string;
}

export function POList(props) {
  const pageSize = 15;
  const orderByField = "pO_ID";
  // const apiUrl = "/country";
  // let init = true;
  const [reloadPO, setReloadPO] = React.useState<boolean>(false);
  const [expType, setExportType] = React.useState<XtExportFileType>({
    type: "none",
    fields: [],
    filename: "",
  });
  const [data, setData] = React.useState<any[]>([]);
  const [reportData, setReportData] = React.useState<any[]>([]);
  const [exportDataItem, setExportDataItem] = React.useState<any[]>([]);
  const [dataCount, setDataCount] = React.useState<number>(0);

  // const [nextUrl, setNextUrl] = React.useState<string>("");
  const [isExported, setIsExported] = React.useState<boolean>(false);
  const [paging, setPaging] = React.useState<XtPaging>({
    skip: 0,
    top: pageSize,
    sort: { field: orderByField, dir: "desc" },
  });
  const [searchParams, setSearchParam] = React.useState<any>({});
  const [gridSelection, setGridSelection] = React.useState<XtGridRowSelection>({
    headerSelected: false,
    differedData: [],
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  // const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [isAdvancedSearchLoaded, setIsAdvancedSearchLoaded] =
    React.useState<boolean>(false);
  const [searchFields, setSearchFields] = React.useState<
    XtAdvancedSearchFieldCondition[]
  >([]);
  const [schema, setSchema] = React.useState<any[]>([]);
  const [redirectTo, setRedirectTo] = useState("");
  const [shortCutData, setShortCutData] = useState<any[]>([
    { value: "myActivePo", text: "My Active POs" },
    { value: "myPo", text: "My POs" },
  ]);
  const [selectedShortcut, setSelectedShortcut] = React.useState<any>(
    sessionStorage.getItem("POPageShortcutHistory")
      ? JSON.parse(sessionStorage.getItem("POPageShortcutHistory"))
      : { value: "myActivePo", text: "My Active POs" }
  );
  let factory;
  let commonFactory;
  const context: IXtContext = React.useContext(XtContext);
  const { paginationState, updatePagination } =
    React.useContext(PaginationContext);
  const paginationEntity = "po";

  React.useEffect(() => {
    if (!context?.loggedOnUser?.username) return;

    checkIsAllowedToViewAll();
    if (sessionStorage.getItem("POPageSearchHistory")) {
      setSearchParam(JSON.parse(sessionStorage.getItem("POPageSearchHistory")));
    }
    loadSchema();
  }, [context?.loggedOnUser?.username]);

  React.useEffect(() => {
    if (!context?.loggedOnUser?.username || !isAdvancedSearchLoaded) return;

    getPOListFromApi();
  }, [context?.loggedOnUser?.username, isAdvancedSearchLoaded]);

  React.useEffect(() => {
    if (!context?.loggedOnUser?.username || !reloadPO) return;

    getPOListFromApi();
  }, [reloadPO]);

  const checkIsAllowedToViewAll = () => {
    let f1: PurchaseOrderApi = factory.create(PurchaseOrderApi);

    f1.purchaseOrderIsAuthorizeToViewAllGet(
      context?.loggedOnUser?.username
    ).then(async (v) => {
      var result = await v.json();
      if (result.result) {
        setShortCutData([
          { value: "myActivePo", text: "My Active POs" },
          { value: "myPo", text: "My POs" },
          { value: "allPo", text: "All POs" },
        ]);
      } else {
        setShortCutData([
          { value: "myActivePo", text: "My Active POs" },
          { value: "myPo", text: "My POs" },
        ]);
      }
    });
  };

  const loadSchema = async () => {
    let f1: VendorApi = factory.create(VendorApi);
    let s1: CompanyApi = commonFactory.create(CompanyApi);
    // let result = await f1.vendorControllerGet(context?.loggedOnUser?.username);
    // let vendors = await result.json();
    // let companyResult = await s1.companyGet(context?.loggedOnUser?.username);
    // let companies = await companyResult.json();
    const vendorPromise = f1
      .vendorControllerGet(context?.loggedOnUser?.username)
      .then((result) => result.json());
    const companyPromise = s1
      .companyGet(context?.loggedOnUser?.username)
      .then((result) => result.json());
    const [vendors, companies] = await Promise.all([
      vendorPromise,
      companyPromise,
    ]);
    const s = [
      {
        field: "pO_ID",
        title: "PO #",
        label: "PO #",
        visible: true,
        searchable: true,
        type: "text",
        cell: PoLinkGridCell,
        width: "130px",
      },

      {
        field: "companyName",
        title: "Company Name",
        label: "Company Name",
        visible: true,
        searchable: true,
        type: "dropdown",
        options: [],
      },
      {
        field: "venD_NAME",
        title: "Vendor Name",
        label: "Vendor Name",
        visible: true,
        searchable: true,
        type: "dropdown",
        options: [],
      },
      {
        field: "PoTotalAmount",
        title: "Committed",
        label: "Committed",
        visible: true,
        searchable: true,
        type: "number",
      },
      {
        field: "costToDateAmount",
        title: "Cost To Date",
        label: "Cost To Date",
        visible: true,
        searchable: true,
        type: "number",
      },
      {
        field: "remainingAmount",
        title: "Remaining",
        label: "Remaining",
        visible: true,
        searchable: true,
        type: "number",
      },
      {
        field: "perF_START_DT",
        title: "POP Start Date",
        label: "POP Start Date",
        visible: true,
        searchable: true,
        type: "datepicker",
      },
      {
        field: "perF_END_DT",
        title: "POP End Date",
        label: "POP End Date",
        visible: true,
        searchable: true,
        type: "datepicker",
      },
      {
        field: "s_PO_STATUS_TYPE",
        title: "Status",
        label: "Status",
        visible: true,
        searchable: true,
        type: "boolean",
      },
      {
        field: "PROJ_ID",
        title: "Project #",
        label: "Project #",
        visible: true,
        searchable: true,
        type: "text",
      },
    ];

    if (vendors?.value) {
      vendors.value.map((v) => {
        s[2].options.push({ text: v.vendorName, value: v.vendorId });
      });
    }

    if (companies?.value) {
      companies.value.map((v) => {
        s[1].options.push({
          text: `${v.companyName} (${v.companyIdCP})`,
          value: v.companyIdCP,
        });
      });
    }

    setSchema(s);
    setIsAdvancedSearchLoaded(true);
  };

  const setSearchFieldsInAdvSearch = () => {
    let sf = [];
    schema.map((v, i) => {
      let c = getAdvancedSearchSelectedValue(v);
      if (v.type === "text" && v.searchable === true) {
        sf.push(
          Search.getTextboxType(
            "country",
            v.field,
            v.title,
            c?.value,
            c?.operator.value,
            c?.value2
          )
        );
      } else if (v.type === "number" && v.searchable === true)
        sf.push(
          Search.getNumericType(
            "country",
            v.field,
            v.title,
            c?.value,
            c?.operator.value,
            c?.value2
          )
        );
      else if (v.type === "boolean" && v.searchable === true)
        sf.push(
          Search.getBooleanType(
            "country",
            v.field,
            v.title,
            [
              { text: "Open", value: "O" },
              { text: "Closed", value: "C" },
              { text: "Void", value: "V" },
              { text: "System Closed", value: "S" },
            ],
            c?.value,
            c?.operator.value
          )
        );
      else if (
        v.type === "dropdown" &&
        v.field === "s_PO_STATUS_TYPE" &&
        v.searchable === true
      ) {
        var value = undefined;
        if (c?.value) {
          value = statusOption.find((v1) => v1.value === c.value);
        }
        sf.push(
          Search.getDropdownType(
            "country",
            v.field,
            v.title,
            statusOption,
            value,
            c?.operator.value
          )
        );
      } else if (
        v.type === "dropdown" &&
        v.field !== "s_PO_STATUS_TYPE" &&
        v.searchable === true
      ) {
        var value = undefined;
        if (c?.value) {
          value = v.options.find((v1) => v1.value === c.value);
        }
        sf.push(
          Search.getDropdownType(
            "country",
            v.field,
            v.title,
            v.options,
            value,
            c?.operator.value
          )
        );
      } else if (v.type === "datepicker" && v.searchable === true)
        sf.push(
          Search.getDatePickerType(
            "country",
            v.field,
            v.title,
            c?.value,
            c?.operator.value,
            c?.value2
          )
        );
    });

    setSearchFields(sf);
  };

  React.useEffect(() => {
    if (schema.length > 0) {
      setSearchFieldsInAdvSearch();
    }
  }, [schema]);

  /*React.useEffect(() => {
        if (selectedShortcut && selectedShortcut?.value !== "") {
            getPOListFromApi()
        }
    }, [])*/

  //let searchFields: XtAdvancedSearchFieldCondition[] = []

  const getAdvancedSearchSelectedValue = (field) => {
    let returnValue = null;
    searchParams.advancedSearchCondition?.map((v, i) => {
      if (field.field === v.field.field) {
        returnValue = v;
      }
    });
    return returnValue;
  };

  const getPOListFromApi = () => {
    let api: PurchaseOrderApi = factory.create(PurchaseOrderApi);
    setLoading(true);
    api
      .purchaseOrderGet(
        context?.loggedOnUser?.username,
        selectedShortcut?.value || "all",
        searchParams?.searchString,
        searchParams?.oDataQuery,
        paginationState[paginationEntity].top,
        paginationState[paginationEntity].skip,
        pageSize,
        paginationState[paginationEntity].sort.field +
          " " +
          paginationState[paginationEntity].sort.dir
      )
      .then(async (data) => {
        var dataFromApi = await data.json();
        setDataCount(dataFromApi["@odata.count"]);
        const poData = [];
        dataFromApi.value.map((po) => {
          poData.push({
            ...po,
            vendor: po.venD_NAME && `${po.venD_NAME} (${po.venD_ID})`,
          });
        });
        setData(poData);
      })
      .finally(() => {
        setLoading(false);
        setReloadPO(false);
      });
  };

  const statusOption = [
    { text: "Open", value: "O" },
    { text: "Closed", value: "C" },
    { text: "Void", value: "V" },
    { text: "System Closed", value: "S" },
  ];

  const onSearchCondition = (e) => {
    //TODO: DO not trigger this from anywhere else. Unless the apply button is clicked
    setSearchParam(e);
    setReloadPO(true);
    setIsAdvancedSearchLoaded(true);
    sessionStorage.setItem("POPageSearchHistory", JSON.stringify(e));
    updatePagination(paginationEntity, {
      skip: 0,
      top: 15,
      sort: {
        field: "createdOn",
        dir: "desc",
      },
    });
  };

  const onExportButtonClick = (e) => {
    let s2: PurchaseOrderApi = factory.create(PurchaseOrderApi);
    let s3 = e.dataItems.map((item) => {
      if (item.field === "PROJ_ID") {
        return { ...item, field: "projectNumber" };
      } else if (item.field === "PoTotalAmount") {
        return { ...item, field: "pO_TOT_AMT" };
      } else {
        return item;
      }
    });
    context.showExportPopup();
    const fieldValues = s3.map((obj) => obj.label);
    const joinedFields = fieldValues.join(",");
    s2.purchaseOrderExportExcelGet(
      context?.loggedOnUser?.username,
      selectedShortcut?.value || "all",
      searchParams?.searchString,
      searchParams?.oDataQuery,
      dataCount,
      null,
      dataCount,
      paginationState[paginationEntity].sort.field +
        " " +
        paginationState[paginationEntity].sort.dir,
      joinedFields
    )
      .then(async (data) => {
        return data.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "data.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        return Promise.reject({ Error: "Something Went Wrong", err });
      })
      .finally(() => {
        context.hideExportPopup();
      });

    // if (gridSelection.headerSelected) {
    //   setLoading(true);
    //   s.purchaseOrderGet(
    //     context?.loggedOnUser?.username,
    //     selectedShortcut?.value || "all",
    //     searchParams?.searchString,
    //     searchParams?.oDataQuery,
    //     undefined,
    //     undefined,
    //     undefined,
    //     paging.sort.field + " " + paging.sort.dir
    //   )
    //     .then(async (data) => {
    //       var d1 = await data.json();
    //       if (gridSelection.differedData.length === 0) setReportData(d1.value);
    //       else {
    //         let data: any[] = d1.value;
    //         let unSelected: any[] = [];
    //         let printData: any[] = [];

    //         gridSelection.differedData.map((v: PurchaseOrderInvoice, i) => {
    //           unSelected.push(v.poNumber);
    //         });

    //         data.map((v: PurchaseOrderInvoice, i) => {
    //           if (unSelected.indexOf(v.poNumber) === -1) {
    //             printData.push(v);
    //           }
    //         });
    //         setReportData(printData);
    //       }
    //       setExportType({
    //         type: e.type,
    //         fields: e.dataItems,
    //         filename: "test." + (e.type === "pdf" ? "pdf" : "xlsx"),
    //       });
    //       setIsExported(false);
    //     })
    //     .finally(() => {
    //       setLoading(false);
    //     });
    // } else {
    //   setReportData(data);
    //   setExportType({
    //     type: e.type,
    //     fields: e.dataItems,
    //     filename: "test." + (e.type === "pdf" ? "pdf" : "xlsx"),
    //   });
    //   setIsExported(false);
    // }
  };

  // const renderGridFields = () => {
  //   let items: any[] = [];
  //   schema.map((v, i) => {
  //     if (v.visible && v.field === "status") {
  //       items.push(
  //         <GridColumn
  //           sortable={true}
  //           key={"row" + i}
  //           field={v.field}
  //           title={v.title}
  //           {...v}
  //         />
  //       );
  //     } else {
  //       items.push(<GridColumn sortable={true} key={"row" + i} {...v} />);
  //     }
  //   });
  //   return items;
  // };

  const onPageChange = (e) => {
    updatePagination(paginationEntity, {
      ...paging,
      skip: e.page.skip,
      top: e.page.take,
    });
    setReloadPO(true);
  };

  const onSortChange = (e) => {
    if (e.sort.length > 0)
      updatePagination(paginationEntity, { ...paging, sort: e.sort[0] });
    else {
      updatePagination(paginationEntity, {
        ...paging,
        sort: {
          ...paging.sort,
          dir: paging.sort.dir === "asc" ? "desc" : "asc",
        },
      });
    }
    setReloadPO(true);
  };

  const onExportAfterRender = (e) => {
    if (e && !isExported) {
      e.save();
      setIsExported(true);
    }
  };

  // const onMenuClick = (e) => {};

  const onGridSelectionChange = (e) => {
    setGridSelection(e);
  };

  const onListShortutsChanged = (e) => {
    setSelectedShortcut(e.value);
    setReloadPO(true);
    sessionStorage.setItem("POPageShortcutHistory", JSON.stringify(e.value));
    updatePagination(paginationEntity, { ...paging, skip: 0 });
  };

  //const rowMenus = [{ text: 'Edit', icon: 'k-icon k-i-menu', onClick: onMenuClick }]
  const rowMenus = [];

  const onGridRowClick = (e) => {
    setRedirectTo("/po/details/" + e.dataItem.pO_ID);
  };

  return (
    <div>
      {redirectTo !== "" && (
        <Redirect
          push
          to={{
            pathname: redirectTo,
          }}
        />
      )}
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <APIFactoryCommon
        ref={(e) => {
          commonFactory = e;
        }}
      />
      {searchFields.length > 0 && (
        <GridToolbar>
          <div className="page-toolbar p-0 align-items-center">
            <div className="row flex-grow-1 g-0">
              <div className="col-xl-auto pe-md-3">
                <h5 className="mb-2 page-title">Purchase Orders</h5>
              </div>
              <div className="col-md px-md-0 mb-2">
                {" "}
                <XtAdvancedSearchBox
                  searchPlaceHolder="Search by Vendor name, PO #, Company Code"
                  hideAdvancedSearch={false}
                  searchConditions={searchFields}
                  selectedConditions={searchParams}
                  onSearchConditionApply={onSearchCondition}
                >
                  <XtAdvancedSearchChild type="prepend">
                    <DropDownList
                      style={{ width: "175px" }}
                      onChange={onListShortutsChanged}
                      data={shortCutData}
                      dataItemKey="value"
                      textField="text"
                      value={selectedShortcut}
                    />
                  </XtAdvancedSearchChild>
                </XtAdvancedSearchBox>
              </div>
              <div className="col-sm-auto pe-md-0 mb-2 btn-two-groups">
                <XtExportToolbar
                  className="btn btn-secondary"
                  fields={searchFields}
                  onApply={onExportButtonClick}
                  gridSelection={gridSelection}
                />
              </div>
            </div>
          </div>
        </GridToolbar>
      )}
      <XtExport
        type={expType.type}
        data={reportData}
        schema={expType.fields}
        fileName={expType.filename}
        onAfterRender={onExportAfterRender}
      >
        <XtGrid
          uniqueField="pO_ID"
          data={data}
          total={dataCount}
          sort={[paginationState[paginationEntity].sort]}
          rowMenus={rowMenus}
          sortable={true}
          skip={paginationState[paginationEntity].skip}
          pageSize={pageSize}
          pageable={true}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          onGridSelectionChange={onGridSelectionChange}
          className="k-grid-pr-list"
          onRowClick={onGridRowClick}
        >
          <GridColumn
            title="PO #"
            field="pO_ID"
            width="150px"
            cell={PoLinkGridCell}
          />
          <GridColumn title="Project #" field="projectNumber" width="150px" />
          <GridColumn title="Company Name" field="companyName" width="270px" />
          <GridColumn title="Vendor Name" field="vendor" width="270px" />
          <GridColumn
            title="Committed"
            field="pO_TOT_AMT"
            width="170px"
            cell={AmountGridCell}
            headerCell={RightAlignGridColumnHeader}
          />
          <GridColumn
            title="Cost To Date"
            field="costToDateAmount"
            width="170px"
            cell={AmountGridCell}
            headerCell={RightAlignGridColumnHeader}
          />
          <GridColumn
            title="Remaining"
            field="remainingAmount"
            width="170px"
            cell={AmountGridCell}
            headerCell={RightAlignGridColumnHeader}
          />
          <GridColumn
            title="POP Start Date"
            field="perF_START_DT"
            width="150px"
            cell={DateGridCell}
          />
          <GridColumn
            title="POP End Date"
            field="perF_END_DT"
            width="150px"
            cell={DateGridCell}
          />
          <GridColumn
            title="Status"
            field="s_PO_STATUS_TYPE"
            width="170px"
            cell={PoStatusGridCell}
          />
        </XtGrid>
      </XtExport>
      {loading && <Loading />}
    </div>
  );
}
