import { XtContextProvider } from "../Contexts/XtContextProvider";
import { defaultXtContext, XtContext, IXtContext } from "../Contexts/XtContext";
import { XtLayout } from "./XtLayout";
import React from "react";
import {
  APIFactoryCommon,
  UserInterfaceMenuApi,
} from "../ApiClients/Common/index";
import { ILeftNavItem } from "./XtNavSide";
import ErrorDialog from "../../components/Pages/Dialogs/Common/ErrorDialog";
import { PaginationProvider } from "../Contexts/PaginationContextProvider";

type XtLayoutWithMenuProps = React.PropsWithChildren<{
  dataLeftNav: ILeftNavItem[];
}>;

function XtLayoutWithMenu(props: XtLayoutWithMenuProps) {
  let apiFactory: APIFactoryCommon;

  const [appTree, setAppTree] = React.useState(null);
  const context: IXtContext = React.useContext(XtContext);
  React.useEffect(() => {
    let appApi = apiFactory.create(UserInterfaceMenuApi);

    if (context?.loggedOnUser?.username) {
      console.log("Context in Layout Menu", context?.loggedOnUser?.username);
      appApi
        .userInterfaceMenuGet()
        .then(async (response) => {
          let tree = await response.json();
          setAppTree(tree);
        })
        .catch((e) => {
          console.log("TREE SET ERROR");
          setAppTree(null);
        });
    }
  }, [context?.loggedOnUser?.username]);

  return (
    <XtLayout
      dataLeftNav={props.dataLeftNav}
      dataMainNav={appTree}
      isAuthenticated={context.isAuthenticated}
    >
      <APIFactoryCommon
        ref={(e) => {
          apiFactory = e;
        }}
      />
      {props.children}
    </XtLayout>
  );
}

export type XtApplicationShellProps = React.PropsWithChildren<{
  dataSideNav: ILeftNavItem[];
  config: {
    apiBasePath: string;
    oktaBasePath: string;
    commonAPIPath: string;
    onError?: any;
  };
}>;

export function XtApplicationShell(
  props: XtApplicationShellProps
): React.ReactElement {
  const [showError, setShowError] = React.useState<boolean>(false);
  const [errorTitle, setErrorTitle] = React.useState<string>("");
  const [errorMsg, setErrorMsg] = React.useState([]);

  const onPageError = (e) => {
    setErrorTitle(e.title);
    setErrorMsg(e.message);
    setShowError(true);
  };

  const onDialogClose = (e) => {
    setErrorMsg([]);
    setErrorTitle("");
    setShowError(false);
  };

  return (
    <XtContextProvider
      xtContext={defaultXtContext}
      config={{
        apiBasePath: props.config.apiBasePath,
        oktaBasePath: props.config.oktaBasePath,
        commonBasePath: props.config.commonAPIPath,
        onError: onPageError,
      }}
    >
      <PaginationProvider>
        <XtLayoutWithMenu dataLeftNav={props.dataSideNav}>
          {props.children}
        </XtLayoutWithMenu>
        {showError && (
          <ErrorDialog
            title={errorTitle}
            errorMessage={errorMsg}
            onClose={onDialogClose}
          />
        )}
      </PaginationProvider>
    </XtContextProvider>
  );
}
