import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { XtField } from "nwcommon";

import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
    APIFactoryCommon,
    CompanyApi,
    ProjectApi,
    VendorApi,
} from "../../../../../Xt/ApiClients/Common";
import FormField, { RequiredFormField } from "../Common/FormField";
import FormLabelMutedText, { FormPoLink } from "../Common/FormLabelMutedText";
import { EnPrAction, EnPrTypeDetail } from "../Common/PrReducer";
import { DateUtil } from "../../../../../Xt/Utils/DateUtil";
import { enum_request_type, NwPrConstants } from "../Common/NwPrConstants";
import PopOverIcon from "../Common/PopOverIcon";


const requisitionTypeInfo = (
  <PopOverIcon header="Requisition Type" >
    <div>
      <p>
        <b>Material/ODCs</b> - All-inclusive term used in place of any of the
        following: appurtenance, assembly, component, equipment, item, module,
        part, structure, sub-assembly, subsystem, system, or unit. For Example:
        Dumpster rental, garbage service, toilet rental, freight
        charges/material delivery charges.
      </p>
      <p>
      <b>Service</b> - All-inclusive term used in place of any of the following:
        installation, design, inspection, engineering, repair, or any
        environmental services acquired in support of the work NWG performs for
        its clients.
      </p>
      <p>
        Please see COP-4002 “Acquisition of Goods and Services” for additional
        detail.
      </p>
    </div>
  </PopOverIcon>
);

const chargeTypeInfo = (
  <PopOverIcon header="Charge Type" >
    <div>
      <p>
      <b>Direct</b> - Purchase(s) made directly for a North Wind project. (Ex: Project
        080153 – ETEC)
      </p>
      <p>
        <b>Indirect</b> - Purchase(s) considered as Overhead (OVHD), General &
        Administrative (G&A), Fringe (FRNG), Unallowable (UNAL), and Bids and
        Proposals (B&P).
      </p>
    </div>
  </PopOverIcon>
);

const dateNeededInfo = (
    <PopOverIcon header="Date Needed" >
      <div>
        <p>
        <b>For Material/ODCs</b> – Arrival Date of the Material/ODCs 
        </p>
        <p>
        <b>For Services</b> – Issued Date of the Subcontract to Subcontractor
        </p>
      </div>
    </PopOverIcon>
  );

export default function NwGeneralInfo(props) {
    const { state, dispatch } = props;
    let factory: APIFactoryCommon;
    const [loadingCompanies,setLoadingCompanies] = useState(true);
    const [loadingProjects,setLoadingProjects] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [projects, setProjects] = useState([]);


    
    const context: IXtContext = useContext(XtContext);
    const init = true;

    useEffect(() => {
        if (context?.loggedOnUser?.username && loadingCompanies) {
            let companyApi: CompanyApi = factory.create(CompanyApi);
            companyApi.companyGet(context?.loggedOnUser?.username).then(async (companyData) => {
                var companyDataFromApi = await companyData.json();
               
                const companyList = companyDataFromApi.value.map((c) => {
                    return { text: `${c.companyName} (${c.companyIdCP})`, value: c.companyIdCP };
                });
                setLoadingCompanies(false);
                setCompanies(companyList);
            });
        }
    }, [context?.loggedOnUser?.username]);

    useEffect(() => {

        if (state?.companyCode && state?.chargeType === "Direct") {
            setLoadingProjects(true);
            let porjectApi: ProjectApi = factory.create(ProjectApi);
            porjectApi.projectListByUserGet(context?.loggedOnUser?.username,state?.chargeType, state?.companyCode).then(async (proejctData) => {
                var projectDataFromApi = await proejctData.json();
                let projectList: any = projectDataFromApi.value;
                if (state?.prTypeDetail === EnPrTypeDetail.PR_CREATE_NEW) {
                   projectList =  projectList.filter(x => x.activE_FL == 'Y')
                }
                projectList = projectList.map((p) => {
                    var activeInactive = p.activE_FL == 'Y' ? "Active" : "Inactive";
                    var projDisplay = `${p.proJ_ID} (${p.proJ_LONG_NAME}) (${activeInactive})`;
                    
                    return { text: projDisplay, value: p.proJ_ID, accountGrpCode: p.accT_GRP_CD, orgId: p.orG_ID };
                });
                setLoadingProjects(false);
                setProjects(projectList);
            });
        } else {
            dispatch({
                type: EnPrAction.PROJECT_NUMBER_CHANGE,
                payload: undefined,
              });
            setProjects([]);
            
            

        }

    }, [state?.companyCode, state?.chargeType]);

    useEffect(() => {
      if (state?.projectNumber && projects?.length > 0) {
        dispatch({
          type: EnPrAction.PROJECT_NUMBER_CHANGE,
          payload: projects.find((p) => p.value == state?.projectNumber),
        });
      }
    }, [projects.length]);



    const getTextOrEmpty = (list, currentValue) => {
        const listItem = list.find(l => l.value === currentValue)
        if (listItem)
            return listItem.text;
        else
            return null;
    }
    return (
        <>
            <APIFactoryCommon
                ref={(e) => {
                    factory = e;
                }}
            />

            <Row>
                <FormField label="PR No.">
                    <FormLabelMutedText value={state?.prNumber??"Auto generated"} />
                </FormField>

                
                {state?.prTypeDetail === EnPrTypeDetail.PO_CHANGE_REQUEST_BY_PR && (
                        <FormField label="Requisition Type" >
                        <FormLabelMutedText
                            value={
                                state?.requisitionType === "Material"
                                    ? "Material/ODCs"
                                    : "Service"
                            }
                        />
                        </FormField>
                    
                    )}
                    {state?.prTypeDetail !== EnPrTypeDetail.PO_CHANGE_REQUEST_BY_PR  && (
                        <RequiredFormField label="Requisition Type" info = {requisitionTypeInfo}  >
                        <XtField
                            type="radiogroup"
                            name="requisitionType"
                            layout="horizontal"
                            items={[
                                { text: "Material/ODCs", value: "Material" },
                                { text: "Service", value: "Service" },
                            ]}
                            value={state?.requisitionType}
                            onChange={(e) =>
                                dispatch({
                                    type: EnPrAction.REQUISITION_TYPE_CHANGE,
                                    payload: e.value,
                                })
                            }
                            required={true}
                            errormessage="Requisition Type is required"
                        />
                        </RequiredFormField>
                    )}
                

                <FormField label="Created By">
                    <FormLabelMutedText
                        value={
                            state?.employeeName
                                ? state?.employeeName
                                : context.loggedOnUser && context.loggedOnUser.displayname
                        }
                    />
                </FormField>

                
                    {state?.prTypeDetail === EnPrTypeDetail.PO_CHANGE_REQUEST_BY_PR  && (
                        <FormField label="Charge Type">
                        <FormLabelMutedText value={state?.chargeType} />
                        </FormField>
                    )}
                    {state?.prTypeDetail !== EnPrTypeDetail.PO_CHANGE_REQUEST_BY_PR  && (
                        <RequiredFormField label="Charge Type" info = {chargeTypeInfo} >
                        <XtField
                            type="radiogroup"
                            name="chargeType"
                            layout="horizontal"
                            items={[
                                { text: "Direct", value: "Direct" },
                                { text: "Indirect", value: "Indirect" },
                            ]}
                            value={state?.chargeType}
                            onChange={(e) =>
                                dispatch({
                                    type: EnPrAction.CHARGE_TYPE_CHANGE,
                                    payload: e.value,
                                })
                            }
                            required={true}
                            errormessage="Charge Type is required"
                        />
                        </RequiredFormField>
                    )}
                

                <FormField label="Date Created">
                    <FormLabelMutedText
                        value={DateUtil.formatmmDdYYY(state?.createdOn)}
                    />
                </FormField>
                
                    {state?.prTypeDetail === EnPrTypeDetail.PO_CHANGE_REQUEST_BY_PR  && (
                        <FormField label="NW Company">
                        <FormLabelMutedText
                            value={state?.companyName}
                        />
                        </FormField>
                    )}
                    {state?.prTypeDetail !== EnPrTypeDetail.PO_CHANGE_REQUEST_BY_PR  && (
                        <RequiredFormField label="NW Company">
                        <XtField
                            type="dropdown"
                            name="companyCode"
                            items={companies}
                            textField="text"
                            value={companies.find((c) => c.value == state?.companyCode)||null}
                            onChange={(e) =>
                                dispatch({
                                    type: EnPrAction.COMPANY_CODE_CHANGE,
                                    payload: e?.value ? e?.value?.value : undefined,
                                })
                            }
                            required={true}
                            errormessage="Company is required"
                            loading = {loadingCompanies}
                        />
                        </RequiredFormField>
                    )}

                {state?.chargeType == "Direct" && (
                    <>
                        <FormField></FormField>
                        
                            {state?.prTypeDetail === EnPrTypeDetail.PO_CHANGE_REQUEST_BY_PR  && (
                                <FormField label="Project No.">
                                <FormLabelMutedText
                                    value={getTextOrEmpty(projects, state?.projectNumber)}
                                />
                                </FormField>
                            )}
                            {state?.prTypeDetail !== EnPrTypeDetail.PO_CHANGE_REQUEST_BY_PR  && (
                                <RequiredFormField label="Project No.">
                                <XtField
                                    type="dropdown"
                                    name="projectNumber"
                                    items={projects}
                                    value={projects.find((p) => p.value == state?.projectNumber)||null}
                                    textField="text"
                                    required={true}
                                    errormessage="Project number is required"
                                    onChange={(e) => {
                                        dispatch({
                                            type: EnPrAction.PROJECT_NUMBER_CHANGE,
                                            payload: e.value
                                                ? e.value
                                                : {
                                                    value: undefined, accountGrpCode: e?.accountGrpCode
                                                }
                                        })
                                    }
                                    }
                                    loading = {loadingProjects}
                                />
                                </RequiredFormField>
                            )}
                        
                    </>
                )}
                <FormField label="PR Type">
                    <FormLabelMutedText value={state?.prType} />
                </FormField>

                <RequiredFormField label="Date Needed" info={dateNeededInfo}>
                    {state?.dateNeeded &&
                        <XtField
                            type="datepicker"
                            name="dateNeeded"
                            value={new Date(state?.dateNeeded)}
                            required={true}
                            errormessage="Date Needed is required"
                            minDate={new Date()}
                            weekNumber={false}
                            onChange={(e) => {
                                dispatch({
                                    type: EnPrAction.DATE_NEEDED_CHANGE,
                                    payload: e.value,
                                });
                            }}
                        />}

                    {!state?.dateNeeded &&
                        <XtField
                            type="datepicker"
                            name="dateNeeded"
                            value={state?.dateNeeded}
                            required={true}
                            errormessage="Date Needed is required"
                            minDate={new Date()}
                            weekNumber={false}
                            onChange={(e) => {
                                dispatch({
                                    type: EnPrAction.DATE_NEEDED_CHANGE,
                                    payload: e.value,
                                });
                            }}
                        />}
                </RequiredFormField>

                {state?.prType === enum_request_type.PO_CHANGE_REQUEST && (
                    <FormField label="PO Number">
                        <FormPoLink poNumber={state?.poNumber}></FormPoLink>
                    </FormField>
                )}
            </Row>
        </>
    );
}
