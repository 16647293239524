import React from "react";
import * as Search from "nwcommon";
import { Redirect, Link } from "react-router-dom";
import { XtExport, XtExportType, XtExportToolbar } from "nwcommon";
import {
  XtAdvancedSearchFieldCondition,
  XtAdvancedSearchBox,
  XtAdvancedSearchChild,
} from "nwcommon";
import { XtContext, IXtContext } from "../../../Xt/Contexts/XtContext";
import { GridToolbar } from "@progress/kendo-react-grid";
import { XtGrid, XtGridRowSelection } from "nwcommon";
import { Loading } from "../../../Xt/Layout/Loading";
import { XtPaging } from "../../../Xt/ApiClients/ApiHelper";
import {
  APIFactory,
  InvoiceApi,
  Invoice,
  VendorApi,
  UserApi,
} from "../../../Xt/ApiClients";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import RightAlignGridColumnHeader from "../Nw/PurchaseRequisition/Common/GridCell/RightAlignGridColumnHeader";
import AmountGridCell from "../Nw/PurchaseRequisition/Common/GridCell/AmountGridCell";
import DateGridCell from "../Nw/PurchaseRequisition/Common/GridCell/DateGridCell";
import { APIFactoryCommon, CompanyApi } from "../../../Xt/ApiClients/Common";
import { AdvSearchAndGridUtil } from "../../../Xt/Utils/AdvanceSearchAndGridUtil";
import PoLinkGridCell from "../Nw/PurchaseRequisition/Common/GridCell/PoLinkGridCell";
import PopOverGridCell from "../Nw/PurchaseRequisition/Common/GridCell/PopOverGridCell";
import { StringUtil } from "../../../Xt/Utils/StringUtil";
import { InvoiceApiService } from "./Common/InvoiceApiService";
import { PaginationContext } from "../../../Xt/Contexts/PaginationContext";

export default function InvoiceStatusGridCell(props) {
  let status: string = props.dataItem["status"] || "";
  let invoiceGuid: string = props.dataItem["invoiceGuid"];
  if (!invoiceGuid) invoiceGuid = props.dataItem["resourceGuid"];
  const prStatusClassName = `badge  pr-status-${status
    ?.replace(" ", "-")
    .toLowerCase()}`;
  if (props.dataItem?.isRejected) {
    status = "Rejected";
  } else if (props.dataItem?.isRevised) {
    status = "Revised";
  }
  return (
    <td>
      {status.toLocaleLowerCase() == "waiting for cost point integration" && (
        <Link to={"/invoice/landingpage/" + invoiceGuid}>
          <span className={prStatusClassName} role={"gridcell"}>
            {status === null ? "" : StringUtil.replaceCostPoint(status)}
          </span>
        </Link>
      )}
      {status.toLocaleLowerCase() != "waiting for cost point integration" && (
        <span className={prStatusClassName} role={"gridcell"}>
          {status === null ? "" : StringUtil.replaceCostPoint(status)}
        </span>
      )}
    </td>
  );
}

interface XtExportFileType {
  type: XtExportType;
  fields: any[];
  filename: string;
}

const myShortcutsData = [
  { value: "myActiveInvoices", text: "My Active Invoices" },
  { value: "myInvoices", text: "My Invoices" },
  { value: "myDraft", text: "My Drafts" },
  { value: "all", text: "All Invoices" },
];

export function InvoiceList(props) {
  const pageSize = 15;
  const orderByField = "createdOn";
  const [expType, setExportType] = React.useState<XtExportFileType>({
    type: "none",
    fields: [],
    filename: "",
  });
  const [data, setData] = React.useState<any[]>([]);
  const [reportData, setReportData] = React.useState<any[]>([]);
  const [dataCount, setDataCount] = React.useState<number>(0);
  const [nextUrl, setNextUrl] = React.useState<string>("");

  const [paging, setPaging] = React.useState<XtPaging>({
    skip: 0,
    top: pageSize,
    sort: { field: orderByField, dir: "desc" },
  });
  const [searchParams, setSearchParam] = React.useState<any>({});
  const [gridSelection, setGridSelection] = React.useState<XtGridRowSelection>({
    headerSelected: false,
    differedData: [],
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [selectedShortcut, setSelectedShortcut] = React.useState<any>(
    sessionStorage.getItem("InvoicePageShortcutHistory")
      ? JSON.parse(sessionStorage.getItem("InvoicePageShortcutHistory"))
      : myShortcutsData[0]
  );
  const [redirectTo, setRedirectTo] = React.useState<string>("");
  const [schema, setSchema] = React.useState<any[]>([]);
  const [searchFields, setSearchFields] = React.useState<
    XtAdvancedSearchFieldCondition[]
  >([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [allowToAdd, setAllowToAdd] = React.useState<boolean>(false);
  const [searchParamsInitialized, setSearchParamsInitialized] =
    React.useState(false);
  const [isAdvancedSearchLoaded, setIsAdvancedSearchLoaded] =
    React.useState<boolean>(false);
  let factory: APIFactory;
  let commonFactory;
  const context: IXtContext = React.useContext(XtContext);
  const { paginationState, updatePagination } =
    React.useContext(PaginationContext);
  const paginationEntity = "invoice";

  const invoiceCallActive = React.useRef(false);
  // let factory: APIFactory

  const schema1 = [
    {
      field: "invoiceID",
      label: "Invoice ID",
      visible: true,
      searchable: true,
      type: "text",
      width: "95",
    },
    {
      field: "poNumber",
      label: "PO #",
      visible: true,
      searchable: true,
      type: "text",
      cell: PoLinkGridCell,
    },
    {
      field: "company",
      label: "Company Name",
      visible: true,
      searchable: true,
      type: "dropdown",
      options: [],
    },
    {
      field: "companyCode",
      label: "Company Code",
      visible: false,
      searchable: false,
      type: "dropdown",
    },
    {
      field: "vendorName",
      label: "Vendor Name",
      visible: false,
      searchable: true,
      type: "dropdown",
      options: [],
    },
    {
      field: "vendor",
      label: "Vendor Name",
      visible: true,
      searchable: false,
      type: "dropdown",
      options: [],
    },
    {
      field: "vendorInvoiceNo",
      label: "Vendor Invoice #",
      visible: true,
      searchable: true,
      type: "text",
    },
    {
      field: "description",
      label: "Description",
      visible: true,
      searchable: false,
      type: "text",
      cell: PopOverGridCell,
    },
    {
      field: "vendorAmount",
      label: "Total Amount",
      visible: true,
      searchable: true,
      type: "number",
      headerCell: RightAlignGridColumnHeader,
      cell: AmountGridCell,
    },
    {
      field: "voucherNo",
      label: "Voucher #",
      visible: true,
      searchable: true,
      type: "text",
    },
    {
      field: "projectNumber",
      label: "Project Number",
      visible: false,
      searchable: true,
      type: "text",
    },
    {
      field: "invoiceDate",
      label: "Invoice Date",
      visible: true,
      searchable: true,
      type: "datepicker",
      format: "{0:dt}",
      cell: DateGridCell,
    },
    {
      field: "createdOn",
      label: "Created Date",
      visible: true,
      searchable: true,
      type: "datepicker",
      format: "{0:dt}",
      cell: DateGridCell,
    },
    {
      field: "representative",
      label: "Accounting Rep",
      visible: false,
      searchable: true,
      type: "dropdown",
      cell: PopOverGridCell,
      options: [],
    },
    // {
    //   field: "accountRepName",
    //   label: "Accounting Rep",
    //   visible: false,
    //   searchable: true,
    //   type: "dropdown",
    //   cell: PopOverGridCell,
    //   options: [],
    // },
    {
      field: "status",
      label: "Status",
      visible: true,
      searchable: true,
      type: "text",
      cell: InvoiceStatusGridCell,
    },
  ];

  React.useEffect(() => {
    if (sessionStorage.getItem("InvoicePageSearchHistory")) {
      setSearchParam(
        JSON.parse(sessionStorage.getItem("InvoicePageSearchHistory"))
      );
    }
    setSearchParamsInitialized(true);
  }, []);

  React.useEffect(() => {
    let s: InvoiceApi = factory.create(InvoiceApi);
    if (context?.loggedOnUser?.username) {
      loadOptions(factory).then((v) => {});
      s.invoiceAddInvoiceAuthGet(context?.loggedOnUser?.username).then(
        async (value: Response) => {
          var d2 = await value.json();
          setAllowToAdd(d2.result);
        }
      );
    }
  }, [context?.loggedOnUser?.username]);

  React.useEffect(() => {
    if (!searchParamsInitialized) {
      return;
    }
    if (invoiceCallActive.current == true) return;

    if (context?.loggedOnUser?.username) {
      invoiceCallActive.current = true;
      setLoading(true);

      let s: InvoiceApi = factory.create(InvoiceApi);
      s.invoiceGet(
        context?.loggedOnUser?.username,
        searchParams?.searchString,
        selectedShortcut?.value || "all",
        searchParams?.oDataQuery,
        paginationState[paginationEntity].top,
        paginationState[paginationEntity].skip,
        pageSize,
        paginationState[paginationEntity].sort.field +
          " " +
          paginationState[paginationEntity].sort.dir
      )
        .then(async (data) => {
          var d1 = await data.json();
          setDataCount(d1["@odata.count"]);
          var prData = d1.value.map((elem) => ({
            ...elem,
            vendor:
              elem.vendorName && `${elem.vendorName} (${elem.vendorCode})`,
          }));
          setData(prData);
        })
        .finally(() => {
          setLoading(false);
          invoiceCallActive.current = false;
        });
    }
  }, [
    paginationState[paginationEntity],
    searchParams,
    selectedShortcut,
    context?.loggedOnUser?.username,
    isAdvancedSearchLoaded,
    searchParamsInitialized,
  ]);

  const loadOptions = async (f) => {
    loadCompanyAndVendor(f);
    loadAccountingRep();
  };

  const loadAccountingRep = async () => {
    let userApi: UserApi = factory.create(UserApi);
    let result = await InvoiceApiService.getReps(
      userApi,
      context?.loggedOnUser?.username,
      true
    );
    schema1[12].options = result;
  };

  const loadCompanyAndVendor = async (f) => {
    let s: VendorApi = f.create(VendorApi);
    let s1: CompanyApi = commonFactory.create(CompanyApi);
    // let result = await s.vendorControllerGet(context?.loggedOnUser?.username);
    // let vendors = await result.json();
    // let companyResult = await s1.companyGet(context?.loggedOnUser?.username);
    // let companies = await companyResult.json();
    const vendorPromise = s
      .vendorControllerGet(context?.loggedOnUser?.username)
      .then((result) => result.json());
    const companyPromise = s1
      .companyGet(context?.loggedOnUser?.username)
      .then((result) => result.json());
    const [vendors, companies] = await Promise.all([
      vendorPromise,
      companyPromise,
    ]);
    if (vendors?.value) {
      vendors.value.map((v) => {
        schema1[4].options.push({ text: v.vendorName, value: v.vendorName });
      });
    }

    if (companies?.value) {
      companies.value.map((v) => {
        schema1[2].options.push({
          text: `${v.companyName} (${v.companyIdCP})`,
          value: v.companyIdCP,
        });
      });
    }

    let searchFields1: any[] = [];
    schema1.map((v, i) => {
      let c = getAdvancedSearchSelectedValue(v);
      if (v.type === "text" && v.searchable === true) {
        searchFields1.push(
          Search.getTextboxType(
            "",
            v.field,
            v.label,
            c?.value,
            c?.operator.value,
            c?.value2
          )
        );
      }
      if (v.type === "datepicker" && v.searchable === true) {
        searchFields1.push(
          Search.getDatePickerType(
            "",
            v.field,
            v.label,
            c?.value,
            c?.operator.value,
            c?.value2
          )
        );
      } else if (v.type === "number" && v.searchable === true)
        searchFields1.push(
          Search.getNumericType(
            "",
            v.field,
            v.label,
            c?.value,
            c?.operator.value,
            c?.value2
          )
        );
      else if (v.type === "boolean" && v.searchable === true)
        searchFields1.push(
          Search.getBooleanType(
            "",
            v.field,
            v.label,
            [
              { text: "Active", value: "true" },
              { text: "Inactive", value: "false" },
            ],
            c?.value,
            c?.operator.value
          )
        );
      else if (v.type === "dropdown" && v.searchable === true) {
        searchFields1.push(
          Search.getDropdownType(
            "",
            v.field,
            v.label,
            v.options,
            c?.value,
            c?.operator.value
          )
        );
      }
    });
    setSchema(schema1);

    setSearchFields(searchFields1);
    setLoaded(true);
  };

  const getAdvancedSearchSelectedValue = (field) => {
    let returnValue = null;
    searchParams.advancedSearchCondition?.map((v, i) => {
      if (field.field === v.field.field) {
        returnValue = v;
      }
    });
    return returnValue;
  };

  const onSearchCondition = React.useCallback(
    (e) => {
      if (invoiceCallActive.current) {
        return;
      }
      setIsAdvancedSearchLoaded(true);
      setSearchParam(e);
      sessionStorage.setItem("InvoicePageSearchHistory", JSON.stringify(e));
      updatePagination(paginationEntity, {
        skip: 0,
        top: 15,
        sort: {
          field: "createdOn",
          dir: "desc",
        },
      });
    },
    [setSearchParam]
  );

  const onExportButtonClick = (e) => {
    const fieldValues = AdvSearchAndGridUtil.getExportType(e).fields.map(
      (obj) => obj.label
    );
    const joinedFields = fieldValues.join(",");
    let s: InvoiceApi = factory.create(InvoiceApi);
    context.showExportPopup();
    s.invoiceExportExcelGet(
      context?.loggedOnUser?.username,
      searchParams?.searchString,
      selectedShortcut?.value || "all",
      searchParams?.oDataQuery,
      dataCount,
      null,
      dataCount,
      paginationState[paginationEntity].sort.field +
        " " +
        paginationState[paginationEntity].sort.dir,
      joinedFields
    )
      .then(async (data) => {
        return data.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "data.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        return Promise.reject({ Error: "Something Went Wrong", err });
      })
      .finally(() => {
        context.hideExportPopup();
      });

    // if (gridSelection.headerSelected) {
    //   setLoading(true);
    //   s.invoiceGet(
    //     context?.loggedOnUser?.username,
    //     searchParams?.searchString,
    //     selectedShortcut?.value || "all",
    //     searchParams?.oDataQuery,
    //     undefined,
    //     undefined,
    //     undefined,
    //     paging.sort.field + " " + paging.sort.dir
    //   )
    //     .then(async (data) => {
    //       var d1 = await data.json();
    //       if (gridSelection.differedData.length === 0) setReportData(d1.value);
    //       else {
    //         let data: any[] = d1.value;
    //         let unSelected: any[] = [];
    //         let printData: any[] = [];

    //         gridSelection.differedData.map((v: Invoice, i) => {
    //           unSelected.push(v.invoiceGuid);
    //         });

    //         data.map((v: Invoice, i) => {
    //           if (unSelected.indexOf(v.invoiceGuid) === -1) {
    //             printData.push(v);
    //           }
    //         });
    //         setReportData(printData);
    //       }
    //       setExportType({
    //         type: e.type,
    //         fields: e.dataItems,
    //         filename: "test." + (e.type === "pdf" ? "pdf" : "xlsx"),
    //       });
    //     })
    //     .finally(() => {
    //       setLoading(false)
    //     });
    // } else {
    //   setReportData(data);
    //   //setReportData(gridSelection.differedData)
    //   setExportType({
    //     type: e.type,
    //     fields: e.dataItems,
    //     filename: "test." + (e.type === "pdf" ? "pdf" : "xlsx"),
    //   });
    //   setIsExported(false);
    // }
  };

  const onPageChange = (e) => {
    // setPaging({ ...paging, skip: e.page.skip, top: e.page.take });
    updatePagination(paginationEntity, {
      ...paging,
      skip: e.page.skip,
      top: e.page.take,
    });
  };

  const onSortChange = (e) => {
    if (e.sort.length > 0)
      updatePagination(paginationEntity, { ...paging, sort: e.sort[0] });
    else {
      updatePagination(paginationEntity, {
        ...paging,
        sort: {
          ...paging.sort,
          dir: paging.sort.dir === "asc" ? "desc" : "asc",
        },
      });
    }
  };

  const onExportAfterRender = (e) => {
    if (e && !loading) {
      e.save();
    }
  };

  const onMenuClick = (e) => {};

  const onGridSelectionChange = (e) => {
    setGridSelection(e);
  };

  const onListShortutsChanged = (e) => {
    setSelectedShortcut(e.value);
    sessionStorage.setItem(
      "InvoicePageShortcutHistory",
      JSON.stringify(e.value)
    );
    updatePagination(paginationEntity, {
      skip: 0,
      top: 15,
      sort: {
        field: "createdOn",
        dir: "desc",
      },
    });
  };

  const onGridRowClick = (e) => {
    if (
      e.dataItem.isEditable &&
      e?.dataItem?.status?.toLowerCase() === "draft"
    ) {
      setRedirectTo(`/invoice/edit/${e.dataItem.invoiceGuid}`);
    } else {
      setRedirectTo(`/invoice/details/${e.dataItem.invoiceGuid}`);
    }
  };

  const rowMenus = [
    { text: "Edit", icon: "k-icon k-i-menu", onClick: onMenuClick },
  ];

  return (
    <div>
      {redirectTo !== "" && <Redirect push to={{ pathname: redirectTo }} />}
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <APIFactoryCommon
        ref={(e) => {
          commonFactory = e;
        }}
      />
      <GridToolbar>
        <div className="page-toolbar p-0 align-items-center">
          <div className="row flex-grow-1 g-0">
            <div className="col-xl-auto pe-md-3">
              <h5 className="mb-2 page-title">Invoices</h5>
            </div>
            <div className="col-md px-md-0 mb-2">
              {" "}
              {loaded && (
                <XtAdvancedSearchBox
                  searchPlaceHolder="Search by PO#, Company Code, Voucher #,"
                  hideAdvancedSearch={false}
                  searchConditions={searchFields}
                  selectedConditions={searchParams}
                  onSearchConditionApply={onSearchCondition}
                >
                  <XtAdvancedSearchChild type="prepend">
                    <DropDownList
                      style={{ width: "200px" }}
                      onChange={onListShortutsChanged}
                      data={myShortcutsData}
                      dataItemKey="value"
                      textField="text"
                      value={selectedShortcut}
                    />
                  </XtAdvancedSearchChild>
                </XtAdvancedSearchBox>
              )}
            </div>
            <div className="col-sm-auto pe-md-0 mb-2 btn-two-groups">
              {allowToAdd && (
                <button
                  className="btn btn-primary me-2"
                  onClick={(e) => {
                    setRedirectTo("/invoice/add");
                  }}
                >
                  Create New
                </button>
              )}
              <XtExportToolbar
                className="btn btn-secondary"
                fields={searchFields}
                onApply={onExportButtonClick}
                gridSelection={gridSelection}
              />
            </div>
          </div>
        </div>
      </GridToolbar>
      <XtExport
        type={expType.type}
        data={reportData}
        schema={expType.fields}
        fileName={expType.filename}
        onAfterRender={onExportAfterRender}
      >
        <XtGrid
          className="k-grid-pr-list"
          uniqueField="invoiceID"
          data={data}
          total={dataCount}
          sort={[paginationState[paginationEntity].sort]}
          rowMenus={[]}
          sortable={true}
          onRowClick={onGridRowClick}
          skip={paginationState[paginationEntity].skip}
          pageSize={pageSize}
          pageable={true}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          onGridSelectionChange={onGridSelectionChange}
        >
          {AdvSearchAndGridUtil.renderGridFields(schema1)}
        </XtGrid>
      </XtExport>
      {loading && <Loading />}
    </div>
  );
}
