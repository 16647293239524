import { NwUserInterfaceMenuProps } from '../../Props/NwMenu/NwUserInterfaceMenuProps';
import { XtNavDropdownItem } from '../NavBar/XtNavDropdownItem';
import XtSocialLinks from '../NavBar/XtSocialLinks';
import NwUserInferface from './NwUserInferface';
export default function NwResourcesMenu(props:NwUserInterfaceMenuProps) {
  const socialMenuDataFromApi = props.menuData.filter(md=> md.mediaType==="social");
  const resourcesWithoutSocialLink = props.menuData.filter(md=> md.mediaType!=="social");
  const socialLinks = <XtNavDropdownItem   href="social links" key="social-link">
  <XtSocialLinks menuData= {socialMenuDataFromApi}/>
  </XtNavDropdownItem>;

  const userInterfaceProps = { menuData:resourcesWithoutSocialLink, interfaceName:"Resources", firstLevelChild: socialLinks};

    return (
         <NwUserInferface {...userInterfaceProps} />
    )

}
