import { useContext, useState } from "react";

import { useOktaAuth } from "@okta/okta-react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button, Col, Container } from "react-bootstrap";

import { XtContext } from "../../../../Xt/Contexts/XtContext";
import { ErrorUtil } from "../../../../Xt/Utils/ErrorUtil";

type InvoiceDraftDeleteButtonProps = {
  setDialogSuccess: (
    prNumber: string,
    actionType: string,
    alertMessage: string
  ) => void;
  setDialogError: (errorMessage: string[]) => void;
  invoiceGuid: string;
  invoiceID: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export function InvoiceDraftDeleteButton(props: InvoiceDraftDeleteButtonProps) {
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const {
    setDialogSuccess,
    setDialogError,
    invoiceGuid,
    invoiceID,
    loading,
    setLoading,
  } = props;

  const context = useContext(XtContext);
  const { authState } = useOktaAuth();

  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      await handleDelete();
    } catch (error) {
      console.log(error);
      setDialogError(["Error deleting PR draft"]);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${context.apiBasePath}/Invoice/Delete?key=${invoiceGuid}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
            Loggedonuser: context?.loggedOnUser.username,
          },
        }
      );

      if (response.ok) {
        setDialogSuccess(invoiceID, "Delete", null);
      } else {
        const data = await response.json();
        const errors = ErrorUtil.getErorMessageFromApi(data);
        setDialogError(errors);
      }
    } catch (error) {
      console.log(error);
      setDialogError(error);
    }
  };

  return (
    <>
      {!loading && (
        <span
          title="Delete"
          role="button"
          className="k-icon k-i-delete k-icon-md mx-2"
          onClick={() => setIsConfirmingDelete(true)}
          id="delete"
        />
      )}

      {loading && (
        <span
          title="Delete"
          role="button"
          className="k-icon k-i-delete k-icon-md mx-2"
        />
      )}

      {isConfirmingDelete && (
        <Dialog
          title="Confirmation"
          onClose={() => {
            setIsConfirmingDelete(false);
          }}
        >
          <Container>
            <Col>Do you really want to delete this draft?</Col>
          </Container>
          <DialogActionsBar>
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                handleConfirmDelete();
                setIsConfirmingDelete(false);
              }}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setIsConfirmingDelete(false);
              }}
            >
              No
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}
