import { useState } from "react";
import { Card } from "react-bootstrap";
import {Redirect} from "react-router-dom"

const cards = [
  {
    url: "/admin/customer",
    name: "Customer",
    description: "View/add and edit customer.",
    iconName: "people_outline",
  },
  {
    url: "/admin/company",
    name: "Company",
    description: "View/add/edit user, group and policy.",
    iconName: "business",
  },
  {
    url: "/admin/office",
    name: "Office",
    description: "View/add and edit office.",
    iconName: "work_outline",
  },
  {
    url: "/auditLog",
    name: "Audit Log",
    description: "View audit logs",
    iconName: "timeline",
  },
  {
    url: "/eventLog",
    name: "Event Log",
    description: "View Event logs",
    iconName: "timeline",
  },
  {
    url: "/user",
    name: "User Management",
    description: "View user management",
    iconName: "person_outline",
  },
];

export default function XtAdminSettings() {
  const redirectTo = {shouldRedirect:false, url:""}
  const [redirect,setRedirect] = useState(redirectTo);
  

  return (
    <>
          {redirect.shouldRedirect && <Redirect push to={redirect.url}/>}
      <div className="admin-settings row">
        {cards.map((card, i) => (
          <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
            <Card
              className="h-100"
              key={`${card.name}-${i}`}
              onClick={(e) =>   setRedirect({shouldRedirect:true,url:card.url})}            >
              <div>
                <span className="mb-0">{card.name}</span>
                <p className="mb-0">{card.description}</p>
              </div>
              <i className="material-icons">{card.iconName}</i>
            </Card>
          </div>
        ))}
      </div>
    </>
    
  );
}
