import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Redirect, useParams, useLocation } from "react-router-dom";

import { Accordion, Button } from "react-bootstrap";
import { XtBreadcrumbs } from "../../../../../Xt/Layout/XtBreadcrumbs";
import { enum_request_type } from "../Common/NwPrConstants";
import { XtForm } from "nwcommon";

import NwGeneralInfo from "./Generalnfo";
import NwVendorInfo from "./VendorInfo";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import AuditLog from "../ApprovalView/AuditLog";

import {
  APIFactory,
  PurchaseOrderApi,
  PurchaseRequisition,
  PurchaseRequisitionApi,
  Vendor,
  VendorApi,
} from "../../../../../Xt/ApiClients";
import NwPurchaseItem from "./PurchaseItem";
import NwShippingPayment from "./ShippingPayment";
import XtAccordianItem from "../Common/XtAccordianItem";
import { Loading } from "../../../../../Xt/Layout/Loading";
import NwAdditionalInfo from "./AdditionalInfo";
import NwPurchaseItemDialog from "./PurchaseItemDailog";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { PrApiService } from "../Common/PrApiService";
import { EnPrAction, EnPrTypeDetail, PrReducer } from "../Common/PrReducer";
import { ErrorUtil } from "../../../../../Xt/Utils/ErrorUtil";
import LoadingDailog, {
  EnConfirmationMessageType,
  LoadingDailogProps,
} from "../../../Dialogs/Common/LoadingDailog";
import { XtFormUtil } from "../../../../../Xt/Utils/XtFormUtil";
import { NumberUtil } from "../../../../../Xt/Utils/NumberUtil";
import { PRDraftDeleteButton } from "./PRDraftDeleteButton";

export const enum RequestPageMode {
  PR_CREATE_NEW = "PR_CREATE_NEW",
  PR_EDIT = "PR_EDIT",
  PO_CHANGE_REQUEST = "PO_CHANGE_REQUEST",
}

export default function NwCreateNewPr() {
  const location = useLocation();
  let { poNumber } = useParams();
  let { prGuid } = useParams();

  const redirectTo = { shouldRedirect: false, url: "" };
  let factory: APIFactory;
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState<LoadingDailogProps>({
    show: false,
  });
  const context: IXtContext = useContext(XtContext);
  const [redirect, setRedirect] = useState(redirectTo);
  let [state, dispatch] = useReducer(PrReducer.reducer, null);
  const [permission, setPermission] = useState(null);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [accountGrpCode, setAccountGrpCode] = useState();
  const saveRef = useRef(null);

  const pageMode = location.pathname.includes("pr/create")
    ? RequestPageMode.PR_CREATE_NEW
    : location.pathname.includes("pr/edit")
      ? RequestPageMode.PR_EDIT
      : RequestPageMode.PO_CHANGE_REQUEST;

  const loadPrEdit = () => {
    if (prGuid) {
      let prApi: PurchaseRequisitionApi = factory.create(
        PurchaseRequisitionApi,
      );
      setLoading(true);
      PrApiService.getPermissionByPrGuid(context, prApi, prGuid)
        .then((data) => {
          let permission: any = data;
          //setPermission(data);

          if (permission.isEditable == false) {
            setRedirect({ shouldRedirect: true, url: "/unauthorize" });
          } else {
            setPermission(data);
            setLoading(true);
            PrApiService.getPrByGuid(context, prApi, prGuid)
              .then((prData) => {
                let pr: any = prData;

                setBreadCrumbs([
                  {
                    href: "/pr",
                    text: "Purchase Requisitions ",
                    isActive: false,
                  },
                  {
                    href: "/pr/create",
                    text: "" + pr?.prNumber,
                    isActive: true,
                  },
                ]);

                dispatch({ type: EnPrAction.EDIT_PR, payload: pr });
              })
              .finally(() => setLoading(false));
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const loadPrCreateNew = () => {
    setBreadCrumbs([
      {
        href: "/pr",
        text: "Purchase Requisitions",
        isActive: false,
      },

      {
        href: "/pr/create",
        text: "Create New",
        isActive: true,
      },
    ]);
    dispatch({ type: EnPrAction.CREATE_NEW_PR });
  };

  const getDate = (date) => {
    return date && new Date(date) > new Date("1900/01/01")
      ? new Date(date)
      : null;
  };

  const loadPOChangeRequest = async () => {
    if (poNumber) {
      let poApi: PurchaseOrderApi = factory.create(PurchaseOrderApi);
      try {
        setLoading(true);

        poApi
          .purchaseOrderPoIDGet(poNumber, context?.loggedOnUser?.username)
          .then((poDetails) => {
            let po: any = poDetails;
            const polineItems = po.poLineItemList.map((p) => {
              return {
                purchaseItemGuid: undefined,
                type: p.accountType,
                chargeCode: p.chargeCode,
                orgId:
                  p?.lineAccountList?.length > 0
                    ? p?.lineAccountList[0]?.orG_ID
                    : "", //confrim
                accountNumber: p.accountID,
                description: p.lineDescription,
                quantity: p.quantity === 0 ? p.orderedAmount : p.quantity,
                lastQuantity: p.quantity === 0 ? p.orderedAmount : p.quantity,
                unit: p.unit,
                unitName: p.unitName,
                unitPrice: p.unitPrice === 0 ? 1 : p.unitPrice,
                lastUnitPrice: p.unitPrice === 0 ? 1 : p.unitPrice,
                total:
                  (p.quantity === 0 ? p.orderedAmount : p.quantity) *
                  (p.unitPrice === 0 ? 1 : p.unitPrice),
                lastTotal:
                  (p.quantity === 0 ? p.orderedAmount : p.quantity) *
                  (p.unitPrice === 0 ? 1 : p.unitPrice),
                startDate: getDate(p.startDate),
                endDate: getDate(p.endDate),
                lineItemNumber: p.lineNumber,
                tranType: "INSERT",
                accountGroupCode: p.accountGroupCode,
                typeName: p.typeName,
                chargeCodeName: p.chargeCodeName,
                costToDate: p.committedAmount,
                accountName: p.accountName,
                remainingAmount: p.remainingAmount,
                emplID: undefined,
                poStatus: undefined,
                purchaseRequisitionGuid: undefined,
              };
            });

            const subTotalAmount = polineItems.reduce(
              (sum, current) => NumberUtil.decimalNumSum(sum, current.total),
              0,
            );
            //TODO: Figure out the charge type properly here.

            var chargeType = "";
            var filterDirectChargeTypes = polineItems.filter(
              (x) => x.accountGroupCode?.toLowerCase() == "dir",
            );
            if (filterDirectChargeTypes && filterDirectChargeTypes.length > 0) {
              chargeType = "Direct";
            } else {
              chargeType = "Indirect";
            }

            dispatch({
              type: EnPrAction.PO_CHANGE_REQUEST,
              payload: {
                poNumber: poNumber,
                prTypeDetail: EnPrTypeDetail.PO_CHANGE_REQUEST_BY_PO,
                purchaseItem: polineItems,
                companyCode: po.companyCode,
                vendorCode: po.vendorID,
                lastSubTotalAmount: subTotalAmount,
                lastTotalAmount: subTotalAmount,
                requisitionType: po.vendorID.endsWith("4")
                  ? "Material"
                  : "Service",
                chargeType: chargeType,
                projectNumber: po.projectNumber,
                qualityLevel:
                  chargeType === "Indirect" ? "NonQuality" : undefined,
              },
            });
          });
      } catch (ex) {
        let msg = await ex.json();
        const errors = ErrorUtil.getErorMessageFromApi(msg);
        setDialogError(errors);
      } finally {
        setLoading(false);

        setBreadCrumbs([
          {
            href: "/po",
            text: "Purchase Orders ",
            isActive: false,
          },
          {
            href: "/po/change/",
            text: "" + poNumber,
            isActive: true,
          },
          {
            href: "",
            text: "PO Change Request",
            isActive: true,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (context?.loggedOnUser?.username) {
      switch (pageMode) {
        case RequestPageMode.PR_EDIT:
          loadPrEdit();
          break;

        case RequestPageMode.PR_CREATE_NEW:
          loadPrCreateNew();
          break;

        case RequestPageMode.PO_CHANGE_REQUEST:
          loadPOChangeRequest();
          break;
      }
    }
  }, [context?.loggedOnUser?.username, pageMode]);

  const checkSSJFiles = (e) => {
    let ssjValidationData = {
      hasSSJ: false,
      fileCount: 0,
    };

    if (e.postData.ssjFormFileList) {
      ssjValidationData.hasSSJ = true;
      ssjValidationData.fileCount = e.postData.ssjFormFileList.length;
    }

    if (state?.attachmentList) {
      const existingSSJList = state?.attachmentList?.filter(
        (f) => f?.attachmentType == "SSJForm",
      );
      if (existingSSJList && existingSSJList.length > 0) {
        ssjValidationData.hasSSJ = true;
        ssjValidationData.fileCount =
          ssjValidationData.fileCount + existingSSJList.length;
      }
    }

    return ssjValidationData;
  };

  const validateDate = (dateToValidate) => {
    if (!dateToValidate) return false;
    if (dateToValidate instanceof String && dateToValidate?.trim() == "")
      return false;
    var dateObj = new Date(dateToValidate);

    const isValidInstance = dateObj instanceof Date;

    if (!isValidInstance) {
      return false;
    }

    const isInvalid = isNaN(dateObj.getTime());
    if (isInvalid) {
      return false;
    }
    if (dateObj < new Date("01/01/2000")) {
      return false;
    }
    return true;
  };

  const showMaterialShippingInfo = state?.requisitionType === "Material";

  const getValidationErrorsOnSubmit = (e) => {
    const submitErrors = [];
    const actionType =
      e?.nativeEvent?.submitter?.id === "submit" ? "Submit" : "Save";

    if (actionType === "Submit") {
      const dateNeeded = e?.postData?.dateNeeded;
      if (!validateDate(dateNeeded)) {
        submitErrors.push(`DateNeeded should have valid date: ${dateNeeded}`);
      }

      if (state?.purchaseItem.length === 0) {
        submitErrors.push("Empty purchase line item is not allowed");
      } else {
        state?.purchaseItem?.map((p) => {
          const missingFields = [];
          if (!p?.unit) missingFields.push("Unit");
          if (!p?.chargeCode) missingFields.push("Charge Code");

          if (!p?.type) missingFields.push("Type");

          if (!p?.description) missingFields.push("Description");

          if (!p?.quantity) missingFields.push("Quantity");

          if (!p?.unitPrice) missingFields.push("Unit Price");

          if (missingFields.length > 0) {
            const lineError = `#${p.lineItemNumber}: ${missingFields.join(
              ", ",
            )} ${missingFields.length > 1 ? " are " : " is "} required.`;
            submitErrors.push(lineError);
          }
        });
      }

      if (e.postData?.isCompeted === "No") {
        const ssjValidationData = checkSSJFiles(e);
        if (ssjValidationData.hasSSJ && ssjValidationData.fileCount === 0) {
          submitErrors.push(
            "Empty competed item is not allowed.  At least one SSJ form is required.",
          );
        }

        if (ssjValidationData.hasSSJ && ssjValidationData.fileCount > 1) {
          submitErrors.push("Only one SSJ form is allowed.");
        }
      }

      if (e.postData?.isCompeted === "Yes") {
        if (e.postData?.quotesFileList) {
          let quotesFileCounts = e.postData.quotesFileList.length;
          if (quotesFileCounts === 0) {
            submitErrors.push(
              "At least one quote is required for a competed item.",
            );
          }

          const quotes = state?.attachmentList?.filter(
            (f) => f?.attachmentType === "Quotes",
          );

          if (quotes) quotesFileCounts = quotesFileCounts + quotes.length;

          if (quotesFileCounts === 0) {
            submitErrors.push(
              "Empty competed item is not allowed. At least one quotes file is required.",
            );
          }
        }
      }
    }

    return submitErrors;
  };

  const getVendorCodeAddressAndContactGuid = async (e, apiFactory) => {
    let postData: any = e.postData;
    let vendorCode: string = state?.vendorCode;
    let vendAddressGuid = undefined;
    let addressContactGuid = undefined;

    let contactList = [];

    contactList.push({
      firstName: postData.vendContactFirstname,
      lastName: postData.vendContactLastname,
      titleName: "",
      emailID: postData.vendContactEmail,
      phoneID: postData.vendContactPhone,
      sequenceNo: postData.vendContactSeqNo,
      tranType: "",
    });

    var address = {
      //LET SErver figure out whether it is insert or update
      tranType: "",
      oldAddressCode: postData.vendAddressCode,
      addressCode: postData.vendAddressCode,
      cityName: postData.vendCity,
      countryCode: postData.vendCountryCode,
      emailID: postData.vendEmail,
      addressLine1: postData.vendAddress1,
      addressLine2: postData.vendAddress2,
      addressLine3: postData.vendAddress3,
      mailStateCode: postData.vendState,
      phoneID: postData.vendContactPhone,
      postalCode: postData.vendZipCode,
      contactList: contactList,
    };

    //Initially Set types here
    let vendorPayloadForApi: Vendor = {
      taxID: "",
      autoVoucherFlag: "",
      cageCD: "",
      companyCode: postData.companyCode,
      voucherPayVendorFlag: "",
      termsCode: "",
      vendor1099Name: "",
      vendorID: vendorCode,
      tranType: "",
      vendorLongName: postData.vendorLongName,
      vendorName: postData.vendName,
      vendorAddressList: [address],
      vendorGuid: state?.vendor ? state?.vendor?.vendorGuid : null,
    };

    //WE NEED TO DETERMINE IF IT IS A UPDATE OR ADDITION OF NEW VENDOR
    //TO FIND THAT OUT WE CHECK IF VENDOR IS NULL IN STATE WHICH HOLDS THE
    //SELECTED VENDOR. WE ALSO NEED TO FIND OUT APPROPRIATE vendorAddressGuid
    // AND vendorContactGuid to be passed to PR.

    let factory = apiFactory;

    var vendorSubmitResult = await AddUpdateVendor(
      e.postData,
      apiFactory,
      vendorCode,
      vendorPayloadForApi,
    );

    if (vendorSubmitResult) {
      return {
        hasError: false,
        vendorCode: vendorSubmitResult.vendorCode,
        vendAddressGuid: vendorSubmitResult.vendorAddressGuid,
        addressContactGuid: vendorSubmitResult.vendorContactGuid,
      };
    } else
      return {
        hasError: true,
        vendorCode,
        vendAddressGuid,
        addressContactGuid,
      };
  };

  const onSubmit = async (e) => {
    let api: PurchaseRequisitionApi = factory.create(PurchaseRequisitionApi);
    let apiFactory = factory;
    const prTypeName =
      state?.prType === enum_request_type.PO_CHANGE_REQUEST
        ? "Change PO"
        : "PR";
    const waitMessage =
      pageMode == RequestPageMode.PR_EDIT
        ? prTypeName + " request is being updated....."
        : prTypeName + " request is being created.....";
    const submitErrors = getValidationErrorsOnSubmit(e);
    setSubmitLoading({
      show: true,
      message: [waitMessage],
      messageType: EnConfirmationMessageType.Wait,
    });

    if (submitErrors.length > 0) {
      setDialogError(submitErrors);
      return;
    }

    const vendorDetails = await getVendorCodeAddressAndContactGuid(
      e,
      apiFactory,
    );
    if (vendorDetails.hasError) return;

    const actionType =
      e?.nativeEvent?.submitter?.id == "submit" ? "Submit" : "Save";

    const newPurchase: PurchaseRequisition = e.postData;
    newPurchase.status = state?.status;
    newPurchase.employeeName = context?.loggedOnUser?.displayname;
    newPurchase.purchaseItem = state?.purchaseItem;
    newPurchase.dateNeeded = new Date(e.postData.dateNeeded);
    newPurchase.isCompeted = state?.isCompeted === "Yes";
    newPurchase.chargeType = state?.chargeType;
    newPurchase.requisitionType = state?.requisitionType;
    newPurchase.paymentType = state?.paymentType;
    newPurchase.totalAmount = state?.totalAmount;
    newPurchase.companyCode = state?.companyCode;
    newPurchase.projectNumber = state?.projectNumber;
    newPurchase.vendorCode = vendorDetails.vendorCode;
    newPurchase.parentGuid = state?.parentGuid;
    newPurchase.qualityLevel = state?.qualityLevel;
    newPurchase.addressContactGuid = vendorDetails.addressContactGuid;
    newPurchase.vendorAddressGuid = vendorDetails.vendAddressGuid;
    newPurchase.prType = state?.prType;
    if (e.postData.shippingCost)
      newPurchase.shippingCost = Number(e.postData.shippingCost);
    else newPurchase.shippingCost = Number();

    if (e.postData.taxAmount)
      newPurchase.taxAmount = Number(e.postData.taxAmount);
    else newPurchase.taxAmount = Number();

    if (e.postData.otherAmount)
      newPurchase.otherAmount = Number(e.postData.otherAmount);
    else newPurchase.otherAmount = Number();
    if (state?.isLeaseChecked == "Yes")
      newPurchase.leaseType = state?.leaseType;
    newPurchase.actionType = actionType;
    newPurchase.poNumber = state?.poNumber;

    let result;

    try {
      if (pageMode == RequestPageMode.PR_EDIT) {
        newPurchase.purchaseRequisitionGuid = state?.purchaseRequisitionGuid;
        newPurchase.prNumber = state?.prNumber;
        result = await api.purchaseRequisitionPut(
          context?.loggedOnUser?.username,
          newPurchase,
          state?.purchaseRequisitionGuid,
        );
      } else {
        result = await api.purchaseRequisitionPost(
          context?.loggedOnUser?.username,
          newPurchase,
        );
      }

      if (result.ok) {
        if (pageMode == RequestPageMode.PR_EDIT) {
          setDialogSuccessForExistingPR(state?.prNumber, actionType, "");
        } else {
          const newPr = await result.json();
          setDialogSuccess(newPr?.prNumber, actionType, newPr?.alertMsg);
        }
      } else {
        let msg = await result.json();
        if (msg.error != null && msg.error.details) {
          if (msg.error.details.length > 0) {
            var approvalRoleErrors = msg.error.details.filter(
              (x) => x.target == "approvalRoles",
            );
            if (approvalRoleErrors && approvalRoleErrors.length > 0) {
              var approvalErrors = approvalRoleErrors.map((x) => x.message);
              setDialogError(approvalErrors);
            } else {
              const errors = ErrorUtil.getErorMessageFromApi(msg);
              setDialogError(errors);
            }
          } else {
            const errors = ErrorUtil.getErorMessageFromApi(msg);
            setDialogError(errors);
          }
        }

        const errors = ErrorUtil.getErorMessageFromApi(msg);
        setDialogError(errors);
      }
    } catch (ex) {
      let msg = await ex.json();
      if (msg.error != null && msg.error.details) {
        if (msg.error.details.length > 0) {
          var approvalRoleErrors = msg.error.details.filter(
            (x) => x.target == "approvalRoles",
          );
          if (approvalRoleErrors && approvalRoleErrors.length > 0) {
            var approvalErrors = approvalRoleErrors.map((x) => x.message);
            setDialogWarningForApprovals(approvalErrors);
          } else {
            const errors = ErrorUtil.getErorMessageFromApi(msg);
            setDialogError(errors);
          }
        } else {
          const errors = ErrorUtil.getErorMessageFromApi(msg);
          setDialogError(errors);
        }
      } else {
        const errors = ErrorUtil.getErorMessageFromApi(msg);
        setDialogError(errors);
      }
    }
  };

  const setDialogSuccess = (
    prNumber: string,
    actionType: string,
    alertMessage: string,
  ) => {
    const prTypeName =
      state?.prType === enum_request_type.PO_CHANGE_REQUEST
        ? "Change PO"
        : "PR";
    const message =
      actionType == "Submit"
        ? `${prNumber} has been successfully created.`
        : `${prNumber} has been successfully saved as draft.`;
    const title =
      actionType == "Submit"
        ? prTypeName + " Creation Successful"
        : prTypeName + " Saved as Draft";
    setSubmitLoading({
      show: true,
      message: [message],
      title: title,
      messageType: EnConfirmationMessageType.Success,
      alertMessageOnSuccess: alertMessage,
      onClose: (e) => {
        setRedirect({ shouldRedirect: true, url: "/pr" });
        setSubmitLoading({ show: false });
      },
      onCreateNew: () => {
        setRedirect({ shouldRedirect: true, url: "/pr/create" });
        setSubmitLoading({ show: false });
      },
    });
  };

  const setDialogSuccessForExistingPR = (
    prNumber: string,
    actionType: string,
    alertMessage: string,
  ) => {
    const prTypeName =
      state?.prType === enum_request_type.PO_CHANGE_REQUEST
        ? "Change PO"
        : "PR";
    const message =
      actionType === "Submit"
        ? `${prNumber} has been successfully submitted.`
        : actionType === "Delete"
          ? `Draft for PR ${prNumber} has been successfully deleted.`
          : `${prNumber} has been successfully saved as draft.`;

    const title =
      actionType === "Submit"
        ? prTypeName + " Submittal Successful"
        : actionType === "Delete"
          ? prTypeName + " Deletion Successful"
          : prTypeName + " Saved as Draft";

    setSubmitLoading({
      show: true,
      message: [message],
      title: title,
      messageType: EnConfirmationMessageType.Success,
      alertMessageOnSuccess: alertMessage,
      onClose: (e) => {
        setRedirect({ shouldRedirect: true, url: "/pr" });
        setSubmitLoading({ show: false });
      },
    });
  };

  const setDialogError = (errorMessage: string[]) => {
    setSubmitLoading({
      show: true,
      title: "Error",
      messageType: EnConfirmationMessageType.Error,
      message: errorMessage,
      onClose: (e) => {
        setSubmitLoading({ show: false });
      },
    });
  };

  const setDialogWarningForApprovals = (errorMessage: string[]) => {
    setSubmitLoading({
      show: true,
      title: "Warning",
      messageType: EnConfirmationMessageType.Warning,
      message: errorMessage,
      onClose: (e) => {
        setRedirect({ shouldRedirect: true, url: "/pr?view=drafts" });
        setSubmitLoading({ show: false });
      },
    });
  };

  const activeSections = ["0", "1", "2", "3", "4"];
  const bc = {
    breadCrumbs: breadCrumbs,
  };

  const canSaveOrSubmit = () => {
    if (!permission) return true;
    if (permission.isEditable) return true;
    return false;
  };

  const AddUpdateVendor = async (
    postData,
    apiFactory,
    vendorCode,
    newVendor,
  ) => {
    let vendorApi: VendorApi = factory.create(VendorApi);
    let result;

    await vendorApi
      .vendorControllerPost(context?.loggedOnUser?.username, newVendor)
      .then(async (vendorDetails: any) => {
        result = vendorDetails;
      })
      .catch(async (e) => {
        if (e.status == 500) {
          let msg = "Something went wrong!.Internal Server Error";
          const errors = [msg];
          setDialogError(errors);
          return;
        } else {
          let msg = await e.json();
          const errors = ErrorUtil.getErorMessageFromApi(msg);
          setDialogError(errors);
        }
      });

    return result;
  };

  const onSave = (e) => {
    let fe = saveRef.current.form.elements;
    const postData = XtFormUtil.getPostData(fe);
    onSubmit({ ...e, postData: postData });
  };

  return (
    <>
      {redirect.shouldRedirect && <Redirect push to={redirect.url} />}
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />

      <div className="row mb-3 align-items-md-center">
        <div className="col">
          <div className="mb-3 mb-md-0">
            <XtBreadcrumbs {...bc} />
          </div>
        </div>

        <div className="col-auto">
          {state?.status === "Draft" &&
            pageMode === RequestPageMode.PR_EDIT && (
              <PRDraftDeleteButton
                setDialogSuccessForExistingPR={setDialogSuccessForExistingPR}
                setDialogError={setDialogError}
                prGuid={state?.purchaseRequisitionGuid}
                prNumber={state?.prNumber}
                loading={loading}
                setLoading={setLoading}
                setLoadingDialog={setSubmitLoading}
              />
            )}

          <span className="px-3 py-2 fw-bold text-white d-inline-block  pr-status-draft">
            {state?.status}
          </span>
        </div>
      </div>

      <div className="pr-create-new">
        <XtForm onSubmit={onSubmit}>
          <Accordion defaultActiveKey={activeSections} alwaysOpen>
            {/*<div className="alert alert-warning" role="alert">*/}
            {/*     Warning: Some of the users are not assigned. Please assign from CMS portal.*/}
            {/* </div> */}

            <XtAccordianItem header="GENERAL" eventKey="0">
              <NwGeneralInfo
                state={state}
                dispatch={dispatch}
                onChange={(value) => setAccountGrpCode(value)}
              />
            </XtAccordianItem>
            <Accordion defaultActiveKey={activeSections} alwaysOpen={false}>
              <XtAccordianItem
                header="AUDIT LOG"
                eventKey="6"
                hide
                IsCollapsible={true}
              >
                <AuditLog prGuid={state?.purchaseRequisitionGuid} />
              </XtAccordianItem>
            </Accordion>

            <XtAccordianItem header="VENDOR" eventKey="2">
              <NwVendorInfo state={state} dispatch={dispatch} />
            </XtAccordianItem>

            <XtAccordianItem header="LINE ITEM" eventKey="3">
              <NwPurchaseItem state={state} dispatch={dispatch} />
            </XtAccordianItem>
            {showMaterialShippingInfo && (
              <XtAccordianItem header="SHIPPING/PAYMENT" eventKey="4">
                <NwShippingPayment state={state} dispatch={dispatch} />
              </XtAccordianItem>
            )}

            <XtAccordianItem header="ADDITIONAL" eventKey="5">
              <NwAdditionalInfo
                state={state}
                dispatch={dispatch}
                pageMode={pageMode}
              />
            </XtAccordianItem>
          </Accordion>
          <DialogActionsBar layout="end">
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                setRedirect({ shouldRedirect: true, url: "/pr" });
              }}
              disabled={loading}
            >
              Cancel
            </Button>
            {canSaveOrSubmit() && (
              <>
                <Button
                  type="button"
                  id="save"
                  variant="secondary"
                  disabled={loading}
                  ref={saveRef}
                  onClick={onSave}
                >
                  Save
                </Button>

                <Button
                  type="submit"
                  variant="primary"
                  id="submit"
                  disabled={loading}
                >
                  Submit
                </Button>
              </>
            )}
          </DialogActionsBar>
        </XtForm>

        {state?.showPurchaseItemDailog && (
          <NwPurchaseItemDialog state={state} dispatch={dispatch} />
        )}
        {loading && <Loading />}
        {submitLoading && submitLoading.show && (
          <LoadingDailog {...submitLoading} />
        )}
      </div>
    </>
  );
}
