import React, { useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IXtContext, XtContext } from "../../../../../../Xt/Contexts/XtContext";



export default function NwWorkflowReviewerCell(props ) {
  const context: IXtContext = useContext(XtContext);
  //console.log(props);
  
  
  const prWorkflowReviewerClassName = `pr-workflow-reviewer-cell`
  const onAddReviewerClick = (e) => {
    props.onAddReviewerClick(e);
  }

  
  const shouldAddReviewerIconVisible = () => {
    let currentStage =   Number(props?.dataItem.stage);
    

   return (
     (context?.loggedOnUser?.displayname === props?.dataItem?.displayName ||
       context?.loggedOnUser?.displayname === props?.dataItem?.designee) &&
     props?.isApprover === true &&
     props?.dataItem?.isActive === true &&
     props?.dataItem?.isCompleted === false &&
     props?.dataItem?.isRejected === false &&
     (currentStage == 2 || currentStage == 3) === true &&
     !props?.status?.toLocaleLowerCase().includes("po change request needed")
   );

     
  };
  

  return (
    <td >
       <OverlayTrigger
      key="top"
      placement="top"
      overlay={
        <Tooltip id={`tooltip-top`}>
          Add reviewer 
        </Tooltip>
      }
    >
      <span className={prWorkflowReviewerClassName} role={"gridcell"}>
        { shouldAddReviewerIconVisible() &&(
        <i onClick={(e)=> onAddReviewerClick({...e, dataItem: props?.dataItem }) }
            className="material-icons text-success"
            style={{ fontSize: "16px" , cursor:"pointer" }}
          >
           add_circle_outline
          </i>)
      }
      </span>
    </OverlayTrigger>
      
    </td>
  );
}
