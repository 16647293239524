import React from "react";
import { Navbar } from "react-bootstrap";

export function XtNavBarToggle(props) {
    return (<React.Fragment>
                <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        {props.children}
                    </Navbar.Collapse>
               
         </React.Fragment>);


}