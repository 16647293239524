import { BrowserRouter, Switch, Route } from "react-router-dom";
import React from "react";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { XtApplicationShell } from "./Xt/Layout/XtApplicationShell";
import {
  AppConfiguration,
  OktaConfig,
} from "./components/Configurations/config";
import { ILeftNavItem } from "./Xt/Layout/XtNavSide";
import { XtContext, IXtContext } from "./Xt/Contexts/XtContext";
import * as Pages from "./components/Pages";
import "../src/scss/vendor/kendo-theme-bootstrap.scss";
import "../src/scss/pr-style.scss";
import Dashboard from "./components/Pages/Dashboard";

declare var window: any;

const oktaOIDCAuth = new OktaAuth(OktaConfig.oidc);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [showLoginBox, setShowLoginBox] = React.useState(false);
  const context: IXtContext = React.useContext(XtContext);
  context.apiBasePath = AppConfiguration.apiConfiguration.basePath;
  context.oktaBasePath = OktaConfig.app.basename;

  oktaOIDCAuth.authStateManager.subscribe((authState) => {
    if (authState && authState.isAuthenticated) {
      setShowLoginBox(false);
    }
  });
  //These are left menu items to be show on left nav bar
  //Pass empty if you do not need one
  let leftNav: ILeftNavItem[] = [
    {
      to: "/",
      text: "My Action Items",
      title: "My Action Items",
      icon: "home",
      children: [],
    },
    {
      to: "",
      text: "Purchase Requisitions",
      title: "Purchase Requisitions",
      icon: "receipt_long",
      children: [
        {
          text: "List",
          title: "List",
          to: "/pr",
          icon: "list",
          children: [],
        },

        {
          text: "Create New",
          title: "Create New",
          icon: "add_circle_outline",
          to: "/pr/create/",
          children: [],
        },
      ],
    },
    {
      to: "",
      text: "Purchase Orders",
      title: "Purchase Orders",
      icon: "receipt",
      children: [
        {
          text: "List",
          title: "List",
          to: "/po",
          icon: "list",
          children: [],
        },

        /*{
          text: "PO Change Request",
          title: "PO Change Request",
          icon: "add_circle_outline",
          to: "/po/change-dailog",
          children: [],
        }*/
      ],
    },
    {
      to: "",
      text: "Invoice",
      title: "Invoice",
      icon: "payment",
      children: [
        {
          text: "List",
          title: "List",
          to: "/invoice",
          icon: "list",
          children: [],
        },

        {
          text: "Add Invoice",
          title: "Add Invoice",
          icon: "add_circle_outline",
          to: "/invoice/add",
          children: [],
        },
      ],
    },
    {
      to: "",
      text: "Personnel Requisitions",
      title: "Personnel Requisitions",
      icon: "person_add",
      children: [
        {
          text: "List",
          title: "List",
          to: "/prr",
          icon: "list",
          children: [],
        },

        {
          text: "Create New",
          title: "Create New",
          icon: "add_circle_outline",
          to: "/prr/create/",
          children: [],
        },
      ],
    },
  ];

  //"AzureAd": {
  //    "Instance": "https://login.microsoftonline.com/",
  //        "Domain": "ecc.net",
  //            "TenantId": "f337a21b-d9ac-4285-9407-6fdd7563d421",
  //                "ClientId": "012bc577-1192-4d27-a4cf-d77d0451a78c",

  //auth: {
  //    clientId: "97b1a8cb-90b5-4781-957a-20839070a6e5",
  //        authority: 'https://login.microsoftonline.com/d8281d55-6e8c-4d95-81bd-f9439067c87f',
  //            redirectUri: 'https://localhost:3000'
  //},

  //Configure API Endpoints and Open ID Connect endpoint for Azure AD
  //Get the development clientId, authority from Hiroto
  //Set the redirectUri to where the app will be running.
  const defaultMsalConfiguration = {
    auth: {
      clientId: "97b1a8cb-90b5-4781-957a-20839070a6e5",
      authority:
        "https://login.microsoftonline.com/d8281d55-6e8c-4d95-81bd-f9439067c87f",
      redirectUri: "https://localhost:3000",
    },

    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };

  //Set the base path for Xt Common APIs here
  //Change it if this is running on diferent port on your machine.

  const customAuthHandler = async (e) => {
    window.location = "/silent-login";
  };

  var restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    window.location = originalUri;
  };

  if (!window.frameElement) {
    window.restoreOriginalUri = restoreOriginalUri;
  } else {
    restoreOriginalUri = window.top.restoreOriginalUri;
  }

  const triggerLogin = () => {
    setShowLoginBox(true);
  };

  AppConfiguration.msalConfiguration = defaultMsalConfiguration;

  React.useEffect(() => {
    if (!window.frameElement) {
      triggerLogin();
    }
  }, [context.isAuthenticated]);

  //Define routes and then create an Outlet
  //Creating Outlet allows you to replace the component within the shell.
  //Outlet is a MUST here.
  return (
    <>
      <Security
        oktaAuth={oktaOIDCAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter basename="">
            <XtApplicationShell
              dataSideNav={leftNav}
              config={{
                apiBasePath: AppConfiguration.apiConfiguration.basePath,
                oktaBasePath: OktaConfig.app.basename,
                commonAPIPath: AppConfiguration.apiConfiguration.commonAPIPath,
              }}
            >
              <Switch>
                <SecureRoute
                  path="/pr"
                  exact
                  component={Pages.PurchaseRequisitionList}
                />
                <SecureRoute path="/home" exact component={Pages.Home} />
                <SecureRoute path="/file" exact component={Pages.File} />
                <SecureRoute path="/upload" exact component={Pages.Upload} />
                <SecureRoute path="/logout" exact component={Pages.Logout} />
                <SecureRoute
                  path="/invoice/add"
                  exact
                  component={Pages.AddInvoice}
                />
                <SecureRoute
                  path="/invoice/add/:poNumber"
                  exact
                  component={Pages.AddInvoice}
                />
                <SecureRoute
                  path="/invoice/details/:id"
                  exact
                  component={Pages.InvoiceDetails}
                />
                <SecureRoute
                  path="/invoice/edit/:id"
                  exact
                  component={Pages.EditInvoice}
                />
                <SecureRoute
                  path="/invoice/landingpage/:id"
                  exact
                  component={Pages.InvoiceCPIntegration}
                />
                <SecureRoute path="/invoice" exact component={Pages.Invoice} />
                <SecureRoute path="/po" exact component={Pages.POList} />
                <SecureRoute
                  path="/cp/landingpage/:id"
                  exact
                  component={Pages.CpIntegraitonLandingPage}
                />

                <SecureRoute
                  path="/company/add"
                  exact
                  component={Pages.AddCompany}
                />
                <SecureRoute path="/company" exact component={Pages.Home} />
                <SecureRoute
                  path="/admin/settings"
                  exact
                  component={Pages.AdminSettings}
                />
                <SecureRoute
                  path="/pr"
                  exact
                  component={Pages.PurchaseRequisitionList}
                />
                <SecureRoute
                  path="/pr/create/"
                  exact
                  component={Pages.CreateNewPr}
                />

                <SecureRoute
                  path="/pr/edit/:prGuid"
                  exact
                  component={Pages.CreateNewPr}
                />

                <SecureRoute
                  path="/pr/view/:id"
                  exact
                  component={Pages.ApprovalView}
                />
                <SecureRoute
                  path="/prr"
                  exact
                  component={Pages.PersonnelRequisitionList}
                />
                <SecureRoute
                  path="/prr/create/"
                  exact
                  component={Pages.CreateNewPrr}
                />
                <SecureRoute
                  path="/prr/edit/:prrGuid"
                  exact
                  component={Pages.CreateNewPrr}
                />

                <SecureRoute
                  path="/prr/view/:id"
                  exact
                  component={Pages.PrrApprovalView}
                />
                <SecureRoute
                  path="/po/details/:poNumber"
                  exact
                  component={Pages.PoDetails}
                />

                <SecureRoute
                  path="/po/change/:poNumber"
                  exact
                  component={Pages.PoEdit}
                />
                <SecureRoute
                  path="/po/change-dailog"
                  exact
                  component={Pages.PoChangeDailog}
                />

                <SecureRoute
                  path="/user"
                  exact
                  component={Pages.UserPermission}
                />
                <SecureRoute
                  path="/group"
                  exact
                  component={Pages.UserPermission}
                />
                <SecureRoute
                  path="/unauthorize"
                  exact
                  component={Pages.UnAuthorize}
                />

                <SecureRoute path="/dashboard" exact component={Dashboard} />
                <SecureRoute path="/" exact component={Pages.ActionItems} />

                <Route
                  path="/silent-login"
                  exact
                  component={Pages.SilentLogin}
                />
                <Route path="/login/callback" component={LoginCallback} />
              </Switch>
            </XtApplicationShell>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Security>

      {/*<Pages.AuthDialog
        show={
                  window.location.pathname === "" &&
          window.location.pathname !== "/silent-login" &&
          window.frameElement === null
        }
      />*/}
    </>
  );
}

export default App;
