import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import FormField, { FormFieldWithParam, RequiredFormField } from "../PurchaseRequisition/Common/FormField";
import FormLabelMutedText from "../PurchaseRequisition/Common/FormLabelMutedText";
import { XtField } from "nwcommon";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
    APIFactoryCommon,
    ResourceAttributeApi

} from "../../../../Xt/ApiClients/Common";
import { APIFactory, VendorApi } from "../../../../Xt/ApiClients";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";




interface CpIntegrationDefaultsProps {
    vendorId: string,
    companyCode: string,
    selectedPaymentTerms: string,
    onPymentTermChange: any,
    selectedApAccount: string,
    onApAccountChange: any,
}

export default function CpIntegrationDefaults(
    props: CpIntegrationDefaultsProps
) {
    let commonApifactory: APIFactoryCommon;
    let factory: APIFactory;


    const [allowEditToPayVoucher, setAllowEditToPayVoucher] = useState(false);
    const [allowAutoVoucher, setAllowAutoVoucher] = useState(false);
    const [paymentTerms, setPaymentTerms] = useState([]);
    const [apAccounts, setApAccounts] = useState([]);
    const [cashAccounts, setCashAccounts] = useState([]);
    const [companyCodeState, setCompanyCodeState] = useState(props.companyCode);
    const [vendor, setVendor] = useState(null);
    const context: IXtContext = useContext(XtContext);




    const loadPaymentTerms = (companyCode: string) => {

        let commonResourceApi: ResourceAttributeApi =
            commonApifactory.create(ResourceAttributeApi);
        commonResourceApi
            .resourceAttributePaymentTermsGet(companyCode)
            .then(async (paymentTermsData) => {
                var paymentTermsDataFromApi = await paymentTermsData.json();
                const paymentTerms = paymentTermsDataFromApi.value.map((p) => {
                    return {
                        text: p.termS_DC,
                        value: p.termS_DC,
                    };
                });
                setPaymentTerms(paymentTerms);
            });
    };

    const loadApAccounts = (companyCode: string) => {
        let commonResourceApi: ResourceAttributeApi =
            commonApifactory.create(ResourceAttributeApi);
        commonResourceApi
            .resourceAttributeAPAccountGet(companyCode)
            .then(async (apAccountsData) => {
                var apAccountsDataFromApi = await apAccountsData.json();
                const apAccountsList = apAccountsDataFromApi.value.map((a) => {
                    return {
                        value: a.aP_ACCTS_DESC,
                        text: a.accT_NAME,

                    };
                });

                setApAccounts(apAccountsList);
            });
    };

    const loadCashAccounts = (companyCode: string) => {
        let commonResourceApi: ResourceAttributeApi =
            commonApifactory.create(ResourceAttributeApi);
        commonResourceApi
            .resourceAttributeCashAccountGet(companyCode)
            .then(async (cashAccountsData) => {
                var cashAccountsDataFromApi = await cashAccountsData.json();
                const cashAccountsList = cashAccountsDataFromApi.value.map((a) => {
                    return {
                        value: a.casH_ACCTS_DESC,
                        text: a.casH_ACCTS_DESC,

                    };
                });
                setCashAccounts(cashAccountsList);
            });
    };

    const loadVendorDetails = (vendorCode, loggedOnUserName) => {
        if (vendorCode && loggedOnUserName) {
            let api: VendorApi = factory.create(VendorApi);
            api
                .vendorDetailGet(                    
                    loggedOnUserName,
                    vendorCode,
                     props?.companyCode
                )
                .then(async (vendorData) => {
                    const vendDetailsFromApi: any = vendorData;
                    setVendor(vendDetailsFromApi);
                    if (vendDetailsFromApi?.allowEditToPayVoucher)
                        setAllowEditToPayVoucher(vendDetailsFromApi?.allowEditToPayVoucher === "Y");
                    if (vendDetailsFromApi?.voucherPayVendorFlag)
                        setAllowEditToPayVoucher(vendDetailsFromApi?.voucherPayVendorFlag === "Y");
                    if (vendDetailsFromApi?.autoVoucherFlag)
                        setAllowAutoVoucher(vendDetailsFromApi?.autoVoucherFlag === "Y");

                    if (vendDetailsFromApi?.termsCode)
                        props.onPymentTermChange(vendDetailsFromApi?.termsCode);


                });
        }
    };




    useEffect(() => {
        if (props.companyCode) {
            loadApAccounts(props.companyCode);
            loadPaymentTerms(props.companyCode);
            loadCashAccounts(props.companyCode);
        }

    }, [props.companyCode]);

    const vendOrIdStartsWith = props.vendorId ? props.vendorId.charAt(0) : "";
    useEffect(() => {
        if (apAccounts.length > 0) {

            setAllowEditToPayVoucher(canAlllowEditToPayVoucher(vendOrIdStartsWith));
            setAllowAutoVoucher(canAlllowAutoVoucher(vendOrIdStartsWith));
            props.onPymentTermChange(
                getPaymentTermsBasedOnVendorId(vendOrIdStartsWith)
            );
            props.onApAccountChange(getApAccountBasedOnVendorId(vendOrIdStartsWith));
        }
    }, [vendOrIdStartsWith, apAccounts.length]);


    const canAlllowEditToPayVoucher = (vendorFirstCharcter: string) => {
        return (
            vendorFirstCharcter === "1" ||
            vendorFirstCharcter === "3" ||
            vendorFirstCharcter === "4"
        );
    };

    const canAlllowAutoVoucher = (vendorFirstCharcter: string) => {
        return vendorFirstCharcter === "3" || vendorFirstCharcter === "4";
    };

    const getPaymentTermsBasedOnVendorId = (vendorFirstCharcter: string) => {
        switch (vendorFirstCharcter) {
            case "1":
                return "ON RECEIPT";
            case "3":
                return "PWP NLT 75";
            case "4":
                return "NET 30";
            case "5":
                return "NET 10";
            default:
                return null;
        }
    };

    const getApAccountBasedOnVendorId = (vendorFirstCharcter: string) => {
        switch (vendorFirstCharcter) {
            case "1":
                return apAccounts.find(a => a.text.trim() === "A/P - Vendors/Employees")?.value;
            case "3":
                return apAccounts.find(a => a.text.trim() === "A/P - Subcontractors")?.value;
            case "4":
                return apAccounts.find(a => a.text.trim() === "A/P - Vendors/Employees")?.value;
            default:
                return null;
        }
    };

    useEffect(() => {
        loadVendorDetails(props?.vendorId, context?.loggedOnUser?.username);
    }, [props?.vendorId, context?.loggedOnUser?.username]);
    return (
        <>
            <APIFactoryCommon
                ref={(e) => {
                    commonApifactory = e;
                }}
            />

            <APIFactory
                ref={(e) => {
                    factory = e;
                }}
            />


            <Row>
                <RequiredFormField label="Cash Account">
                    <XtField
                        type="dropdown"
                        name="cashAccountDesc"
                        defaultValue={cashAccounts.find(c => c.value === vendor?.cashAccountDescription) || null}
                        items={cashAccounts}
                        textField="text"
                        required={true}
                        errormessage="Cash Account is required"
                    />
                </RequiredFormField>

                <FormFieldWithParam RowAlignment="align-items-center" label="Allow Edit to Pay On Voucher" Form_sm={12} Form_md={6} Label_xs={"10"} Control_xs={"auto"} Label_md={10} Control_md={2} Label_lg={8} Control_lg={4}>
                    
                    <Checkbox
                        key={`${allowEditToPayVoucher}`}
                        name="allowEditToPayOnVoucher"
                        checked={allowEditToPayVoucher}
                        defaultValue={`${allowEditToPayVoucher}`}
                        onChange={(e) => setAllowEditToPayVoucher(x => !x)}
                    />
                </FormFieldWithParam>


                <RequiredFormField label="Payment Terms">
                    <XtField
                        type="dropdown"
                        name="paymentTerms"
                        items={paymentTerms}
                        textField="text"
                        value={paymentTerms?.find((c) => c.value === props.selectedPaymentTerms)}
                        onChange={(e) => props.onPymentTermChange(e.value.value)}
                        required={true}
                        errormessage="Payment Terms is required"
                    />
                </RequiredFormField>
                <FormFieldWithParam RowAlignment="align-items-center" label="Allow Auto-Vouchering for POs" Form_sm={12} Form_md={6} Label_xs={"10"} Control_xs={"auto"} Label_md={10} Control_md={2} Label_lg={8} Control_lg={4}>
                   
                    <Checkbox
                        key={`${allowAutoVoucher}`}
                        name="allowAutoVoucher"
                        checked={allowAutoVoucher}
                        defaultValue={`${allowAutoVoucher}`}
                        onChange={(e) => setAllowAutoVoucher(x => !x)}
                    />
                </FormFieldWithParam>

                <RequiredFormField label="A/P">
                    <XtField
                        type="dropdown"
                        name="apAccounts"
                        items={apAccounts}
                        value={apAccounts.find((c) => c.value === props.selectedApAccount)}
                        onChange={(e) => props.onApAccountChange(e.value.value)}
                        textField="text"
                        required={true}
                        errormessage="A/P is required"
                    />
                </RequiredFormField>

            </Row>
        </>
    );
}
