import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { XtField } from "nwcommon";
import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
  GridToolbar,
  GridProps,
} from "@progress/kendo-react-grid";
import { AdvSearchAndGridUtil } from "../../../../Xt/Utils/AdvanceSearchAndGridUtil";
import { XtPaging } from "../../../../Xt/ApiClients/ApiHelper";

import RightAlignGridColumnHeader from "../../Nw/PurchaseRequisition/Common/GridCell/RightAlignGridColumnHeader";
import AmountGridCell from "../../Nw/PurchaseRequisition/Common/GridCell/AmountGridCell";
import InvoiceAmountGridCell, {
  InvoiceTextGridCell,
} from "../../Nw/PurchaseRequisition/Common/GridCell/InvoiceAmountGridCell";
import { NumberUtil } from "../../../../Xt/Utils/NumberUtil";

export type EditInvoiceInvoiceCodingProps = {
  orgId?: string;
  data?: any[];
  onInvoiceLineItemChange: any;
  triggerCount: number;
  invCodeRunningTotals: any;
};

export function EditInvoiceInvoiceCoding(props: EditInvoiceInvoiceCodingProps) {
  const [data, setData] = React.useState<any[]>([]);

    React.useEffect(() => {
        setData(props.data);
    }, [props.data]);

    return (<>
        <Grid
            data={data?.sort((l1,l2)=> l1.lineNumber-l2.lineNumber)}
            total={0}
            className="k-grid-pr-list"
            editField="inEdit"
            onItemChange={(e) => {
                props.onInvoiceLineItemChange(e, e.dataItem)
            }}
        >
            <GridColumn title="#" field="sno" width="35px" cell={(e) => {
                e.dataItem.inEdit = true;
                return (<td>{e.dataIndex + 1}</td>)
            }} />
            <GridColumn title="Charge Code" field="projectNumber" width="250px" cell={(e) => {
                let acctNo = e.dataItem.projectNumber + ' - ' + e.dataItem.projectName;
                if (e.dataItem.chargeCode !== undefined) {
                    if (e.dataItem.lineAccountList.length > 0) {
                        acctNo = e.dataItem.chargeCode + " - " + e.dataItem.lineAccountList[0].proJ_NAME
                    }
                }
                return (<td>{acctNo}</td>)
            }} />
            <GridColumn title="Org ID" field="orgId" cell={(e) => {
                let orgID = e.dataItem[e.field]
                if (e.dataItem.lineAccountList !== undefined && e.dataItem.lineAccountList.length > 0) {
                    orgID = e.dataItem.lineAccountList[0].orG_ID
                }
                return (<td>{orgID}</td>)
            }} />
               <GridColumn className="invoice-desc" title="Description" field="poLineDescription" editable={false}/>
               <GridColumn title="Invoice Line Description" field="description" editable={true} cell={InvoiceTextGridCell} width="200px"/>
            <GridColumn title="Account #" field="accountNumber" cell={(e) => {
                let acctNo = e.dataItem.accountNumber + ' - ' + e.dataItem?.accountName
                if (e.dataItem.accountID !== undefined)
                    acctNo = e.dataItem.accountID + " - " + e.dataItem?.accountName
                return (<td>{acctNo}</td>)
            }} />
            <GridColumn title="Invoice Coding Amount" field="amount" editable={true}  cell={InvoiceAmountGridCell} />
            <GridColumn title="Commited Amount" field="committedAmount" headerCell={RightAlignGridColumnHeader} cell={AmountGridCell}  />
             <GridColumn title="Cost to Date" field="costToDateAmount" headerCell={RightAlignGridColumnHeader} cell={AmountGridCell}  />
             <GridColumn title="Running Total" field="runningTotal" headerCell={RightAlignGridColumnHeader} cell={(e) => {
                return <td style={{ textAlign: "right" }}>{NumberUtil.formatToTwoDecimalWithComma(props.invCodeRunningTotals[e.dataIndex]?? e.dataItem.runningTotal) }</td>
            }}/>
            <GridColumn title="Remaining Amount" field="remainingAmount" headerCell={RightAlignGridColumnHeader} cell={(e) => {
                let remainingAmount = NumberUtil.decimalNumDiff(e.dataItem.committedAmount, props.invCodeRunningTotals[e.dataIndex] ?? e.dataItem.runningTotal) || 0;
                return <td style={{ textAlign: "right" }}><span className={remainingAmount < 0 ? 'badge bg-danger' : ''}>{NumberUtil.formatToTwoDecimalWithComma(remainingAmount ?? e.dataItem.remainingAmount)}</span></td>
            }} /> 
        </Grid>
    </>)
}
