import React from 'react';
import { XtForm } from "nwcommon";
import { XtField } from 'nwcommon';

export function XtAddCompany(props: any) {

    const onSubmit = (e) => {
         
    }

    return (<div>
        <XtForm onSubmit={ onSubmit }>
            <XtField name="name" placeholder="Name" type="text" required={true} errormessage="Please enter text field" />
            <XtField name="ssc" placeholder="SSC" type="text" required={true} errormessage="Please enter SSC in ddd-ddd-dddd format" pattern="[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}-[a-zA-Z0-9]{4}" />
            <XtField name="amount" placeholder="Amount" type="numeric" required={true} errormessage="Please enter text field" />
            <XtField name="isChecked" type="checkbox" required={true} errormessage="Please enter text field" label="Checkbox Test" />
            <XtField type="radio" name="g1" value="1" errormessage="Please enter text field" label="Radio Test1" />
            <XtField type="radio" name="g1" value="2" errormessage="Please enter text field" label="Radio Test2" />
            <XtField type="radiogroup" required={true} value="opt1" items={[{ text: "Option1", value: "opt1" }, { text: "Option2", value: "opt2" } ] } name="g2" errormessage="Please enter text field" label="Checkbox box Test" />
            <XtField name="telephone" placeholder="Telephone" type="tel" required={true} errormessage="Please enter text field" />
            <XtField name="email" placeholder="Email" type="email" required={true} errormessage="Please enter text field" />
            <XtField name="details" placeholder="Details" type="textarea" required={true} errormessage="Please enter text1 field" />
            <XtField name="field" placeholder="Field" type="dropdown" items={[{ text: 'First Name', value: 'firstName' }, { text: 'Last Name', value: 'lastName' }]} textField="text" required={true} errormessage="Please enter text1 field" />
            <XtField name="fields" placeholder="Fields" type="multiselect" items={[{ text: 'First Name', value: 'firstName' }, { text: 'Last Name', value: 'lastName' }]} textField="text" required={true} errormessage="Please enter text1 field" />
            <XtField name="dateOfBirth" value="02/28/2001" placeholder="Date of Birth" type="datepicker" required={true} errormessage="Please enter text field" />
            <XtField name="log" value="02/28/2001 12:00:01" placeholder="Log" type="datetimepicker" required={true} errormessage="Please enter text field" />
            <button type="submit">Submit</button>
        </XtForm>
    </div>)
}