import React from "react";
import { User } from "../ApiClients/index";

export interface IXtContext {
  loggedOnUser: User;
  rootUser: User;
  switchUser: any;
  isAuthenticated: boolean;
  isInSwitchUserMode: any;
  switchBack: any;
  tokens: IOidcTokens;
  isAllowedToSwitch: any;
  isSuperAdmin: any;
  isHRAdmin: any;
  isPRRReadAll: any;
  apiBasePath: string;
  oktaBasePath: string;
  commonBasePath: string;
  onError: any;
  isExportPopupVisible: boolean;
  showExportPopup: () => void;
  hideExportPopup: () => void;
}

export interface IOidcTokens {
  accessToken: string;
  idToken: string;
  idTokenClaims: any;
}

export const defaultXtContext: IXtContext = {
  tokens: { accessToken: "", idToken: "", idTokenClaims: {} },
  loggedOnUser: null,
  rootUser: null,
  switchUser: () => {},
  isAuthenticated: false,
  isInSwitchUserMode: () => {
    return false;
  },
  switchBack: (): void => {},
  isAllowedToSwitch: () => {
    return false;
  },
  isSuperAdmin: () => {
    return false;
  },
  isHRAdmin: () => {
    return false;
  },
  isPRRReadAll: () => {
    return false;
  },
  apiBasePath: "",
  oktaBasePath: "",
  commonBasePath: "",
  onError: () => {},
  isExportPopupVisible: false,
  showExportPopup: () => {},
  hideExportPopup: () => {},
};

export const XtContext = React.createContext<IXtContext>(defaultXtContext);
