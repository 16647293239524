import { GridToolbar } from "@progress/kendo-react-grid";
import * as Search from "nwcommon";
import {
  XtAdvancedSearchBox,
  XtAdvancedSearchFieldCondition,
  XtExport,
  XtExportToolbar,
  XtGrid,
} from "nwcommon";
import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { APIFactory, GenericApi } from "../../../Xt/ApiClients";
import { Loading } from "../../../Xt/Layout/Loading";
import { IXtContext, XtContext } from "../../../Xt/Contexts/XtContext";
import { AdvSearchAndGridUtil } from "../../../Xt/Utils/AdvanceSearchAndGridUtil";
import InvoiceStatusGridCell from "../invoice/Invoice";
import DateGridCell from "./PurchaseRequisition/Common/GridCell/DateGridCell";
import NwPrStatusGridCell from "./PurchaseRequisition/Common/GridCell/NwPrStatusGridCell";
import PrrStatusGridCell from "./PurchaseRequisition/Common/GridCell/PrrStatusGridCell";
import { NumberUtil } from "../../../Xt/Utils/NumberUtil";

//This is the cell for the type column
//The cell is used to render the type of the row
export function TypeGridCell(props) {
  let type: string = props.dataItem["type"] || "";
  const className = `badge  action-type-${type}`;
  return (
    <td className="text-center ellipisis-none" width="100px">
      <span className={className} role={"gridcell"}>
        {type}
      </span>
    </td>
  );
}
//This is the cell for the status column
//The cell is used to render the status of the row
export function MyActionItemStatusGridCell(props) {
  const type: string = props.dataItem["type"] || "";
  if (type === "Invoice") return <InvoiceStatusGridCell {...props} />;
  if (type === "PRR") return <PrrStatusGridCell {...props} />;
  else return <NwPrStatusGridCell {...props} />;
}

function descriptorGridCell(props) {
  const type: string = props.dataItem["type"] || "";

  // both of these conditional branches are the same, but
  // we may add/remove fields in the future, so we'll keep them separate
  if (type === "Invoice") {
    const descriptorSplit: string[] = props.dataItem["descriptor"].split("|");
    const descriptorFormatted: string[] = descriptorSplit.map((v, i) => {
      // 2 is unformatted invoice amount
      if (i === 2) return NumberUtil.formatToTwoDecimalWithComma(v);
      return v;
    });

    return <td>{descriptorFormatted.join(", ")}</td>;
  } else if (type === "PR") {
    const descriptorSplit: string[] = props.dataItem["descriptor"].split("|");
    const descriptorFormatted: string[] = descriptorSplit.map((v, i) => {
      // 2 is unformatted total amount
      if (i === 2) return NumberUtil.formatToTwoDecimalWithComma(v);
      return v;
    });

    return <td>{descriptorFormatted.join(", ")}</td>;
  }

  return <td>{props.dataItem["descriptor"]}</td>;
}

export const action_schema = [
  {
    field: "type",
    label: "Type",
    visible: true,
    searchable: true,
    type: "text",
    cell: TypeGridCell,
    width: "35px",
  },
  {
    field: "id",
    label: "ID #",
    visible: true,
    searchable: true,
    type: "text",
    width: "40px",
  },
  {
    field: "createdOn",
    label: "Created Date ",
    visible: true,
    searchable: true,
    type: "datepicker",
    width: "40px",
    cell: DateGridCell,
  },
  {
    field: "descriptor",
    label: "Description",
    visible: true,
    searchable: true,
    type: "text",
    cell: descriptorGridCell,
  },
  {
    field: "status",
    label: "Status ",
    visible: true,
    searchable: true,
    type: "text",
    cell: MyActionItemStatusGridCell,
  },
];

export default function MyActionItem() {
  let factory: APIFactory;
  let pageSize = 15;
  const orderByField = "createdOn";
  const [redirectLink, setRedirectLink] = React.useState<string>("");
  const [selectedShortcut, setSelectedShortcut] = useState(null);
  const xts = AdvSearchAndGridUtil.useXtGridSate(pageSize, orderByField);
  const [shortCut, setShortCut] = useState<any>({ key: "all", value: "All" });
  const [searchParams, setSearchParam] = useState<any>({});
  const context: IXtContext = useContext(XtContext);
  const [loading, setLoading] = useState<boolean>(true);

  const onSearchCondition = (e) => {
    xts.setSearchParam(e);
    sessionStorage.setItem("ActionItemsPageSearchHistory", JSON.stringify(e));
    xts.setTime(xts.time + 1);
  };

  const onExportButtonClick = (e) => {
    xts.setReportData(xts.data);

    xts.setExportType({
      type: e.type,
      fields: e.dataItems,
      filename: "test." + (e.type === "pdf" ? "pdf" : "xlsx"),
    });
    xts.setIsExported(false);
  };

  const onPageChange = (e) => {
    xts.setPaging({ ...xts.paging, skip: e.page.skip, top: e.page.take });
  };

  const onSortChange = (e) => {
    AdvSearchAndGridUtil.onSortChange(e, xts);
  };

  const onExportAfterRender = (e) => {
    AdvSearchAndGridUtil.onExportAfterRender(e, xts);
  };

  const onGridSelectionChange = (e) => {
    xts.setGridSelection(e);
  };

  const onGridRowClick = (e) => {
    let url = "";
    if (e.dataItem.type === "PR") {
      if (e.dataItem.isEditable) url = `/pr/edit/${e.dataItem.resourceGuid}`;
      else url = `/pr/view/${e.dataItem.resourceGuid}`;
    } else if (e.dataItem.type === "PRR") {
      if (e.dataItem.isEditable) url = `/prr/edit/${e.dataItem.resourceGuid}`;
      else url = `/prr/view/${e.dataItem.resourceGuid}`;
    } else {
      url = e.dataItem.isEditable
        ? `/invoice/edit/${e.dataItem.resourceGuid}`
        : `/invoice/details/${e.dataItem.resourceGuid}`;
    }
    setRedirectLink(url);
  };
  const rowMenus = [];

  useEffect(() => {
    if (context?.loggedOnUser?.username) {
      loadMyActionItems().then(() => {});
    }
  }, [
    shortCut,
    xts.paging,
    xts.searchParams,
    selectedShortcut,
    context?.loggedOnUser?.username,
  ]);

  let searchFields: XtAdvancedSearchFieldCondition[] = [];
  const loadMyActionItems = async () => {
    if (
      loading ||
      searchParams?.searchString ||
      selectedShortcut?.value !== shortCut?.value
    ) {
      let s: GenericApi = factory.create<GenericApi>(GenericApi);
      try {
        let result = await s.genericMyActionItemGet(
          context?.loggedOnUser?.username,
          xts.searchParams?.searchString,
          selectedShortcut?.value || "all",
          xts.searchParams?.oDataQuery,
          xts.paging.top,
          xts.paging.skip,
          pageSize,
          xts.paging.sort.field + " " + xts.paging.sort.dir
        );
        if (result.ok) {
          var data = await result.json();
          xts.setData(data.value);
          xts.setDataCount(data["@odata.count"]);
          setLoading(false);
        } else {
          var error = await result.json();
          factory.showErrorMessage(JSON.stringify(error), "Error");
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const getAdvancedSearchSelectedValue = (field) => {
    let returnValue = null;
    xts.searchParams.advancedSearchCondition?.map((v, i) => {
      if (field.field === v.field.field) {
        returnValue = v;
      }
    });
    return returnValue;
  };

  action_schema.map((v, i) => {
    let c = getAdvancedSearchSelectedValue(v);
    if (v.type === "text" && v.searchable === true) {
      searchFields.push(
        Search.getTextboxType(
          "",
          v.field,
          v.label,
          c?.value,
          c?.operator.value,
          c?.value2
        )
      );
    }
    if (v.type === "datepicker" && v.searchable === true) {
      searchFields.push(
        Search.getDatePickerType(
          "",
          v.field,
          v.label,
          c?.value,
          c?.operator.value,
          c?.value2
        )
      );
    } else if (v.type === "number" && v.searchable === true) {
      searchFields.push(
        Search.getNumericType(
          "",
          v.field,
          v.label,
          c?.value,
          c?.operator.value,
          c?.value2
        )
      );
    } else if (v.type === "boolean" && v.searchable === true) {
      searchFields.push(
        Search.getBooleanType(
          "",
          v.field,
          v.label,
          [
            { text: "Active", value: "true" },
            { text: "Inactive", value: "false" },
          ],
          c?.value,
          c?.operator.value
        )
      );
    }
  });

  return (
    <div>
      {redirectLink !== "" && <Redirect push to={{ pathname: redirectLink }} />}
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <GridToolbar>
        <div className="page-toolbar p-0 align-items-center">
          <div className="row flex-grow-1 g-0">
            <div className="col-xl-auto pe-md-3">
              <h5 className="mb-2 page-title">My Action Items</h5>
            </div>
            <div className="col-md px-md-0 mb-2">
              {searchFields.length > 0 && (
                <XtAdvancedSearchBox
                  searchPlaceHolder="Search"
                  hideAdvancedSearch={false}
                  searchConditions={searchFields}
                  selectedConditions={xts.searchParams}
                  onSearchConditionApply={onSearchCondition}
                ></XtAdvancedSearchBox>
              )}
            </div>
            <div className="col-sm-auto pe-md-0 mb-2 btn-two-groups">
              <XtExportToolbar
                fields={searchFields}
                onApply={onExportButtonClick}
                gridSelection={xts.gridSelection}
              />
            </div>
          </div>
        </div>
      </GridToolbar>
      <XtExport
        type={xts.exportType.type}
        data={xts.reportData}
        schema={xts.exportType.fields}
        fileName={xts.exportType.filename}
        onAfterRender={onExportAfterRender}
      >
        <XtGrid
          uniqueField="idNumber"
          data={xts.data}
          total={xts.dataCount}
          sort={[xts.paging.sort]}
          rowMenus={rowMenus}
          sortable={true}
          skip={xts.paging.skip}
          pageSize={pageSize}
          pageable={true}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          onGridSelectionChange={onGridSelectionChange}
          onRowClick={onGridRowClick}
          className="k-grid-pr-list"
        >
          {AdvSearchAndGridUtil.renderGridFields(action_schema)}
        </XtGrid>
      </XtExport>
      {loading && <Loading />}
    </div>
  );
}
