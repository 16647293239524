import React, { useContext, useEffect, useState } from "react";
import { ListGroup, NavDropdown } from "react-bootstrap";
import { NwApplicationMenuProps } from "../../Props/NwMenu/NwApplicationMenuProps";
import { NwAppMenuProps } from "../../Props/NwMenu/NwAppMenuProps";
import NwApplicationList from "./NwApplicationList";
import { APIFactoryCommon, ApplicationApi } from '../../ApiClients/Common/index';
import { IXtContext, XtContext } from "../../Contexts/XtContext";

const appMenu: NwAppMenuProps = {
  name: "North.Apps",
  title: "North.Apps",
  categories: []
}
export default function NwAppMenu() {
    let factory: APIFactoryCommon;
  const init = true;
  const [applications, setApplications] = useState([]);
  const [shouldShowApplicaiton, setShouldShowApplicaitonFlag] = useState(false);
  const [appMenus, setAppMenus] = useState(appMenu);
  const context: IXtContext = React.useContext(XtContext);

  useEffect(() => {
    let appMenuApi: ApplicationApi = factory.create(ApplicationApi);
    appMenuApi.applicationGet().then(async (appData) => {
      var appMenuDataFromApi = await appData.json();
      setAppMenus({
        ...appMenu,
        categories: appMenuDataFromApi.value
      })
      appMenuApi.applicationFavoriteGet().then(async (favData) => {
        var appMenuFavoriteDataFromApi = await favData.json();
        if (appMenuDataFromApi && appMenuFavoriteDataFromApi)
          appMenuFavoriteDataFromApi.value.forEach(fav => {
            appMenuDataFromApi.value.forEach(category => {
              const application = category.applications.filter(a => a.applicationId == fav.applicationId);
              if (application && application.length > 0) {
                application[0].isFavorite = true;

              }
            });
          });
      })

    }).finally(() => {
    })
  }
    , [init]
  );


 

  function showApplications(applications: NwApplicationMenuProps[]) {
    setShouldShowApplicaitonFlag(true);
    setApplications(applications);
    
  }

  function showEmployeeAction() {
    const employeeActionCategory = appMenus.categories.filter(cat => cat.name == "EmployeeActions")
    if (employeeActionCategory && employeeActionCategory.length > 0) {
      showApplications(employeeActionCategory[0].applications)
    }

  }



  return (
    <>
          <APIFactoryCommon ref={(e) => { factory = e; }} />
      <NavDropdown title={appMenus.title} className="nw_megamenu" id="" autoClose="outside" onToggle={() => showEmployeeAction()}>
        <div className="d-flex">
          <div className="nw_megamenu-left">
            <ListGroup variant="flush">
                          {
                appMenus.categories.map((cat, i) =>
                  <ListGroup.Item action href={cat.url} onClick={() => showApplications(cat.applications)} className="nw_text-truncate" key={i}>
                    {cat.name}
                  </ListGroup.Item>
                )
              }
            </ListGroup>
          </div>
          {
            shouldShowApplicaiton && <NwApplicationList categories={applications} />
          }
        </div>
      </NavDropdown>
    </>
  );
}