import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Redirect } from "react-router-dom";

interface XtBreadcrumb{
    href:string,
    text:string,
    isActive?:boolean,
}
export type XtBreadcrumbsProps = React.PropsWithChildren<{ breadCrumbs: XtBreadcrumb[] }>
export function XtBreadcrumbs(props: XtBreadcrumbsProps) {

    const [redirect, setRedirect]=React.useState<string>("")

    const onRedirect = (e: any, url: string) => {
        e.preventDefault();
        setRedirect(url)
    }
    
  return (
      <>
          <Breadcrumb> {
              props.breadCrumbs?.map((bc, i) => {
                  return bc.isActive ? <Breadcrumb.Item href="#" onClick={(e) => { onRedirect(e, bc.href) }} active key={`${bc.text}-${i}`}> {bc.text}</Breadcrumb.Item> : <Breadcrumb.Item href="#" onClick={(e) => { onRedirect(e, bc.href) }} key={`${bc.text}-${i}`} > {bc.text}</Breadcrumb.Item>
              })}
          </Breadcrumb>
          {redirect && <Redirect push to={redirect}/>}
    </>
     )
  
}
