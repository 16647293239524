import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormField, { FormFieldFullRow } from "../Common/FormField";
import { APIFactoryCommon } from "../../../../../Xt/ApiClients/Common";
import {
  APIFactory,
  ResourceAttributeApi as PrResourceAttributeApi,
  PurchaseRequisitionApi,
} from "../../../../../Xt/ApiClients";
import FormLabelMutedText from "../Common/FormLabelMutedText";
import AttachmentListReadOnly from "./AttachmentListReadOnly";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { PrApiService } from "../Common/PrApiService";
import { Loading } from "../../../../../Xt/Layout/Loading";
import { DateUtil } from "../../../../../Xt/Utils/DateUtil";

export default function NwAdditionalInfoReadOnly(props) {
  const { pr } = props;

  let commonfactory: APIFactoryCommon;
  let prFactory: APIFactory;
  const [qualityLevels, setQualityLevels] = useState([]);
  const [leaseTypes, setLeaseTypes] = useState([]);
  const context: IXtContext = useContext(XtContext);
  const [attachmentList, setAttachmentList] = useState(
    pr?.attachmentList || [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [allowAttachmentDelete, setAllowAttachmentDelete] =
    useState<boolean>(false);
  const [allowAttachmentUpload, setAllowAttachmentUpload] =
    useState<boolean>(false);

  const loadQualityLevels = () => {
    const api: PrResourceAttributeApi = prFactory.create(
      PrResourceAttributeApi,
    );
    PrApiService.getResourceAttributeList(
      context,
      api,
      "PR",
      "QualityLevel",
    ).then((data) => {
      const sortedQualityLevel = data.sort(function (a, b) {
        return a.text.localeCompare(b.text);
      });

      setQualityLevels(sortedQualityLevel);
    });
  };

  const loadLeaseTypes = () => {
    const api: PrResourceAttributeApi = prFactory.create(
      PrResourceAttributeApi,
    );
    PrApiService.getResourceAttributeList(context, api, "PR", "lease").then(
      (data) => {
        setLeaseTypes(data);
      },
    );
  };

  useEffect(() => {
    if (context?.loggedOnUser?.username) {
      loadQualityLevels();
      loadLeaseTypes();
      setAttachmentList(pr?.attachmentList || []);
      if (pr?.purchaseRequisitionGuid) {
        const prApi: PurchaseRequisitionApi = prFactory.create(
          PurchaseRequisitionApi,
        );
        prApi
          .purchaseRequisitionHasPurchaseRequisitionAttachmentPermissionPrGuidGet(
            pr?.purchaseRequisitionGuid,
            context?.loggedOnUser?.username,
          )
          .then((res) => res.json())
          .then((data) => {
            setAllowAttachmentDelete(data.allowDelete);
            setAllowAttachmentUpload(data.allowAttachment);
          });
      }
    }
  }, [context?.loggedOnUser?.username, pr?.attachmentList]);

  const qualityLevel = qualityLevels.find((q) => q.value === pr?.qualityLevel);
  const leaseType = leaseTypes.find((l) => l.value === pr?.leaseType);
  const showSSj =
    pr?.isCompeted === false &&
    attachmentList &&
    attachmentList.filter((a) => a.attachmentType === "SSJForm").length > 0;
  const showQuotes =
    pr?.isCompeted &&
    attachmentList &&
    attachmentList.filter((a) => a.attachmentType === "Quotes").length > 0;
  const showCompeted = showSSj || showQuotes;

  const toBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result?.toString() || "");
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const ff = e.target.files[i];
      file.push({
        name: ff.name,
        type: ff.type,
        size: `${ff.size} bytes`,
        description: "",
        data: await toBase64(ff),
        AttachmentType: "AdditionalAttachment",
      });
    }
    e.target.value = "";
    const prApi: PurchaseRequisitionApi = prFactory.create(
      PurchaseRequisitionApi,
    );
    setLoading(true);
    prApi
      .purchaseRequisitionAddAttachmentPost(
        context?.loggedOnUser?.username,
        file,
        pr.purchaseRequisitionGuid,
      )
      .then((data) => data.json())
      .then((res) => {
        const data = res.result;
        data.forEach((attachment) => {
          attachment.createdOn = DateUtil.formatmmDdYYY(attachment?.createdOn);
        });
        setAttachmentList(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //let defaultValueForTheProject = qualityLevels.find((q) =>q.value === props.defaultQualityLevelForTheSelectedProject);
  return (
    <>
      <APIFactoryCommon
        ref={(e) => {
          commonfactory = e;
        }}
      />

      <APIFactory
        ref={(e) => {
          prFactory = e;
        }}
      />
      <Row>
        <Col md={6}>
          <FormFieldFullRow label="Quality Level">
            <FormLabelMutedText value={qualityLevel?.text} />
          </FormFieldFullRow>
          <FormFieldFullRow label={leaseType ? "Lease Type" : "Lease"}>
            <FormLabelMutedText value={leaseType ? leaseType?.text : "No"} />
          </FormFieldFullRow>
          <FormFieldFullRow label="Comments">
            <FormLabelMutedText value={pr?.comments} />
          </FormFieldFullRow>
          <Row>
            <Col md={12} className="mb-3">
              <Row>
                <Form.Label column sm={2}>
                  Additional Attachments
                </Form.Label>
                <Col sm={10} />
              </Row>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <AttachmentListReadOnly
                files={attachmentList.filter(
                  (a) => a.attachmentType === "AdditionalAttachment",
                )}
                allowAttachmentDelete={allowAttachmentDelete}
                prGuid={pr?.purchaseRequisitionGuid}
                onUpdateFiles={(updatedFiles) =>
                  setAttachmentList(updatedFiles)
                }
              />
              {allowAttachmentUpload && (
                <Form.Control
                  title="Choose File"
                  type="file"
                  onChange={handleFileUpload}
                  className="xtupload-button mb-3 mt-3"
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          {showCompeted && (
            <FormField label="Competed">
              <FormLabelMutedText value={pr?.isCompeted ? "Yes" : "No"} />
            </FormField>
          )}

          {showSSj && (
            <Row className="mb-3">
              <Col sm={12}>
                <AttachmentListReadOnly
                  files={attachmentList.filter(
                    (a) => a.attachmentType === "SSJForm",
                  )}
                />
              </Col>
            </Row>
          )}

          {showQuotes && (
            <Row className="mb-3">
              <Col sm={12}>
                <AttachmentListReadOnly
                  files={attachmentList.filter(
                    (a) => a.attachmentType === "Quotes",
                  )}
                />
              </Col>
            </Row>
          )}
        </Col>
        {loading && <Loading />}
      </Row>
    </>
  );
}
