export const enum PrrStatus {
  DRAFT = "Draft",
  SUBMITTED = "Submitted",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  CANCELED = "Canceled",
  WAITING = "Waiting for",
  REQUESTOR = "Requestor",
  APPROVED_BREEZY = "Waiting for Breezy integration",
  BREEZY_INTEGRATED = "Breezy Integrated",
}
export enum EnApprovalMode {
  Approve = "Approve",
  Reject = "Reject",
  Revise = "Revise",
  NotSet = "NotSet",
  Cancel = "Cancel",
}

export const enum EnPrWorkflowStatus {
  Completed = "Completed",
  In_Progress = "In-Progress",
  Rejected = "Rejected",
  Not_Complete = "Not Completed",
}
export const enum PrrRequestType {
  PERSONNEL_REQUISITION = "New Personnel Request",
  EDIT_PERSONNEL_REQUISITION = "Edit Personnel Request",
}
export const enum RequestPageMode {
  PRR_CREATE_NEW = "PRR_CREATE_NEW",
  PRR_EDIT = "PRR_EDIT",
  PRR_CREATE_NEW_CLONE = "PRR_CREATE_NEW_CLONE",
}
