import { Button, Col } from "react-bootstrap";
import { XtGrid } from "nwcommon";
import { AdvSearchAndGridUtil } from "../../../../../Xt/Utils/AdvanceSearchAndGridUtil";
import {
    GridToolbar, Grid,
    GridColumn } from "@progress/kendo-react-grid";
import NwSubTotal from "./SubTotal";
import { EnPrAction } from "../Common/PrReducer";
import { enum_request_type, NwPrConstants } from "../Common/NwPrConstants";
import { DateUtil } from "../../../../../Xt/Utils/DateUtil";
import { NumberUtil } from "../../../../../Xt/Utils/NumberUtil";
import AmountGridCell, { AmountGridCellBold, AmountGridCellWithOldValue, AmountGridCellWithOldValueBold, NumberGridCellWithComma, NumberGridCellWithCommaWithOldValue, TotalAmountGridCellWithOldValueBold, TotalAmountGridCellBold } from "../Common/GridCell/AmountGridCell";
import RightAlignGridColumnHeader from "../Common/GridCell/RightAlignGridColumnHeader";
import NumericGridCell from "../Common/GridCell/NumberGridCell";
import NumberGridCell from "../Common/GridCell/NumberGridCell";
import RightAlignGridCell from "../Common/GridCell/RightAlignGridCell";


export default function NwPurchaseItem(props) {
    const { state, dispatch } = props;
    return (
        <>
            <Col sm={12}>
                {/* <Row> */}
                <GridToolbar>
                    <Button variant="primary" onClick={(e) => dispatch({ type: EnPrAction.PURCHASE_ITEM_ADD_NEW_CLICK })}>
                        Add Line Item
                    </Button>
                </GridToolbar>
                {/*<XtGrid*/}
                {/*  uniqueField="lineItemNumber"*/}
                {/*  data={state?.purchaseItem}*/}
                {/*  onRowClick={(e)=> dispatch({type:EnPrAction.PURCHASE_ITEM_EDIT_CLICK, payload:e.dataItem})}*/}
                {/*>*/}
                {/*  {AdvSearchAndGridUtil.renderGridFields(NwPrConstants.purchaseItemSchema)}*/}
                {/*</XtGrid>*/}

                {/* </Row> */}
                {/* <Row> */}

                <>
                    <Grid
                        data={ state?.purchaseItem}
                        total={0}
                        className="k-grid-pr-list"
                        onRowClick={(e) => dispatch({ type: EnPrAction.PURCHASE_ITEM_EDIT_CLICK, payload: e.dataItem })}
                    >
                        <GridColumn title="#" field="lineItemNumber" width="36px" />
                        <GridColumn title="Charge Code" field="chargeCode" cell={(v) => {
                            var value = v.dataItem[v.field] + " - " + v.dataItem?.chargeCodeName;
                            return (<td>{value}</td>);
                        }} />
                        <GridColumn title="Type" field="type" width="100px" cell={(v) => {
                            var value = v.dataItem[v.field] + " - " + v.dataItem?.typeName;
                            return (<td>{value}</td>);
                        }} />
                        <GridColumn title="Account No." field="accountNumber" cell={(v) => {
                            var value = v.dataItem[v.field] + " - " + v.dataItem?.accountName;
                            return (<td>{value}</td>);
                        }} />
                        <GridColumn title="Description" field="description" width="200px" />
                        <GridColumn title="Quantity" field="quantity" 
                            cell={state?.prType === enum_request_type.PO_CHANGE_REQUEST ? NumberGridCellWithCommaWithOldValue : NumberGridCellWithComma}
                             headerCell={RightAlignGridColumnHeader} />
                        <GridColumn title="Unit" field="unit" />
                        
                        <GridColumn title="Unit Price" field="unitPrice" 
                                cell={state?.prType === enum_request_type.PO_CHANGE_REQUEST? AmountGridCellWithOldValue:AmountGridCell }
                         headerCell={RightAlignGridColumnHeader}  />
                        
                        {state?.requisitionType ==="Service" && 
                        <GridColumn title="Start Date" field="startDate" cell={(v) => {
                            if (state?.requisitionType !== "Service")
                                return (<td>N/A</td>);
                            if (v === undefined)
                                return (<td></td>);
                            
                            var value = DateUtil.formatmmDdYYY(v.dataItem[v.field]);
                            return (<td>{ value}</td>);
                        }} /> }
                        {state?.requisitionType ==="Service" &&
                        <GridColumn title="End Date" field="endDate" cell={(v) => {
                            if (state?.requisitionType !== "Service")
                                return (<td>N/A</td>);
                            if (v === undefined)
                                return (<td></td>);
                            var value = DateUtil.formatmmDdYYY(v.dataItem[v.field]);
                            return (<td>{value}</td>);
                        }} />}
                        <GridColumn title="Total" field="total" cell={state?.prType === enum_request_type.PO_CHANGE_REQUEST? TotalAmountGridCellWithOldValueBold:TotalAmountGridCellBold } headerCell={RightAlignGridColumnHeader}  />
                    </Grid>
                </>
                <NwSubTotal
                    state={state}
                    dispatch={dispatch}
                />
                {/* </Row> */}
            </Col>
        </>
    );
}
