import { Button } from "react-bootstrap";

interface ISaveButtonProps {
  loading: boolean;
  onSave: (e: any) => void;
}

export default function SaveButton(props: ISaveButtonProps) {
  const { loading, onSave } = props;
  return (
    <Button
      type="button"
      id="save"
      variant="secondary"
      disabled={loading}
      onClick={onSave}
    >
      Save
    </Button>
  );
}
