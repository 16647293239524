import { NumberUtil } from "../../../../../Xt/Utils/NumberUtil";
import { enum_request_type } from "./NwPrConstants";

export const enum EnPrTypeDetail {
  PR_CREATE_NEW = "PR_CREATE_NEW",
  PR_EDIT = "PR_EDIT",
  PO_CHANGE_REQUEST_BY_PO = "PO_CHANGE_REQUEST_BY_PO",
  PO_CHANGE_REQUEST_BY_PR = "PO_CHANGE_REQUEST_BY_PR",
}

export const enum EnPrAction {
  CREATE_NEW_PR = "CREATE_NEW_PR",
  EDIT_PR = "EDIT_PR",
  PO_CHANGE_REQUEST = "PO_CHANGE_REQUEST",
  REQUISITION_TYPE_CHANGE = "REQUISITION_TYPE_CHANGE",
  CHARGE_TYPE_CHANGE = "CHARGE_TYPE_CHANGE",
  COMPANY_CODE_CHANGE = "COMPANY_CODE_CHANGE",
  PROJECT_NUMBER_CHANGE = "PROJECT_NUMBER_CHANGE",
  DATE_NEEDED_CHANGE = "DATE_NEEDED_CHANGE",
  QUALITY_LEVEL_CHANGE = "QUALITY_LEVEL_CHANGE",
  VENDOR_CODE_CHANGE = "VENDOR_CODE_CHANGE",
  PURCHASE_ITEM_ADD_NEW_CLICK = "PURCHASE_ITEM_ADD_NEW_CLICK",
  PURCHASE_ITEM_EDIT_CLICK = "PURCHASE_ITEM_EDIT_CLICK",
  PURCHASE_ITEM_DIALOG_CLOSE = "PURCHASE_ITEM_DIALOG_CLOSE",
  PURCHASE_ITEM_ADD_TO_LIST = "PURCHASE_ITEM_ADD_TO_LIST",
  PURCHASE_ITEM_EDIT_ON_LIST = "PURCHASE_ITEM_EDIT_ON_LIST",
  PURCHASE_ITEM_DELETE_FROM_LIST = "PURCHASE_ITEM_DELETE_FROM_LIST",
  ESTIMATED_SHIPPING_COST_CHANGE = "ESTIMATED_SHIPPING_COST_CHANGE",
  TAX_AMOUNT_CHANGE = "TAX_AMOUNT_CHANGE",
  OTHER_AMOUNT_CHANGE = "OTHER_AMOUNT_CHANGE",
  PAYMENT_TYPE_CHANGE = "PAYMENT_TYPE_CHANGE",
  IS_LEASE_CHECKED = "IS_LEASE_CHECKED",
  LEASE_CHANGE = "LEASE_CHANGE",
  COMPETED_CHANGE = "COMPETED_CHANGE",
  PURCHASE_ITEM_ADD_TO_LIST_AND_CONTINUE = "PURCHASE_ITEM_ADD_TO_LIST_AND_CONTINUE",
  ATTACHMENT_DELTE_FILE = "ATTACHMENT_DELTE_FILE",
  SET_VENDOR_GUID = "SET_VENDOR_GUID",
  SET_VENDOR = "SET_VENDOR",
}

export class PrAction {
  type: EnPrAction;
  payload: any;
}

export const PrReducer = {
  reducer: (state, action) => {
    switch (action.type) {
      case EnPrAction.CREATE_NEW_PR:
        return {
          prNumber: "Auto generated",
          status: "Draft",
          prType: "New Purchase Request",
          createdOn: new Date(),
          showPurchaseItemDailog: false,
          nextLineItemNumber: 1,
          isPurchaseItemForEdit: false,
          currentPurchaseItem: null,
          purchaseItem: [],
          dateNeeded: undefined,
          prTypeDetail: EnPrTypeDetail.PR_CREATE_NEW,
        };

      case EnPrAction.EDIT_PR:
        let editState = {
          ...action.payload,
          subTotal: action.payload.purchaseItem.reduce(
            (sum, current) => NumberUtil.decimalNumSum(sum, current.total),
            0,
          ),
          isLeaseChecked: action.payload?.leaseType ? "Yes" : "No",
          isVendorRequired: action.payload?.vendorCode ? "Yes" : "No",
          isCompeted: action.payload.isCompeted ? "Yes" : "No",
          dateNeeded: action.payload?.dateNeeded
            ? new Date(action.payload?.dateNeeded)
            : undefined,
          prTypeDetail: EnPrTypeDetail.PR_EDIT,
        };

        return getNewStateAfterPurchaseItemUpdate(
          editState,
          editState.purchaseItem,
        );

      case EnPrAction.PO_CHANGE_REQUEST:
        let poChangeState = {
          ...action.payload,
          subTotal: action.payload?.purchaseItem
            ? action.payload?.purchaseItem?.reduce(
                (sum, current) => NumberUtil.decimalNumSum(sum, current.total),
                0,
              )
            : 0,
          isLeaseChecked: action.payload?.leaseType ? "Yes" : "No",
          isVendorRequired: action.payload?.vendorCode ? "Yes" : "No",
          isCompleted: action.payload?.isCompleted ? "Yes" : "No",
          dateNeeded: action.payload?.dateNeeded
            ? new Date(action.payload?.dateNeeded)
            : undefined,
          createdOn: new Date(),
          prType: enum_request_type.PO_CHANGE_REQUEST,
          status: "Draft",
          purchaseRequisitionGuid: null,
          prNumber: null,
          vendorGuid: state?.vendorGuid,

          parentGuid: action.payload?.purchaseRequisitionGuid,
        };

        return getNewStateAfterPurchaseItemUpdate(
          poChangeState,
          poChangeState.purchaseItem,
        );
      case EnPrAction.REQUISITION_TYPE_CHANGE:
        return {
          ...state,
          requisitionType: action.payload,
          shippingCost: undefined,
          taxAmount: undefined,
          otherAmount: undefined,
          vendorCode: undefined,
        };

      case EnPrAction.CHARGE_TYPE_CHANGE:
        return {
          ...state,
          chargeType: action.payload,
          qualityLevel:
            action.payload === "Indirect" ? "NonQuality" : undefined,
        };
      case EnPrAction.COMPANY_CODE_CHANGE:
        return {
          ...state,
          companyCode: action.payload,
          vendorCode: undefined,
        };
      case EnPrAction.PROJECT_NUMBER_CHANGE:
        return {
          ...state,
          projectNumber: action?.payload?.value,
          // qualityLevel: action.payload.qualityLevel,
          accountGrpCode: action?.payload?.accountGrpCode,
          projectName: action?.payload?.text,
        };
      case EnPrAction.DATE_NEEDED_CHANGE:
        return {
          ...state,
          dateNeeded: action.payload,
        };
      case EnPrAction.QUALITY_LEVEL_CHANGE:
        return {
          ...state,
          qualityLevel: action.payload,
        };
      case EnPrAction.VENDOR_CODE_CHANGE:
        return {
          ...state,
          vendorCode: action.payload,
        };
      case EnPrAction.SET_VENDOR_GUID:
        return {
          ...state,
          vendorGuid: action.payload || undefined,
        };
      case EnPrAction.SET_VENDOR:
        return {
          ...state,
          vendor: action.payload,
        };

      case EnPrAction.PURCHASE_ITEM_DIALOG_CLOSE:
        return {
          ...state,
          showPurchaseItemDailog: false,
        };

      case EnPrAction.PURCHASE_ITEM_ADD_NEW_CLICK:
        return {
          ...state,
          currentPurchaseItem: { lineItemNumber: state.nextLineItemNumber },
          isPurchaseItemForEdit: false,
          showPurchaseItemDailog: true,
        };
      case EnPrAction.PURCHASE_ITEM_EDIT_CLICK:
        return {
          ...state,
          currentPurchaseItem: action.payload,
          isPurchaseItemForEdit: true,
          showPurchaseItemDailog: true,
        };

      case EnPrAction.PURCHASE_ITEM_ADD_TO_LIST:
        const purchaseItemAfterAdd = [
          ...state.purchaseItem,
          {
            ...action.payload,
            startDate: new Date(action.payload.startDate),
            endDate: new Date(action.payload.endDate),
            // tranType: state?.prType== enum_request_type.PO_CHANGE_REQUEST ? "UPDATE":"INSERT"
          },
        ];

        return getNewStateAfterPurchaseItemUpdate(state, purchaseItemAfterAdd);

      case EnPrAction.PURCHASE_ITEM_EDIT_ON_LIST:
        const editItem = {
          ...action.payload,
          startDate: new Date(action.payload.startDate),
          endDate: new Date(action.payload.endDate),
        };
        const purchaseItemAfterEdit = state.purchaseItem.map((p) =>
          p.lineItemNumber == editItem.lineItemNumber
            ? {
                ...editItem,
                lastQuantity: p?.lastQuantity,
                lastUnitPrice: p?.lastUnitPrice,
                lastTotal: p?.lastTotal,
              }
            : p,
        );

        //console.log(purchaseItemAfterEdit);

        return getNewStateAfterPurchaseItemUpdate(state, purchaseItemAfterEdit);

      case EnPrAction.PURCHASE_ITEM_DIALOG_CLOSE:
        return { ...state, showPurchaseItemDailog: false };

      case EnPrAction.PURCHASE_ITEM_DELETE_FROM_LIST:
        const purchaseItemAfterDelete = state?.purchaseItem
          .filter((p) => p.lineItemNumber != action.payload)
          .map((p, i) => {
            return { ...p, lineItemNumber: i + 1 };
          });
        return getNewStateAfterPurchaseItemUpdate(
          state,
          purchaseItemAfterDelete,
        );

      case EnPrAction.ESTIMATED_SHIPPING_COST_CHANGE:
        return {
          ...state,
          shippingCost: action.payload,
          totalAmount: getTotalAmount({
            ...state,
            shippingCost: action.payload,
          }),
        };
      case EnPrAction.TAX_AMOUNT_CHANGE:
        return {
          ...state,
          taxAmount: action.payload,
          totalAmount: getTotalAmount({
            ...state,
            taxAmount: action.payload,
          }),
        };

      case EnPrAction.OTHER_AMOUNT_CHANGE:
        return {
          ...state,
          otherAmount: action.payload,
          totalAmount: getTotalAmount({
            ...state,
            otherAmount: action.payload,
          }),
        };
      case EnPrAction.PAYMENT_TYPE_CHANGE:
        return {
          ...state,
          paymentType: action.payload,
        };

      case EnPrAction.IS_LEASE_CHECKED:
        return {
          ...state,
          isLeaseChecked: action.payload,
          leaseType: action.payload == "No" ? undefined : state?.leaseType,
        };
      case EnPrAction.LEASE_CHANGE:
        return {
          ...state,
          leaseType: action.payload,
        };
      case EnPrAction.COMPETED_CHANGE:
        return {
          ...state,
          isCompeted: action.payload,
        };

      case EnPrAction.ATTACHMENT_DELTE_FILE:
        const attachmentList = state?.attachmentList.filter(
          (a) => a.attachmentGuid != action.payload,
        );

        return { ...state, attachmentList: attachmentList };

      case EnPrAction.PURCHASE_ITEM_ADD_TO_LIST_AND_CONTINUE:
        const purchaseItemAfterAddAndContinue = [
          ...state.purchaseItem,
          {
            ...action.payload,
            startDate: new Date(action.payload.startDate),
            endDate: new Date(action.payload.endDate),
            // tranType: state?.prType== enum_request_type.PO_CHANGE_REQUEST ? "UPDATE":"INSERT"
          },
        ];

        const newStateAfterPurchaseItemAddAndContinue =
          getNewStateAfterPurchaseItemUpdate(
            state,
            purchaseItemAfterAddAndContinue,
          );

        newStateAfterPurchaseItemAddAndContinue.currentPurchaseItem = {
          lineItemNumber: purchaseItemAfterAddAndContinue.length + 1,
          chargeCode: "",
          description: "",
          type: action.payload.type,
          accountNumber: action.payload.accountNumber,
          quantity: Number(),
          unitPrice: Number(),
          unit: "",
          total: Number(),
          startDate: undefined,
          endDate: undefined,
        };
        newStateAfterPurchaseItemAddAndContinue.showPurchaseItemDailog = true;
        return newStateAfterPurchaseItemAddAndContinue;
    }
  },
};

const getNewStateAfterPurchaseItemUpdate = (state, purchaseItem) => {
  const subTotal = purchaseItem
    ? purchaseItem.reduce(
        (sum, current) => NumberUtil.decimalNumSum(sum, current.total),
        0,
      )
    : 0;
  const totalAmount = getTotalAmount({
    ...state,
    purchaseItem: purchaseItem,
    subTotal: subTotal,
  });

  return {
    ...state,
    nextLineItemNumber: purchaseItem ? purchaseItem.length + 1 : 1,
    purchaseItem: purchaseItem,
    showPurchaseItemDailog: false,
    subTotal: subTotal,
    totalAmount: totalAmount,
  };
};

const getTotalAmount = (newState) => {
  if (newState?.subTotal) {
    let newTotalAmount = newState?.subTotal;
    if (newState?.shippingCost)
      newTotalAmount = newTotalAmount + newState?.shippingCost;
    if (newState?.taxAmount)
      newTotalAmount = newTotalAmount + newState?.taxAmount;
    if (newState?.otherAmount)
      newTotalAmount = newTotalAmount + newState?.otherAmount;
    return newTotalAmount;
  } else return undefined;
};
