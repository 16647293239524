import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React from 'react';
import { Button } from 'react-bootstrap';
import { XtForm } from 'nwcommon';
import { XtField } from 'nwcommon';
import { XtField as XtField1 } from 'nwcommon';
import { CountryApi, APIFactory, Country, UploadApi } from '../../../../Xt/ApiClients';

export function XtAddEditCountryView(props: any) {
    let factory: APIFactory;

    const [completeOptions, setCompleteOptions] = React.useState<string[]>(["Australia", "India", "Nepal", "United Kingdom", "United States"])
    const [checkboxValue, setCheckboxValue] = React.useState<string>("opt1")
    const [fieldValue, setFieldValue] = React.useState<string>()
    
    const onSubmit = async (e) => {
        
        var api: UploadApi = factory.create<UploadApi>(UploadApi);
        var result = await api.uploadPathPost("/test",e.postData);
        
        if (props.onClose) {
            props.onClose(e)
        }
    }

    const onCancel = (e) => {
        if (props.onClose) {
            props.onClose(e)
        }
    }

    const onTypeAhead = async (e) => {
        let s: CountryApi = factory.create(CountryApi);
        let counties = await s.countryGet(e.value)
        if (counties.ok) {
            let items: string[] = [];
            let json = await counties.json();
            if (json.value && json.value.map) {
                json.value.map((v: Country, i: number) => {
                    items.push(v.countryName);
                })
            }
            setCompleteOptions(items);
        }
    }

    const onMultiRadioChange = (e) => {
        setCheckboxValue(e.value.value)
    }

    const onFieldChange = (e) => {
        
        setFieldValue(e.value)
    }

    return (
        <>
            {<Dialog title={props.title} onClose={props.onClose} {...props} width={"70%"}>
                <APIFactory ref={(e) => { factory = e; }} />
                <XtForm onSubmit={onSubmit}>
                    {/*<XtField type="radiogroup" required={true} items={[{ text: "Option1", value: "opt1" }, { text: "Option2", value: "opt2" }]} name="g2" errormessage="Please enter text field" label="Checkbox box Test" />
                    <XtField type="radio" name="g1" value="1" errormessage="Please enter text field" label="Radio Test1" />
                    <XtField type="radio" name="g1" value="2" errormessage="Please enter text field" label="Radio Test2" />
                    <XtField name="name" placeholder="Name" type="text" required={true} errormessage="Please enter text field" />
                    <XtField name="ssc" placeholder="SSC" type="text" required={true} errormessage="Please enter SSC in ddd-ddd-dddd format" pattern="[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}-[a-zA-Z0-9]{4}" />
                    <XtField name="amount" placeholder="Amount" type="numeric" required={true} errormessage="Please enter text field" />
                    <XtField type="radiogroup" required={true} value="opt1" items={[{ text: "Option1", value: "opt1" }, { text: "Option2", value: "opt2" }]} name="g2" errormessage="Please enter text field" label="Checkbox box Test" />
                    <XtField name="telephone" placeholder="Telephone" type="tel" required={true} errormessage="Please enter text field" />
                    <XtField name="email" placeholder="Email" type="email" required={true} errormessage="Please enter text field" />
                    <XtField name="details" placeholder="Details" type="textarea" required={true} errormessage="Please enter text1 field" />
                    <XtField name="field" placeholder="Field" type="dropdown" items={[{ text: 'First Name', value: 'firstName' }, { text: 'Last Name', value: 'lastName' }]} textField="text" required={true} errormessage="Please enter text1 field" />
                    <XtField name="multi" placeholder="Field" type="multiselect" items={[{ text: 'First Name', value: 'firstName' }, { text: 'Last Name', value: 'lastName' }]} textField="text" required={false} errormessage="Please enter text1 field" />
                    <XtField name="autoc" placeholder="Auto Complete" type="autocomplete" items={completeOptions} required={false} errormessage="Please enter text1 field" onChange={onTypeAhead} />
                    <XtField name="dateOfBirth" minDate={new Date(2022, 4, 22)} maxDate={new Date(2022, 5, 22)} placeholder="Date of Birth" type="datepicker" required={true} errormessage="Please enter text field" />
                    <XtField name="log" value="02/28/2001 12:00:01" placeholder="Log" type="datetimepicker" required={true} errormessage="Please enter text field" />
                    <XtField name="isChecked" type="checkbox" required={true} errormessage="Please enter text field" label={"Checked state"} />*/}
                    <XtField name="field" placeholder="Field" onChange={onFieldChange} type="dropdown" items={[{ text: 'First Name', value: 'firstName' }, { text: 'Last Name', value: 'lastName' }]} textField="text" value={fieldValue} required={true} errormessage="Please enter text1 field" />
                    <XtField1 name="file1" type="file" required={true} errormessage="Please select a file to upload" />
                    <DialogActionsBar>
                        <Button type="submit" variant="primary">Submit</Button>
                        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                    </DialogActionsBar>
                </XtForm>
            </Dialog>}
        </>
            )
}