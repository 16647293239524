import { createTree, TreeNode } from '../../Utils/Tree';
import { XtNavDropdownMulti } from '../NavBar/XtNavDropdownMulti';
import { IXtMenuData } from '../../Utils/IXtMenuData';
import { NwUserInterfaceMenuProps } from '../../Props/NwMenu/NwUserInterfaceMenuProps';

function mapToMenuDataRecursive(datafromApi: any[]) {
  const menuDataList = [];
  datafromApi.forEach(element => {
    const menuDataItem: IXtMenuData = { url: element.menuUrl, title: element.menuText };
    if (element.child && element.child.length > 0)
      menuDataItem.children = mapToMenuDataRecursive(element.child);
    menuDataList.push(menuDataItem);
  });
  return menuDataList;
}

export default function NwUserInferface(props: NwUserInterfaceMenuProps) {
  const menuDataInTreeFormat = mapToMenuDataRecursive(props.menuData);
  const headerMenu: IXtMenuData = { url: props.interfaceName, title: props.interfaceName, className: "nav-item" };
  const treeData = createTree(menuDataInTreeFormat || [], headerMenu);

  if (props.firstLevelChild) {
    const firstLevelChildNode = new TreeNode(-1, props.firstLevelChild, treeData.Root);
    treeData.Root.Children = [firstLevelChildNode, ...treeData.Root.Children];
  }
  return (
    <>
      <XtNavDropdownMulti data={treeData} />
    </>
  )

}
