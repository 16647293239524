import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function PopOverGridCell(props) {
  // console.log(props);
  return (
    <td>
      <OverlayTrigger
        key="right"
        placement={props?.placement ?? "left"}
        overlay={
          <Popover id="popover-basic">
            <Popover.Body>{props.dataItem[props.field]}</Popover.Body>
          </Popover>
        }
      >
        <span className="text-truncate">{props.dataItem[props.field]}</span>
      </OverlayTrigger>
    </td>
  );
}
