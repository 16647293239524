
import React from 'react'
import { Accordion } from 'react-bootstrap'

export default function XtAccordianItem(props) {
    
  return (
    <>
      {props?.IsCollapsible && (
        <Accordion.Item eventKey={props.eventKey}>
          <Accordion.Header>{props.header}</Accordion.Header>
          <Accordion.Body>{props.children}</Accordion.Body>
        </Accordion.Item>
      )}

      {!props?.IsCollapsible && (
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              type="button"
              aria-expanded="false"
              
              className="accordion-button-with-out-arrow accordion-button"
            >
              {props.header}
            </button>
          </h2>
          <div className="accordion-collapse collapse show">
            <div className="accordion-body">{props.children}</div>
          </div>
        </div>
      )}
    </>
  );

  
}

export function XtAccordianSeperator(props) {

  return (
    <div className="accordion-item">
    <div className="accordion-header">
        <div
              aria-expanded="false"
              className="accordion-button-with-out-arrow accordion-button"
            >
              {props.title}
      </div>
    </div>
    </div>
  )
}
