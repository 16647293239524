import React from "react";
import context from "react-bootstrap/esm/AccordionContext";
import { APIFactory, UploadApi } from "../../Xt/ApiClients";
import { IXtContext, XtContext } from "../../Xt/Contexts/XtContext";
import { XtFileType } from "../../Xt/FileBrowser/XtFile";
import { XtFileBrowser } from "../../Xt/FileBrowser/XtFileBrowser";
import { Loading } from "../../Xt/Layout/Loading";

export function XtFile(props: any) {
  const [files, setFiles] = React.useState<XtFileType[]>([]);
  const [path, setPath] = React.useState<string>("/");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [time, setTime] = React.useState<number>(0);

  let context: IXtContext = React.useContext(XtContext);

  let apiFactory: APIFactory;
  /*const getFiles = () => {
        let items: XtFileType[] = [];
        items.push({
            name: "Level1Folder1",
            parent: "/test",
            type: ""
        })

        setFiles(items)
    }*/

  const getFiles = () => {
    let s: UploadApi = apiFactory.create(UploadApi);
    setLoading(true);
    s.uploadPathGet(path, context?.loggedOnUser?.username)
      .then(async (v) => {
        let data = await v.json();
        setFiles(data.value);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (context?.loggedOnUser?.username) getFiles();
  }, [props, context?.loggedOnUser?.username]);

  React.useEffect(() => {
    getFiles();
  }, [path, time]);

  const onFolderOpen = (e) => {
    setFiles([]);
    setPath(e.path);
  };

  const onFileUpload = async (e) => {
    let s: UploadApi = apiFactory.create(UploadApi);
    setLoading(true);
    try {
      let result = await s.uploadPathPost(
        path,
        context?.loggedOnUser?.username,
        { file1: e.postData }
      );
      if (result.ok) {
        setTime(time + 1);
        e.callback();
        setLoading(false);
      }
    } catch (e) {
      let msg = await e.json();
      context.onError({
        title: "Error Uploading",
        message: [msg.message || msg.title],
      });
      setLoading(false);
    }
  };

  const onFolderCreate = (e) => {
    setLoading(true);
    let s: UploadApi = apiFactory.create(UploadApi);
    s.uploadPathFolderPost(
      `${path}/${e.value}`,
      context?.loggedOnUser?.username,
      {}
    )
      .then(async (res) => {
        e.callback();
        setTime(time + 1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <APIFactory
        ref={(e) => {
          apiFactory = e;
        }}
      />
      <XtFileBrowser
        displayType="inline"
        path={path}
        files={files}
        paneHeight="300px"
        onFolderOpen={onFolderOpen}
        onFolderCreate={onFolderCreate}
        allowChangeFolder={true}
        onUpload={onFileUpload}
      />
      {loading && <Loading />}
    </div>
  );
}
