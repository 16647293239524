import { useState, useEffect, useContext } from "react";
import { Accordion } from "react-bootstrap";
import { XtNavLeftProps } from "../../Props/XtNavLeftProps";
import { XtNavLeftItem } from "./XtNavLeftItem";
import { Redirect } from "react-router-dom";
import { IXtContext, XtContext } from "../../Contexts/XtContext";
import { APIFactory, InvoiceApi } from "../../ApiClients";

export function XtNavLeft(props: XtNavLeftProps) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { data } = props;
  const [redirectUrl, setRedirectUrl] = useState<string>(null);
  const [allowToAddInvoice, setAllowToAddInvoice] = useState<string>(null);

  let activeKeyMap = {};

  data.forEach((item, idx) => {
    item.children.forEach((child, cIdx) => {
      activeKeyMap[child.to] = `${idx + 1}-${cIdx + 1}`;
    });
  });

  const [activeItem, setActiveItem] = useState(
    activeKeyMap[window.location.pathname] ?? "1-0"
  );

  let factory: APIFactory;

  const context: IXtContext = useContext(XtContext);

  const setActiveItemValue = (activeKey) => {
    setActiveItem(activeKey);
  };

  const getActiveKeys = () => {
    return data.map((x, ix) => ix + "");
  };

  const toggleClass = () => {
    setToggleMenu(!toggleMenu);
  };

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 40);
    });
  }, []);

  useEffect(() => {
    if (context?.loggedOnUser?.username) {
      let s: InvoiceApi = factory.create(InvoiceApi);
      s.invoiceAddInvoiceAuthGet(context?.loggedOnUser?.username).then(
        async (value: Response) => {
          var d2 = await value.json();
          setAllowToAddInvoice(d2.result);
        }
      );
    }
  }, [context?.loggedOnUser]);

  let navLeftClass =
    `sidebar ` +
    (toggleMenu ? "sidebar-collapse " : "") +
    (props.toggleSideBarByLogo ? "sidebar-logo-toggle " : "") +
    (scroll ? "sidebar-attach" : "");

  let treeJsx = data.map((x, ix) => {
    return (
      <Accordion.Item eventKey={"" + ix} key={ix}>
        {x.children.length > 0 && (
          <XtNavLeftItem
            header
            tooltip={x.title}
            icon={x.icon}
            text={x.title}
            to={x.to}
            keyVal={`${ix + 1}-0`}
          />
        )}
        {x.children.length == 0 && (
          <XtNavLeftItem
            tooltip={x.title}
            icon={x.icon}
            text={x.title}
            to={x.to}
            keyVal={`${ix + 1}-0`}
            setActiveItem={setActiveItemValue}
            activeKey={activeItem}
          />
        )}
        <Accordion.Body>
          {x.children.map((child, i) => {
            //console.log(child.title,ix+1+"-"+i+1,child.to);

            if (child.title !== "Add Invoice") {
              return (
                <XtNavLeftItem
                  tooltip={child.title}
                  icon={child.icon}
                  text={child.title}
                  to={child.to}
                  key={`${ix + 1}-${i + 1}`}
                  keyVal={`${ix + 1}-${i + 1}`}
                  setActiveItem={setActiveItemValue}
                  activeKey={activeItem}
                />
              );
            } else {
              if (allowToAddInvoice) {
                return (
                  <XtNavLeftItem
                    tooltip={child.title}
                    icon={child.icon}
                    text={child.title}
                    to={child.to}
                    key={`${ix + 1}-${i + 1}`}
                    keyVal={`${ix + 1}-${i + 1}`}
                    setActiveItem={setActiveItemValue}
                    activeKey={activeItem}
                  />
                );
              } else {
                return <div key={i + 1}></div>;
              }
            }
          })}
        </Accordion.Body>
      </Accordion.Item>
    );
  });

  return (
    <div className={navLeftClass}>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <Accordion defaultActiveKey={getActiveKeys()} alwaysOpen>
        {/* <OverlayTrigger
          key="right"
          placement="right"
          overlay={<Tooltip id={`tooltip-right`}>PR Open/Collapse</Tooltip>}
        > */}
        <div className="accordion-item">
          <div className="sidebar-item">
            <h2 className="accordion-header">
              <button
                type="button"
                className="accordion-button collapsed"
                onClick={toggleClass}
              >
                <i className="material-icons">menu</i>
                <span className="accordion-text text-primary">Menu</span>
              </button>
            </h2>
          </div>
          <div className="accordion-collapse collapse show">
            <div className="accordion-body"></div>
          </div>
        </div>
        {/* 
          <div className="sidebar-item sidebar-item-menu" onClick={toggleClass}>
            <h2 className="accordion-header">
              <button type="button" className="accordion-button collapsed" >
                <i className="material-icons"></i>
                <Link to="/pr">
                    <span className="accordion-text">PR List</span>
                </Link>
              </button>
            </h2>
            <h2 className="accordion-header">
                <button type="button" className="accordion-button collapsed" >
                    <i className="material-icons"></i>
                    <Link to="/invoice/add">
                        <span className="accordion-text">Add Invoice</span>
                    </Link>
                </button>
            </h2>
            <h2 className="accordion-header">
                <button type="button" className="accordion-button collapsed" >
                    <i className="material-icons"></i>
                    <Link to="/invoice">
                        <span className="accordion-text">Invoice List</span>
                    </Link>
                </button>
            </h2>
            <h2 className="accordion-header">
                <button type="button" className="accordion-button collapsed" >
                    <i className="material-icons"></i>
                    <Link to="/po">
                        <span className="accordion-text">PO List</span>
                    </Link>
                </button>
            </h2>
          </div>*/}
        <>{treeJsx}</>
        {/* </OverlayTrigger> */}

        {redirectUrl && <Redirect push to={redirectUrl} />}
      </Accordion>
    </div>
  );
}
