import React, { useContext, useEffect, useState } from 'react'
import { APIFactoryCommon, UserInterfaceMenuApi } from '../../ApiClients/Common/index';
import { IXtContext, XtContext } from '../../Contexts/XtContext';
import NwResourcesMenu from './NwResourcesMenu'
import NwUserInferface from './NwUserInferface'
export default function NwDirectoryAndResourcesMenu() {
    let factory: APIFactoryCommon;
  const context: IXtContext = useContext(XtContext);
  const [directory, setDirectory] = useState([]);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    let userInterfaceApiClient: UserInterfaceMenuApi = factory.create(UserInterfaceMenuApi);
    userInterfaceApiClient.userInterfaceMenuGet().then(async (data) => {
      var dataFromApi = await data.json();
      const directory = dataFromApi.value.filter(d => d.menuText === "Directory")[0].child;
      setDirectory(directory);

      const resources = dataFromApi.value.filter(d => d.menuText === "Resources")[0].child;
      setResources(resources);
      
    }
    );
  }
    , [context.tokens.accessToken]
  );
  
  
  return (
    <>
          <APIFactoryCommon ref={(e) => { factory = e;  } } />
      <NwUserInferface menuData={directory} interfaceName ="Directory"/>
      <NwResourcesMenu menuData={resources} interfaceName="Resources"/>
    </>
  )
}
