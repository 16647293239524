export const DateUtil = {

    formatmmDdYYY: (date) => {
        if (!date) return undefined;

        
        let newDate = new Date(date);
        //let offset = newDate.getTimezoneOffset();
        //newDate = new Date(newDate.getTime() - (offset * 60 * 1000));
        
        
        

        return newDate.toLocaleDateString("en-US", { // you can use undefined as first argument
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });

    },
    convertUTCDateToLocalDate: (date) => {
        var dateLocal = new Date(date);
        var newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000);
        var localTime = newDate.toLocaleString();
        return localTime;
    },

    format24Hour: (date) => {

        let newDate = new Date(date);
        const formatData = (input) => {
            if (input > 9) {
                return input;
            } else return `0${input}`;
        };

        // Function to convert
        // 24 Hour to 12 Hour clock
        const formatHour = (input) => {
            if (input > 12) {
                return input - 12;
            }
            return input;
        };

        // Data about date
        const format = {
            dd: formatData(newDate.getDate()),
            mm: formatData(newDate.getMonth() + 1),
            yyyy: newDate.getFullYear(),
            HH: formatData(newDate.getHours()),
            hh: formatData(formatHour(newDate.getHours())),
            MM: formatData(newDate.getMinutes()),
            SS: formatData(newDate.getSeconds()),
        };

        const format24HourDate = ({ dd, mm, yyyy, HH, MM, SS }) => {
            return `${mm}/${dd}/${yyyy} ${HH}:${MM}:${SS}`;
        };

        // Time in 24 Hour format
        return format24HourDate(format);

    }



}