import { useContext, useState } from "react";
import { Button, Col, Container } from "react-bootstrap";
import {
  APIFactory,
  PersonnelRequisitionApi,
} from "../../../../../../Xt/ApiClients";
import { XtContext } from "../../../../../../Xt/Contexts/XtContext";
import { ErrorUtil } from "../../../../../../Xt/Utils/ErrorUtil";
import { EnConfirmationMessageType } from "../../../../Dialogs/Common/LoadingDailog";
import { Redirect } from "react-router-dom";
import { EnApprovalMode } from "../../../PurchaseRequisition/ApprovalView/ApproveRejectReviseDialog";
import { useOktaAuth } from "@okta/okta-react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { PrrStatus, RequestPageMode } from "../../Interfaces/Enums";

interface IDeleteButtonProps {
  pageMode: RequestPageMode;
  setLoading: (props: any) => void;
  setDailogSuccessForExistingReq: (
    id: string,
    title: string,
    message: string
  ) => void;
  setDialogError: (error: any) => void;
  prrGuid?: string;
  loading: boolean;
}
interface ICancelButtonViewProps {
  onApprovalButtonClick: (approvalMode: EnApprovalMode) => void;
}

export function DeleteButton(props: IDeleteButtonProps) {
  const {
    pageMode,
    setLoading,
    setDailogSuccessForExistingReq,
    setDialogError,
    prrGuid,
    loading,
  } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [redirect, setRedirect] = useState({ shouldRedirect: false, url: "" });
  const context = useContext(XtContext);
  const { authState } = useOktaAuth();

  const handleConfirm = async () => {
    setSubmitLoading(true);
    try {
      if (setLoading) {
        setLoading(true);
      }
      await handleCancel();
    } catch (error) {
    } finally {
      if (setLoading) {
        setLoading(false);
      }
      setSubmitLoading(false);
    }
  };

  const canCancel = () => {
    if (context.isSuperAdmin()) {
      if (pageMode !== RequestPageMode.PRR_CREATE_NEW) {
        return true;
      } else if (pageMode === undefined || pageMode === null) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleCancel = async () => {
    if (prrGuid) {
      await fetch(
        `${context.apiBasePath}/PersonnelRequisition/Delete?key=${prrGuid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
            Loggedonuser: context?.loggedOnUser.username,
          },
        }
      )
        .then(async (res) => {
          if (res.status === 200) {
            if (setDailogSuccessForExistingReq) {
              setDailogSuccessForExistingReq(
                prrGuid,
                "Delete",
                "PRR Draft has been successfully deleted."
              );
            }
          } else {
            let msg = await res.json();
            const errors = ErrorUtil.getErorMessageFromApi(msg);
            if (setDialogError) {
              setDialogError(errors);
            }
          }
        })
        .catch(async (ex) => {
          let msg = await ex.json();
          const errors = ErrorUtil.getErorMessageFromApi(msg);
          if (setDialogError) {
            setDialogError(errors);
          }
        })
        .finally(() => {
          if (setLoading) {
            setLoading({
              show: true,
              title: "Warning",
              messageType: EnConfirmationMessageType.Success,
              message: ["Prr Canceled"],
              onClose: (e) => {
                setRedirect({ shouldRedirect: true, url: "/prr" });
                setLoading({ show: false });
              },
            });
          } else {
            setRedirect({ shouldRedirect: true, url: "/prr" });
          }
        });
    }
  };

  return (
    <>
      {redirect.shouldRedirect && <Redirect push to={redirect.url} />}
      {canCancel() && !loading && !submitLoading && (
        <span
          title="Delete"
          role="button"
          className="k-icon k-i-delete k-icon-md mx-2"
          onClick={() => setIsConfirmingDelete(true)}
          id="delete"
        />
      )}

      {canCancel() && (loading || submitLoading) && (
        <span
          title="Delete"
          role="button"
          className="k-icon k-i-delete k-icon-md mx-2"
        />
      )}

      {isConfirmingDelete && (
        <Dialog
          title="Confirmation"
          onClose={() => {
            setIsConfirmingDelete(false);
          }}
        >
          <Container>
            <Col>Do you really want to delete this draft?</Col>
          </Container>
          <DialogActionsBar>
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                handleConfirm();
                setIsConfirmingDelete(false);
              }}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setIsConfirmingDelete(false);
              }}
            >
              No
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}

interface ICancelButtonEditProps {
  pageMode: RequestPageMode;
  setLoading: (props: any) => void;
  setDailogSuccessForExistingReq: (
    id: string,
    title: string,
    message: string
  ) => void;
  setDialogError: (error: any) => void;
  prrNumber?: string;
  prNumber?: string;
  loading: boolean;
}

export function CancelButtonEdit(props: ICancelButtonEditProps) {
  const {
    pageMode,
    setLoading,
    setDailogSuccessForExistingReq,
    setDialogError,
    prrNumber,
    prNumber,
    loading,
  } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [redirect, setRedirect] = useState({ shouldRedirect: false, url: "" });
  const context = useContext(XtContext);
  let factory;

  const handleClick = async () => {
    setSubmitLoading(true);
    try {
      if (setLoading) {
        setLoading(true);
      }
      await handleCancel();
    } catch (error) {
    } finally {
      if (setLoading) {
        setLoading(false);
      }
      setSubmitLoading(false);
    }
  };

  const canCancel = () => {
    if (context.isSuperAdmin) {
      if (pageMode !== RequestPageMode.PRR_CREATE_NEW) {
        return true;
      } else if (pageMode === undefined || pageMode === null) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleCancel = async () => {
    if (prrNumber) {
      let api: PersonnelRequisitionApi = factory.create(
        PersonnelRequisitionApi
      );
      await api
        .personnelRequisitionCancelPost(
          context?.loggedOnUser?.username,
          PrrStatus.CANCELED,
          prrNumber
        )
        .then(async (res) => {
          if (res.status === 200 || res.status === 201) {
            if (setDailogSuccessForExistingReq) {
              setDailogSuccessForExistingReq(
                prrNumber,
                "Cancel",
                "PRR has been successfully canceled."
              );
            }
          } else {
            let msg = await res.json();
            const errors = ErrorUtil.getErorMessageFromApi(msg);
            if (setDialogError) {
              setDialogError(errors);
            }
          }
        })
        .catch(async (ex) => {
          let msg = await ex.json();
          const errors = ErrorUtil.getErorMessageFromApi(msg);
          if (setDialogError) {
            setDialogError(errors);
          }
        })
        .finally(() => {
          if (setLoading) {
            setLoading({
              show: true,
              title: "Warning",
              messageType: EnConfirmationMessageType.Success,
              message: ["Prr Canceled"],
              onClose: (e) => {
                setRedirect({ shouldRedirect: true, url: "/prr" });
                setLoading({ show: false });
              },
            });
            setRedirect({ shouldRedirect: true, url: "/prr" });
          } else {
            console.log("Redirecting...");
            setRedirect({ shouldRedirect: true, url: "/prr" });
          }
        });
    }
  };

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      {redirect.shouldRedirect && <Redirect push to={redirect.url} />}
      {canCancel() && (
        <Button
          type="button"
          variant="danger"
          onClick={handleClick}
          disabled={loading || submitLoading}
        >
          Cancel
        </Button>
      )}
    </>
  );
}

export function CancelButtonView(props: ICancelButtonViewProps) {
  const { onApprovalButtonClick } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [redirect, setRedirect] = useState({ shouldRedirect: false, url: "" });
  const context = useContext(XtContext);
  let factory;

  const handleClick = async () => {
    onApprovalButtonClick(EnApprovalMode.Cancel);
  };

  const canCancel = () => {
    return context.isSuperAdmin();
  };

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      {redirect.shouldRedirect && <Redirect push to={redirect.url} />}
      {canCancel() && (
        <Button
          type="button"
          onClick={handleClick}
          disabled={submitLoading}
          className="pinkish"
        >
          Cancel
        </Button>
      )}
    </>
  );
}
