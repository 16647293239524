import { XtAlertDropDown } from "./XtAlertDropDown";
import { XtUserProfileMenu } from "./XtUserProfileMenu";
import { XtNavBarContainer } from "./NavBar/XtNavBarContainer";
import { XtNavBarBrand } from "./NavBar/XtNavBarBrand";
import { XtNavBarToggle } from "./NavBar/XtNavBarToggle";
import { XtNav } from "./NavBar/XtNav";
import NwAppMenu from "./NwMenu/NwAppMenu";
import NwSettingsMenuItem from "./NwMenu/NwSettingsMenuItem";
import XtSwtichBack from "./NavBar/XtSwtichBack";
import NwDirectoryAndResourcesMenu from "./NwMenu/NwDirectoryAndResourcesMenu";
import oktaConfig from "../../components/Configurations/okta.config";
import { Navbar, NavLink } from "react-bootstrap";

//TODO: Change any to strict type
export type XtTopNavBarProps2 = React.PropsWithChildren<{
  dataMainNav: any;
  onLogoClick: any;
}>;

export function XtTopNavBar2(props: XtTopNavBarProps2) {
  return (
    <XtNavBarContainer>
      <XtNavBarBrand href="#">
        <img
          alt=""
          src="../../images/logo.png"
          height="50"
          className="logo d-inline-block align-top"
          onClick={props.onLogoClick}
        />
      </XtNavBarBrand>
      <XtNavBarToggle>
        <NwAppMenu />
        <NwDirectoryAndResourcesMenu />
      </XtNavBarToggle>
      <XtNav className="navbar-right-custom">
        <XtSwtichBack />
        <XtAlertDropDown />
        <NwSettingsMenuItem />
        <NavLink href="https://vimeo.com/showcase/10104779" target={"_blank"}>
          <i className="material-icons">help</i>
        </NavLink>
        <XtUserProfileMenu show={false} />
      </XtNav>
    </XtNavBarContainer>
  );
}
