import React, { useContext, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { XtField } from "nwcommon";
import {
  APIFactory,
  Invoice,
  InvoiceApi,
  PurchaseOrderApi,
  UserApi,
} from "../../../../Xt/ApiClients";
import { NumberUtil } from "../../../../Xt/Utils/NumberUtil";
import FormLabelMutedText, {
  FormLabelMutedFullText,
  FormPoLink,
} from "../../Nw/PurchaseRequisition/Common/FormLabelMutedText";
import { DateUtil } from "../../../../Xt/Utils/DateUtil";
import FormField from "../../Nw/PurchaseRequisition/Common/FormField";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import { Redirect, Link } from "react-router-dom";
import { InvoiceApiService } from "../Common/InvoiceApiService";

export type InvoiceDetailsHeaderProps = {
  company?: any;
  invoice?: Invoice;
  vendorName?: string;
  onCodingAmountChanged?: any;
  loadAccountSpecificUsers: boolean;
  projectNumber?: string;
};

export function InvoiceDetailsHeader(props: InvoiceDetailsHeaderProps) {
  let init = true;
  let factory: APIFactory;

  const context: IXtContext = useContext(XtContext);

  const [companyName, setCompanyName] = React.useState<string>("");
  const [redirectTo, setRedirectTo] = useState("");
  const [reps, setReps] = React.useState<any[]>([]);

  const loadReps = () => {
    let userApi: UserApi = factory.create(UserApi);
    InvoiceApiService.getReps(
      userApi,
      context?.loggedOnUser?.username,
      props.loadAccountSpecificUsers
    ).then((data) => {
      setReps(data);
    });
  };

  React.useEffect(() => {
    if (
      context?.loggedOnUser?.username &&
      props?.loadAccountSpecificUsers != null
    ) {
      loadReps();
    }
  }, [context?.loggedOnUser?.username, props?.loadAccountSpecificUsers]);

  React.useEffect(() => {
    if (props.invoice?.companyCode && context?.loggedOnUser?.username) {
      let c: InvoiceApi = factory.create(InvoiceApi);
      c.invoiceCompanyOrgIdGet(
        context?.loggedOnUser?.username,
        props.invoice?.companyCode
      )
        .then(async (v) => {
          let a: any = await v.json();
          setCompanyName(a.companyName);
        })
        .finally(() => {});
    }
  }, [props.invoice?.companyCode, context?.loggedOnUser?.username]);

  const getVendorAddress = () => {
    let address = "";
    if (props?.invoice?.addressLine1) address += props?.invoice?.addressLine1;
    if (props?.invoice?.addressLine2)
      address += ", " + props?.invoice?.addressLine2;
    if (props?.invoice?.addressLine3)
      address += ", " + props?.invoice?.addressLine3;
    if (props?.invoice?.city) address += ", " + props?.invoice?.city;
    if (props?.invoice?.state) address += ", " + props?.invoice?.state;
    if (props?.invoice?.zip) address += ", " + props?.invoice?.zip;
    return address;
  };

  return (
    <>
      {redirectTo !== "" && <Redirect push to={redirectTo} />}
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <Row>
        <FormField label="Invoice ID.">
          <FormLabelMutedText value={props.invoice?.invoiceID} />
        </FormField>
        <FormField label="Invoice Date">
          <FormLabelMutedText
            value={DateUtil.formatmmDdYYY(props.invoice?.invoiceDate)}
          />
        </FormField>
      </Row>
      <Row>
        <FormField label="Created By">
          <FormLabelMutedText value={props?.invoice?.displayName} />
        </FormField>
        <FormField label="Created On">
          <FormLabelMutedText
            value={DateUtil.formatmmDdYYY(props.invoice?.createdOn)}
          />
        </FormField>
      </Row>

      <Row>
        <FormField label="Vendor Invoice No.">
          <FormLabelMutedText value={props.invoice?.vendorInvoiceNo} />
        </FormField>

        <FormField label="Company Name">
          <FormLabelMutedText value={companyName} />
        </FormField>
      </Row>
      <Row>
        <FormField label="PO Number">
          <FormPoLink poNumber={props.invoice?.poNumber}></FormPoLink>
        </FormField>
        <FormField label="Vendor Name">
          <FormLabelMutedText
            value={
              props.invoice?.vendorName &&
              `${props.invoice?.vendorName} (${props.invoice?.vendorCode})`
            }
          />
        </FormField>
      </Row>
      <Row>
        {props?.projectNumber !== "" && (
          <FormField label="Project Number">
            <FormLabelMutedText value={props?.projectNumber} />
          </FormField>
        )}
        {props?.projectNumber === "" && (
          <FormField label="Charge Type">
            <FormLabelMutedText value="Indirect" />
          </FormField>
        )}
        <FormField label="Vendor Address">
          <FormLabelMutedText value={getVendorAddress()} />
        </FormField>
      </Row>
      <Row>
        <FormField label=" Assigned Rep.">
          <FormLabelMutedText
            value={
              reps.find((r) => r.value == props.invoice?.representative)?.text
            }
          />
        </FormField>
        <FormField label=" Invoice Amount">
          <FormLabelMutedText
            value={NumberUtil.formatToTwoDecimalWithComma(
              props.invoice?.vendorAmount
            )}
          />
        </FormField>
      </Row>
      <Row>
        <FormField label=" Comments">
          <FormLabelMutedFullText value={props.invoice?.comment} />
        </FormField>
        <FormField label=" Description">
          <FormLabelMutedFullText value={props.invoice?.description} />
        </FormField>
      </Row>
      <Row>
        <FormField label="Voucher No.">
          <FormLabelMutedText value={props.invoice?.voucherNo} />
        </FormField>
      </Row>
    </>
  );
}
