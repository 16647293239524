import React, { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";

import {
  APIFactory,
  InvoiceApi,
  PurchaseOrderApi,
  AttachmentApi,
  UserApi,
} from "../../../../Xt/ApiClients";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import { XtField } from "nwcommon";
import { NumberUtil } from "../../../../Xt/Utils/NumberUtil";
import FormField, {
  FormFieldFullRow,
  RequiredFormField,
  RequiredFormFieldFullRow,
} from "../../Nw/PurchaseRequisition/Common/FormField";
import FormLabelMutedText from "../../Nw/PurchaseRequisition/Common/FormLabelMutedText";
import { InvoiceApiService } from "../Common/InvoiceApiService";
import { DateUtil } from "../../../../Xt/Utils/DateUtil";

export type AddInvoiceHeaderProps = {
  company?: any;
  purchaseOrder?: any;
  onPOChange: any;
  invoice?: any;
  selectedPONumber?: any;
  loadAccountSpecificUsers: boolean;
  selectedAccountRep?: any;
  setSelectedAccountRep: any;
};

type VendorAddress = {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  state: string;
  zip: string;
};

export function EditInvoiceHeader(props: AddInvoiceHeaderProps) {
  let init = true;
  let factory: APIFactory;
  const context: IXtContext = useContext(XtContext);
  const [prNumbers, setPRNumbers] = React.useState<string[]>([]);
  const [po, setPO] = React.useState<any>(null);
  const [invoice, setInvoice] = React.useState<any>(null);
  const [companyName, setCompanyName] = React.useState<string>("");
  const [vendorName, setVendorName] = React.useState<string>("");
  const [reps, setReps] = React.useState<any[]>([]);
  const [vendorAddress, setVendorAddress] = React.useState<
    VendorAddress | undefined
  >(undefined);

  React.useEffect(() => {
    if (props.invoice?.companyCode && context?.loggedOnUser?.username) {
      let c: InvoiceApi = factory.create(InvoiceApi);
      c.invoiceCompanyOrgIdGet(
        context?.loggedOnUser?.username,
        props.invoice?.companyCode
      )
        .then(async (v) => {
          let a: any = await v.json();
          setCompanyName(a.companyName);
        })
        .finally(() => {});
    }
  }, [props.invoice?.companyCode, context?.loggedOnUser?.username]);

  const loadPrNumber = () => {
    let c: InvoiceApi = factory.create(InvoiceApi);
    c.invoicePoNumbersGet(context?.loggedOnUser?.username).then(
      async (data) => {
        let d = [];
        for (let i in data) {
          d.push({ text: data[i], value: data[i] });
        }
        setPRNumbers(d);
      }
    );
  };

  React.useEffect(() => {
    if (props.invoice) {
      setInvoice(props.invoice);
      loadPrNumber();
    }
  }, [props.invoice]);

  const loadReps = () => {
    let userApi: UserApi = factory.create(UserApi);

    InvoiceApiService.getReps(
      userApi,
      context?.loggedOnUser?.username,
      props.loadAccountSpecificUsers
    ).then((data) => {
      setReps(data);
      const accRep = data.find(
        (item) => item.value === props?.invoice?.representative
      );
      props.setSelectedAccountRep(accRep);
    });
  };

  React.useEffect(() => {
    if (
      context?.loggedOnUser?.username &&
      props.loadAccountSpecificUsers != null
    ) {
      //  loadPrNumber();
      loadReps();
      init = false;
    }
  }, [context?.loggedOnUser?.username, props?.loadAccountSpecificUsers]);

  React.useEffect(() => {
    if (props.purchaseOrder) {
      setPO(props.purchaseOrder);
      setVendorAddress({
        addressLine1: props.purchaseOrder?.addressLine1,
        addressLine2: props.purchaseOrder?.addressLine2,
        addressLine3: props.purchaseOrder?.addressLine3,
        city: props.purchaseOrder?.city,
        state: props.purchaseOrder?.state,
        zip: props.purchaseOrder?.zip,
      });
    } else {
      setPO(null);
    }
    // setSelectedPONumber({ text: invoice?.poNumber, value: invoice?.poNumber  });
  }, [props.purchaseOrder]);

  const getVendorAddress = () => {
    if (!vendorAddress) return "";

    let address = "";
    if (vendorAddress.addressLine1) address += vendorAddress.addressLine1;
    if (vendorAddress.addressLine2)
      address += ", " + vendorAddress.addressLine2;
    if (vendorAddress.addressLine3)
      address += ", " + vendorAddress.addressLine3;
    if (vendorAddress.city) address += ", " + vendorAddress.city;
    if (vendorAddress.state) address += ", " + vendorAddress.state;
    if (vendorAddress.zip) address += ", " + vendorAddress.zip;
    return address;
  };

  React.useEffect(() => {
    if (
      !context ||
      !context?.loggedOnUser?.username ||
      !props.loadAccountSpecificUsers ||
      !po?.projectNumber ||
      !reps
    )
      return;
    var accountRep;
    async function getAccountRepByProjectNumber() {
      const res = await fetch(
        // `https://localhost:44319/Project/AccountRep/${po?.projectNumber}`,
        context?.commonBasePath + `/Project/AccountRep/${po?.projectNumber}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context?.tokens.accessToken}`,
          },
        }
      );
      if (res.status !== 200) return;

      const data = await res.json();
      accountRep = reps.find((x) => x.value === data?.accountRep.userGuid);
    }

    if (invoice?.representative) {
      accountRep = reps.find((x) => x.value === invoice?.representative);
    } else {
      getAccountRepByProjectNumber();
    }
    props.setSelectedAccountRep(accountRep);
  }, [
    context,
    context?.loggedOnUser?.username,
    context?.commonBasePath,
    context?.tokens.accessToken,
    props.loadAccountSpecificUsers,
    po?.projectNumber,
    reps,
  ]);

  const onAssignedRepChange = (e) => {
    if (!e?.value) props.setSelectedAccountRep(null);
    props.setSelectedAccountRep(e.value);
  };

  const onPoChange = (e) => {
    if (props?.onPOChange) {
      props?.onPOChange(e);
      props.setSelectedAccountRep(null);
    }
  };

  const getDate = (date) => {
    return date &&
      new Date(date).getFullYear() ==
        new Date("0001-01-01T00:00:00").getFullYear()
      ? undefined
      : new Date(date);
  };

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <Row>
        <FormField label="PO Number">
          <XtField
            type="dropdown"
            name="PONumber"
            required={true}
            items={prNumbers}
            textField="text"
            errormessage="Purchase Order Number is required."
            value={props?.selectedPONumber}
            onChange={onPoChange}
            disabled={props?.invoice?.poNumber ? true : false}
          />
        </FormField>

        {po?.projectNumber !== "" && (
          <FormField label="Project Number">
            <FormLabelMutedText value={po?.projectNumber} />
          </FormField>
        )}
        {po?.projectNumber === "" && (
          <FormField label="Charge Type">
            <FormLabelMutedText value="Indirect" />
          </FormField>
        )}

        <FormField label="Company Name">
          <FormLabelMutedText value={props?.purchaseOrder?.companyName} />
          <input
            type="hidden"
            name="CompanyCode"
            value={props?.purchaseOrder?.companyCode}
          />
        </FormField>

        <FormField label="Vendor Name">
          <FormLabelMutedText
            value={
              props.purchaseOrder?.vendorName &&
              `${props.purchaseOrder?.vendorName} (${props.purchaseOrder?.vendorID})`
            }
          />
          <input type="hidden" name="VendorCode" value={po?.vendorID} />
          <input type="hidden" name="VendorCode" value={invoice?.vendorCode} />
        </FormField>

        <FormField label="Invoice ID.">
          <FormLabelMutedText value={props.invoice?.invoiceID} />
        </FormField>

        <FormField label="Vendor Address">
          <FormLabelMutedText value={getVendorAddress()} />
        </FormField>

        <FormField label="Created On">
          <FormLabelMutedText
            value={DateUtil.formatmmDdYYY(props.invoice?.createdOn)}
          />
        </FormField>

        <RequiredFormField label="Vendor Invoice No.">
          <XtField
            type="text"
            name="VendorInvoiceNo"
            required={true}
            value={invoice?.vendorInvoiceNo}
            onChange={(e) => {
              setInvoice({ ...invoice, vendorInvoiceNo: e.target.value });
            }}
            errormessage="Invoice Number is required."
          />
        </RequiredFormField>

        <RequiredFormField label="Invoice Date">
          <XtField
            type="datepicker"
            name="InvoiceDate"
            weekNumber={false}
            required={true}
            value={getDate(invoice?.invoiceDate)}
            errormessage="Invoice Date is required."
          />
        </RequiredFormField>

        <RequiredFormField label="Invoice Amount">
          <XtField
            type="amount"
            name="VendorAmount"
            required={true}
            value={invoice?.vendorAmount}
            errormessage="Please enter vendor amount."
          />
        </RequiredFormField>

        <RequiredFormField label="Assigned Rep.">
          <XtField
            type="dropdown"
            name="Representative"
            items={reps}
            textField="text"
            required={true}
            errormessage="Please select a representative."
            onChange={onAssignedRepChange}
            value={props.selectedAccountRep}
          />
        </RequiredFormField>

        <FormField label="Description">
          <XtField
            type="textarea"
            name="Description"
            value={invoice?.description}
          />
        </FormField>

        <FormField label="Comments">
          <XtField type="textarea" name="Comment" value={invoice?.comment} />
        </FormField>

        <RequiredFormFieldFullRow label="Attachments">
          <XtField
            type="file"
            showtables={"true"}
            multiple={true}
            name="attachments"
            showdescription={false}
            files={invoice?.attachments}
            onChange={async (e) => {
              if (e.operation === "remove") {
                if (e.deletedFile?.attachmentGuid) {
                  let api: AttachmentApi = factory.create(AttachmentApi);
                  let result = await api.attachmentKeyDelete(
                    context?.loggedOnUser?.username,
                    e.deletedFile?.attachmentGuid
                  );
                }
              }
            }}
            //required={true}
            //errormessage="Attachments are required."
          />
        </RequiredFormFieldFullRow>
      </Row>
    </>
  );
}
