import { useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";

interface WorkflowReviewerCell {
  onAddReviewerClick: (arg0: any) => void;
  displayName: string;
  designee: string;
  isActive: boolean;
  isCompleted: boolean;
  isRejected: boolean;
  isApprover: boolean;
}

export default function NwWorkflowReviewerCell(props: WorkflowReviewerCell) {
  const context: IXtContext = useContext(XtContext);
  const onAddReviewerClick = (e) => {
    props.onAddReviewerClick(e);
  };

  const shouldAddReviewerIconVisible = () => {
    const isUserDisplayNameMatch =
      context?.loggedOnUser?.displayname === props?.displayName;
    const isUserDesigneeMatch =
      context?.loggedOnUser?.displayname === props?.designee;
    const isApprover = props?.isApprover === true;
    const isActive = props?.isActive === true;
    const isCompleted = props?.isCompleted === false;
    const isNotRejected = props?.isRejected === false;

    return (
      (isUserDisplayNameMatch || isUserDesigneeMatch) &&
      isApprover &&
      isActive &&
      isCompleted &&
      isNotRejected
    );
  };

  return (
    <td>
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={<Tooltip id={`tooltip-top`}>Add reviewer</Tooltip>}
      >
        <span role={"gridcell"}>
          {shouldAddReviewerIconVisible() && (
            <i
              onClick={(e) => onAddReviewerClick({ ...e, dataItem: props })}
              className="material-icons text-success"
            >
              add_circle_outline
            </i>
          )}
        </span>
      </OverlayTrigger>
    </td>
  );
}
