import React from "react";
import { Container } from "react-bootstrap";
import { APIFactory, AttachmentApi } from "../../../../../../Xt/ApiClients";



export default function DeleteAttachmentCell(props) {
  const attachmentGuid: string = props.dataItem["attachmentGuid"];
  const deleteAttachment = (attachmentGuid) => {
   // ;
     props.selectionChange({attachmentGuid:attachmentGuid});
  };

  return (
    <>
    
    <td>
      
        <span style={{"cursor":"pointer"}}
          className="k-icon k-i-delete"
          onClick={(e) => deleteAttachment({ attachmentGuid })}
        ></span>
      
    </td>
    </>
  );
}