import { Col, Form, Row } from "react-bootstrap";
import PopOverIcon from "./PopOverIcon";

export default function FormField(props) {
  return (
    <Row>
      <Form.Label column sm={4}>
        <div className="d-flex align-content-center p-2">
          {props.label}

          {props?.isRequired && <span className="text-danger me-1">*</span>}
          {props.info && <> {props.info}</>}
        </div>
        {props?.labelNotes && (
          <>
            <br />
            <span className="label-notes">{props.labelNotes}</span>
          </>
        )}
      </Form.Label>
      <Col className=" p-2 align-content-center" sm={8}>
        {props.children}
      </Col>
    </Row>
  );
}
export function SmallFormField(props) {
  return (
    <Row>
      <Col className=" p-2 align-content-center">{props.children} </Col>
    </Row>
  );
}
export function TwoFieldFormField(props) {
  return (
    <Row>
      <Form.Label column sm={4}>
        <div className=" align-content-center p-2">
          {props.label}

          {props?.isRequired && <span className="text-danger me-1">*</span>}
          {props.info && <> {props.info}</>}
        </div>
        {props?.labelNotes && (
          <>
            <br />
            <span className="label-notes">{props.labelNotes}</span>
          </>
        )}
      </Form.Label>
      <Col className="p-2" sm={4}>
        {props.children[0]}
      </Col>
      <Col className="p-2" sm={4}>
        {props.children[1]}
      </Col>
    </Row>
  );
}
export function RequiredFormField(props) {
  return (
    <FormField {...props} isRequired={true}>
      {props.children}
    </FormField>
  );
}

export function FormField3Column(props) {
  return (
    <Col sm={12} className="mb-3">
      <Row>
        <Form.Label column sm={4}>
          {props.label}
          {props?.isRequired && <span className="text-danger">*</span>}
        </Form.Label>
        <Col sm={8}>{props.children}</Col>
      </Row>
    </Col>
  );
}
export function FormFieldNoLabel(props) {
  return (
    <Col sm={12} className="mb-3">
      <Row>
        <Col sm={12}>{props.children}</Col>
      </Row>
    </Col>
  );
}

export function FormFieldFullRow(props) {
  return (
    <Col sm={12} className="mb-3">
      <Row>
        <Form.Label column sm={4}>
          <div className=" p-2">
            {props.label}
            {props.info && <> {props.info}</>}
            {props?.isRequired && <span className="text-danger">*</span>}
          </div>
        </Form.Label>
        <Col sm={8}>{props.children}</Col>
      </Row>
    </Col>
  );
}

export function RequiredFormFieldFullRow(props) {
  return (
    <FormFieldFullRow {...props} isRequired={true}>
      {props.children}
    </FormFieldFullRow>
  );
}

export function RequiredFormField3Column(props) {
  return (
    <FormField3Column {...props} isRequired={true}>
      {props.children}
    </FormField3Column>
  );
}

export function FormFieldWithNotes(props) {
  return (
    <Col md={6}>
      <Row className="mb-3">
        <Col sm={4} className="d-flex align-content-center">
          <Form.Label className="me-1">{props.label}</Form.Label>
          <PopOverIcon> {props.notes}</PopOverIcon>
        </Col>
        <Col sm={8}>{props.children}</Col>
      </Row>
    </Col>
  );
}

export function FormFieldWithParam(props) {
  return (
    <Col
      xs={props?.Form_xs}
      sm={props?.Form_sm}
      md={props?.Form_md}
      lg={props?.Form_lg}
      xl={props?.Form_xl}
      className="mb-3"
    >
      <Row className={props?.RowAlignment}>
        <Form.Label
          column
          xs={props?.Label_xs}
          sm={props?.Label_sm}
          md={props?.Label_md}
          lg={props?.Label_lg}
          xl={props?.Label_xl}
        >
          <div className="d-flex align-content-center">
            {props.label}

            {props?.isRequired && <span className="text-danger me-1">*</span>}
            {props.info && <> {props.info}</>}
          </div>
          {props?.labelNotes && (
            <>
              <br />
              <span className="label-notes">{props.labelNotes}</span>
            </>
          )}
        </Form.Label>
        <Col
          xs={props?.Control_xs}
          sm={props?.Control_sm}
          md={props?.Control_md}
          lg={props?.Control_lg}
          xl={props?.Control_xl}
        >
          {props.children}
        </Col>
      </Row>
    </Col>
  );
}

export function RequiredFormFieldWithParam(props) {
  return (
    <FormFieldWithParam {...props} isRequired={true}>
      {props.children}
    </FormFieldWithParam>
  );
}
