import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "react-bootstrap";
import KeyPersonnelAlert from "../../Nw/PurchaseRequisition/Common/KeyPersonnelAlert";

export enum EnConfirmationMessageType {
  "Wait",
  "Success",
  "Warning",
  "Error",
}
export interface LoadingDailogProps {
  show: boolean;
  messageType?: EnConfirmationMessageType;
  message?: string[];
  onClose?: any;
  title?: string;
  successMessageOnWarning?: string;
  alertMessageOnSuccess?: string;
  onCreateNew?: () => void;
  isInvoice?: boolean;
}

export default function LoadingDailog(props: LoadingDailogProps) {
  return (
    <Dialog
      onClose={() => props.onClose}
      {...props}
      width={"50%"}
      height={"50%"}
      title={props?.title ?? ""}
    >
      <div>
        {props.messageType === EnConfirmationMessageType.Wait && (
          <span>
            {" "}
            <div className="text-center">
              {" "}
              <h5>{props.message[0]}</h5>
            </div>
          </span>
        )}

        {props.messageType === EnConfirmationMessageType.Success && (
          <>
            <KeyPersonnelAlert alertMessage={props?.alertMessageOnSuccess} />
            {props?.message?.length > 0 && (
              <span>
                <p className="alert alert-success">{props?.message[0]}</p>
              </span>
            )}
          </>
        )}

        {props.messageType === EnConfirmationMessageType.Error && (
          <span>
            {" "}
            <p className="alert alert-danger">
              <ul style={{ listStyleType: "none" }}>
                {props?.message.map((e) => (
                  <li> {e} </li>
                ))}
              </ul>
            </p>
          </span>
        )}

        {props.messageType === EnConfirmationMessageType.Warning && (
          <span>
            {" "}
            {props?.successMessageOnWarning && (
              <>
                <p className="alert alert-success">
                  {" "}
                  {props?.successMessageOnWarning ?? ""}{" "}
                </p>
              </>
            )}
            <p className="alert alert-warning">
              <p>Warning!</p>
              <ul style={{ listStyleType: "none" }}>
                {props?.message.map((e) => (
                  <li> {e} </li>
                ))}
              </ul>
            </p>
          </span>
        )}

        {props.messageType === EnConfirmationMessageType.Wait && (
          <div className="k-loading-mask">
            <span className="k-loading-text"></span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
          </div>
        )}
      </div>

      {props.messageType !== EnConfirmationMessageType.Wait && props.isInvoice && (
        <DialogActionsBar layout="end">
          <Button type="button" variant="secondary" onClick={props.onClose}>
            Back to List
          </Button>
          <Button type="button" variant="primary" onClick={props.onCreateNew}>
            Create New
          </Button>
        </DialogActionsBar>
      )}

      {props.messageType !== EnConfirmationMessageType.Wait &&
        !props.isInvoice && (
          <DialogActionsBar layout="end">
            <Button type="button" variant="secondary" onClick={props.onClose}>
              Ok
            </Button>
          </DialogActionsBar>
        )}
    </Dialog>
  );
}
