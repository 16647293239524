import React from "react";
import * as Search from "nwcommon";
import { XtAdvancedSearchFieldCondition } from "nwcommon";
import { XtAdvancedSearchBox } from "nwcommon";
import { XtExport, XtExportType } from "nwcommon";
import { XtExportToolbar } from "nwcommon";
import { GridColumn, GridToolbar } from "@progress/kendo-react-grid";

import { XtGrid, XtGridRowSelection } from "nwcommon";
import { Loading } from "../../Xt/Layout/Loading";
import { XtAddEditGroupView } from "./Dialogs/Group/AddEditGroup";
import { ConfirmDialog } from "nwcommon";
import { XtGroupPermissionView } from "./Dialogs/Group/GroupPermission";
import { XtGroupAssignmentView } from "./Dialogs/Group/GroupAssignment";
import { ErrorHandler } from "../../Xt/Layout/ErrorHandler";
import { IXtContext, XtContext } from "../../Xt/Contexts/XtContext";
import { XtPaging } from "../../Xt/ApiClients/ApiHelper";
import { APIFactory, Group, GroupApi } from "../../Xt/ApiClients";

interface XtExportFileType {
  type: XtExportType;
  fields: any[];
  filename: string;
}

export function XtGroup(props) {
    const pageSize = 15;
    const orderByField = "GroupName";
    const init = true;
    const [expType, setExportType] = React.useState<XtExportFileType>({
        type: "none",
        fields: [],
        filename: "",
    });
    const [data, setData] = React.useState<any[]>([]);
    const [reportData, setReportData] = React.useState<any[]>([]);
    const [dataCount, setDataCount] = React.useState<number>(0);
    const [dialogTitle, setDialogTitle] = React.useState<string>("");
    const [isExported, setIsExported] = React.useState<boolean>(false);
    const [paging, setPaging] = React.useState<XtPaging>({
        skip: 0,
        top: pageSize,
        sort: { field: orderByField, dir: "asc" },
    });
    const [searchParams, setSearchParam] = React.useState<any>({});
    const [gridSelection, setGridSelection] = React.useState<XtGridRowSelection>({
        headerSelected: false,
        differedData: [],
    });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showDialog, setShowDialog] = React.useState<boolean>(false);
    const [time, setTime] = React.useState<number>(0);
    const [editingGroup, setEditingGroup] = React.useState<any>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] =
        React.useState<boolean>(false);
    const [showGroupPermission, setShowGroupPermission] =
        React.useState<boolean>(false);
    const [showGroupAssignment, setShowGroupAssignment] =
        React.useState<boolean>(false);

    let factory: APIFactory;
    const context: IXtContext = React.useContext(XtContext);

    const schema = [
        {
            field: "groupName",
            label: "Name",
            visible: true,
            searchable: true,
            type: "text",
            width: "200px",
        },
        {
            field: "description",
            label: "Description",
            visible: true,
            searchable: true,
            type: "text",
            //   width: "400px",
        },
    ];

    React.useEffect(() => {
        if (context?.loggedOnUser?.username) {
            let s: GroupApi = factory.create(GroupApi);
            setLoading(true);
            s.groupGet(
                context?.loggedOnUser?.username,
                searchParams?.searchString,
                searchParams?.oDataQuery,
                paging.top,
                paging.skip,
                pageSize,
                paging.sort.field + " " + paging.sort.dir
            )
                .then(async (data) => {
                    var d1 = await data.json();
                    setDataCount(d1["@odata.count"]);
                    setData(d1.value);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

    }, [
        init,
        factory,
        paging.top,
        paging.skip,
        pageSize,
        paging.sort,
        searchParams?.oDataQuery,
        searchParams?.searchString,
        context?.loggedOnUser?.username
    ]);

    React.useEffect(() => {
        let s: GroupApi = factory.create(GroupApi);
        setLoading(true);
        s.groupGet(
            context?.loggedOnUser?.username,
            searchParams?.searchString,
            searchParams?.oDataQuery,
            paging.top,
            paging.skip,
            pageSize,
            paging.sort.field + " " + paging.sort.dir
        )
            .then(async (data) => {
                var d1 = await data.json();
                setDataCount(d1["@odata.count"]);
                setData(d1.value);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [paging, searchParams, time, factory]);

    let searchFields: XtAdvancedSearchFieldCondition[] = [];

    const getAdvancedSearchSelectedValue = (field) => {
        let returnValue = null;
        searchParams.advancedSearchCondition?.map((v, i) => {
            if (field.field === v.field.field) {
                returnValue = v;
            }
        });
        return returnValue;
    };

    schema.map((v, i) => {
        let c = getAdvancedSearchSelectedValue(v);
        if (v.type === "text" && v.searchable === true) {
            searchFields.push(
                Search.getTextboxType(
                    "country",
                    v.field,
                    v.label,
                    c?.value,
                    c?.operator.value,
                    c?.value2
                )
            );
        }
    });

    const onSearchCondition = (e) => {
        setSearchParam(e);
    };

    const onExportButtonClick = (e) => {
        let s: GroupApi = factory.create(GroupApi);
        if (gridSelection.headerSelected) {
            setLoading(true);
            s.groupGet(
                context?.loggedOnUser?.username,
                searchParams?.searchString,
                searchParams?.oDataQuery,
                undefined,
                undefined,
                undefined,
                paging.sort.field + " " + paging.sort.dir
            )
                .then(async (data) => {
                    var d1 = await data.json();
                    if (gridSelection.differedData.length === 0) setReportData(d1.value);
                    else {
                        let data: any[] = d1.value;
                        let unSelected: any[] = [];
                        let printData: any[] = [];

                        gridSelection.differedData.map((v: Group, i) => {
                            unSelected.push(v.groupGuid);
                        });

                        data.map((v: Group, i) => {
                            if (unSelected.indexOf(v.groupGuid) === -1) {
                                printData.push(v);
                            }
                        });
                        setReportData(printData);
                    }
                    setExportType({
                        type: e.type,
                        fields: e.dataItems,
                        filename: "test." + (e.type === "pdf" ? "pdf" : "xlsx"),
                    });
                    setIsExported(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setReportData(gridSelection.differedData);
            setExportType({
                type: e.type,
                fields: e.dataItems,
                filename: "test." + (e.type === "pdf" ? "pdf" : "xlsx"),
            });
            setIsExported(false);
        }
    };

    const renderGridFields = () => {
        let items: any[] = [];
        schema.map((v, i) => {
            if (v.visible) {
                items.push(
                    <GridColumn
                        sortable={true}
                        key={"row" + i}
                        field={v.field}
                        title={v.label}
                        width={v.width}
                    />
                );
            }
        });
        return items;
    };

    const onPageChange = (e) => {
        setPaging({ ...paging, skip: e.page.skip, top: e.page.take });
    };

    const onSortChange = (e) => {
        if (e.sort.length > 0) setPaging({ ...paging, sort: e.sort[0] });
        else {
            setPaging({
                ...paging,
                sort: {
                    ...paging.sort,
                    dir: paging.sort.dir === "asc" ? "desc" : "asc",
                },
            });
        }
    };

    const onExportAfterRender = (e) => {
        if (e && !isExported) {
            e.save();
            setIsExported(true);
        }
    };

    const onEditClick = (e) => {
        setEditingGroup(e.dataItem);
        setDialogTitle("Edit Group");
        setShowDialog(true);
    };

    const onDeleteClick = (e) => {
        setEditingGroup(e.dataItem);
        setShowDeleteConfirmation(true);
    };

    const onDeleteConfirm = async (e) => {
        let s: GroupApi = factory.create(GroupApi);
        let result = await factory.callAPI(
            s.groupKeyDelete(editingGroup.groupGuid)
        );
        if (result.ok) {
            setShowDeleteConfirmation(false);
            setEditingGroup({});
            setTime(new Date().getTime());
        } else {
            let d = await result.json();
            factory.showErrorMessage(d.message);
        }
    };

    const onGridSelectionChange = (e) => {
        setGridSelection(e);
    };

    const onPermissionClick = (e) => {
        setEditingGroup(e.dataItem);
        setShowGroupPermission(true);
    };

    const onAssignmentClick = (e) => {
        setEditingGroup(e.dataItem);
        setShowGroupAssignment(true);
    };

    const rowMenus = [
        {
            text: "Permissions",
            icon: "k-icon k-i-wrench",
            onClick: onPermissionClick,
        },
        {
            text: "Assign User",
            icon: "k-icon k-i-module-manager",
            onClick: onAssignmentClick,
        },
        { text: "Edit", icon: "k-icon k-i-edit k-i-pencil", onClick: onEditClick },
        {
            text: "Delete",
            icon: "k-icon k-i-delete k-i-trash",
            onClick: onDeleteClick,
        },
    ];

    const onAddGroup = (e) => {
        setDialogTitle("Add Group");
        setShowDialog(true);
    };

    const onCancelDelete = (e) => {
        setEditingGroup({});
        setShowDeleteConfirmation(false);
    };

    const onCancelGroupPermission = (e) => {
        setEditingGroup({});
        setShowGroupPermission(false);
    };

    const onCancelGroupAssignment = (e) => {
        setEditingGroup({});
        setShowGroupAssignment(false);
    };

    return (
        <ErrorHandler>
            <div>
                <APIFactory
                    ref={(e) => {
                        factory = e;
                    }}
                />

                <GridToolbar>
                    <div className="page-toolbar">
                        <XtAdvancedSearchBox
                            searchPlaceHolder="Search"
                            hideAdvancedSearch={false}
                            searchConditions={searchFields}
                            onSearchConditionApply={onSearchCondition}
                        />
                        <div className="ms-2">
                            <XtExportToolbar
                                fields={searchFields}
                                menuItems={[
                                    {
                                        text: "Add Group",
                                        icon: "plus",
                                        onClick: (e) => {
                                            onAddGroup(e);
                                        },
                                    },
                                ]}
                                onApply={onExportButtonClick}
                                gridSelection={gridSelection}
                            />
                        </div>
                    </div>
                </GridToolbar>
                <XtExport
                    type={expType.type}
                    data={reportData}
                    schema={expType.fields}
                    fileName={expType.filename}
                    onAfterRender={onExportAfterRender}
                >
                    <XtGrid
                        uniqueField="countryId"
                        data={data}
                        total={dataCount}
                        sort={[paging.sort]}
                        rowMenus={rowMenus}
                        sortable={true}
                        skip={paging.skip}
                        pageSize={pageSize}
                        pageable={true}
                        onSortChange={onSortChange}
                        onPageChange={onPageChange}
                        onGridSelectionChange={onGridSelectionChange}
                        className="settings-groups_list"
                    >
                        {renderGridFields()}
                    </XtGrid>
                </XtExport>
                {showDialog && (
                    <XtAddEditGroupView
                        title={dialogTitle}
                        dataItem={editingGroup}
                        onClose={(e) => {
                            setShowDialog(false);
                            setEditingGroup({});
                            if (e.reloadData && e.reloadData === true) {
                                setTime(new Date().getTime());
                            }
                        }}
                    />
                )}
                {loading && <Loading />}
                {showDeleteConfirmation && (
                    <ConfirmDialog
                        message="Do you really want to delete this group?"
                        onClose={onCancelDelete}
                        onNo={onCancelDelete}
                        onYes={onDeleteConfirm}
                        title="Confirm Delete"
                        width="50%"
                    />
                )}
                {showGroupPermission && (
                    <XtGroupPermissionView
                        onClose={onCancelGroupPermission}
                        title="Group Permissions"
                        dataItem={editingGroup}
                    />
                )}
                {showGroupAssignment && (
                    <XtGroupAssignmentView
                        onClose={onCancelGroupAssignment}
                        title="Assign User to Group"
                        dataItem={editingGroup}
                    />
                )}
            </div>
        </ErrorHandler>
    );
}
