import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useContext, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Accordion, Button, Row } from "react-bootstrap";
import {
  APIFactory,
  Invoice,
  InvoiceApi,
  InvoiceApproval,
  PurchaseOrderApi,
  RequisitionPermission,
  InvoiceIntegration,
} from "../../../../Xt/ApiClients";
import { APIFactoryCommon } from "../../../../Xt/ApiClients/Common";
import { Loading } from "../../../../Xt/Layout/Loading";
import { XtBreadcrumbs } from "../../../../Xt/Layout/XtBreadcrumbs";
import { InvoiceCPIntegrationInvoiceCoding } from "./InvoiceCPIntegrationInvoiceCoding";
import { InvoiceCPIntegrationReviewer } from "./InvoiceCPIntegrationReviewer";
import { InvoiceCPIntegrationHeader } from "./InvoiceCPIntegrationHeader";
import { XtField } from "nwcommon";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import LoadingDailog, {
  EnConfirmationMessageType,
  LoadingDailogProps,
} from "../../Dialogs/Common/LoadingDailog";
import { ErrorUtil } from "../../../../Xt/Utils/ErrorUtil";
import XtAccordianItem from "../../Nw/PurchaseRequisition/Common/XtAccordianItem";
import FormField, {
  RequiredFormField,
} from "../../Nw/PurchaseRequisition/Common/FormField";
import { PoCostDetails } from "../Common/PoCostDetails";
import ErrorDialog from "../../Dialogs/Common/ErrorDialog";
import FormLabelMutedText from "../../Nw/PurchaseRequisition/Common/FormLabelMutedText";
import { InvoiceConstants } from "../Common/InvoiceConstants";
import { NumberUtil } from "../../../../Xt/Utils/NumberUtil";
import { useMutation } from "@tanstack/react-query";
import { saveInvoiceWithVoucherNo } from "../../../../api/react-query";

const breadCrumbs = {
  breadCrumbs: [
    {
      href: "/invoice",
      text: "Invoice List",
      isActive: false,
    },
    {
      href: "/pr/create",
      text: "Costpoint Integration Details",
      isActive: true,
    },
  ],
};

export function InvoiceCPIntegration(props: any) {
  let factory: APIFactory;
  let commonApifactory: APIFactoryCommon;
  const context: IXtContext = useContext(XtContext);
  const [redirectTo, setRedirectTo] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const dialogRedirectTo = { shouldRedirect: false, url: "" };
  const [dialogloading, setDialogLoading] = useState<LoadingDailogProps>({
    show: false,
  });

  const [invoiceId, setInvoiceId] = useState<string>("");
  const [po, setPO] = useState<any>(null);
  const [invoice, setInvoice] = useState<Invoice>(null);
  const [runningCost, setRunningCost] = useState<number>(0);
  const [reviewers, setReviewers] = useState<InvoiceApproval[]>(null);
  const [permission, setPermission] = useState<RequisitionPermission>(null);
  const [company, setCompany] = useState<any>(null);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [allowToAdd, setAllowToAdd] = useState<boolean>(false);
  const [invCodeRunningTotals, setInvCodeRunningTotals] = useState<any>({});
  const [invCodingsOriginalAmounts, setInvCodingsOriginalAmounts] =
    useState<any>([]);
  const [errors, setErrors] = useState([]);
  const [loadAccountSpecificUsers, setLoadAccountSpecificUsers] =
    useState(null);

  let years: any[] = [];
  let initialYear = 2022;
  let finalYear = new Date().getFullYear();
  finalYear++;
  for (let i = finalYear; i >= initialYear; i--) {
    years.push({
      text: i.toString(),
      value: i.toString(),
    });
  }

  let months: any[] = [];
  for (let i = 1; i <= 12; i++)
    months.push({ text: i.toString(), value: i.toString() });

  let { id } = useParams();

  const [fy, setFy] = useState<any>({
    text: new Date().getFullYear().toString(),
    value: new Date().getFullYear().toString(),
  });
  // const [mn, setMonth] = useState<any>({ text: "1", value: "1" });
  const [mn, setMonth] = useState<any>({
    text: (new Date().getMonth() + 1).toString(),
    value: (new Date().getMonth() + 1).toString(),
  });
  const [dt, setDate] = useState<string>("1");

  useEffect(() => {
    if (id !== "") {
      setInvoiceId(id);
      // setFy(years[1]);
      // setMonth(months[1]);
      setDate("1");
    }
  }, [id]);

  useEffect(() => {
    let s: InvoiceApi = factory.create(InvoiceApi);
    if (invoiceId && context?.loggedOnUser?.username) {
      loadInvoice();
    }
  }, [invoiceId, context?.loggedOnUser?.username]);

  const saveInvoiceWithVoucherNoMutation = useMutation(
    saveInvoiceWithVoucherNo,
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        onSaveSuccess(
          [
            `Invoice has been saved successfully with Voucher No. ${invoice.voucherNo}.`,
          ],
          "Success"
        );
      },
      onError: (error) => {
        console.log(error);
        setDailogError(["Internal Server Error. Please contact support."]);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const loadInvoice = () => {
    if (invoiceId && context?.loggedOnUser?.username) {
      let c: InvoiceApi = factory.create(InvoiceApi);
      const poApi: PurchaseOrderApi =
        factory.create<PurchaseOrderApi>(PurchaseOrderApi);
      setLoading(true);
      c.invoiceIntegrationAuthInvoiceGuidGet(
        invoiceId,
        context?.loggedOnUser?.username
      )
        .then(async (value: Response) => {
          var d2 = await value.json();
          setAllowToAdd(d2.result);
          if (d2.result === false) {
            window.location.replace("/Error403");
          } else {
            c.invoiceInvoiceGuidGet(
              invoiceId,
              context?.loggedOnUser?.username
            ).then(async (data) => {
              let inv: any = data;
              setInvCodingsOriginalAmounts(inv?.invoiceCodings);
              loadPO(inv, poApi);
            });
          }
        })
        .catch((e) => {
          console.log("Inside Catch");
          if (e.status === 401 || e.status === 403) {
            window.location.replace("/Error403");
          }
        })
        .finally(() => {
          setLoading(false);
        });
      loadReviewers();
    }
  };

  const loadPO = (invoiceFromApi, poApi) => {
    setLoading(false);
    let loadSpecificUser = false;
    poApi
      .purchaseOrderPoIDGet(
        invoiceFromApi?.poNumber,
        context?.loggedOnUser?.username
      )
      .then(async (result) => {
        let nPO: any = result;
        setPO(result);
        const invCodings = invoiceFromApi?.invoiceCodings?.map((i) => {
          const poLineItem = nPO?.poLineItemList?.find(
            (poli) => poli?.lineNumber === i?.poLineNumber
          );
          if (
            InvoiceConstants.AccountCodesForRepFilters.includes(
              poLineItem.accountType
            )
          )
            loadSpecificUser = true;

          return {
            ...i,
            amount: i?.amount,
            committedAmount: poLineItem?.committedAmount,
            costToDateAmount: poLineItem?.costToDateAmount,
            runningTotal: poLineItem?.runningTotal,
            remainingAmount:
              poLineItem?.committedAmount - poLineItem?.runningTotal,
            description: i?.description,
            poLineDescription: poLineItem?.lineDescription,
          };
        });
        setLoadAccountSpecificUsers(loadSpecificUser);
        setInvoice({ ...invoiceFromApi, invoiceCodings: invCodings });
        setRunningCost(nPO.runningTotal);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadReviewers = () => {
    let c: InvoiceApi = factory.create(InvoiceApi);

    c.invoiceReviewersInvoiceGuidGet(context?.loggedOnUser?.username, invoiceId)
      .then(async (data) => {
        setReviewers(data);
      })
      .finally(() => {});

    c.invoicePermissionInvoiceGuidGet(
      context?.loggedOnUser?.username,
      invoiceId
    )
      .then(async (v) => {
        setPermission(v);
      })
      .finally(() => {});
  };

  const onCancelClicked = (e) => {
    setRedirectTo("/invoice");
  };

  const onSaveClicked = () => {
    if (!invoice.voucherNo) {
      setErrors(["Please enter a voucher number."]);
      return;
    }

    const submitErrors = [];
    let total = 0;

    invoice.invoiceCodings.forEach((c) => {
      total = NumberUtil.decimalNumSum(total, c.amount);
    });

    if (
      Number(total) !==
      Number(NumberUtil.formatToTwoDecimal(invoice.vendorAmount))
    ) {
      submitErrors.push(
        "Total invoice coding amount is not equal to invoice amount."
      );
      setErrors(submitErrors);
      return;
    }

    const model: InvoiceIntegration = {
      subPeriod: dt,
      month: mn.value,
      year: fy.value,
      pushToCostPoint: false,
      invoice: invoice,
    };

    saveInvoiceWithVoucherNoMutation.mutate({
      token: context?.tokens.accessToken,
      loggedOnUserName: context?.loggedOnUser?.username,
      invoiceGuid: invoice.invoiceGuid,
      invoice: model,
    });
  };

  const onSubmitClicked = async (e) => {
    const submitErrors = [];
    var total = 0;

    invoice.invoiceCodings.map((c, i) => {
      total = NumberUtil.decimalNumSum(total, c.amount);

      // if (c.amount > c.committedAmount)
      //   submitErrors.push(
      //     `#${c.sno}: Invoice amount cannot be more than committed amount.`
      //   );
    });
    // if (runningCost > po?.totalAmount) {
    //   submitErrors.push(
    //     "PO running total cannot be more than PO committed amount."
    //   );
    // }

    if (
      Number(total) !==
      Number(NumberUtil.formatToTwoDecimal(invoice.vendorAmount))
    ) {
      submitErrors.push(
        "Total invoice coding amount is not equals to invoice amount."
      );
    }
    if (submitErrors.length > 0) {
      setErrors(submitErrors);
      return;
    }

    setDialogLoading({
      show: true,
      message: ["Please wait for costpoint integration....."],
      messageType: EnConfirmationMessageType.Wait,
    });
    var c: InvoiceApi = factory.create(InvoiceApi);

    var model: InvoiceIntegration = {
      subPeriod: dt,
      month: mn.value,
      year: fy.value,
      pushToCostPoint: true,
      invoice: invoice,
    };

    try {
      var result = await c.invoiceInvoiceGuidIntegrationPut(
        id,
        context?.loggedOnUser?.username,
        model
      );
      if (result.ok) {
        let poResponseData = await result.json();
        if (poResponseData.isSucceed) {
          const voucherNumber = poResponseData.returnValue;
          setDailogSuccess([
            "Invoice has been integrated with voucher number." + voucherNumber,
          ]);
        } else {
          //partial success
          if (poResponseData.returnValue)
            setDailogWarning(
              ErrorUtil.getErrorMessageFromCostPointResponse(
                poResponseData.responseMsg
              )
            );
          else
            setDailogError(
              ErrorUtil.getErrorMessageFromCostPointResponse(
                poResponseData.responseMsg
              )
            );
        }
      } else {
        setDailogError([
          "Costpoint Server Might not be available, please try again later.",
        ]);
      }
    } catch (error) {
      setDailogError([
        "Costpoint Server Might not be available, please try again later.",
      ]);
    }
  };

  const setDailogWarning = (message: string[]) => {
    setDialogLoading({
      show: true,
      title: "Costpoint Integration Successful with warnings",
      messageType: EnConfirmationMessageType.Warning,
      message: message,
      onClose: (e) => {
        setRedirectTo("/");
        setDialogLoading({ show: false });
      },
    });
  };
  const setDailogSuccess = (message: string[]) => {
    setDialogLoading({
      show: true,
      message: message,
      title: "Costpoint Integration Successful",
      messageType: EnConfirmationMessageType.Success,
      onClose: (e) => {
        setRedirectTo("/");
        setDialogLoading({ show: false });
      },
    });
  };

  const onSaveSuccess = (message: string[], title: string) => {
    setDialogLoading({
      show: true,
      message: message,
      title: title,
      messageType: EnConfirmationMessageType.Success,
      onClose: () => {
        setRedirectTo("/");
        setDialogLoading({ show: false });
      },
    });
  };

  const setDailogError = (errorMessage: string[]) => {
    setDialogLoading({
      show: true,
      title: "Error",
      messageType: EnConfirmationMessageType.Error,
      message: errorMessage,
      onClose: (e) => {
        setDialogLoading({ show: false });
      },
    });
  };

  const onInvoiceLineItemChange = (e, row) => {
    if (e?.field === "amount") {
      let index = invoice.invoiceCodings.indexOf(row);
      let invCodingRunningTotalTemp = { ...invCodeRunningTotals };
      var runningTotal = invoice.invoiceCodings[index].runningTotal;
      const invoiceCodingAmount = parseFloat(e.value || 0);
      let lastAmount = invCodingsOriginalAmounts[index].amount;
      runningTotal = runningTotal + (invoiceCodingAmount - lastAmount);
      invoice.invoiceCodings[index].amount = invoiceCodingAmount;
      invCodingRunningTotalTemp[index] = runningTotal;
      setInvCodeRunningTotals(invCodingRunningTotalTemp);
      let totalRunningAmount = 0;
      invoice.invoiceCodings.map((v, i) => {
        totalRunningAmount =
          (invCodingRunningTotalTemp[i] ??
            invoice.invoiceCodings[i].runningTotal) + totalRunningAmount;
      });

      setRunningCost(totalRunningAmount);
    } else if (e?.field === "description") {
      let index = invoice.invoiceCodings.indexOf(row);
      //TODO:we need to uncomment once invoiceLineDescription is added to the invoiceCoding interface
      invoice.invoiceCodings[index].description = e.value || "";
    }
    setInvoice({ ...invoice, invoiceCodings: invoice.invoiceCodings });
  };

  const onAccountChange = (e, poLineNumber) => {
    let index = invoice.invoiceCodings.findIndex(
      (i) => i.poLineNumber === poLineNumber
    );
    invoice.invoiceCodings[index].accountNumber = e;
    setInvoice({ ...invoice, invoiceCodings: invoice.invoiceCodings });
  };

  const onAccountLoad = (acc, key) => {
    accounts[key] = acc;
    setAccounts(accounts);
  };

  const getCreatedBy = () => {
    if (invoice && reviewers) {
      const createdByReviewerRecord = reviewers.find(
        (r) => r?.createdBy === invoice?.createdBy
      );
      if (createdByReviewerRecord) {
        return createdByReviewerRecord?.displayName;
      }
    }

    return null;
  };

  const activeSections = ["0", "1", "2", "3", "4", "5"];
  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <APIFactoryCommon
        ref={(e) => {
          commonApifactory = e;
        }}
      />
      <XtBreadcrumbs {...breadCrumbs} />
      {redirectTo !== "" && <Redirect push to={{ pathname: redirectTo }} />}
      <div className="pr-create-new">
        <Accordion defaultActiveKey={activeSections} alwaysOpen>
          <XtAccordianItem header="INVOICE DETAIL" eventKey="0">
            <Row>
              <RequiredFormField label="Fiscal Period: Year">
                <XtField
                  type="dropdown"
                  name="Year"
                  required={true}
                  items={years}
                  textField="text"
                  errormessage="Purchase Order Number is required."
                  value={fy}
                  onChange={(e) => {
                    setFy(e.value);
                  }}
                />
              </RequiredFormField>

              <RequiredFormField label="Period">
                <XtField
                  type="dropdown"
                  name="Month"
                  required={true}
                  items={months}
                  value={mn}
                  textField="text"
                  errormessage="Purchase Order Number is required."
                  onChange={(e) => {
                    setMonth(e.value);
                  }}
                />
              </RequiredFormField>

              <RequiredFormField label="Sub Period">
                <XtField
                  type="numeric"
                  name="date"
                  required={true}
                  errormessage="Please enter sub period."
                  value={dt}
                  max="31"
                  min="1"
                  onChange={(e) => {
                    let dt = e.target.value;
                    if (parseInt(dt) > 0 && parseInt(dt) < 32) {
                      setDate(dt.toString());
                    } else {
                      setDate("1");
                    }
                  }}
                />
              </RequiredFormField>
              <FormField label="Invoice ID.">
                <FormLabelMutedText value={invoice?.invoiceID} />
              </FormField>
            </Row>
            <InvoiceCPIntegrationHeader
              invoice={invoice}
              company={company}
              projectNumber={po?.projectNumber}
              loadAccountSpecificUsers={loadAccountSpecificUsers}
              createdBy={getCreatedBy()}
            />
          </XtAccordianItem>

          <XtAccordianItem header="PO Cost Details" eventKey="1">
            <PoCostDetails
              committedAmount={po?.totalAmount}
              costToDate={po?.costToDateAmount}
              runningTotalCost={runningCost}
            />
          </XtAccordianItem>
          <XtAccordianItem header="INVOICE CODING" eventKey="2">
            <InvoiceCPIntegrationInvoiceCoding
              data={invoice?.invoiceCodings}
              accounts={accounts}
              orgId={company?.orgId}
              onInvoiceLineItemChange={onInvoiceLineItemChange}
              onAccountChange={onAccountChange}
              onAccountLoad={onAccountLoad}
              invCodeRunningTotals={invCodeRunningTotals}
            />
          </XtAccordianItem>
          <XtAccordianItem header="REVIEWER DETAIL" eventKey="3">
            <InvoiceCPIntegrationReviewer data={reviewers} isApprover={true} />
          </XtAccordianItem>
        </Accordion>

        <DialogActionsBar layout="end">
          {allowToAdd && (
            <Button
              type="button"
              variant="primary"
              id="save"
              onClick={onSaveClicked}
              disabled={dialogloading?.show}
            >
              Save
            </Button>
          )}
          <Button
            type="button"
            variant="secondary"
            onClick={onCancelClicked}
            disabled={dialogloading?.show}
          >
            Cancel
          </Button>
          {allowToAdd && (
            <Button
              type="button"
              variant="primary"
              id="submit"
              onClick={onSubmitClicked}
              disabled={dialogloading?.show}
            >
              Submit To Costpoint
            </Button>
          )}
        </DialogActionsBar>

        {dialogloading && dialogloading.show && (
          <LoadingDailog {...dialogloading} />
        )}

        {loading && <Loading />}
        {errors.length > 0 && (
          <ErrorDialog errorMessage={errors} onClose={(e) => setErrors([])} />
        )}
      </div>
    </>
  );
}
