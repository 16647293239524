import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect, useParams } from "react-router-dom";

import {
  Accordion,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Container,
  Dropdown,
  FormGroup,
  Row,
} from "react-bootstrap";
import { XtBreadcrumbs } from "../../../../../Xt/Layout/XtBreadcrumbs";
import { XtForm } from "nwcommon";

import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  APIFactory,
  PurchaseItem,
  PurchaseRequisition,
  PurchaseRequisitionApi,
} from "../../../../../Xt/ApiClients";
import { Loading } from "../../../../../Xt/Layout/Loading";
import { getConsole } from "@okta/okta-auth-js";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";

import NwVendorInfoReadOnly from "./VendorInfoReadOnly";
import NwPurchaseItemReadOnly from "./PurchaseItemReadOnly";
import NwAdditionalInfoReadOnly from "./AdditionalInfoReadOnly";

import XtAccordianItem from "../Common/XtAccordianItem";
import NwGeneralInfoReadOnly from "./GeneralnfoReadOnly";
import NwShippingPaymentReadOnly from "./ShippingPaymentReadOnly";
import { PrApiService } from "../Common/PrApiService";
import NwWorkflowReadOnly from "./WorkflowReadOnly";
import AuditLog from "../ApprovalView/AuditLog";
import ApproveRejectReviseDialog, {
  EnApprovalMode,
} from "./ApproveRejectReviseDialog";
import KeyPersonnelAlert from "../Common/KeyPersonnelAlert";
import { StringUtil } from "../../../../../Xt/Utils/StringUtil";

const breadCrumbs = {
  breadCrumbs: [],
};

export default function NwApprovalView(props) {
  const redirectTo = { shouldRedirect: false, url: "" };
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  let factory: APIFactory;
  const [loading, setLoading] = useState(false);
  const context: IXtContext = useContext(XtContext);
  const [redirect, setRedirect] = useState(redirectTo);
  const [pr, setPr] = useState(null);
  const [showApprovalDialog, setShowApprovalDialog] = useState(false);
  const [approvalMode, setApprovalMode] = useState(EnApprovalMode.NotSet);
  const [permission, setPermission] = useState(null);
  const [allowToIntegrate, setAllowToIntegrate] = useState<boolean>(false);
  const [inProgressWfForCurrentUser, setInProgressWfForCurrentUser] =
    useState(null);
  const [auditLogComment, setAuditLogComment] = useState([]);
  const [allowComment, setAllowComment] = React.useState<boolean>(false);

  const init = true;
  const activeSections = ["0", "2", "3", "4", "5"];

  let { id } = useParams();

  const onApprovalButtonClick = (approvalMode: EnApprovalMode) => {
    setApprovalMode(approvalMode);
    setShowApprovalDialog(true);
  };

  useEffect(() => {
    if (context?.loggedOnUser?.username) {
      let prApi: PurchaseRequisitionApi = factory.create(
        PurchaseRequisitionApi
      );
      setLoading(true);
      PrApiService.getPrByGuid(context, prApi, id)
        .then((prData) => {
          let pr: any = prData;
          setBreadCrumbs([
            {
              href: "/pr",
              text: "Purchase Requisitions ",
              isActive: false,
            },
            {
              href: "/pr/view",
              text: "" + pr?.prNumber,
              isActive: true,
            },
          ]);
          setPr(pr);
        })
        .finally(() => setLoading(false));

      PrApiService.getPermissionByPrGuid(context, prApi, id)
        .then((data) => {
          setPermission(data);
        })
        .finally(() => setLoading(false));

      prApi
        .purchaseRequisitionHasPurchaseRequisitionCommentPermissionPrGuidGet(
          id,
          context?.loggedOnUser?.username
        )
        .then((data) => data.json())
        .then((res) => setAllowComment(res.allowComment));

      prApi
        .purchaseRequisitionIntegrateAuthPrGuidGet(
          id,
          context?.loggedOnUser?.username
        )
        .then((d) => {
          setAllowToIntegrate(true);
        })
        .catch((e) => {
          setAllowToIntegrate(false);
        })
        .finally(() => setLoading(false));
    }
  }, [context?.loggedOnUser?.username]);

  const prStatusClassName = `px-3 py-2 fw-bold text-white d-inline-block  pr-status-${pr?.status
    .replace(" ", "-")
    .toLowerCase()}`;

  const bc = { breadCrumbs: breadCrumbs };

  const approvalDailogOnClose = () => {
    setShowApprovalDialog(false);

    if (permission.stage === 4 && approvalMode === EnApprovalMode.Approve) {
      if (
        pr?.companyCode === "0" &&
        pr?.totalAmount > 3500 &&
        pr?.chargeType === "Indirect" &&
        inProgressWfForCurrentUser &&
        inProgressWfForCurrentUser.level == 5
      )
        setRedirect({ shouldRedirect: true, url: "/" });
      else
        setRedirect({
          shouldRedirect: true,
          url: "/cp/landingpage/" + pr?.purchaseRequisitionGuid,
        });
    } else if (approvalMode === EnApprovalMode.Comment) {
      return;
    } else setRedirect({ shouldRedirect: true, url: "/" });
  };

  const canApproveReviseOrReject = () => {
    if (!permission) return false;
    if (pr?.status == "Rejected") return false;
    if (pr?.status.includes("Originator")) return false;
    if (permission.isApprover) return true;
    if (permission.isSAUser) return true;

    return false;
  };

  const canEdit = () => {
    if (!permission) return false;
    if (
      pr?.status.includes("Originator") &&
      permission.isEditable &&
      permission.isApprover
    )
      return true;
    return false;
  };

  return (
    <>
      {redirect.shouldRedirect && <Redirect push to={redirect.url} />}

      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />

      <div className="row mb-3 align-items-md-center">
        <div className="col">
          <div className="mb-3 mb-md-0">
            <XtBreadcrumbs {...bc} />
          </div>
        </div>
        <div className="col-auto">
          {pr?.status.toLocaleLowerCase() ==
            "waiting for cost point integration" &&
            allowToIntegrate && (
              <a
                href=""
                onClick={(e) =>
                  setRedirect({
                    shouldRedirect: true,
                    url: "/cp/landingpage/" + pr?.purchaseRequisitionGuid,
                  })
                }
              >
                <span className={prStatusClassName} role={"gridcell"}>
                  {pr?.status === null
                    ? ""
                    : StringUtil.replaceCostPoint(pr?.status)}
                </span>
              </a>
            )}
          {pr?.status.toLocaleLowerCase() !=
            "waiting for cost point integration" && (
            <span className={prStatusClassName} role={"gridcell"}>
              {pr?.status === null
                ? ""
                : StringUtil.replaceCostPoint(pr?.status)}
            </span>
          )}
          {pr?.status.toLocaleLowerCase() ===
            "waiting for cost point integration" &&
            !allowToIntegrate && (
              <span className={prStatusClassName} role={"gridcell"}>
                {pr?.status === null
                  ? ""
                  : StringUtil.replaceCostPoint(pr?.status)}
              </span>
            )}
        </div>

        <DialogActionsBar layout="end">
          {allowComment && (
            <Button
              type="button"
              variant="primary"
              id="submit"
              onClick={() => {
                onApprovalButtonClick(EnApprovalMode.Comment);
              }}
            >
              Add Comment
            </Button>
          )}

          {canApproveReviseOrReject() && (
            <>
              <Button
                type="button"
                name="btnlist"
                variant="warning"
                onClick={() => {
                  onApprovalButtonClick(EnApprovalMode.Revise);
                }}
              >
                Revise
              </Button>
              <Button
                type="button"
                variant="danger"
                onClick={() => {
                  onApprovalButtonClick(EnApprovalMode.Reject);
                }}
              >
                Reject
              </Button>
              <Button
                type="button"
                variant="primary"
                id="submit"
                onClick={() => {
                  onApprovalButtonClick(EnApprovalMode.Approve);
                }}
              >
                Approve
              </Button>
            </>
          )}

          {canEdit() && (
            <Button
              onClick={(e) => {
                setRedirect({
                  shouldRedirect: true,
                  url: `/pr/edit/${pr?.purchaseRequisitionGuid}`,
                });
              }}
            >
              Edit
            </Button>
          )}
        </DialogActionsBar>
        <div className="row mb-3 align-items-md-center"></div>
      </div>

      <div className="pr-approval-view">
        <KeyPersonnelAlert alertMessage={pr?.alertMsg} />
        <Accordion defaultActiveKey={activeSections} alwaysOpen>
          <XtAccordianItem header="REVIEWER DETAIL" eventKey="0">
            <NwWorkflowReadOnly
              prGuid={pr?.purchaseRequisitionGuid}
              isApprover={canApproveReviseOrReject()}
              inProgressWfForCurrentUser={setInProgressWfForCurrentUser}
              designatorList={permission?.designatorList}
            />
          </XtAccordianItem>

          <Accordion defaultActiveKey={activeSections} alwaysOpen={false}>
            <XtAccordianItem
              header="AUDIT LOG"
              eventKey="1"
              hide
              IsCollapsible={true}
            >
              <AuditLog
                auditLogComment={auditLogComment}
                prGuid={pr?.purchaseRequisitionGuid}
              />
            </XtAccordianItem>
          </Accordion>

          <XtAccordianItem header="GENERAL" eventKey="2">
            <NwGeneralInfoReadOnly pr={pr} />
          </XtAccordianItem>

          <XtAccordianItem header="VENDOR" eventKey="3">
            <NwVendorInfoReadOnly pr={pr} />
          </XtAccordianItem>

          <XtAccordianItem header="LINE ITEM" eventKey="4">
            <NwPurchaseItemReadOnly pr={pr} />
          </XtAccordianItem>
          {pr?.requisitionType == "Material" && (
            <XtAccordianItem header="SHIPPING/PAYMENT" eventKey="5">
              <NwShippingPaymentReadOnly pr={pr} />
            </XtAccordianItem>
          )}
          <XtAccordianItem header="ADDITIONAL" eventKey="6">
            <NwAdditionalInfoReadOnly pr={pr} />
          </XtAccordianItem>
        </Accordion>
        {canApproveReviseOrReject() && (
          <DialogActionsBar layout="end">
            <Button
              type="button"
              name="btnlist"
              variant="warning"
              onClick={() => {
                onApprovalButtonClick(EnApprovalMode.Revise);
              }}
            >
              Revise
            </Button>
            <Button
              type="button"
              variant="danger"
              onClick={() => {
                onApprovalButtonClick(EnApprovalMode.Reject);
              }}
            >
              Reject
            </Button>
            <Button
              type="button"
              variant="primary"
              id="submit"
              onClick={() => {
                onApprovalButtonClick(EnApprovalMode.Approve);
              }}
            >
              Approve
            </Button>
          </DialogActionsBar>
        )}

        {loading && <Loading />}
        {showApprovalDialog && (
          <ApproveRejectReviseDialog
            approvalMode={approvalMode}
            onPrimaryClick={approvalDailogOnClose}
            pr={pr}
            prGuid={id}
            prNumber={pr?.prNumber}
            currentLevel={pr?.currentLevel}
            onSecondaryClick={(e) => setShowApprovalDialog(false)}
            setAuditLogComment={setAuditLogComment}
          />
        )}
      </div>
    </>
  );
}
