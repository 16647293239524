import { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { XtField } from "nwcommon";
import {
  APIFactory,
  Invoice,
  InvoiceApi,
  UserApi,
} from "../../../../Xt/ApiClients";
import FormLabelMutedText from "../../Nw/PurchaseRequisition/Common/FormLabelMutedText";
import { DateUtil } from "../../../../Xt/Utils/DateUtil";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import FormField, {
  RequiredFormField,
} from "../../Nw/PurchaseRequisition/Common/FormField";
import { Link } from "react-router-dom";
import { InvoiceApiService } from "../Common/InvoiceApiService";
import PopOverIcon from "../../Nw/PurchaseRequisition/Common/PopOverIcon";

export type InvoiceCPIntegrationHeaderProps = {
  company?: any;
  invoice?: Invoice;
  vendorName?: string;
  projectNumber?: string;
  loadAccountSpecificUsers: boolean;
  createdBy: string;
};

type VendorAddress = {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  state: string;
  zip: string;
};

export function InvoiceCPIntegrationHeader(
  props: InvoiceCPIntegrationHeaderProps
) {
  let factory: APIFactory;
  const context: IXtContext = useContext(XtContext);
  const [companyName, setCompanyName] = useState<string>("");
  const [reps, setReps] = useState<any[]>([]);

  const invoice = props.invoice as Invoice & VendorAddress;
  let vendorAddress: VendorAddress | undefined = undefined;

  if (invoice) {
    vendorAddress = {
      addressLine1: invoice.addressLine1,
      addressLine2: invoice.addressLine2,
      addressLine3: invoice.addressLine3,
      city: invoice.city,
      state: invoice.state,
      zip: invoice.zip,
    };
  }

  const loadReps = () => {
    let userApi: UserApi = factory.create(UserApi);

    InvoiceApiService.getReps(
      userApi,
      context?.loggedOnUser?.username,
      props.loadAccountSpecificUsers
    ).then((data) => {
      //console.log("reps",data);
      setReps(data);
    });
  };

  useEffect(() => {
    if (
      (context?.loggedOnUser?.username, props?.loadAccountSpecificUsers != null)
    ) {
      loadReps();
    }
  }, [context?.loggedOnUser?.username, props?.loadAccountSpecificUsers]);

  useEffect(() => {
    if (props.invoice?.companyCode && context?.loggedOnUser?.username) {
      let c: InvoiceApi = factory.create(InvoiceApi);
      c.invoiceCompanyOrgIdGet(
        context?.loggedOnUser?.username,
        props.invoice?.companyCode
      )
        .then(async (v) => {
          let a: any = await v.json();
          setCompanyName(a.companyName);
        })
        .finally(() => {});
    }
  }, [props.invoice?.companyCode, context?.loggedOnUser?.username]);

  const getDate = (date) => {
    return date &&
      new Date(date).getFullYear() ==
        new Date("0001-01-01T00:00:00").getFullYear()
      ? undefined
      : new Date(date);
  };

  const onVendorInvoiceNoChange = (e) => {
    props.invoice.vendorInvoiceNo = e.target.value;
  };

  const onInvoiceDateChange = (e) => {
    props.invoice.invoiceDate = getDate(e.target.value);
  };

  const onRepresentativeChange = (e) => {
    if (!reps || !e.target.value) return;

    props.invoice.representative = reps.find(
      (c) => c.value === e.target.value.value
    );
  };

  const onDescriptionChange = (e) => {
    props.invoice.description = e.target.value;
  };

  const onCommentsChange = (e) => {
    props.invoice.comment = e.target.value;
  };

  const getVendorAddress = () => {
    if (!vendorAddress) return "";

    let address = "";

    if (vendorAddress.addressLine1) address += vendorAddress.addressLine1;
    if (vendorAddress.addressLine2)
      address += ", " + vendorAddress.addressLine2;
    if (vendorAddress.addressLine3)
      address += ", " + vendorAddress.addressLine3;

    if (vendorAddress.city) address += ", " + vendorAddress.city;
    if (vendorAddress.state) address += ", " + vendorAddress.state;
    if (vendorAddress.zip) address += ", " + vendorAddress.zip;

    return address;
  };

  const voucherNumberInfo = (
    <PopOverIcon>
      <div>
        <p>
          Please enter Costpoint generated Voucher No.. Click "Save" at the
          bottom of the page to save and update Invoice Status.
        </p>
      </div>
    </PopOverIcon>
  );

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <Row>
        <FormField label="Created By">
          <FormLabelMutedText value={props?.createdBy} />
        </FormField>
        <FormField label="Created On">
          <FormLabelMutedText
            value={DateUtil.formatmmDdYYY(props?.invoice?.createdOn)}
          />
        </FormField>

        <FormField label="PO Number">
          <span className="px-sm-3 w-100">
            <Link to={`/po/details/${props.invoice?.poNumber}`}>
              {" "}
              {props.invoice?.poNumber}
            </Link>
          </span>
        </FormField>

        {props.projectNumber !== "" && (
          <FormField label="Project Number">
            <FormLabelMutedText value={props?.projectNumber} />
          </FormField>
        )}
        {props.projectNumber === "" && <FormField></FormField>}

        <FormField label="Company Name">
          <FormLabelMutedText value={companyName} />
        </FormField>
        <FormField label="Vendor Name">
          <FormLabelMutedText value={props.invoice?.vendorName} />
        </FormField>
        <RequiredFormField label="Vendor Invoice No.">
          <XtField
            type="text"
            name="VendorInvoiceNo"
            required={true}
            value={props.invoice?.vendorInvoiceNo}
            errormessage="Invoice Number is required."
            onChange={onVendorInvoiceNoChange}
          />
        </RequiredFormField>
        <FormField label="Vendor Address">
          <FormLabelMutedText value={getVendorAddress()} />
        </FormField>
        {/* <FormField label="Invoice Date">
            <FormLabelMutedText
              value={DateUtil.formatmmDdYYY(props.invoice?.invoiceDate)}
            />
          </FormField> */}
        <RequiredFormField label="Invoice Date">
          <XtField
            type="datepicker"
            name="InvoiceDate"
            weekNumber={false}
            required={true}
            value={getDate(props.invoice?.invoiceDate)}
            errormessage="Invoice Date is required."
            onChange={onInvoiceDateChange}
          />
        </RequiredFormField>
        <FormField label="Invoice Amount">
          <FormLabelMutedText
            value={props.invoice?.vendorAmount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          />
        </FormField>
        <RequiredFormField label="Assigned Rep.">
          <XtField
            type="dropdown"
            name="Representative"
            items={reps}
            textField="text"
            required={true}
            errormessage="Please select a representative."
            defaultValue={
              reps.find((c) => c.value == props.invoice?.representative) || null
            }
            disabled={!reps || reps.length === 0}
            onChange={onRepresentativeChange}
          />
        </RequiredFormField>

        <FormField label="Description">
          {/* <FormLabelMutedText value={props.invoice?.description} /> */}
          <XtField
            type="textarea"
            name="Description"
            value={props.invoice?.description}
            onChange={onDescriptionChange}
          />
        </FormField>

        <FormField label="Comments">
          <XtField
            type="textarea"
            name="Comment"
            value={props.invoice?.comment}
            onChange={onCommentsChange}
          />
        </FormField>

        <FormField label="Voucher No." info={voucherNumberInfo}>
          <XtField
            type="text"
            name="VoucherNo"
            value={props.invoice?.voucherNo}
            onChange={(e) => {
              props.invoice.voucherNo = e.target.value;
            }}
          />
        </FormField>
      </Row>
    </>
  );
}
