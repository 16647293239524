import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import FormField from '../Common/FormField'
import { XtField,XtForm } from "nwcommon";
import { APIFactory,  GroupApi,  UserApi } from '../../../../../Xt/ApiClients';
import FormLabelMutedText from '../Common/FormLabelMutedText';
import { NwPrConstants } from '../Common/NwPrConstants';
import { IXtContext, XtContext } from '../../../../../Xt/Contexts/XtContext';




interface AddReviewerDailogProps {
  additionalReviewer : additionalReviewerLevel
  onClose :any,
  onAddClick:any
}

interface  additionalReviewerLevel {
 role :string,
 level:number,
 displayName: string 
}

export default function AddRerviewerDailog(props:AddReviewerDailogProps ) {
  let factory: APIFactory;
  const [users,setUser] = useState([]);
  
  const [lodingUser,setLoadingUser] = useState(false);
  const [selectedUser , setSelectedUser] =useState(null);
  const [selectedRole , setSelectedRole] =useState(null);
  const context: IXtContext = useContext(XtContext);
  const init =true;
    useEffect(() => {
        if (context?.loggedOnUser?.username) {
          setLoadingUser(true);
            let apiUser: UserApi = factory.create(
                UserApi
            );
            apiUser.userGet(context?.loggedOnUser.username).then(async (data) => {
                let dataFromApi = await data.json();
                if (dataFromApi && dataFromApi.value) {
                  //Filter out user.displayname string like HealthMailbox, portal from dataFromApi using a reg exp
                    let filteredUsers = dataFromApi.value.filter((user) => {
                        if(user.displayname !== context?.loggedOnUser?.displayname
                          // A regular expression that checjks if the string contains mailbox, portal, .net_dev_pool or (sa)
                           && !user.displayname.match(/HealthMailbox|portal|.net_dev_pool|(sa)/gi)){
                            return user;
                           }
                          }
                    );
                    //Sort the filtered users by displayname
                    filteredUsers = filteredUsers.sort((a, b) => a.displayname.localeCompare(b.displayname));
                    setUser(filteredUsers);
                }
            }).finally(() => { setLoadingUser(false) });
        }

    

  }, [context?.loggedOnUser?.username])

  const onSubmit = (e) => {
    props.onAddClick({
      ...e,
      data: { selectedUser: selectedUser, selectedRole: NwPrConstants.pr_additional_reviewer },
    });
  };


  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <Dialog
        title={`Add reviewer` }
        onClose={props.onClose}
        {...props}
        width={"60%"}
      >
        <XtForm onSubmit={onSubmit}>
          <FormField label="Reviewer">
            <XtField
              type="dropdown"
              name="users"
              items={users}
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.value)}
              required={true}
              textField="displayname"
              errormessage="User is required"
              loading={lodingUser}
            />
          </FormField>

          <FormField label="Role">
          <FormLabelMutedText value={NwPrConstants.pr_additional_reviewer} />
            
           
          </FormField>

          <DialogActionsBar layout={"end"}>
            <Button variant="secondary" onClick={props.onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Add
            </Button>
          </DialogActionsBar>
        </XtForm>
      </Dialog>
    </>
  );
}
