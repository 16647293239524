import React, { useContext, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { Accordion, Button, Form } from "react-bootstrap";
import { APIFactoryCommon } from "../../../../Xt/ApiClients/Common";
import {
  APIFactory,
  InvoiceApi,
  PurchaseOrderApi,
  Invoice,
  InvoiceApproval,
  RequisitionPermission,
} from "../../../../Xt/ApiClients";
import { Loading } from "../../../../Xt/Layout/Loading";
import { XtBreadcrumbs } from "../../../../Xt/Layout/XtBreadcrumbs";
import { InvoiceDataType } from "../InvoiceConstants";
import { InvoiceDetailsHeader } from "./InvoiceDetailsHeader";
import { InvoiceDetailsInvoiceCoding } from "./InvoiceDetailsInvoiceCoding";
import { InvoiceDetailsReviewer } from "./InvoiceDetailsReviewer";
import { EnApprovalMode } from "../../Nw/PurchaseRequisition/ApprovalView/ApproveRejectReviseDialog";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import AttachmentListReadOnly from "../../Nw/PurchaseRequisition/ApprovalView/AttachmentListReadOnly";
import InvoiceApproveRejectDialog from "./InvoiceApproveRejectDialog";
import InvoiceAuditLog from "./InvoiceAuditLog";
import XtAccordianItem from "../../Nw/PurchaseRequisition/Common/XtAccordianItem";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import { PoCostDetails } from "../Common/PoCostDetails";
import { InvoiceConstants } from "../Common/InvoiceConstants";
import { StringUtil } from "../../../../Xt/Utils/StringUtil";
import { NumberUtil } from "../../../../Xt/Utils/NumberUtil";
import { DateUtil } from "../../../../Xt/Utils/DateUtil";

export function InvoiceDetails(props: any) {
  const redirectTo = { shouldRedirect: false, url: "" };
  const context: IXtContext = useContext(XtContext);
  let factory: APIFactory;
  let factoryCommon: APIFactoryCommon;
  const [redirect, setRedirect] = useState(redirectTo);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<InvoiceDataType>({});
  const [po, setPO] = React.useState<any>(null);
  const [invoiceId, setInvoiceId] = React.useState<string>("");
  const [invoice, setInvoice] = React.useState<Invoice>(null);
  const [company, setCompany] = React.useState<any>(null);
  const [runningCost, setRunningCost] = React.useState<number>(0);
  const [reviewers, setReviewers] = React.useState<InvoiceApproval[]>(null);
  const [triggerCount, setTriggerCount] = React.useState<number>(0);
  const [permission, setPermission] =
    React.useState<RequisitionPermission>(null);
  const [approvalMode, setApprovalMode] = React.useState<EnApprovalMode>(
    EnApprovalMode.NotSet
  );
  const [approvalDialog, setShowApprovalDialog] =
    React.useState<boolean>(false);
  const [reloadInvoice, setReloadInvoice] = React.useState<boolean>(false);
  const [updateApproverTriggerCnt, setUpdateApprover] =
    React.useState<number>(0);
  const [breadCrumbs, setBreadCrumbs] = React.useState<any>(null);
  const [allowToAdd, setAllowToAdd] = React.useState<boolean>(false);
  const [invCodeRunningTotals, setInvCodeRunningTotals] = React.useState<any>(
    {}
  );
  const [invCodingsOriginalAmounts, setInvCodingsOriginalAmounts] =
    React.useState<any>([]);
  const [loadAccountSpecificUsers, setLoadAccountSpecificUsers] =
    useState(null);
  const [attachmentList, setAttachmentList] = useState([]);
  const [allowAttachmentDelete, setAllowAttachmentDelete] =
    React.useState<boolean>(false);
  const [allowAttachmentUpload, setAllowAttachmentUpload] =
    React.useState<boolean>(false);
  const [allowComment, setAllowComment] = React.useState<boolean>(false);
  const [auditLogComment, setAuditLogComment] = useState([]);

  const activeSections = ["0", "2", "3", "4", "5"];

  React.useEffect(() => {
    setInvoiceId(props.match?.params?.id);
    let b = {
      breadCrumbs: [
        {
          href: "/invoice",
          text: "Invoice List",
          isActive: false,
        },
        {
          href: "/invoice/details/" + props.match?.params?.id,
          text: "Invoice Details",
          isActive: true,
        },
      ],
    };
    setBreadCrumbs(b);
  }, [props.match?.params?.id]);

  const canApproveReviseOrReject = () => {
    if (!permission) return false;
    if (invoice?.status == "Rejected") return false;
    if (permission?.isApprover) return true;
    //if (permission?.isSAUser) return true;

    return false;
    //return true;
  };

  const canEdit = () => {
    if (!permission) return false;
    if (invoice?.status.includes("Originator") && permission.isEditable)
      return true;
    return false;
  };

  const onApprovalButtonClick = (approvalMode: EnApprovalMode) => {
    setApprovalMode(approvalMode);
    setShowApprovalDialog(true);
  };

  const loadInvoice = () => {
    if (invoiceId && context?.loggedOnUser?.username) {
      loadPermission();
      let c: InvoiceApi = factory.create(InvoiceApi);
      const poApi: PurchaseOrderApi =
        factory.create<PurchaseOrderApi>(PurchaseOrderApi);

      setLoading(true);
      c.invoicePermissionInvoiceGuidGet(
        context?.loggedOnUser?.username,
        invoiceId
      )
        .then(async (v) => {
          setPermission(v);
          if (v) {
            c.invoiceInvoiceGuidGet(
              invoiceId,
              context?.loggedOnUser?.username
            ).then(async (data) => {
              let inv: any = data;
              setAttachmentList(inv?.attachments);
              setInvCodingsOriginalAmounts(inv?.invoiceCodings);
              if (inv?.poNumber) {
                loadPO(poApi, inv);
              } else {
                setInvoice(inv);
              }
            });
          }
          //loadReviewers();
        })
        .catch((e) => {
          console.log(e);
          if (e.status === 403) {
            setLoading(false);
            setRedirect({ shouldRedirect: true, url: "/Error403" });
          }
        })
        .finally(() => {
          setLoading(false);
        });
      loadReviewers();
    }
  };

  const loadPermission = () => {
    let s: InvoiceApi = factory.create(InvoiceApi);
    if (context?.loggedOnUser?.username) {
      s.invoiceAddInvoiceAuthGet(context?.loggedOnUser?.username).then(
        async (value: Response) => {
          var d2 = await value.json();
          setAllowToAdd(d2.result);
        }
      );
      s.invoiceHasInvoiceAttachmentPermissionInvoiceGuidGet(
        invoiceId,
        context?.loggedOnUser?.username
      )
        .then(async (data) => data.json())
        .then((val) => {
          setAllowAttachmentDelete(val?.allowDelete);
          setAllowAttachmentUpload(val?.allowAttachment);
        });
      s.invoiceHasInvoiceCommentPermissionInvoiceGuidGet(
        invoiceId,
        context?.loggedOnUser?.username
      )
        .then((data) => data.json())
        .then((res) => setAllowComment(res?.allowComment));
    }
  };

  const loadPO = (poApi, invoiceFromApi) => {
    setLoading(true);

    //TODO: 1/5/2023 Not sure if we need to load PO any more.
    //Perhaps we can read directly from invoice coding.
    poApi
      .purchaseOrderCompanyCodePoIDGet(
        invoiceFromApi?.poNumber,
        invoiceFromApi?.companyCode,
        context?.loggedOnUser?.username
      )
      .then(async (result) => {
        let nPO: any = result;
        setPO(result);
        let loadSpecificUser = false;
        const invCodings = invoiceFromApi.invoiceCodings?.map((i) => {
          const poLineItem = nPO.poLineItemList?.find(
            (poli) => poli?.lineNumber === i?.sno
          );

          if (
            InvoiceConstants.AccountCodesForRepFilters.includes(
              poLineItem?.accountType
            )
          )
            loadSpecificUser = true;
          return {
            ...i,
            amount: i?.amount,
            committedAmount: poLineItem?.committedAmount,
            costToDateAmount: poLineItem?.costToDateAmount,
            runningTotal: poLineItem?.runningTotal,
            remainingAmount: NumberUtil.decimalNumDiff(
              poLineItem?.committedAmount,
              poLineItem?.runningTotal
            ),
            description: i?.description,
            poLineDescription: poLineItem?.lineDescription,
          };
        });
        setLoadAccountSpecificUsers(loadSpecificUser);
        setInvoice({ ...invoiceFromApi, invoiceCodings: invCodings });
        //TODO: Check this for accuracy when invoice is not yet in CP.
        setRunningCost(nPO?.runningTotal);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadReviewers = () => {
    let c: InvoiceApi = factory.create(InvoiceApi);
    setLoading(true);
    c.invoiceReviewersInvoiceGuidGet(context?.loggedOnUser?.username, invoiceId)
      .then(async (data) => {
        //console.log("reviewers",data);
        setReviewers(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (invoiceId && context?.loggedOnUser?.username) {
      loadInvoice();
    }
  }, [invoiceId, context?.loggedOnUser?.username]);

  React.useEffect(() => {
    if (approvalDialog === false && reloadInvoice && invoiceId !== "") {
      loadInvoice();
    }
  }, [approvalDialog]);

  React.useEffect(() => {
    if (updateApproverTriggerCnt > 0) loadInvoice();
  }, [updateApproverTriggerCnt]);

  const onAdditionalReviewerAdded = (e) => {
    setUpdateApprover(updateApproverTriggerCnt + 1);
  };

  const getInvoiceStatus = (v) => {
    if (invoice) {
      let activeReviewer: any[] = [];
      invoice.reviewers?.map((v2) => {
        if (v2.isActive) {
          if (activeReviewer[v2.role] && activeReviewer[v2.role] !== "") {
            activeReviewer[v2.role] += ", ";
          }
          if (activeReviewer[v2.role])
            activeReviewer[v2.role] += v2.displayName;
          else activeReviewer[v2.role] = v2.displayName;
        }
      });

      let str = "";
      for (let i in activeReviewer) {
        if (str !== "") str += " And ";
        str += ` ${i} (${activeReviewer[i]}) `;
      }
      return str;
    }

    return !v ? "" : v;
  };

  const isAllReviewerReviewed = () => {
    if (reviewers?.length == 0) return false;
    let allreviewed = true;
    reviewers?.map((v) => {
      if (!v.isCompleted && v.level !== 100) {
        allreviewed = false;
      }
    });
    return allreviewed;
  };

  const onApprovalPrimaryClick = (e) => {
    if (
      approvalMode === EnApprovalMode.Revise ||
      approvalMode === EnApprovalMode.Approve ||
      approvalMode === EnApprovalMode.Reject
    ) {
      setRedirect({
        shouldRedirect: true,
        url: "/",
      });
    } else {
      setReloadInvoice(true);
      setShowApprovalDialog(false);
    }
  };

  const allowEditForPM = () => {
    if (reviewers?.length > 0) {
      const currentUserRole = reviewers.find((r) => r.isActive);
      // console.log(currentUserRole);
      return (
        currentUserRole?.role?.toLocaleLowerCase() === "project manager" &&
        (currentUserRole?.displayName === context?.loggedOnUser?.displayname ||
          permission?.isApprover)
      );
    }

    return false;
  };

  const onCodingAmountChanged = (e, row) => {
    let invoiceCodings = invoice.invoiceCodings;
    let invCodingRunningTotalTemp = { ...invCodeRunningTotals };
    let index = invoiceCodings.indexOf(row);
    var runningTotal = invoiceCodings[index].runningTotal;
    let lastAmount = invCodingsOriginalAmounts[index].amount;
    const amount = parseFloat(e.value || 0);
    // runningTotal = runningTotal + (amount - lastAmount);
    runningTotal = NumberUtil.decimalNumSum(
      runningTotal,
      NumberUtil.decimalNumDiff(amount, lastAmount)
    );
    invCodingRunningTotalTemp[index] = runningTotal;
    setInvCodeRunningTotals(invCodingRunningTotalTemp);
    invoiceCodings[index].amount = amount;
    let totalRunningAmount = 0;
    invoiceCodings.map((v, i) => {
      // totalRunningAmount = (invCodingRunningTotalTemp[i] ?? invoiceCodings[i].runningTotal) + totalRunningAmount;
      totalRunningAmount = NumberUtil.decimalNumSum(
        invCodingRunningTotalTemp[i] ?? invoiceCodings[i].runningTotal,
        totalRunningAmount
      );
    });
    setRunningCost(totalRunningAmount);
    setInvoice({
      ...invoice,
      invoiceCodings: invoiceCodings,
      poRunningTotalAmount: runningCost,
    });
  };

  const onInvoiceAmountChanged = (e) => {
    if (e?.target?.value) {
      setInvoice({ ...invoice, vendorAmount: e?.target?.value });
    }
  };

  const statusClassName = `badge  pr-status-${invoice?.status
    ?.replace(" ", "-")
    ?.toLowerCase()}`;

  const toBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result?.toString() || "");
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    var file = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const ff = e.target.files[i];
      file.push({
        name: ff.name,
        type: ff.type,
        size: ff.size + " bytes",
        description: "",
        data: await toBase64(ff),
      });
    }
    e.target.value = "";

    let c: InvoiceApi = factory.create(InvoiceApi);
    setLoading(true);
    c.invoiceInvoiceAddAttachmentInvoiceGuidPost(
      context?.loggedOnUser?.username,
      file,
      invoiceId
    )
      .then((data) => data.json())
      .then((res) => {
        let data = res.result;
        data.forEach((attachment) => {
          attachment.createdOn = DateUtil.formatmmDdYYY(attachment?.createdOn);
        });
        setAttachmentList(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {redirect.shouldRedirect && <Redirect push to={redirect.url} />}
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <APIFactoryCommon
        ref={(e) => {
          factoryCommon = e;
        }}
      />

      <div className="row mb-3 align-items-md-center">
        <div className="col">
          <div className="mb-3 mb-md-0">
            <XtBreadcrumbs {...breadCrumbs} />
          </div>
        </div>
        <div className="col-auto">
          {(invoice?.status?.toLocaleLowerCase() ===
            "waiting for cost point integration" ||
            isAllReviewerReviewed()) &&
            allowToAdd && (
              <a
                href=""
                onClick={(e) =>
                  setRedirect({
                    shouldRedirect: true,
                    url: "/invoice/landingpage/" + invoiceId,
                  })
                }
              >
                <span className={statusClassName} role={"gridcell"}>
                  {StringUtil.replaceCostPoint(invoice?.status)}
                </span>
              </a>
            )}
          {invoice?.status?.toLocaleLowerCase() !=
            "waiting for cost point integration" &&
            isAllReviewerReviewed() === false && (
              <span className={statusClassName} role={"gridcell"}>
                {!invoice ? "" : StringUtil.replaceCostPoint(invoice?.status)}
              </span>
            )}
        </div>
        <DialogActionsBar layout="end">
          {allowComment && (
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                onApprovalButtonClick(EnApprovalMode.Comment);
              }}
            >
              Add Comment
            </Button>
          )}
          {canApproveReviseOrReject() && (
            <>
              {permission?.level > 1 && (
                <Button
                  type="button"
                  name="btnlist"
                  variant="warning"
                  onClick={() => {
                    onApprovalButtonClick(EnApprovalMode.Revise);
                  }}
                >
                  Revise
                </Button>
              )}
              <Button
                type="button"
                variant="danger"
                onClick={() => {
                  onApprovalButtonClick(EnApprovalMode.Reject);
                }}
              >
                Reject
              </Button>

              {!invoice?.status
                ?.toLocaleLowerCase()
                .includes("po change request needed") && (
                <Button
                  type="button"
                  variant="primary"
                  id="submit"
                  onClick={() => {
                    onApprovalButtonClick(EnApprovalMode.Approve);
                  }}
                >
                  Approve
                </Button>
              )}
            </>
          )}
          {canEdit() && (
            <Button
              onClick={(e) => {
                setRedirect({
                  shouldRedirect: true,
                  url: `/invoice/edit/${invoice?.invoiceGuid}`,
                });
              }}
            >
              Edit
            </Button>
          )}
        </DialogActionsBar>
        <div className="row mb-3 align-items-md-center"></div>
      </div>

      <div className="pr-create-new">
        <Accordion defaultActiveKey={activeSections} alwaysOpen>
          <XtAccordianItem header="REVIEWER DETAIL" eventKey="0">
            <InvoiceDetailsReviewer
              data={reviewers}
              isApprover={true}
              invoiceGuid={invoiceId}
              onAdditionalReviewerAdded={onAdditionalReviewerAdded}
              designatorList={permission?.designatorList}
              status={invoice?.status}
            />
          </XtAccordianItem>
          <XtAccordianItem header="AUDIT LOG" eventKey="1" IsCollapsible={true}>
            <InvoiceAuditLog
              auditLogComment={auditLogComment}
              invoiceGuid={invoiceId}
            />
          </XtAccordianItem>
          <XtAccordianItem header="INVOICE DETAIL" eventKey="2">
            <InvoiceDetailsHeader
              invoice={invoice}
              company={company}
              onCodingAmountChanged={onInvoiceAmountChanged}
              loadAccountSpecificUsers={loadAccountSpecificUsers}
              projectNumber={po?.projectNumber}
            />
          </XtAccordianItem>
          <XtAccordianItem header="PO Cost Details" eventKey="1">
            <PoCostDetails
              committedAmount={po?.totalAmount}
              costToDate={po?.costToDateAmount}
              runningTotalCost={runningCost}
            />
          </XtAccordianItem>
          <XtAccordianItem header="INVOICE CODING" eventKey="4">
            <InvoiceDetailsInvoiceCoding
              data={invoice?.invoiceCodings}
              triggerCount={triggerCount}
              orgId={company?.orgId}
              allowEditForPM={allowEditForPM()}
              onCodingAmountChanged={onCodingAmountChanged}
              invCodeRunningTotals={invCodeRunningTotals}
            />
          </XtAccordianItem>
          <XtAccordianItem header="ATTACHMENT" eventKey="5">
            <AttachmentListReadOnly
              allowAttachmentDelete={allowAttachmentDelete}
              files={attachmentList}
              invoiceGuid={invoiceId}
              onUpdateFiles={(updatedFiles) => setAttachmentList(updatedFiles)}
            />
            {allowAttachmentUpload && (
              <Form.Control
                title="Choose File"
                type="file"
                onChange={handleFileUpload}
                className="xtupload-button mb-3 mt-3"
              />
            )}
          </XtAccordianItem>
        </Accordion>
        <DialogActionsBar layout="end">
          {canApproveReviseOrReject() && (
            <>
              {allowComment && (
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => {
                    onApprovalButtonClick(EnApprovalMode.Comment);
                  }}
                >
                  Add Comment
                </Button>
              )}

              {permission?.level > 1 && (
                <Button
                  type="button"
                  name="btnlist"
                  variant="warning"
                  onClick={() => {
                    onApprovalButtonClick(EnApprovalMode.Revise);
                  }}
                >
                  Revise
                </Button>
              )}
              <Button
                type="button"
                variant="danger"
                onClick={() => {
                  onApprovalButtonClick(EnApprovalMode.Reject);
                }}
              >
                Reject
              </Button>

              {!invoice?.status
                ?.toLocaleLowerCase()
                .includes("po change request needed") && (
                <Button
                  type="button"
                  variant="primary"
                  id="submit"
                  onClick={() => {
                    onApprovalButtonClick(EnApprovalMode.Approve);
                  }}
                >
                  Approve
                </Button>
              )}
            </>
          )}
        </DialogActionsBar>
        {loading && <Loading />}
        {approvalDialog && (
          <InvoiceApproveRejectDialog
            approvalMode={approvalMode}
            onPrimaryClick={onApprovalPrimaryClick}
            invoiceGuid={invoiceId}
            currentLevel={permission?.level}
            invoice={invoice}
            committedAmount={po?.totalAmount}
            poRunningTotal={runningCost}
            allowEditForPM={allowEditForPM()}
            setAuditLogComment={setAuditLogComment}
            onSecondaryClick={(e) => {
              setReloadInvoice(false);
              setShowApprovalDialog(false);
            }}
          />
        )}
      </div>
    </>
  );
}
