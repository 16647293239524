import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

import { XtContext } from "../../../../../index-Lib";
import { IXtContext } from "../../../../../Xt/Contexts/XtContext";
import { DateUtil } from "../../../../../Xt/Utils/DateUtil";
import { getPrrAuditLogByGuid } from "../../../../../api/react-query";

interface AuditLogProps {
  prrGuid?: string;
  prGuid?: string;
}
export default function AuditLog(props: AuditLogProps) {
  const context: IXtContext = useContext(XtContext);
  const { prrGuid } = props;

  const token = context?.tokens?.accessToken;
  const username = context?.loggedOnUser?.username;

  const { data: auditLogData } = useQuery(
    ["auditLog", prrGuid, token, username],
    getPrrAuditLogByGuid,
    {
      enabled: !!prrGuid && !!token && !!username,
      placeholderData: [],
    }
  );

  return (
    <>
      {auditLogData.map((val, index) => (
        <div key={index} className="mb-4">
          <div className="small d-flex justify-content-between text-muted mb-1">
            <div className="">
              <span> {val.displayName}</span>
              <span className="badge bg-primary  ms-2">{val.role}</span>
            </div>
            <div className="d-flex align-items-center">
              <i className="material-icons small me-1">schedule</i>
              {val.stage === "1" && "Submitted on"}
              {val.stage !== "1" &&
                val.isRejected === false &&
                val.isRevised === false &&
                val.isCanceled === false &&
                "Approved on"}
              {val.isRejected === true && "Rejected on"}
              {val.isRevised === true && "Revised on"}
              {val.isCanceled === true && "Canceled on"}
              {DateUtil.convertUTCDateToLocalDate(val.timeStamp)}
              {val.displayName === val.completedBy
                ? ""
                : " by  " +
                  (val.completedBy === val.displayName
                    ? val.completedBy
                    : val.completedBy +
                      " (on behalf of " +
                      val.displayName +
                      ")")}
            </div>
          </div>

          {val.comment && val.comment.length > 0 && (
            <small className="d-block fw-bold mb-0 text-primary">
              {val.comment}
            </small>
          )}

          {val.comment && val.comment.length === 0 && (
            <div className="text-muted text-uppercase small font-weight-bold ml-3 d-block">
              No Comments
            </div>
          )}
        </div>
      ))}
    </>
  );
}
