export const NumberUtil = {
  checkDecimalDigit: (num) => {
    const decimalIndex = num.toString().indexOf(".");
    return decimalIndex >= 0
      ? num.toString().slice(decimalIndex + 1).length
      : 0;
  },
  formatToTwoDecimal: (num: any) => {
    // if (isNaN(num)) return undefined;
    // return "" + (Math.round(num * 100) / 100).toFixed(2);
    if (isNaN(num)) return undefined;
    const decDigits = NumberUtil.checkDecimalDigit(num);
    const truncatedNum = decDigits < 3 ? num : Math.trunc(num * 100) / 100;
    return truncatedNum;
  },
  roundToTwoDecimal: (num:any) => {
    if (isNaN(num)) return undefined;
    const roundedNum = Math.round(num * 100) / 100
    return roundedNum;
  },
  decimalNumDiff: (num1, num2) => {
    const diff =
      Math.round(NumberUtil.formatToTwoDecimal(num1) * 100) -
      Math.round(NumberUtil.formatToTwoDecimal(num2) * 100);
    const res = diff / 100;
    return res;
  },
  decimalNumSum: (num1, num2) => {
    const sum =
      Math.round(NumberUtil.formatToTwoDecimal(num1) * 100) +
      Math.round(NumberUtil.formatToTwoDecimal(num2) * 100);
    const res = sum / 100;
    return res;
  },

  decimalNumMultiply: (num1, num2) => {
    const multiply =
      Math.round(NumberUtil.formatToTwoDecimal(num1) * 100) *
      Math.round(NumberUtil.formatToTwoDecimal(num2) * 100);
    const res = multiply / 10000;
    return res;
  },

  roundToFourDecimal: (num: any) => {
    if (isNaN(num)) return undefined;
    // return (Math.round(num * 10000) / 10000);
    const decDigits = NumberUtil.checkDecimalDigit(num);
    const truncatedNum = decDigits < 5 ? num : Math.trunc(num * 10000) / 10000;
    return truncatedNum;
  },
  //TODO: This is not showing correct decimal places.
  //FIX THIS
  // formatToTwoDecimalWithComma: (value, decimal = 2) => {
  //   if (isNaN(value)) return undefined;
  //   let formattedValue;
  //   try {
  //     formattedValue = Number(value).toLocaleString("en-US", {
  //       style: "currency",
  //       currency: "USD",
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: decimal,
  //     });
  //   } catch (e) {
  //     formattedValue = value;
  //   }
  // },

  formatToTwoDecimalWithComma: (value, decimal = 2) => {
    if (isNaN(value)) return undefined;
    let formattedValue;
    try {
      const decDigits = NumberUtil.checkDecimalDigit(value);

      const truncatedValue =
        decDigits < 3 ? value : Math.trunc(value * 100) / 100;
      formattedValue = Number(truncatedValue).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: decimal,
      });
    } catch (e) {
      formattedValue = value;
    }

    return formattedValue;
  },
  roundToTwoDecimalWithComma: (value, decimal = 2) => {
    if (isNaN(value)) return undefined;
    let formattedValue;
    try {
      
      formattedValue = Number(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: decimal,
      });
    } catch (e) {
      formattedValue = value;
    }

    return formattedValue;
  },

  formatTrucNumToTwoDecimalWithComma: (value, decimal = 2) => {
    if (isNaN(value)) return undefined;
    let formattedValue;
    try {
      formattedValue = value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: decimal,
      });
    } catch (e) {
      formattedValue = value;
    }
    return formattedValue;
  },

  formatToTwoDecimalWithCommaNoCurrency: (value, decimal = 2) => {
    if (isNaN(value)) return undefined;
    let formattedValue;
    try {
      // const truncatedValue = Math.trunc(value * 100) / 100; // Truncate value to two decimal places
      const decDigits = NumberUtil.checkDecimalDigit(value);
      const truncatedValue =
        decDigits < 3 ? value : Math.trunc(value * 100) / 100;
      formattedValue = Number(truncatedValue).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: decimal,
      });
    } catch (e) {
      formattedValue = value;
    }
    return formattedValue;
  },
  formatTruncNumToTwoDecimalWithCommaNoCurrency: (value, decimal = 2) => {
    if (isNaN(value)) return undefined;
    let formattedValue;
    try {
      formattedValue = value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: decimal,
      });
    } catch (e) {
      formattedValue = value;
    }
    return formattedValue;
  },
};
