import React from "react";
import { Button, Container } from "react-bootstrap";
import { APIFactory, AttachmentApi } from "../../../../../../Xt/ApiClients";
import { XtFileIcon } from "../../../../../../Xt/FileBrowser/XtFile";
import { IXtContext, XtContext } from '../../../../../../Xt/Contexts/XtContext';

export default function NwFileLinkGridCell(props) {
    let factory: APIFactory;
    const context: IXtContext = React.useContext(XtContext);
  const fileName: string = props.dataItem["name"];
  const filePath: string = props.dataItem["filePath"];
  const attachmentGuid: string = props.dataItem["attachmentGuid"];
  const downloadAttachment = (attachmentGuid, fileName) => {
    let api: AttachmentApi = factory.create(AttachmentApi);
    api
        .attachmentControllerGet(context?.loggedOnUser?.username,attachmentGuid)
      .then(async (d) => {
        const arrayBuffer = await d.arrayBuffer();
        createAndDownloadBlobFile(arrayBuffer, fileName);
      })
      .catch((e) => {
        console.log(e);
        // factory.showErrorMessage(e, "Error in download");
      });
  };

  const createAndDownloadBlobFile = (body, filename) => {
    const blob = new Blob([body]);
    const fileName = `${filename}`;
    if ((navigator as any).msSaveBlob) {
      // IE 10+
      (navigator as any).msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const fileIconProps = {
    file: { name: fileName, type: "file", parent: "" },
    selected: false,
  };
  //  console.log(fileIconProps);
  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <td>
        <span style={{"cursor":"pointer"}}
          onClick={(e) => downloadAttachment(attachmentGuid, fileName)}
        >
           {" "}
          <XtFileIcon {...fileIconProps} />
          {fileName}
        </span>
      </td>
    </>
  );
}
