import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormLabelMutedText from "../Common/FormLabelMutedText";
import AddressReadOnly from "./AddressReadOnly";

export default function NwShippingPaymentReadOnly(props) {
    const { pr } = props;


    const address = {
        address1: pr?.primaryAddress,
        address2: pr?.secondaryAddress,
        city: pr?.city,
        state: pr?.state,
        zipCode: pr?.zipCode,
        country: pr?.countryCode
    };
    return (
        <Row>
            <Col md={6}>

                <Row>
                    <Form.Label column md={4}>
                        Shipping Address
                    </Form.Label>
                    <Col md={8}>
                        <AddressReadOnly {...address} />

                    </Col>
                </Row>
            </Col>
            <Col md={6}>
                <Row className="mt-2 mb-1">
                    <Form.Label column md={4}>
                        Payment Type
                    </Form.Label>
                    <Col md={8}>
                        <FormLabelMutedText value={pr?.paymentType} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Form.Label column md={4}>
                        Special Payment Terms
                    </Form.Label>
                    <Col md={8}>
                        <FormLabelMutedText value={pr?.specialPaymentTerm} />
                    </Col>
                </Row>

               
            </Col>
            <Col md={6}>
                <Row>
                    <Form.Label column md={4}>
                        Special Handling Notes
                    </Form.Label>
                    <Col md={8}>
                        <FormLabelMutedText value={pr?.specialNote} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
