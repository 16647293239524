import { PurchaseRequisitionApi, VendorApi } from "../../../../../Xt/ApiClients";
import { ResourceAttributeApi as NWResourceAttributeApi } from "../../../../../Xt/ApiClients";
import { IXtContext } from "../../../../../Xt/Contexts/XtContext";
import { NumberUtil } from "../../../../../Xt/Utils/NumberUtil";
import { Address } from "./VendorContactReadOnly";

export const PrApiService = {
    getPrByGuid: (context: IXtContext, api: PurchaseRequisitionApi, guid: string) => {
        return api.purchaseRequisitionKeyGet(context?.loggedOnUser?.username, guid).then(async (prData) => {
            let pr: any = prData;
            if (pr.purchaseItem && pr.purchaseItem.length > 0) {
                let purchaseItem = pr.purchaseItem.sort((p1,p2)=> p1.lineNumber-p2.lineNumber).map((p, c) => {
                    let lastTotalAmt = p.lastQuantity * p.lastUnitPrice
                    return {
                        ...p,
                        lineItemNumber: c + 1,
                        startDate: new Date(p.startDate),
                        endDate: new Date(p.endDate),
                        lastTotal: NumberUtil.roundToTwoDecimal(lastTotalAmt),
                        isNewItem: p.tranType ==="INSERT" ? true : false
                    };
                });
                pr.purchaseItem = purchaseItem;
            }
            if (pr.attachmentList && pr.attachmentList.length > 0) {
                let attachmentList = pr.attachmentList.map((a, c) => {
                    return {
                        ...a,
                        createdOn: new Date(a.createdOn),
                    };
                });
                pr.attachmentList = attachmentList;
            }
            return pr;
        });
    },
    getApprovalFlowByGuid: (context: IXtContext, api: PurchaseRequisitionApi, guid: string) => {
        return api
            .purchaseRequisitionApprovalFlowKeyGet(context?.loggedOnUser?.username, guid)
            .then(async (data) => {
                let dataFromApi = await data.json();
                return dataFromApi.value;
            });
    },

    getApprovalFlowLogByGuid: (context: IXtContext, api: PurchaseRequisitionApi, guid: string) => {
        return api
            .purchaseRequisitionApprovalFlowLogKeyGet(context?.loggedOnUser?.username, guid)
            .then(async (data) => {
                let dataFromApi = await data.json();
                return dataFromApi.value;
            });
    },

    getPermissionByPrGuid: (context: IXtContext, api: PurchaseRequisitionApi, guid: string) => {
        return api
            .purchaseRequisitionRequisitionPermissionKeyGet(context?.loggedOnUser?.username, guid)
            .then(async (data) => {
                let dataFromApi = await data;
                return dataFromApi;
            });
    },


    getVendor: (context: IXtContext, api: VendorApi, vendorCode: string, companyCode:string, vendorAddressGuid :string, addressContactGuid:string) => {
        return api
            .vendorDetailGet(context?.loggedOnUser?.username,vendorCode, companyCode)
            .then(async (vendorDetails) => {
                const vendDetailsFromApi: any = vendorDetails;
                const selectedVendorDetails = {
                    vendorName: vendDetailsFromApi?.vendorName,
                    vendorGuid: vendDetailsFromApi?.vendorGuid,
                    vendorCode: vendDetailsFromApi?.vendorID,
                    address: null,
                    contactFirstName: null,
                    contactLastName: null,
                    contactEmail: null,
                    contactPhone: null,
                };
                
                if (
                    vendDetailsFromApi?.vendorAddressList &&
                    vendDetailsFromApi.vendorAddressList.length > 0
                ) {
                    const address = vendDetailsFromApi.vendorAddressList.find(a=> a.vendorAddressGuid ===vendorAddressGuid);
                    const selectedVendorAddress: Address = {
                        address1: address?.addressLine1,
                        address2: address?.addressLine2,
                        address3: address?.addressLine3,
                        city: address?.cityName,
                        state: address?.mailStateCode,
                        zipCode: address?.postalCode,
                        country: address?.countryCode,
                        addressCode: address?.addressCode
                    };
                    selectedVendorDetails.address = selectedVendorAddress;
                    if (address.contactList && address.contactList.length > 0) {
                        const contact = address.contactList.find(c=> c.addressContactGuid===addressContactGuid);
                        selectedVendorDetails.contactFirstName = contact?.firstName;
                        selectedVendorDetails.contactLastName = contact?.lastName;
                        selectedVendorDetails.contactEmail =
                            contact?.emailID;
                        selectedVendorDetails.contactPhone =
                            contact?.phoneID;
                    }
                }
                return selectedVendorDetails;
            });
    },

    getVendorDetails: (context: IXtContext, api: VendorApi, vendorCode: string, companyCode: string) => {
        return api
            .vendorDetailGet( context?.loggedOnUser?.username,vendorCode,companyCode)
            .then(async (vendorDetails) => {                    
                return vendorDetails;
            }).catch(async (e) => {
                return {};
            })
    },


    getResourceAttributeList: (context: IXtContext, api: NWResourceAttributeApi, entity, resourceType) => {
        return api.resourceAttributeControllerGet(context?.loggedOnUser?.username, entity, resourceType)
            .then(async (data) => {
                let dataFromApi = await data.json();
                let resourceAttributeValue = dataFromApi.value;
                if (
                    resourceAttributeValue &&
                    resourceAttributeValue.length > 0 &&
                    resourceAttributeValue[0].resourceAttributeValueList &&
                    resourceAttributeValue[0].resourceAttributeValueList.length > 0
                ) {
                    const attributeList =
                        resourceAttributeValue[0].resourceAttributeValueList.map((a) => {
                            return { value: a.value, text: a.name };
                        });
                    return attributeList;
                }
            });
    },

    getResouceAttributeFromCommonApi: (context: IXtContext, api: NWResourceAttributeApi, attributeName) => {
        return api.resourceAttributeControllerGet(context?.loggedOnUser?.username, attributeName).then(async (data) => {
            var dataFromApi = await data.json();
            const qualtityLevelList = dataFromApi.value.map((q) => {
                return { text: q.name, value: q.value };
            });
            return qualtityLevelList;
        });
    },
}
