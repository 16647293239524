import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { XtFileUpload, XtForm } from "nwcommon";
import { XtFile, XtFileType } from "./XtFile";
import { XtAddFolderDialog } from "./XtAddFolderDialog";

export type XtFileContainerProps = React.PropsWithChildren<{
  paneHeight: string;
  path: string;
  files: XtFileType[];
  onFolderOpen: any;
  onUpload: any;
  onFolderCreate?: any;
  allowChangeFolder: boolean;
}>;

export function XtFileContainer(props: XtFileContainerProps) {
  const [files, setFiles] = React.useState<XtFileType[]>(
    props.files ? props.files : []
  );
  const [copied, setCopiedFile] = React.useState<XtFileType>();
  const [selectedFile, setSelectedFile] = React.useState<XtFileType>();
  const [showContextMenu, setShowContextMenu] = React.useState<boolean>(false);
  const [showCreateFolder, setShowCreateFolder] =
    React.useState<boolean>(false);
  const [poffset, setOffset] = React.useState<any>({});
  const [removefiles, setRemoveFiles] = React.useState<boolean>(false);
  const [showUploadButton, setShowUploadButton] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (!showContextMenu) setSelectedFile(undefined);
  }, [showContextMenu]);

  React.useEffect(() => {
    if (props.files) {
      let f: XtFileType[] = [];
      if (props.path !== "/" && props.allowChangeFolder) {
        let folders = props.path.split("/");
        folders.pop();
        f.push({
          name: "..",
          parent: folders.join("/") === "" ? "/" : folders.join("/"),
          type: "",
        });
        props.files.map((v: XtFileType, i: number) => {
          f.push({ ...v, parent: `${props.path}` });
        });
      } else {
        props.files.map((v: XtFileType, i: number) => {
          f.push({ ...v, parent: `${props.path}` });
        });
      }
      f = f.sort((a: XtFileType, b: XtFileType) => {
        if (a.type < b.type) return -1;
        else if (a.type === b.type) return 0;
        else if (a.type > b.type) return 1;
        return 1;
      });
      setFiles(f);
    }
  }, [props.files, props.path]);

  const onFileOrFolderContextMenu = (e: any) => {
    setOffset({ left: e.clientX, top: e.clientY });
    setSelectedFile(e.dataItem);
    setShowContextMenu(true);
  };

  const onFileOrFolderClick = (e: any) => {
    setShowContextMenu(false);
    setSelectedFile(e.dataItem);
  };

  const onFileOrFolderDouble = (e: any) => {
    setSelectedFile(e.dataItem);
    if (e.dataItem.type === "") {
      if (!props.allowChangeFolder) return;
      if (e.dataItem.name === "..") {
        props.onFolderOpen({
          ...e,
          path: `${e.dataItem.parent}`.replace("//", "/"),
        });
      } else {
        props.onFolderOpen({
          ...e,
          path: `${props.path}/${e.dataItem.name}`.replace("//", "/"),
        });
      }
      return;
    }
  };

  const renderFiles = () => {
    let items: any[] = [];
    if (props.allowChangeFolder) {
      files.map((v, i) => {
        items.push(
          <XtFile
            selected={selectedFile === v}
            key={"file" + i}
            onContextMenu={onFileOrFolderContextMenu}
            file={v}
            onDoubleClick={onFileOrFolderDouble}
            onClick={onFileOrFolderClick}
          />
        );
      });
    } else {
      files.map((v, i) => {
        if (v.type !== "") {
          items.push(
            <XtFile
              selected={selectedFile === v}
              key={"file" + i}
              onContextMenu={onFileOrFolderContextMenu}
              file={v}
              onDoubleClick={onFileOrFolderDouble}
              onClick={onFileOrFolderClick}
            />
          );
        }
      });
    }

    return items;
  };

  const onContextMenu = (e: any) => {
    e.preventDefault();
    setOffset({ left: e.clientX, top: e.clientY });
    setSelectedFile(undefined);
    setShowContextMenu(true);
  };

  const onClick = (e: any) => {
    e.preventDefault();
    setShowContextMenu(false);
  };

  const onContextMenuClicked = (e: any) => {
    setShowContextMenu(false);
    if (e.action === "open") {
      if (!props.allowChangeFolder) return;
      if (e.dataItem.name === "..") {
        props.onFolderOpen({
          ...e,
          path: `${e.dataItem.parent}`.replace("//", "/"),
        });
      } else {
        props.onFolderOpen({
          ...e,
          path: `${props.path}/${e.dataItem.name}`.replace("//", "/"),
        });
      }
      return;
    } else if (e.action === "createFolder") {
      setShowCreateFolder(true);
    } else if (e.action === "copy") {
      setCopiedFile(selectedFile);
    }

    if (e.action !== "copy" && e.action !== "open") {
      setCopiedFile(undefined);
    }
  };

  const onSubmit = (e: any) => {
    props.onUpload({ postData: e.postData.file1, callback: clearFiles });
  };

  const clearFiles = () => {
    setRemoveFiles(true);
  };

  const onCloseFolderDialog = (e: any) => {
    setShowCreateFolder(false);
  };

  const onFolderCreate = (e: any) => {
    props.onFolderCreate({ ...e, callback: onAfterFolderCreated });
  };

  const onAfterFolderCreated = () => {
    setShowCreateFolder(false);
  };

  const onFileChange = (e) => {
    setShowUploadButton(e.files?.length > 0);
  };

  return (
    <div className="xtfilecontainer-wrapper">
      <div>
        <Row>
          <Col className="file-uploader custom-fileupload" xs={12}>
            <XtForm onSubmit={onSubmit}>
              <div className="d-inline-block me-2">
                <XtFileUpload
                  name="file1"
                  type="file"
                  clearFiles={removefiles}
                  onChange={onFileChange}
                />
              </div>
              <div className="button-div d-inline-block">
                <Button type="submit" disabled={!showUploadButton}>
                  Upload
                </Button>
              </div>
            </XtForm>
          </Col>
        </Row>
        <Row>
          <Col
            // className="file-browser-pane"
            style={{ height: props.paneHeight }}
            onContextMenu={onContextMenu}
            onClick={onClick}
          >
            {/* <div className="files">
              <div className="file-container"> */}
            <h6 className="mt-4">Your Files</h6>
            <Row>
              <Col>
                <div className="card">
                  <div className="card-body p-2">{renderFiles()}</div>
                </div>
              </Col>
            </Row>
            {/* </div>
            </div> */}
          </Col>
        </Row>
      </div>
      {showContextMenu && (
        <XtContextMenu
          file={selectedFile}
          style={{ position: "absolute", left: poffset.left, top: poffset.top }}
          onMenuClick={onContextMenuClicked}
          copied={copied}
        />
      )}
      {showCreateFolder && (
        <XtAddFolderDialog
          onClose={onCloseFolderDialog}
          onCreate={onFolderCreate}
        />
      )}
    </div>
  );
}

type XtContextMenuProps = {
  file: XtFileType | undefined;
  copied: XtFileType | undefined;
  onMenuClick: any;
} & React.InputHTMLAttributes<HTMLDivElement>;

function XtContextMenu(props: XtContextMenuProps) {
  const getFileMenu = () => {
    let items: any[] = [];
    items.push(
      <li key={"k" + 1}>
        <a href="#" onClick={onCopyClick}>
          <span className="k-icon k-i-copy"></span> Copy
        </a>
      </li>
    );
    items.push(
      <li key={"k" + 2}>
        <a href="#" onClick={onDeleteClick}>
          <span className="k-icon k-i-trash"></span> Delete
        </a>
      </li>
    );
    items.push(
      <li key={"k" + 3}>
        <a href="#" onClick={onDownloadClick}>
          <span className="k-icon k-i-download"></span> Download
        </a>
      </li>
    );
    items.push(
      <li key={"k" + 4}>
        <a href="#" onClick={onDescriptionClick}>
          <span className="k-icon k-i-detail-section"></span> Description
        </a>
      </li>
    );
    return items;
  };

  const getFolderMenu = () => {
    let items: any[] = [];
    items.push(
      <li key={"k" + 1}>
        <a href="#" onClick={onOpenClick}>
          <span className="k-icon k-i-folder-open"></span> Open
        </a>
      </li>
    );
    items.push(
      <li key={"k" + 2}>
        <a href="#" onClick={onDeleteClick}>
          <span className="k-icon k-i-trash"></span> Delete
        </a>
      </li>
    );
    return items;
  };

  const getPaneMenu = () => {
    let items: any[] = [];
    items.push(
      <li key={"k" + 1}>
        <a href="#" onClick={onCreateFolderClick}>
          <span className="k-icon k-i-folder-add"></span> Create Folder
        </a>
      </li>
    );
    if (props.copied !== undefined) {
      items.push(
        <li key={"k" + 2}>
          <a href="#" onClick={onCreateFolderClick}>
            <span className="k-icon k-i-aggregate-fields"></span> Paste
          </a>
        </li>
      );
    }

    return items;
  };

  const getMenu = () => {
    if (props.file?.type === "") return getFolderMenu();
    else return getFileMenu();
  };

  const onCreateFolderClick = (e: any) => {
    e.preventDefault();
    onMenuClick({ ...e, action: "createFolder", dataItem: props.file });
  };

  const onDescriptionClick = (e: any) => {
    e.preventDefault();
    onMenuClick({ ...e, action: "description", dataItem: props.file });
  };

  const onDeleteClick = (e: any) => {
    e.preventDefault();
    onMenuClick({ ...e, action: "delete", dataItem: props.file });
  };

  const onDownloadClick = (e: any) => {
    e.preventDefault();
    onMenuClick({ ...e, action: "download", dataItem: props.file });
  };

  const onCopyClick = (e: any) => {
    e.preventDefault();
    onMenuClick({ ...e, action: "copy", dataItem: props.file });
  };

  const onOpenClick = (e: any) => {
    e.preventDefault();
    onMenuClick({ ...e, action: "open", dataItem: props.file });
  };

  const onMenuClick = (e: any) => {
    props.onMenuClick(e);
  };

  const onMenuClickWrapper = (e: any) => {
    e.preventDefault();
  };

  let nProps: any = {};
  for (let i in props) {
    if (i !== "onMenuClick" && i !== "file") {
      nProps[i] = props[i];
    }
  }

  return (
    <div className="popup-container" {...nProps}>
      <ul className="popup-menu">
        {props.file && getMenu()}
        {!props.file && getPaneMenu()}
      </ul>
    </div>
  );
}
