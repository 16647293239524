import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import FormField, {
	RequiredFormField,
} from "../PurchaseRequisition/Common/FormField";
import FormLabelMutedText, {
	FormPoLink,
} from "../PurchaseRequisition/Common/FormLabelMutedText";
import { XtField } from "nwcommon";
import {
	APIFactoryCommon,
	ResourceAttributeApi,
} from "../../../../Xt/ApiClients/Common";
import { enum_request_type } from "../PurchaseRequisition/Common/NwPrConstants";

export interface CpIntegrationPurchaseOrderDetailsProps {
	prNumber?: string;
	orderDate: Date;
	onOrderDateChange: any;
	companyCode?: string;
	buyerId?: string;
	prType?: string;
	poNumber?: string;
	onPoNumberChange?: (e: string) => void;
}

export default function CpIntegrationPurchaseOrderDetails(
	props: CpIntegrationPurchaseOrderDetailsProps,
) {
	let commonApifactory: APIFactoryCommon;
	const [buyersList, setBuyersList] = useState([]);
	const [selectedBuyer, setSelectedBuyer] = useState(null);
	const [loadingBuyer, setLoadingBuyer] = useState(false);

	useEffect(() => {
		if (props.companyCode) {
			let commonResourceApi: ResourceAttributeApi =
				commonApifactory.create(ResourceAttributeApi);
			setLoadingBuyer(true);

			commonResourceApi
				.resourceAttributeBuyerGet(props.companyCode)
				.then(async (buyersData) => {
					var buyersDataDataFromApi = await buyersData.json();
					const buyers = buyersDataDataFromApi.value.map((b) => {
						return {
							value: b.buyeR_ID,
							text: b.lasT_FIRST_NAME,
						};
					});
					setBuyersList(buyers);
				})
				.finally(() => setLoadingBuyer(false));
		}
	}, [props.companyCode]);

	useEffect(() => {
		if (buyersList.length && props?.buyerId) setSelectedBuyer(props?.buyerId);
	}, [props?.buyerId, buyersList.length]);

	return (
		<>
			<APIFactoryCommon
				ref={(e) => {
					commonApifactory = e;
				}}
			/>
			<Row>
				<FormField label="PR No.">
					<FormLabelMutedText value={props?.prNumber} />
				</FormField>

				<RequiredFormField label="Buyer">
					<XtField
						type="dropdown"
						name="buyer"
						value={buyersList.find((b) => b?.value === selectedBuyer) || null}
						onChange={(e) => setSelectedBuyer(e.value.vlaue)}
						items={buyersList}
						textField="text"
						required={true}
						errormessage="Buyer Type is required"
						loading={loadingBuyer}
					/>
				</RequiredFormField>

				<RequiredFormField label="Order date">
					<XtField
						type="datepicker"
						name="orderDate"
						weekNumber={false}
						required={true}
						errormessage="Order date is required"
						value={props.orderDate}
						onChange={(e) => props.onOrderDateChange(e.value)}
					/>
				</RequiredFormField>

				<FormField label="GUC ">
					<FormLabelMutedText value={"1"} />
				</FormField>

				{props?.prType === enum_request_type.PO_CHANGE_REQUEST && (
					<FormField label="PO Number">
						<FormPoLink poNumber={props?.poNumber} />
					</FormField>
				)}

				{props?.prType &&
					props?.prType !== enum_request_type.PO_CHANGE_REQUEST && (
						<FormField label="PO Number">
							<XtField
								type="text"
								value={props?.poNumber}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									props.onPoNumberChange(e.target.value)
								}
							/>
						</FormField>
					)}
			</Row>
		</>
	);
}
