import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { XtModal, XtModalProps } from "nwcommon";
import { XtNavDropdownItem } from "../NavBar/XtNavDropdownItem";
import { APIFactory, UserApi } from "../../ApiClients/index";
import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { XtAdvancedSearchBox } from "nwcommon";
import { XtContext, IXtContext } from "../../Contexts/XtContext";
import { Loading } from "../../Layout/Loading";

const gridColumns = [
  {
    field: "name",
    title: "Name",
  },
  {
    field: "email",
    title: "Email",
  },
  {
    field: "department",
    title: "Department",
  },
];

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

export type NwSwitchUserProps = React.PropsWithChildren<{ onUserSwitch: any }>;

export default function NwSwitchUser(props: NwSwitchUserProps) {
  let factory;
  const init = true;
  const [show, setShow] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [filterdUser, setFilteredUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searchParams, setSearchParam] = React.useState<any>({});
  const [dataCount, setDataCount] = React.useState<number>(0);
  const [page, setPage] = React.useState<PageState>(initialDataState);

  const context: IXtContext = React.useContext(XtContext);

  const onPageChange = (event: GridPageChangeEvent) => {
    setPage(event.page);
  };

  const handleClose = () => {
    setShow(false);
    setSearchUser("");
    setFilteredUser(null);
  };

  const onGridRowClick = (rowData: any) => {
    setSelectedUser(rowData);
    context.switchUser(rowData.dataItem);
    handleClose();
    if (props.onUserSwitch) {
      props.onUserSwitch();
    }
  };

  const handleSwitch = () => {
    setShow(false);
  };

  function handleSerachChange(event) {
    const searchedText = event.target.value;
    setPage({ ...page, skip: 0 });
    setSearchUser(searchedText);
  }

  const handleShow = () => setShow(true);

  const modalProps: XtModalProps = {
    header: "Switch User",
    secondaryButtonLabel: "Cancel",
    onSecondaryClick: handleClose,
    show: show,
  };

  const getUsersFromApi = () => {
    let s: UserApi = factory.create(UserApi, context.apiBasePath);
    setLoading(true);
    const filterParameter = "firstname ne ''";

    s.userGet(
      context?.loggedOnUser?.username,
      searchUser,
      filterParameter,
      page.take,
      page.skip,
      page.take,
      "firstname" + " " + "asc"
    )
      .then(async (data) => {
        var d1 = await data.json();
        const totalRecords = d1["@odata.count"];

        setDataCount(d1["@odata.count"]);
        setFilteredUser(d1.value);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (show && context?.loggedOnUser?.username) {
      getUsersFromApi();
    }
  }, [context?.loggedOnUser?.username, searchUser, page]);

  const onSearchCondition = (e) => {
    setPage({ ...page, skip: 0 });
    setSearchUser(e.searchString);
  };

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      {context.isSuperAdmin && context.isAllowedToSwitch() && (
        <XtNavDropdownItem onClick={handleShow}>
          Switch User {selectedUser && selectedUser.name}
        </XtNavDropdownItem>
      )}

      <XtModal {...modalProps}>
        <div className="nw-switch-user">
          <div className="row align-items-center">
            <div className="d-flex col mb-3">
              <h5 className="mb-0 mr-3 p-2">Select user to switch </h5>
              <XtAdvancedSearchBox
                searchPlaceHolder="Search"
                hideAdvancedSearch={true}
                onSearchConditionApply={onSearchCondition}
              />
            </div>
          </div>

          <Grid
            data={filterdUser}
            onPageChange={onPageChange}
            onRowClick={onGridRowClick}
            pageable={true}
            skip={page.skip}
            take={page.take}
            total={dataCount}
            pageSize={page.take}
          >
            <GridColumn field="displayname" title="Name" />
            <GridColumn field="workEmail" title="Email" />
            <GridColumn field="department" title="Department" />
          </Grid>
        </div>
        {loading && <Loading />}
      </XtModal>
    </>
  );
}
