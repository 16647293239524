import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Redirect, Link } from "react-router-dom"

export function XtNavLeftItem(props) {

    //console.log(props.keyVal);
    const activeClass = props?.activeKey !=="" && props?.activeKey===props?.keyVal? "active" :"";
    return (
          <>
              {props.header &&
                  <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={
                          <Tooltip id={`tooltip-right`}>
                              {props.tooltip != null ? props.tooltip : ""}
                          </Tooltip>
                      }
                  >
                      <Accordion.Header>
                          <i className="material-icons">
                              {props.icon != null ? props.icon : ""}
                          </i>
                          <Link to={props.to??""} >
                            <span className="accordion-text">
                                {props.text != null ? props.text : ""}
                            </span>
                          </Link>
                      </Accordion.Header>
                  </OverlayTrigger>
              }
              {!props.header && (
                  <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={
                          <Tooltip id={`tooltip-right`}>
                              {props.tooltip != null ? props.tooltip : ""}
                          </Tooltip>
                      }
                  >
                      <div className="sidebar-item">
                          <h2 className="accordion-header">
                              <button type="button" className={`accordion-button collapsed ${activeClass}`} onClick={(e)=> props.setActiveItem(props?.keyVal? ""+props?.keyVal:"")}>
                                  <i className="material-icons">
                                      {props.icon != null ? props.icon : ""}
                                  </i>
                                  <Link to={props.to??""}>
                                    <span className="accordion-text">
                                      {props.text != null ? props.text : ""}
                                     
                                  </span>
                                  </Link>

                                 
                              </button>
                          </h2>
                      </div>
                  </OverlayTrigger>
              )}
              {props.menucollapse && (
                  <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={
                          <Tooltip id={`tooltip-right`}>
                              {props.tooltip != null ? props.tooltip : ""}
                          </Tooltip>
                      }
                  >
                      <div className="sidebar-item">
                          <h2 className="accordion-header">
                              <button type="button" className={`accordion-button collapsed ${activeClass}`} onClick={(e)=> props.setActiveItem(props?.keyVal? ""+props?.keyVal:"")}>
                                  <i className="material-icons">
                                      {props.icon != null ? props.icon : ""}
                                  </i>
                                  <Link to={props.to??""}>
                                  <span className="accordion-text">
                                    
                                      {props.text != null ? props.text : ""}
                                  </span>
                                  </Link>
                              </button>
                          </h2>
                      </div>
                  </OverlayTrigger>
              )}
          </>
          );
  }