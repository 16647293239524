import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { APIFactory, GroupApi, Group } from '../../../../Xt/ApiClients';
import { XtForm } from 'nwcommon';
import { XtField } from 'nwcommon';


export function XtAddEditGroupView(props: any) {

    let factory: APIFactory;

    const onSubmit = async (e) => {
        let s: GroupApi = factory.create(GroupApi);
        if (props.dataItem?.groupGuid) {
            let result = await s.groupPut(e.postData, props.dataItem.groupGuid);
            if (result.ok) {
                if (props.onClose) {
                    props.onClose({ ...e, reloadData: true });
                    return;
                }
            }
            else {
                // TODO: show error
            }
        }
        else {
            let result = await s.groupPost(e.postData);
            if (result.ok) {
                if (props.onClose) {
                    props.onClose({ ...e, reloadData: true });
                    return;
                }
            }
            else {
                // TODO: show error
            }
        }
    }

    const onCancel = (e) => {
        if (props.onClose) {
            props.onClose(e)
        }
    }

    return (
        <>
            {<Dialog title={props.title} onClose={props.onClose} {...props} width={"50%"}>
                <APIFactory ref={(e) => { factory = e; }} />
                <XtForm onSubmit={onSubmit}>
                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Group Name</Form.Label>
                            <XtField name="groupName" defaultValue={ props.dataItem?.groupName } placeholder="Group Name" type="text" required={true} errormessage="Please enter group name" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <XtField name="description" defaultValue={props.dataItem?.description} placeholder="Description" type="textarea" errormessage="Please enter description" />
                        </Form.Group>
                    </Row>
                    <DialogActionsBar>
                        <Button type="submit" variant="primary">Submit</Button>
                        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                    </DialogActionsBar>
                </XtForm>
            </Dialog>}
        </>
    )
}