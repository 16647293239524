export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col align-items-center">
              <a
                className="px-0 me-3"
                href="https://northwindgrp.com"
                target="_blank"
              >
                <img src="../../images/footer-logo.svg" width="120px" />
              </a>
              <small className="ps-3">© {currentYear}</small>
              <small className="ps-3">v{process.env.REACT_APP_VERSION}</small>
            </div>
            <div className="col-auto">
              <div className="small float-end">
                <span>
                  <span className="footer-anchor">Restriction on Use</span>
                  <span className="footer-popup footer-popup-lg">
                    <span>
                      All true.North content is the sole property of North Wind
                      Group and is intended solely for internal company use. Any
                      use of the information contained on true.North for
                      purposes other than official internal North Wind Group
                      purposes is
                      <span className="font-weight-bolder">
                        EXPRESSLY PROHIBITED
                      </span>{" "}
                      without the prior expressed permission of the North Wind
                      Group Communications Department.
                      <a href="mailto:pr@northwindgrp.com" target="_blank">
                        <i>pr@northwindgrp.com</i>
                      </a>
                      .
                    </span>
                  </span>
                </span>{" "}
                |
                <span>
                  <a
                    className="footer-anchor"
                    href="https://north-wind-group.breezy.hr/team/portal"
                    target="_blank"
                  >
                    Careers
                  </a>
                </span>{" "}
                |
                <span>
                  <span className="footer-anchor">Mission</span>
                  <span className="footer-popup footer-popup-lg">
                    <span>
                      To be the nationwide small business leader in the
                      engineering, environmental, construction, and technical
                      services industries providing creative and practical
                      solutions to complex problems, while delivering safely, on
                      time, on budget, every time.
                      <br />
                      <br />
                      The North Wind Group of companies is proudly owned by the
                      Cook Inlet Region, Inc., also known as CIRI. As one of the
                      leading Alaska Native Corporations, CIRI owns and operates
                      a variety of businesses through-out the United States.
                      Detailed information about CIRI will be highlighted on
                      true.North and may also be found at{" "}
                      <a href="http://www.ciri.com" target="_blank">
                        <i>www.ciri.com</i>
                      </a>
                      .
                    </span>
                  </span>
                </span>{" "}
                |
                <span>
                  <span className="footer-anchor">Compliance Hotline</span>
                  <span className="footer-popup">
                    <span className="broder-bottom">
                      <b>NW Employee Concerns Hotline:</b>
                      Website:{" "}
                      <a href="http://www.safehotline.com" target="_blank">
                        SAFEHOTLINE.COM
                      </a>
                      <br />
                      Call: 1-855-662-SAFE
                      <br />
                      <u>Company Code: 8613812553</u>
                    </span>
                    <span className="mb-2">
                      <b>CIRI Compliance Hotline:</b>
                      Call: (888) 228-7798
                      <br />
                      Compliance Hotline
                      <br />
                      P.O. BOX 93330
                      <br />
                      Anchorage, AK 99509-3330
                      <br />
                    </span>
                    <br />
                    <a
                      href="https://northwindgrp.sharepoint.com/sites/E_AND_C/SitePages/Home.aspx"
                      target="_blank"
                    >
                      <b>North Wind Code of Ethics and Compliance</b>
                    </a>
                  </span>
                </span>{" "}
                |
                <span>
                  <span className="footer-anchor">Quality Policy</span>
                  <span className="footer-popup footer-popup-lg">
                    <span className="">
                      <b>North Wind Quality Policy</b>
                    </span>
                    <span>
                      North Wind is committed to providing the highest quality
                      goods and services to our customers in a timely,
                      cost-effective manner. We believe that quality is achieved
                      through effective planning, control of operations,
                      management and independent oversight, and process
                      improvement. <br />
                      <br />
                      North Wind believes the foundation of the Quality
                      Management System is built upon a culture where the
                      primary objectives are safety, value, and quality. All
                      employees are expected to perform their responsibilities
                      in accordance with the applicable Quality Management
                      System requirements and strive for customer satisfaction
                      and continual improvement.
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
