import { Button } from "react-bootstrap";

interface ICloneButtonProps {
  loadClone: () => void;
  loading: boolean;
}
export default function CloneButton(props: ICloneButtonProps) {
  const { loadClone, loading } = props;

  return (
    <Button
      type="button"
      variant="secondary"
      onClick={() => {
        loadClone();
      }}
      disabled={loading}
      className="max-w-5"
    >
      Clone
    </Button>
  );
}
