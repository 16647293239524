import React from "react"
import { XtFileType } from "./XtFile";
import { XtFileContainer } from "./XtFileContainer"

export type XtInlineBrowserProps = React.PropsWithChildren<{
    paneHeight: string
    path: string
    files: XtFileType[]
    onFolderOpen: any
    onUpload: any
    allowChangeFolder: boolean
    onFolderCreate?: any
}>;

export function XtInlineBrowser(props: XtInlineBrowserProps) {
    
    return (<div>
        <XtFileContainer {...props}/>
    </div>)
}