import React, { useState } from "react";
import { Button, Container, NavLink } from "react-bootstrap";
import { enum_pr_status } from "../NwPrConstants";
import { Redirect } from "react-router-dom";

export default function PoLinkGridCell(props) {
  let poNumber: string = props.dataItem["pO_ID"];
  if (poNumber === "" || poNumber === undefined)
    poNumber = props.dataItem["poNumber"];
  const prGuid: string = props.dataItem["pO_ID"];

  const [redirect, setRedirect] = useState({ shouldRedirect: false, url: "" });

  return (
    <>
      {redirect.shouldRedirect && <Redirect push to={redirect.url} />}
      <td>
        <a
          // href=""
          onClick={(e) =>
            setRedirect({
              shouldRedirect: true,
              url: "/po/details/" + poNumber,
            })
          }
          className="text-primary"
        >
          {" "}
          {poNumber}
        </a>
      </td>
    </>
  );
}
