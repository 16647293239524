import { useContext, useRef, useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Accordion, Button } from "react-bootstrap";
import { APIFactoryCommon } from "../../../../Xt/ApiClients/Common";
import {
  APIFactory,
  InvoiceApi,
  PurchaseOrderApi,
  PurchaseRequisitionApi,
  InvoiceCoding,
} from "../../../../Xt/ApiClients";
import { XtForm } from "nwcommon";
import { Loading } from "../../../../Xt/Layout/Loading";
import { XtBreadcrumbs } from "../../../../Xt/Layout/XtBreadcrumbs";
import { AddInvoiceHeader } from "./AddInvoice-Header";
import { AddInvoiceInvoiceCoding } from "./AddInvoice-InvoiceCoding";

import { NwPrConstants } from "../../Nw/PurchaseRequisition/Common/NwPrConstants";
import XtAccordianItem from "../../Nw/PurchaseRequisition/Common/XtAccordianItem";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import LoadingDailog, {
  EnConfirmationMessageType,
  LoadingDailogProps,
} from "../../Dialogs/Common/LoadingDailog";
import ErrorDialog from "../../Dialogs/Common/ErrorDialog";
import { PoCostDetails } from "../Common/PoCostDetails";
import { ErrorUtil } from "../../../../Xt/Utils/ErrorUtil";
import { InvoiceConstants } from "../Common/InvoiceConstants";
import { XtFormUtil } from "../../../../Xt/Utils/XtFormUtil";
import { NumberUtil } from "../../../../Xt/Utils/NumberUtil";

const breadCrumbs = {
  breadCrumbs: [
    {
      href: "/invoice",
      text: "Invoice List",
      isActive: false,
    },
    {
      href: "/invoice/add",
      text: "Create Invoice",
      isActive: true,
    },
  ],
};

export function AddInvoice(props: any) {
  let factory: APIFactory;
  let factoryCommon: APIFactoryCommon;
  const context: IXtContext = useContext(XtContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [runningCost, setRunningCost] = useState<number>(0);
  const [po, setPO] = useState<any>(null);
  const [selectedPO, setSelectedPO] = useState<any>(null);
  const [invoiceCoding, setInvoiceCoding] = useState<any[]>([]);
  const [reviewers, setReviewers] = useState<any[]>([]);
  const [redirectTo, setRedirectTo] = useState<string>("");

  const [invCodeRunningTotals, setInvCodeRunningTotals] = useState<any>({});

  const [errors, setErrors] = useState([]);
  const [submitLoading, setSubmitLoading] = useState<LoadingDailogProps>({
    show: false,
  });
  const [loadAccountSpecificUsers, setLoadAccountSpecificUsers] =
    useState(null);
  const saveRef = useRef(null);

  let { poNumber } = useParams();

  useEffect(() => {
    if (poNumber && context?.loggedOnUser?.username) {
      setSelectedPO({ text: poNumber, value: poNumber });
      loadPO(poNumber).then(() => {});
    }
  }, [poNumber, context?.loggedOnUser?.username]);

  const onPONumberChange = async (e) => {
    if (e?.value) {
      loadPO(e?.value?.value);
    } else {
      setPO(null);
      setInvoiceCoding([]);
      setRunningCost(0);
      setReviewers([]);
    }
  };

  const loadPO = async (poNumber) => {
    try {
      setLoading(true);
      const c: PurchaseOrderApi =
        factory.create<PurchaseOrderApi>(PurchaseOrderApi);
      var result = await c.purchaseOrderPoIDGet(
        poNumber,
        context?.loggedOnUser?.username
      );
      let nPO: any = result;
      setPO(result);

      let loadSpecificUser = false;
      let codingList: any[] = [];
      nPO?.poLineItemList.map((v) => {
        if (v.itemID) {
          //setting poLineDescription and invoice Line Description
          //This is the case where we are loading invoice coding
          // from poLineItem
          v.poLineDescription = v.lineDescription;
          v.description = undefined;

          if (
            InvoiceConstants.AccountCodesForRepFilters.includes(v?.accountType)
          )
            loadSpecificUser = true;

          codingList.push(v);
        }
      });
      setLoadAccountSpecificUsers(loadSpecificUser);

      setInvoiceCoding(codingList);

      setRunningCost(nPO?.runningTotal);

      // await loadReviewers(invApi, prApi, poNumber);
      setLoading(false);
    } catch (ex) {
      if (ex?.message) {
        setErrors([
          `An error occurred while getting purchase order details for ${poNumber}.`,
          `Error: ${ex.message}`,
        ]);
      } else {
        let msg = await ex?.json();
        if (msg) {
          const errors = ErrorUtil.getErorMessageFromApi(msg);
          setErrors(errors);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const loadReviewers = async (
    c: InvoiceApi,
    prApi: PurchaseRequisitionApi,
    poNumber
  ) => {
    var result1 = await c.invoiceApprovalsPONumberGet(poNumber);
    if (result1.length > 0) {
      var d: any = result1[0];
      var result = await prApi.purchaseRequisitionApprovalFlowKeyGet(
        context?.loggedOnUser?.username,
        d.purchaseRequisitionGuid
      );
      var data = await result.json();
      let d1 = [];
      data?.value?.map((v) => {
        if (v.role !== "Quality Manager") {
          d1.push(v);
        }
      });
      setReviewers(d1);
    }
  };

  const onSubmit = async (e) => {
    const submitErrors = [];
    const actionType =
      e?.nativeEvent?.submitter?.id == "submit" ? "Submit" : "Save";
    //if (actionType == "Save" && e?.postData?.PONumber === "") {
    //setErrors([`PO Number is required.`]);
    //return;
    //}

    var postData = e.postData;
    postData.VendorAmount = postData.VendorAmount.replace(/[^\d\.\-]/g, "");
    var total = 0;
    postData.POTotalAmount = po?.totalAmount;
    postData.poRunningTotalAmount = runningCost;
    postData.invoiceCodings = [];
    postData.InvoiceDate = new Date(postData.InvoiceDate);
    postData.VendorAmount =
      postData.VendorAmount === "" ? 0 : postData.VendorAmount;
    postData.actionType = actionType;

    invoiceCoding.map((v, i) => {
      let c: InvoiceCoding = {};
      if (v.lineAccountList.length > 0) {
        c.projectNumber = v.lineAccountList[0].mM_POLNACCT_PROJ_ID;
        c.accountNumber = v.lineAccountList[0].accT_ID;
        c.orgId = v.lineAccountList[0].orG_ID;
        c.projectName = v.lineAccountList[0].proJ_NAME;
        c.accountName = v.lineAccountList[0].accT_NAME;
        c.accountGroupCode = v.lineAccountList[0].accountGroupCode;
      }
      c.accountType = v.accountType;
      c.sno = v.lineNumber;
      c.poLineNumber = v.lineNumber;
      c.poNumber = po?.poNumber;
      c.amount = parseFloat(v.unitPrice);
      c.description = v.description;
      // c.poLineDescription = "";
      //c.invoiceLineDescription = v.invoiceLineDescription ;//TODO: need to add this to api

      // total += c.amount;
      total = NumberUtil.decimalNumSum(total, c.amount);
      postData.invoiceCodings.push(c);

      //   if (actionType == "Submit" && c.amount > parseFloat(v.committedAmount))
      //     submitErrors.push(
      //       `- #${c.sno}: Invoice coding amount cannot be more than committed amount.`
      //     );
    });

    if (actionType === "Submit") {
      if (postData?.attachments.length === 0) {
        submitErrors.push("- At least one attachment is required.");
      }
      //   if (runningCost > po?.totalAmount) {
      //     submitErrors.push(
      //       "- PO running total cannot be more than PO committed amount."
      //     );
      //   }

      if (
        total !== NumberUtil.formatToTwoDecimal(Number(postData.VendorAmount))
      ) {
        submitErrors.push(
          `- Total invoice coding amount is not equal to invoice amount.`
        );
      }

      if (postData?.VendorInvoiceNo.length > 15) {
        submitErrors.push(
          `- Vendor Invoice Number cannot be more than 15 characters.`
        );
      }

      if (submitErrors.length > 0) {
        setErrors(submitErrors);
        return;
      }
    }
    //setLoading(true);
    setSubmitLoading({
      show: true,
      message: ["Invoice is being created....."],
      messageType: EnConfirmationMessageType.Wait,
    });

    try {
      let invApi: InvoiceApi = factory.create(InvoiceApi);
      let result = await invApi.invoicePost(
        context?.loggedOnUser?.username,
        postData
      );

      if (result.ok) {
        const newInvoice = await result.json();
        setDailogSuccess(newInvoice?.invoiceID, postData.actionType);
      } else {
        let msg = await result.json();
        const errors = ErrorUtil.getErorMessageFromApi(msg);
        setDailogError(errors);
      }
    } catch (ex) {
      let msg = await ex.json();
      const errors = ErrorUtil.getErorMessageFromApi(msg);
      setDailogError(errors);
    }
  };

  const setDailogError = (errorMessage: string[]) => {
    setSubmitLoading({
      show: true,
      title: "Error",
      messageType: EnConfirmationMessageType.Error,
      message: errorMessage,
      onClose: () => {
        setSubmitLoading({ show: false });
      },
    });
  };
  const setDailogSuccess = (invoiceId: string, actionType: string) => {
    const message =
      actionType == "Submit"
        ? `Invoice ${invoiceId} has been successfully created.`
        : `Invoice ${invoiceId} has been successfully saved as draft.`;
    const title =
      actionType == "Submit"
        ? "Invoice Creation Successful"
        : "Invoice Saved as Draft";
    setSubmitLoading({
      show: true,
      message: [message],
      title: title,
      messageType: EnConfirmationMessageType.Success,
      onClose: () => {
        setRedirectTo("/invoice");
        setSubmitLoading({ show: false });
      },
      onCreateNew: () => {
        setRedirectTo("/invoice/add");
        setSubmitLoading({ show: false });
        resetForm();
      },
      isInvoice: true,
    });
  };

  const resetForm = () => {
    setInvoiceCoding([]);
    setInvCodeRunningTotals({});
    setErrors([]);
    setSubmitLoading({ show: false });
    setPO(null);
    setRunningCost(0);
  };

  const onInvoiceLineItemChange = (e, row) => {
    if (e?.field === "unitPrice") {
      let index = invoiceCoding.indexOf(row);
      let invCodingRunningTotalTemp = { ...invCodeRunningTotals };
      var runningTotal = invoiceCoding[index].runningTotal;
      const invoiceCodingAmount = parseFloat(e.value || 0);

      runningTotal = NumberUtil.decimalNumSum(
        runningTotal,
        invoiceCodingAmount
      );

      invCodingRunningTotalTemp[index] = runningTotal;
      setInvCodeRunningTotals(invCodingRunningTotalTemp);
      invoiceCoding[index].unitPrice = invoiceCodingAmount;
      let totalRunningAmount = 0;
      invoiceCoding.map((v, i) => {
        totalRunningAmount = NumberUtil.decimalNumSum(
          invCodingRunningTotalTemp[i] ?? invoiceCoding[i].runningTotal,
          totalRunningAmount
        );
      });

      setRunningCost(totalRunningAmount);
    } else if (e?.field === "description") {
      let index = invoiceCoding.indexOf(row);
      invoiceCoding[index].description = e.value || "";
    }

    setInvoiceCoding(invoiceCoding);
  };

  const activeSections = ["0", "1", "2"];

  const onAdditionalReviewerAdded = (e) => {
    reviewers.push({
      level: e.stage,
      displayName: e.displayName,
      userGuid: e.userGuid,
      role: NwPrConstants.pr_additional_reviewer,
      status: "Yet to assign",
    });
    let r = reviewers.sort((v, v1) => {
      if (v1.level < v.level) return -1;
      else if (v1.level === v.level) return 0;
      else return 1;
    });
    setReviewers(r);
  };

  const onSave = (e) => {
    let fe = saveRef.current.form.elements;
    const postData = XtFormUtil.getPostData(fe);
    onSubmit({ ...e, postData: postData });
  };

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <APIFactoryCommon
        ref={(e) => {
          factoryCommon = e;
        }}
      />
      <XtBreadcrumbs {...breadCrumbs} />

      {redirectTo !== "" && <Redirect push to={{ pathname: redirectTo }} />}

      <div className="pr-create-new">
        <XtForm onSubmit={onSubmit}>
          <Accordion defaultActiveKey={activeSections} alwaysOpen>
            <XtAccordianItem header="INVOICE DETAIL " eventKey="0">
              <AddInvoiceHeader
                onPOChange={onPONumberChange}
                purchaseOrder={po}
                loadAccountSpecificUsers={loadAccountSpecificUsers}
              />
              <br />
              <input
                type="hidden"
                name="POTotalAmount"
                value={po?.trN_PO_TOT_AMT}
              />
              <input
                type="hidden"
                name="PORunningTotalAmount"
                value={runningCost}
              />
            </XtAccordianItem>

            <XtAccordianItem header="PO Cost Details" eventKey="1">
              <PoCostDetails
                committedAmount={po?.totalAmount}
                costToDate={po?.costToDateAmount}
                runningTotalCost={runningCost}
              />
            </XtAccordianItem>

            <XtAccordianItem header="INVOICE CODING" eventKey="2">
              <AddInvoiceInvoiceCoding
                data={invoiceCoding}
                onInvoiceLineItemChange={onInvoiceLineItemChange}
                invCodeRunningTotals={invCodeRunningTotals}
              />
            </XtAccordianItem>
          </Accordion>

          <DialogActionsBar layout="end">
            <Button
              type="button"
              className="me-2"
              variant="secondary"
              onClick={() => setRedirectTo("/invoice")}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="button"
              id="save"
              variant="secondary"
              disabled={loading}
              ref={saveRef}
              onClick={onSave}
            >
              Save
            </Button>
            <Button
              type="submit"
              id="submit"
              variant="primary"
              disabled={loading}
            >
              Submit
            </Button>
          </DialogActionsBar>
        </XtForm>

        {loading && <Loading />}

        {errors.length > 0 && (
          <ErrorDialog errorMessage={errors} onClose={() => setErrors([])} />
        )}
        {submitLoading && submitLoading.show && (
          <LoadingDailog {...submitLoading} />
        )}
      </div>
    </>
  );
}
