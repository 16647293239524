import { useState } from 'react';
import { NwApplicationMenuProps } from '../../Props/NwMenu/NwApplicationMenuProps';
import defaultIconPath from "../../../images/logo.svg";
import { APIFactoryCommon, UserFavoriteApplicationApi } from '../../ApiClients/Common/index';


export default function NwApplicationItem(props: NwApplicationMenuProps) {

    let factory: APIFactoryCommon;

    const [iconPath, setIconPath] = useState(props.iconFullPath);
    const [isFavorite, setIsFavorite] = useState(props.isFavorite);
    const [applicaitonId, setApplicaitonId] = useState(props.applicationId);
    const canShowPlusIcon = (!isFavorite);
    const canShowMinusIcon = isFavorite;

    //If any error load default icon
    const setDefaultImage = () => {
        setIconPath(defaultIconPath)
    };

    const updateFavorite = (event) => {
        
        let userFavoriteAppApi: UserFavoriteApplicationApi = factory.create(UserFavoriteApplicationApi);
        if (isFavorite) {
            userFavoriteAppApi.userFavoriteApplicationApplicationIdDelete(applicaitonId).then(async (appData) => {
                setIsFavorite(false);
            })
        }
        else {
            setIsFavorite(true);
            userFavoriteAppApi.userFavoriteApplicationPost({ applicationId: applicaitonId }).then(async (appData) => {
                setIsFavorite(true);
            })
        }

        event.preventDefault();
    }

    return (
        <>
            <APIFactoryCommon ref={(e) => { factory = e; }} />
            <a href={props.url} className="nw_megamenu-right-item" target="_blank" title={props.description} >
                <span data-tooltip={props.description} className="app-img">
                    <img src={iconPath} alt={props.title} onError={() => setDefaultImage()} />
                    {canShowPlusIcon && <i className="material-icons" onClick={updateFavorite}></i>}
                    {canShowMinusIcon && <i className="material-icons favourite" onClick={updateFavorite}></i>}
                </span>
                <span>{props.title} </span>

            </a>
        </>

    )

}
