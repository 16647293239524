import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { XtField } from "nwcommon";
import { useContext, useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";

import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { RequiredFormField, SmallFormField } from "../Components/FormField";
import PopOverIcon from "../Components/PopOverIcon";
import { EnPrrAction, State } from "./PrrReducer";
import { getLocationList } from "../../../../../api/react-query";
import { MINUTE } from "../../../../../utils/constants";

export default function LocationForm(props) {
  const MAX_LOCATIONS = 3;
  let locationIdCounter = 0;
  const state: State = props.state;
  const dispatch: any = props.dispatch;
  const context: IXtContext = useContext(XtContext);
  const [editIndex, setEditIndex] = useState(null);
  const [locations, setLocations] = useState([{ idx: 0, city: "", state: "" }]);
  const [stateList, setStateList] = useState([]);

  const token = context?.tokens?.accessToken;
  const username = context?.loggedOnUser?.username;

  const {
    data: locationList,
    isLoading: isLocationListLoading,
    isFetching: isLocationListFetching,
  } = useQuery(["locations", token, username], getLocationList, {
    enabled: !!token && !!username,
    placeholderData: [],
    staleTime: Infinity,
    cacheTime: 20 * MINUTE,
  });

  useEffect(() => {
    if (!locationList) return;

    const states = locationList.map((location) => {
      return {
        text: location.name,
        value: location.name,
        region: location.region,
      };
    });

    setStateList(states);
  }, [locationList]);

  const handleChange = (index, field, value) => {
    const newLocations = [...locations];
    newLocations[index][field] = value;
    newLocations[index].idx = state?.locationList.length + 1;
    setLocations(newLocations);
  };

  const onRowClick = (e) => {
    const rowIndex = state?.locationList.findIndex(
      (location) => location === e.dataItem
    );
    setEditIndex(rowIndex);
    setLocations([e.dataItem]);
  };

  const deleteLocation = () => {
    dispatch({
      type: EnPrrAction.LOCATIONS_LIST_DELETE,
      payload: editIndex,
    });
    setLocations([{ idx: 0, city: "", state: "" }]);
    setEditIndex(null);
  };

  const locationInfo = (
    <PopOverIcon header="Info">
      <div>
        <p>- Max. of three locations. List locations in order of preference.</p>
        <p>
          - If the location is within the United States, please enter the city
          name.
        </p>
        <p>
          - If the location is within the United States, please enter the state
          name.
        </p>
        <p>
          - If the location is outside the United States, please enter the
          country name in both fields.
        </p>
      </div>
    </PopOverIcon>
  );

  const handleSubmit = () => {
    //Checks if the state or city is empty
    if (locations[0].city === "" || locations[0].state === "") {
      return;
    }
    locationIdCounter++;
    const locationWithId = { ...locations[0], id: locationIdCounter };
    locationIdCounter++;
    if (editIndex !== null) {
      dispatch({
        type: EnPrrAction.LOCATIONS_LIST_EDIT,
        payload: { index: editIndex, location: locationWithId },
      });
    } else {
      dispatch({
        type: EnPrrAction.LOCATIONS_LIST_ADD,
        payload: locationWithId,
      });
    }
    setLocations([{ idx: 0, city: "", state: "" }]);
    setEditIndex(null);
  };

  return (
    <div>
      <RequiredFormField
        label={"Location(s) - Max of 3"}
        info={locationInfo}
        classname="align-content"
      >
        {(state?.locationList?.length < MAX_LOCATIONS ||
          editIndex !== null) && (
          <>
            {locations.map((location, index) => (
              <div key={index}>
                <Col sm={6}>
                  <SmallFormField>
                    <XtField
                      type="text"
                      textField="city"
                      placeholder="City"
                      value={location?.city || ""}
                      onChange={(e) =>
                        handleChange(index, "city", e?.target?.value)
                      }
                      required={state?.locationList?.length === 0}
                      errormessage="City is required"
                    />
                  </SmallFormField>
                </Col>

                <Col sm={6}>
                  <SmallFormField>
                    <XtField
                      type="dropdown"
                      placeholder="State / Country"
                      items={stateList}
                      textField="text"
                      value={location?.state || null}
                      onChange={(e) =>
                        handleChange(index, "state", e?.target?.value?.value)
                      }
                      required={state?.locationList?.length === 0}
                      errormessage="State/Country is required"
                    />
                  </SmallFormField>
                </Col>
                <Button onClick={handleSubmit}>Add</Button>
              </div>
            ))}
          </>
        )}
        {state?.locationList && state?.locationList?.length > 0 && (
          <Grid
            data={state?.locationList ? state?.locationList : null}
            style={{ marginTop: "20px" }}
            onRowClick={onRowClick}
          >
            <GridColumn field="idx" title="#" width={35} />
            <GridColumn field="city" title="City" />
            <GridColumn field="state" title="State/Country" />
          </Grid>
        )}
        {editIndex !== null && state?.locationList?.length !== 0 && (
          <div>
            <Button onClick={deleteLocation} style={{ marginTop: "10px" }}>
              Delete Selected Location
            </Button>
          </div>
        )}
      </RequiredFormField>
    </div>
  );
}
