import React from "react";
export interface KeyPersonnelAlertProps {
  alertMessage?: string;
}
export default function KeyPersonnelAlert(props: KeyPersonnelAlertProps) {
  return (
    <>
      {props?.alertMessage && props?.alertMessage !== "" && (
        <p className="alert alert-danger">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            className="me-2"
          >
            <path
              d="M1 21 12 2l11 19Zm3.45-2h15.1L12 6ZM12 18q.425 0 .713-.288Q13 17.425 13 17t-.287-.712Q12.425 16 12 16t-.712.288Q11 16.575 11 17t.288.712Q11.575 18 12 18Zm-1-3h2v-5h-2Zm1-2.5Z"
              fill="currentColor"
            ></path>
          </svg>
          {props?.alertMessage}
        </p>
      )}
    </>
  );
}
