import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { XtField, XtGrid } from "nwcommon";

import { AdvSearchAndGridUtil } from "../../../../../Xt/Utils/AdvanceSearchAndGridUtil";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

import {
  APIFactory,
  PurchaseRequisition,
  PurchaseRequisitionApi,
} from "../../../../../Xt/ApiClients";
import { PrApiService } from "../Common/PrApiService";
import NwWorkflowReviewerCell from "../Common/GridCell/WorkflowReviewerCell";
import PrCompletedGridCell from "../Common/GridCell/PrCompletedGridCell";
import AddRerviewerDailog from "./AddRerviewerDailog";
import ApprovalFlowComments from "../Common/GridCell/ApprovalFlowCommentsGridCell";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import PopOverGridCell from "../Common/GridCell/PopOverGridCell";

interface WorkflowReadOnlyProps {
  prGuid: string;
  isApprover: boolean;
  inProgressWfForCurrentUser: any;
  designatorList?: any[];
}

export default function WorkflowReadOnly(props: WorkflowReadOnlyProps) {
  let factory: APIFactory;
  const init = true;
  const [workflowData, setWorkflowData] = useState([]);
  const [showAddReviewerDialog, setShowReviewerDialog] = useState(false);
  const [additionalReviewer, setAdditionalReviewer] = useState(null);

  const context: IXtContext = useContext(XtContext);

  const loadWorkflowData = () => {
    let prApi: PurchaseRequisitionApi = factory.create(PurchaseRequisitionApi);
    PrApiService.getApprovalFlowByGuid(context, prApi, props.prGuid).then(
      (data) => {
        if (data?.length > 0) {
          const inprogressRecord = data.find(
            (r) =>
              r.isActive &&
              r?.displayName === context?.loggedOnUser?.displayname
          );
          props.inProgressWfForCurrentUser(inprogressRecord);

          const wfData = data.map((r) => {
            const currentUserIsDesignee = props?.designatorList?.find(
              (d) => d.displayname === r.displayName
            );
            if (currentUserIsDesignee)
              return {
                ...r,
                designee: context?.loggedOnUser?.displayname,
              };
            else return { ...r, designee: "" };
          });

          setWorkflowData(wfData);
        } else setWorkflowData([]);
      }
    );
  };
  useEffect(() => {
    if (
      props?.prGuid &&
      context?.loggedOnUser?.username &&
      props?.designatorList
    ) {
      if (props?.prGuid && context?.loggedOnUser) {
        loadWorkflowData();
      }
    }
  }, [props?.prGuid, context?.loggedOnUser?.username, props?.designatorList]);

  const onAddReviwerIconClick = (e) => {
    const additionalReviewerLevel = {
      role: e?.dataItem?.role,
      stage: e?.dataItem?.stage,
      level: e?.dataItem?.level,
      displayName: e?.dataItem?.displayName,
    };
    setAdditionalReviewer(additionalReviewerLevel);
    setShowReviewerDialog(true);
  };

  const onAddReviewerButtonClickFromDailog = async (e) => {
    if (e.data && e.data.selectedUser && e.data.selectedRole) {
      let prApi: PurchaseRequisitionApi = factory.create(
        PurchaseRequisitionApi
      );

      let result = await factory.callAPI(
        prApi.purchaseRequisitionApproverPost(
          context?.loggedOnUser?.username,
          props?.prGuid,
          e.data.selectedUser.userGuid
        )
      );
      if (result.ok) {
        loadWorkflowData();
        setShowReviewerDialog(false);
      } else {
        let data = await result.json();
        let message = "";
        for (const e in data.errors) {
          const errorData = data.errors[e];
          message = message + errorData[0] + ";";
        }
        factory.showErrorMessage(message, data.title);
      }
    }
  };

  const minStageValue = () => {
    if (workflowData && workflowData.length > 0) {
      const minValue = workflowData.reduce(function (prev, curr) {
        return prev.stage < curr.stage ? prev : curr;
      });
      return minValue.stage;
    }
    return 1;
  };

  const maxStageValue = () => {
    if (workflowData && workflowData.length > 0) {
      const maxValue = workflowData.reduce(function (prev, curr) {
        return prev.stage > curr.stage ? prev : curr;
      });
      return maxValue.stage;
    }
    return 1;
  };

  const reviewCell = (reviewProps) => (
    <NwWorkflowReviewerCell
      {...reviewProps}
      onAddReviewerClick={onAddReviwerIconClick}
      minStage={minStageValue()}
      maxStage={maxStageValue()}
      isApprover={props.isApprover}
      requestType={"PR"}
    />
  );
  const statusCell = (props) => <PrCompletedGridCell {...props} />;
  const approval_workflow_schema = [
    {
      field: "stage",
      label: "Stage",
      visible: true,
      type: "number",
      width: "60px",
    },
    {
      field: "displayName",
      label: "Reviewer",
      visible: true,
      searchable: true,
      width: "250",
      type: "text",
    },
    {
      field: "role",
      label: "Reviewer Role",
      visible: true,
      searchable: true,
      type: "text",
      width: "250",
    },

    {
      field: "status",
      label: "Status",
      visible: true,
      searchable: true,
      type: "text",
      width: "300",
      cell: PrCompletedGridCell,
    },

    {
      field: "comment",
      label: "Comment",
      visible: true,
      searchable: true,
      type: "text",
      cell: PopOverGridCell,
    },
  ];

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <Row className="mb-3">
        <Col sm={12}>
          <Grid data={workflowData} className="k-grid-reviewer-list">
            <GridColumn
              field="addReviewer"
              title=" "
              cell={reviewCell}
              width="50px"
            />

            {AdvSearchAndGridUtil.renderGridFields(approval_workflow_schema)}
          </Grid>
        </Col>
      </Row>
      {showAddReviewerDialog && (
        <AddRerviewerDailog
          additionalReviewer={additionalReviewer}
          onClose={(e) => setShowReviewerDialog(false)}
          onAddClick={onAddReviewerButtonClickFromDailog}
        />
      )}
    </>
  );
}
