import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { XtField } from "nwcommon";
import { number } from "prop-types";
import { NumberUtil } from "../../../../../Xt/Utils/NumberUtil";
import { EnPrAction } from "../Common/PrReducer";
import { enum_request_type } from "../Common/NwPrConstants";
import { ShowNumberDiff } from "../Common/GridCell/AmountGridCell";

export default function NwSubTotal(props) {
 const {state,dispatch} = props;
 let subTotal = Number();
 let totalAmount =Number();
 let lastSubTotalAmount = Number();
 let lastTotalAmount = Number();
 const shippingCost = Number(state?.shippingCost) || 0;
 const taxAmount = Number(state?.taxAmount) || 0;
 const otherAmount = Number(state?.otherAmount) || 0;

 if (state?.purchaseItem?.length > 0) {
  subTotal = state?.purchaseItem?.reduce(
    (sum, current) => NumberUtil.decimalNumSum(sum, current.total),
    0
  );

  lastSubTotalAmount = state?.purchaseItem?.reduce(
    (sum, current) => NumberUtil.decimalNumSum(sum, NumberUtil.roundToTwoDecimal((current.lastUnitPrice ? current.lastUnitPrice : 0) * (current.lastQuantity ? current.lastQuantity : 0))) ,
    0
  );

  totalAmount= subTotal+ shippingCost + taxAmount + otherAmount ;
  lastTotalAmount =  NumberUtil.decimalNumDiff(totalAmount, NumberUtil.decimalNumDiff(subTotal, lastSubTotalAmount));
}

  return (
    <div className="clearfix">
      <table className="table table-striped table-bordered table-line_item-total_section mb-0">
        <tbody>
          {state?.requisitionType == "Material" && (
            <>
              <tr>
                <td height={35}>Sub Total</td>
                <td align="right" className="amount-bold">
               
                
                    {state?.prType !== enum_request_type.PO_CHANGE_REQUEST && (
                      
                       <>
                        {NumberUtil.formatToTwoDecimalWithComma(
                          state?.subTotal
                        )}
                        </>
                      
                    )}

                    {state?.prType === enum_request_type.PO_CHANGE_REQUEST && (
                      <>
                        <ShowNumberDiff
                          currentValue={subTotal}
                          lastValue={lastSubTotalAmount}
                          formatFunction={
                            NumberUtil.formatToTwoDecimalWithComma
                          }
                          
                        />
                      </>
                    )}
                  
                </td>
              </tr>
              <tr>
                <td height={35}> Estimated Shipping Cost</td>
                <td >
                  <XtField
                    type="numeric"
                    name="shippingCost"
                    precision={2}
                    defaultValue={state?.shippingCost}
                    onChange={(e) => {
                      dispatch({
                        type: EnPrAction.ESTIMATED_SHIPPING_COST_CHANGE,
                        payload: Number(e.target.value),
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td height={35}>Estimated Tax Amount</td>
                <td>
                  <XtField
                    type="numeric"
                    name="taxAmount"
                    defaultValue={state?.taxAmount}
                    precision={2}
                    onChange={(e) => {
                      dispatch({
                        type: EnPrAction.TAX_AMOUNT_CHANGE,
                        payload: Number(e.target.value),
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td height={35}>Other</td>
                <td>
                  <XtField
                    type="numeric"
                    name="otherAmount"
                    defaultValue={state?.otherAmount}
                    format="n2"
                    onChange={(e) => {
                      dispatch({
                        type: EnPrAction.OTHER_AMOUNT_CHANGE,
                        payload: Number(e.target.value),
                      });
                    }}
                  />
                </td>
              </tr>
            </>
          )}
          <tr>
            <td height={35}>Total Amount</td>
            <td align="right" className="amount-bold">
            

                {state?.prType !== enum_request_type.PO_CHANGE_REQUEST && (
                  <>
                    {NumberUtil.formatToTwoDecimalWithComma(state?.totalAmount)}
                 </>
                )}
                {state?.prType === enum_request_type.PO_CHANGE_REQUEST && (
                  
                    <ShowNumberDiff
                      currentValue={state?.totalAmount}
                      lastValue={lastTotalAmount}
                      formatFunction={NumberUtil.formatToTwoDecimalWithComma}
                    />
                  
                )}
                
              
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}