import XtSocialLinkItem from './XtSocialLinkItem';
export default function XtSocialLinks(props) {
    return (
        <div className="social-links">
            {props.menuData && props.menuData.map(sl =>
                <XtSocialLinkItem {...sl} />
            )}
        </div>

    );
}
