import { Input, NumericTextBox } from "@progress/kendo-react-inputs";

export default function InvoiceAmountGridCell(props) {
    return (
    <td>
      <NumericTextBox
        width="100%"
        spinners={false}
        value={props.dataItem[props.field]}
        format="c2"
        onChange={(e) =>
          props.onChange({
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.value ,
          })
        }
      />
    </td>);
};


export  function InvoiceTextGridCell(props) {
  
  return (
  <td>
    <Input
      width="100%"
      type="text"
      // value={props.dataItem[props.field]}
     defaultValue={props.dataItem[props.field]}
      onChange={(e) =>
          props.onChange({
          dataItem: props.dataItem,
          field: props.field,
          syntheticEvent: e.syntheticEvent,
          value: e.value,
        })
      }
    />
  </td>);
};