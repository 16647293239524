import { Grid } from "@progress/kendo-react-grid";
import React from "react";
import { APIFactory, AttachmentApi } from "../../../../../Xt/ApiClients";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { AdvSearchAndGridUtil } from "../../../../../Xt/Utils/AdvanceSearchAndGridUtil";
import AttachmentList from "../Common/AttachmentList";
import DeleteAttachmentCell from "../Common/GridCell/DeleteAttachmentCell";
import NwFileLinkGridCell from "../Common/GridCell/FileLinkGridCell";
import { EnPrAction } from "../Common/PrReducer";

const schema = [
  {
    field: "attachmentGuid",
    label: "attachmentGuid",
    visible: false,
    searchable: false,
    type: "string",
  },
  {
    field: "",
    label: "",
    visible: true,
    searchable: false,
    type: "string",
    cell: DeleteAttachmentCell,
    width: 40,
  },

  {
    field: "filePath",
    label: "Uploaded Files",
    visible: true,
    searchable: false,
    type: "string",
    cell: NwFileLinkGridCell,
  },
  {
    field: "attachmentType",
    label: "Attachment Type",
    visible: false,
    searchable: false,
    type: "string",
    //width: 100,
  },

  {
    field: "description",
    label: "Description",
    visible: false,
    searchable: false,
    type: "string",
  },
  {
    field: "createdOn",
    label: "Created Date",
    visible: false,
    searchable: false,
    type: "date",
    format: "{0:MM-dd-yyyy hh:mm}",
  },
];

export default function AttachmentListWithDelete(props) {
  let factory: APIFactory;
  const {state,dispatch} = props;
  let context: IXtContext = React.useContext(XtContext);
  const onDeleteAttachment = (e) => {
   
    //console.log(e);
    
    const attachmentGuid: string = e.dataItem["attachmentGuid"];

    //console.log(attachmentGuid);
    let api: AttachmentApi = factory.create(AttachmentApi);
    api.attachmentKeyDelete(context?.loggedOnUser?.username, attachmentGuid).then( (d)=> {
      //console.log("success");
      
      dispatch({type:EnPrAction.ATTACHMENT_DELTE_FILE, payload: attachmentGuid});

    })
    .catch((e) => {
      console.log(e);
      // factory.showErrorMessage(e, "Error in download");
    });

 
  };
  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <AttachmentList
        {...props}
        schema={schema}
        onDeleteAttachment={onDeleteAttachment}
      />
    </>
  );
}
