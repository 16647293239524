import { IXtMenuData } from "./IXtMenuData";

export class TreeNode {
    Id: number
    Data: any
    Children: TreeNode[]
    Parent: TreeNode

    constructor(id: number, value: any, parent: TreeNode) {
        this.Data = value;
        this.Parent = parent;
        this.Id = id;
        this.Children = [];

    }

    public IsLeaf(): boolean {
        return this.Children.length === 0;
    }

    public HasChildren(): boolean {
        return !this.IsLeaf;
    }

    public IsRoot(): boolean {
        if (this.Parent == null) {
            return true;
        }
        else {
            return false;
        }
    }

}

export class Tree {
    Root: TreeNode;

    constructor(root: TreeNode) {
        this.Root = root;
    }

    public *preOrderTraversal(node = this.Root) {

        yield node;
        if (node.Children.length) {
            for (let child of node.Children) {
                yield* this.preOrderTraversal(child);
            }
        }
    }

    

    public treeVisitor(root,fn) {
        if (fn(root)) return root;
        for (var k = 0; k < root.Children.length; ++k) {
            var found = this.treeVisitor(root.Children[k], fn);
            if (found) return found;
        }
    }

    

    public AddChild(parentId: number, childId: number, data: any) {
        let node = this.Root;       
        const testFn = (x) => {
                return x.Id == parentId;
        }

        var found = this.treeVisitor(node, testFn);
        if (found) {
            found.Children.push(new TreeNode(childId, data, found));
        }
        
        return;

        //for (let node of this.preOrderTraversal()) {
        //    if (node.Id == parentId) {
        //        node.Children.push(new TreeNode(childId, data, node))
        //        return true;
        //    }
        //}
        //return false;

    }

    public *postOrderTraversal(node = this.Root) {
        if (node.Children.length) {
            for (let child of node.Children) {
                yield* this.postOrderTraversal(child);
            }
        }
        yield node;
    }

}



function getChildren ( id:number,parent:TreeNode, menuItems:IXtMenuData[])  
{
    let nodes: TreeNode[] = [];
    menuItems.forEach(item => {
        const node = new TreeNode(id++,{...item}, parent)
        nodes.push(node);
        if(item.children !=null && item.children.length>0)
            node.Children=getChildren(id,node,item.children); 
    }); 
    return nodes;
}

export  function createTree(menuItems:IXtMenuData[] , headerMenu :IXtMenuData  ){
    let rootNode = new TreeNode(-1, {...headerMenu}, null);
    rootNode.Children = getChildren(-1,rootNode,menuItems);
    const tree = new Tree(rootNode);
    return tree;
}