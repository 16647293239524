import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { XtField } from "nwcommon";
import { number } from "prop-types";
import FormLabelMutedText from "../Common/FormLabelMutedText";
import { NumberUtil } from "../../../../../Xt/Utils/NumberUtil";
import { enum_request_type } from "../Common/NwPrConstants";
import { ShowNumberDiff } from "../Common/GridCell/AmountGridCell";

export default function NwSubTotalReadOnly(props) {

 
  const {pr} = props;
  let subTotal = Number();
  let totalAmount =Number();
  let lastSubTotalAmount = Number();
  let lastTotalAmount = Number();

  if (pr?.purchaseItem?.length > 0) {
    subTotal = pr?.purchaseItem?.reduce(
      (sum, current) => NumberUtil.decimalNumSum(sum, current.total),
      0
    );

    lastSubTotalAmount = pr?.purchaseItem?.reduce(
      (sum, current) => NumberUtil.decimalNumSum(sum, NumberUtil.roundToTwoDecimal(current.lastUnitPrice * current.lastQuantity) ) ,
      0
    );

    totalAmount= subTotal+ Number(pr?.shippingCost) + Number(pr?.taxAmount) + Number(pr?.otherAmount) ;
    lastTotalAmount =  NumberUtil.decimalNumDiff(totalAmount, NumberUtil.decimalNumDiff(subTotal, lastSubTotalAmount));
  }
  
  return (
    <div className="clearfix">
      <table className="table table-striped table-bordered table-line_item-total_section mb-0">
        <tbody>
          {pr?.requisitionType == "Material" && (
            <>
              <tr>
                <td height={35}>Sub Total</td>
                <td align="right">
                <span className="text-muted text-truncate p-2 d-block">
    <b>
                  {pr?.prType !== enum_request_type.PO_CHANGE_REQUEST && (
                     <>
                      {NumberUtil.formatToTwoDecimalWithComma(subTotal)}
                      </>
                     
                  )}

                  {pr?.prType === enum_request_type.PO_CHANGE_REQUEST && (
                    <>
                      <ShowNumberDiff
                        currentValue={subTotal}
                        lastValue={lastSubTotalAmount}
                        formatFunction={NumberUtil.formatToTwoDecimalWithComma}
                      />
                    </>
                  )}
                  </b>
                </span> 
                </td>
              </tr>
              <tr>
                <td height={35}> Estimated Shipping Cost</td>
                <td align="right">
                  <FormLabelMutedText
                    value={NumberUtil.formatToTwoDecimalWithComma(
                      pr?.shippingCost
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td height={35}>Estimated Tax Amount</td>
                <td align="right" className="pr-15">
                  <FormLabelMutedText
                    value={NumberUtil.formatToTwoDecimalWithComma(
                      pr?.taxAmount
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td height={35}>Other</td>
                <td align="right">
                  <FormLabelMutedText
                    value={NumberUtil.formatToTwoDecimalWithComma(
                      pr?.otherAmount
                    )}
                  />
                </td>
              </tr>
            </>
          )}
          <tr>
            <td height={35}>Total Amount</td>
            <td align="right">
            <span className="text-truncate p-2 d-block" style={{fontWeight:"bold"}}>
           
              {pr?.prType !== enum_request_type.PO_CHANGE_REQUEST && (
              
               <>
                 
                {NumberUtil.formatToTwoDecimalWithComma(totalAmount)}
                </>
                
              )}

              {pr?.prType === enum_request_type.PO_CHANGE_REQUEST && (
                <ShowNumberDiff
                  currentValue={pr?.totalAmount}
                  lastValue={lastTotalAmount}
                  formatFunction={NumberUtil.formatToTwoDecimalWithComma}
                  bold={true}
                />
              )}
            
            </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
