import {
  PaginationState,
  Action,
  PaginationContext,
  initialState,
} from "./PaginationContext";
import { useReducer, ReactNode } from "react";

const paginationReducer = (
  state: Record<string, PaginationState>,
  action: Action
): Record<string, PaginationState> => {
  switch (action.type) {
    case "UPDATE_PAGINATION":
      return { ...state, [action.entity]: action.payload };
    default:
      return state;
  }
};

export function PaginationProvider({ children }: { children: ReactNode }) {
  const [paginationState, dispatch] = useReducer(
    paginationReducer,
    initialState
  );

  const updatePagination = (
    entity: string,
    newPaginationState: PaginationState
  ) => {
    dispatch({
      type: "UPDATE_PAGINATION",
      entity,
      payload: newPaginationState,
    });
  };

  return (
    <PaginationContext.Provider value={{ paginationState, updatePagination }}>
      {children}
    </PaginationContext.Provider>
  );
}
