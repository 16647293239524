import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useContext, useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { XtField, XtForm } from "nwcommon";
import FormField, {
  RequiredFormField,
  RequiredFormFieldFullRow,
} from "../Common/FormField";
import { enum_request_type } from "../Common/NwPrConstants";
import FormLabelMutedText from "../Common/FormLabelMutedText";
import {
  PurchaseItemApi,
  ResourceAttributeApi as NWResourceAttributeApi,
} from "../../../../../Xt/ApiClients/SwaggerCLI";
import { APIFactory } from "../../../../../Xt/ApiClients";
import { PrApiService } from "../Common/PrApiService";
import { EnPrAction, EnPrTypeDetail } from "../Common/PrReducer";
import { NumberUtil } from "../../../../../Xt/Utils/NumberUtil";
import {
  APIFactoryCommon,
  ResourceAttributeApi,
} from "../../../../../Xt/ApiClients/Common";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { NwNotification } from "../Common/NwNotificaton";
import { formatNumber } from "@progress/kendo-intl";
import { Loading } from "../../../../../Xt/Layout/Loading";

const enum EnPrItemAction {
  ADD_AND_CONTINUE = "ADD_AND_CONTINUE",
  ADD_AND_CLOSE = "ADD_AND_CLOSE",
  EDIT_AND_CLOSE = "EDIT_AND_CLOSE",
}

export default function NwPurchaseItemDialog(props) {
  const { state, dispatch } = props;
  const item = state?.currentPurchaseItem;
  let factory;
  let commonApifactory: APIFactoryCommon;
  const [quantity, setQuantity] = useState<number>(item?.quantity);
  const [unitPrice, setUnitPrice] = useState<number>(item?.unitPrice);
  const [total, setTotal] = useState<number>();
  const [startDate, setStartDate] = useState(item?.startDate);
  const [endDate, setEndDate] = useState(item?.endDate);

  const [units, setUnits] = useState([]);
  const [types, setTypes] = useState([]);
  const [accountNumberList, setAccountNumberList] = useState([]);
  const [chargeCode, setChargeCode] = useState(null);
  const [chargeCodeList, setChargeCodeList] = useState([]);

  const [accountNumber, setAccountNumber] = useState(null);
  const [type, setType] = useState(item?.type);
  const [accountGrpCode, setAccountGrpCode] = useState();
  const [orgId, setOrgId] = useState("");

  const [descriptionError, setDescriptionError] = useState<boolean>(false);

  const [apiTypeList, setApiTypeList] = useState([]);
  const [apiAccountList, setApiAccountList] = useState([]);
  const [apiChargeList, setApiChargeList] = useState([]);
  const [chargeCodeLoading, setChargeCodeLoading] = useState(false);
  const [typeLoading, setTypeLoading] = useState(false);
  const [accountNoLoading, setAccountNoLoading] = useState(false);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const toggleDialog = () => {
    setVisible(!visible);
  };

  const context: IXtContext = useContext(XtContext);

  useEffect(() => {
    if (state?.isPurchaseItemForEdit && chargeCodeList.length > 0) {
      setChargeCode(item?.chargeCode);
      setType(item?.type);
      setAccountNumber(item?.accountNumber);
      const selectedChargeCode =
        chargeCodeList.find((c) => c.value == item?.chargeCode) || null;
      if (selectedChargeCode) {
        onChargeChange(
          item?.chargeCode,
          selectedChargeCode.accountGrpCode,
          selectedChargeCode.orgId,
          item?.type,
          item?.accountNumber
        );
      }
    }
  }, [chargeCodeList.length]);

  useEffect(() => {
    if (context?.loggedOnUser?.username) {
      let api: NWResourceAttributeApi = factory.create(NWResourceAttributeApi);
      PrApiService.getResourceAttributeList(context, api, "PR", "unit").then(
        (data) => {
          const attributeList = data.map((a) => {
            return { value: a.value, text: a.value + " - " + a.text };
          });
          setUnits(attributeList);
        }
      );
      loadChargeCode();
    }
  }, [context?.loggedOnUser?.username]);

  useEffect(() => {
    if (!quantity || !unitPrice) {
      setTotal(undefined);
    } else if (quantity && unitPrice) {
      let totalAmt = NumberUtil.decimalNumMultiply(unitPrice, quantity);
      setTotal(NumberUtil.roundToTwoDecimal(totalAmt));
    } else {
      let totalAmt = NumberUtil.decimalNumMultiply(
        item?.quantity,
        item?.unitPrice
      );
      setTotal(NumberUtil.roundToTwoDecimal(totalAmt));
    }
  }, [quantity, unitPrice]);

  const onTypeChange = (
    type,
    accountGroupCode,
    orgId,
    currentAccountNumber
  ) => {
    if (type) {
      setType(type);
      let commonApi: ResourceAttributeApi =
        commonApifactory.create(ResourceAttributeApi);
      loadAccountNumber(
        commonApi,
        type,
        accountGroupCode,
        orgId,
        currentAccountNumber
      );
    } else {
      setType(null);
      setAccountNumber(null);
      setAccountNumberList([]);
    }
  };
  const onChargeChange = (
    chargeCode,
    accountGroupCode,
    orgId,
    currentType,
    currentAccountNumber
  ) => {
    if (chargeCode && accountGroupCode && orgId) {
      setChargeCode(chargeCode);
      setOrgId(orgId);
      setAccountGrpCode(accountGroupCode);
      loadType(accountGroupCode, orgId, currentType, currentAccountNumber);
    } else {
      setAccountNumber(null);
      setChargeCode(null);
      setAccountGrpCode(null);
      setOrgId(null);
      setType(null);
      setTypes([]);
      setAccountNumberList([]);
    }
  };
  const loadType = (
    groupCode,
    orgnizationId,
    currentType,
    currentAccountNumber
  ) => {
    let commonApi: ResourceAttributeApi =
      commonApifactory.create(ResourceAttributeApi);
    let acGrpCode = groupCode;
    if (groupCode === null) acGrpCode = state?.accountGrpCode;

    if (state?.companyCode && acGrpCode && orgnizationId) {
      setTypeLoading(true);
      commonApi
        .resourceAttributeAccountTypeByFilterGet(
          state?.companyCode,
          acGrpCode,
          orgnizationId
        )
        .then(async (acType) => {
          var typeDataFromApi = await acType.json();
          const apList = typeDataFromApi.value.map((v) => {
            return {
              text: v.accT_NAME,
              value: v.accountCode,
            };
          });
          setApiTypeList(apList);
          const typeList = typeDataFromApi.value.map((v) => {
            return {
              text: v.accountCode + " - " + v.accT_NAME,
              value: v.accountCode,
            };
          });
          setTypes(typeList);
          const currentTypeExsits = typeList.find(
            (t) => t.value === currentType
          );
          if (currentTypeExsits) {
            if (accountNumberList.length == 0)
              loadAccountNumber(
                commonApi,
                type,
                acGrpCode,
                orgnizationId,
                currentAccountNumber
              );
          } else {
            setType(null);
            setAccountNumber(null);
            setAccountNumberList([]);
          }
        })
        .finally(() => setTypeLoading(false));
    }
  };

  const loadAccountNumber = (
    commonApi: ResourceAttributeApi,
    accounType: string,
    accountGroupCode: string,
    organizationId: string,
    currentAccountNumber: string
  ) => {
    let acGrpCode = accountGroupCode;
    if (acGrpCode === null) acGrpCode = state?.accountGrpCode;

    if (state?.companyCode && acGrpCode && accounType && organizationId) {
      setAccountNoLoading(true);
      commonApi
        .resourceAttributeAccountCodeByFilterGet(
          state?.companyCode,
          acGrpCode,
          accounType,
          organizationId
        )
        .then(async (acCode) => {
          var typeDataFromApi = await acCode.json();
          const apcodeList = typeDataFromApi.value.map((v) => {
            return {
              text: v.accT_NAME,
              value: v.accT_ID,
            };
          });
          setApiAccountList(apcodeList);
          const codeList = typeDataFromApi.value.map((v) => {
            return {
              text: v.accT_ID + " - " + v.accT_NAME,
              value: v.accT_ID,
            };
          });
          setAccountNumberList(codeList);
          const currentAccountNumberExists = codeList.find(
            (a) => a.value === currentAccountNumber
          );
          if (!currentAccountNumberExists) {
            setAccountNumber(null);
          }
        })
        .finally(() => setAccountNoLoading(false));
    }
  };

  //backup function after change in api
  //const loadChargeCode = () => {
  //    let commonApi: ResourceAttributeApi = commonApifactory.create(ResourceAttributeApi);
  //    if (state?.chargeType !== undefined) {
  //        setDropdownLoadingSate({...dropdownLoadingState, ChargeCode : true})
  //        commonApi.resourceAttributeChargeCodeGet(state?.companyCode, state?.chargeType, state?.accountGrpCode, state?.projectNumber).then(async (code) => {
  //            var json = await code.json();
  //            const apcodeList =
  //                json.value.map((m) => {
  //                    return { value: m.proJ_ID, text: m.proJ_NAME };
  //                });
  //            setApiChargeList(apcodeList);
  //            const codeList =
  //                json.value.map((m) => {
  //                    return { value: m.proJ_ID, text: m.proJ_ID + ' (' + m.proJ_NAME + ')', accountGrpCode: m.accT_GRP_CD, orgId: m.orG_ID, emplID: m.empL_ID };
  //                });
  //            //if (state?.chargeType === "Direct") {
  //            //    codeList.push({ value: state?.projectNumber, text: state?.projectName, accountGrpCode: state?.accountGrpCode, orgId: state?.orgId });
  //            //}
  //            setChargeCodeList(codeList);
  //            setDropdownLoadingSate({...dropdownLoadingState, ChargeCode : false})
  //        })
  //    }

  //}
  const loadChargeCode = () => {
    let commonApi: ResourceAttributeApi =
      commonApifactory.create(ResourceAttributeApi);
    if (state?.chargeType !== undefined) {
      setChargeCodeLoading(true);
      commonApi
        .resourceAttributeChargeCodeGet(
          state?.companyCode,
          state?.chargeType,
          state?.projectNumber
        )
        .then(async (code) => {
          var json = await code.json();
          const apcodeList = json.value.map((m) => {
            return { value: m.proJ_ID, text: m.proJ_NAME };
          });
          setApiChargeList(apcodeList);
          var chargeCodeList = json.value;
          if (state?.prTypeDetail === EnPrTypeDetail.PR_CREATE_NEW) {
            chargeCodeList = chargeCodeList.filter(
              (x) => x.activE_FL == "Y" && x.alloW_CHARGES_FL == "Y"
            );
          }
          const codeList = chargeCodeList.map((m) => {
            var codeDisplay = `${m.proJ_ID} (${m.proJ_NAME}) (${m.alloW_CHARGES_FL})`;
            return {
              value: m.proJ_ID,
              text: codeDisplay,
              accountGrpCode: m.accT_GRP_CD,
              orgId: m.orG_ID,
              emplID: m.empL_ID,
            };
          });
          //if (state?.chargeType === "Direct") {
          //    codeList.push({ value: state?.projectNumber, text: state?.projectName, accountGrpCode: state?.accountGrpCode, orgId: state?.orgId });
          //}
          setChargeCodeList(codeList);
        })
        .finally(() => setChargeCodeLoading(false));
    }
  };

  const dialogTitle = state?.isPurchaseItemForEdit
    ? "Edit Purchase Line Item"
    : "Add Purchase Line Item";

  const onSubmit = (e) => {
    // e.preventDefault();
    e.postData.total = total;
    e.postData.lineItemNumber = item?.lineItemNumber;
    e.postData.tranType = item?.tranType;
    e.postData.startDate = startDate;
    e.postData.endDate = endDate;
    e.postData.type = type;
    e.postData.accountNumber = accountNumber;
    e.postData.accountGroupCode = accountGrpCode;
    e.postData.orgId = orgId;
    e.postData.chargeCodeName = apiChargeList.find(
      (c) => c.value == e.postData.chargeCode
    ).text;
    e.postData.typeName = apiTypeList.find((c) => c.value == type).text;
    e.postData.accountName = apiAccountList.find(
      (c) => c.value == accountNumber
    ).text;
    e.postData.emplID = chargeCodeList.find(
      (c) => c.value == e.postData.chargeCode
    ).emplID;
    const action = e?.nativeEvent?.submitter?.id;
    switch (action) {
      case EnPrItemAction.ADD_AND_CLOSE:
        e.postData.isNewItem = true;
        dispatch({
          type: EnPrAction.PURCHASE_ITEM_ADD_TO_LIST,
          payload: e.postData,
        });
        break;
      case EnPrItemAction.ADD_AND_CONTINUE:
        e.postData.isNewItem = true;

        dispatch({
          type: EnPrAction.PURCHASE_ITEM_ADD_TO_LIST_AND_CONTINUE,
          payload: e.postData,
        });
        setShowSuccessNotification(true);
        resetForm();
        break;
      case EnPrItemAction.EDIT_AND_CLOSE:
        e.postData.purchaseItemGuid = item?.purchaseItemGuid;
        e.postData.isNewItem =
          e.postData.tranType === "INSERT" &&
          state.prType !== enum_request_type.PO_CHANGE_REQUEST
            ? true
            : false;

        if (item.isUpdated) {
          e.postData.isUpdated = true;
        } else {
          e.postData.isUpdated = false;
        }

        if (
          (item?.description !== e.postData.description ||
            item?.quantity !== e.postData.quantity ||
            item?.unit !== e.postData.unit ||
            item?.unitPrice !== Number(e.postData.unitPrice)) &&
          state.prTypeDetail === "PO_CHANGE_REQUEST_BY_PO"
        ) {
          e.postData.isUpdated = true;
        }

        dispatch({
          type: EnPrAction.PURCHASE_ITEM_EDIT_ON_LIST,
          payload: e.postData,
        });
        break;
    }
  };

  const resetForm = () => {
    setTotal(undefined);
    setUnitPrice(undefined);
    setQuantity(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
  };

  const onPurchaseItemDelete = async () => {
    if (item?.purchaseItemGuid) {
      let api: PurchaseItemApi = factory.create(PurchaseItemApi);
      setLoading(true);
      let result = await factory.callAPI(
        api.purchaseItemKeyDelete(
          context?.loggedOnUser?.username,
          item?.purchaseItemGuid
        )
      );

      if (!result.ok) {
        let data = await result.json();
        let message = "";
        for (const e in data.errors) {
          const errorData = data.errors[e];
          message = message + errorData[0] + ";";
        }
        factory.showErrorMessage(message, data.title);
        setLoading(false);
      }
      setLoading(false);
    }
    dispatch({
      type: EnPrAction.PURCHASE_ITEM_DELETE_FROM_LIST,
      payload: item?.lineItemNumber,
    });
  };
  const getTextOrEmpty = (list, currentValue) => {
    const listItem = list.find((l) => l.value === currentValue);
    if (listItem) return listItem.text;
    else return null;
  };

  const shouldDisable =
    state?.isPurchaseItemForEdit &&
    state?.prTypeDetail === EnPrTypeDetail.PO_CHANGE_REQUEST_BY_PR &&
    (!item?.isNewItem || item?.isNewItem === false);

  const onPurchaseItemDeleteModal = () => {
    setVisible(true);
  };
  const onDescriptionKeyUp = (e) => {
    // regex that only allows alphanumeric, space, comma, and period
    if (!e.target.value.match(/^[a-zA-Z0-9 .,]+$/)) setDescriptionError(true);
    else setDescriptionError(false);
  };

  return (
    <>
      {loading && <Loading />}
      {visible && (
        <Dialog title={"Please confirm"} onClose={toggleDialog}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            Are you sure you want to delete this Purchase Line Item?
          </p>
          <DialogActionsBar layout="end">
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleDialog}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-error"
              onClick={onPurchaseItemDelete}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      <APIFactoryCommon
        ref={(e) => {
          commonApifactory = e;
        }}
      />
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <Dialog
        title={dialogTitle}
        onClose={(e) =>
          dispatch({ type: EnPrAction.PURCHASE_ITEM_DIALOG_CLOSE })
        }
        {...props}
        width={"70%"}
      >
        <XtForm onSubmit={onSubmit}>
          <Row>
            <Row>
              {" "}
              <FormField label="Line Item No.">
                <FormLabelMutedText value={item?.lineItemNumber} />
              </FormField>
            </Row>

            <RequiredFormField label="Charge Code">
              <XtField
                type="dropdown"
                value={
                  chargeCodeList.find((c) => c.value == chargeCode) || null
                }
                items={chargeCodeList}
                onChange={(e) => {
                  onChargeChange(
                    e?.value?.value,
                    e?.value?.accountGrpCode,
                    e?.value?.orgId,
                    type,
                    accountNumber
                  );
                }}
                key={`"chargeCodes"${item?.lineItemNumber}`}
                defaultValue={
                  chargeCodeList.find((c) => c.value == item?.chargeCode) ||
                  null
                }
                textField="text"
                name="chargeCode"
                required={true}
                errormessage="Charge Code is required"
                disabled={shouldDisable}
                loading={chargeCodeLoading}
              />
            </RequiredFormField>

            <RequiredFormField label="Type">
              <XtField
                type="dropdown"
                items={types}
                value={types.find((c) => c.value === type) || null}
                defaultValue={types.find((c) => c.value === item?.type) || null}
                key={`"type"${item?.lineItemNumber}`}
                onChange={(e) =>
                  onTypeChange(
                    e?.value?.value,
                    accountGrpCode,
                    orgId,
                    accountNumber
                  )
                }
                textField="text"
                name="type"
                required={true}
                errormessage="Type is required"
                disabled={shouldDisable}
                loading={typeLoading}
              />
            </RequiredFormField>

            <RequiredFormField label="Account No.">
              <XtField
                type="dropdown"
                items={accountNumberList}
                value={
                  accountNumberList.find((c) => c.value === accountNumber) ||
                  null
                }
                defaultValue={
                  accountNumberList.find(
                    (c) => c.value == item?.accountNumber
                  ) || null
                }
                onChange={(e) => setAccountNumber(e.value.value)}
                textField="text"
                name="accountNumber"
                key={`"accountNumber"${item?.lineItemNumber}`}
                required={true}
                errormessage="Account is required"
                disabled={shouldDisable}
                loading={accountNoLoading}
              />
            </RequiredFormField>
            <RequiredFormFieldFullRow label="Description">
              <XtField
                type="textarea"
                name="description"
                defaultValue={item?.description}
                key={`"description"${item?.lineItemNumber}`}
                required={true}
                errormessage="Description is required"
                onKeyUp={onDescriptionKeyUp}
                // pattern="[a-zA-Z0-9 .,]+"
                //disabled={shouldDisable}
              />
              {descriptionError && (
                <div className="invalid-field">
                  Only alphanumeric characters (and spaces, periods, and commas)
                  are allowed
                </div>
              )}
            </RequiredFormFieldFullRow>

            <RequiredFormField label="Quantity">
              <XtField
                type="numeric"
                name="quantity"
                defaultValue={item?.quantity}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
                key={`"quantity"${item?.lineItemNumber}`}
                required={true}
                min={0}
                step={0.01}
                errormessage="Quantity is required and cannot be negative"
              />
            </RequiredFormField>

            <RequiredFormField label="Unit">
              <XtField
                type="dropdown"
                name="unit"
                key={`"unit"${item?.lineItemNumber}`}
                defaultValue={units.find((u) => u.value == item?.unit) || null}
                items={units}
                /* onChange={(e) => {
                                  setUnit(e.target.value);
                                }}*/
                textField="text"
                required={true}
                errormessage="Unit is required"
              />
            </RequiredFormField>

            <RequiredFormField label="Unit Price">
              <XtField
                type="numeric"
                name="unitPrice"
                defaultValue={item?.unitPrice}
                onChange={(e) => {
                  setUnitPrice(Number(e.target.value));
                }}
                key={`"unitPrice"${item?.lineItemNumber}`}
                required={true}
                errormessage="Unit Price is required and cannot be negative"
                min={0}
                precision={2}
                step={0.01}
              />
            </RequiredFormField>

            {/* <FormField label="Total Price">
              <FormLabelMutedText value={formatNumber(total, "##,#.00")} />
            </FormField> */}
            {/* Considering just two digits after decimal point */}
            <FormField label="Total Price">
              <FormLabelMutedText
                value={total?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              />
            </FormField>
            {/* <p>Total amount: {total}</p> */}
            {state?.requisitionType === "Service" && (
              <RequiredFormField label="Start Date">
                <XtField
                  type="datepicker"
                  name="startDate"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.value);
                  }}
                  key={`"startDate"${item?.lineItemNumber}`}
                  errormessage="Start Date is required"
                  weekNumber={false}
                  required={false}
                />
              </RequiredFormField>
            )}
            {state?.requisitionType === "Service" && (
              <RequiredFormField label="End Date">
                <XtField
                  type="datepicker"
                  name="endDate"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.value);
                  }}
                  key={`"endDate"${item?.lineItemNumber}`}
                  errormessage="End Date is required"
                  weekNumber={false}
                  required={false}
                />
              </RequiredFormField>
            )}
          </Row>

          <Row>
            <div>
              <NwNotification
                showNotification={showSuccessNotification}
                type={"success"}
                message={"Line item was successfully added"}
                onClose={(e) => setShowSuccessNotification(false)}
              />
            </div>
          </Row>

          <DialogActionsBar layout="end">
            {/*<Button variant="secondary" onClick={(e)=> dispatch({type:EnPrAction.PURCHASE_ITEM_DIALOG_CLOSE})}>
              Cancel
      </Button>*/}

            {state?.isPurchaseItemForEdit && (
              <>
                {(state?.prType === enum_request_type.PURCHASE_REQUISITION ||
                  state?.currentPurchaseItem.isNewItem) && (
                  <Button
                    variant="secondary"
                    onClick={onPurchaseItemDeleteModal}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="primary"
                  id={EnPrItemAction.EDIT_AND_CLOSE}
                  disabled={descriptionError}
                >
                  Save & Close
                </Button>
              </>
            )}

            {!state?.isPurchaseItemForEdit && (
              <>
                <Button
                  type="submit"
                  variant="secondary"
                  id={EnPrItemAction.ADD_AND_CONTINUE}
                  disabled={descriptionError}
                >
                  Add & Continue
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  id={EnPrItemAction.ADD_AND_CLOSE}
                  disabled={descriptionError}
                >
                  Add & Close
                </Button>
              </>
            )}
          </DialogActionsBar>
        </XtForm>
      </Dialog>
    </>
  );
}
