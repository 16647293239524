

const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = process.env.PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

export default {
    oidc: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        issuer: process.env.REACT_APP_ISSUER,
        redirectUri: REDIRECT_URI,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    },
    resourceServer: {
        messagesUrl: 'http://localhost:8000/api/messages',
    },
    app: {
        basename: BASENAME,
    }
};