import React, { useEffect, useState } from "react";
import { User, UserApi } from "../ApiClients/index";
import { IXtContext, XtContext, IOidcTokens } from "./XtContext";
import { useOktaAuth } from "@okta/okta-react";
declare var window: any;
declare var localStorage: any;

export type ContextConfigType = {
  apiBasePath: string;
  commonBasePath: string;
  oktaBasePath: string;
  onError?: any;
};

export type XtContextProviderProps = React.PropsWithChildren<{
  xtContext: IXtContext;
  config: ContextConfigType;
}>;

export function XtContextProvider(
  props: XtContextProviderProps
): React.ReactElement {
  const { authState, oktaAuth } = useOktaAuth();
  const [loggedOnUser, setLoggedOnUser] = useState<User>(null);
  const [rootUser, setRootUser] = useState<User>(null);
  const [superAdmin, setSuperAdmin] = useState<boolean>(false);
  const [hrAdmin, setHrAdmin] = useState<boolean>(false);
  const [prrReadAll, setPrrReadAll] = useState<boolean>(false);

  const [tokens, setTokens] = React.useState<IOidcTokens>({
    accessToken: "",
    idToken: "",
    idTokenClaims: {},
  });
  const [isExportPopupVisible, setIsExportPopupVisible] = React.useState(false);

  const showExportPopup = () => {
    setIsExportPopupVisible(true);
  };

  const hideExportPopup = () => {
    setIsExportPopupVisible(false);
  };

  const isInSwitchUserMode = (): boolean => {
    if (rootUser != null) {
      return true;
    } else {
      return false;
    }
  };

  const switchUser = (switchToUser: User) => {
    sessionStorage.setItem("loggedOnUser", JSON.stringify(switchToUser));
    setLoggedOnUser(switchToUser);
  };

  const switchBack = (): void => {
    sessionStorage.setItem(
      "loggedOnUser",
      JSON.stringify(contextValue.rootUser)
    );
    setLoggedOnUser(contextValue.rootUser);
  };

  React.useEffect(() => {
    let currentAccount = null;

    var silentRequest = {
      scopes: "",
      account: currentAccount,
      forceRefresh: true,
    };
    var request = {
      scopes: "",
      loginHint: currentAccount?.username,
    };
  }, []);

  // okta was expecting value without domain part.
  const truncateEmailDomainFromUserName = (userName: string) => {
    if (!userName) return;
    if (
      userName.search(/([^.@\s]+)(\.[^.@\s]+)*@([^.@\s]+\.)+([^.@\s]+)/) !== -1
    ) {
      userName = userName.replace(/@([^.@\s]+\.)+([^.@\s]+)/, "");
    }
    return userName;
  };

  React.useEffect(() => {
    if (authState?.idToken?.idToken) {
      let idToken: any = authState?.idToken;
      let loginUser = truncateEmailDomainFromUserName(
        idToken.claims.preferred_username
      );

      // just test code to check if fix is working as intended.
      // let loginUser1 = truncateEmailDomainFromUserName("chems.worth@lbyd.com");
      // console.log("Context loggedOnUser: ", loginUser1)

      const options = {
        headers: {
          Authorization: "Bearer " + authState?.accessToken?.accessToken,
        },
      };

      //TODO: Merge these 2 calls into 1
      let employeeApi = new UserApi(
        undefined,
        props.config.apiBasePath,
        undefined
      ).userGet(
        loginUser,
        undefined,
        `(username eq '${loginUser}' or workEmail eq '${loginUser}')`,
        undefined,
        undefined,
        undefined,
        undefined,
        options
      );

      let isSuperUser = new UserApi(
        undefined,
        props.config.apiBasePath,
        undefined
      ).userIsSuperUserUsernameGet(loginUser, loginUser, options);

      isSuperUser.then(async (res) => {
        var result = await res.json();
        setSuperAdmin(result.result);
      });

      let isHRAdmin = fetch(
        props.config.apiBasePath + `/User/HRAdmin/${loginUser}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }
      );
      isHRAdmin.then(async (res) => {
        var result = await res.json();
        setHrAdmin(result.result);
      });

      let isPRRReadAll = fetch(
        props.config.apiBasePath + `/User/PRRReadAll/${loginUser}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }
      );
      isPRRReadAll.then(async (res) => {
        var result = await res.json();
        setPrrReadAll(result.result);
      });

      employeeApi
        .then(async (response) => {
          let json = await response.json();
          if (json["@odata.count"] > 0) {
            // setLoggedOnUser(json.value[0]);
            setRootUser(json.value[0]);
            if (sessionStorage.getItem("loggedOnUser")) {
              let loggonUser = JSON.parse(
                sessionStorage.getItem("loggedOnUser")
              );
              setLoggedOnUser(loggonUser);
            } else {
              setLoggedOnUser(json.value[0]);
            }
          }
        })
        .catch((error) => {});
    }
  }, [authState?.idToken]);

  let claims: any = authState?.accessToken?.claims;

  const contextValue: IXtContext = {
    loggedOnUser,
    rootUser,
    switchUser: switchUser,
    isAuthenticated: authState?.isAuthenticated,
    tokens: {
      accessToken: authState?.accessToken?.accessToken,
      idToken: authState?.idToken?.idToken,
      idTokenClaims: authState?.accessToken?.claims,
    },
    isInSwitchUserMode: () => {
      return (
        JSON.stringify(contextValue.loggedOnUser) !==
        JSON.stringify(contextValue.rootUser)
      );
    },
    switchBack,
    isAllowedToSwitch: () => {
      return superAdmin;
    },
    isSuperAdmin: () => {
      return superAdmin;
    },
    isHRAdmin: () => {
      return hrAdmin;
    },
    isPRRReadAll: () => {
      return prrReadAll;
    },
    apiBasePath: props.config.apiBasePath,
    oktaBasePath: props.config.oktaBasePath,
    onError: props.config.onError,
    commonBasePath: props.config.commonBasePath,
    isExportPopupVisible,
    showExportPopup,
    hideExportPopup,
  };

  return (
    <XtContext.Provider value={contextValue}>
      {" "}
      {props.children}{" "}
    </XtContext.Provider>
  );
}
