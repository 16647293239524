import axios from "axios";
import { QueryFunctionContext } from "@tanstack/react-query";

import {
	ApprovalFlowLog,
	BambooEmployee,
	ChargeCode,
	Company,
	LaborGroup,
	Location,
	Project,
	PRR,
	PrrPermission,
} from "../components/Pages/Nw/PersonnelRequisition/Interfaces/API";

import {
	PrrOptions,
	PrrSearchResults,
	SubmitExistingPrr,
	SaveOrSubmitPrr,
	SaveInvoiceWithVoucherNo,
	SavePrWithPoNumber,
} from "./types";
import { PersonnelRequisition } from "../Xt/ApiClients";

const purchasingApi = process.env.REACT_APP_API_BASE_PATH;
const invoiceApi = `${purchasingApi}/Invoice`;
const prrApi = `${purchasingApi}/PersonnelRequisition`;
const commonApi = process.env.REACT_APP_COMMON_API_BASE_PATH;

export async function getApprovalFlowByGuid({
	queryKey,
}: QueryFunctionContext<[string, string, string, string]>) {
	const [, guid, token, loggedOnUserName] = queryKey;

	const { data } = await axios.get(
		`${prrApi}/ApprovalFlow(%7Bkey%7D)?key=${guid}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Loggedonuser: loggedOnUserName,
			},
		},
	);

	return data.value as ApprovalFlowLog[];
}

export async function getPrrByGuid({
	queryKey,
}: QueryFunctionContext<[string, string, string, string]>) {
	const [, guid, token, loggedOnUserName] = queryKey;

	const { data } = await axios.get(`${prrApi}(%7Bkey%7D)?key=${guid}`, {
		headers: {
			Authorization: `Bearer ${token}`,
			Loggedonuser: loggedOnUserName,
		},
	});

	// data comes back as a semicolon separated pairing of city and state,
	// with city and state being comma separated
	// e.g., "Knoxville, Tennessee;Nashville, Tennessee;Floorplan, Mississippi"
	data.locationList = data.locationList.split(";").map((item: string) => {
		const itemSplit = item.split(",");
		return {
			city: itemSplit[0],
			state: itemSplit[1],
		};
	});

	return data as PRR;
}

export async function getPrrPermissionByGuid({
	queryKey,
}: QueryFunctionContext<[string, string, string, string]>) {
	const [, guid, token, loggedOnUserName] = queryKey;

	const { data } = await axios.get<PrrPermission>(
		`${prrApi}/RequisitionPermission(%7Bkey%7D)?key=${guid}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Loggedonuser: loggedOnUserName,
			},
		},
	);

	return data;
}

export async function getPrrAuditLogByGuid({
	queryKey,
}: QueryFunctionContext<[string, string, string, string]>) {
	const [, guid, token, loggedOnUserName] = queryKey;

	const { data } = await axios.get(
		`${prrApi}/ApprovalFlowLog(%7Bkey%7D)?key=${guid}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Loggedonuser: loggedOnUserName,
			},
		},
	);

	return data.value as ApprovalFlowLog[];
}

export async function getCompanyList({
	queryKey,
}: QueryFunctionContext<[string, string, string]>) {
	const [, token, loggedOnUserName] = queryKey;

	const { data } = await axios.get(`${commonApi}/Company`, {
		headers: {
			Authorization: `Bearer ${token}`,
			Loggedonuser: loggedOnUserName,
		},
	});

	let companyList = data.value as Company[];

	// need to do a little preprocessing first - only need certain data...
	// and need to filter out on company code 1 (North Wind Inc. - no longer around)
	const preProcessedCompanyList = companyList.map((company) => {
		return {
			text: `${company.companyName} (${company.companyIdCP})`,
			value: company.companyIdCP,
			bambooName: company.bambooHRName,
			companyName: company.companyName,
		};
	});

	var regExp = /[a-zA-Z]/;
	let filteredCompanyList = preProcessedCompanyList.filter(
		(value) => !regExp.test(value.value) && value.value !== "1",
	);

	return filteredCompanyList;
}

export async function getProjectList({
	queryKey,
}: QueryFunctionContext<[string, string, string, string]>) {
	const [, token, loggedOnUserName, companyCode] = queryKey;

	const { data } = await axios.get(
		`${commonApi}/Project?companyCode=${companyCode}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Loggedonuser: loggedOnUserName,
			},
		},
	);

	const projectList = data.value as Project[];

	let preprocessedProjectList = [];
	preprocessedProjectList.push({ text: "Project Not Found", value: "-1" });

	projectList.forEach((project) => {
		if (project.status !== "Active") return;

		var projDisplay = `${project.projectNumber} (${project.contractTitle}))`;

		preprocessedProjectList.push({
			text: projDisplay,
			value: project.projectNumber,
			orgID: project.orgID,
		});
	});

	type PreprocessedProjectList = {
		text: string;
		value: string;
		orgID: string;
	};

	return preprocessedProjectList as PreprocessedProjectList[];
}

export async function getChargeCodeList({
	queryKey,
}: QueryFunctionContext<[string, string, string, string]>) {
	const [, token, loggedOnUserName, companyCode] = queryKey;

	const { data } = await axios.get(
		`${commonApi}/ResourceAttribute/ChargeCode?companyCode=${companyCode}&chargeType=Indirect`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Loggedonuser: loggedOnUserName,
			},
		},
	);

	const chargeCodeList = data.value as ChargeCode[];

	var filteredCodeList = chargeCodeList.map((chargeCode) => ({
		value: chargeCode.proJ_ID,
		text: chargeCode.proJ_ID + " - " + chargeCode.proJ_NAME,
	}));
	if (Number(companyCode) === 0) {
		filteredCodeList = filteredCodeList.filter((value) => {
			let pattern: RegExp = /^00OVHD.190.01.|^00GADM.170.00./gm;
			return pattern.test(value.value);
		});
	}

	return filteredCodeList;
}

export async function getBambooEmployeeList({
	queryKey,
}: QueryFunctionContext<[string, string, string]>) {
	const [, token, loggedOnUserName] = queryKey;

	const { data } = await axios.get(`${commonApi}/Bamboo/Employee/list`, {
		headers: {
			Authorization: `Bearer ${token}`,
			Loggedonuser: loggedOnUserName,
		},
	});

	const employeeList = data.employees as BambooEmployee[];

	const preprocessedEmployeeList = employeeList?.map((e) => {
		const nameParts = e.fullName2.split(",");
		const lastNameTemp = nameParts[0].trim();
		const firstNameTemp = nameParts[1].trim();

		const middleName = e.middleName !== null ? `${e.middleName}` : "";

		const capitalizeFirstLetter = (str) =>
			str.charAt(0).toUpperCase() + str.slice(1);

		const firstName = capitalizeFirstLetter(firstNameTemp.toLowerCase());
		const lastName = capitalizeFirstLetter(lastNameTemp.toLowerCase());

		const displayName = `${firstName} ${middleName} ${lastName}`;

		return { text: displayName, value: e.id };
	});

	return preprocessedEmployeeList;
}

export async function getPrrOptions({
	queryKey,
}: QueryFunctionContext<[string, string, string]>) {
	const [, token, loggedOnUserName] = queryKey;

	const { data } = await axios.get(
		`${purchasingApi}/AdvancedSearch/PRROptions`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Loggedonuser: loggedOnUserName,
			},
		},
	);

	const users = data.Users as PrrOptions["Users"];
	const regions = data.Regions as PrrOptions["Regions"];
	const locations = data.Locations as PrrOptions["Locations"];
	const companies = data.Companies as PrrOptions["Companies"];

	return {
		Users: users,
		Regions: regions,
		Locations: locations,
		Companies: companies,
	};
}

export async function getPersonnelRequisitionList({
	queryKey,
}: QueryFunctionContext<
	[
		string,
		string,
		string,
		string,
		string,
		string,
		number,
		number,
		number,
		string,
	]
>) {
	const [
		,
		token,
		loggedOnUserName,
		actionFilter,
		search,
		filter,
		top,
		skip,
		take,
		orderBy,
	] = queryKey;

	const { data } = await axios.get(
		`${prrApi}?actionFilter=${actionFilter}&search=${search}&Filter=${filter}&Top=${top}&Skip=${skip}&Take=${take}&OrderBy=${orderBy}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Loggedonuser: loggedOnUserName,
			},
		},
	);

	const prrs = data.value as PRR[];

	const updatedData = [];
	prrs.forEach((prr) => {
		updatedData.push({
			...prr,
			prrNumber: prr.prrNumber === null ? "null" : prr.prrNumber,
			createdOn: prr.createdOn === null ? "null" : prr.createdOn,
			companyCode:
				prr.employingCompany === null ? "null" : prr.employingCompany,
			jobTitle: prr.jobTitle === null ? "null" : prr.jobTitle,
			dateNeeded: prr.dateNeeded === null ? "null" : prr.dateNeeded,
			projectNumber:
				prr.projectNumber === "-1" ? "Project Not Found" : prr.projectName,
			employmentType: prr.employmentType === null ? "null" : prr.employmentType,
			vacancies: prr.vacancies === null ? "null" : prr.vacancies,
			status: prr.status !== null ? prr.status : "draft",
		});
	});

	const prrList = {
		count: data["@odata.count"],
		value: prrs,
	} as PrrSearchResults;

	return prrList;
}

export async function getLocationList({
	queryKey,
}: QueryFunctionContext<[string, string, string]>) {
	const [, token, loggedOnUserName] = queryKey;

	const { data } = await axios.get(`${purchasingApi}/Locations`, {
		headers: {
			Authorization: `Bearer ${token}`,
			Loggedonuser: loggedOnUserName,
		},
	});

	return data.value as Location[];
}

export async function getLaborGroupsByCompanyCode({
	queryKey,
}: QueryFunctionContext<[string, string, string, string]>) {
	const [, token, loggedOnUserName, companyCode] = queryKey;

	const { data } = await axios.get<LaborGroup[]>(
		`${commonApi}/LaborGroup/list?companyCode=${companyCode}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Loggedonuser: loggedOnUserName,
			},
		},
	);

	const laborGroups = data
		.filter(
			(e) =>
				!e.laB_GRP_TYPE_DESC.includes("DNU") &&
				!e.laB_GRP_TYPE_DESC.includes("NOT USED"),
		)
		.map((e) => {
			return { text: e.laB_GRP_TYPE_DESC, value: e.laB_GRP_TYPE_DESC };
		})
		.sort((a, b) => a.text.localeCompare(b.text));

	return laborGroups;
}

export async function submitNewPrr({
	token,
	loggedOnUserName,
	prr,
}: SaveOrSubmitPrr) {
	const { data: newPrr } = await axios.post<PersonnelRequisition>(
		`${prrApi}`,
		{
			...prr,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Loggedonuser: loggedOnUserName,
			},
		},
	);

	return newPrr;
}

export async function submitExistingPrr({
	token,
	loggedOnUserName,
	prrGuid,
	prr,
}: SubmitExistingPrr) {
	const { data } = await axios.put<PersonnelRequisition>(
		`${prrApi}?key=${prrGuid}`,
		{
			...prr,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Loggedonuser: loggedOnUserName,
			},
		},
	);

	return data;
}

export async function saveInvoiceWithVoucherNo({
	token,
	loggedOnUserName,
	invoiceGuid,
	invoice,
}: SaveInvoiceWithVoucherNo) {
	const { data } = await axios.put(
		`${invoiceApi}/SaveInvoiceWithVoucherNo/${invoiceGuid}`,
		{
			...invoice,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Loggedonuser: loggedOnUserName,
			},
		},
	);

	return data;
}

export async function savePrWithPoNumber({
	token,
	loggedOnUserName,
	prGuid,
	pr,
}: SavePrWithPoNumber) {
	const { data } = await axios.put(
		`${purchasingApi}/PurchaseRequisition/${prGuid}/UpdatePoNumber`,
		{
			...pr,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Loggedonuser: loggedOnUserName,
			},
		},
	);

	return data;
}
