import { XtBody } from "./XtBody";
import { Footer } from "./XtFooter";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  MsalAuthenticationTemplate,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { XtTopNavBar2 } from "./XtTopNavBar2";
import { IXtNavLeftItem } from "../Interfaces/IXtNavLeftItem";
import { XtNavLeft } from "./NavBar/XtNavLeft";
import { useState, ReactElement, PropsWithChildren, useContext } from "react";
import { Loader } from "@progress/kendo-react-indicators";
import { XtContext } from "../Contexts/XtContext";

export type XtLayoutProps = React.PropsWithChildren<{
  dataLeftNav: IXtNavLeftItem[];
  dataMainNav: any;
  isAuthenticated: boolean;
}>;

export function XtLayout(props: XtLayoutProps): React.ReactElement {
  const [sideBarToggleByLogo, setSideBarToggleByLogo] = useState(false);
  const xtContext = useContext(XtContext);
  const { isExportPopupVisible } = xtContext;

  // console.log("xtContext val");
  // console.log(xtContext);
  // console.log(isPopupVisible);

  function ErrorComponent({ error }) {
    return <p>An Error Occurred: {error}</p>;
  }

  function LoadingComponent() {
    return <p>Authentication in progress...</p>;
  }

  const authRequest = {
    scopes: ["openid", "profile"],
  };

  const onLogoClicked = (e) => {
    setSideBarToggleByLogo(!sideBarToggleByLogo);
  };

  const isPopup = window.frameElement !== null;

  return (
    <>
      {props.isAuthenticated && (
        <div>
          <div className="app-container">
            {!isPopup && (
              <header>
                <XtTopNavBar2
                  dataMainNav={props.dataMainNav}
                  onLogoClick={onLogoClicked}
                />
              </header>
            )}
            <div className="side-bar-body-footer">
              {props.dataLeftNav.length > 0 && (
                <XtNavLeft
                  data={props.dataLeftNav}
                  toggleSideBarByLogo={sideBarToggleByLogo}
                />
              )}
              <div className="body-and-footer">
                <XtBody>{props.children}</XtBody>
                {isExportPopupVisible && (
                  <div className="d-flex align-items-center export-popup">
                    <Loader type="infinite-spinner" />
                    Exporting
                  </div>
                )}

                {!isPopup && (
                  <div className="mt-auto">
                    <Footer />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!props.isAuthenticated && (
        <div>
          {window.location.pathname !== "/login-silent" && (
            <div className="app-container">
              <XtBody>{props.children}</XtBody>
            </div>
          )}
          {window.location.pathname === "/login-silent" && (
            <div>
              <XtBody>{props.children}</XtBody>
            </div>
          )}
        </div>
      )}
    </>
  );
}
