//TODO cleanup unused imports and dead code

import { useEffect, useState } from "react";
import {
  Grid,
  GridColumn,
  GridFooterCellProps,
} from "@progress/kendo-react-grid";
import RightAlignGridColumnHeader from "../../Nw/PurchaseRequisition/Common/GridCell/RightAlignGridColumnHeader";
import AmountGridCell from "../../Nw/PurchaseRequisition/Common/GridCell/AmountGridCell";
import InvoiceAmountGridCell, {
  InvoiceTextGridCell,
} from "../../Nw/PurchaseRequisition/Common/GridCell/InvoiceAmountGridCell";
import { NumberUtil } from "../../../../Xt/Utils/NumberUtil";

export type AddInvoiceInvoiceCodingProps = {
  data?: any[];
  onInvoiceLineItemChange: any;
  triggerCount?: number;
  invCodeRunningTotals: any;
};

export function AddInvoiceInvoiceCoding(props: AddInvoiceInvoiceCodingProps) {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    var d = props.data;
    d.map((v, i) => {
      v.unitPrice = 0;
    });
    setData(d);
  }, [props.data]);

  const sumFooterCell = (props: GridFooterCellProps) => {
    const field = props.field || "";
    const sum = data
      .map((dataItem) => dataItem[field])
      .reduce((acc, current) => NumberUtil.decimalNumSum(acc, current), 0);

    return (
      <td style={{ textAlign: "right" }}>
        {NumberUtil.formatToTwoDecimalWithComma(sum)}
      </td>
    );
  };

  const runningTotalFooterCell = (props: GridFooterCellProps) => {
    const totalField = props.field || "";
    const totalSum = data
      .map((dataItem) => dataItem[totalField])
      .reduce((acc, current) => NumberUtil.decimalNumSum(acc, current), 0);

    const invoiceField = "unitPrice";
    const invoiceSum = data
      .map((dataItem) => dataItem[invoiceField])
      .reduce((acc, current) => NumberUtil.decimalNumSum(acc, current), 0);
    const runningTotalAmount = NumberUtil.decimalNumSum(totalSum, invoiceSum)

    return (
      <td style={{ textAlign: "right" }}>
        {NumberUtil.formatToTwoDecimalWithComma(runningTotalAmount)}
      </td>
    );
  };

  const remainingAmountFooterCell = (props: GridFooterCellProps) => {
    const totalField = props.field || "";
    const totalSum = data
      .map((dataItem) => dataItem[totalField])
      .reduce((acc, current) => NumberUtil.decimalNumSum(acc, current), 0);

    const invoiceField = "unitPrice";
    const invoiceSum = data
      .map((dataItem) => dataItem[invoiceField])
      .reduce((acc, current) => NumberUtil.decimalNumSum(acc, current), 0);
    const remAmount = NumberUtil.decimalNumDiff(totalSum, invoiceSum)
    return (
      <td style={{ textAlign: "right" }}>
        {NumberUtil.formatToTwoDecimalWithComma(remAmount)}
      </td>
    );
  };

  return (
    <>
      <Grid
        data={data.sort((l1, l2) => l1.lineNumber - l2.lineNumber)}
        total={0}
        className="k-grid-pr-list"
        editField="inEdit"
        onItemChange={(e) => {
          props.onInvoiceLineItemChange(e, e.dataItem);
        }}
      >
        <GridColumn
          title="#"
          field="lineNumber"
          width="35px"
          cell={(e) => {
            e.dataItem.inEdit = true;
            return <td>{e.dataItem.lineNumber}</td>;
          }}
        />
        <GridColumn
          title="Charge Code"
          field="projectNumber"
          width="250px"
          cell={(e) => {
            let acctNo = "";
            if (e.dataItem.lineAccountList.length > 0) {
              acctNo =
                e.dataItem.chargeCode +
                " - " +
                e.dataItem.lineAccountList[0].proJ_NAME;
            }
            return <td>{acctNo}</td>;
          }}
        />

        <GridColumn
          title="Description"
          field="poLineDescription"
          editable={false}
        />
        <GridColumn
          title="Invoice Line Description"
          field="description"
          editable={true}
          cell={InvoiceTextGridCell}
          width="200px"
        />
        <GridColumn
          title="Account #"
          field="accountNumber"
          cell={(e) => {
            let acctNo = "";
            acctNo = e.dataItem.accountID + " - " + e.dataItem?.accountName;
            return <td>{acctNo}</td>;
          }}
        />
        <GridColumn
          title="Invoice Coding Amount"
          field="unitPrice"
          editable={true}
          cell={InvoiceAmountGridCell}
          footerCell={sumFooterCell}
        />
        <GridColumn
          title="Commited Amount"
          field="committedAmount"
          headerCell={RightAlignGridColumnHeader}
          cell={AmountGridCell}
          footerCell={sumFooterCell}
        />
        <GridColumn
          title="Cost to Date"
          field="costToDateAmount"
          headerCell={RightAlignGridColumnHeader}
          cell={AmountGridCell}
          footerCell={sumFooterCell}
        />
        <GridColumn
          title="Running Total"
          field="runningTotal"
          headerCell={RightAlignGridColumnHeader}
          cell={(e) => {
            return (
              <td style={{ textAlign: "right" }}>
                {NumberUtil.formatToTwoDecimalWithComma(
                  props.invCodeRunningTotals[e.dataIndex] ??
                    e.dataItem.runningTotal
                )}
              </td>
            );
          }}
          footerCell={runningTotalFooterCell}
        />
        <GridColumn
          title="Remaining Amount"
          field="remainingAmount"
          headerCell={RightAlignGridColumnHeader}
          cell={(e) => {
            let remainingAmount = NumberUtil.decimalNumDiff(e.dataItem.committedAmount, props.invCodeRunningTotals[e.dataIndex] ?? e.dataItem.runningTotal) || 0
            return (
              <td style={{ textAlign: "right" }}>
                <span className={remainingAmount < 0 ? 'badge bg-danger' : ''}>
                {NumberUtil.formatToTwoDecimalWithComma(
                  remainingAmount ?? e.dataItem.remainingAmount
                )}
                </span>
              </td>
            );
          }}
          footerCell={remainingAmountFooterCell}
        />
      </Grid>
    </>
  );
}
