import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { process } from "@progress/kendo-data-query";
import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { ConfirmDialog } from 'nwcommon';
import { dataItem, XtField } from 'nwcommon';
import { XtGrid, XtGridRowSelection } from 'nwcommon';
import { Loading } from '../../../../Xt/Layout/Loading';
import { APIFactory, GroupPermissionApi, Group, GroupUser, UserGroupApi, UserApi } from '../../../../Xt/ApiClients';
import { XtPaging } from '../../../../Xt/ApiClients/ApiHelper';
import { IXtContext, XtContext } from '../../../../Xt/Contexts/XtContext';

export type XtGroupAssignmentProps = {
    dataItem: Group;
    onClose: any;
    title: string;

}

export function XtGroupAssignmentView(props: XtGroupAssignmentProps) {

    let factory: APIFactory;
    let pageSize = 5;
    let orderByField = "name";

    const [loading, setLoading] = React.useState<boolean>(false)
    const [time, setTime] = React.useState<number>(0)
    const [deleteMessage, setDeleteMessage] = React.useState<string>("")
    const [data, setData] = React.useState<any[]>([])
    const [users, setUsers] = React.useState<dataItem[]>([])
    const [dataCount, setDataCount] = React.useState<number>(0)
    const [paging, setPaging] = React.useState<XtPaging>({ skip: 0, top: pageSize, sort: { field: orderByField, dir: 'asc' } });
    const [gridSelection, setGridSelection] = React.useState<XtGridRowSelection>({ headerSelected: false, differedData: [] });
    const [showDelete, setShowDelete] = React.useState<boolean>(false);
    const [showConfirmAssignment, setShowConfirmAssignment] = React.useState<boolean>(false);
    const [showConfirmDeassignment, setShowConfirmDeassignment] = React.useState<boolean>(false);
    const [selectedUsers, setSelectedUsers] = React.useState<any[]>([]);
    const [defaultSelectedUsers, setDefaultSelectedUsers] = React.useState<any[]>([]);

    const context: IXtContext = React.useContext(XtContext);

    React.useEffect(() => {
        if (context?.loggedOnUser?.username) {
            let s: UserGroupApi = factory.create(UserGroupApi);
            let s1: UserApi = factory.create(UserApi);
            setLoading(true)
            s.userGroupGroupGroupGuidGet(props.dataItem.groupGuid, context?.loggedOnUser?.username).then(async (v) => {
                if (v.ok) {
                    let json = await v.json();
                    setDataCount(json['@odata.count'])
                    setData(json.value);
                }
                else {
                    // TODO: error
                }
            }).finally(() => {
                setLoading(false)
            })

            setLoading(true)
            s1.userGet(context?.loggedOnUser?.username).then(async (v) => {
                if (v.ok) {
                    let json = await v.json();
                    let items: dataItem[] = [];
                    json.value?.map((v, i) => {
                        items.push({
                            value: v.userGuid,
                            text: v.displayname
                        })
                    })
                    setUsers(items);
                }
                else {
                    // TODO: Error
                }
            }).finally(() => {
                setLoading(false)
            })
        }
        
    }, [props.dataItem, time, context?.loggedOnUser?.username])

    const schema = [
        { field: "user.displayname", label: "Name", visible: true, searchable: true, type: 'text' },
        { field: "user.jobTitle", label: "Job title", visible: true, searchable: true, type: 'text' },
        { field: "user.company", label: "Company", visible: true, searchable: true, type: 'text' }
    ]

    const onSubmit = async (e) => {
        if (props.onClose) {
            props.onClose({ ...e, reloadData: true });
        }
    }

    const onCancel = (e) => {
        if (props.onClose) {
            props.onClose(e)
        }
    }

    const renderGridFields = () => {
        let items: any[] = [];
        schema.map((v, i) => {
            if (v.visible) {
                items.push(<GridColumn sortable={true} key={"row" + i} field={v.field} title={v.label} />)
            }
        })
        return items;
    }

    const onDeleteClick = async (e) => {
        setDeleteMessage("Do you really want to delete user from this group?")
        setSelectedUsers([e.dataItem]);
        setShowConfirmDeassignment(true);
    }

    const rowMenus = [
        { text: 'Delete', icon: 'k-icon k-i-delete k-i-trash', onClick: onDeleteClick }
    ]

    const onSortChange = (e) => {
        if (e.sort.length > 0)
            setPaging({ ...paging, sort: e.sort[0] })
        else {
            setPaging({ ...paging, sort: { ...paging.sort, dir: (paging.sort.dir === "asc") ? "desc" : "asc" } })
        }
    }

    const onPageChange = (e) => {
        setPaging({ ...paging, skip: e.page.skip, top: e.page.take })
    }

    const onGridSelectionChange = (e) => {
       
        if (e.headerSelected || (!e.headerSelected && e.differedData.length > 0)) {
            setShowDelete(true)
        }
        else {
            setShowDelete(false)
        }
        
       // console.log(e);
        setGridSelection(e)
    }

    const onUserSelectionChange = (e) => {
        let items: any[] = [];
        e.value?.map((v, i) => {
            items.push({
                groupGuid: props.dataItem.groupGuid,
                userGuid: v.value
            })
        })
        setSelectedUsers(items)
        setDefaultSelectedUsers(e.value)
    }

    const onAssignUserToGroup = (e) => {
        setShowConfirmAssignment(true)
    }

    const onCloseAssignmentConfirmation = (e) => {
        setShowConfirmAssignment(false)
    }

    const onAssignmentConfirm = (e) => {
        let s: UserGroupApi = factory.create(UserGroupApi);
        setShowConfirmAssignment(false)
        setLoading(true)
        s.userGroupPost(context?.loggedOnUser?.username, selectedUsers).then((v) => {
            if (v.ok) {
                setDefaultSelectedUsers([])
                setSelectedUsers([])
                setTime(new Date().getTime())
            }
            else {
                // TODO: show error
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const onCloseDeassignmentConfirmation = (e) => {
        setShowConfirmDeassignment(false)
    }

    const onDeassignmentConfirm = async (e) => {
        //if action is bulk delete
        if ((gridSelection.headerSelected || gridSelection.differedData.length > 0) && selectedUsers.length === 0) {
            let delData: any[] = [];
            if (gridSelection.headerSelected) {
                data.map((v, i) => {
                    let index = gridSelection.differedData.findIndex((v1, i1) => { if (v1.groupUserGuid === v.groupUserGuid) { return i1; } return -1; });
                    if (index === 0) {
                        delData.push({
                            groupUserGuid: v.groupUserGuid,
                            userGuid: v.userGuid,
                            groupGuid: v.groupGuid
                        })
                    }
                })
            }
                
            else {
                gridSelection.differedData.map((v, i) => {
                    delData.push({
                        groupUserGuid: v.groupUserGuid,
                        userGuid: v.userGuid,
                        groupGuid: v.groupGuid
                    })
                })
            }
            setShowConfirmDeassignment(false);
            setLoading(true);
            let s: UserGroupApi = factory.create(UserGroupApi);
            var result = await s.userGroupDelete(context?.loggedOnUser?.username, delData)
            if (result.ok) {
                setLoading(false);
                setTime(new Date().getTime())
            }
            else {
                // TODO: Error
            }
        }
        else {
            setShowConfirmDeassignment(false);
            setLoading(true);
            let s: UserGroupApi = factory.create(UserGroupApi);
            var result = await s.userGroupKeyDelete(context?.loggedOnUser?.username, selectedUsers[0].groupUserGuid);
            if (result.ok) {
                setLoading(false);
                setTime(new Date().getTime())
            }
            else {
                // TODO: Error
            }
        }
    }

    const onMultipleDelete = (e) => {
        var d = gridSelection;
        setSelectedUsers([]);
        setDeleteMessage("Do you really want to delete selected users from this group?")
        setShowConfirmDeassignment(true);
    }

    const processData = () => {
        var d = process(data, { skip: paging.skip, sort: [paging.sort], take: paging.top })
        return d.data;
    }
    
    return (
        <>
            {<Dialog title={props.title} onClose={props.onClose} {...props} width={"50%"} >
                <APIFactory ref={(e) => { factory = e; }} />
                <GridToolbar>
                    <Container>
                        {showDelete && <Col xs={4}><Button variant="secondary" type="button" onClick={ onMultipleDelete }>Delete</Button></Col>}
                        <Col xs={8}>                            
                            <Row>
                                <Col xs={10}>
                                    <XtField name="fields" placeholder="Users" type="multiselect" items={users} textField="text" value={ defaultSelectedUsers } onChange={ onUserSelectionChange } />
                                </Col>
                                <Col xs={2}><Button variant="primary" disabled={(selectedUsers.length === 0) ? true : false} type="button" onClick={ onAssignUserToGroup }>Assign</Button></Col>
                            </Row>
                        </Col>
                    </Container>
                </GridToolbar>
                <XtGrid uniqueField="groupUserGuid" data={processData()} total={dataCount} sort={[paging.sort]} rowMenus={rowMenus} sortable={true} 
                    skip={paging.skip} pageSize={pageSize} pageable={true} onSortChange={onSortChange} onPageChange={onPageChange} onGridSelectionChange={onGridSelectionChange}>
                    {renderGridFields()}
                </XtGrid>
                {loading && <Loading />}
                {showConfirmAssignment && <ConfirmDialog message="Do you really want to assign these users to the group?" onClose={onCloseAssignmentConfirmation} onNo={onCloseAssignmentConfirmation} onYes={onAssignmentConfirm} title="User Assigment to Group" width="50%" />}
                {showConfirmDeassignment && <ConfirmDialog message={ deleteMessage } onClose={onCloseDeassignmentConfirmation} onNo={onCloseDeassignmentConfirmation} onYes={onDeassignmentConfirm} title="Delete User from Group" width="50%" />}
            </Dialog>}
        </>
    )
}