import React from "react"
import { XtDialogBrowser } from "./XtDialogBrowser";
import { XtFileType } from "./XtFile";
import { XtInlineBrowser } from "./XtInlineBrowser";

export type XtDisplayType = "inline" | "dialog"

export type XtFileBrowserProps = React.PropsWithChildren<{
    displayType: XtDisplayType
    paneHeight: string
    path: string
    files: XtFileType[]
    onFolderOpen: any
    onUpload: any
    allowChangeFolder: boolean
    onFolderCreate?: any
}>;

export function XtFileBrowser(props: XtFileBrowserProps) {
    return (<div className="filebrowser-wrapper">
        {props.displayType === "inline" && <XtInlineBrowser {...props}/>}
        {props.displayType === "dialog" && <XtDialogBrowser {...props}/>}
    </div>)
}