import React from 'react';
import {
    Grid,
    GridColumn,
} from "@progress/kendo-react-grid";
import { XtPaging } from '../../../../Xt/ApiClients/ApiHelper';
import { InvoiceApproval } from '../../../../Xt/ApiClients';
import PrCompletedGridCell from '../../Nw/PurchaseRequisition/Common/GridCell/PrCompletedGridCell';
import NwWorkflowReviewerCell from '../../Nw/PurchaseRequisition/Common/GridCell/WorkflowReviewerCell';


export type InvoiceCPIntegrationReviewerProps = {
    data: InvoiceApproval[],
    isApprover: boolean
}

export function InvoiceCPIntegrationReviewer(props: InvoiceCPIntegrationReviewerProps) {

    const [data, setData] = React.useState<InvoiceApproval[]>([]);

    React.useEffect(() => {
        if (props.data) {
            let d = props.data;
            let stages: any[] = [];
            d.map((v) => {
                if (stages.indexOf(v.level) === -1)
                    stages.push(v.level)
            })
            stages.sort((v, v1) => { return v < v1 ? v1 : v; });
            d.map((v) => {
                v.level = stages.indexOf(v.level) + 1;
            })
            setData(d)
        }
    }, [props.data])


    const onAddReviwerIconClick = (e) => {

    }

    const minStageValue = () => { return 1; }
    const maxStageValue = () => { return 10; }

    const reviewCell = (reviewProps) => (
        <NwWorkflowReviewerCell
            {...reviewProps}
            onAddReviewerClick={onAddReviwerIconClick}
            minStage={minStageValue()}
            maxStage={maxStageValue()}
            isApprover={props.isApprover}
        />
    );

    return (<>
        <Grid
            data={data}
            total={0}
            className="k-grid-pr-list"
        >
            {/*<GridColumn title="" field="" width="100px" cell={reviewCell}/>*/}
            {/*<GridColumn title="Stage" field="level" width="300px" />*/}
            <GridColumn title="Reviewer" field="displayName" width="300px"  />
            <GridColumn title="Reviewer Role" field="role" width="300px" />
            <GridColumn title="Status" field="status" width="300px" cell={PrCompletedGridCell} />
            <GridColumn title="Comments" field="comment" />
        </Grid>
    </>)
}