import { PrrRequestType } from '../Interfaces/Enums';

export const enum EnPrrTypeDetail {
  PRR_CREATE_NEW = "PRR_CREATE_NEW",
  PRR_EDIT = "PRR_EDIT",
  PRR_CLONE = "PRR_CLONE",
}
export const enum EnPrrAction {
  //Form Actions
  CREATE_NEW_PRR = "CREATE_NEW_PRR",
  EDIT_PRR = "EDIT_PRR",
  CLONE_PRR = "CLONE_PRR",
  //Variable Changes
  //General
  PRR_NUMBER_CHANGE = "PRR_NUMBER_CHANGE",
  CREATED_BY_CHANGE = "CREATED_BY_CHANGE",
  CREATED_ON_CHANGE = "CREATED_ON_CHANGE",
  DATE_NEEDED_CHANGE = "DATE_NEEDED_CHANGE",
  EMPLOYEE_TYPE_CHANGE = "EMPLOYEE_TYPE_CHANGE",
  COMPANY_CODE_CHANGE = "COMPANY_CODE_CHANGE",
  CHARGE_CODE_CHANGE = "CHARGE_CODE_CHANGE",
  PROJECT_NUMBER_CHANGE = "PROJECT_NUMBER_CHANGE",
  REASON_FOR_REQUEST_CHANGE = "REASON_FOR_REQUEST_CHANGE",
  REPLACEMENT_EMPLOYEE_CHANGE = "REPLACEMENT_EMPLOYEE_CHANGE",
  //Position Info
  EMPLOYMENT_TYPE_CHANGE = "EMPLOYMENT_TYPE_CHANGE",
  JOB_TITLE_CHANGE = "JOB_TITLE_CHANGE",
  JOB_DESCRIPTION_CHANGE = "JOB_DESCRIPTION_CHANGE",
  ATTACHMENT_ADD_FILE = "ATTACHMENT_ADD_FILE",
  ATTACHMENT_DELETE_FILE = "ATTACHMENT_DELETE_FILE",
  LOCATIONS_LIST_ADD = "LOCATIONS_LIST_ADD",
  LOCATIONS_LIST_DELETE = "LOCATIONS_LIST_DELETE",
  LOCATIONS_LIST_EDIT = "LOCATIONS_LIST_EDIT",
  LABOR_GROUP_CHANGE = "LABOR_GROUP_CHANGE",
  ORG_CHANGE = "ORG_CHANGE",
  NUMBER_OF_VACANCIES_CHANGE = "NUMBER_OF_VACANCIES_CHANGE",
  IS_HIRING_MANAGER_CHANGE = "IS_HIRING_MANAGER_CHANGE",
  HIRING_MANAGER_CHANGE = "HIRING_MANAGER_CHANGE",
  DEPARTMENT_CHANGE = "DEPARTMENT_CHANGE",
  //Additional info
  WAGE_TYPE_CHANGE = "WAGE_TYPE_CHANGE",
  MIN_WAGE_RATE_CHANGE = "MIN_WAGE_RATE_CHANGE",
  MAX_WAGE_RATE_CHANGE = "MAX_WAGE_RATE_CHANGE",
  YEARS_OF_EXPERIENCE_CHANGE = "YEARS_OF_EXPERIENCE_CHANGE",
  EDUCATION_CHANGE = "EDUCATION_CHANGE",
  IS_TRAVEL_REQUIRED_CHANGE = "IS_TRAVEL_REQUIRED_CHANGE",
  TRAVEL_PERCENTAGE_CHANGE = "TRAVEL_PERCENTAGE_CHANGE",
  REQUIRED_SKILLS_CHANGE = "REQUIRED_SKILLS_CHANGE",
  REQUIRED_LISCENSES_CHANGE = "REQUIRED_LISCENSES_CHANGE",
  DESIRED_SKILLS_CHANGE = "DESIRED_SKILLS_CHANGE",
  SECURITY_ACCESS_CHANGE = "SECURITY_ACCESS_CHANGE",
  SPECIAL_SKILLS_CHANGE = "SPECIAL_SKILLS_CHANGE",
  COMMENTS_CHANGE = "COMMENT_CHANGE",
  //Hidden fields
  STATUS_CHANGE = "STATUS_CHANGE",
  REQUISITION_TYPE_CHANGE = "REQUISITION_TYPE_CHANGE",
  IS_COMPLETED_CHANGE = "IS_COMPLETED_CHANGE",
  PRR_TYPE_CHANGE = "PRR_TYPE_CHANGE",
  PRR_TYPE_DETAIL_CHANGE = "PRR_TYPE_DETAIL_CHANGE",
  //Internal component use
  PROJECT_LIST_CHANGE = "PROJECT_LIST_CHANGE",
  CLONE = "CLONE",
}

export class PrrAction {
  type: EnPrrAction;
  payload: any;
}

export const initialState = {
  //Form Data
  //General
  prrNumber: "Auto generated",
  createdBy: 0,
  createdOn: new Date(),
  dateNeeded: undefined,
  employeeType: undefined,
  companyCode: "",
  companyBambooName: "",
  projectNumber: "",
  projectName: "",
  chargeNumber: "",
  requestReason: "",
  replacementEmployee: 0,
  //Position Info
  employmentType: "",
  jobTitle: "",
  jobDescription: "",
  attachmentList: [],
  attachmentFileList: [],
  additionalAttachmentFileList: [],
  locationList: [],
  laborGroup: "",
  organization: "",
  vacancies: 1,
  isHiringManager: undefined,
  hiringManager: undefined,
  //Additional Info
  wageType: "",
  wageMaximum: 0,
  wageMinimum: 0,
  yearsExperience: "",
  education: "",
  status: "Draft",
  prrType: "New Personnel Request",
  travelRequired: "",
  travelAmount: "",
  requiredSkills: "",
  requiredLicenses: "",
  desiredSkills: "",
  securityAccessRequirements: "",
  specialSkillsTraining: "",
  comments: "",

  //Hidden fields
  requisitionType: PrrRequestType.PERSONNEL_REQUISITION,
  isCompleted: "No",
  prrTypeDetail: EnPrrTypeDetail.PRR_CREATE_NEW,
};

export const PrrReducer = {
  reducer: (state: State, action) => {
    switch (action.type) {
      //Set initial state
      case EnPrrAction.CREATE_NEW_PRR:
        state = {};
        return {
          ...initialState,
          prrTypeDetail: EnPrrTypeDetail.PRR_CREATE_NEW,
        };
      //Edit state of exisiting prr
      case EnPrrAction.EDIT_PRR:
        let editState = {
          ...action.payload,
          additionalAttachmentFileList: action.payload.attachmentList,
          isCompleted: action.payload.isCompleted ? "Yes" : "No",
          dateNeeded: action.payload?.dateNeeded
            ? new Date(action.payload?.dateNeeded)
            : undefined,
          prrTypeDetail: EnPrrTypeDetail.PRR_EDIT,
          companyCode: action.payload.companyCode,
          wageMaximum: action.payload.wageMaximum,
          wageMinimum: action.payload.wageMinimum,
          employeeType:
            action.payload.employeeType === "True" ? "Direct" : "Indirect",
          travelRequired: action.payload.travelRequired ? "Yes" : "No",
          isHiringManager: action.payload.isHiringManager ? "Yes" : "No",
          department: action.payload.department,
          locationList: action.payload.locationList,
          requisitionType: PrrRequestType.EDIT_PERSONNEL_REQUISITION,
        };
        return editState;

      case EnPrrAction.CLONE_PRR:
        let cloneState = {
          ...action.payload,
          prrNumber: "Auto generated",
          createdOn: new Date(),
          isCompleted: "No",
          dateNeeded: new Date(),
          prrTypeDetail: EnPrrTypeDetail.PRR_CLONE,
          status: "Draft",
          attachmentList: [],
          hiringManager: action.payload.hiringManager,
          companyCode: action.payload.companyCode,
          employeeType:
            action.payload.employeeType === "True" ? "Direct" : "Indirect",
          travelRequired: action.payload.travelRequired ? "Yes" : "No",
          isHiringManager: action.payload.isHiringManager ? "Yes" : "No",
          locationList: action.payload.locationList,
          requisitionType: PrrRequestType.EDIT_PERSONNEL_REQUISITION,
          department: action.payload.department
        };
        return cloneState;
      case EnPrrAction.PRR_NUMBER_CHANGE:
        return {
          ...state,
          prrNumber: action.payload,
        };
      case EnPrrAction.CREATED_BY_CHANGE:
        return {
          ...state,
          createdBy: action.payload,
        };
      case EnPrrAction.CREATED_ON_CHANGE:
        return {
          ...state,
          createdOn: action.payload,
        };
      case EnPrrAction.DATE_NEEDED_CHANGE:
        return {
          ...state,
          dateNeeded: action.payload,
        };
      case EnPrrAction.EMPLOYEE_TYPE_CHANGE:
        return {
          ...state,
          employeeType: action.payload,
        };
      case EnPrrAction.COMPANY_CODE_CHANGE:
        return {
          ...state,
          companyCode: action.payload.companyCode,
          companyBambooName: action.payload.companyBambooName,
          employingCompany: action.payload.employingCompany,
        };
      case EnPrrAction.PROJECT_NUMBER_CHANGE:
        return {
          ...state,
          projectNumber: action.payload?.value,
          projectName: action.payload?.text,
        };
      case EnPrrAction.CHARGE_CODE_CHANGE:
        return {
          ...state,
          chargeNumber: action.payload,
        };
      case EnPrrAction.REASON_FOR_REQUEST_CHANGE:
        return {
          ...state,
          requestReason: action.payload,
        };
      case EnPrrAction.REPLACEMENT_EMPLOYEE_CHANGE:
        return {
          ...state,
          replacementEmployee: action.payload,
        };
      case EnPrrAction.EMPLOYMENT_TYPE_CHANGE:
        return {
          ...state,
          employmentType: action.payload,
        };
      case EnPrrAction.JOB_TITLE_CHANGE:
        return {
          ...state,
          jobTitle: action.payload,
        };
      case EnPrrAction.JOB_DESCRIPTION_CHANGE:
        return {
          ...state,
          jobDescription: action.payload,
        };
      case EnPrrAction.ATTACHMENT_ADD_FILE:
        return {
          ...state,
          additionalAttachmentFileList: action.payload,
        };
      case EnPrrAction.ATTACHMENT_DELETE_FILE:
        const attachmentList = state?.attachmentList.filter(
          (a) => a.attachmentGuid !== action.payload
        );

        // return { ...state, attachmentList: attachmentList };
        // case EnPrrAction.ATTACHMENT_DELETE_FILE:
        //   const additionalAttachmentFileList =
        //     state?.AttachmentFileList.filter(
        //       (a) => a.attachmentGuid !== action.payload
        //     );

        return {
          ...state,
          attachmentList: attachmentList,
        };

      case EnPrrAction.LOCATIONS_LIST_ADD:
        return {
          ...state,
          locationList: state.locationList
            ? [...state.locationList, action.payload]
            : [action.payload],
        };

      case EnPrrAction.LOCATIONS_LIST_EDIT:
        return {
          ...state,
          locationList: state.locationList.map((location, index) =>
            index === action.payload.index ? action.payload.location : location
          ),
        };

      case EnPrrAction.LOCATIONS_LIST_DELETE:
        let updatedLocationList = [...state.locationList];
        updatedLocationList.splice(action.payload, 1);

        updatedLocationList = updatedLocationList.map((location, index) => {
          return { ...location, idx: index + 1 };
        });

        return {
          ...state,
          locationList: updatedLocationList,
        };

      case EnPrrAction.LABOR_GROUP_CHANGE:
        return {
          ...state,
          laborGroup: action.payload,
        };
      case EnPrrAction.ORG_CHANGE:
        return {
          ...state,
          organization: action.payload,
        };
      case EnPrrAction.NUMBER_OF_VACANCIES_CHANGE:
        return {
          ...state,
          vacancies: action.payload,
        };
      case EnPrrAction.IS_HIRING_MANAGER_CHANGE:
        return {
          ...state,
          isHiringManager: action.payload.isHiringManager,
        };
      case EnPrrAction.HIRING_MANAGER_CHANGE:
        return {
          ...state,
          hiringManager: action.payload,
        };
      case EnPrrAction.DEPARTMENT_CHANGE:
        return {
          ...state,
          department: action.payload,
        };

      case EnPrrAction.WAGE_TYPE_CHANGE:
        return {
          ...state,
          wageType: action.payload,
        };
      case EnPrrAction.MAX_WAGE_RATE_CHANGE:
        return {
          ...state,
          wageMaximum: action.payload,
        };
      case EnPrrAction.MIN_WAGE_RATE_CHANGE:
        return {
          ...state,
          wageMinimum: action.payload,
        };
      case EnPrrAction.YEARS_OF_EXPERIENCE_CHANGE:
        return {
          ...state,
          yearsExperience: action.payload,
        };
      case EnPrrAction.EDUCATION_CHANGE:
        return {
          ...state,
          education: action.payload,
        };
      case EnPrrAction.IS_TRAVEL_REQUIRED_CHANGE:
        return {
          ...state,
          travelRequired: action.payload,
        };
      case EnPrrAction.TRAVEL_PERCENTAGE_CHANGE:
        return {
          ...state,
          travelAmount: action.payload,
        };
      case EnPrrAction.REQUIRED_SKILLS_CHANGE:
        return {
          ...state,
          requiredSkills: action.payload,
        };
      case EnPrrAction.REQUIRED_LISCENSES_CHANGE:
        return {
          ...state,
          requiredLicenses: action.payload,
        };
      case EnPrrAction.DESIRED_SKILLS_CHANGE:
        return {
          ...state,
          desiredSkills: action.payload,
        };
      case EnPrrAction.SECURITY_ACCESS_CHANGE:
        return {
          ...state,
          securityAccessRequirements: action.payload,
        };
      case EnPrrAction.SPECIAL_SKILLS_CHANGE:
        return {
          ...state,
          specialSkillsTraining: action.payload,
        };
      case EnPrrAction.COMMENTS_CHANGE:
        return {
          ...state,
          comments: action.payload,
        };
      case EnPrrAction.REQUISITION_TYPE_CHANGE:
        return {
          ...state,
          requisitionType: action.payload,
        };
      case EnPrrAction.IS_COMPLETED_CHANGE:
        return {
          ...state,
          isCompleted: action.payload,
        };

      case EnPrrAction.PRR_TYPE_DETAIL_CHANGE:
        return {
          ...state,
          prrTypeDetail: action.payload,
        };
      case EnPrrAction.PRR_TYPE_CHANGE:
        return {
          ...state,
          prrType: action.payload,
        };
      default:
        return initialState;
    }
  },
};

export type State = {
  employeeName?: any;
  prrNumber?: string;
  createdBy?: number;
  createdOn?: Date;
  dateNeeded?: Date;
  employeeType?: string;
  companyCode?: string;
  companyBambooName?: string;
  projectNumber?: string;
  chargeNumber?: string;
  requestReason?: string;
  replacementEmployee?: string;
  employmentType?: string;
  jobTitle?: string;
  jobDescription?: string;
  attachmentList?: any[];
  locationList?: any;
  laborGroup?: string;
  organization?: string;
  vacancies?: string;
  isHiringManager?: string;
  hiringManager?: string;
  wageType?: string;
  wageMaximum?: number;
  wageMinimum?: number;
  yearsExperience?: number;
  education?: number;
  status?: string;
  prrType?: string;
  travelRequired?: string;
  travelAmount?: string;
  requiredSkills?: string;
  requiredLicenses?: string;
  desiredSkills?: string;
  securityAccessRequirements?: string;
  specialSkillsTraining?: string;
  comments?: string;
  requisitionType?: string;
  isCompleted?: string;
  prrTypeDetail?: EnPrrTypeDetail;
};
