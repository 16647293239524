import { Row } from "react-bootstrap";
import AddressReadOnly from "../ApprovalView/AddressReadOnly";
import FormField from "./FormField";
import FormLabelMutedText from "./FormLabelMutedText";

export interface NwVendorContactProps {
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactPhone?: string;
  address?: Address;
}

export interface Address {
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  addressCode?: string;
}

export default function NwVendorContactReadOnly(props: NwVendorContactProps) {
  return (
    <Row className="pt-3">
      <FormField label="Contact First Name">
        <FormLabelMutedText value={props.contactFirstName} />
      </FormField>
      <FormField label="Contact Last Name">
        <FormLabelMutedText value={props.contactLastName} />
      </FormField>

      <FormField label="Contact Email">
        <FormLabelMutedText value={props.contactEmail} />
      </FormField>

      <FormField label="Contact Phone">
        <FormLabelMutedText value={props.contactPhone} />
      </FormField>

      <FormField label="Vendor Address">
        <AddressReadOnly {...props?.address} />
      </FormField>
    </Row>
  );
}
