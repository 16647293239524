import React from "react";
import { DateUtil } from "../../../../../../Xt/Utils/DateUtil";


export default function DateGridCell(props) {
  return (
    <td >
      {DateUtil.formatmmDdYYY(props.dataItem[props.field])}
    </td>
  );
}
