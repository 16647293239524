import React, { useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import {
  XtBreadcrumbsProps,
  XtBreadcrumbs,
} from "../../../../Xt/Layout/XtBreadcrumbs";
import { XtGroup } from "../../XtGroup";
import { XtUser } from "../../XtUser";
import { XtUserGroupView } from "./XtUserGroup";
import { useLocation, Redirect } from "react-router-dom";

export default function XtUserPermission() {
  const redirectTo = {shouldRedirect:false, url:""}
  const [redirect,setRedirect] = useState(redirectTo);
  const location = useLocation();
  
  const path: string = location.pathname;

  const [showUser, setShowUser] = useState(path.toLowerCase().includes("user"));
  const [showGroup, setShowGroup] = useState(
    path.toLowerCase().includes("group")
  );

  const breadCrumbs: XtBreadcrumbsProps = {
    breadCrumbs: [
      {
        href: "/admin/settings",
        text: "Settings",
        isActive: false,
      },
      {
        href: "/user",
        text: "User Management",
        isActive: true,
      },
    ],
  };

  const onTabSelect = (e) => {
    if (e == "user") {
      setShowUser(true);
      setShowGroup(false);
    } else {
      setShowUser(false);
      setShowGroup(true);
    }
    setRedirect({shouldRedirect:true,url:e});
    //history.push(e);
  };

  return (
    <>
     {redirect.shouldRedirect && <Redirect push to={redirect.url}/>}
    <div className="xt-tabs">
      <XtBreadcrumbs {...breadCrumbs} />
      <Tabs
        activeKey={path.replace("/", "")}
        id="uncontrolled-tab-example"
        onSelect={onTabSelect}
      >
        <Tab eventKey="user" title="Users">
          {showUser && <XtUser />}
        </Tab>
        <Tab eventKey="group" title="Groups">
          {showGroup && <XtGroup />}
        </Tab>
      </Tabs>
    </div>
    </>
    
  );
}
