import { Container, Navbar } from "react-bootstrap";

export function XtNavBarContainer(props) {
    return (
        <Navbar bg="light" className="shadow-sm" expand="lg">
            <Container fluid>
                {props.children}
            </Container>
        </Navbar>
    )
}