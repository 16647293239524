import  { useContext, useEffect, useState } from "react";
import { Row} from "react-bootstrap";
import {
  APIFactoryCommon,
  CompanyApi
} from "../../../../../Xt/ApiClients/Common";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { DateUtil } from "../../../../../Xt/Utils/DateUtil";
import FormField from "../Common/FormField";
import FormLabelMutedText, { FormPoLink } from "../Common/FormLabelMutedText";
import { enum_request_type } from "../Common/NwPrConstants";






export default function NwGeneralInfoReadOnly(props) {
let factory: APIFactoryCommon;
const {pr} = props;
  return (
    <>
      <APIFactoryCommon
        ref={(e) => {
          factory = e;
        }}
      />

      <Row>
        <FormField label="PR No.">
          <FormLabelMutedText value={pr?.prNumber} />
        </FormField>

        <FormField label="Requisition Type">
          <FormLabelMutedText
            value={
              pr?.requisitionType == "Material"
                ? "Material/ODCs"
                : pr?.requisitionType
            }
          />
        </FormField>

        <FormField label="Created By">
          <FormLabelMutedText value={pr?.employeeName} />
        </FormField>

        <FormField label="Charge Type">
          <FormLabelMutedText value={pr?.chargeType} />
        </FormField>

        <FormField label="Date Created">
          <FormLabelMutedText value={DateUtil.formatmmDdYYY(pr?.createdOn)} />
        </FormField>
        <FormField label="NW Company">
          <FormLabelMutedText value={pr?.companyName} />
        </FormField>

        {pr?.chargeType == "Direct" && (
          <>
            <FormField></FormField>
            <FormField label="Project No.">
              <FormLabelMutedText value={pr?.projectNumber} />
            </FormField>
          </>
        )}

        <FormField label="PR Type">
          <FormLabelMutedText value={pr?.prType} />
        </FormField>

        <FormField label="Date Needed">
          <FormLabelMutedText value={DateUtil.formatmmDdYYY(pr?.dateNeeded)} />
        </FormField>
        {pr?.prType === enum_request_type.PO_CHANGE_REQUEST && (
          <FormField label="PO Number">
            <FormPoLink poNumber={pr?.poNumber}></FormPoLink>
          </FormField>
        )}
      </Row>
    </>
  );
}
