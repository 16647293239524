import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useContext, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormField from "../PurchaseRequisition/Common/FormField";
import FormLabelMutedText from "../PurchaseRequisition/Common/FormLabelMutedText";
import { XtField } from "nwcommon";
import { APIFactory, PurchaseOrderApi } from "../../../../Xt/ApiClients";
import { Redirect, useParams, useLocation } from "react-router-dom";
import { Loading } from "../../../../Xt/Layout/Loading";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";

const tempPoData = [
  { poNumber: "6-00000714", prGuid: "2dad6be7-f1b7-4682-85be-88b12c94b6c1" },
];

export default function POChangeRequestDailog(props) {
    const context: IXtContext = useContext(XtContext);
  const redirectTo = { shouldRedirect: false, url: "" };
  const [redirect, setRedirect] = useState(redirectTo);
  const [title,setTitle] = useState("Data loading...")
  const init = true;
  let factory;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showDialog ,setShowdialog] =useState(true);
  const [poData, setPoData] = useState([]);
  const getPOListFromApi = () => {
    let api: PurchaseOrderApi = factory.create(PurchaseOrderApi);
    setLoading(true);
    api
      .purchaseOrderGet(context?.loggedOnUser.username)
      .then(async (data) => {
        var dataFromApi = await data.json();

        const poData = [];
        dataFromApi.value.map((po) => {
          poData.push({
            text: po.pO_ID,
            value: po.pO_ID,
          });
        });
        //setPoData(poData);
       
      })
      .finally(() => {
        setLoading(false);
        setTitle("Select Purchase Order")
      });
  };

  React.useEffect(() => {
   // getPOListFromApi();

    setPoData(tempPoData);
  }, [init]);

  const onPOChange = (e) => {
    setRedirect({shouldRedirect:true,url:"/po/change/"+e?.value?.prGuid})
  };
  const onClose = (e) => {
    setRedirect({shouldRedirect:true,url:"/"})
  };
  return (
    <>
          {redirect.shouldRedirect && <Redirect push to={redirect.url} />}
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <Dialog
        onClose={onClose}
        {...props}
        width={"30%"}
        height={"20%"}
        title={title}
      >
        <Col md={6} className="mb-3">
          <Row>
            <Form.Label column sm={6} className="required pe-0">
              PO Number
            </Form.Label>
            <Col sm={6}>
              <XtField
                type="dropdown"
                name="PONumber"
                items={poData}
                textField="poNumber"
                onChange={onPOChange}
              />
            </Col>
          </Row>
        </Col>
        <Row />
      </Dialog>
      {loading && <Loading />}
    </>
  );
}
