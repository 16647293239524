import React from "react";
import * as Search from "nwcommon";
import { XtAdvancedSearchFieldCondition } from "nwcommon";
import { XtAdvancedSearchBox } from "nwcommon";
import { XtExport, XtExportType } from "nwcommon";
import { XtContext, IXtContext } from "../../Xt/Contexts/XtContext";
import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";

import { XtGrid, XtGridRowSelection } from "nwcommon";
import { Loading } from "../../Xt/Layout/Loading";
import { XtUserGroupView } from "./Dialogs/Users/XtUserGroup";
import { ApiHelperGet, XtPaging, XtSort } from "../../Xt/ApiClients/ApiHelper";
import { APIFactory, User, UserApi } from "../../Xt/ApiClients";

interface XtExportFileType {
  type: XtExportType;
  fields: any[];
  filename: string;
}

export function XtUser(props) {
    const pageSize = 15;
    const orderByField = "displayName";
    const init = true;
    const [expType, setExportType] = React.useState<XtExportFileType>({
        type: "none",
        fields: [],
        filename: "",
    });
    const [data, setData] = React.useState<any[]>([]);
    const [reportData, setReportData] = React.useState<any[]>([]);
    const [dataCount, setDataCount] = React.useState<number>(0);
    const [dialogTitle, setDialogTitle] = React.useState<string>("");
    const [isExported, setIsExported] = React.useState<boolean>(false);
    const [paging, setPaging] = React.useState<XtPaging>({
        skip: 0,
        top: pageSize,
        sort: { field: orderByField, dir: "asc" },
    });
    const [searchParams, setSearchParam] = React.useState<any>({});
    const [gridSelection, setGridSelection] = React.useState<XtGridRowSelection>({
        headerSelected: false,
        differedData: [],
    });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showDialog, setShowDialog] = React.useState<boolean>(false);
    const [time, setTime] = React.useState<number>(0);
    const [editingGroup, setEditingGroup] = React.useState<any>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] =
        React.useState<boolean>(false);
    const [showGroupPermission, setShowGroupPermission] =
        React.useState<boolean>(false);
    const [showGroupAssignment, setShowGroupAssignment] =
        React.useState<boolean>(false);

    let factory;
    const context: IXtContext = React.useContext(XtContext);

    const schema = [
        {
            field: "displayname",
            label: "Name",
            visible: true,
            searchable: true,
            type: "text",
        },
        {
            field: "jobTitle",
            label: "Job Title",
            visible: true,
            searchable: true,
            type: "text",
            width: "150px",
        },
        {
            field: "department",
            label: "Department",
            visible: true,
            searchable: true,
            type: "text",
            width: "100px",
        },
        {
            field: "workEmail",
            label: "Work Email",
            visible: true,
            searchable: true,
            type: "text",
            width: "220px",
        },
        {
            field: "workPhone",
            label: "Work Phone",
            visible: true,
            searchable: true,
            type: "text",
            width: "130px",
        },
        {
            field: "mobilePhone",
            label: "Mobile",
            visible: true,
            searchable: true,
            type: "text",
            width: "130px",
        },
    ];

    React.useEffect(() => {
        if (!context?.loggedOnUser?.username)
            return;
        let s: UserApi = factory.create(UserApi);
        setLoading(true);
        s.userGet(
            context?.loggedOnUser?.username,
            searchParams?.searchString,
            searchParams?.oDataQuery,
            paging.top,
            paging.skip,
            pageSize,
            paging.sort.field + " " + paging.sort.dir
        )
            .then(async (data) => {
                var d1 = await data.json();
                setDataCount(d1["@odata.count"]);
                setData(d1.value);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [context?.loggedOnUser?.username]);

    React.useEffect(() => {
        if (!context?.loggedOnUser?.username)
            return;
        let s: UserApi = factory.create(UserApi);
        setLoading(true);
        s.userGet(
            context?.loggedOnUser?.username,
            searchParams?.searchString,
            searchParams?.oDataQuery,
            paging.top,
            paging.skip,
            pageSize,
            paging.sort.field + " " + paging.sort.dir
        )
            .then(async (data) => {
                var d1 = await data.json();
                setDataCount(d1["@odata.count"]);
                setData(d1.value);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [paging, searchParams, time, context?.loggedOnUser?.username]);

    let searchFields: XtAdvancedSearchFieldCondition[] = [];

    const getAdvancedSearchSelectedValue = (field) => {
        let returnValue = null;
        searchParams.advancedSearchCondition?.map((v, i) => {
            if (field.field === v.field.field) {
                returnValue = v;
            }
        });
        return returnValue;
    };

    schema.map((v, i) => {
        let c = getAdvancedSearchSelectedValue(v);
        if (v.type === "text" && v.searchable === true) {
            searchFields.push(
                Search.getTextboxType(
                    "country",
                    v.field,
                    v.label,
                    c?.value,
                    c?.operator.value,
                    c?.value2
                )
            );
        }
    });

    const onSearchCondition = (e) => {
        setSearchParam(e);
    };

    const onExportButtonClick = (e) => {
        let s: UserApi = factory.create(UserApi);
        if (gridSelection.headerSelected) {
            setLoading(true);
            s.userGet(
                context?.loggedOnUser?.username,
                searchParams?.searchString,
                searchParams?.oDataQuery,
                undefined,
                undefined,
                undefined,
                paging.sort.field + " " + paging.sort.dir
            )
                .then(async (data) => {
                    var d1 = await data.json();
                    if (gridSelection.differedData.length === 0) setReportData(d1.value);
                    else {
                        let data: any[] = d1.value;
                        let unSelected: any[] = [];
                        let printData: any[] = [];

                        gridSelection.differedData.map((v: User, i) => {
                            unSelected.push(v.userGuid);
                        });

                        data.map((v: User, i) => {
                            if (unSelected.indexOf(v.userGuid) === -1) {
                                printData.push(v);
                            }
                        });
                        setReportData(printData);
                    }
                    setExportType({
                        type: e.type,
                        fields: e.dataItems,
                        filename: "test." + (e.type === "pdf" ? "pdf" : "xlsx"),
                    });
                    setIsExported(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setReportData(gridSelection.differedData);
            setExportType({
                type: e.type,
                fields: e.dataItems,
                filename: "test." + (e.type === "pdf" ? "pdf" : "xlsx"),
            });
            setIsExported(false);
        }
    };

    const renderGridFields = () => {
        let items: any[] = [];
        schema.map((v, i) => {
            if (v.visible) {
                items.push(
                    <GridColumn
                        sortable={true}
                        key={"row" + i}
                        field={v.field}
                        title={v.label}
                        width={v.width}
                    />
                );
            }
        });
        return items;
    };

    const onPageChange = (e) => {
        setPaging({ ...paging, skip: e.page.skip, top: e.page.take });
    };

    const onSortChange = (e) => {
        if (e.sort.length > 0) setPaging({ ...paging, sort: e.sort[0] });
        else {
            setPaging({
                ...paging,
                sort: {
                    ...paging.sort,
                    dir: paging.sort.dir === "asc" ? "desc" : "asc",
                },
            });
        }
    };

    const onExportAfterRender = (e) => {
        if (e && !isExported) {
            e.save();
            setIsExported(true);
        }
    };

    const onEditClick = (e) => {
        setEditingGroup(e.dataItem);
        setDialogTitle("Edit Group");
        setShowDialog(true);
    };

    const onDeleteClick = (e) => {
        setEditingGroup(e.dataItem);
        setShowDeleteConfirmation(true);
    };

    const onGridSelectionChange = (e) => {
        setGridSelection(e);
    };

    const onPermissionClick = (e) => {
        setEditingGroup(e.dataItem);
        setShowGroupPermission(true);
    };

    const onAssignmentClick = (e) => {
        setEditingGroup(e.dataItem);
        setShowGroupAssignment(true);
    };

    const onCancelGroupAssignment = (e) => {
        setShowGroupAssignment(false);
    };

    const rowMenus = [
        {
            text: "Assign Group",
            icon: "k-icon k-i-module-manager",
            onClick: onAssignmentClick,
        },
    ];

    return (
        <div>
            <APIFactory
                ref={(e) => {
                    factory = e;
                }}
            />
            <div className="my-3">
                <XtAdvancedSearchBox
                    searchPlaceHolder="Search"
                    hideAdvancedSearch={false}
                    searchConditions={searchFields}
                    onSearchConditionApply={onSearchCondition}
                />
            </div>
            <XtExport
                type={expType.type}
                data={reportData}
                schema={expType.fields}
                fileName={expType.filename}
                onAfterRender={onExportAfterRender}
            >
                <XtGrid
                    uniqueField="userGuid"
                    data={data}
                    total={dataCount}
                    sort={[paging.sort]}
                    rowMenus={rowMenus}
                    sortable={true}
                    skip={paging.skip}
                    pageSize={pageSize}
                    pageable={true}
                    onSortChange={onSortChange}
                    onPageChange={onPageChange}
                    onGridSelectionChange={onGridSelectionChange}
                    className="settings-users_list"
                >
                    {renderGridFields()}
                </XtGrid>
            </XtExport>
            {loading && <Loading />}
            {showGroupAssignment && (
                <XtUserGroupView
                    onClose={onCancelGroupAssignment}
                    title="Assign User to Groups"
                    dataItem={editingGroup}
                />
            )}
        </div>
    );
}
