import React, { useState, useEffect, useContext } from "react";
import { Row } from "react-bootstrap";
import FormField, {
  RequiredFormField,
} from "../PurchaseRequisition/Common/FormField";
import { XtField } from "nwcommon";

import { APIFactory, VendorApi } from "../../../../Xt/ApiClients";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import { NwVendorAddressDetails } from "../PurchaseRequisition/Vendor/NwVendorAddressDetails";
import { XtAccordianSeperator } from "../PurchaseRequisition/Common/XtAccordianItem";

const defaultAddress = [
  { text: "Default", value: "D" },
  { text: "Yes", value: "Y" },
  { text: "No", value: "N" },
];

interface CpIntegrationVendorDetailsProps {
  vendorCode: string;
  onOrderAddressChagne: any;
  onPaymentAddressChagne: any;
  onVendorIdChange: any;
  onCountryChange: any;
  selectedPaymentAddress: string;
  selectedOrderAddress: string;
  selectedCountry: string;
  companyCode: string;
  vendorAddressGuid?: string;
  addressContactGuid?: string;
  requisitionType: string;
}

export default function CpIntegrationVendorDetails(
  props: CpIntegrationVendorDetailsProps
) {
  let factory: APIFactory;
  const [vendor, setVendor] = useState(null);
  const [vendorLoading, setVendorLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorAddressGuid, setVendorAddressGuild] = useState(null);
  const [addressContactGuid, setaddressContactGuid] = useState(null);
  const [isNewVendor, setIsNewVendor] = useState(false);

  const context: IXtContext = useContext(XtContext);

  const init = true;

  const detectWhetherNewVendor = (vParam) => {
    if (vParam) {
      if (vParam?.isIntegrated) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const loadVendors = () => {
    let api: VendorApi = factory.create(VendorApi);
    if (props?.companyCode !== undefined && props?.requisitionType) {
      setVendorLoading(true);
      api
        .vendorVendorListGet(
          context?.loggedOnUser?.username,
          props?.companyCode,
          props?.requisitionType === "Service" ? "service" : "material"
        )
        .then(async (vendorData) => {
          var vendorDataFromApi = await vendorData.json();
          const vendorList = vendorDataFromApi.value.map((v) => {
            const textVal = v.isIntegrated
              ? v.vendorName + " (" + v.vendorCode + ")"
              : v.vendorName;
            return {
              text: textVal,
              value: v.vendorCode,
            };
          });
          setVendorLoading(false);
          setVendors([
            { text: "Request New Vendor", value: "" },
            ...vendorList,
          ]);
        });
    }
  };
  useEffect(() => {
    loadVendors();
  }, [props?.companyCode, props?.requisitionType]);

  const loadVendorDetails = (vendorCode, loggedOnUserName) => {
    if (vendorCode && loggedOnUserName) {
      let api: VendorApi = factory.create(VendorApi);
      api
        .vendorDetailGet(loggedOnUserName, vendorCode, props?.companyCode)
        .then(async (vendorData) => {
          const vendDetailsFromApi: any = vendorData;
          var isVendorNewBasedOnDetails = detectWhetherNewVendor(vendorData);
          setIsNewVendor(isVendorNewBasedOnDetails);
          setVendor(vendDetailsFromApi);
          if (vendDetailsFromApi.isAutoGeneratedVendorCode) {
            setSelectedVendor({
              text: vendDetailsFromApi.vendorName,
              value: props?.vendorCode,
            });
          } else {
            setSelectedVendor({
              text: vendDetailsFromApi.vendorName,
              value: vendDetailsFromApi.vendorID,
            });
          }
          props.onVendorIdChange(vendDetailsFromApi.vendorID);
          const selectedAddress =
            vendDetailsFromApi?.vendorAddressList &&
            vendDetailsFromApi.vendorAddressList.length > 0
              ? vendDetailsFromApi.vendorAddressList.find(
                  (v) => v.vendorAddressGuid === vendorAddressGuid
                )
              : null;
          props.onOrderAddressChagne(selectedAddress?.orderAddressCode);
          props.onPaymentAddressChagne(selectedAddress?.pmtAddressCode);
          props.onCountryChange(selectedAddress?.countryCode);
        });
    }
  };

  useEffect(() => {
    loadVendorDetails(props?.vendorCode, context?.loggedOnUser?.username);
  }, [props?.vendorCode, context?.loggedOnUser?.username]);

  useEffect(() => {
    if (!selectedVendor || selectedVendor?.value === "") setVendor(null);
    else
      loadVendorDetails(selectedVendor?.value, context?.loggedOnUser?.username);
  }, [selectedVendor?.value, context?.loggedOnUser?.username]);

  useEffect(() => {
    if (props?.addressContactGuid)
      setaddressContactGuid(props?.addressContactGuid);
  }, [props?.addressContactGuid]);

  useEffect(() => {
    if (props?.vendorAddressGuid)
      setVendorAddressGuild(props?.vendorAddressGuid);
  }, [props?.vendorAddressGuid]);

  const selectedVendorAddress =
    vendor?.vendorAddressList && vendor.vendorAddressList.length > 0
      ? vendor.vendorAddressList.find(
          (v) => v.vendorAddressGuid === props?.vendorAddressGuid
        )
      : null;

  const onVendorChagne = (e) => {
    if (e.value.value == "") setIsNewVendor(true);
    setSelectedVendor(e.value);
    setaddressContactGuid(undefined);
    setVendorAddressGuild(undefined);
  };

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />

      {isNewVendor && (
        <Row>
          <p className="alert alert-warning">
            {" "}
            Selected Vendor is not in Costpoint yet. Enter correctly formatted
            Vendor Id, Address Code and other details.
          </p>
        </Row>
      )}
      <Row>
        <FormField label="Vendor">
          <XtField
            type="dropdown"
            name="vendorCode"
            items={vendors}
            value={
              vendors.find((v) => v.value === selectedVendor?.value) || null
            }
            onChange={(e) => onVendorChagne(e)}
            textField="text"
            required={true}
            errormessage="Vendor is required"
            loading={vendorLoading}
            disabled={false}
          />
        </FormField>

        <RequiredFormField label="Vendor Id">
          <XtField
            type="text"
            name="vendorID"
            defaultValue={isNewVendor ? "" : vendor?.vendorID}
            key={vendor?.vendorID}
            required={true}
            errormessage="Vendor id is required"
            disabled={!isNewVendor}
          />
        </RequiredFormField>
      </Row>

      <NwVendorAddressDetails
        data={vendor}
        vendorCode={selectedVendor?.value}
        vendorAddressGuid={props?.vendorAddressGuid}
        addressContactGuid={props?.addressContactGuid}
        isFromIntegrationPage={true}
        isNewVendor={isNewVendor}
      />

      <XtAccordianSeperator />
      <Row>
        <FormField label="EIN">
          <XtField
            type="numeric"
            name="dunsNumber"
            defaultValue={vendor?.dunsNumber}
            key={vendor?.dunsNumber}
          />
        </FormField>

        <FormField label="Tax ID" labelNotes="(IRS Tax Number without hyphens)">
          <XtField
            type="text"
            name="taxID"
            defaultValue={vendor?.taxID}
            key={vendor?.taxID}
          />
        </FormField>

        <FormField label="CAGE Code">
          <XtField
            type="text"
            defaultValue={vendor?.cageCD}
            key={vendor?.cageCD}
            name="cageCD"
          />
        </FormField>

        <FormField label="1099 Name">
          <XtField
            type="text"
            name="vendor1099Name"
            defaultValue={
              vendor?.vendor1099Name
                ? vendor?.vendor1099Name
                : "Miscellaneous Income - IRS form"
            }
            key={
              vendor?.vendor1099Name
                ? vendor?.vendor1099Name
                : "Miscellaneous Income - IRS form"
            }
          />
        </FormField>
      </Row>

      <Row>
        <RequiredFormField label="Payment Address">
          <XtField
            type="dropdown"
            name="paymentaddress"
            value={defaultAddress.find(
              (d) => d.value === props.selectedPaymentAddress
            )}
            items={defaultAddress}
            onChange={(e) => props.onPaymentAddressChagne(e.value.value)}
            textField="text"
            required={true}
            errormessage="Payment address is required"
          />
        </RequiredFormField>
        <RequiredFormField label="Order Address">
          <XtField
            type="dropdown"
            name="orderAddress"
            value={defaultAddress.find(
              (d) => d.value === props.selectedOrderAddress
            )}
            items={defaultAddress}
            onChange={(e) => props.onOrderAddressChagne(e.value.value)}
            textField="text"
            required={true}
            errormessage="Order address is required"
          />
        </RequiredFormField>
      </Row>
    </>
  );
}
