import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function PopOverIcon(props) {
  return (
    <OverlayTrigger
      key="right"
      placement= {props?.placement??"right"}
      overlay={
        <Popover id="popover-basic">
          <Popover.Header as="h3">{props.header}</Popover.Header>
          <Popover.Body>{props.children}</Popover.Body>
        </Popover>
      }
    >
      <span>
        <i className="material-icons text-primary">info</i>
      </span>
    </OverlayTrigger>
  );
}
