import React from "react";
import { Container } from "react-bootstrap";
import { enum_pr_status } from "../NwPrConstants";
import { Redirect, Link } from "react-router-dom";
import { StringUtil } from "../../../../../../Xt/Utils/StringUtil";

export default function PrrStatusGridCell(props) {
  const status: string = props.dataItem["status"];
  

  let personnelRequisitionGuid :string = props.dataItem["personnelRequisitionGuid"];
  if(!personnelRequisitionGuid) 
  personnelRequisitionGuid =props.dataItem["resourceGuid"]


  const prStatusClassName = `badge  pr-status-${status
    .replace(" ", "-")
    .toLowerCase()}`;
//TODO: Need to change the link to the new one for PRR
// and create a new api in the backend to get the PRR details
  return (
    <td>
      {status.toLocaleLowerCase()=="waiting for cost point integration" && 
        <Link to={"/cp/landingpage/"+personnelRequisitionGuid} >
          <span className={prStatusClassName} role={"gridcell"}>
            {status === null ? "" : StringUtil.replaceCostPoint(status)}
          </span>
          </Link>


      }
      {status.toLocaleLowerCase()!="waiting for cost point integration" && 
        
          <span className={prStatusClassName} role={"gridcell"}>
            {status === null ? "" : StringUtil.replaceCostPoint(status)}
          </span>
        
      }

    </td>
  );
}




