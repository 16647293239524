import React from "react";
import { NumberUtil } from "../../../../../../Xt/Utils/NumberUtil";

export default function RightAlignGridCell(props) {
  return (
    <td style={{ textAlign: "right" }}>
      {props.dataItem[props.field]}
    </td>
  );
}
