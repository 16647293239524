import { Button } from "react-bootstrap";

interface ISaveButtonProps {
  loading: boolean;
  onSubmit: any;
}
export default function SubmitButton(props: ISaveButtonProps) {
  const { loading } = props;

  return (
    <Button type="submit" variant="primary" id="submit" disabled={loading}>
      Submit
    </Button>
  );
}
