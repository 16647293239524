import React from 'react';
import * as Search from "nwcommon"
import { XtAdvancedSearchFieldCondition, XtAdvancedSearchBox, XtExport, XtExportType, XtExportToolbar } from "nwcommon"
import { XtContext, IXtContext } from '../../Xt/Contexts/XtContext';

import {
	Grid,
	GridColumn,
	GridPageChangeEvent,
	GridToolbar,
} from "@progress/kendo-react-grid";

import { XtGrid, XtGridRowSelection } from 'nwcommon'
import { Loading } from '../../Xt/Layout/Loading';
import { XtAddEditCountryView } from './Dialogs/Country/XtAddEditCountryView';
import { ApiHelperGet, XtPaging, XtSort } from '../../Xt/ApiClients/ApiHelper';
import { APIFactory, Country, UploadApi } from '../../Xt/ApiClients';
declare var window: any;

interface XtExportFileType {
	type: XtExportType;
	fields: any[];
	filename: string;
}

export type XtUploadProps = React.PropsWithChildren<{
	path: string
}>;

export function XtUpload(props: XtUploadProps) {
	const pageSize = 15;
	const orderByField = "name"
	const init = true;
	const [expType, setExportType] = React.useState<XtExportFileType>({ type: 'none', fields: [], filename: '' });
	const [data, setData] = React.useState<any[]>([]);
	const [reportData, setReportData] = React.useState<any[]>([]);
	const [dataCount, setDataCount] = React.useState<number>(0);
	const [isExported, setIsExported] = React.useState<boolean>(false);
	const [paging, setPaging] = React.useState<XtPaging>({ skip: 0, top: pageSize, sort: { field: orderByField, dir: 'asc' } });
	const [searchParams, setSearchParam] = React.useState<any>({});
	const [gridSelection, setGridSelection] = React.useState<XtGridRowSelection>({ headerSelected: false, differedData: [] });
	const [loading, setLoading] = React.useState<boolean>(false)
	const [showDialog, setShowDialog] = React.useState<boolean>(false)
	const [path, setPath] = React.useState<string>(props.path)

	let factory;
	const context: IXtContext = React.useContext(XtContext);

	const schema = [
		{ field: "name", label: "Filename", visible: true, searchable: true, type: 'text' },
		{ field: "size", label: "Size", visible: true, searchable: true, type: 'text' },
		{ field: "type", label: "Mime Type", visible: true, searchable: true, type: 'text' }
	]

	React.useEffect(() => {
		if (!context?.loggedOnUser?.username)
			return;
		let s: UploadApi = factory.create(UploadApi);
		setLoading(true)
		s.uploadPathGet(path, context?.loggedOnUser?.username, searchParams?.oDataQuery, paging.top, paging.skip, pageSize, paging.sort.field + " " + paging.sort.dir).then(async (data) => {
			var d1 = await data.json();
			setDataCount(d1['@odata.count'])
			setData(d1.value)
		}).finally(() => {
			setLoading(false)
		})
	}, [context?.loggedOnUser?.username])

	React.useEffect(() => {
		let s: UploadApi = factory.create(UploadApi);
		setLoading(true)
		
		s.uploadPathGet(path, context?.loggedOnUser?.username, searchParams?.oDataQuery, paging.top, paging.skip, pageSize, paging.sort.field + " " + paging.sort.dir).then(async (data) => {
			var d1 = await data.json();
			setDataCount(d1['@odata.count'])
			setData(d1.value)
		}).finally(() => {
			setLoading(false)
		})

	}, [path, showDialog])


	let searchFields: XtAdvancedSearchFieldCondition[] = []

	const getAdvancedSearchSelectedValue = (field) => {
		let returnValue = null;
		searchParams.advancedSearchCondition?.map((v, i) => {
			if (field.field === v.field.field) {
				returnValue = v;
			}
		})
		return returnValue;
	}

	schema.map((v, i) => {
		let c = getAdvancedSearchSelectedValue(v);
		if (v.type === 'text' && v.searchable === true) {
			searchFields.push(Search.getTextboxType('country', v.field, v.label, c?.value, c?.operator.value, c?.value2))
		}
		else if (v.type === 'number' && v.searchable === true)
			searchFields.push(Search.getNumericType('country', v.field, v.label, c?.value, c?.operator.value, c?.value2))
		else if (v.type === 'boolean' && v.searchable === true)
			searchFields.push(Search.getBooleanType('country', v.field, v.label, [{ text: "Active", value: "true" }, { text: "Inactive", value: "false" }], c?.value, c?.operator.value))
	})

	const onSearchCondition = (e) => {
		setSearchParam(e)
	}

	const onExportButtonClick = (e) => {
		let s: UploadApi = factory.create(UploadApi);

		if (gridSelection.headerSelected) {
			setLoading(true)
			s.uploadPathGet(path, context?.loggedOnUser?.username, searchParams?.oDataQuery, undefined, undefined, undefined, paging.sort.field + " " + paging.sort.dir).then(async (data) => {
				var d1 = await data.json();

				if (gridSelection.differedData.length === 0)
					setReportData(d1.value)
				else {
					let data: any[] = d1.value;
					let unSelected: any[] = [];
					let printData: any[] = [];

					gridSelection.differedData.map((v: Country, i) => {
						unSelected.push(v.countryId)
					});

					data.map((v: Country, i) => {
						if (unSelected.indexOf(v.countryId) === -1) {
							printData.push(v);
						}
					})
					setReportData(printData)
				}
				setExportType({
					type: e.type,
					fields: e.dataItems,
					filename: "test." + (e.type === "pdf" ? "pdf" : "xlsx")
				});
				setIsExported(false)
			}).finally(() => {
				setLoading(false)
			})
		}
		else {
			setReportData(gridSelection.differedData)
			setExportType({
				type: e.type,
				fields: e.dataItems,
				filename: "test." + (e.type === "pdf" ? "pdf" : "xlsx")
			});
			setIsExported(false)
		}

	}

	const downloadURI = (uri, name) => {
		let link = document.createElement("a");
		link.download = name;
		link.href = uri;
		link.click();
	}

	const downloadFile = (e) => {
		setLoading(true)
		let s: UploadApi = factory.create(UploadApi);
		let file = `${path}/${decodeURI(e.name)}`;

		s.uploadFilenameGet(file, context?.loggedOnUser?.username).then(async (result) => {
			var r = await result.json()
			downloadURI(r.data, r.name);
			window.URL.revokeObjectURL(r.data);
		}).finally(() => {
			setLoading(false)
		})
		
    }

	const renderGridFields = () => {
		let items: any[] = [];
		schema.map((v, i) => {
			if (v.visible) {
				if (i === 0) {
					items.push(<GridColumn sortable={true} key={"row" + i} field={v.field} title={v.label} cell={(e) => {
						return (<td><a href="#" onClick={(e1) => { e1.preventDefault(); downloadFile(e.dataItem) }}>{e.dataItem.name}</a></td>)
					}
					}/>)
				}
				else {
					items.push(<GridColumn sortable={true} key={"row" + i} field={v.field} title={v.label} />)
                }
			}
		})
		return items;
	}


	const onPageChange = (e) => {
		setPaging({ ...paging, skip: e.page.skip, top: e.page.take })
	}

	const onSortChange = (e) => {
		if (e.sort.length > 0)
			setPaging({ ...paging, sort: e.sort[0] })
		else {
			setPaging({ ...paging, sort: { ...paging.sort, dir: (paging.sort.dir === "asc") ? "desc" : "asc" } })
		}
	}

	const onExportAfterRender = (e) => {

		if (e && !isExported) {
			e.save()
			setIsExported(true)
		}
	}

	const onMenuClick = (e) => {

	}

	const onGridSelectionChange = (e) => {
		setGridSelection(e)
	}

	const rowMenus = [{ text: 'Edit', icon: 'k-icon k-i-menu', onClick: onMenuClick }]

	return (<div>Home Page
		<APIFactory ref={(e) => { factory = e; }} />
		<XtAdvancedSearchBox searchPlaceHolder="Search" hideAdvancedSearch={false} searchConditions={searchFields} onSearchConditionApply={onSearchCondition} />
		<GridToolbar>
			<XtExportToolbar fields={searchFields} onApply={onExportButtonClick} gridSelection={gridSelection} />
			<button className="btn btn-secondary" onClick={(e) => { setShowDialog(true) }}>Add Country</button>
		</GridToolbar>
		<XtExport type={expType.type} data={reportData} schema={expType.fields} fileName={expType.filename} onAfterRender={onExportAfterRender} >
			<XtGrid uniqueField="countryId" data={data} total={dataCount} sort={[paging.sort]} rowMenus={rowMenus} sortable={true}
				skip={paging.skip} pageSize={pageSize} pageable={true} onSortChange={onSortChange} onPageChange={onPageChange} onGridSelectionChange={onGridSelectionChange}>
				{renderGridFields()}
			</XtGrid>
		</XtExport>
		{showDialog && <XtAddEditCountryView title="Upload File" onClose={(e) => { setShowDialog(false) }} />}
		{loading && <Loading />}
	</div>)

}