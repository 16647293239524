import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function FormLabelMutedText(props) {
  return (
    <Form.Label className="text-muted px-sm-1 w-100" column>
      {props.value}
    </Form.Label>
  );
}

export function FormLabelMutedFullText(props) {
  return (
    <Form.Label className="text-muted px-sm-3 w-100" column>
      {props.value}
    </Form.Label>
  );
}

export function FormLabelMutedAmount(props) {
  return (
    <span className="text-muted text-truncate p-2 d-block">{props.value}</span>
  );
}

export interface FormPoLinkProps {
  poNumber: string;
}
export function FormPoLink(props: FormPoLinkProps) {
  return (
    <span className="px-sm-1 w-100">
      <Link to={`/po/details/${props.poNumber}`}> {props.poNumber}</Link>
    </span>
  );
}
