import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { XtField, XtGrid } from "nwcommon";
import { AdvSearchAndGridUtil } from "../../../../../Xt/Utils/AdvanceSearchAndGridUtil";
import NwSubTotalReadOnly from "./SubTotalReadOnly";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DateUtil } from "../../../../../Xt/Utils/DateUtil";
import { ResourceAttributeApi as NWResourceAttributeApi } from "../../../../../Xt/ApiClients/SwaggerCLI";
import { PrApiService } from "../Common/PrApiService";
import { APIFactory } from "../../../../../Xt/ApiClients";
import RightAlignGridCell from "../Common/GridCell/RightAlignGridCell";
import RightAlignGridColumnHeader from "../Common/GridCell/RightAlignGridColumnHeader";
import AmountGridCell, { AmountGridCellBold, AmountGridCellWithOldValue,  AmountGridCellWithOldValueBold, NumberGridCellWithComma, NumberGridCellWithCommaWithOldValue } from "../Common/GridCell/AmountGridCell";
import { XtContext } from "../../../../../index-Lib";
import { IXtContext } from "../../../../../Xt/Contexts/XtContext";
import { enum_request_type } from "../Common/NwPrConstants";
import PopOverGridCell from "../Common/GridCell/PopOverGridCell";

const  itemIdsdata= [{text:"Item1",value:"i1"},{text:"Item2",value:"i2"}];

export default function NwPurchaseItemReadOnly(props) {

   // console.log(props?.pr?.purchaseItem)
    const [lineItems, setLineItems] = useState([]);
    let factory;
    const init = true;

    const context: IXtContext = useContext(XtContext);

    useEffect(() => {
        if (context?.loggedOnUser?.username) {
            let api: NWResourceAttributeApi = factory.create(NWResourceAttributeApi);

            PrApiService.getResourceAttributeList(context, api, "PR", "item").then((data) => {
                var list = data.map((a) => {
                    return { value: a.value, text: a.value + ' - ' + a.text };
                });
                setLineItems(list);
            });
        }

    }, [context?.loggedOnUser?.username])

    const onItemIdChange = (e,dataItem) => {
        if(props?.onItemIdChange){
            props?.onItemIdChange(e.value.value, dataItem.lineItemNumber)
        }
        
    }
    return (
        <>
            <APIFactory
                ref={(e) => {
                    factory = e;
                }}
            />
            <Col sm={12}>
                {/*<Grid data={props?.pr?.purchaseItem}>*/}
                {/*  {AdvSearchAndGridUtil.renderGridFields(purchaseItemSchema)}*/}
                {/*</Grid>*/}
                <>
                    <Grid
                        data={props?.pr?.purchaseItem?.sort( (l1,l2)=> l1.lineNumber-l2.lineNumber) }
                        total={0}
                        className="k-grid-pr-list"
                    >
                        <GridColumn title="#" field="lineNumber" width="35px" />
                        <GridColumn title="Charge Code" field="chargeCode" cell={(v) => {
                            var value = v.dataItem[v.field] + " - "+ v.dataItem?.chargeCodeName;
                            return (<td>{value}</td>);
                        }} />
                        <GridColumn title="Type" field="type" width="100px" cell={(v) => {
                            var value = v.dataItem[v.field] + " - " + v.dataItem?.typeName ;
                            return (<td>{value}</td>);
                        }} />
                        <GridColumn title="Account No." field="accountNumber" cell={(v) => {
                            var value = v.dataItem[v.field] + " - " + v.dataItem?.accountName ;
                            return (<td>{value}</td>);
                        }} />
                        
                        {props.IsLandingpage && <GridColumn title="Item ID." width="200px" field="itemId" cell={(v) => {
                            
                            return (<td> <XtField
                                type="dropdown"
                                name={"itemid"+v?.dataItem?.lineItemNumber}
                                items={lineItems}
                                value={lineItems.find(p=> p.value ===v?.dataItem?.itemId)}
                                textField="text"
                                onChange={ (e)=> onItemIdChange(e,v?.dataItem) }
                              /></td>)
                        }}

                         />
                        }

                        <GridColumn title="Description" field="description"  width="200px"  cell={PopOverGridCell}/>
                        
                        <GridColumn title="Quantity" field="quantity" 
                            cell={props?.pr?.prType === enum_request_type.PO_CHANGE_REQUEST ? NumberGridCellWithCommaWithOldValue : NumberGridCellWithComma}
                             headerCell={RightAlignGridColumnHeader} />
                        
                        <GridColumn title="Unit" field="unit" cell={(v) => {
                            var value = v.dataItem[v.field] + " - " + v.dataItem?.unitName;
                            return (<td>{value}</td>);
                        }} />
                        
                        <GridColumn title="Unit Price" field="unitPrice" 
                                cell={props?.pr?.prType === enum_request_type.PO_CHANGE_REQUEST? AmountGridCellWithOldValue:AmountGridCell }
                         headerCell={RightAlignGridColumnHeader}  />
                        

                        {props?.pr?.requisitionType ==="Service" && 
                        <GridColumn title="Start Date" field="startDate" cell={(v) => {
                            if (props?.pr?.requisitionType !== "Service")
                                return (<td>N/A</td>);
                            if (v === undefined)
                                return (<td></td>);
                            
                            var value = DateUtil.formatmmDdYYY(v.dataItem[v.field]);
                            return (<td>{value}</td>);
                        }} />}
                        {props?.pr?.requisitionType ==="Service" && 
                        <GridColumn title="End Date" field="endDate" cell={(v) => {
                            if (props?.pr?.requisitionType !== "Service")
                                return (<td>N/A</td>);
                            if (v === undefined)
                                return (<td></td>);
                            var value = DateUtil.formatmmDdYYY(v.dataItem[v.field]);
                            return (<td>{value}</td>);
                        }} />}
                        
                        <GridColumn title="Total" field="total" cell={props?.pr?.prType === enum_request_type.PO_CHANGE_REQUEST? AmountGridCellWithOldValueBold:AmountGridCellBold } headerCell={RightAlignGridColumnHeader}  />
                        
                    </Grid>
                </>
                <NwSubTotalReadOnly pr={props?.pr} />
            </Col>
        </>
    );
}
