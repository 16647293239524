import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import * as Search from "nwcommon";
import {
  XtAdvancedSearchFieldCondition,
  XtAdvancedSearchChild,
} from "nwcommon";
import { XtAdvancedSearchBox } from "nwcommon";
import { XtExport } from "nwcommon";
import { XtExportToolbar } from "nwcommon";
import { XtContext, IXtContext } from "../../../../../Xt/Contexts/XtContext";
import { GridToolbar } from "@progress/kendo-react-grid";
import { XtGrid } from "nwcommon";
import { Loading } from "../../../../../Xt/Layout/Loading";
import {
  APIFactory,
  PurchaseRequisition,
  PurchaseRequisitionApi,
  ResourceAttributeApi as PrResourceAttributeApi,
  AdvancedSearchApi,
  VendorApi,
} from "../../../../../Xt/ApiClients";
import {
  APIFactoryCommon,
  CompanyApi,
  ProjectApi,
} from "../../../../../Xt/ApiClients/Common";
import { NwPrConstants } from "../Common/NwPrConstants";
import { AdvSearchAndGridUtil } from "../../../../../Xt/Utils/AdvanceSearchAndGridUtil";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { Button } from "react-bootstrap";
import AmountGridCell from "../../PurchaseRequisition/Common/GridCell/AmountGridCell";
import DateGridCell from "../../PurchaseRequisition/Common/GridCell/DateGridCell";
import EditMenuGridCell from "../../PurchaseRequisition/Common/GridCell/EditMenuGridCell";
import NwPrStatusGridCell from "../../PurchaseRequisition/Common/GridCell/NwPrStatusGridCell";
import PoLinkGridCell from "../../PurchaseRequisition/Common/GridCell/PoLinkGridCell";
import RightAlignGridColumnHeader from "../../PurchaseRequisition/Common/GridCell/RightAlignGridColumnHeader";
import { PaginationContext } from "../../../../../Xt/Contexts/PaginationContext";
export function NwPurchaseRequisitionList(props) {
  const orderByField = "createdOn";
  const entity = "purchaseRequisition";
  const pageSize = 15;
  let factory;
  let commonfactory;
  const myActionFilter = [
    { value: "MyActivePR", text: "My Active PRs" },
    { value: "MyPR", text: "My PRs" },
    { value: "MyDraft", text: "Drafts" },
    { value: "AllPR", text: "All PRs" },
  ];
  const redirectTo = { shouldRedirect: false, url: "" };
  const [redirect, setRedirect] = useState(redirectTo);
  const [searchFields, setSearchFields] = useState<
    XtAdvancedSearchFieldCondition[]
  >([]);
  const [searchParams, setSearchParam] = React.useState<any>({});
  const [schema, setSchema] = useState<any[]>([]);
  const [time, setTime] = useState(0);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [selectedActionFilter, setSelectedActionFilter] = React.useState<any>(
    sessionStorage.getItem("PRPageShortcutHistory")
      ? JSON.parse(sessionStorage.getItem("PRPageShortcutHistory"))
      : myActionFilter[0]
  );
  const xts = AdvSearchAndGridUtil.useXtGridSate(pageSize, orderByField);
  const [isAdvancedSearchLoaded, setIsAdvancedSearchLoaded] =
    React.useState<boolean>(false);
  const context: IXtContext = React.useContext(XtContext);
  const { paginationState, updatePagination } =
    React.useContext(PaginationContext);
  const paginationEntity = "pr";
  const filteredShortcuts = context.isSuperAdmin()
    ? NwPrConstants.list_shortcuts.data
    : NwPrConstants.list_shortcuts.data.filter((l) => l.key != "AllPR");

  const requisitionOption = [
    { text: "Material/ODCs", value: "Material" },
    { text: "Service", value: "Service" },
  ];

  const chargeOption = [
    { text: "Direct", value: "Direct" },
    { text: "Indirect", value: "Indirect" },
  ];

  const leaseOption = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];

  const prTypeOption = [
    { text: "PO Change Request", value: "PO Change Request" },
    { text: "New Purchase Request", value: "New Purchase Request" },
  ];

  const competedOption = [
    { text: "Yes", value: "true" },
    { text: "No", value: "false" },
  ];

  const statusOption = [
    { text: "PO Created", value: "PO Created" },
    { text: "PO Updated", value: "PO Updated" },
  ];

  const schema1 = [
    {
      field: "isEditable",
      label: "  ",
      visible: false,
      searchable: false,
      type: "text",
      cell: EditMenuGridCell,
      width: "150px",
    },
    {
      field: "prNumber",
      label: "PR No.",
      visible: true,
      searchable: true,
      type: "text",
      width: "150px",
    },
    {
      field: "purchaseRequisitionGuid",
      label: "Purchase Requistion Guid",
      visible: false,
      searchable: false,
      type: "text",
    },
    {
      field: "createdOn",
      label: "Date Created",
      visible: true,
      searchable: true,
      type: "datepicker",
      format: "{0:dt}",
      cell: DateGridCell,
    },
    {
      field: "employeeName",
      label: "Created By",
      visible: true,
      searchable: true,
      type: "multiselect",
      options: [],
    },
    {
      field: "projectNumber",
      label: "Project Number",
      visible: true,
      searchable: true,
      type: "text",
    },
    {
      field: "prType",
      label: "PR Type",
      visible: true,
      searchable: true,
      type: "dropdown",
      options: prTypeOption,
    },
    {
      field: "poNumber",
      label: "PO #",
      visible: true,
      searchable: true,
      type: "text",
      cell: PoLinkGridCell,
    },
    {
      field: "companyName",
      label: "Company Name",
      visible: true,
      searchable: false,
      type: "text",
    },
    {
      field: "vendorCode",
      label: "Vendor Name",
      visible: false,
      searchable: true,
      type: "dropdown",
      options: [],
    },
    {
      field: "vendor",
      label: "Vendor Name",
      visible: true,
      searchable: false,
      type: "dropdown",
      options: [],
    },
    {
      field: "lineItemAmount",
      label: "Line Item Amount",
      visible: false,
      searchable: true,
      type: "number",
      format: "{0:c2}",
      headerCell: RightAlignGridColumnHeader,
      cell: AmountGridCell,
    },
    {
      field: "totalAmount",
      label: "PR Total Amount",
      visible: true,
      searchable: true,
      type: "number",
      format: "{0:c2}",
      headerCell: RightAlignGridColumnHeader,
      cell: AmountGridCell,
    },
    {
      field: "status",
      label: "Status",
      visible: true,
      searchable: true,
      type: "dropdown",
      cell: NwPrStatusGridCell,
      options: statusOption,
    },
    {
      field: "chargeType",
      label: "Charge Type",
      visible: false,
      searchable: true,
      type: "dropdown",
      options: chargeOption,
    },
    {
      field: "requisitionType",
      label: "Requisition Type",
      visible: false,
      searchable: true,
      type: "dropdown",
      options: requisitionOption,
    },
    {
      field: "leaseType",
      label: "Lease Type",
      visible: false,
      searchable: true,
      type: "dropdown",
      options: leaseOption,
    },
    {
      field: "qualityLevel",
      label: "Quality Level",
      visible: false,
      searchable: true,
      type: "dropdown",
      options: [],
    },
    {
      field: "companyCode",
      label: "Company",
      visible: false,
      searchable: true,
      type: "dropdown",
      options: [],
    },
    {
      field: "dateNeeded",
      label: "Date Needed",
      visible: false,
      searchable: true,
      type: "date",
    },
    {
      field: "description",
      label: "Description",
      visible: false,
      searchable: true,
      type: "text",
    },
    {
      field: "accountNumber",
      label: "Account Number",
      visible: false,
      searchable: true,
      type: "text",
    },
    {
      field: "chargeCode",
      label: "Charge Code",
      visible: false,
      searchable: true,
      type: "text",
    },
    {
      field: "isCompleted",
      label: "Competed",
      visible: false,
      searchable: true,
      type: "boolean",
      options: competedOption,
    },
  ];

  //useEffect(() => {
  //    if (context?.loggedOnUser?.username) {
  //        //loadCompany().then((v) => { });
  //        loadOptions().then((v) => { });
  //        getDataFromApi();
  //    }

  //}, [context?.loggedOnUser]);

  useEffect(() => {
    //xts.setPaging(xts.defaultPage);
    if (context?.loggedOnUser?.username && schema.length == 0) {
      loadOptions().then((v) => {});
    }
    if (loaded && xts.searchParams) {
      getDataFromApi();
    }
  }, [
    paginationState[paginationEntity],
    xts.searchParams,
    selectedActionFilter,
    context?.loggedOnUser?.username,
    loaded,
  ]);

  React.useEffect(() => {
    if (sessionStorage.getItem("PRPageSearchHistory")) {
      xts.setSearchParam(
        JSON.parse(sessionStorage.getItem("PRPageSearchHistory"))
      );
    }
  }, []);

  const onGridRowClick = (e) => {
    let url;
    if (e.dataItem.status === "Draft") {
      url = "/pr/edit/" + e.dataItem.purchaseRequisitionGuid;
    } else {
      url = "/pr/view/" + e.dataItem.purchaseRequisitionGuid;
    }
    setRedirect({
      shouldRedirect: true,
      url: url,
    });
  };

  const getDataFromApi = () => {
    let api: PurchaseRequisitionApi = factory.create(PurchaseRequisitionApi);
    xts.setLoading(true);
    if (context?.loggedOnUser?.username) {
      api
        .purchaseRequisitionGet(
          context?.loggedOnUser?.username,
          selectedActionFilter?.value || "all",
          xts.searchParams?.searchString,
          xts.searchParams?.oDataQuery,
          paginationState[paginationEntity].top,
          paginationState[paginationEntity].skip,
          pageSize,
          paginationState[paginationEntity].sort.field +
            " " +
            paginationState[paginationEntity].sort.dir
        )
        .then(async (data) => {
          var dataFromApi = await data.json();
          xts.setDataCount(dataFromApi["@odata.count"]);
          const updatedData = [];
          dataFromApi.value.map((pr) => {
            updatedData.push({
              ...pr,
              projectNumber: pr.projectNumber,
              poNumber: pr.poNumber,
              vendor: pr.vendorCode && `${pr.vendorCode} (${pr.vendorName})`,
            });
          });
          xts.setData(updatedData);
        })
        .finally(() => {
          xts.setLoading(false);
        });
    }
  };
  const getAdvancedSearchSelectedValue = (field) => {
    let returnValue = null;
    searchParams.advancedSearchCondition?.map((v, i) => {
      if (field.field === v.field.field) {
        returnValue = v;
      }
    });
    return returnValue;
  };
  const loadOptions = async () => {
    let f1: VendorApi = factory.create(VendorApi);
    let advancedSearchApi: AdvancedSearchApi =
      factory.create(AdvancedSearchApi);
    // let result = await advancedSearchApi.advancedSearchPROptionsGet(
    //   context?.loggedOnUser?.username
    // );
    // let data = await result.json();
    // let vendorResult = await f1.vendorControllerGet(
    //   context?.loggedOnUser?.username
    // );
    // let vendors = await vendorResult.json();

    const resultPromise = advancedSearchApi
      .advancedSearchPROptionsGet(context?.loggedOnUser?.username)
      .then((result) => result.json());

    const vendorPromise = f1
      .vendorControllerGet(context?.loggedOnUser?.username)
      .then((result) => result.json());

    const [data, vendors] = await Promise.all([resultPromise, vendorPromise]);

    if (vendors?.value) {
      vendors.value.map((v) => {
        schema1[9].options.push({ text: v.vendorName, value: v.vendorId });
      });
    }

    //let companyApi: CompanyApi = commonfactory.create(CompanyApi);
    //let api: PrResourceAttributeApi = factory.create(PrResourceAttributeApi);
    //let cFactory = commonfactory;
    //let prFactory = factory;
    //let result = await PrApiService.getResourceAttributeList(context, api, "PR", "QualityLevel")
    //let companyResult = await companyApi.companyGet(context?.loggedOnUser?.username);
    //var companyDataFromApi = await companyResult.json();
    //const companyList = companyDataFromApi.value.map((c) => {
    //    return { text: `${c.companyName} (${c.companyIdCP})`, value: c.companyIdCP };
    //});
    //let leaseType = await PrApiService.getResourceAttributeList(context, api, "PR", "lease");
    //let userApi: UserApi = prFactory.create(UserApi);
    //let userResult = await userApi.userGet(context?.loggedOnUser?.username);
    //var userDataFromApi = await userResult.json();
    //const userList = userDataFromApi.value.map((c) => {
    //    return { text: `${c.displayname}`, value: c.UserGuid };
    //})

    schema1[4].options = data.Users;
    schema1[14].options = data.LeaseType;
    schema1[15].options = data.QualityLevel;
    schema1[16].options = data.Companies;
    let searchFields1: any[] = [];
    //AdvSearchAndGridUtil.mapSchema(
    //    entity,
    //    schema,
    //    sf,
    //    xts.searchParams,
    //    Search
    //);
    schema1.forEach((v, i) => {
      let c = getAdvancedSearchSelectedValue(v);
      if (v.type === "text" && v.searchable === true) {
        searchFields1.push(
          Search.getTextboxType(
            "",
            v.field,
            v.label,
            c?.value,
            c?.operator.value,
            c?.value2
          )
        );
      }
      if (v.type === "datepicker" && v.searchable === true) {
        searchFields1.push(
          Search.getDatePickerType(
            "",
            v.field,
            v.label,
            c?.value,
            c?.operator.value,
            c?.value2
          )
        );
      } else if (v.type === "number" && v.searchable === true)
        searchFields1.push(
          Search.getNumericType(
            "",
            v.field,
            v.label,
            c?.value,
            c?.operator.value,
            c?.value2
          )
        );
      else if (v.type === "boolean" && v.searchable === true)
        searchFields1.push(
          Search.getBooleanType(
            "",
            v.field,
            v.label,
            [
              { text: "Yes", value: "true" },
              { text: "No", value: "false" },
            ],
            c?.value,
            c?.operator.value
          )
        );
      else if (v.type === "dropdown" && v.searchable === true)
        searchFields1.push(
          Search.getDropdownType(
            "",
            v.field,
            v.label,
            v.options,
            c?.value,
            c?.operator.value
          )
        );
      else if (v.type === "multiselect" && v.searchable === true) {
        searchFields1.push(
          Search.getDropdownType(
            "",
            v.field,
            v.label,
            v.options,
            c?.value,
            c?.operator.value
          )
        );
      }
    });
    setSchema(schema1);
    setSearchFields(searchFields1);
    setLoaded(true);
    //setOptions(options)
  };

  const loadCompany = async () => {
    let companyApi: CompanyApi = commonfactory.create(CompanyApi);
    let result = await companyApi.companyGet(context?.loggedOnUser?.username);
    var companyDataFromApi = await result.json();
    const companyList = companyDataFromApi.value.map((c) => {
      return {
        text: `${c.companyName} (${c.companyIdCP})`,
        value: c.companyIdCP,
      };
    });
    schema[15].options = companyList;
    //setCompanies(companyList);
    let sf = [];
    AdvSearchAndGridUtil.mapSchema(
      entity,
      schema,
      sf,
      xts.searchParams,
      Search
    );
    setSchema(schema);
    setSearchFields(sf);
    setTime(time + 1);
  };

  const onSearchCondition = (e) => {
    setIsAdvancedSearchLoaded(true);
    xts.setSearchParam(e);
    sessionStorage.setItem("PRPageSearchHistory", JSON.stringify(e));
    updatePagination(paginationEntity, {
      skip: 0,
      top: 15,
      sort: {
        field: "createdOn",
        dir: "desc",
      },
    });
  };

  const onExportButtonClick = (e) => {
    // let api: PurchaseRequisitionApi = factory.create(PurchaseRequisitionApi);
    // xts.setLoading(true);
    context.showExportPopup();
    const fieldValues = AdvSearchAndGridUtil.getExportType(e).fields.map(
      (obj) => obj.label
    );
    const joinedFields = fieldValues.join(",");

    let s: PurchaseRequisitionApi = factory.create(PurchaseRequisitionApi);
    s.purchaseRequisitionExportExcelGet(
      context?.loggedOnUser?.username,
      selectedActionFilter?.value || "all",
      xts.searchParams?.searchString,
      xts.searchParams?.oDataQuery,
      xts.dataCount,
      null,
      xts.dataCount,
      paginationState[paginationEntity].sort.field +
        " " +
        paginationState[paginationEntity].sort.dir,
      joinedFields
    )
      .then(async (data) => {
        return data.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "data.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        return Promise.reject({ Error: "Something Went Wrong", err });
      })
      .finally(() => {
        // xts.setLoading(false);
        context.hideExportPopup();
      });
  };

  const onPageChange = (e) => {
    // xts.setPaging({ ...xts.paging, skip: e.page.skip, top: e.page.take });
    updatePagination(paginationEntity, {
      ...xts.paging,
      skip: e.page.skip,
      top: e.page.take,
    });
  };

  const onSortChange = (e) => {
    AdvSearchAndGridUtil.onSortChange(e, xts);
  };

  const onExportAfterRender = (e) => {
    AdvSearchAndGridUtil.onExportAfterRender(e, xts);
  };

  const onGridSelectionChange = (e) => {
    xts.setGridSelection(e);
  };

  const rowMenus = [];

  //  const rowMenus = [
  //  { text: "Edit", icon: "k-icon k-i-edit", onClick: onMenuClick },
  // { text: "Delete", icon: "k-icon k-i-delete", onClick: onMenuClick },
  //];

  const onMyActionFilterChange = (e) => {
    setSelectedActionFilter(e.value);
    sessionStorage.setItem("PRPageShortcutHistory", JSON.stringify(e.value));
    updatePagination(paginationEntity, {
      skip: 0,
      top: 15,
      sort: {
        field: "createdOn",
        dir: "desc",
      },
    });
  };

  return (
    <div>
      {redirect.shouldRedirect && (
        <Redirect
          push
          to={{
            pathname: redirect.url,
          }}
        />
      )}
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <APIFactoryCommon
        ref={(e) => {
          commonfactory = e;
        }}
      />

      <GridToolbar>
        <div className="page-toolbar p-0 align-items-center">
          <div className="row flex-grow-1 g-0">
            <div className="col-xl-auto pe-md-3">
              <h5 className="mb-2 page-title">Purchase Requisitions</h5>
            </div>
            <div className="col-md px-md-0 mb-2">
              {" "}
              {loaded && (
                <XtAdvancedSearchBox
                  searchPlaceHolder={
                    "Search by PR#, PO#, Vendor code, Company Code and Special Note only"
                  }
                  hideAdvancedSearch={false}
                  searchConditions={searchFields}
                  selectedConditions={xts.searchParams}
                  onSearchConditionApply={onSearchCondition}
                >
                  <XtAdvancedSearchChild type="prepend">
                    <DropDownList
                      style={{ width: "155px" }}
                      onChange={onMyActionFilterChange}
                      data={myActionFilter}
                      dataItemKey="value"
                      textField="text"
                      value={selectedActionFilter}
                    />
                  </XtAdvancedSearchChild>
                </XtAdvancedSearchBox>
              )}
            </div>
            <div className="col-sm-auto pe-md-0 mb-2 btn-two-groups">
              <Button className="me-2" href="/pr/create">
                {" "}
                Create New PR{" "}
              </Button>
              <XtExportToolbar
                fields={searchFields}
                onApply={onExportButtonClick}
                gridSelection={xts.gridSelection}
              />
            </div>
          </div>
        </div>
      </GridToolbar>
      <XtExport
        type={xts.exportType.type}
        data={xts.reportData}
        schema={xts.exportType.fields}
        fileName={xts.exportType.filename}
        onAfterRender={onExportAfterRender}
      >
        <XtGrid
          uniqueField="prNumber"
          data={xts.data}
          total={xts.dataCount}
          sort={[paginationState[paginationEntity].sort]}
          rowMenus={rowMenus}
          sortable={true}
          skip={paginationState[paginationEntity].skip}
          pageSize={pageSize}
          pageable={true}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          onGridSelectionChange={onGridSelectionChange}
          onRowClick={onGridRowClick}
          className="k-grid-pr-list"
        >
          {AdvSearchAndGridUtil.renderGridFields(schema1)}
        </XtGrid>
      </XtExport>
      {xts.loading && <Loading />}
    </div>
  );
}
