import { Grid } from "@progress/kendo-react-grid";
import React from "react";
import { AdvSearchAndGridUtil } from "../../../../../Xt/Utils/AdvanceSearchAndGridUtil";
import AttachmentList from "../Common/AttachmentList";
import DeleteAttachmentCell from "../Common/GridCell/DeleteAttachmentCell";
import NwFileLinkGridCell from "../Common/GridCell/FileLinkGridCell";
import { DateUtil } from "../../../../../Xt/Utils/DateUtil";

const schema = [
  {
    field: "attachmentGuid",
    label: "attachmentGuid",
    visible: false,
    searchable: false,
    type: "string",
  },

  {
    field: "filePath",
    label: "File",
    visible: true,
    searchable: false,
    type: "string",
    cell: NwFileLinkGridCell,
  },
  {
    field: "displayName",
    label: "Uploaded By",
    visible: true,
    searchable: false,
    type: "string",
  },
  {
    field: "createdOn",
    label: "Uploaded On",
    visible: true,
    searchable: false,
    type: "string",
  },
];

export default function AttachmentListReadOnly(props) {
  const formattedFiles = props.files?.map((attachment) => ({
    ...attachment,
    createdOn: DateUtil.formatmmDdYYY(attachment?.createdOn),
  }));

  return (
    <AttachmentList
      files={formattedFiles}
      allowAttachmentDelete={props?.allowAttachmentDelete}
      schema={schema}
      invoiceGuid={props?.invoiceGuid}
      onUpdateFiles={props?.onUpdateFiles}
      prGuid={props?.prGuid}
    />
  );
}
