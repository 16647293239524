import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React, { useContext, useState } from "react";
import { XtForm, XtField } from "nwcommon";
import { Button, Row } from "react-bootstrap";
import {
  APIFactory,
  Invoice,
  InvoiceApi,
  PurchaseRequisitionApi,
} from "../../../../Xt/ApiClients";
import { Loading } from "../../../../Xt/Layout/Loading";
import FormField from "../../Nw/PurchaseRequisition/Common/FormField";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import ErrorDialog from "../../Dialogs/Common/ErrorDialog";
import { NumberUtil } from "../../../../Xt/Utils/NumberUtil";

export enum EnApprovalMode {
  Approve = "Approve",
  Reject = "Reject",
  Revise = "Revise",
  NotSet = "NotSet",
  Cancel = "Cancel",
  Comment = "Comment",
}

interface InvoiceApproveRejectDialogProps {
  approvalMode: EnApprovalMode;
  onPrimaryClick: any;
  onSecondaryClick: any;
  invoiceGuid: string;
  currentLevel: number;
  invoice: Invoice;
  committedAmount: number;
  poRunningTotal: number;
  allowEditForPM: boolean;
  setAuditLogComment?: any;
}

export default function InvoiceApproveRejectDialog(
  props: InvoiceApproveRejectDialogProps
) {
  let factory: APIFactory;
  const context: IXtContext = useContext(XtContext);
  const [approvalMode, setApprovalMode] = useState(props.approvalMode);
  const [invoiceGuid, setInvoiceGuid] = useState(props.invoiceGuid);
  const [loading, setLoading] = useState(false);
  const [currentLevel, setCurrntLevel] = useState(props?.currentLevel ?? 0);
  const [errors, setErrors] = React.useState([]);

  const onSubmit = async (e) => {
    let invoice = {
      ...props.invoice,
      actionType: approvalMode?.toLocaleString(),
    };
    let api: InvoiceApi = factory.create(InvoiceApi);
    let result;
    const comments = e.postData.comments;
    const submitErrors = [];
    var total = 0;

    props.invoice.invoiceCodings.map((c, i) => {
      total = NumberUtil.decimalNumSum(total, c.amount);

      // if (c.amount > c.committedAmount)
      // submitErrors.push(
      //   `#${c.sno}: Invoice amount cannot be more than committed amount.`
      // );
    });
    // if (props.poRunningTotal > props.committedAmount) {
    //   submitErrors.push("PO running total cannot be more than PO committed amount.");
    // }

    if (
      Number(total) !==
      Number(NumberUtil.formatToTwoDecimal(props.invoice.vendorAmount))
    ) {
      submitErrors.push(
        "Total invoice coding amount is not equals to invoice amount."
      );
    }
    if (submitErrors.length > 0) {
      setErrors(submitErrors);
      return;
    }

    //invoice.poRunningTotalAmount = props.totalRunningCost;

    setLoading(true);

    try {
      switch (approvalMode) {
        case EnApprovalMode.Approve:
          if (props?.allowEditForPM) {
            // First call ApproveInvoice API then only call UpdateInvoice API
            result = await api.invoiceApproveInvoiceGuidPost(
              context?.loggedOnUser?.username,
              {
                comment: comments,
                currentLevel: currentLevel,
              },
              invoiceGuid
            );

            if (result.ok) invoice.comment = comments;
            result = await api.invoiceInvoiceGuidPut(
              invoiceGuid,
              context?.loggedOnUser?.username,
              invoice
            );
          } else {
            result = await api.invoiceApproveInvoiceGuidPost(
              context?.loggedOnUser?.username,
              {
                comment: comments,
                currentLevel: currentLevel,
              },
              invoiceGuid
            );
          }

          break;
        case EnApprovalMode.Reject:
          result = await api.invoiceRejectInvoiceGuidPost(
            context?.loggedOnUser?.username,
            invoiceGuid,
            comments
          );
          break;
        case EnApprovalMode.Revise:
          invoice.comment = comments;
          //result = await api.invoiceInvoiceGuidPut(invoiceGuid, context?.loggedOnUser?.username, invoice);
          //if(result.ok)
          //  result = await api.invoiceReviseInvoiceGuidPost(context?.loggedOnUser?.username, {}, invoiceGuid, comments)
          result = await api.invoiceReviseInvoiceGuidPost(
            context?.loggedOnUser?.username,
            invoice,
            invoiceGuid
          );
          break;
        case EnApprovalMode.Comment:
          result = await api.invoiceAddCommentInvoiceGuidPost(
            invoiceGuid,
            context?.loggedOnUser?.username,
            { comments: comments }
          );
          let res = await result.json();
          props?.setAuditLogComment([res.result]);
      }
      props.onPrimaryClick();
    } catch (ex) {
      console.log(ex);
      let err: string[] = [];
      if (ex.status == 400) {
        let msg = await ex.json();
        if (msg.errors) {
          Object.keys(msg.errors).forEach((key) => {
            err.push(
              `${key}- ${msg.errors[key as keyof typeof msg.errors].join(",")}`
            );
          });
        }
      } else {
        err.push(ex.statusText);
      }
      setErrors(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />

      {errors.length == 0 && (
        <Dialog
          title={EnApprovalMode[props.approvalMode]}
          onClose={props.onSecondaryClick}
          width={"60%"}
        >
          <XtForm onSubmit={onSubmit}>
            <Row>
              <FormField label="Comments">
                <XtField
                  name="comments"
                  type="textarea"
                  required={
                    approvalMode === EnApprovalMode.Approve ? false : true
                  }
                  errormessage="Comments is required"
                />
              </FormField>
            </Row>
            <DialogActionsBar layout="end">
              <Button
                type="button"
                variant="secondary"
                onClick={props.onSecondaryClick}
              >
                {" "}
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                {EnApprovalMode[props.approvalMode]}
              </Button>
            </DialogActionsBar>
          </XtForm>
        </Dialog>
      )}
      {loading && <Loading />}
      {errors.length > 0 && (
        <ErrorDialog
          errorMessage={errors}
          onClose={props.onSecondaryClick}
          width={"60%"}
        />
      )}
    </>
  );
}
