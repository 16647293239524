import React from "react";

declare var window: any


interface IErrorHandlerProps {

}

interface IErrorHandlerState {
    error: any,
    errorInfo: any
}

export class ErrorHandler extends React.Component{
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return {
            error: {},
            errorInfo: {} };
    }

    onWindowError(e) {
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        window.onerror = this.onWindowError
        return (<>
            {this.props.children}
        </>)
    }
}