import { Button } from "react-bootstrap";
import { EnApprovalMode } from "../../Interfaces/Enums";

interface IRejectButtonProps {
  onApprovalButtonClick: (approvalMode: EnApprovalMode) => void;
}

export default function RejectButton(props: IRejectButtonProps) {
  const { onApprovalButtonClick } = props;
  return (
    <Button
      type="button"
      variant="danger"
      onClick={() => {
        onApprovalButtonClick(EnApprovalMode.Reject);
      }}
    >
      Reject
    </Button>
  );
}
