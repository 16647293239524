import React, { useState } from "react";
import { Card, Container, NavDropdown } from "react-bootstrap";
import { NwAlertMenuItemProps } from "../../Props/NwMenu/NwAlertMenuItemProps";

export default function NwAlertMenuItem(props: NwAlertMenuItemProps) {
  const createdByName = props.createdByName
    

  return (
    <div className="notification-item px-2 py-3 border-bottom">
      <a href={`props.url`}> {props.subject} </a>
      <div>{props.additionalMessage}</div>
      <div>
        <div> {createdByName} </div>
        <div className="small">
          {props.createdByName && (
            <i className="material-icons me-1">schedule</i>
          )}

          {props.createdOnString}
        </div>
      </div>
    </div>
  );
}
