import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useContext, useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { Redirect, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { Loading } from "../../../../../Xt/Layout/Loading";
import { XtBreadcrumbs } from "../../../../../Xt/Layout/XtBreadcrumbs";
import ApproveRejectReviseDialog from "../../PurchaseRequisition/ApprovalView/ApproveRejectReviseDialog";
import XtAccordianItem from "../../PurchaseRequisition/Common/XtAccordianItem";
import AuditLog from "../Components/AuditLog";
import ActionButtons from "../Components/Buttons/ActionButtons";
import CloneButton from "../Components/Buttons/CloneButton";
import { EnApprovalMode } from "../Interfaces/Enums";
import PrrAdditionalInfoReadOnly from "./AdditionalInfoReadOnly";
import PrrGeneralInfoReadOnly from "./GeneralnfoReadOnly";
import PrrPositionInfoReadOnly from "./PositionInfoReadOnly";
import WorkflowReadOnly from "./WorkflowReadOnly";
import {
  getPrrByGuid,
  getPrrPermissionByGuid,
} from "../../../../../api/react-query";

export default function NwPrrApprovalView() {
  let { id } = useParams();
  const activeSections = ["0", "2", "3", "4"];
  const context: IXtContext = useContext(XtContext);
  const redirectTo = { shouldRedirect: false, url: "" };
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [redirect, setRedirect] = useState(redirectTo);
  const [showApprovalDialog, setShowApprovalDialog] = useState(false);
  const [approvalMode, setApprovalMode] = useState(EnApprovalMode.NotSet);
  const [canApproveReviseOrReject, setCanApproveReviseOrReject] =
    useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const bc = { breadCrumbs: breadCrumbs };

  const token = context?.tokens?.accessToken;
  const loggedOnUser = context?.loggedOnUser?.username;

  const {
    data: prrData,
    error: prrError,
    isLoading: isPrrLoading,
  } = useQuery(["getPrrByGuid", id, token, loggedOnUser], getPrrByGuid, {
    enabled: !!id && !!token && !!loggedOnUser,
  });

  const {
    data: prrPermissionData,
    error: prrPermissionError,
    isLoading: isPrrPermissionLoading,
  } = useQuery(
    ["getPrrPermissionByGuid", id, token, loggedOnUser],
    getPrrPermissionByGuid,
    {
      enabled: !!id && !!token && !!loggedOnUser,
    }
  );

  const onApprovalButtonClick = (approvalMode: EnApprovalMode) => {
    setApprovalMode(approvalMode);
    setShowApprovalDialog(true);
  };

  const approvalDailogOnClose = () => {
    setShowApprovalDialog(false);
    setRedirect({ shouldRedirect: true, url: "/" });
  };

  const loadClone = () => {
    if (!prrData) return;

    localStorage.setItem("prr", JSON.stringify(prrData));
    setRedirect({ shouldRedirect: true, url: "/prr/create" });
  };

  useEffect(() => {
    if (!prrData) return;

    setBreadCrumbs([
      {
        href: "/prr",
        text: "Personnel Requisitions ",
        isActive: false,
      },
      {
        href: "",
        text: "" + prrData.prrNumber,
        isActive: true,
      },
    ]);
  }, [prrData]);

  useEffect(() => {
    if (!prrPermissionData || !prrData) return;

    const checkApproveReviseRejectPermission = (): boolean => {
      if (!prrPermissionData) return false;
      if (prrData?.status.includes("Rejected")) return false;
      if (prrData?.status.includes("Requestor")) return false;
      if (prrData?.status.includes("Breezy")) return false;
      if (prrData?.status.includes("Canceled")) return false;
      if (prrPermissionData.isApprover) return true;
      if (prrPermissionData.isSAUser) return true;
      if (context.isSuperAdmin() && !context.isInSwitchUserMode()) return true;
      if (context.isHRAdmin()) return true;
      return false;
    };

    const checkEditPermission = (): boolean => {
      return (
        prrPermissionData &&
        prrData?.status.includes("Requestor") &&
        prrPermissionData.isEditable &&
        prrPermissionData.isApprover
      );
    };

    const approvePerm = checkApproveReviseRejectPermission();
    setCanApproveReviseOrReject(approvePerm);
    const editPerm = checkEditPermission();
    setCanEdit(editPerm);
  }, [prrPermissionData, context, prrData]);

  return (
    <>
      {redirect.shouldRedirect && <Redirect push to={redirect.url} />}

      <div className="row mb-3 align-items-md-center">
        <div className="col">
          <div className="mb-3 mb-md-0">
            <XtBreadcrumbs {...bc} />
          </div>
        </div>
        <div>
          <div className="d-flex col justify-content-between">
            <CloneButton
              loading={isPrrLoading || isPrrPermissionLoading}
              loadClone={loadClone}
            />
            {canApproveReviseOrReject && (
              <ActionButtons onApprovalButtonClick={onApprovalButtonClick} />
            )}
          </div>
        </div>

        {canEdit && (
          <DialogActionsBar layout="end">
            <Button
              onClick={(e) => {
                setRedirect({
                  shouldRedirect: true,
                  url: `/prr/edit/${prrData?.personnelRequisitionGuid}`,
                });
              }}
            >
              Edit
            </Button>
          </DialogActionsBar>
        )}
        <div className="row mb-3 align-items-md-center"></div>
      </div>

      <div className="prr-approval-view">
        <Accordion defaultActiveKey={activeSections} alwaysOpen>
          <XtAccordianItem header="REVIEWER DETAIL" eventKey="0">
            <WorkflowReadOnly
              prrGuid={prrData?.personnelRequisitionGuid}
              isApprover={canApproveReviseOrReject}
              designatorList={prrPermissionData?.designatorList}
            />
          </XtAccordianItem>

          <Accordion defaultActiveKey={activeSections} alwaysOpen={false}>
            <XtAccordianItem
              header="AUDIT LOG"
              eventKey="1"
              hide
              IsCollapsible={true}
              className="mb-3"
            >
              <AuditLog prrGuid={prrData?.personnelRequisitionGuid} />
            </XtAccordianItem>
          </Accordion>

          <XtAccordianItem header="GENERAL" eventKey="2">
            <PrrGeneralInfoReadOnly prr={prrData} />
          </XtAccordianItem>

          <XtAccordianItem header="POSITION DETAILS" eventKey="3">
            <PrrPositionInfoReadOnly prr={prrData} />
          </XtAccordianItem>

          <XtAccordianItem header="ADDITIONAL" eventKey="4">
            <PrrAdditionalInfoReadOnly prr={prrData} />
          </XtAccordianItem>
        </Accordion>
        {canApproveReviseOrReject && (
          <div className="d-flex justify-content-end">
            <ActionButtons onApprovalButtonClick={onApprovalButtonClick} />
          </div>
        )}

        {isPrrLoading && isPrrPermissionLoading && <Loading />}

        {showApprovalDialog && (
          <ApproveRejectReviseDialog
            approvalMode={approvalMode}
            onPrimaryClick={approvalDailogOnClose}
            prrGuid={id}
            prrNumber={prrData?.prrNumber}
            currentLevel={null}
            onSecondaryClick={() => setShowApprovalDialog(false)}
          />
        )}
      </div>
    </>
  );
}
