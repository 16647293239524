import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React, { useContext, useState } from "react"
import { Button, Col, Row } from "react-bootstrap";
import { APIFactory, UserApi, InvoiceApi } from "../../../../Xt/ApiClients";
import { XtField, XtForm } from "nwcommon";
import FormField, { RequiredFormField } from "../../Nw/PurchaseRequisition/Common/FormField";
import FormLabelMutedText from "../../Nw/PurchaseRequisition/Common/FormLabelMutedText";
import { NwPrConstants } from "../../Nw/PurchaseRequisition/Common/NwPrConstants";
import { Loading } from "../../../../Xt/Layout/Loading";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
export type AddInvoiceAddReviewerProps = {
    additionalReviewer: any,
    invoiceGuid: string,
    onClose: any,
    onUpdateReviewers?: any
    
}

export function AddInvoiceAddReviewer(props: AddInvoiceAddReviewerProps) {
    let factory: APIFactory
    
    const [selectedUser, setSelectedUser] = React.useState<any>(null)
    const [users, setUsers] = React.useState<any[]>([])
    const [selectedRole, setSelectedRole] = React.useState(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const context: IXtContext = useContext(XtContext);
  

    React.useEffect(() => {
        if (context?.loggedOnUser?.username) {
            setLoading(true)
            let apiUser: UserApi = factory.create(UserApi);
            apiUser.userGet(context?.loggedOnUser?.username).then(async (data) => {
                let dataFromApi = await data.json();

                if (dataFromApi && dataFromApi.value) {
                    const filteredUsers = dataFromApi.value.filter(
                        (u) => u.displayname !== context?.loggedOnUser?.displayname
                    );

                    setUsers(filteredUsers);
                }

            }).finally(() => { setLoading(false) });
        }
    }, [props, context?.loggedOnUser?.username])

    const onSubmit = (e) => {
        if (props.invoiceGuid && props.invoiceGuid !== "00000000-0000-0000-0000-000000000001") {
            var postData = e.postData
            postData.invoiceId = props.invoiceGuid;
            postData = {
                invoiceGuid: props.invoiceGuid,
                userGuid: selectedUser.userGuid,
                level: props.additionalReviewer.level.toString()
            }
            let api: InvoiceApi = factory.create(InvoiceApi);
            api.invoiceAdditionalReviewerInvoiceGuidPost(props.invoiceGuid, context?.loggedOnUser?.username, postData).then(async (v) => {
                props.onUpdateReviewers(v)
            }).catch(async (e) => {
                if (e.message)
                    alert(e.message)
            }).finally(() => {
                setLoading(false);
                props.onClose();
            })
        }
        else {
            var data = e.postData
            data.displayName = users.find((e1) => e1.value === data.user)?.text
            data.role = NwPrConstants.pr_additional_reviewer
            data.stage = props.additionalReviewer.level
            data.userGuid = data.user;
            props.onUpdateReviewers(data)
        }
    }

    return (<>
        <APIFactory
            ref={(e) => {
                factory = e;
            }}
        />
        <Dialog
            title={`Add Reviewer`}
            onClose={props.onClose}
            {...props}
            width={"60%"}
        >
            <XtForm onSubmit={onSubmit}>
                <RequiredFormField label="Reviewer">
                    <XtField
                        type="dropdown"
                        name="users"
                        items={users}
                        defaultValue={selectedUser}
                        onChange={(e) => setSelectedUser(e.value)}
                        required={true}
                        textField="displayname"
                        errormessage="User is required"
                        loading={loading}
                    />
                </RequiredFormField>

                <FormField label="Role">
                    <input type="hidden" name="level" value={props.additionalReviewer.level }/>
                    <FormLabelMutedText value={NwPrConstants.pr_additional_reviewer} />
                </FormField>

                <DialogActionsBar layout={"end"}>
                    <Button variant="secondary" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Add
                    </Button>
                </DialogActionsBar>
            </XtForm>
        </Dialog>
        
    </>);

}