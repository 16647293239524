import { preparePKCE } from "@okta/okta-auth-js";
import React from "react";
import { NumberUtil } from "../../../../../../Xt/Utils/NumberUtil";

export default function AmountGridCell(props) {
  return (
    <td style={{ textAlign: "right" }}>
      {NumberUtil.formatToTwoDecimalWithComma(props.dataItem[props.field])}
    </td>
  );
}

export function TrucAmountGridCell(props) {
  return (
    <td style={{ textAlign: "right" }}>
      {NumberUtil.formatTrucNumToTwoDecimalWithComma(
        props.dataItem[props.field]
      )}
    </td>
  );
}
export function TrucAmountGridCellBg(props) {
  return (
    <td style={{ textAlign: "right" }}>
     <span className={props.dataItem[props.field] < 0 ? "badge bg-danger" : ""}>{NumberUtil.formatTrucNumToTwoDecimalWithComma(props.dataItem[props.field])}</span> 
    </td>
  );
}

export function AmountGridCellBg(props) {
  return (
    <td style={{ textAlign: "right" }}> <span className={props.dataItem[props.field] < 0 ? "badge bg-danger" : ""}>{NumberUtil.formatToTwoDecimalWithComma(props.dataItem[props.field])}</span> </td>
  );
}

export function AmountGridCellBold(props) {
  return (
    <td style={{ textAlign: "right", fontWeight: "bold" }}>
      {NumberUtil.formatToTwoDecimalWithComma(props.dataItem[props.field])}
    </td>
  );
}

export function TotalAmountGridCellBold(props) {
  return (
    <td style={{ textAlign: "right", fontWeight: "bold" }}>
      {NumberUtil.roundToTwoDecimalWithComma(props.dataItem[props.field])}
    </td>
  );
}

export function NumberGridCellWithComma(props) {
  return (
    <td style={{ textAlign: "right" }}>
      {NumberUtil.formatToTwoDecimalWithCommaNoCurrency(
        props.dataItem[props.field]
      )}
    </td>
  );
}

export function AmountGridCellWithOldValueBold(props) {
  return GridCellWithCommaWithOldValue(
    props,
    NumberUtil.formatToTwoDecimalWithComma,
    true
  );
}

export function TotalAmountGridCellWithOldValueBold(props) {
  return GridCellWithCommaWithOldValue(
    props,
    NumberUtil.roundToTwoDecimalWithComma,
    true
  );
}

export function AmountGridCellWithOldValue(props) {
  return GridCellWithCommaWithOldValue(
    props,
    NumberUtil.formatToTwoDecimalWithComma,
    false
  );
}
export function NumberGridCellWithCommaWithOldValue(props) {
  return GridCellWithCommaWithOldValue(
    props,
    NumberUtil.formatToTwoDecimalWithCommaNoCurrency,
    false
  );
}

export function GridCellWithCommaWithOldValue(props, formatFunction, bold) {
  const lastField =
    "last" + props.field.charAt(0).toUpperCase() + props.field.slice(1);
  let lastValue = props.dataItem[lastField];
  const currentValue = props.dataItem[props.field];

  

  return (
    <td style={{ textAlign: "right" }}>
      <ShowNumberDiff
        lastValue={lastValue}
        currentValue={currentValue}
        formatFunction={formatFunction}
        bold={bold}
      />
    </td>
  );
}

export interface ShowNumberDiffProps {
  currentValue?: Number;
  lastValue?: Number;
  formatFunction?: any;
  bold?: boolean;
}

const shouldShowDiff = (props) => {
  if (!props?.lastValue) return false;
  if (Number(props?.lastValue) == 0) return false;
  const diff = Number(props?.currentValue) - Number(props?.lastValue);
  if (isNaN(diff)) return false;
  if (Number(diff) == 0) return false;
  return true;
};

export function ShowNumberDiff(props: ShowNumberDiffProps) {
  const diff = NumberUtil.decimalNumDiff(
    Number(props?.currentValue),
    Number(props?.lastValue)
  );



  if (!props?.lastValue)
    return (
      <span style={{ color: "green" }}>
        {props?.formatFunction(props?.currentValue)}
      </span>
    );
  else
    return (
      <>
        {shouldShowDiff(props) && (
          <>
            {props?.formatFunction(props?.lastValue)}
            <br />
            {diff > 0 && (
              <span style={{ color: "green" }}>
                + {props?.formatFunction(Math.abs(diff))}
              </span>
            )}

            {diff < 0 && (
              <span style={{ color: "red" }}>
                - {props?.formatFunction(Math.abs(diff))}
              </span>
            )}

            <br />
            <span style={{ color: "black", fontWeight: "bold" }}>
              {props?.formatFunction(props?.currentValue)}
            </span>
          </>
        )}
        {!shouldShowDiff(props) && (
          <>
            {props?.bold && (
              <span style={{ color: "black", fontWeight: "bold" }}>
                {props?.formatFunction(props?.currentValue)}
              </span>
            )}

            {!props?.bold && (
              <span>{props?.formatFunction(props?.currentValue)}</span>
            )}
          </>
        )}
      </>
    );
}
