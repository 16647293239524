import React from "react";
import { Container } from "react-bootstrap";
import { enum_pr_status } from "../NwPrConstants";
import { Redirect, Link } from "react-router-dom";
import { StringUtil } from "../../../../../../Xt/Utils/StringUtil";

export default function NwPrStatusGridCell(props) {
  const status: string = props.dataItem["status"];
  

  let purchaseRequisitionGuid :string = props.dataItem["purchaseRequisitionGuid"];
  if(!purchaseRequisitionGuid) 
  purchaseRequisitionGuid =props.dataItem["resourceGuid"]


  const prStatusClassName = `badge  pr-status-${status
    .replace(" ", "-")
    .toLowerCase()}`;

  return (
    <td>
      {status.toLocaleLowerCase()=="waiting for cost point integration" && 
        <Link to={"/cp/landingpage/"+purchaseRequisitionGuid} >
          <span className={prStatusClassName} role={"gridcell"}>
            {status === null ? "" : StringUtil.replaceCostPoint(status)}
          </span>
          </Link>


      }
      {status.toLocaleLowerCase()!="waiting for cost point integration" && 
        
          <span className={prStatusClassName} role={"gridcell"}>
            {status === null ? "" : StringUtil.replaceCostPoint(status)}
          </span>
        
      }

    </td>
  );
}




