import { ErrorHandler } from "../Layout/ErrorHandler";

export const ErrorUtil = {
  getUncaughtErrorMessage: (httpError) => {
    let errors = [];
    if (httpError.errors) {
      for (const key in httpError.errors) {
        const errorDescriptionArray =
          httpError.errors[key as keyof typeof httpError.errors];
        if (errorDescriptionArray && errorDescriptionArray.length > 0)
          errors.push(errorDescriptionArray[0]);
      }
    } else {
      errors.push(JSON.stringify(httpError));
    }
    return errors;
  },
  getErrorMessageFromCostPointResponse: (constPointErrorResponse) => {
    var errors: any[] = [];
    const errorsSeperatedByNewLine = constPointErrorResponse.split("/n");
    errorsSeperatedByNewLine.map((err) => {
      var errorMessageFromApi = err;
      var indexOfArray = errorMessageFromApi.indexOf("[{");
      if (indexOfArray != -1) {
        errors.push(errorMessageFromApi.substring(0, indexOfArray));
        // console.log(errorMessageFromApi.substring(indexOfArray, errorMessageFromApi.length))
        var errorArrayFromApi = JSON.parse(
          errorMessageFromApi.substring(
            indexOfArray,
            errorMessageFromApi.length
          )
        );
        errorArrayFromApi.map((a: any) => errors.push(a.msgText));
      } else {
        errors.push(constPointErrorResponse);
      }
    });

    return errors;
  },
  getErorMessageFromApi: (error) => {
    const errors = [];
    if (error.status && error.status === 400) {
      if (error.errors) {
        Object.keys(error.errors).forEach((key) => {
          errors.push(
            `${key}- ${error.errors[key as keyof typeof error.errors].join(
              ","
            )}`
          );
        });
      }
    } else if (error.status && error.status === 500) {
      errors.push(error.statusText);
    } else {
      errors.push(error?.message || error?.title);
    }
    return errors;
  },
};
