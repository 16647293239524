import React, { useContext } from 'react'
import { NavItem } from 'react-bootstrap'
import { XtContext } from '../../Contexts/XtContext'

export default function NwSettingsMenuItem() {
    const context = useContext(XtContext);
    const isSuperAdmin = context.isSuperAdmin();
    return (
        isSuperAdmin ?
            <NavItem className="nw-settings">
                <a href="/admin/settings" className="nav-link">
                    <i className="menu-icon material-icons">settings</i>
                </a>
            </NavItem> : null
    )
}
