import React from 'react';

import { DateUtil } from '../../../../../../Xt/Utils/DateUtil';

export const enum EnPrWorkflowStatus {
    COMPLETED = "completed",
    IN_PROGRRESS = "inprogress",
    REJECTED = "rejected",
    NOT_COMPLETE = "not completed",
    CANCELED = "canceled",
}

export default function PrCompletedGridCell(props) {
    const getStatus = () => {
        const isCompleted: boolean = props.dataItem["isCompleted"] === true;
        const isRejected: boolean = props.dataItem["isRejected"] === true;
        const isActive: boolean = props.dataItem["isActive"] === true;
        const isCanceled: boolean = props.dataItem["isCanceled"] === true;
        let status = EnPrWorkflowStatus.NOT_COMPLETE;
        if(isCanceled){
            status = EnPrWorkflowStatus.CANCELED;
        }
        else if (isCompleted && isRejected) {
            status = EnPrWorkflowStatus.REJECTED;
        }
        else if (isCompleted) {
            status = EnPrWorkflowStatus.COMPLETED;
        } else if (isRejected) {
            status = EnPrWorkflowStatus.REJECTED;
        } else if (isActive) {
            status = EnPrWorkflowStatus.IN_PROGRRESS;
        } else {
            status = EnPrWorkflowStatus.NOT_COMPLETE;
        }

        return status;
    };

    const status = getStatus();

    const prCompletedClassName = `pr-completed-status`;
    //console.log(props);
    return (
        <td>
            <span className={prCompletedClassName} role={"gridcell"}>
                {status == EnPrWorkflowStatus.COMPLETED && (
                    <span className="badge  d-inline-flex align-items-center bg-success">
                        <i className="material-icons me-1" style={{ fontSize: "16px" }}>
                            check_circle
                        </i>
                        Completed on  {DateUtil.convertUTCDateToLocalDate(props.dataItem.timeStamp)} {props?.dataItem?.displayName === props?.dataItem?.completedBy ? "" : (" by " + props.dataItem.completedBy)}
                        
                    </span>
                )}
                {status == EnPrWorkflowStatus.NOT_COMPLETE && (
                    <span className="badge  d-inline-flex align-items-center text-dark bg-light">
                        <i className="material-icons me-1" style={{ fontSize: "16px" }}>
                            do_not_disturb_on
                        </i>
                        Not Complete
                    </span>
                )}

                {status == EnPrWorkflowStatus.REJECTED && (
                    <span className="badge  d-inline-flex align-items-center bg-danger">
                        <i className="material-icons me-1" style={{ fontSize: "16px" }}>
                            close
                        </i>
                        Rejected on {DateUtil.convertUTCDateToLocalDate(props.dataItem.timeStamp)} {props.dataItem.displayName === props.dataItem.completedBy ? "" : (" by " + props.dataItem.completedBy)}
                    </span>
                )}
                {status == EnPrWorkflowStatus.CANCELED && (
                    <span className="badge  d-inline-flex align-items-center bg-danger">
                        <i className="material-icons me-1" style={{ fontSize: "16px" }}>
                            close
                        </i>
                        Canceled on {DateUtil.convertUTCDateToLocalDate(props.dataItem.timeStamp)} {props.dataItem.displayName === props.dataItem.completedBy ? "" : (" by " + props.dataItem.completedBy)}
                    </span>
                )}
                {status == EnPrWorkflowStatus.CANCELED && (
                    <span className="badge  d-inline-flex align-items-center bg-danger">
                        <i className="material-icons me-1" style={{ fontSize: "16px" }}>
                            close
                        </i>
                        Canceled on {DateUtil.format24Hour(props.dataItem.timeStamp)} {props.dataItem.displayName === props.dataItem.completedBy ? "" : (" by " + props.dataItem.completedBy)}
                    </span>
                )}
                {status == EnPrWorkflowStatus.IN_PROGRRESS && (
                    <span className="badge  d-inline-flex align-items-center text-light bg-primary">
                        <i className="material-icons me-1" style={{ fontSize: "16px" }}>
                            rotate_left
                        </i>
                        In Progress
                    </span>
                )}
            </span>
        </td>
    );
}
