import React from "react"
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs"
import { Button, Col, Container } from "react-bootstrap"
import { XtField } from "nwcommon";


export type XtAddFolderDialogProps = {
	onClose?: any
	onCreate?: any
}

export function XtAddFolderDialog(props: XtAddFolderDialogProps) {

	const [text, setText] = React.useState<string>();

	const onChange = (e: any) => {
		setText(e.target.value)
	}

	const onCreate = (e: any) => {
		props.onCreate({...e, value: text})
    }

	return (<Dialog title="Add Folder" onClose={props.onClose} width="50%">
		<Container>
			<Col><XtField type="text" onChange={onChange} /></Col>
		</Container>
		<DialogActionsBar>
			<Button type="button" variant="primary" onClick={onCreate}>Create</Button>
			<Button variant="secondary" onClick={props.onClose}>Cancel</Button>
		</DialogActionsBar>
	</Dialog>)
}