import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { XtField } from "nwcommon";
import {
    APIFactory,
      
    VendorApi
} from "../../../../../Xt/ApiClients";
import { EnPrAction, EnPrTypeDetail } from "../Common/PrReducer";
import FormLabelMutedText from "../Common/FormLabelMutedText";
import { PrApiService } from "../Common/PrApiService";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { NwVendorAddressDetails } from "../Vendor/NwVendorAddressDetails";
import { RequiredFormField } from "../Common/FormField";

//TODO: props must be strictly typed
// Always strictly type props so intention in
// code is clear
export default function NwVendorInfo(props) {
    let prApiFactory: APIFactory;
    const { state, dispatch } = props;
    const [vendors, setVendors] = useState([]);
    const [vendorLoading, setVendorLoadig] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [vendorAddressGuid,setVendorAddressGuild] =useState(null);
    const [addressContactGuid, setaddressContactGuid] = useState(null);
    const [showAddressDetails, setShowAddressDetails] = useState(false);
    const context: IXtContext = React.useContext(XtContext);   

    useEffect(() => {
            loadVendors();
    }, [context?.loggedOnUser?.username, state?.companyCode, state?.requisitionType]);

    useEffect(() => {     
        if (state?.vendorCode) {
            loadVendorDetail();
        } else  {

          setSelectedVendor(null);;
          setVendorAddressGuild(null);
          setaddressContactGuid(null);     
          
        }
        
    }, [state?.vendorCode]);

    useEffect(() => {
        if (state?.addressContactGuid)
          setaddressContactGuid(state?.addressContactGuid);
      }, [state?.addressContactGuid]);
  
    useEffect(() => {
          if (state?.vendorAddressGuid)
            setVendorAddressGuild(state?.vendorAddressGuid);
    }, [state?.vendorAddressGuid]);


    const loadVendors = () => {

        let api: VendorApi = prApiFactory.create(VendorApi);
        if (context?.loggedOnUser?.username && state?.companyCode && state?.requisitionType) {
            setVendorLoadig(true);
            api.vendorVendorListGet(context?.loggedOnUser?.username, state?.companyCode, state?.requisitionType === "Service" ? "service" : "material").then(async (vendorData) => {
                var vendorDataFromApi = await vendorData.json();
                const vendorList = vendorDataFromApi.value.map((v) => {
                    const textVal = v.isIntegrated ? v.vendorName + " (" + v.vendorCode + ")" : v.vendorName + "( Not Integrated )";
                    return {
                        text: textVal,
                        value: v.vendorCode,
                    };
                });

                let filteredList = vendorList;

                //if (state?.requisitionType === "Material")
                //    filteredList = vendorList.filter(v => v.value?.endsWith("4"));
                //else if (state?.requisitionType === "Service")
                //    filteredList = vendorList.filter(v => v.value?.endsWith("3"));
                //It is important to set Request New Vendor value to blank 
                // to check if we are actually requesting a new vendor
                // we are not expecting the vendor code to come
                //out as blank in api call.
                setVendors([
                    { text: "Request New Vendor", value: "" },
                    ...filteredList,
                ]);
            }).finally(() => setVendorLoadig(false));
        } else {
            setVendors([]);
            dispatch({
                type: EnPrAction.VENDOR_CODE_CHANGE,
                payload: undefined,
            })
        }

    }
       
    const loadVendorDetail = () => {          
              if (state?.vendorCode && state?.vendorCode !=="") {
                        const vendorCode = state?.vendorCode;
            let api: VendorApi = prApiFactory.create(VendorApi);
            //TODO: CHeck for error condition
            PrApiService.getVendorDetails(context, api, vendorCode, state?.companyCode).then(
                (vendorDetails) => {
                    let v: any = vendorDetails;
                    //setSelectedVendor might no longer be necessary
                    //TODO: Perhaps deprecate this.
                    setSelectedVendor(vendorDetails);
                    setShowAddressDetails(true);

                   
                    dispatch({
                        type: EnPrAction.SET_VENDOR,
                        payload: vendorDetails
                    })
                    
                }
            );
        }
        else {
            setSelectedVendor(null);

        }
    }

    const onSelectedVenorChange = (e) => {
        

        setShowAddressDetails(true);
        setaddressContactGuid(undefined);
        setVendorAddressGuild(undefined);
        if (e?.value && e.value.value === "") {
          
          setSelectedVendor(undefined)
            dispatch({
                type: EnPrAction.VENDOR_CODE_CHANGE,
                payload: e.value ? e.value.value : undefined,
            })
        }
         else {

            dispatch({
                type: EnPrAction.VENDOR_CODE_CHANGE,
                payload: e.value ? e.value.value : undefined,
            })
        }

    }

    const getTextOrEmpty = (list, currentValue) => {
        const listItem = list.find(l => l.value === currentValue)
        if (listItem)
            return listItem.text;
        else
            return null;
    }


    return (
      <>
        <APIFactory
          ref={(e) => {
            prApiFactory = e;
          }}
        />

        <Row>
          <>
            <RequiredFormField label="Recommended Vendor">
              {state?.prTypeDetail === EnPrTypeDetail.PO_CHANGE_REQUEST_BY_PR && (
                <FormLabelMutedText
                  value={getTextOrEmpty(vendors, state?.vendorCode)}
                />
              )}
              {state?.prTypeDetail !== EnPrTypeDetail.PO_CHANGE_REQUEST_BY_PR && (
                <XtField
                  type="dropdown"
                  name="vendorCode"
                  items={vendors}
                  value={
                    vendors.find((v) => v.value === state?.vendorCode) || null
                  }
                  onChange={onSelectedVenorChange}
                  textField="text"
                  required={true}
                  errormessage="Vendor is required"
                  loading={vendorLoading}
                />
              )}
            </RequiredFormField>
          </>
        </Row>
            {showAddressDetails && <NwVendorAddressDetails
                data={selectedVendor}
                vendorCode={state?.vendorCode}
                vendorAddressGuid={vendorAddressGuid}
                addressContactGuid={addressContactGuid}
                isFromIntegrationPage={false}
                
            ></NwVendorAddressDetails>}
      </>
    );
}
