import { createContext } from "react";

export interface PaginationState {
	skip: number;
	top: number;
	sort: {
		field: string;
		dir: "asc" | "desc";
	};
}

export const initialState: Record<string, PaginationState> = {
	pr: {
		skip: 0,
		top: 15,
		sort: {
			field: "createdOn",
			dir: "desc",
		},
	},
	po: {
		skip: 0,
		top: 15,
		sort: {
			field: "createdOn",
			dir: "desc",
		},
	},
	invoice: {
		skip: 0,
		top: 15,
		sort: {
			field: "createdOn",
			dir: "desc",
		},
	},
	prr: {
		skip: 0,
		top: 15,
		sort: {
			field: "createdOn",
			dir: "desc",
		},
	},
};

export type Action = {
	type: "UPDATE_PAGINATION";
	entity: string;
	payload: PaginationState;
};

interface PaginationContextType {
	paginationState: Record<string, PaginationState>;
	updatePagination: (
		entity: string,
		newPaginationState: PaginationState,
	) => void;
}

export const PaginationContext = createContext<
	PaginationContextType | undefined
>(undefined);
