import { Button } from "react-bootstrap";
import { EnApprovalMode } from "../../Interfaces/Enums";

interface IReviseButtonProps {
  onApprovalButtonClick: (approvalMode: EnApprovalMode) => void;
}

export default function ReviseButton(props: IReviseButtonProps) {
  const { onApprovalButtonClick } = props;
  return (
    <Button
      type="button"
      name="btnlist"
      variant="warning"
      onClick={() => {
        onApprovalButtonClick(EnApprovalMode.Revise);
      }}
    >
      Revise
    </Button>
  );
}
