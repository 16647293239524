import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useContext, useState } from "react";
import { XtForm, XtField } from "nwcommon";
import { Button, Row } from "react-bootstrap";
import FormField from "../Common/FormField";
import {
	APIFactory,
	PersonnelRequisition,
	PersonnelRequisitionApi,
	PurchaseRequisition,
	PurchaseRequisitionApi,
} from "../../../../../Xt/ApiClients";
import { Loading } from "../../../../../Xt/Layout/Loading";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { useQueryClient } from "@tanstack/react-query";

export enum EnApprovalMode {
  Approve = "Approve",
  Reject = "Reject",
  Revise = "Revise",
  NotSet = "NotSet",
  Cancel = "Cancel",
  Comment = "Comment",
}

interface ApproveRejectReviseDialogProps {
  approvalMode: EnApprovalMode;
  onPrimaryClick: any;
  onSecondaryClick: any;
  prGuid?: string;
  prNumber?: string;
  prrGuid?: string;
  prrNumber?: string;
  currentLevel: number;
  pr?: PurchaseRequisition;
  prr?: PersonnelRequisition;
  setAuditLogComment?: any;
}

const submittingLabel = {
	[EnApprovalMode.Approve]: "Approving...",
	[EnApprovalMode.Reject]: "Rejecting...",
	[EnApprovalMode.Revise]: "Revising...",
	[EnApprovalMode.Cancel]: "Cancelling...",
};

export default function ApproveRejectReviseDialog(
	props: ApproveRejectReviseDialogProps,
) {
	let factory: APIFactory;
	const [loading, setLoading] = useState(false);

	const context: IXtContext = useContext(XtContext);
	const token = context?.tokens?.accessToken;
	const username = context?.loggedOnUser?.username;

	const { approvalMode, prGuid, prrGuid, currentLevel } = props;

	const isCancel = approvalMode === EnApprovalMode.Cancel;
	const isRequired = approvalMode !== EnApprovalMode.Approve;

	const queryClient = useQueryClient();

	const onSubmit = async (e) => {
		setLoading(true);
		let result;
		const comments = e.postData.comments;
		const purchaseRequisition = props?.pr;

		if (props.prrGuid || props.prrNumber) {
			let api: PersonnelRequisitionApi = factory.create(
				PersonnelRequisitionApi,
			);
			try {
				switch (approvalMode) {
					case EnApprovalMode.Approve:
						result = await api.personnelRequisitionApproveKeyPost(
							context?.loggedOnUser?.username,
							prrGuid,
							comments,
						);
						break;
					case EnApprovalMode.Reject:
						result = await api.personnelRequisitionRejectKeyPost(
							context?.loggedOnUser?.username,
							prrGuid,
							comments,
						);
						break;
					case EnApprovalMode.Revise:
						result = await api.personnelRequisitionReviseKeyPost(
							context?.loggedOnUser?.username,
							prrGuid,
							comments,
						);
						break;
					case EnApprovalMode.Cancel:
						result = await api.personnelRequisitionCancelPost(
							context?.loggedOnUser?.username,
							prrGuid,
							comments,
						);
				}
			} catch (e) {
				result = null;
			}
			setLoading(false);
			if (result?.ok) {
				const listModesToInvalidate = ["MyActivePRR", "MyPRR", "AllPRR"];

				queryClient.invalidateQueries({
					predicate: (query) => {
						return (
							query.queryKey[0] === "prrList" &&
							listModesToInvalidate.includes(query.queryKey[3] as string)
						);
					},
				});

				queryClient.invalidateQueries({
					queryKey: ["approvalFlow", prrGuid, token, username],
				});

				queryClient.invalidateQueries({
					queryKey: ["getPrrByGuid", prrGuid, token, username],
				});

				queryClient.invalidateQueries({
					queryKey: ["auditLog", prrGuid, token, username],
				});

				props.onPrimaryClick();
			} else {
				setLoading(false);
				let data = await result.json();
				let message = "";
				for (const e in data.errors) {
					const errorData = data.errors[e];
					message = message + errorData[0] + ";";
				}
				factory.showErrorMessage(message, data.title);
			}
		} else if (props.prGuid || props.prNumber) {
			let api: PurchaseRequisitionApi = factory.create(PurchaseRequisitionApi);

      switch (approvalMode) {
        case EnApprovalMode.Comment:
          result = await factory.callAPI(
            api.purchaseRequisitionAddCommentPrGuidPost(
              prGuid,
              context?.loggedOnUser?.username,
              { comments: comments }
            )
          );
          let res = await result.json();
          props?.setAuditLogComment([res.logList]);
          break;
        case EnApprovalMode.Approve:
          result = await factory.callAPI(
            api.purchaseRequisitionApproveKeyPost(
              context?.loggedOnUser?.username,
              prGuid,
              comments,
              currentLevel
            )
          );
          break;
        case EnApprovalMode.Reject:
          result = await factory.callAPI(
            api.purchaseRequisitionRejectKeyPost(
              context?.loggedOnUser?.username,
              prGuid,
              comments
            )
          );
          break;
        case EnApprovalMode.Revise:
          purchaseRequisition.comments = comments;
          result = await factory.callAPI(
            api.purchaseRequisitionReviseKeyPost(
              context?.loggedOnUser?.username,
              purchaseRequisition,
              prGuid
            )
          );
      }
      setLoading(false);
      if (result && result.ok) {
        props.onPrimaryClick();
      } else {
        setLoading(false);
        let data = await result.json();
        let message = "";
        for (const e in data.errors) {
          const errorData = data.errors[e];
          message = message + errorData[0] + ";";
        }
        factory.showErrorMessage(message, data.title);
      }
    } else {
      return;
    }
  };

	return (
		<>
			<APIFactory
				ref={(e) => {
					factory = e;
				}}
			/>

			<Dialog
				title={
					approvalMode + " - " + props.prNumber
						? props.prNumber
						: props.prrNumber
				}
				onClose={props.onSecondaryClick}
				width={"60%"}
			>
				<XtForm onSubmit={onSubmit}>
					{isCancel && (
						<div className="d-flex justify-content-center">
							<p>Do you really want to cancel this request?</p>
						</div>
					)}

					<Row>
						<FormField label="Comments">
							<XtField
								name="comments"
								type="textarea"
								required={isRequired}
								errormessage={"A comment is required"}
							/>
						</FormField>
					</Row>

					{!isCancel && (
						<DialogActionsBar layout="end">
							<Button
								type="button"
								variant="secondary"
								onClick={props.onSecondaryClick}
							>
								Close
							</Button>
							<Button type="submit" variant="primary" disabled={loading}>
								{loading ? submittingLabel[approvalMode] : approvalMode}
							</Button>
						</DialogActionsBar>
					)}

					{isCancel && (
						<DialogActionsBar layout="end">
							<Button
								type="button"
								variant="secondary"
								onClick={props.onSecondaryClick}
								className="min-w-4"
							>
								No
							</Button>
							<Button type="submit" variant="primary" className="min-w-4">
								Yes
							</Button>
						</DialogActionsBar>
					)}
				</XtForm>
			</Dialog>

			{loading && <Loading />}
		</>
	);
}
