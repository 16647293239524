import React from 'react';
import { XtSocialLinkItemProps } from '../../Props/XtSocialLinkItemProps';

export default function XtSocialLinkItem(props: XtSocialLinkItemProps) {
    return (<div>
        <a href={props.menuUrl} data-tooltip= {props.menuDescription}  title={props.menuText} target="_blank">

        <img src={props.iconFullPath}/>
            
        </a>
    </div>);
}
