import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom'
import { OktaConfig } from "../Configurations/config"

export function XtLogout(props){
	const { authState, oktaAuth } = useOktaAuth();
	sessionStorage.clear()
	oktaAuth.signOut();
	return (<div></div>)
}