import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  APIFactory,
  PersonnelRequisitionApi,
} from "../../../../../Xt/ApiClients";
import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { AdvSearchAndGridUtil } from "../../../../../Xt/Utils/AdvanceSearchAndGridUtil";
import AddRerviewerDailog from "../../PurchaseRequisition/ApprovalView/AddRerviewerDailog";
import PopOverGridCell from "../../PurchaseRequisition/Common/GridCell/PopOverGridCell";
import PrCompletedGridCell from "../../PurchaseRequisition/Common/GridCell/PrCompletedGridCell";
import NwWorkflowReviewerCell from "./WorkFlowReviewerCell";
import { ApprovalFlowLog } from "../Interfaces/API";
import { getApprovalFlowByGuid } from "../../../../../api/react-query";

interface WorkflowReadOnlyProps {
  prrGuid: string;
  isApprover: boolean;
  designatorList?: any[];
}

export default function WorkflowReadOnly(props: WorkflowReadOnlyProps) {
  let factory: APIFactory;
  const [workflowData, setWorkflowData] = useState([]);
  const [showAddReviewerDialog, setShowReviewerDialog] = useState(false);
  const [additionalReviewer, setAdditionalReviewer] = useState(null);

  const context: IXtContext = useContext(XtContext);

  const queryClient = useQueryClient();

  const token = context?.tokens?.accessToken;
  const username = context?.loggedOnUser?.username;
  const displayName = context?.loggedOnUser?.displayname;
  const { prrGuid, designatorList } = props;

  const { data: approvalFlowData } = useQuery(
    ["approvalFlow", prrGuid, token, username],
    getApprovalFlowByGuid,
    {
      enabled: !!prrGuid && !!username && !!designatorList,
      placeholderData: [],
    }
  );

  useEffect(() => {
    if (
      !approvalFlowData ||
      approvalFlowData.length === 0 ||
      !displayName ||
      !designatorList
    )
      return;

    const uniqueData = approvalFlowData.reduce((acc, current) => {
      if (!acc.find((x) => x.userGuid === current.userGuid)) {
        acc.push(current);
      }

      return acc;
    }, []);

    const wfData = uniqueData.map((workFlow: ApprovalFlowLog) => {
      const currentUserIsDesignee = designatorList?.find(
        (d) => d.displayname === workFlow.displayName
      );
      let role = workFlow.role;
      if (currentUserIsDesignee)
        return {
          ...workFlow,
          designee: displayName,
          role: role,
        };
      else return { ...workFlow, designee: "", role: role };
    });

    setWorkflowData(wfData);
  }, [approvalFlowData, displayName, designatorList]);

  const onAddReviewerIconClick = (e) => {
    const additionalReviewerLevel = {
      role: e?.dataItem?.role,
      level: e?.dataItem?.level,
      displayName: e?.dataItem?.displayName,
    };
    setAdditionalReviewer(additionalReviewerLevel);
    setShowReviewerDialog(true);
  };

  const onAddReviewerButtonClickFromDialog = async (e) => {
    if (e.data && e.data.selectedUser && e.data.selectedRole) {
      let prrApi: PersonnelRequisitionApi = factory.create(
        PersonnelRequisitionApi
      );

      let result = await factory.callAPI(
        prrApi.personnelRequisitionApproverPost(
          context?.loggedOnUser?.username,
          props?.prrGuid,
          e.data.selectedUser.userGuid
        )
      );
      if (result.ok) {
        queryClient.invalidateQueries({
          queryKey: ["approvalFlow"],
        });
        setShowReviewerDialog(false);
      } else {
        let data = await result.json();
        let message = "";
        for (const e in data.errors) {
          const errorData = data.errors[e];
          message = message + errorData[0] + ";";
        }
        factory.showErrorMessage(message, data.title);
      }
    }
  };

  const reviewCell = (reviewProps) => (
    <NwWorkflowReviewerCell
      {...reviewProps.dataItem}
      onAddReviewerClick={onAddReviewerIconClick}
      isApprover={props.isApprover}
    />
  );

  const approval_workflow_schema = [
    {
      field: "stage",
      label: "Stage",
      visible: true,
      type: "number",
      width: "60px",
    },
    {
      field: "displayName",
      label: "Reviewer",
      visible: true,
      searchable: true,
      type: "text",
    },
    {
      field: "role",
      label: "Reviewer Role",
      visible: true,
      searchable: true,
      type: "text",
      width: "200",
    },

    {
      field: "status",
      label: "Status",
      visible: true,
      searchable: true,
      type: "text",
      cell: PrCompletedGridCell,
    },

    {
      field: "comment",
      label: "Comment",
      visible: true,
      searchable: true,
      type: "text",
      cell: PopOverGridCell,
    },
  ];

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <Row className="mb-3">
        <Col sm={12}>
          <Grid data={workflowData} className="k-grid-reviewer-list">
            <GridColumn
              field="addReviewer"
              title=" "
              cell={reviewCell}
              width="50px"
            />

            {AdvSearchAndGridUtil.renderGridFields(approval_workflow_schema)}
          </Grid>
        </Col>
      </Row>
      {showAddReviewerDialog && (
        <AddRerviewerDailog
          additionalReviewer={additionalReviewer}
          onClose={(e) => setShowReviewerDialog(false)}
          onAddClick={onAddReviewerButtonClickFromDialog}
        />
      )}
    </>
  );
}
