import { Col, Form, Row } from "react-bootstrap";
import { XtField } from "nwcommon";
import { EnPrAction } from "../Common/PrReducer";


const countryList = [{
    text: "United States of America",
    value: "USA"
},
    {
        text: "Canada",
        value: "CA"
    }
]

export default function NwShippingPayment(props) {
    const { state, dispatch } = props;
    return (
        <Row>
            <Col md={6}>
                <Row>
                    <Form.Label column md={4}>
                        Ship to Address
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Col md={8}>
                        <div className="mb-3">
                            <XtField
                                type="text"
                                placeholder="Address 1"
                                name="primaryAddress"
                                required={true}
                                errormessage="Address 1 is required"
                                defaultValue={state?.primaryAddress}
                            />
                        </div>
                        <div className="mb-3">
                            <XtField
                                type="text"
                                placeholder="Address 2"
                                name="secondaryAddress"
                                defaultValue={state?.secondaryAddress}
                            />
                        </div>

                        <Row className="mb-3 g-3">
                            <Col>
                            <XtField type="text" placeholder="City" name="city" defaultValue={state?.city} 
                            required={true}
                            errormessage="City is required" />
                            </Col>
                            <Col>
                            <XtField type="text" placeholder="State" name="state" defaultValue={state?.state} maxLength="2" required={true}
                                errormessage="State is required" />
                            </Col>
                        </Row>

                       

                        <Row className="g-3">
                           
                            <Col>
                                <XtField type="text" placeholder="Zip code" name="zipCode" defaultValue={state?.zipCode}  required={true}
                                errormessage="Zip code is required"/>
                            </Col>
                            <Col>
                            <XtField type="dropdown"
                                            placeholder="Country"
                                            name="countryCode"
                                            items={countryList}
                                            defaultValue= {countryList[0]}
                                            textField="text"
                                            required={true}
                                            value={countryList.find(c=> c.value==state?.countryCode)||null}
                                            key={"create-new-country-code"}
                                            errormessage="Country is required"

                                        />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col md={6}>
                <Row className="mt-2 mb-1">
                    <Form.Label column md={4}>
                        Payment Type
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Col md={8}>
                        <XtField
                            name="paymentType"
                            type="radiogroup"
                            layout="horizontal"
                            items={[
                                { text: "Credit Card", value: "Credit Card" },
                                { text: "Check", value: "Check" },
                            ]}
                            value={state?.paymentType}
                            onChange={(e) => { dispatch({ type: EnPrAction.PAYMENT_TYPE_CHANGE, payload: e.value }) }}
                            required={true}
                            errormessage="Payment type is required"
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Form.Label column md={4}>
                        Special Payment Terms
                    </Form.Label>
                    <Col md={8}>
                        <XtField type="textarea" name="specialPaymentTerm" defaultValue={state?.specialPaymentTerm} />
                    </Col>
                </Row>

                
                <Row>
                    <Form.Label column md={4}>
                        Special Handling Notes
                    </Form.Label>
                    <Col md={8}>
                        <XtField type="textarea" name="specialNote" defaultValue={state?.specialNote} />
                    </Col>
                </Row>
            </Col>

        </Row>
    );
}
