import React, { useEffect, useState } from 'react';
import { XtField } from "nwcommon"
import {
    Grid,
    GridColumn,
} from "@progress/kendo-react-grid";
import { InvoiceCoding } from '../../../../Xt/ApiClients';
import { APIFactoryCommon, ResourceAttributeApi } from '../../../../Xt/ApiClients/Common';
import { Loading } from '../../../../Xt/Layout/Loading';
import InvoiceAmountGridCell, { InvoiceTextGridCell } from '../../Nw/PurchaseRequisition/Common/GridCell/InvoiceAmountGridCell';
import RightAlignGridColumnHeader from '../../Nw/PurchaseRequisition/Common/GridCell/RightAlignGridColumnHeader';
import AmountGridCell from '../../Nw/PurchaseRequisition/Common/GridCell/AmountGridCell';
import { NumberUtil } from '../../../../Xt/Utils/NumberUtil';

export type InvoiceCPIntegrationInvoiceCodingProps = {
    orgId?: string,
    data?: InvoiceCoding[],
    accounts?: any[],
    onAccountChange?: any,
    onInvoiceLineItemChange?: any
    onAccountLoad?: any
    invCodeRunningTotals: any
}


export function InvoiceCPIntegrationInvoiceCoding(props: InvoiceCPIntegrationInvoiceCodingProps) {
    const [data, setData] = React.useState<InvoiceCoding[]>([]);
    const [accounts, setAccounts] = React.useState<any[]>([]);

    React.useEffect(() => {
        if (props.accounts && props.accounts.length > 0) {
            setAccounts(props.accounts)
        }
    }, [props.accounts])

    React.useEffect(() => {
        setData(props.data)
    }, [props.data])

    const getAccountKey = (dataItem) => {
        const orgArray = dataItem?.orgId?.split(".");
        const companyCode = orgArray.length > 0 ? orgArray[0] : undefined;
        const accountGroupCode = dataItem?.accountGroupCode; //TODO: Gautam populate accountgroup code 
        return `${companyCode}-${accountGroupCode}-${dataItem?.accountType}-${dataItem?.orgId}`;
    }

    return (<>
        <Grid
            data={data}
            total={0}
            className="k-grid-pr-list"
            editField="inEdit"
            onItemChange={(e) => {
                props.onInvoiceLineItemChange(e, e.dataItem)
            }}
        >
            <GridColumn title="#" field="poLineNumber" width="35px" cell={(e) => {
                e.dataItem.inEdit = true;
                return (<td>{e.dataItem.poLineNumber}</td>)
            }} />
            <GridColumn title="Charge Code" field="projectNumber" width="250px" cell={(e) => {
                let value = e.dataItem[e.field] + " - " + e.dataItem?.accountName
                return (<td>{value}</td>)
            }} />
            {/*<GridColumn title="Org ID" field="orgId" cell={(e) => {*/}
            {/*    return (<td>{ e.dataItem.orgId }</td>)*/}
            {/*}} />*/}
            <GridColumn title="Description" field="poLineDescription" editable={false} />
            <GridColumn title="Invoice Line Description" field="description" editable={true} cell={InvoiceTextGridCell} width="200px" />

            <GridColumn title="Account #" width={400} field="accountNumber" cell={(e) => {
                return AccountNumberField({
                    poLineNumber: e.dataItem?.poLineNumber,
                    orgId: e.dataItem?.orgId,
                    accountType: e.dataItem?.accountType,
                    accountGroupCode: e.dataItem?.accountGroupCode,
                    accountNumber: e.dataItem?.accountNumber,
                    onAccountChange: props.onAccountChange,
                    onAccountLoad: props.onAccountLoad,
                    accounts: props.accounts,
                });
            }} />
            <GridColumn title="Invoice Coding Amount" field="amount" editable={true} cell={InvoiceAmountGridCell} />
            <GridColumn title="Committed Amount" field="committedAmount" headerCell={RightAlignGridColumnHeader} editable={false} cell={AmountGridCell} />
            <GridColumn title="Cost to Date" field="costToDateAmount" headerCell={RightAlignGridColumnHeader} cell={AmountGridCell} />
            <GridColumn title="Running Total" field="runningTotal" headerCell={RightAlignGridColumnHeader} cell={(e) => {
                return <td style={{ textAlign: "right" }}>{NumberUtil.formatToTwoDecimalWithComma(props.invCodeRunningTotals[e.dataIndex] ?? e.dataItem.runningTotal)}</td>
            }} />
            <GridColumn title="Remaining Amount" field="remainingAmount" headerCell={RightAlignGridColumnHeader} cell={(e) => {
                let remainingAmount = e.dataItem.committedAmount - (props.invCodeRunningTotals[e.dataIndex] ?? e.dataItem.runningTotal) || 0;
                return <td style={{ textAlign: "right" }}><span className={remainingAmount < 0 ? 'badge bg-danger' : ''}>{NumberUtil.formatToTwoDecimalWithComma(remainingAmount ?? e.dataItem.remainingAmount)}</span></td>
            }} />
        </Grid>

    </>)
}


const getAccountKey = (companyCode: string, accountGroupCode: string, accountType: string, orgId: string) => {
    return `${companyCode}-${accountGroupCode}-${accountType}-${orgId}`;
}


export interface AccountNumberFieldProps {
    poLineNumber: number,
    accountType?: string,
    orgId?: string,
    accountGroupCode?: string,
    accountNumber?: string,
    onAccountLoad: any,
    onAccountChange: any,
    accounts?: any,
}

function AccountNumberField(props: AccountNumberFieldProps) {
    let commonApifactory: APIFactoryCommon
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currentAccounts, setCurrentAccounts] = useState([]);

    const loadAccount = (companyCode: string, accountGroupCode: string, accountType: string, orgId: string) => {
        if (companyCode && accountGroupCode && accountType && orgId) {

            let commonApi: ResourceAttributeApi = commonApifactory.create(ResourceAttributeApi);
            commonApi.resourceAttributeAccountCodeByFilterGet(companyCode, accountGroupCode, accountType, orgId).then(async (acCode) => {
                const accountKey = getAccountKey(companyCode, accountGroupCode, accountType, orgId);
                var typeDataFromApi = await acCode.json();
                const codeList = typeDataFromApi.value.map((v) => {
                    return {
                        text: v.accT_ID + ' - ' + v.accT_NAME,
                        value: v.accT_ID
                    };
                });
                setCurrentAccounts(codeList);
                props.onAccountLoad(codeList, accountKey);
            }).finally(() => { })
        }
    }

    const getCompanyCode = (d) => {
        const orgArray = d?.orgId?.split(".");
        return orgArray?.length > 0 ? orgArray[0] : undefined;
    };


    const companyCode = getCompanyCode(props);
    const accountGroupCode = props?.accountGroupCode;


    useEffect(() => {
        const accountKey = getAccountKey(companyCode, accountGroupCode, props?.accountType, props?.orgId)
        if (
            props?.accountType
            && props?.orgId
            && companyCode
            && accountGroupCode
            && companyCode != ""
            && accountGroupCode != ""
            && props.accountType != ""
        ) {
            if (props?.accounts && props?.accounts[accountKey])
                setCurrentAccounts(props.accounts[accountKey])
            else
                loadAccount(companyCode, accountGroupCode, props?.accountType, props?.orgId)
        }
    }, [props?.accountType, companyCode, accountGroupCode, props?.orgId])




    return (
        <td>
            <APIFactoryCommon
                ref={(e) => {
                    commonApifactory = e;
                }}
            />
            <XtField
                type="dropdown"
                items={currentAccounts}
                textField="text"
                value={
                    currentAccounts.find(
                        (c) => c.value === props?.accountNumber
                    ) || {
                        text: props?.accountNumber,
                        value: props?.accountNumber,
                    }
                }
                onChange={(e) => {
                    props.onAccountChange(e?.value?.value, props?.poLineNumber);
                }}
            />
            {loading && <Loading />}
        </td>
    );
}