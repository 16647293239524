import { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { XtField } from "nwcommon";
import { APIFactory, InvoiceApi, UserApi } from "../../../../Xt/ApiClients";
import FormLabelMutedText from "../../Nw/PurchaseRequisition/Common/FormLabelMutedText";
import FormField, {
  RequiredFormField,
} from "../../Nw/PurchaseRequisition/Common/FormField";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import { InvoiceApiService } from "../Common/InvoiceApiService";
import FileUpload from "../../../ui/FileUpload";
import { NumericTextBox } from "@progress/kendo-react-inputs";

export type AddInvoiceHeaderProps = {
  purchaseOrder?: any;
  onPOChange: any;
  loadAccountSpecificUsers: boolean;
};

type VendorAddress = {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  state: string;
  zip: string;
};

export function AddInvoiceHeader(props: AddInvoiceHeaderProps) {
  let factory: APIFactory;
  const context: IXtContext = useContext(XtContext);

  const [prNumbers, setPRNumbers] = useState<any[]>([]);
  const [po, setPO] = useState<any>(null);
  const [vendorInvoiceNumber, setVendorInvoiceNumber] = useState<string | null>(
    null
  );
  const [vendorAddress, setVendorAddress] = useState<VendorAddress | undefined>(
    undefined
  );
  const [invoiceAmount, setInvoiceAmount] = useState<string>("");
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(null);
  const [description, setDescription] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [selectedPONumber, setSelectedPONumber] = useState<any>(null);
  const [selectedAccountRep, setSelectedAccountRep] = useState<any>(null);

  const [reps, setReps] = useState<any[]>([]);

  const loadPrNumber = () => {
    let c: InvoiceApi = factory.create(InvoiceApi);
    c.invoicePoNumbersGet(context?.loggedOnUser?.username).then(
      async (data) => {
        let d = [];
        for (let i in data) {
          if (data[i] !== "") d.push({ text: data[i], value: data[i] });
        }
        setPRNumbers(d);
      }
    );
  };

  const loadReps = () => {
    let userApi: UserApi = factory.create(UserApi);

    InvoiceApiService.getReps(
      userApi,
      context?.loggedOnUser?.username,
      props.loadAccountSpecificUsers
    ).then((data) => {
      setReps(data);
    });
  };

  useEffect(() => {
    if (context?.loggedOnUser?.username) {
      loadPrNumber();
    }
  }, [context?.loggedOnUser?.username]);

  useEffect(() => {
    if (
      context?.loggedOnUser?.username &&
      props.loadAccountSpecificUsers != null
    ) {
      loadReps();
    }
  }, [context?.loggedOnUser?.username, props.loadAccountSpecificUsers]);

  useEffect(() => {
    if (
      !context ||
      !context?.loggedOnUser?.username ||
      !props.loadAccountSpecificUsers ||
      !po?.projectNumber ||
      !reps
    )
      return;

    async function getAccountRepByProjectNumber() {
      const res = await fetch(
        // `https://localhost:44319/Project/AccountRep/${po?.projectNumber}`,
        context?.commonBasePath + `/Project/AccountRep/${po?.projectNumber}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context?.tokens.accessToken}`,
          },
        }
      );
      if (res.status !== 200) return;

      const data = await res.json();
      const accountRep = reps.find(
        (x) => x.value === data?.accountRep.userGuid
      );

      setSelectedAccountRep(accountRep);
    }

    getAccountRepByProjectNumber();
  }, [
    context,
    context?.loggedOnUser?.username,
    context?.commonBasePath,
    context?.tokens.accessToken,
    props.loadAccountSpecificUsers,
    po?.projectNumber,
    reps,
  ]);

  useEffect(() => {
    if (props.purchaseOrder) {
      setPO(props.purchaseOrder);
      setSelectedPONumber({
        text: props.purchaseOrder.poNumber,
        value: props.purchaseOrder.poNumber,
      });
      setVendorAddress({
        addressLine1: props.purchaseOrder.addressLine1,
        addressLine2: props.purchaseOrder.addressLine2,
        addressLine3: props.purchaseOrder.addressLine3,
        city: props.purchaseOrder.city,
        state: props.purchaseOrder.state,
        zip: props.purchaseOrder.zip,
      });
    } else {
      setPO(null);
      setSelectedPONumber(null);
      setSelectedAccountRep(null);
      setVendorAddress(undefined);
      setVendorInvoiceNumber("");
      setInvoiceAmount("");
      setInvoiceDate(null);
      setDescription("");
      setComments("");
    }
  }, [props.purchaseOrder]);

  const onPoChange = (e) => {
    if (!e?.value) setSelectedPONumber(null);
      if (props?.onPOChange) {
          props?.onPOChange(e);
          setSelectedAccountRep(null);
      }
  };

  const onAssignedRepChange = (e) => {
    if (!e?.value) setSelectedAccountRep(null);
    setSelectedAccountRep(e.value);
  };

  const getVendorAddress = () => {
    if (!vendorAddress) return "";

    let address = "";
    if (vendorAddress.addressLine1) address += vendorAddress.addressLine1;
    if (vendorAddress.addressLine2)
      address += ", " + vendorAddress.addressLine2;
    if (vendorAddress.addressLine3)
      address += ", " + vendorAddress.addressLine3;
    if (vendorAddress.city) address += ", " + vendorAddress.city;
    if (vendorAddress.state) address += ", " + vendorAddress.state;
    if (vendorAddress.zip) address += ", " + vendorAddress.zip;
    return address;
  };

  return (
    <>
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <Row>
        <RequiredFormField label="PO Number">
          <XtField
            type="dropdown"
            name="PONumber"
            required={true}
            items={prNumbers}
            textField="text"
            errormessage="Purchase Order Number is required."
            value={selectedPONumber}
            onChange={onPoChange}
          />
        </RequiredFormField>

        {po?.projectNumber !== "" && (
          <FormField label="Project Number">
            <FormLabelMutedText value={po?.projectNumber} />
          </FormField>
        )}

        {po?.projectNumber === "" && (
          <FormField label="Charge Type">
            <FormLabelMutedText value="Indirect" />
          </FormField>
        )}

        <FormField label="Company Name">
          <FormLabelMutedText value={po?.companyName} />
          <input type="hidden" name="CompanyCode" value={po?.companyCode} />
        </FormField>

        <FormField label="Vendor Name">
          <FormLabelMutedText
            value={po?.vendorName && `${po?.vendorName} (${po?.vendorID})`}
          />
          <input type="hidden" name="VendorCode" value={po?.vendorID} />
        </FormField>

        <RequiredFormField label="Vendor Invoice #">
          <XtField
            type="text"
            name="VendorInvoiceNo"
            required={true}
            errormessage="Invoice Number is required."
            maxLength={15}
            value={vendorInvoiceNumber}
            onChange={(e) => setVendorInvoiceNumber(e.target.value)}
          />
        </RequiredFormField>

        <FormField label="Vendor Address">
          <FormLabelMutedText value={getVendorAddress()} />
        </FormField>

        <RequiredFormField label="Invoice Date">
          <XtField
            type="datepicker"
            name="InvoiceDate"
            weekNumber={false}
            required={true}
            errormessage="Invoice Date is required."
            value={invoiceDate}
            onChange={(e) => setInvoiceDate(e.target.value)}
          />
        </RequiredFormField>

        <RequiredFormField label="Invoice Amount">
          {/* <XtField
            type="amount"
            name="VendorAmount"
            required={true}
            errormessage="Please enter invoice amount."
            value={invoiceAmount}
            onChange={(e) => setInvoiceAmount(e.target.value)}
          /> */}
          <NumericTextBox
            spinners={false}
            name="VendorAmount"
            value={Number(invoiceAmount)}
            onChange={(e) => setInvoiceAmount(String(e.target.value))}
            format="c2"
          />
        </RequiredFormField>

        <RequiredFormField label="Assigned Rep.">
          <XtField
            type="dropdown"
            name="Representative"
            items={reps}
            textField="text"
            required={true}
            errormessage="Please select a representative."
            onChange={onAssignedRepChange}
            value={selectedAccountRep}
          />
        </RequiredFormField>

        <FormField label="Description">
          <XtField
            type="textarea"
            name="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormField>

        <FormField label="Comments">
          <XtField
            type="textarea"
            name="Comment"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </FormField>

        <RequiredFormField label="Attachments">
          <FileUpload
            name="attachments"
            type="purchaseOrder"
            purchaseOrder={po}
          />
          {/* <XtField
            type="file"
            showtables={"true"}
            multiple={true}
            name="attachments"
            showdescription={false}
            //required={true}
            //errormessage="Attatchments are required ."
          /> */}
        </RequiredFormField>
      </Row>
    </>
  );
}
