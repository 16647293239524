import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GridToolbar } from "@progress/kendo-react-grid";
import * as Search from "nwcommon";
import {
  XtAdvancedSearchBox,
  XtAdvancedSearchChild,
  XtAdvancedSearchFieldCondition,
  XtExport,
  XtExportToolbar,
  XtGrid,
} from "nwcommon";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";
import {
  APIFactory,
  PersonnelRequisition,
  PersonnelRequisitionApi,
} from "../../../../Xt/ApiClients";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import { Loading } from "../../../../Xt/Layout/Loading";
import { AdvSearchAndGridUtil } from "../../../../Xt/Utils/AdvanceSearchAndGridUtil";
import DateGridCell from "../PurchaseRequisition/Common/GridCell/DateGridCell";
import EditMenuGridCell from "../PurchaseRequisition/Common/GridCell/EditMenuGridCell";
import NwPrStatusGridCell from "../PurchaseRequisition/Common/GridCell/NwPrStatusGridCell";
import {
  completedOption,
  employmentTypeOption,
  myActionFilter,
} from "./Interfaces/Constants";
import { useQuery } from "@tanstack/react-query";
import {
  getPersonnelRequisitionList,
  getPrrOptions,
} from "../../../../api/react-query";
import { MINUTE } from "../../../../utils/constants";
import { PaginationContext } from "../../../../Xt/Contexts/PaginationContext";
import { XtPaging } from "../../../../Xt/ApiClients/ApiHelper";

export function NwPersonnelRequisitionList() {
  let factory;
  const pageSize = 15;
  const orderByField = "createdOn";
  const paging: XtPaging = {
    skip: 0,
    top: pageSize,
    sort: { field: orderByField, dir: "desc" },
  };
  const redirectTo = { shouldRedirect: false, url: "" };
  const [redirect, setRedirect] = useState(redirectTo);
  const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
  const [searchFields, setSearchFields] = useState<
    XtAdvancedSearchFieldCondition[]
  >([]);
  const [selectedActionFilter, setSelectedActionFilter] = useState(
    sessionStorage.getItem("PRRPageShortcutHistory")
      ? JSON.parse(sessionStorage.getItem("PRRPageShortcutHistory"))
      : myActionFilter[0],
  );

  const xts = AdvSearchAndGridUtil.useXtGridSate(pageSize, orderByField);
  const context: IXtContext = useContext(XtContext);

  const { paginationState, updatePagination } = useContext(PaginationContext);
  const paginationEntity = "prr";

  const username = context?.loggedOnUser?.username;
  const token = context?.tokens?.accessToken;

  const {
    data: prrOptions,
    isLoading: isPrrOptionsLoading,
    isFetching: isPrrOptionsFetching,
  } = useQuery(["prrOptions", token, username], getPrrOptions, {
    enabled: !!token && !!username,
    staleTime: Infinity,
    cacheTime: 20 * MINUTE,
  });

  const {
    data: prrList,
    isLoading: isPrrListLoading,
    isFetching: isPrrListFetching,
  } = useQuery(
    [
      "prrList",
      token,
      username,
      selectedActionFilter?.value || "AllPRR",
      xts.searchParams?.searchString || "",
      xts.searchParams?.oDataQuery || "",
      paginationState[paginationEntity].top,
      paginationState[paginationEntity].skip,
      pageSize, //15 by default
      `prr.${paginationState[paginationEntity].sort.field} ${paginationState[paginationEntity].sort.dir}`,
    ],
    getPersonnelRequisitionList,
    {
      enabled:
        !!token &&
        !!username &&
        optionsLoaded &&
        !!selectedActionFilter &&
        !!prrOptions,
      staleTime: Infinity,
      cacheTime: 20 * MINUTE,
    },
  );

  const filteredShortcuts =
    (context.isSuperAdmin() || context.isHRAdmin() || context.isPRRReadAll()) &&
    !context.isInSwitchUserMode()
      ? myActionFilter
      : myActionFilter.filter((action) => action.value !== "AllPRR");

  const prrSchema = [
    //Visible fields
    {
      field: "prrNumber",
      label: "Personnel Req No.",
      visible: true,
      searchable: true,
      type: "text",
      width: "auto",
    },
    {
      field: "createdOn",
      label: "Date Created",
      visible: true,
      searchable: false,
      type: "text",
      cell: DateGridCell,
    },
    {
      field: "employingCompany",
      label: "Employing Company",
      visible: true,
      searchable: true,
      type: "dropdown",
      options: [],
    },
    {
      field: "jobTitle",
      label: "Job Title",
      visible: true,
      searchable: true,
      type: "text",
    },
    {
      field: "dateNeeded",
      label: "Date Needed",
      visible: true,
      searchable: false,
      type: "date",
      cell: DateGridCell,
    },
    {
      field: "projectName",
      label: "Project #",
      visible: true,
      searchable: true,
      type: "text",
    },
    {
      field: "employmentType",
      label: "Employment Type",
      visible: true,
      searchable: true,
      type: "dropdown",
      options: employmentTypeOption,
    },
    {
      field: "vacancies",
      label: "No. of Vacancies",
      visible: true,
      searchable: true,
      type: "text",
    },
    {
      field: "status",
      label: "Status",
      visible: true,
      searchable: true,
      type: "text",
      cell: NwPrStatusGridCell,
      width: "auto",
    },
    //Hidden fields
    {
      field: "createdOn",
      label: "Created On",
      visible: false,
      searchable: true,
      type: "date",
      cell: DateGridCell,
      width: "150px",
    },
    {
      field: "isEditable",
      label: "  ",
      visible: false,
      searchable: false,
      type: "text",
      cell: EditMenuGridCell,
      width: "150px",
    },
    {
      field: "isCompleted",
      label: "Completed",
      visible: false,
      searchable: true,
      type: "dropdown",
      options: completedOption,
    },
    {
      field: "locationList",
      label: "Location",
      visible: false,
      searchable: true,
      type: "dropdown",
      options: [],
    },
    {
      field: "requestor",
      label: "Requestor",
      visible: false,
      searchable: true,
      type: "dropdown",
      options: [],
    },
    {
      field: "locationList",
      label: "Location",
      visible: false,
      searchable: true,
      type: "dropdown",
      options: [],
    },
    {
      field: "requestor",
      label: "Requestor",
      visible: false,
      searchable: true,
      type: "dropdown",
      options: [],
    },
    {
      field: "region",
      label: "Region",
      visible: false,
      searchable: true,
      type: "dropdown",
      options: [],
    },
  ];

  useEffect(() => {
    if (!prrOptions) {
      return;
    }

    for (const field of prrSchema) {
      if (field.field.includes("location")) {
        field.options = prrOptions.Locations;
      }
      if (field.field.includes("companyCode")) {
        field.options = prrOptions.Companies;
      }
      if (field.field.includes("requestor")) {
        field.options = prrOptions.Users;
      }
      if (field.field.includes("region")) {
        field.options = prrOptions.Regions;
      }
    }

    let searchFields1: any[] = [];

    for (const field of prrSchema) {
      let searchValues = getAdvancedSearchSelectedValue(field);
      if (field.type === "text" && field.searchable === true) {
        searchFields1.push(
          Search.getTextboxType(
            "",
            field.field,
            field.label,
            searchValues?.value,
            searchValues?.operator.value,
            searchValues?.value2,
          ),
        );
      }
      if (field.type === "datepicker" && field.searchable === true) {
        searchFields1.push(
          Search.getDatePickerType(
            "",
            field.field,
            field.label,
            searchValues?.value,
            searchValues?.operator.value,
            searchValues?.value2,
          ),
        );
      } else if (field.type === "number" && field.searchable === true) {
        searchFields1.push(
          Search.getNumericType(
            "",
            field.field,
            field.label,
            searchValues?.value,
            searchValues?.operator.value,
            searchValues?.value2,
          ),
        );
      } else if (field.type === "boolean" && field.searchable === true) {
        searchFields1.push(
          Search.getBooleanType(
            "",
            field.field,
            field.label,
            [
              { text: "Yes", value: "true" },
              { text: "No", value: "false" },
            ],
            searchValues?.value,
            searchValues?.operator.value,
          ),
        );
      } else if (field.type === "dropdown" && field.searchable === true) {
        searchFields1.push(
          Search.getDropdownType(
            "",
            field.field,
            field.label,
            field.options,
            searchValues?.value,
            searchValues?.operator.value,
          ),
        );
      } else if (field.type === "multiselect" && field.searchable === true) {
        searchFields1.push(
          Search.getDropdownType(
            "",
            field.field,
            field.label,
            field.options,
            searchValues?.value,
            searchValues?.operator.value,
          ),
        );
      }
    }

    const searchHistory = sessionStorage.getItem("PRRPageSearchHistory")
      ? JSON.parse(sessionStorage.getItem("PRRPageSearchHistory"))
      : myActionFilter[0];
    xts.setSearchParam(searchHistory);

    setSearchFields(searchFields1);
    setOptionsLoaded(true);
  }, [prrOptions]);

  const onGridRowClick = (e) => {
    const prr: PersonnelRequisition = e.dataItem;
    const isEditable =
      prr?.status === "Draft" || prr?.status === "Revision" ? true : false;
    let url;
    if (isEditable) {
      url = "/prr/edit/" + prr.personnelRequisitionGuid;
    } else {
      url = "/prr/view/" + prr.personnelRequisitionGuid;
    }
    setRedirect({
      shouldRedirect: true,
      url: url,
    });
  };

  const getAdvancedSearchSelectedValue = (field) => {
    if (!xts.searchParams.advancedSearchCondition) {
      return;
    }

    let returnValue = null;

    for (const v of xts.searchParams.advancedSearchCondition) {
      if (field.field === v.field.field) {
        returnValue = v;
      }
    }

    return returnValue;
  };

  const onSearchCondition = (e) => {
    xts.setSearchParam(e);
    sessionStorage.setItem("PRRPageSearchHistory", JSON.stringify(e));
    updatePagination(paginationEntity, {
      ...paging,
      skip: 0,
    });
  };

  const onExportButtonClick = (e) => {
    let api: PersonnelRequisitionApi = factory.create(PersonnelRequisitionApi);

    xts.setLoading(true);
    api
      .personnelRequisitionGet(
        context?.loggedOnUser?.username,
        selectedActionFilter?.value || "AllPRR",
        xts.searchParams?.searchString,
        xts.searchParams?.oDataQuery,
        prrList.count,
        0,
        prrList.count,
        `prr.${xts.paging.sort.field} ${xts.paging.sort.dir}`,
      )
      .then(async (data) => {
        const dataFromApi = await data.json();

        if (xts.gridSelection.differedData.length === 0)
          xts.setReportData(dataFromApi.value);
        else {
          let data: any[] = dataFromApi.value;
          let unSelected: any[] = [];
          let printData: any[] = [];

          xts.gridSelection.differedData.forEach(
            (prr: PersonnelRequisition, i) => {
              unSelected.push(prr.prrNumber);
            },
          );

          data.forEach((prr: PersonnelRequisition, i) => {
            if (unSelected.indexOf(prr.prrNumber) === -1) {
              printData.push(prr);
            }
          });
          xts.setReportData(printData);
        }
        xts.setExportType(AdvSearchAndGridUtil.getExportType(e));
        xts.setIsExported(false);
      })
      .finally(() => {
        xts.setLoading(false);
      });
  };

  const onPageChange = (e) => {
    xts.setPaging({ ...xts.paging, skip: e.page.skip, top: e.page.take });
    updatePagination(paginationEntity, {
      ...paging,
      skip: e.page.skip,
      top: e.page.take,
    });
  };

  const onSortChange = (e) => {
    AdvSearchAndGridUtil.onSortChange(e, xts);

    if (e.sort.length > 0)
      updatePagination(paginationEntity, { ...paging, sort: e.sort[0] });
    else {
      updatePagination(paginationEntity, {
        ...paging,
        sort: {
          ...paging.sort,
          dir: paging.sort.dir,
        },
      });
    }
  };

  const onExportAfterRender = (e) => {
    AdvSearchAndGridUtil.onExportAfterRender(e, xts);
  };

  const onGridSelectionChange = (e) => {
    xts.setGridSelection(e);
  };

  const onMyActionFilterChange = (e) => {
    setSelectedActionFilter(e.value);
    sessionStorage.setItem("PRRPageShortcutHistory", JSON.stringify(e.value));
  };

  return (
    <div>
      {redirect.shouldRedirect && (
        <Redirect
          push
          to={{
            pathname: redirect.url,
          }}
        />
      )}
      <APIFactory
        ref={(e) => {
          factory = e;
        }}
      />
      <GridToolbar>
        <div className="page-toolbar p-0 align-items-center">
          <div className="row flex-grow-1 g-0">
            <div className="col-xl-auto pe-md-3">
              <h5 className="mb-2 page-title">
                Personnel Requisition Requests
              </h5>
            </div>
            <div className="col-md px-md-0 mb-2">
              {optionsLoaded && (
                <XtAdvancedSearchBox
                  searchPlaceHolder={"Search..."}
                  hideAdvancedSearch={false}
                  searchConditions={searchFields}
                  selectedConditions={xts.searchParams}
                  onSearchConditionApply={onSearchCondition}
                >
                  <XtAdvancedSearchChild type="prepend">
                    <DropDownList
                      style={{ width: "200px" }}
                      onChange={onMyActionFilterChange}
                      data={filteredShortcuts}
                      dataItemKey="value"
                      textField="text"
                      value={selectedActionFilter}
                    />
                  </XtAdvancedSearchChild>
                </XtAdvancedSearchBox>
              )}
            </div>

            <div className="col-sm-auto pe-md-0 mb-2 btn-two-groups">
              <Link to="/prr/create">
                <Button className="me-2">Create New PRR</Button>
              </Link>
              <XtExportToolbar
                fields={searchFields}
                onApply={onExportButtonClick}
                gridSelection={xts.gridSelection}
              />
            </div>
          </div>
        </div>
      </GridToolbar>
      <XtExport
        type={xts.exportType.type}
        data={xts.reportData}
        schema={xts.exportType.fields}
        fileName={xts.exportType.filename}
        onAfterRender={onExportAfterRender}
      >
        <XtGrid
          uniqueField="prrNumber"
          data={prrList?.value}
          total={prrList?.count}
          sort={[paginationState[paginationEntity].sort]}
          sortable={true}
          skip={paginationState[paginationEntity].skip}
          pageSize={pageSize}
          pageable={true}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          onGridSelectionChange={onGridSelectionChange}
          onRowClick={onGridRowClick}
          className="k-grid-prr-list"
        >
          {AdvSearchAndGridUtil.renderGridFields(prrSchema)}
        </XtGrid>
      </XtExport>
      {(isPrrOptionsLoading ||
        isPrrListLoading ||
        isPrrOptionsFetching ||
        isPrrListFetching) && <Loading />}
    </div>
  );
}
