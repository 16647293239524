import React from 'react'
import { Container } from 'react-bootstrap';
import { IXtContext, XtContext } from '../../Contexts/XtContext';
import { Loading } from '../Loading';

export default function XtSwtichBack() {
  let context: IXtContext = React.useContext(XtContext);
  const [loading, setLoading] = React.useState(false);
  let loggedOnUserDisplayname = "";

  if (context.loggedOnUser) {
    loggedOnUserDisplayname =
      context?.loggedOnUser?.firstname + " " + context?.loggedOnUser?.lastname;
  }

  return (
    <>
      {context.isInSwitchUserMode() && (
        <div className="switch-back">
          <i className="material-icons">swap_vertical_circle</i>
          <span className="px-2">
            {loggedOnUserDisplayname}
          </span>
          <a href="#"
            onClick={(e) => {
              e.preventDefault()
              context.switchBack();
            }}
          >
            <i className="material-icons" style={{ fontSize: "24px" }}>arrow_forward</i>
          </a>

        </div>
      )
      }

      {loading && <Loading />}
    </>
  )
}
