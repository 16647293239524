import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { XtField } from "nwcommon";
import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
  GridToolbar,
  GridProps,
  GridFooterCellProps,
} from "@progress/kendo-react-grid";
import { AdvSearchAndGridUtil } from "../../../../Xt/Utils/AdvanceSearchAndGridUtil";
import { XtPaging } from "../../../../Xt/ApiClients/ApiHelper";
import { InvoiceCoding } from "../../../../Xt/ApiClients";
import RightAlignGridColumnHeader from "../../Nw/PurchaseRequisition/Common/GridCell/RightAlignGridColumnHeader";
import AmountGridCell from "../../Nw/PurchaseRequisition/Common/GridCell/AmountGridCell";
import InvoiceAmountGridCell from "../../Nw/PurchaseRequisition/Common/GridCell/InvoiceAmountGridCell";
import { NumberUtil } from "../../../../Xt/Utils/NumberUtil";

export type InvoiceDetailsInvoiceCodingProps = {
  orgId?: string;
  data?: InvoiceCoding[];
  triggerCount: number;
  allowEditForPM?: boolean;
  onCodingAmountChanged?: any;
  invCodeRunningTotals: any;
};

export function InvoiceDetailsInvoiceCoding(
  props: InvoiceDetailsInvoiceCodingProps
) {
  const sumFooterCell = (cellProps: GridFooterCellProps) => {
    if (!props || !props.data) return null;
    const field = cellProps.field || "";
    const sum = props.data
      .map((dataItem) => dataItem[field])
      .reduce((acc, current) => NumberUtil.decimalNumSum(acc, current), 0);
    return (
      <td style={{ textAlign: "right" }}>
        {NumberUtil.formatToTwoDecimalWithComma(sum)}
      </td>
    );
  };

  return (
    <>
      <Grid
        data={props?.data?.sort((l1, l2) => l1.sno - l2.sno)}
        total={0}
        className="k-grid-pr-list"
        editField="inEdit"
        onItemChange={(e) => {
          props.onCodingAmountChanged(e, e.dataItem);
        }}
      >
        <GridColumn
          title="#"
          field="sno"
          width="35px"
          cell={(e) => {
            return <td>{e.dataItem.sno}</td>;
          }}
        />
        <GridColumn
          title="Charge Code"
          field="projectNumber"
          width="250px"
          cell={(e) => {
            let value = e.dataItem[e.field] + " - " + e.dataItem?.projectName;
            return <td>{value}</td>;
          }}
        />
        <GridColumn
          title="Org ID"
          field="orgId"
          cell={(e) => {
            return <td>{e.dataItem.orgId}</td>;
          }}
        />
        <GridColumn
          title="Description"
          field="poLineDescription"
          editable={false}
          cell={(e) => {
            return <td>{e.dataItem.poLineDescription ?? ""} </td>;
          }}
        />

        <GridColumn
          title="Invoice Line Description"
          field="description"
          width="200px"
          editable={false}
          cell={(e) => {
            return <td>{e.dataItem.description ?? ""} </td>;
          }}
        />
        <GridColumn
          title="Account #"
          field="accountNumber"
          cell={(e) => {
            let value = e.dataItem[e.field] + " - " + e.dataItem?.accountName;
            return <td>{value}</td>;
          }}
        />
        {props?.allowEditForPM == false && (
          <GridColumn
            title="Invoice Coding Amount"
            field="amount"
            headerCell={RightAlignGridColumnHeader}
            cell={AmountGridCell}
            footerCell={sumFooterCell}
          />
        )}

        {props?.allowEditForPM && (
          <GridColumn
            title="Invoice Coding Amount"
            field="amount"
            editable={true}
            cell={InvoiceAmountGridCell}
            footerCell={sumFooterCell}
          />
        )}

        <GridColumn
          title="Committed Amount"
          field="committedAmount"
          headerCell={RightAlignGridColumnHeader}
          cell={AmountGridCell}
          footerCell={sumFooterCell}
        />
        <GridColumn
          title="Cost to Date"
          field="costToDateAmount"
          headerCell={RightAlignGridColumnHeader}
          cell={AmountGridCell}
          footerCell={sumFooterCell}
        />

        <GridColumn
          title="Running Total"
          field="runningTotal"
          headerCell={RightAlignGridColumnHeader}
          cell={(e) => {
            return (
              <td style={{ textAlign: "right" }}>
                {NumberUtil.formatToTwoDecimalWithComma(
                  props.invCodeRunningTotals[e.dataIndex] ??
                    e.dataItem.runningTotal
                )}
              </td>
            );
          }}
          footerCell={sumFooterCell}
        />
        <GridColumn
          title="Remaining Amount"
          field="remainingAmount"
          headerCell={RightAlignGridColumnHeader}
          cell={(e) => {
            let remainingAmount =
              NumberUtil.decimalNumDiff(
                e.dataItem.committedAmount,
                props.invCodeRunningTotals[e.dataIndex] ??
                  e.dataItem.runningTotal
              ) || 0;
            return (
              <td style={{ textAlign: "right" }}>
                <span className={remainingAmount < 0 ? "badge bg-danger" : ""}>
                  {NumberUtil.formatToTwoDecimalWithComma(
                    remainingAmount ?? e.dataItem.remainingAmount
                  )}
                </span>
              </td>
            );
          }}
          footerCell={sumFooterCell}
        />
      </Grid>
    </>
  );
}
