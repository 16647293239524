import AmountGridCell from "./GridCell/AmountGridCell";
import DateGridCell from "./GridCell/DateGridCell";
import EditMenuGridCell from "./GridCell/EditMenuGridCell";
import NwPrStatusGridCell from "./GridCell/NwPrStatusGridCell";
import PoLinkGridCell from "./GridCell/PoLinkGridCell";
import RightAlignGridCell from "./GridCell/RightAlignGridCell";
import RightAlignGridColumnHeader from "./GridCell/RightAlignGridColumnHeader";

export const enum_request_type = {
  PURCHASE_REQUISITION: "New Purchase Request",
  
  PO_CHANGE_REQUEST: "PO Change Request",
  BLANKET_PURCHASE_REQUEST: "Blanket Purchase Request"
}

export const enum_pr_status = {
  DRAFT: "Draft",
  APPROVED: "Approved",
  PARTIALLY_APPPROVED: "Partially Approved",
  SUBMITTED: "Submitted"
}
const requisitionOption = [{ text: 'Material/ODCs', value: 'Material' }, { text: 'Service', value: 'Service' }]
const chargeOption = [{ text: 'Direct', value: 'Direct' }, { text: 'Indirect', value: 'Indirect' }]
const leaseOption = [{ text: 'Yes', value: 'Yes' }, { text: 'No', value: 'No' }]
const prTypeOption = [{ text: 'Purchase Requestion', value: 'Purchase Requestion' }, { text: 'PO Change Request', value: 'PO Change Request' }]

export const NwPrConstants = {
  pr_additional_reviewer:"Additional Reviewer",
  pr_list_schema: [
    {
      field: "isEditable",
      label: "  ",
      visible: false,
      searchable: false,
      type: "text",
      cell: EditMenuGridCell,
      width: "50px"

    },
    {
      field: "prNumber",
      label: "Purchase Req",
      visible: true,
      searchable: true,
      type: "text",
      width: "100px"
    },
    {
      field: "purchaseRequisitionGuid",
      label: "Purchase Requistion Guid",
      visible: false,
      searchable: false,
      type: "text",
    },
    {
      field: "createdOn",
      label: "Date Created",
      visible: true,
      searchable: true,
        type: "date",
      cell:DateGridCell,
    },


    {
      field: "employeeName",
      label: "Employee Name",
      visible: true,
      searchable: true,
      type: "text",
    },
    {
      field: "projectNumber",
      label: "Project Number",
      visible: true,
      searchable: true,
      type: "text",
    },
    {
      field: "prType",
      label: "PR Type",
      visible: true,
      searchable: true,
        type: "dropdown",
        options: prTypeOption
    },
    { field: "poNumber", label: "PO", visible: true, searchable: true, type: "text" , cell:PoLinkGridCell},
    {
      field: "vendorCode",
      label: "Vendor Name",
      visible: true,
      searchable: true,
      type: "text",
    },
    {
      field: "totalAmount",
      label: "Total Amount",
      visible: true,
      searchable: true,
      type: "number",
      format: "{0:c2}",
      headerCell:RightAlignGridColumnHeader,
      cell:AmountGridCell
      
      
    },
    {
      field: "status",
      label: "Status",
      visible: true,
      searchable: true,
      type: "text",
      cell: NwPrStatusGridCell,
      },
      {
          field: "chargeType",
          label: "Charge Type",
          visible: false,
          searchable: true,
          type: "dropdown",
          options: chargeOption
      },
      {
          field: "requisitionType",
          label: "Requisition Type",
          visible: false,
          searchable: true,
          type: "dropdown",
          options: requisitionOption
      },
      {
          field: "leaseType",
          label: "Lease Type",
          visible: false,
          searchable: true,
          type: "dropdown",
          options: leaseOption
      },
      {
          field: "qualityLevel",
          label: "Quality Level",
          visible: false,
          searchable: true,
          type: "dropdown",
          options: []
      },
      {
          field: "companyCode",
          label: "Company",
          visible: false,
          searchable: true,
          type: "dropdown",
          options: []
      },
      {
          field: "dateNeeded",
          label: "Date Needed",
          visible: false,
          searchable: true,
          type: "date",
      },
      {
          field: "description",
          label: "Description",
          visible: false,
          searchable: true,
          type: "text",
      },
      {
          field: "accountNumber",
          label: "Account Number",
          visible: false,
          searchable: true,
          type: "text",
      },
      {
          field: "chargeCode",
          label: "Charge Code",
          visible: false,
          searchable: true,
          type: "text",
      },
  ],

  list_shortcuts: {
    data: [
          { key: "MyPR", value: "MyPR" },
          { key: "AllPR", value: "All PRs" },
          { key: "MyDraft", value: "My Drafts" },
          { key: "MyActivePR", value: "My Active PRs" },
    ],
    textField: "value",
    dataItemKey: "key",
    defaultValue: { key: "my-active-prs", value: "My Active PRs" },
  },

  pr_type: {
    items: [
      {
        text: "Purchase Requisition",
        icon: "",
        value: enum_request_type.PURCHASE_REQUISITION,
        url: "/pr/create",
      },
      {
        text: "PO Change Request",
        icon: "",
        value: enum_request_type.PO_CHANGE_REQUEST,
      },
      {
        text: "Blanket Purchase Request",
        icon: "",
        value: enum_request_type.BLANKET_PURCHASE_REQUEST,
      },
    ],
    title: "Create New",
  },
  purchaseItemSchema: [
    {
      field: "lineItemNumber",
      label: "Line Item No.",
      visible: true,
      searchable: false,
      type: "number",
      width: 100,
    },
    {
      field: "type",
      label: "Type",
      visible: true,
      searchable: false,
      type: "text",
      width: 150,
    },
    {
      field: "chargeCode",
      label: "Charge Code",
      visible: true,
      searchable: false,
      type: "text",
      width: 100,
    },
    {
      field: "accountNumber",
      label: "Account No.",
      visible: true,
      searchable: false,
      type: "text",
      width: 100,
    },
    {
      field: "description",
      label: "Description",
      visible: true,
      searchable: false,
      type: "text",
      minWidth: 150,
    },
    {
      field: "quantity",
      label: "Quantity",
      visible: true,
      searchable: false,
      type: "number",
      width: 80,
    },
    {
      field: "unit",
      label: "Unit",
      visible: true,
      searchable: false,
      type: "number",
      width: 60,
    },
    {
      field: "unitPrice",
      label: "Unit Price",
      visible: true,
      searchable: false,
      type: "number",
      width: 100,
      format: "{0:c2}"
    },

    {
      field: "startDate",
      label: "Start Date",
      format: "{0:MM/dd/yyyy}",
      editor: "date",
      visible: true,
      searchable: false,
      type: "date",
      width: 100,
    },
    {
      field: "endDate",
      label: "End Date",
      format: "{0:MM/dd/yyyy}",
      visible: true,
      editor: "date",
      searchable: false,
      type: "date",
      width: 100,
    },
    {
      field: "total",
      label: "Total",
      visible: true,
      searchable: false,
      type: "number",
      width: 150,
      format: "{0:c2}"
    }]




};