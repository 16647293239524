import React from 'react';
import { NwApplicationMenuProps } from '../../Props/NwMenu/NwApplicationMenuProps';
import NwApplicationItem from './NwApplicationItem';
export default function NwApplicationList(props: {categories:  NwApplicationMenuProps[] } ) {

    return (
        <div className="nw_megamenu-right">
            {
                props.categories.map((app) =>
                    <NwApplicationItem {...app} key={app.applicationId} />
                )
            }
        </div>
    );
}
