import { XtContext, IXtContext } from "../Contexts/XtContext";
import * as React from "react";
import { XtNavDropdown } from "./NavBar/XtNavDropdown";
import { XtNavDropdownItem } from "./NavBar/XtNavDropdownItem";
import NwSwitchUser from "./NwMenu/NwSwitchUser";
import { Loading } from '../Layout/Loading';

export type XtUserProfileMenuProps = React.PropsWithChildren<{
    show: boolean
}>;

export function XtUserProfileMenu(props: XtUserProfileMenuProps) {
  const [isSwitchUserVisible, setSwitchUserVisible] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let context: IXtContext = React.useContext(XtContext);

  React.useEffect(() => {
    if (context.loggedOnUser) {
      if (context.isAllowedToSwitch()) {
        setSwitchUserVisible(true);
      } else {
        setSwitchUserVisible(false);
      }
    }
  }, [context.loggedOnUser]);

  const handleSwitch = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };


  const onUserSwitch = () => {
    //setLoading(true)
  }

  let loggedOnUserDisplayname = "";

  if (context.loggedOnUser) {
    loggedOnUserDisplayname =
      context?.loggedOnUser?.firstname + " " + context?.loggedOnUser?.lastname;
  }

  let jsx = (
    <React.Fragment>
      <XtNavDropdown
        
        align="end"
        className="nav-icon nav-user"
        id=""
      >
        <XtNavDropdownItem>Profile</XtNavDropdownItem>
              <NwSwitchUser onUserSwitch={onUserSwitch} />
              <XtNavDropdownItem href={"/logout"}>
          Log Out
        </XtNavDropdownItem>
      </XtNavDropdown>
     
      
      {loading && <Loading />}
    </React.Fragment>

  );

 
  return jsx;
}
