import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { XtPaging } from "../../../../Xt/ApiClients/ApiHelper";
import { InvoiceApproval } from "../../../../Xt/ApiClients";
import PrCompletedGridCell from "../../Nw/PurchaseRequisition/Common/GridCell/PrCompletedGridCell";
import NwWorkflowReviewerCell from "../../Nw/PurchaseRequisition/Common/GridCell/WorkflowReviewerCell";
import { Card } from "react-bootstrap";
import { AddInvoiceAddReviewer } from "../AddEditInvoice/AddInvoice-AddReviewer";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import PopOverGridCell from "../../Nw/PurchaseRequisition/Common/GridCell/PopOverGridCell";

export type InvoiceDetailsReviewerProps = {
  invoiceGuid: string;
  data: any[];
  onAdditionalReviewerAdded?: any;
  isApprover: boolean;
  designatorList?: any[];
  status: string;
};

export function InvoiceDetailsReviewer(props: InvoiceDetailsReviewerProps) {
  const [data, setData] = React.useState<any[]>([]);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [userLevel, setUserLevel] = React.useState<any>(null);
  const [approvalFlowGuid, setApprovalFlowGuid] = React.useState(null);

  const context: IXtContext = React.useContext(XtContext);

  React.useEffect(() => {
    if (props?.data && props?.designatorList) {
      if (props?.data.length > 0) {
        const wfData = props?.data.map((r) => {
          const currentUserIsDesignee = props?.designatorList?.find(
            (d) => d.displayname === r.displayName
          );
          if (currentUserIsDesignee)
            return {
              ...r,
              designee: context?.loggedOnUser?.displayname,
            };
          else return { ...r, designee: "" };
        });
        setData(wfData);
      } else setData([]);
    }
  }, [props?.data, props?.designatorList]);

  React.useEffect(() => {
    if (data) {
      let name = context?.loggedOnUser?.displayname;
      let index = data.findIndex((v, i) => {
        return name === v.displayName && v.isActive;
      });
      if (index >= 0) {
        setUserLevel({ level: data[index].level });
      }
    }
  }, [context?.loggedOnUser?.displayname, data]);

  const onShowDialogClick = (e) => {
    setApprovalFlowGuid(e.dataItem.invoiceApprovalFlowGuid);
    setShowDialog(true);
  };

  const onUpdateReviewers = (e) => {
    props.onAdditionalReviewerAdded(e);
  };

  const reviewCell = (reviewProps) => (
    <NwWorkflowReviewerCell
      {...reviewProps}
      onAddReviewerClick={onShowDialogClick}
      isApprover={props.isApprover}
      requestType={"Invoice"}
      status={props.status}
    />
  );

  return (
    <>
      <Card>
        <Card.Header className="border-bottom">
          <span className="col-form-label fw-bold">Reviewer Detail</span>
        </Card.Header>
        <Grid data={data} total={0} className="k-grid-reviewer-list">
          <GridColumn title="" field="" width="50px" cell={reviewCell} />
          <GridColumn
            title="Stage"
            field="stage"
            width="60px"
            cell={(e) => {
              if (
                e.dataItem?.role?.toLowerCase() === "accounting" ||
                e.dataItem?.stage + "" === "0" ||
                e.dataItem?.stage + "" === "100"
              ) {
                return <td>&nbsp;</td>;
              } else {
                return <td> {e.dataItem?.stage}</td>;
              }
            }}
          />
          <GridColumn title="Reviewer" field="displayName" width="250" />
          <GridColumn title="Reviewer Role" field="role" width="250" />
          <GridColumn
            title="Status"
            field="status"
            width="300"
            cell={(e) => {
              if (!e.dataItem.isCompleted && e.dataItem.level === 100) {
                return <td>&nbsp;</td>;
              } else {
                return <PrCompletedGridCell {...e} />;
              }
            }}
          />
          <GridColumn title="Comments" field="comment" cell={PopOverGridCell} />
        </Grid>
      </Card>
      {showDialog && (userLevel?.level === 2 || userLevel?.level === 3) && (
        <AddInvoiceAddReviewer
          additionalReviewer={userLevel}
          onClose={(e) => {
            setShowDialog(false);
          }}
          onUpdateReviewers={onUpdateReviewers}
          invoiceGuid={props.invoiceGuid}
        />
      )}
    </>
  );
}
