import { DropDownButton } from '@progress/kendo-react-buttons';
import React, { useState } from 'react';
import { Redirect} from "react-router-dom";

export default function EditMenuGridCell(props) {
  const redirectTo = {shouldRedirect:false, url:""}
  const [redirect,setRedirect] = useState(redirectTo);
  const isEditable: boolean = props.dataItem["isEditable"] == true;
 
  const onItemClick = (e: any) => {
    const url = "/pr/edit/" +props.dataItem.purchaseRequisitionGuid;
    setRedirect({
      shouldRedirect: true,
      url: url 
    });  
}

  const rowMenus = [
    { text: "Edit", icon: "k-icon k-i-edit" },
  
  ];
 
  return (
    <>
    {redirect.shouldRedirect && (
              <Redirect push
          to={{
            pathname: redirect.url
          }}
        />
      )}
      <td>
      {isEditable && (
        
          <DropDownButton
            className="k-icon k-i-more-vertical"
            items={rowMenus}
            onItemClick={onItemClick}
          />
       
      )}
       </td>
    </>
  );
}


