import { Col, Row } from "react-bootstrap";
import { PersonnelRequisition } from "../../../../../Xt/ApiClients";
import FormField from "../Components/FormField";
import FormLabelMutedText from "../Components/FormLabelMutedText";

export default function PrrAdditionalInfoReadOnly(props) {
  const prr: PersonnelRequisition = props.prr;

  return (
    <>
      <Row>
        <Col md={6}>
          <FormField label="Wage Type">
            <FormLabelMutedText value={prr?.wageType || "N/A"} />
          </FormField>
          <FormField label="Min Wage Rate">
            <FormLabelMutedText
              value={
                prr?.wageMinimum?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }) || "N/A"
              }
            />
          </FormField>
          <FormField label="Max Wage Rate">
            <FormLabelMutedText
              value={
                prr?.wageMaximum?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }) || "N/A"
              }
            />
          </FormField>
          <FormField label="Years of Experience">
            <FormLabelMutedText value={prr?.yearsExperience || "N/A"} />
          </FormField>
        </Col>
        <Col md={6}>
          <FormField label="Education">
            <FormLabelMutedText value={prr?.education || "N/A"} />
          </FormField>
          <FormField label="Is travel required?">
            <FormLabelMutedText
              value={prr?.travelRequired ? "Yes" : "No" || "N/A"}
            />
          </FormField>
          <FormField label="Travel Percentage">
            <FormLabelMutedText value={prr?.travelAmount || "N/A"} />
          </FormField>
        </Col>
        <Col md={12}>
          <FormField label="Required Licenses and Certifications">
            <FormLabelMutedText value={prr?.requiredLicenses || "N/A"} />
          </FormField>
          {/* <FormField label="Preferred  Skills">
            <FormLabelMutedText value={prr?.desiredSkills || "N/A"} />
          </FormField> */}
          <FormField label="Security Access Requirements">
            <FormLabelMutedText
              value={prr?.securityAccessRequirements || "N/A"}
            />
          </FormField>
          <FormField label="Comments">
            <FormLabelMutedText value={prr?.comments || "N/A"} />
          </FormField>
        </Col>
      </Row>
    </>
  );
}
