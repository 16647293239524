import { XtField } from "nwcommon";
import { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { FormField3Column, RequiredFormField } from "../Common/FormField";

export function NwVendorAddressDetails(props) {
  const countryList = [
    {
      text: "United States of America",
      value: "USA",
    },
    {
      text: "Canada",
      value: "CAN",
    },
  ];

  //console.log("NWVendorAddressProps: ", props);

  const [selectedAddressCode, setSelectedAddressCode] = useState(undefined);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [contactList, setContactList] = useState([]);
  const [selectedContact, setSelectedContact] = useState([]);
  const [selectedContactDetails, setSelectedContactDetails] =
    useState(undefined);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [showSelectAddress, setShowSelectAddress] = useState(false);
  const [showSelectContact, setShowSelectContact] = useState(false);

  const resetAddressDetails = () => {
    setSelectedAddress(undefined);
    setSelectedAddressCode(undefined);
    setContactList([]);
    setSelectedContactDetails(undefined);
    setSelectedContact(undefined);
  };

  const onVendorAddressChange = (evt) => {
    if (evt.value?.value == "") {
      setShowSelectContact(false);
    }

    let listOfContactsWithAdd = [
      {
        text: "Add New Contact",
        value: "-1",
        addressContactGuid: "",
        tranType: "Insert",
      },
    ];

    let selCode = evt.value?.value;
    setSelectedAddressCode(evt?.value);

    let vendorAddresses = props?.data?.vendorAddressList;

    if (vendorAddresses?.length > 0) {
      let selAddress = vendorAddresses.filter((x) => x.addressCode == selCode);
      if (selAddress) {
        if (
          selAddress.length > 0 &&
          selAddress[0].contactList &&
          selAddress[0].contactList?.length > 0
        ) {
          let listOfContacts =
            selAddress[0].contactList.map((x) => {
              return {
                text: `${x.sequenceNo}: ${x.firstName} ${x.lastName} (${x.emailID})`,
                value: x.sequenceNo,
                addressContactGuid: x.addressContactGuid,
              };
            }) || [];

          listOfContactsWithAdd.push(...listOfContacts);
          if (listOfContacts.length == 1) {
            setSelectedContact(listOfContacts[0]);
            setSelectedContactDetails(selAddress[0].contactList[0]);
            setShowSelectContact(true);
          } else {
            setSelectedContact(undefined);
            setSelectedContactDetails(undefined);
            setShowSelectContact(true);
          }

          let filteredCountry = countryList.filter((x) => {
            return x.value == selAddress[0].countryCode;
          });
          if (filteredCountry && filteredCountry.length > 0) {
            setSelectedCountry(filteredCountry[0]);
          }
        } else {
          setSelectedContact(undefined);
          setSelectedContactDetails(undefined);
          setShowSelectContact(false);
        }

        setSelectedAddress(selAddress[0]);
      } else {
        setSelectedAddress(undefined);
        setSelectedContact(undefined);
        setSelectedContactDetails(undefined);
      }
    }
    setContactList([...listOfContactsWithAdd]);
  };

  const onVendorContactChange = (evt) => {
    let selSeqno = evt?.value?.value;
    if (selSeqno) {
      setSelectedContact(evt?.value);
      let contact = selectedAddress.contactList?.filter(
        (x) => x.sequenceNo == selSeqno
      );

      if (contact?.length > 0) {
        setSelectedContactDetails(contact[0]);
      } else {
        setSelectedContactDetails(undefined);
      }
    } else {
      setSelectedContactDetails(undefined);
      setSelectedContact(undefined);
    }
  };

  let vendorAddressCodesWithNew = [
    { text: "Add New Address", value: "", vendorAddressGuid: "" },
  ];
  if (
    props?.data?.vendorAddressList &&
    props?.data.vendorAddressList.length > 0
  ) {
    let vendorAddressCodes = props?.data?.vendorAddressList.map((x) => {
      var addressPieces = [];

      if (x.addressLine1 && x.addressLine1 != "")
        addressPieces.push(x.addressLine1);
      if (x.cityName && x.cityName != "") addressPieces.push(x.cityName);
      if (x.mailStateCode && x.mailStateCode != "")
        addressPieces.push(x.mailStateCode);
      if (x.postalCode && x.postalCode != "") addressPieces.push(x.postalCode);
      let textToShow: string = "";
      if (props?.data?.isIntegrated) {
        textToShow = `${x.addressCode}: ${addressPieces.join(", ")}`;
        if (x?.tranType == "INSERT") {
          textToShow = `${addressPieces.join(", ")}`;
        }
      } else {
        textToShow = addressPieces.join(", ");
      }

      return {
        text: textToShow,
        value: x.addressCode,
        vendorAddressGuid: x.vendorAddressGuid,
        tranType: x.tranType,
      };
    });
    vendorAddressCodesWithNew.push(...vendorAddressCodes);
  }

  useEffect(() => {
    resetAddressDetails();
    if (props?.vendorCode == "" || props?.vendorCode === undefined) {
      setShowSelectAddress(false);
      setShowSelectContact(false);
    }
  }, [props?.vendorCode]);

  useEffect(() => {
    if (props?.vendorAddressGuid || props?.data) {
      const addressDetails = getVendorAddressDetails();
      setSelectedAddressCode(addressDetails?.code);
      setSelectedAddress(addressDetails?.details);
      setSelectedCountry(
        countryList.find(
          (x) => x.value == addressDetails?.details?.countryCode
        ) || null
      );
    }
  }, [props?.vendorAddressGuid, props?.data]);

  useEffect(() => {
    if (props?.addressContactGuid || props?.data) {
      const contactDetails = getContacDetails();
      setSelectedContact(contactDetails?.contact);
      setSelectedContactDetails(contactDetails?.details);
      setContactList(contactDetails?.list);
    }
  }, [props?.addressContactGuid, props?.data]);

  const getVendorAddressDetails = () => {
    let vendorAddresses = props?.data?.vendorAddressList;
    if (vendorAddresses === undefined) {
      setShowSelectAddress(false);
    }

    if (vendorAddresses?.length === 1) {
      setShowSelectAddress(true);

      const firstAddressCode = vendorAddressCodesWithNew.find(
        (v) => v.vendorAddressGuid == vendorAddresses[0].vendorAddressGuid
      );
      return { code: firstAddressCode, details: vendorAddresses[0] };
    } else if (vendorAddresses?.length > 0) {
      setShowSelectAddress(true);

      let selAddress = vendorAddresses.filter(
        (x) => x.vendorAddressGuid == props.vendorAddressGuid
      );
      if (selAddress) {
        const selectedAddressCode = vendorAddressCodesWithNew.find(
          (v) => v.vendorAddressGuid == props.vendorAddressGuid
        );

        return { code: selectedAddressCode, details: selAddress[0] };
      }
    }
  };

  const getContacDetails = () => {
    let vendorAddresses = props?.data?.vendorAddressList;

    if (vendorAddresses && vendorAddresses?.length > 0) {
      let selAddress =
        vendorAddresses.length === 1
          ? [vendorAddresses[0]]
          : vendorAddresses.filter(
              (x) => x.vendorAddressGuid == props.vendorAddressGuid
            );
      if (selAddress && selAddress.length > 0) {
        if (selAddress[0]?.contactList?.length > 0) {
          setShowSelectContact(true);
          let listOfContacts =
            selAddress[0]?.contactList.map((x) => {
              return {
                text: `${x.sequenceNo}: ${x.firstName} ${x.lastName} ${
                  x.emailID !== "" ? "(" + x.emailID + ")" : ""
                }`,
                value: x.sequenceNo,
                addressContactGuid: x.addressContactGuid,
              };
            }) || [];

          let details =
            selAddress[0].contactList.length === 1
              ? selAddress[0].contactList[0]
              : selAddress[0].contactList.find(
                  (c) => c?.addressContactGuid === props?.addressContactGuid
                );

          return {
            contact:
              selAddress[0].contactList.length === 1
                ? listOfContacts.find(
                    (c) =>
                      c?.addressContactGuid ===
                      selAddress[0].contactList[0].addressContactGuid
                  )
                : listOfContacts.find(
                    (c) => c?.addressContactGuid === props?.addressContactGuid
                  ),
            details: details,
            list: [
              {
                text: "Add New Contact",
                value: "-1",
                addressContactGuid: "",
                tranType: "Insert",
              },
              ...listOfContacts,
            ],
          };
        } else {
          setShowSelectContact(false);
        }
      }
    } else {
      setShowSelectContact(false);
    }
  };

  return (
    <>
      {(props?.vendorCode == "" || props.isFromIntegrationPage) && (
        <Row>
          <RequiredFormField label="Vendor Name">
            <XtField
              type="text"
              name="vendName"
              value={props?.data?.vendorName}
              key={`"vendor-name-"${props?.data?.vendorName}`}
              required={true}
              errormessage="Vendor is required"
            />
          </RequiredFormField>

          {props.isFromIntegrationPage && (
            <RequiredFormField label="Long Name">
              <XtField
                type="text"
                name="vendorLongName"
                value={
                  props?.data?.vendorLongName != null
                    ? props?.data?.vendorLongName
                    : props?.data?.vendorName
                }
                required={true}
                key={`"vendor-longname-1"${props?.data?.vendorLongName}`}
                errormessaage="Long Name is required"
              />
            </RequiredFormField>
          )}
        </Row>
      )}
      <Row>
        {showSelectAddress && (
          <RequiredFormField label="Select Address">
            <XtField
              type="dropdown"
              name="vendAddressCode"
              items={vendorAddressCodesWithNew}
              value={selectedAddressCode || null}
              onChange={onVendorAddressChange}
              textField="text"
              required={true}
              errormessage="Address Selection is Required"
            />
          </RequiredFormField>
        )}
        {props.isFromIntegrationPage && (
          <RequiredFormField label="Address Code">
            <XtField
              type="text"
              placeholder="Address Code"
              name="addressCode"
              value={
                props?.data?.isIntegrated == false ||
                selectedAddress?.tranType == "INSERT"
                  ? ""
                  : selectedAddress?.addressCode ?? ""
              }
              key={`"vendor-longname-1"${
                props.data?.isIntegrated == false
                  ? ""
                  : selectedAddress?.addressCode ?? ""
              }`}
              required={true}
              errormessage="Address Selection is Required"
            />
          </RequiredFormField>
        )}
      </Row>

      {(selectedAddressCode || props?.vendorCode == "") && (
        <Row>
          <Col md={12}>
            <Row>
              <Col md={6}>
                <Form.Label column sm={12} className="mb-3">
                  Vendor Address<span className="text-danger">*</span>
                </Form.Label>

                <div className="mb-3">
                  <XtField
                    type="text"
                    placeholder="Address 1"
                    name="vendAddress1"
                    required={true}
                    value={selectedAddress ? selectedAddress.addressLine1 : ""}
                    errormessage="Address 1 is required"
                    key={6}
                  />
                </div>
                <div className="mb-3">
                  <XtField
                    type="text"
                    placeholder="Address 2"
                    required={false}
                    name="vendAddress2"
                    value={selectedAddress ? selectedAddress?.addressLine2 : ""}
                    key={7}
                  />
                </div>
                <div className="mb-3">
                  <XtField
                    type="text"
                    placeholder="Address 3"
                    required={false}
                    name="vendAddress3"
                    value={selectedAddress ? selectedAddress?.addressLine3 : ""}
                    key={8}
                  />
                </div>

                <Row className="mb-3">
                  <Col>
                    <XtField
                      type="text"
                      placeholder="City"
                      name="vendCity"
                      value={selectedAddress ? selectedAddress?.cityName : ""}
                      required={true}
                      errormessage="City is required"
                      key={9}
                    />
                  </Col>
                  <Col>
                    <XtField
                      type="text"
                      placeholder="State"
                      name="vendState"
                      maxLength="2"
                      required={true}
                      errormessage="State is required"
                      value={
                        selectedAddress ? selectedAddress?.mailStateCode : ""
                      }
                      key={10}
                    />
                  </Col>
                </Row>

                <Row className=" mb-3">
                  <Col>
                    <XtField
                      type="text"
                      placeholder="Zip Code"
                      name="vendZipCode"
                      value={selectedAddress ? selectedAddress?.postalCode : ""}
                      key={11}
                      required={true}
                    />
                  </Col>
                  <Col>
                    <XtField
                      type="dropdown"
                      placeholder="Country"
                      name="vendCountryCode"
                      items={countryList}
                      textField="text"
                      //defaultValue={countryList[0]}
                      required={true}
                      onChange={(evt) => {
                        setSelectedCountry(evt.value);
                      }}
                      value={selectedCountry}
                      key={12}
                      errormessage="Country is required"
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={6}>
                {!showSelectContact && (
                  <Form.Label column sm={12} className="mb-3">
                    Vendor Contact
                  </Form.Label>
                )}

                {showSelectContact && (
                  <FormField3Column label="Vendor Contact">
                    <XtField
                      type="dropdown"
                      name="vendContactSeqNo"
                      items={contactList}
                      value={selectedContact}
                      onChange={onVendorContactChange}
                      textField="text"
                      key={2}
                      required={showSelectContact}
                      errormessage="Vendor Contact is required"
                    />
                  </FormField3Column>
                )}

                <input
                  type="hidden"
                  name="sequenceNo"
                  value={selectedContactDetails?.sequenceNo ?? ""}
                  key={"key-sequenceNo"}
                />
                <input
                  type="hidden"
                  name="tranType"
                  value={selectedContactDetails?.tranType ?? ""}
                  key={"key-tranType"}
                />
                <FormField3Column label="First Name">
                  <XtField
                    type="text"
                    name="vendContactFirstname"
                    value={
                      selectedContactDetails
                        ? selectedContactDetails?.firstName
                        : ""
                    }
                    key={2}
                  />
                </FormField3Column>

                <FormField3Column label="Last Name">
                  <XtField
                    type="text"
                    name="vendContactLastname"
                    value={
                      selectedContactDetails
                        ? selectedContactDetails?.lastName
                        : ""
                    }
                    key={3}
                  />
                </FormField3Column>

                <FormField3Column label=" Email">
                  <XtField
                    type="email"
                    name="vendContactEmail"
                    value={
                      selectedContactDetails
                        ? selectedContactDetails.emailID
                        : ""
                    }
                    key={4}
                  />
                </FormField3Column>

                <FormField3Column label=" Phone">
                  <XtField
                    type="tel"
                    name="vendContactPhone"
                    value={
                      selectedContactDetails
                        ? selectedContactDetails.phoneID
                        : ""
                    }
                    key={5}
                  />
                </FormField3Column>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}
