import { useState } from "react";

import { GridColumn, Grid, GridCellProps } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button, Col, Container } from "react-bootstrap";

import { IFile } from "./FileUpload";

interface IFileUploadGridProps {
  files: IFile[] | any[];
  onRemoveFile: (fileIndex: number) => void;
}

export default function FileUploadGrid(props: IFileUploadGridProps) {
  const [deletingIndex, setDeletingIndex] = useState<number>(-1);

  const { files, onRemoveFile } = props;
  const show = files.length > 0;

  const deleteCell = (props: GridCellProps) => {
    return (
      <td>
        <span
          title="Delete"
          role="button"
          className="k-icon k-i-delete "
          onClick={() => setDeletingIndex(props.dataIndex)}
        />
      </td>
    );
  };

  return (
    <>
      {show && (
        <div>
          <Grid data={files}>
            <GridColumn cell={deleteCell} width={40} />
            <GridColumn field="name" title="File Name" />
          </Grid>
        </div>
      )}

      {deletingIndex !== -1 && (
        <Dialog
          title="Confirmation"
          onClose={() => {
            setDeletingIndex(-1);
          }}
        >
          <Container>
            <Col>Do you really want to delete this file?</Col>
          </Container>
          <DialogActionsBar>
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                onRemoveFile(deletingIndex);
                setDeletingIndex(-1);
              }}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setDeletingIndex(-1);
              }}
            >
              No
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}
